import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'
import { Fab } from '@material-ui/core'
import { Fade } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Link } from '@material-ui/core'
import { TextField } from '@material-ui/core'

import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import EmailsModal from './PIIEmailsModal.jsx'
import CustomConfirmAlert from 'components/CustomConfirmAlert/CustomConfirmAlert.js'
import { PIIRejectDialog } from 'components/PIIEmails/PIIRejectModal.js'
import { PIIDetailsModal } from 'components/PIIEmails/PIIDetailsModal.js'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import EditIcon from '@material-ui/icons/Edit'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import NotInterested from '@material-ui/icons/NotInterested'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import SendIcon from '@material-ui/icons/Send'
import VisibilityIcon from '@material-ui/icons/Visibility'

// utils
import { formatDate, piiStatusColor } from 'utils/functions.js'
import { PIIStatus } from 'utils/Constants'
import { useGetSuppliersData, useModifySupplier, useRejectPiiValidationManually, useResendInvitation } from 'hooks/usePii'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/piiStyle'

const useStyles = makeStyles(styles)

export default function PIIEmailsView() {
  const classes = useStyles()

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm()

  const availableStatus = React.useMemo(
    () => [
      {
        value: PIIStatus.NOT_INVITED,
        label: PIIStatus.NOT_INVITED,
        classFilled: classes.notInvitedColorFilled,
        classNotFilled: classes.notInvitedColor,
        icon: <MailOutlineIcon />
      },
      {
        value: PIIStatus.INVITED,
        label: PIIStatus.INVITED,
        classFilled: classes.primaryColorFilled,
        classNotFilled: classes.primaryColor,
        icon: <PersonAddIcon />
      },
      {
        value: PIIStatus.IN_PROCESS,
        label: PIIStatus.IN_PROCESS,
        classFilled: classes.inProgressColorFilled,
        classNotFilled: classes.inProgressColor,
        icon: <VisibilityIcon />
      },
      {
        // It works just for history status
        value: PIIStatus.TOKEN_EXPIRED,
        label: PIIStatus.TOKEN_EXPIRED,
        classFilled: classes.tokenExpiredColorFilled,
        classNotFilled: classes.tokenExpiredColor,
        icon: <ReportProblemIcon />
      },
      {
        value: PIIStatus.INTEGRATION_COMPLETED,
        label: PIIStatus.INTEGRATION_COMPLETED,
        classFilled: classes.integratedColorFilled,
        classNotFilled: classes.integratedColor,
        icon: <HowToRegIcon />
      },
      {
        value: PIIStatus.OBSOLETE,
        label: PIIStatus.OBSOLETE,
        classFilled: classes.warningColorFilled,
        classNotFilled: classes.warningColor,
        icon: <PriorityHighIcon />
      },
      {
        value: PIIStatus.MAX_ATTEMPTS_ACHIEVED,
        label: 'Max Attempts',
        classFilled: classes.dangerColorFilled,
        classNotFilled: classes.dangerColor,
        icon: <NotInterestedIcon />
      },
      {
        value: PIIStatus.BLOCKED,
        label: PIIStatus.BLOCKED,
        classFilled: classes.exceptionColorFilled,
        classNotFilled: classes.exceptionColor,
        icon: <NotInterestedIcon />
      },
      {
        value: PIIStatus.SUBMITTED,
        label: PIIStatus.SUBMITTED,
        classFilled: classes.successColorFilled,
        classNotFilled: classes.successColor,
        icon: <CheckIcon />
      },
      {
        value: PIIStatus.COMPLETED,
        label: PIIStatus.COMPLETED,
        classFilled: classes.completedColorFilled,
        classNotFilled: classes.completedColor,
        icon: <DoneAllIcon />
      },
      {
        value: PIIStatus.REJECTED,
        label: PIIStatus.REJECTED,
        classFilled: classes.dangerColorFilled,
        classNotFilled: classes.dangerColor,
        icon: <CloseIcon />
      }
    ],
    [classes]
  )

  const initialPagination = { pageSize: 25, pageNumber: 1 }
  const initialFilterStatus = availableStatus.map(s => s.value)
  const initialFilters = [{ fieldName: 'status', operatorValue: 'in', filterValue: initialFilterStatus.join(',') }]

  const [selectText, setSelectText] = useState('Deselect All')
  const [createError, setCreateError] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const [detailsModal, setDetailsModal] = useState(undefined)
  const [rejectSupplierDialog, setRejectSupplierDialog] = useState(undefined)
  const [resendInvitationAlert, setResendInvitationAlert] = useState(undefined)
  const [updateEmailModal, setUpdateEmailModal] = useState(undefined)

  const [suppliers, setSuppliers] = useState([])
  const [suppliersTotal, setSuppliersTotal] = useState(0)
  const [pagination, setPagination] = useState(initialPagination)
  const [sorting, setSorting] = useState(undefined)
  const [filterStatus, setFilterStatus] = useState(initialFilterStatus)
  const [filters, setFilters] = useState(initialFilters)

  const { data: suppliersResponse, isLoading, error, isError, refetch, isFetching } = useGetSuppliersData(pagination, sorting, filters)

  const {
    mutateAsync: updateSupplier,
    isLoading: isLoadingModifySupplier,
    error: errorModifySupplier,
    isError: isErrorModifySupplier
  } = useModifySupplier()

  const {
    mutate: rejectPiiValidationManually,
    error: errorRejectPiiValidationManually,
    isError: isErrorRejectPiiValidationManually
  } = useRejectPiiValidationManually()

  const {
    mutateAsync: resendSupplierInvitation,
    isLoading: isLoadingResendInvitation,
    error: errorResendInvitation,
    isError: isErrorResendInvitation
  } = useResendInvitation()

  useEffect(() => {
    if (!isError && !isErrorModifySupplier && !isErrorRejectPiiValidationManually && !isErrorResendInvitation) {
      return
    }
    setCreateError(true)

    if (isError) {
      setErrorMessage(e => e + '\n' + error.response?.data?.message || 'Something went wrong, try again later')
    }
    if (isErrorModifySupplier) {
      setErrorMessage(
        e => e + '\nError Modifying Supplier: ' + errorModifySupplier.response?.data?.message || 'Something went wrong, try again later'
      )
    }
    if (isErrorRejectPiiValidationManually) {
      setErrorMessage(
        e =>
          e + '\nError Rejecting Supplier: ' + errorRejectPiiValidationManually.response?.data?.message ||
          'Something went wrong, try again later'
      )
    }
    if (isErrorResendInvitation) {
      setErrorMessage(
        e =>
          e + '\nError Sending Invitation to Supplier: ' + errorResendInvitation.response?.data?.message ||
          'Something went wrong, try again later'
      )
    }
    setTimeout(() => {
      setCreateError(false)
      setErrorMessage('')
    }, 5000)
  }, [
    isError,
    isErrorModifySupplier,
    isErrorRejectPiiValidationManually,
    isErrorResendInvitation,
    error,
    errorModifySupplier,
    errorRejectPiiValidationManually,
    errorResendInvitation
  ])

  React.useEffect(() => {
    if (suppliersResponse) {
      setSuppliers(suppliersResponse.suppliers)
      setSuppliersTotal(suppliersResponse.total)
    }
  }, [suppliersResponse])

  React.useEffect(() => {
    if (filterStatus.length === availableStatus.length) {
      setSelectText('Deselect All')
    } else {
      setSelectText('Select All')
    }

    setFilters(f => [
      ...f.filter(x => x.fieldName !== 'status'),
      { fieldName: 'status', operatorValue: 'in', filterValue: filterStatus.length ? filterStatus.join(',') : 'NA' }
    ])

    setPagination(p => ({ ...p, pageNumber: 1 }))
    // eslint-disable-next-line
  }, [filterStatus])

  const resendInvitation = React.useCallback((hashKey, validationId) => {
    resendSupplierInvitation({
      hashKey,
      validationId
    }).then(() => {
      setValidationMessage('Invitation forwarded')
    })
    // eslint-disable-next-line
  }, [])

  const columns = React.useMemo(
    () => [
      {
        field: 'creationDate',
        headerName: 'Creation Date',
        headerAlign: 'center',
        align: 'center',
        width: 200,
        filterable: false,
        renderCell: params => (params.value ? formatDate({ date: params.value }) : 'Invalid Date')
      },
      {
        field: 'lastUpdated',
        headerName: 'Last Update',
        headerAlign: 'center',
        align: 'center',
        width: 200,
        filterable: false,
        renderCell: params => (params.value ? formatDate({ date: params.value }) : 'Invalid Date')
      },
      {
        field: 'appId',
        headerName: 'App Id',
        headerAlign: 'center',
        align: 'center',
        width: 150
      },
      {
        field: 'type',
        headerName: 'App Type',
        headerAlign: 'center',
        align: 'center',
        width: 150
      },
      {
        field: 'taskId',
        headerName: 'Task Id',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: params => (
          <Link
            component="button"
            variant="body2"
            style={{ color: '#144CAC' }}
            underline="none"
            onClick={() => {
              window.open(params.row.taskAribaLink, '_blank')
            }}
          >
            {params.value}
          </Link>
        )
      },
      {
        field: 'supplierId',
        headerName: 'Supplier Id',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: params => (
          <Link
            component="button"
            variant="body2"
            style={{ color: '#144CAC' }}
            underline="none"
            onClick={() => {
              window.open(params.row.supplierAribaLink, '_blank')
            }}
          >
            {params.value}
          </Link>
        )
      },
      {
        field: 'email',
        headerName: 'Supplier Email',
        headerAlign: 'center',
        align: 'center',
        width: 200
      },
      {
        field: 'status',
        headerName: 'Status',
        headerAlign: 'center',
        align: 'center',
        width: 200,
        filterable: false,
        renderCell: params => (
          <p style={{ color: piiStatusColor(params.value) }}>{params.value.charAt(0).toUpperCase() + params.value.substring(1)}</p>
        )
      },
      {
        field: 'actions',
        headerAlign: 'center',
        align: 'center',
        width: 250,
        filterable: false,
        renderCell: params => {
          const disableEditMail = [
            PIIStatus.CANCELLED_BY_SUPPLIER,
            PIIStatus.COMPLETED,
            PIIStatus.INTEGRATION_COMPLETED,
            PIIStatus.OBSOLETE,
            PIIStatus.REJECTED_WITHOUT_APPROVAL,
            PIIStatus.REJECTED,
            PIIStatus.SUBMITTED
          ].includes(params.row.status)

          const disableRejectSupplier = [
            PIIStatus.CANCELLED_BY_SUPPLIER,
            PIIStatus.COMPLETED,
            PIIStatus.INTEGRATION_COMPLETED,
            PIIStatus.OBSOLETE,
            PIIStatus.REJECTED_WITHOUT_APPROVAL,
            PIIStatus.REJECTED,
            PIIStatus.SUBMITTED
          ].includes(params.row.status)

          const disableResendInvitation = [
            PIIStatus.CANCELLED_BY_SUPPLIER,
            PIIStatus.COMPLETED,
            PIIStatus.INTEGRATION_COMPLETED,
            PIIStatus.OBSOLETE,
            PIIStatus.REJECTED_WITHOUT_APPROVAL,
            PIIStatus.REJECTED,
            PIIStatus.SUBMITTED
          ].includes(params.row.status)

          return (
            <Grid container justifyContent="space-evenly">
              <Grid item>
                <Tooltip title={'Edit Email'}>
                  <span>
                    <IconButton
                      aria-label="Edit Email"
                      size="small"
                      onClick={() => {
                        setUpdateEmailModal(
                          <EmailsModal
                            title={'Edit Supplier Email'}
                            supplierEmail={params.row.email}
                            onCancel={() => setUpdateEmailModal(undefined)}
                            onConfirm={returnValues => {
                              updateSupplier({
                                hashKey: `${params.row.tenantId}-${params.row.appId}`,
                                validationId: params.row.taskId,
                                email: returnValues.supplierEmail
                              })
                              setUpdateEmailModal(undefined)
                            }}
                          />
                        )
                      }}
                      disabled={disableEditMail}
                    >
                      <EditIcon style={{ color: disableEditMail ? 'rgba(0, 0, 0, 0.26)' : '#081c3e' }} />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={'Reject Supplier'}>
                  <span>
                    <IconButton
                      aria-label="Reject Supplier"
                      size="small"
                      onClick={() => {
                        setRejectSupplierDialog(
                          <PIIRejectDialog
                            open={true}
                            onClose={() => setRejectSupplierDialog(undefined)}
                            rejectPII={rejectedMessage => {
                              rejectPiiValidationManually({
                                hashKey: `${params.row.tenantId}-${params.row.appId}`,
                                validationId: params.row.taskId,
                                rejectedMessage
                              })
                              setRejectSupplierDialog(undefined)
                            }}
                          />
                        )
                      }}
                      disabled={disableRejectSupplier}
                    >
                      <NotInterested style={{ color: disableRejectSupplier ? 'rgba(0, 0, 0, 0.26)' : '#081c3e' }} />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={'Re-send invitation'}>
                  <span>
                    <IconButton
                      aria-label="Re-send invitation"
                      size="small"
                      onClick={() => {
                        setResendInvitationAlert(
                          <CustomConfirmAlert
                            message={'Are you sure, you want resend the invitation?'}
                            title={'Resend Invitation'}
                            onCancel={() => setResendInvitationAlert(undefined)}
                            onConfirm={() => {
                              resendInvitation(`${params.row.tenantId}-${params.row.appId}`, params.row.taskId)
                              setResendInvitationAlert(undefined)
                            }}
                          />
                        )
                      }}
                      disabled={disableResendInvitation}
                    >
                      <SendIcon style={{ color: disableResendInvitation ? 'rgba(0, 0, 0, 0.26)' : '#081c3e' }} />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={'Details'}>
                  <span>
                    <IconButton
                      aria-label="Details"
                      size="small"
                      onClick={() => {
                        setDetailsModal(
                          <PIIDetailsModal
                            showModal={true}
                            onClose={() => setDetailsModal(undefined)}
                            tenantId={params.row.tenantId}
                            appId={params.row.appId}
                            supplierId={params.row.supplierId}
                          />
                        )
                      }}
                    >
                      <MoreHorizIcon style={{ color: '#081c3e' }} />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          )
        }
      }
    ],
    [rejectPiiValidationManually, resendInvitation, updateSupplier]
  )

  const onChangeStatus = React.useCallback(status => {
    setFilterStatus(fs => (fs.includes(status) ? fs.filter(x => x !== status) : [...fs, status]))
  }, [])

  const toggleAllStatus = React.useCallback(() => {
    const newFilterStatus = []
    if (selectText === 'Select All') {
      newFilterStatus.push(...availableStatus.map(s => s.value))
      setSelectText('Deselect All')
    } else {
      setSelectText('Select All')
    }
    setFilterStatus(newFilterStatus)
    setFilters(f => [
      ...f.filter(x => x.fieldName !== 'status'),
      { fieldName: 'status', operatorValue: 'in', filterValue: newFilterStatus.length ? newFilterStatus.join(',') : 'NA' }
    ])
  }, [availableStatus, selectText])

  const onSubmit = data => {
    const fields = columns.filter(c => c.filterable !== false).map(column => column.field)
    setFilters(f => [
      ...f.filter(f => !fields.includes(f.fieldName)),
      ...fields.filter(f => data[f]).map(f => ({ fieldName: f, operatorValue: 'equals', filterValue: data[f] }))
    ])
    setPagination(p => ({ ...p, pageNumber: 1 }))
  }

  const onResetFilters = () => {
    reset()
    const fields = columns.filter(c => c.filterable !== false).map(column => column.field)
    setFilters(f => [...f.filter(f => !fields.includes(f.fieldName))])
    setPagination(p => ({ ...p, pageNumber: 1 }))
  }

  return (
    <>
      <Fade in={true} timeout={250}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader>
                <h3 className={classes.formHeader}>{'PII Suppliers Information'}</h3>
              </CardHeader>
              <CardBody>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid container item xs={12} justifyContent="space-evenly" spacing={1}>
                    {availableStatus.map((status, index) => (
                      <Grid key={index} item xs={4} sm={3} md={2} lg={1}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid container item xs={12} justifyContent="center">
                            <Fab
                              size="small"
                              className={filterStatus.includes(status.value) ? status.classFilled : status.classNotFilled}
                              onClick={() => onChangeStatus(status.value)}
                            >
                              {status.icon}
                            </Fab>
                          </Grid>
                          <Grid container item xs={12} justifyContent="center">
                            <span style={{ textAlign: 'center' }}>{status.label}</span>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container item xs={12} justifyContent="center">
                    <div className={classes.statusButton} style={{ width: '115px', border: 'none' }}>
                      <Button size="small" onClick={toggleAllStatus} style={{ color: '#999' }}>
                        {selectText}
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid container justifyContent="center" spacing={2}>
                        <Grid container item xs={12} justifyContent="space-evenly">
                          {columns
                            .filter(c => c.filterable !== false)
                            .map(column => (
                              <Grid key={column.field} item>
                                <TextField {...register(column.field)} label={column.headerName} />
                              </Grid>
                            ))}
                        </Grid>
                        <Grid container item xs={12} sm={8} md={4} lg={2} justifyContent="space-evenly">
                          <Button variant="contained" color="secondary" onClick={onResetFilters} disabled={!isDirty}>
                            {'Reset'}
                          </Button>
                          <Button variant="contained" color="primary" type="submit" disabled={!isDirty}>
                            {'Filter'}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                  <GridItem xs={12} sm={12}>
                    <GridContainer justifyContent="center" style={{ marginTop: '5px' }}>
                      {(isLoading || isFetching || isLoadingModifySupplier || isLoadingResendInvitation) && (
                        <div className={classes.reloadSize}>
                          <CircularProgress />
                        </div>
                      )}
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ height: 400, width: '100%' }}>
                          <DataGrid
                            columns={columns}
                            disableColumnMenu
                            rows={suppliers || []}
                            getRowId={row => `${row.tenantId}-${row.appId}-${row.taskId}`}
                            rowCount={suppliersTotal}
                            pageSize={pagination.pageSize}
                            page={pagination.pageNumber - 1}
                            onPageSizeChange={newPageSize => setPagination({ pageNumber: 1, pageSize: parseInt(newPageSize) })}
                            onPageChange={newPage => setPagination({ ...pagination, pageNumber: newPage + 1 })}
                            paginationMode={'server'}
                            sortModel={sorting ? [sorting] : []}
                            onSortModelChange={model => setSorting(model.length ? { ...model.shift() } : undefined)}
                            sortingOrder={['desc', 'asc', null]}
                            sortingMode="server"
                            components={{
                              Toolbar: () => (
                                <GridToolbarContainer style={{ display: 'flex', justifyContent: 'end' }}>
                                  <Tooltip title="Refresh">
                                    <Button color="primary" size="small" onClick={() => refetch({ throwOnError: true })}>
                                      <RefreshRoundedIcon />
                                    </Button>
                                  </Tooltip>
                                </GridToolbarContainer>
                              )
                            }}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
          <Snackbar
            place="br"
            color="success"
            icon={AddAlert}
            message={validationMessage}
            open={submitSuccess}
            closeNotification={() => setSubmitSuccess(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={errorMessage}
            open={createError}
            closeNotification={() => {
              setCreateError(false)
              setErrorMessage('')
            }}
            close
          />
        </GridContainer>
      </Fade>
      {updateEmailModal}
      {resendInvitationAlert}
      {detailsModal}
      {rejectSupplierDialog}
    </>
  )
}
