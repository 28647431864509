import React, { useState } from 'react'

// @material-ui/core components
//import { makeStyles } from '@material-ui/core/styles'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import CustomInput from '../../components/CustomInput/CustomInput.js'

//import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/customModalStyles.js'
import CustomModal from 'components/CustomModal/CustomModal.js'
//const useStyles = makeStyles(styles)
export default function OneStringModal (props) {
  const [stringValue, setStringValue] = useState(props.initValue)
  //const classes = useStyles()
  return (
    <CustomModal
      title={props.title}
      confirmBtnCssClass={props.confirmBtnCssClass}
      cancelBtnCssClass={props.cancelBtnCssClass}
      onCancel={props.onCancel}
      onConfirm={() => {
        props.onConfirm(stringValue)
      }}
    >
      <GridContainer container spacing={4}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
            <CustomInput
              labelProps={{ style: { width: '100%' } }}
              labelText={props.label}
              id='inputId'
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => {
                  setStringValue(event.target.value)
                  // console.log(event)
                },
                type: 'text',
                value: stringValue
              }}
            />
          </div>
        </GridItem>
      </GridContainer>
    </CustomModal>
  )
}
