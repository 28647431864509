import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// material ui icons

// core components
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

// images
import LoginImage from 'assets/img/LoginImage.png'

import styles from 'assets/jss/material-dashboard-pro-react/views/loginViewStyle.js'
import { getHashParameters } from 'utils/functions'
import usePopUp from './usePopUp'

const useStyles = makeStyles(styles)

export function path(root, sublink) {
  const rootTemp = typeof window !== 'undefined' && root === '' ? window.location.origin : root
  return `${rootTemp}${sublink}`
}

export default function LoginPageExternal() {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden')
  const { openPopup, popupData } = usePopUp()
  const location = useLocation()
  const isLogged = Boolean(popupData)
  const history = useHistory()
  const login = path('', `/auth/login-page`)

  setTimeout(function() {
    setCardAnimation('')
  }, 700)

  const classes = useStyles()

  function handleLoginButtonClick(params) {
    openPopup({
      url: login,
      width: 400,
      height: 648
    })
  }

  React.useEffect(() => {
    if (location.state && location.state.from) {
      localStorage.setItem('returnUrl', `${location.state.from.pathname}${location.state.from.search || ''}`)
    } else if (location.hash) {
      const hashParams = getHashParameters(location.hash)
      if (hashParams.returnUrl) {
        localStorage.setItem('returnUrl', hashParams.returnUrl)
      }
    }
  }, [location.state, location.hash])

  React.useEffect(() => {
    localStorage.removeItem('login.method')
  }, [])

  React.useEffect(() => {
    if (!isLogged) {
      return
    }

    localStorage.removeItem('login.workflow')
    localStorage.removeItem('origin')
    localStorage.setItem('login.method', 'external')

    const returnUrl = localStorage.getItem('returnUrl')
    if (returnUrl) {
      localStorage.removeItem('returnUrl')
      history.push(returnUrl)
    } else {
      history.push(`/admin/home`)
    }
    // eslint-disable-next-line
  }, [isLogged])

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="primary">
                <h2 className={classes.cardTitle}>Relish</h2>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={12}>
                  <img width="100%" src={LoginImage} alt="logo" className={classes.logotypeImage} />
                </GridItem>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="primary" simple size="lg" block onClick={handleLoginButtonClick}>
                  Login
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  )
}
