import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

// Material UI
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

// Components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Snackbar from 'components/Snackbar/Snackbar.js'

// Icons
import TouchApp from '@material-ui/icons/TouchApp'
import CheckIcon from '@material-ui/icons/Check'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AddAlert from '@material-ui/icons/AddAlert'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Constants
import { insightsDeluxeTitles } from 'utils/Constants'

// Hooks
import {
  useGetAppList,
  useGetTransaction,
  useGetChildTransactions,
  useTransactionLogs
} from 'hooks/useInsights'

// Views
import TabPanel from '../Components/TabPanel'
import DetailsView from './DetailsView'
import LogsView from './LogsView'

moment.locale(window.navigator.userLanguage || window.navigator.language)
const useStyles = makeStyles(styles)

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function InsightsDetails(props) {
  const classes = useStyles()
  const { appId, group, transactionId } = useParams()
  const history = useHistory()
  const [tab, setTab] = useState(0)
  const [submitSuccess, setSubmitSuccess] = useState({
    isOpen: false,
    message: ''
  })
  const [createError, setCreateError] = useState({
    message: '',
    isOpen: false,
    color: 'danger'
  })
  const [paginationLog, setPaginationLog] = useState({
    page: 0,
    rowsPage: 10,
    lastPage: -1,
    lastEvaluatedKeys: {}
  })
  const [paginationChild, setPaginationChild] = useState({
    page: 0,
    rowsPage: 10,
    lastPage: -1,
    lastEvaluatedKeys: {}
  })

  const appList = useGetAppList({
    options: { refetchOnWindowFocus: false }
  })

  useEffect(() => {
    if (!appList.isLoading && appList.isError) {
      setCreateError({
        message: appList.error.response?.data?.message ?? 'Something went wrong, try again later',
        isOpen: true,
        color: 'danger'
      })
    }
  }, [appList.isLoading, appList.isError, appList.error])

  const transaction = useGetTransaction({
    appId,
    transactionId,
    options: { refetchOnWindowFocus: false }
  })

  const childTransactions = useGetChildTransactions({
    appId,
    transactionId,
    pagination: paginationChild,
    options: { refetchOnWindowFocus: false }
  })

  useEffect(() => {
    if (!childTransactions.isError) {
      return
    }
    setCreateError({
      isOpen: true,
      message:
        childTransactions.error.response?.data?.message || 'Something went wrong, try again later'
    })
  }, [childTransactions.isError, childTransactions.error])

  useEffect(() => {
    if (!childTransactions.data) {
      return
    }

    if (!childTransactions.data?.lastEvaluatedKey) {
      setPaginationChild(old => {
        return {
          ...old,
          lastPage: old.page
        }
      })
      return
    }

    setPaginationChild(old => {
      return {
        ...old,
        lastEvaluatedKeys: {
          ...old.lastEvaluatedKeys,
          [old.page]: childTransactions.data?.lastEvaluatedKey
        }
      }
    })
  }, [childTransactions.data])

  const {
    data: transactionLogs,
    isLoading: isLoadingTransactionLogs,
    error: errorTransactionLogs,
    isError: isErrorTransactionLogs,
    refetch: refetchTransactionLog,
    isFetching: isRefetchingTransactionLog
  } = useTransactionLogs({
    appId,
    transactionId,
    pagination: paginationLog,
    options: { refetchOnWindowFocus: false }
  })

  useEffect(() => {
    if (!isErrorTransactionLogs) {
      return
    }
    setCreateError({
      isOpen: true,
      message:
        errorTransactionLogs.response?.data?.message || 'Something went wrong, try again later'
    })
  }, [isErrorTransactionLogs, errorTransactionLogs])

  useEffect(() => {
    if (!transactionLogs) {
      return
    }

    if (!transactionLogs?.lastEvaluatedKey) {
      setPaginationLog(old => {
        return {
          ...old,
          lastPage: old.page
        }
      })
      return
    }

    setPaginationLog(old => {
      return {
        ...old,
        lastEvaluatedKeys: {
          ...old.lastEvaluatedKeys,
          [old.page]: transactionLogs?.lastEvaluatedKey
        }
      }
    })
  }, [transactionLogs])

  let breadcrumbViews = [
    {
      name: 'Relish Insights',
      url: '/admin/insights/index',
      icon: AssessmentIcon
    },
    {
      name: insightsDeluxeTitles[group] + ' Connection',
      url: `/admin/insights/${appId}/groups/${group}`,
      icon: CheckIcon
    },
    {
      name: 'Transaction Details',
      url: `/admin/insights/${appId}/groups/${group}/details/${transactionId}`,
      icon: CheckIcon
    }
  ]

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} sm={3} md={3} lg={3}>
        <Card style={{ height: '100%' }}>
          <CardContent>Application List</CardContent>
          <CardContent>
            {appList.isLoading ? (
              <Card>
                <Grid container justifyContent="center">
                  <div className={classes.circularProgress}>
                    <CircularProgress color="inherit" />
                  </div>
                </Grid>
              </Card>
            ) : (
              <List>
                {appList.data.map((prop, key) => {
                  return (
                    <ListItem
                      className={classes.listItem}
                      button
                      selected={appId === prop.appId}
                      key={key}
                      onClick={() => {
                        history.push('/admin/insights/index')
                      }}
                    >
                      <ListItemAvatar>
                        {appId === prop.appId ? (
                          <Avatar>
                            <TouchApp />
                          </Avatar>
                        ) : (
                          <Avatar className={classes.clearAvatar}></Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText primary={prop.appName} secondary={`Type: ${prop.type}`} />
                    </ListItem>
                  )
                })}
              </List>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={9} md={9} lg={9}>
        <Breadcrumbs views={breadcrumbViews} />
        <Card>
          <CardContent>
            <Grid container justifyContent="center">
              {childTransactions.isLoading || transaction.isLoading ? (
                <Grid item xs={12} className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={3}>
                    <Grid item xs={12}>
                      <h3 className={classes.formHeader}>Relish Insights - Details</h3>
                    </Grid>
                    <Grid item xs={12}>
                      <Tabs
                        value={tab}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                      >
                        <Tab label="Details" {...a11yProps(0)} />
                        {childTransactions.data && childTransactions.data?.rows.length === 0 ? (
                          <Tab label="Logs" {...a11yProps(1)} />
                        ) : null}
                      </Tabs>
                      <TabPanel value={tab} index={0}>
                        {transaction.data ? (
                          <DetailsView
                            transaction={transaction}
                            childTransactions={childTransactions}
                            pagination={paginationChild}
                            setPagination={setPaginationChild}
                            appId={appId}
                            group={group}
                            setSubmitSuccess={setSubmitSuccess}
                            setCreateError={setCreateError}
                          />
                        ) : null}
                      </TabPanel>
                      <TabPanel value={tab} index={1}>
                        {!isLoadingTransactionLogs ? (
                          <LogsView
                            transactionLogs={transactionLogs}
                            pagination={paginationLog}
                            setPagination={setPaginationLog}
                            refetch={refetchTransactionLog}
                            isFetching={isRefetchingTransactionLog}
                            isLoading={isLoadingTransactionLogs}
                          />
                        ) : null}
                      </TabPanel>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Snackbar
        place="br"
        color="success"
        icon={AddAlert}
        message={submitSuccess.message}
        open={submitSuccess.isOpen}
        closeNotification={() =>
          setSubmitSuccess({
            isOpen: false,
            message: ''
          })
        }
        close
      />
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() =>
          setCreateError({
            isOpen: false,
            message: ''
          })
        }
        close
      />
    </Grid>
  )
}
