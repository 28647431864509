import { useQuery, useQueryClient, useMutation } from 'react-query'
import {
  getPaymentsQuery,
  getPaymentQuery,
  getPresignedUrlPaymentFilesQuery,
  changePaymentStatusQuery
} from 'services/apiInvoices'

export function useGetPayments({ status, configTenant }) {
  const queryClient = useQueryClient()
  return useQuery(
    // eslint-disable-next-line
    ['payments', status.reduce((acc, curr) => ((acc[curr] = curr), acc), {})],
    () =>
      getPaymentsQuery({ status, appId: configTenant.defaultApp }).then(
        result => {
          result.data.forEach(payment => {
            queryClient.setQueryData(['payments', payment.paymentId], payment)
          })
          const data = result.data.map(row => {
            row['supplierId'] = row.header?.supplierId 
            row['supplierName'] = row.header?.supplierName
            row['integrationStatus'] = typeof row.integrationStatus === 'object'
              ? row.integrationStatus?.status
              : row.integrationStatus
              row['cfdiStatus'] = row.header.cfdiStatus
              row['UUID'] = row.relatedDocumentId
              row['RelishId'] = row.paymentId
            return row
          })
          return data
        }
      ),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!configTenant
    }
  )
}

export function useGetPayment({ paymentId, configTenant, options = {} }) {
  return useQuery(
    ['payments', paymentId],
    () =>
      getPaymentQuery({
        paymentId,
        appId: configTenant.defaultApp
      }).then(result => {
        return { ...result.data, history: result.data.history || [] }
      }),
    {
      enabled: !!configTenant,
      ...options
    }
  )
}

export function useGetPresignedurlAttachments({
  paymentId,
  appId,
  config = {}
}) {
  return useQuery(
    ['payments', 'attachments', paymentId],
    () =>
      getPresignedUrlPaymentFilesQuery({ paymentId, appId }).then(
        result => result.data
      ),
    {
      ...config
    }
  )
}

export function useUpdateStatusPayment({ configTenant, paymentId }) {
  const queryClient = useQueryClient()
  return useMutation(
    values =>
      changePaymentStatusQuery({
        app: configTenant.defaultApp,
        id: paymentId,
        body: { message: values.message, status: values.status }
      }),
    {
      onMutate: async paymentUpdated => {
        await queryClient.cancelQueries(['payments', paymentUpdated.paymentId])

        const status =
          paymentUpdated.status === 'reject' ? 'rejected' : 'processing'

        // Snapshot the previous value
        const previousPayment = queryClient.getQueryData([
          'payments',
          paymentUpdated.paymentId
        ])

        const paymentData = {
          ...previousPayment,
          status
        }

        // Optimistically update to the new value
        queryClient.setQueryData(
          ['payments', paymentUpdated.paymentId],
          paymentData
        )

        const previousPayments = queryClient.getQueryData('payments')

        if (previousPayments) {
          queryClient.setQueryData(['payments'], oldPayments => {
            return oldPayments.map(payment => {
              if (payment.paymentId === paymentUpdated.paymentId) {
                return paymentData
              }
              return payment
            })
          })
        }

        // Return a rollback function
        return () => {
          queryClient.setQueryData(
            ['payments', paymentUpdated.paymentId],
            paymentData
          )
          queryClient.setQueryData(['payments'], previousPayments)
        }
      }
    }
  )
}

export function useManualAssignPayment({ configTenant, paymentId }) {
  const queryClient = useQueryClient()
  return useMutation(
    values =>
      changePaymentStatusQuery({
        app: configTenant.defaultApp,
        id: paymentId,
        body: { message: values.message, status: values.status }
      }),
    {
      onMutate: async paymentUpdated => {
        await queryClient.cancelQueries(['payments', paymentUpdated.paymentId])

        // Snapshot the previous value
        const previousPayment = queryClient.getQueryData([
          'payments',
          paymentUpdated.paymentId
        ])

        const paymentData = {
          ...previousPayment,
          integrationStatus: {
            status: paymentUpdated.status,
            message: paymentUpdated.message
          }
        }

        // Optimistically update to the new value
        queryClient.setQueryData(
          ['payments', paymentUpdated.paymentId],
          paymentData
        )

        const previousPayments = queryClient.getQueryData('payments')

        if (previousPayments) {
          queryClient.setQueryData(['payments'], oldPayments => {
            return oldPayments.map(payment => {
              if (payment.paymentId === paymentUpdated.paymentId) {
                return paymentData
              }
              return payment
            })
          })
        }

        // Return a rollback function
        return () => {
          queryClient.setQueryData(
            ['payments', paymentUpdated.paymentId],
            paymentData
          )
          queryClient.setQueryData(['payments'], previousPayments)
        }
      }
    }
  )
}
