import { useQuery, useMutation } from 'react-query'

import {
  getEmailConfiguration,
  createEmailOriginConfiguration,
  updateEmailOriginConfiguration,
  getTenantProcess,
  getEmailOriginConfigById,
  deleteOriginConfiguration,
  getAllOriginConfigs,
  createApiOriginConfiguration,
  updateApiOriginConfiguration,
  getApiOriginConfigById
} from 'services/apiInvoiceOrigin'

export function useGetEmailConfiguration(appId) {
  return useQuery(
    ['invoiceOrigins', appId],
    () => getEmailConfiguration(appId).then(result => result.data),
    {
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!appId
    }
  )
}

//useUpdateApp
export function useCreateEmailOriginConfiguration() {
  return useMutation(values => createEmailOriginConfiguration(values.data))
}

export function useUpdateEmailOriginConfiguration() {
  return useMutation(values => updateEmailOriginConfiguration(values.data.id, values.data.data))
}

export function useCreateApiOriginConfiguration() {
  return useMutation(values => createApiOriginConfiguration(values.data))
}

export function useUpdateApiOriginConfiguration() {
  return useMutation(values => updateApiOriginConfiguration(values.data.id, values.data.data))
}

export function useDeleteOriginConfiguration() {
  return useMutation(values => deleteOriginConfiguration(values.data.id, values.data.data))
}

export function useGetTenantProcess(appId) {
  return useQuery(
    ['invoice', 'process', appId],
    () => getTenantProcess(appId).then(result => result.data),
    {
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!appId
    }
  )
}

export function useGetEmailOriginConfigurationById(id, appId) {
  return useQuery(
    ['invoiceOrigin', 'email', id],
    () => getEmailOriginConfigById(id, appId).then(result => result.data),
    {
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!appId && !!id
    }
  )
}

export function useGetApiOriginConfigurationById(id, appId) {
  return useQuery(
    ['invoiceOrigin', 'api', id],
    () => getApiOriginConfigById(id, appId).then(result => result.data),
    {
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!appId && !!id
    }
  )
}

export function useGeAllOriginConfigurations(appId) {
  return useQuery(
    ['invoiceOrigin', 'all', appId],
    () => getAllOriginConfigs(appId).then(result => result.data),
    {
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!appId
    }
  )
}
