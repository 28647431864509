import PropTypes from 'prop-types'
import React from 'react'

import AdditionalDetails from 'components/Invoice/AdditionalDetails'
import { useWatch } from 'react-hook-form'
import { HeaderFieldsSide } from 'utils/Constants'

export default function AdditionalDetailsExceptions(props) {
  const { control } = props

  const value = useWatch({
    name: 'summaryFields',
    control: control
  })

  const supplierId = value?.find(field => field.type === HeaderFieldsSide.VENDOR_ID.type)
  const companyCode = value?.find(field => field.type === HeaderFieldsSide.COMPANY_CODE.type)

  return <AdditionalDetails {...props} supplierId={supplierId} companyCode={companyCode} />
}

AdditionalDetailsExceptions.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onEditComplete: PropTypes.func,
  readOnly: PropTypes.bool,
  lineItemSelected: PropTypes.object,
  validationErrorsState: PropTypes.object,
  requiredFields: PropTypes.object.isRequired,
  process: PropTypes.string.isRequired
}
