function shownComponentByExternalFactors(component, externalFactors) {
    const {  process, status, origin } = externalFactors
    const show =
    (component.PROCESS.includes('*') ||  component.PROCESS.includes(process)) &&
    (component.STATUS.includes('*') ||  component.STATUS.includes(status)) &&
    (component.ORIGIN.includes('*') ||  component.ORIGIN.includes(origin))

    return show
}

export {
    shownComponentByExternalFactors
}