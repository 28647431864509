import React from 'react'
import cx from 'classnames'
import { Switch, Route } from 'react-router-dom'
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// core components
import TeamsNavbar from 'components/Navbars/Teams/TeamsNavbar.js'
import Footer from 'components/Footer/Footer.js'

import styles from 'assets/jss/material-dashboard-pro-react/layouts/Teams/teamsStyle.js'

var ps

const useStyles = makeStyles(styles)

export default function Dashboard (props) {
  const { ...rest } = props
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [miniActive, setMiniActive] = React.useState(false)
  // styles
  const classes = useStyles()
  const mainPanelClasses =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1
    })
  // ref for main panel div
  const mainPanel = React.createRef()
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      })
      document.body.style.overflow = 'hidden'
    }

    // Specify how to clean up after this effect:
    return function cleanup () {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
      }
    }
  })
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }
      if (prop.layout === '/teams') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const sidebarMinimize = () => {
    setMiniActive(!miniActive)
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  return (
    <div className={classes.wrapper}>
      <div className={mainPanelClasses} ref={mainPanel}>
        <TeamsNavbar
          signOut={props.signOut}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>{getRoutes(props.teamsMenu)}</Switch>
          </div>
        </div>
        <Footer fluid />
      </div>
    </div>
  )
}
