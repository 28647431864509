import React, { useState, useEffect } from 'react'

import moment from 'moment'
import { useSelector, shallowEqual } from 'react-redux'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp'

import CheckIcon from '@material-ui/icons/Check'
import AssessmentIcon from '@material-ui/icons/Assessment'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'

import NavPills from 'components/NavPills/NavPills.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import CustomLogComponent from 'components/CustomLogComponent/CustomLogComponent.js'
import CustomExecutionsComponent from 'components/CustomExecutionsComponent/CustomExecutionsComponent.js'
import ExpiredDiv from 'components/ExpiredDiv/ExpiredDiv'
import SyncLoader from 'components/SyncLoader/SyncLoader.js'
import NotificationAlert from 'components/NotificationAlert/NotificationAlert'

// Constants
import { typeLoad, updateFrequency, loadStatus } from 'utils/Constants'

import {
  useGetAppList,
  useGetChildLoadsByLoadId,
  useGetAppLoadByAppAndId
} from 'hooks/useApp'

import { checkExpiration } from 'utils/functions'
// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js'

moment.locale(window.navigator.userLanguage || window.navigator.language)
const useStyles = makeStyles(styles)

function jsUcfirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

function formatLoads(loads) {
  return loads.map(load => {
    return {
      id: load.idLoad,
      load: load.params.name,
      destination: load.params.destination.nameConnection,
      type: typeLoad[load.params.type],
      startDate: moment(load.params.startDate).format('MM/DD/YYYY'),
      creationDate: load.params.creationDate,
      updateFrequency: updateFrequency[load.params.updateFrequency],
      status: loadStatus[load.status],
      parentLoad: load.parentLoad,
      statusProgress: load.statusProgress
    }
  })
}

const app = 'insights'

export default function InsightsLogs(props) {
  const params = useParams()
  const history = useHistory()

  const enabledAppsInvoice = useSelector(
    state => state.app.enabledApps[app],
    shallowEqual
  )
  const expirationData = checkExpiration(enabledAppsInvoice)

  const appId = params.id
  const typeApp = params.type
  const idLoad = params.idLoad

  /*  const queryClient = useQueryClient() */
  const classes = useStyles()

  /* const [submitSuccess, setSubmitSuccess] = useState(false)
  const [createError, setCreateError] = useState(false) */
  //const [validationMessage, setValidationMessage] = useState('')
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPage: 10,
    lastPage: -1,
    lastEvaluatedKeys: {}
  })

  const appList = useGetAppList(app)
  const parentLoad = useGetAppLoadByAppAndId({
    app,
    idLoad,
    environmentId: appId,
    typeApp
  })
  const childLoads = useGetChildLoadsByLoadId({
    app,
    type: typeApp,
    idLoad,
    environmentId: appId,
    format: formatLoads,
    pagination
  })

  useEffect(() => {
    if (!childLoads.data) {
      return
    }

    if (!childLoads.data?.lastEvaluatedKey) {
      setPagination(old => {
        return {
          ...old,
          lastPage: old.page
        }
      })
      return
    }

    setPagination(old => {
      return {
        ...old,
        lastEvaluatedKeys: {
          ...old.lastEvaluatedKeys,
          [old.page]: childLoads.data?.lastEvaluatedKey
        }
      }
    })
  }, [childLoads.data])

  let breadcrumbViews = [
    {
      name: 'Relish Insights',
      url: '/admin/nitorInsights/index',
      icon: AssessmentIcon
    },
    {
      name: jsUcfirst(typeApp) + ' Connection',
      url: `/admin/nitorInsights/${typeApp}/${appId}`,
      icon: CheckIcon
    },
    {
      name: 'Load Details',
      url: `/admin/nitorInsights/details/${appId}/${typeApp}/${idLoad}`,
      icon: CheckIcon
    }
  ]

  function returnLogsTab() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        {idLoad && appId ? (
          <CustomLogComponent
            transactionId={idLoad}
            environmentId={appId}
            app={app}
            enabled={expirationData ? expirationData.enabled : false}
          />
        ) : null}
      </GridItem>
    )
  }

  function onRender(id) {
    /* queryClient.invalidateQueries(['Loads'])
    queryClient.invalidateQueries(['Apps']) */
    setPagination({
      page: 0,
      rowsPage: 10,
      lastPage: -1,
      lastEvaluatedKeys: {}
    })
    history.push(`/admin/nitorInsights/details/${appId}/${typeApp}/${id}`)
  }

  function onGetChildLoads(params) {
    childLoads.refetch({ throwOnError: true })
  }

  function onChangeRowsPerPage(pageSize) {
    setPagination({
      page: 0,
      rowsPage: pageSize,
      lastEvaluatedKeys: []
    })
  }

  const handleChangePage = (event, newPage) => {
    setPagination(old => {
      return {
        ...old,
        page: newPage
      }
    })
  }

  function returnDetailsTab() {
    return (
      <GridItem md={12}>
        <GridContainer>
          <GridItem md={4}>
            <p>
              <b>Name:</b> {parentLoad.data.name}
            </p>
          </GridItem>
          <GridItem md={4}>
            <p>
              <b>Type:</b> {typeLoad[parentLoad.data.type]}
            </p>
          </GridItem>
          <GridItem md={4}>
            <p>
              <b>Update Frecuency:</b>{' '}
              {updateFrequency[parentLoad.data.updateFrequency]}
            </p>
          </GridItem>
          {parentLoad.data.type === 'scheduled' ? (
            <>
              {parentLoad.data.updateFrequency === 'daily' ? (
                <GridItem md={4}>
                  <p>
                    <b>Execution Time:</b> {parentLoad.data.executionTime}
                  </p>
                </GridItem>
              ) : null}
              {parentLoad.data.updateFrequency === 'weekly' &&
              parentLoad.data.updateFrequency === 'monthly' ? (
                <GridItem md={4}>
                  <p>
                    <b>Execution Day:</b> {parentLoad.data.executionDay}
                  </p>
                </GridItem>
              ) : null}
              {parentLoad.data.updateFrequency === 'everyNHours' ? (
                <GridItem md={4}>
                  <p>
                    <b>Execution:</b> Every {parentLoad.data.executionHour}{' '}
                    Hours
                  </p>
                </GridItem>
              ) : null}
              {parentLoad.data.updateFrequency === 'everyNMinutes' ? (
                <GridItem md={4}>
                  <p>
                    <b>Execution:</b> Every {parentLoad.data.executionMinute}{' '}
                    Minutes
                  </p>
                </GridItem>
              ) : null}
            </>
          ) : null}
          <GridItem md={4}>
            <p>
              <b>From Date:</b> {parentLoad.data.startDate}
            </p>
          </GridItem>
          <GridItem md={4}>
            <p>
              <b>Status:</b> {loadStatus[parentLoad.data.status]}
            </p>
          </GridItem>
          {childLoads.data?.rows.length === 0 ? (
            <GridItem md={12}>
              <p>
                <b>Status Progress:</b>
              </p>
              <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress
                    variant="determinate"
                    value={
                      parentLoad.data.statusProgress
                        ? parentLoad.data.statusProgress
                        : 0
                    }
                  />
                </Box>
                <Box minWidth={35}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >{`${Math.round(
                    parentLoad.data.statusProgress
                      ? parentLoad.data.statusProgress
                      : 0
                  )}%`}</Typography>
                </Box>
              </Box>
              <br />
            </GridItem>
          ) : null}
          <GridItem md={12}>
            <p>
              <b>Views:</b>{' '}
            </p>
            <List>
              {parentLoad.data.views
                ? parentLoad.data.views.map(view => (
                    <ListItem button key={view.view}>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography style={{ fontSize: '14px' }}>
                            {jsUcfirst(view.view)}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))
                : null}
            </List>
          </GridItem>
        </GridContainer>
        <SyncLoader
          showLoader={childLoads.isFetching && !childLoads.isLoading}
        />

        {childLoads.isError ? null : childLoads.data.rows.length > 0 ? (
          <CustomExecutionsComponent
            rows={childLoads.data.rows}
            enabled={expirationData ? expirationData.enabled : {}}
            render={onRender}
            childLoads={onGetChildLoads}
            isCustomPagination={true}
            pagination={pagination}
            pageSizeOptions={[10, 20, 30, 40, 50]}
            onChangeRowsPerPage={onChangeRowsPerPage}
            handleChangePage={handleChangePage}
          />
        ) : null}
      </GridItem>
    )
  }

  function returnTabsToDisplay() {
    let tabs = [
      {
        tabButton: 'Details',
        tabContent: parentLoad.data ? returnDetailsTab() : null
      }
    ]
    if (childLoads.data && childLoads.data?.rows.length === 0) {
      tabs.push({
        tabButton: 'Logs',
        tabContent: parentLoad.data ? returnLogsTab() : null
      })
    }
    return (
      <GridItem xs={12} sm={12} md={12}>
        <div style={{ position: 'relative' }}>
          <NotificationAlert
            variant="filled"
            severity="warning"
            appInfo={expirationData ?? {}}
          />
          <Card>
            <CardHeader>
              <h3 className={classes.formHeader}>Relish Insights - Details</h3>
            </CardHeader>
            <CardBody>
              <NavPills
                action={actions => {
                  if (actions) {
                    // props.updateHeightFunction(actions.updateHeight)
                  }
                }}
                animateHeight={false}
                color="primary"
                tabs={tabs}
              />
            </CardBody>
          </Card>
          <ExpiredDiv appInfo={expirationData ?? {}} />
        </div>
      </GridItem>
    )
  }

  function onAppChanged(id) {
    history.push(`/admin/nitorInsights/${typeApp}/${id}`)
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={3}>
        <Card className={classes.appBar}>
          <CardHeader>Application List</CardHeader>
          <CardBody>
            {appList.isLoading ? (
              <Card>
                <GridContainer justify="center">
                  <div className={classes.circularProgress}>
                    <CircularProgress color="inherit" />
                  </div>
                </GridContainer>
              </Card>
            ) : (
              <List>
                {appList.data.map((prop, key) => {
                  return (
                    <ListItem
                      className={classes.listItem}
                      button
                      selected={appId === prop.env}
                      key={key}
                      onClick={() => onAppChanged(prop.env)}
                    >
                      <ListItemAvatar>
                        {appId === prop.env ? (
                          <Avatar>
                            <TouchApp />
                          </Avatar>
                        ) : (
                          <Avatar className={classes.clearAvatar}></Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={prop.appName}
                        secondary={`Type: ${prop.type}`}
                      />
                    </ListItem>
                  )
                })}
              </List>
            )}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={9}>
        <Breadcrumbs views={breadcrumbViews} />
        {childLoads.isLoading || parentLoad.isLoading ? (
          <Card>
            <GridContainer justify="center">
              <div className={classes.circularProgress}>
                <CircularProgress color="inherit" />
              </div>
            </GridContainer>
          </Card>
        ) : (
          returnTabsToDisplay()
        )}
      </GridItem>
    </GridContainer>
  )
}
