import TextField from '@material-ui/core/TextField'
import Button from 'components/CustomButtons/Button.js'
import DOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import Grid from '@material-ui/core/Grid'

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { makeStyles } from '@material-ui/core/styles'
import AddAlert from '@material-ui/icons/AddAlert'
import Snackbar from 'components/Snackbar/Snackbar'
import { useSaveInvoiceComment } from 'hooks/useInvoices'
import { useLocalStorageState } from 'hooks/useLocalStorage'
const gridStyle = { minHeight: 400, maxHeight: 700 }

const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
  autoHide: true,
  alwaysShowTrack: true,
  scrollThumbStyle: {
    background: '#1E408A'
  }
})

const useStyles = makeStyles(() => ({
  root: {
    height: '400px'
  }
}))

function escape(htmlStr = '') {
  return htmlStr
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
}

function generateTextWithLinks(text) {
  let result = text ?? ''
  let matches = result.match(/\bhttps?:\/\/\S+/gi)
  matches = [...new Set(matches)]

  // eslint-disable-next-line
  for (const match of matches) {
    const matchUpdated = match.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const reg = new RegExp(matchUpdated, 'g')
    result = result.replace(reg, '<a href="' + match + '">' + match + '</a>')
  }

  return { __html: DOMPurify.sanitize(result) }
}

export default function CommentsView(props) {
  const columns = [
    {
      header: 'key',
      name: 'key',
      defaultFlex: 1,
      minWidth: 100,
      editable: false,
      defaultVisible: false
    },
    {
      name: 'comment',
      header: 'Comments',
      defaultFlex: 1,
      editable: false,
      render: ({ value }) => {
        const splitName = (value.name ?? 'Relish User').split(' ')
        const avatarName = `${splitName[0][0]}${splitName[1][1]}`.toLowerCase()
        return (
          <Grid container>
            <Grid item xs={1} sm={1} md={1}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <img
                  src={
                    value.picture ??
                    `https://i1.wp.com/cdn.auth0.com/avatars/${avatarName}.png?ssl=1`
                  }
                  style={{ borderRadius: '50%', width: '30%' }}
                  alt={value.name}
                />
              </div>
            </Grid>
            <Grid item xs={11} sm={11} md={11}>
              <Grid item xs={12} sm={12} md={12}>
                <p style={{ fontSize: '80%' }}>
                  <b>{value.userName}</b>{' '}
                  {`  commented  ${printCommentDate(new Date(value.creationDate))}`}
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div
                  style={{
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word'
                  }}
                >
                  <p dangerouslySetInnerHTML={generateTextWithLinks(value.comment)}></p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    }
  ]

  const queryClient = useQueryClient()
  const classesGridHeaderContainer = useStyles()
  const [currentComment, setCurrentComment] = useState('')

  const { appId, invoiceId, disabled, commentsInformation } = props

  const {
    isLoading: isLoadingComments,
    isError: isErrorLoading,
    data: comments
  } = commentsInformation

  const [userInfo] = useLocalStorageState('userInfo', {})

  const { mutate: postComment } = useSaveInvoiceComment({ appId, invoiceId })
  const [createError, setCreateError] = useState({ message: '', isOpen: false })

  useEffect(() => {
    if (!isLoadingComments && isErrorLoading) {
      setCreateError({
        message: "Unable to get Invoice's comments",
        isOpen: true
      })
    }
  }, [isLoadingComments, isErrorLoading])

  const printCommentDate = creationTime => {
    let hours = creationTime.getHours()
    let minutes = creationTime.getMinutes()
    let day = creationTime.getDate()
    const month = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ][creationTime.getMonth()]
    const ampm = hours >= 12 ? 'pm' : 'am'

    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes
    day = day < 10 ? `0${day}` : day
    return `${day} ${month} ${creationTime.getFullYear()}     at    ${hours}:${minutes} ${ampm}`
  }

  function saveComment() {
    postComment(
      {
        comment: escape(DOMPurify.sanitize(currentComment)),
        email: userInfo.email,
        userName: userInfo.name,
        picture: userInfo.picture,
        userId: userInfo.sub
      },
      {
        onError: () =>
          setCreateError({
            message: "Unable to save Invoice's comment",
            isOpen: true
          }),
        onSettled: () => {
          queryClient.invalidateQueries(['apps', appId, 'invoices', invoiceId, 'comments'])
        },
        onSuccess: () => {
          setCurrentComment('')
        }
      }
    )
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            autoFocus
            margin="dense"
            id="comment"
            label="Add your comment"
            type="textarea"
            multiline={true}
            rows="3"
            fullWidth
            value={currentComment}
            onChange={event => {
              if (event.target.value.length <= 200) {
                setCurrentComment(event.target.value)
              }
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color="primary"
            disabled={currentComment === ''}
            onClick={() => {
              saveComment()
            }}
          >
            Comment
          </Button>
          <Button
            color="primary"
            disabled={currentComment === ''}
            onClick={() => {
              setCurrentComment('')
            }}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12} className={classesGridHeaderContainer.root}>
          <ReactDataGrid
            idProperty="key"
            dataSource={comments ?? []}
            pagination
            scrollProps={scrollProps}
            sortable={false}
            style={gridStyle}
            minRowHeight={80}
            rowHeight={null}
            columns={columns}
            showColumnMenuTool={false}
            defaultLimit={10}
            loading={isLoadingComments}
          />
        </Grid>

        <Snackbar
          place="bl"
          color={'danger'}
          icon={AddAlert}
          message={createError.message}
          open={createError.isOpen}
          closeNotification={() => setCreateError({ isOpen: false, message: '' })}
          close
        />
      </Grid>
    </>
  )
}
