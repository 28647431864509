import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Snackbar from 'components/Snackbar/Snackbar.js'

import GridOnIcon from '@material-ui/icons/GridOn'
import ViewWeekIcon from '@material-ui/icons/ViewWeek'
import AddAlert from '@material-ui/icons/AddAlert'

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { useGetTenantConfig } from 'hooks/useTenantConfig'
import {
  useCreateImportInvoices,
  useStartImportInvoices,
  useUploadImportInvoices
} from 'hooks/useImportInvoices'

function csvToArray(text) {
  let p = '',
    row = [''],
    ret = [row],
    i = 0,
    r = 0,
    s = !0,
    l
  for (l of text) {
    if ('"' === l) {
      if (s && l === p) row[i] += l
      s = !s
    } else if (',' === l && s) l = row[++i] = ''
    else if ('\n' === l && s) {
      if ('\r' === p) row[i] = row[i].slice(0, -1)
      row = ret[++r] = [(l = '')]
      i = 0
    } else row[i] += l
    p = l
  }
  return ret
}

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: 'none'
  },
  btnColorPrimary: {
    color: '#081c3e',
    border: '1px solid #081c3e',
    '&:hover': {
      backgroundColor: '#081c3e',
      color: 'white',
      border: '1px solid #081c3e'
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  divider: {
    borderRight: `2px solid ${theme.palette.divider}`
  },
  btnBgColorPrimary: {
    backgroundColor: '#081c3e',
    '&:hover': {
      backgroundColor: '#1E408A'
    }
  }
}))

const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
  autoHide: true,
  alwaysShowTrack: true,
  scrollThumbStyle: {
    background: '#1E408A'
  }
})

const gridStyle = { minHeight: 900 }

const fileReader = new FileReader()

const standardFields = [
  'INVOICE_RECEIPT_ID',
  'INVOICE_TYPE',
  'INVOICE_RECEIPT_DATE',
  'VENDOR_ID',
  'VENDOR_NAME',
  'COMPANY_CODE',
  'PURCHASE_ORDER',
  'CURRENCY',
  'SUBTOTAL',
  'TAX',
  'DISCOUNT',
  'TOTAL'
]

const standardLineItems = [
  'LINE_NUMBER',
  'PO_LINE_NUMBER',
  'ITEM',
  'SUPPLIER_PART_NUMBER',
  'QUANTITY',
  'UNIT_PRICE',
  'DISCOUNT',
  'PRICE',
  'TAX_AMOUNT',
  'TAX_PERCENTAGE'
]

/* const standardFields = Object.values(HeaderFieldsSide)

const standardLineItems = Object.values(ColumnsOrderLineItems) */

const filterValue = [{ name: 'standardField', operator: 'startsWith', type: 'string', value: '' }]

export default function InvoiceImportLoad() {
  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()
  const [headerKeys, setHeaderKeys] = useState([])
  // const [isLoadingSub, setIsLoadingFile] = useState(false)
  const { data: configTenant, isLoading: isLoadingTenantConfig } = useGetTenantConfig()
  const [createError, setCreateError] = useState({ isOpen: false, message: '' })
  const useMutationInvoiceImport = useCreateImportInvoices(configTenant?.defaultApp)
  const uploadInvoiceImport = useUploadImportInvoices()
  const startInvoiceImport = useStartImportInvoices()
  const [file, setFile] = useState(null)

  const isLoadingSubmit =
    useMutationInvoiceImport.isLoading ||
    uploadInvoiceImport.isLoading ||
    startInvoiceImport.isLoading

  const [columnMapHeader, setColumMapHeader] = useState(() =>
    standardFields.map(x => ({
      standardField: x,
      fileColumn: { name: '' },
      type: 'fileColumn'
    }))
  )

  const [columnMapLineItems, setColumMapLineItems] = useState(() =>
    standardLineItems.map(x => ({
      standardField: x,
      fileColumn: { name: '' },
      type: 'fileColumn'
    }))
  )

  const handleOnChange = e => {
    const file = e.target.files[0]
    // setIsLoadingFile(true)
    if (file) {
      fileReader.onload = function(event) {
        const text = event.target.result
        csvFileToArray(text)
        setFile(file)
        setColumMapHeader(
          standardFields.map(x => ({
            standardField: x,
            fileColumn: { name: '' },
            type: 'fileColumn'
          }))
        )
        setColumMapLineItems(
          standardLineItems.map(x => ({
            standardField: x,
            fileColumn: { name: '' },
            type: 'fileColumn'
          }))
        )
        // setIsLoadingFile(false)
      }
      fileReader.readAsText(file)
    }
  }

  const handleChangeHeader = (value, key) => {
    setColumMapHeader(oldData => {
      const newData = [...oldData]
      const index = oldData.findIndex(x => x.standardField === key)
      if (index === -1) {
        return newData
      }

      newData[index] = { ...newData[index], fileColumn: { name: value } }
      return newData
    })
  }

  const handleChangeLineItems = (value, key) => {
    setColumMapLineItems(oldData => {
      const newData = [...oldData]
      const index = oldData.findIndex(x => x.standardField === key)
      if (index === -1) {
        return newData
      }

      newData[index] = { ...newData[index], fileColumn: { name: value } }
      return newData
    })
  }

  const breadcrumbViews = [
    {
      name: 'Imported Files',
      url: `/admin/invoiceAI/imports`,
      icon: GridOnIcon
    },
    {
      name: 'Import New File',
      url: `/admin/invoiceAI/upload/import`,
      icon: ViewWeekIcon
    }
  ]

  const columnsHeader = [
    {
      name: 'standardField',
      header: 'Standard Field',
      defaultFlex: 1,
      defaultVisible: true
    },
    {
      name: 'fileColumn',
      header: 'File Column',
      defaultFlex: 1,
      defaultVisible: true,
      render: ({ value, data }) => {
        return (
          <Autocomplete
            id="combo-box-demo"
            options={headerKeys}
            value={value.name}
            getOptionLabel={option => option}
            getOptionSelected={(option, value) => {
              //nothing that is put in here will cause the warning to go away
              if (value === '') {
                return true
              } else if (value === option) {
                return true
              }
            }}
            style={{ width: 300 }}
            renderInput={params => (
              <TextField {...params} placeholder="Choose a column" variant="outlined" />
            )}
            onChange={(e, value) => handleChangeHeader(value, data.standardField)}
          />
        )
      }
    }
  ]

  const columnsLineItems = [
    {
      name: 'standardField',
      header: 'Standard Field',
      defaultFlex: 1,
      defaultVisible: true
    },
    {
      name: 'fileColumn',
      header: 'File Column',
      defaultFlex: 1,
      defaultVisible: true,
      render: ({ value, data }) => {
        return (
          <Autocomplete
            id="combo-box-demo"
            options={headerKeys}
            value={value.name}
            getOptionLabel={option => option}
            getOptionSelected={(option, value) => {
              if (value === '') {
                return true
              } else if (value === option) {
                return true
              }
            }}
            style={{ width: 300 }}
            renderInput={params => (
              <TextField {...params} placeholder="Choose a column" variant="outlined" />
            )}
            onChange={(e, value) => handleChangeLineItems(value, data.standardField)}
          />
        )
      }
    }
  ]

  const csvFileToArray = string => {
    const csvHeader = csvToArray(string.slice(0, string.indexOf('\n')))
    setHeaderKeys(csvHeader[0].map(x => x.trim()))
  }

  async function handleSubmit() {
    try {
      const response = await useMutationInvoiceImport.mutateAsync({
        fileName: file.name,
        fileColumns: headerKeys.map(x => ({ name: x })),
        header: columnMapHeader,
        lineItems: columnMapLineItems
      })

      const importId = response.data.newImportFile.importId
      await uploadInvoiceImport.mutateAsync({
        file,
        presignedUploadUrl: response.data.presignedUrl
      })

      await startInvoiceImport.mutateAsync({
        appId: configTenant?.defaultApp,
        importId
      })
      queryClient.resetQueries(['import', 'invoices'])
      history.push(`/admin/invoiceAI/import/${importId}/details`)
    } catch (error) {
      queryClient.resetQueries(['import', 'invoices'])
      setCreateError({
        isOpen: true,
        message: 'Something went wrong, try again later',
        color: 'danger'
      })
    }
  }

  return (
    <Grid container>
      <Card>
        <Breadcrumbs views={breadcrumbViews} />
        <CardHeader>
          <Grid container justifyContent="center">
            <h2>Import New File</h2>
          </Grid>
        </CardHeader>
        <CardBody>
          <Grid item xs={12} style={{ marginBottom: '2rem' }}>
            <Grid container justifyContent="center">
              <form>
                <input
                  accept={'.csv'}
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  onChange={handleOnChange}
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  <Button
                    color="primary"
                    type="button"
                    variant="outlined"
                    className={classes.btnColorPrimary}
                    style={{ marginRight: '5px' }}
                    component="span"
                  >
                    Load
                  </Button>
                </label>
              </form>
              <Button
                color="primary"
                type="button"
                variant="contained"
                disabled={isLoadingSubmit || isLoadingTenantConfig}
                className={classes.btnBgColorPrimary}
                onClick={() => handleSubmit()}
              >
                {isLoadingSubmit ? 'Submitting..' : 'Submit'}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid
              item
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className={classes.divider}
            >
              <Grid item container justifyContent="center" xs={12}>
                <h3>Header</h3>
              </Grid>
              <Grid item xs={12}>
                <ReactDataGrid
                  idProperty="standardField"
                  columns={columnsHeader}
                  dataSource={columnMapHeader}
                  style={gridStyle}
                  enableKeyboardNavigation={false}
                  scrollProps={scrollProps}
                  activeIndexThrottle={300}
                  showZebraRows={false}
                  minRowHeight={100}
                  rowHeight={null}
                  defaultFilterValue={filterValue}
                />
              </Grid>
            </Grid>

            <Grid item container justifyContent="center" xs={12} sm={12} md={12} lg={6}>
              <Grid item container justifyContent="center" xs={12}>
                <h3>Line Items</h3>
              </Grid>
              <Grid item xs={12}>
                <ReactDataGrid
                  idProperty="standardField"
                  columns={columnsLineItems}
                  dataSource={columnMapLineItems}
                  style={gridStyle}
                  enableKeyboardNavigation={false}
                  scrollProps={scrollProps}
                  activeIndexThrottle={300}
                  showZebraRows={false}
                  minRowHeight={100}
                  rowHeight={null}
                  defaultFilterValue={filterValue}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardBody>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={createError.message}
          open={createError.isOpen}
          closeNotification={() => setCreateError({ isOpen: false, message: '' })}
          close
        />
      </Card>
    </Grid>
  )
}
