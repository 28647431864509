import {
  primaryColor,
  hexToRgb,
  blackColor
} from 'assets/jss/material-dashboard-pro-react.js'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.js'
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.js'
const nitorConnectStyle = theme => ({
  noPadding: { padding: '0 !important' },
  padding5: { padding: '5px !important' },
  padding10: { padding: '10px !important' },
  appBar: {
    overflow: 'auto',
    '@media (min-width: 1300px)': {
      height: '100%'
    }
  },
  listItem: {
    color: primaryColor[0],
    flexGrow: 1
  },
  listItemText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  circularProgress: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  clearAvatar: {
    backgroundColor: 'transparent'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    //display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center'
    padding: '70px 10px 0 10px'
  },
  floatRight: {
    float: 'right'
  },
  bgColorPrimary: {
    backgroundColor: '#081c3e'
  },
  reloadSize: {
    width: '100%',
    height: '240px',
    textAlign: 'center',
    padding: '50px'
  },
  formHeader: {
    textAlign: 'center'
  },
  textErrors: {
    '&:hover': {
      color: '#b31212'
    },
    '&:focus': {
      color: '#b31212'
    },
    color: 'red',
    padding: '0',
    fontSize: '9px',
    textTransform: 'capitalize'
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.26)',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex'
  },
  circularProgressDefaultApp: {
    height: '100%'
  },
  colorPrimary: {
    color: '#081c3e'
  },
  ...modalStyle(theme),
  ...buttonStyle,
  ...customSelectStyle
})

export default nitorConnectStyle
