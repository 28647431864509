import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { TextField, FormControl, Select, MenuItem } from '@material-ui/core'

// Form Validation
import { Controller } from 'react-hook-form'

const useStyles = makeStyles(theme => ({
  otherFields: {
    flex: '0 0 50%',
    display: 'flex',
    margin: '2rem 0 0 0',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formControl: {
    minWidth: 100,
    maxWidth: 100,
    margin: ' 0 2rem 0 1rem'
  }
}))

export const OtherField = props => {
  const {
    notRecognizedField,
    control,
    setTextInputRef,
    onChangeField,
    index,
    selectOptions
  } = props

  const classes = useStyles()

  selectOptions.sort((a, b) => {
    let fa = a.value.toLowerCase(),
      fb = b.value.toLowerCase()

    if (fa < fb) {
      return -1
    }
    if (fa > fb) {
      return 1
    }
    return 0
  })
  const selectOptionsJSX = selectOptions.map(selectOption => {
    return (
      <MenuItem key={selectOption.value} value={selectOption.value}>
        {selectOption.label}
      </MenuItem>
    )
  })

  const label =
    notRecognizedField.label?.length > 15
      ? `${notRecognizedField.label.substring(0, 15)}...`
      : notRecognizedField.label
  return (
    <div className={classes.otherFields} key={notRecognizedField.id}>
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            className={classes.otherFiled}
            id={notRecognizedField.id}
            label={label ?? ''}
            variant="outlined"
            size="small"
            onMouseOut={e =>
              onChangeField(e, {
                current: -1,
                previus: notRecognizedField.id,
                page: notRecognizedField.page
              })
            }
            onMouseOver={e =>
              onChangeField(e, {
                current: notRecognizedField.id,
                previus: notRecognizedField.id,
                page: notRecognizedField.page
              })
            }
            inputRef={element =>
              setTextInputRef(element, notRecognizedField.id)
            }
          />
        )}
        name={`otherFields.${index}.value`}
        control={control}
      />
      <FormControl variant="standard" className={classes.formControl}>
        <Controller
          render={({ field }) => (
            <Select label="Field Type" {...field}>
              <MenuItem value={''}>None</MenuItem>
              {selectOptionsJSX}
            </Select>
          )}
          name={`otherFields.${index}.type`}
          control={control}
        />
      </FormControl>
    </div>
  )
}
