import { useDebounce } from 'hooks/useDebounce'
import { useGetInvoiceOcrFieldValues } from 'hooks/useOcrInvoice'
import React from 'react'
import { useWatch } from 'react-hook-form'
import { HeaderFieldsStandard } from 'utils/Constants'
import AutoCompleteAsyncHeader from './AutoCompleteAsyncHeader'

const AutoCompleteAsyncValuesHeader = props => {
  const { headerField, headerFieldsActions, control } = props

  const [filter, setFilter] = React.useState('')
  const [debouncedValue] = useDebounce(filter, 500)

  const value = useWatch({
    name: 'headerFields',
    control
  })
  const { deleteErrorHeader } = headerFieldsActions
  const process = value.find(field => field.field === HeaderFieldsStandard.process.type)
  const field = value.find(field => field.field === headerField.field)

  const filedValues = useGetInvoiceOcrFieldValues(headerField.field, {
    filter: debouncedValue,
    level: 'header',
    status: 'enabled',
    process: process.value
  })

  const valueFilter = field?.value ?? ''

  React.useEffect(() => {
    setFilter(valueFilter)
  }, [valueFilter])

  function onChangeAutoComplete(data) {
    deleteErrorHeader({
      field: headerField.field
    })
  }

  return (
    <AutoCompleteAsyncHeader
      {...props}
      filter={filter}
      setFilter={setFilter}
      isLoading={filedValues.isLoading || filedValues.isFetching}
      options={filedValues.data}
      onChangeAutoComplete={onChangeAutoComplete}
    />
  )
}

export default AutoCompleteAsyncValuesHeader
