import React from 'react'
// Router
import { useParams } from 'react-router'

// Constants
import {
  frequency,
  insightsDeluxeTitles,
  transactionLogType,
  transactionStatus
} from 'utils/Constants'

// Icons

import CheckIcon from '@material-ui/icons/Check'
import AssessmentIcon from '@material-ui/icons/Assessment'

import InsightsDetails from 'components/Configuration/Details/InsightsDetails'
import { useGetAppConfigurationById } from 'hooks/useApp'
import { useGetTransaction } from 'hooks/useInvoiceConf'
import moment from 'moment'
import { statusColor } from 'utils/functions'

export default function WorkdayTransactionDetail() {
  const { group, appId, transactionId } = useParams()

  const app = useGetAppConfigurationById(appId)

  const transaction = useGetTransaction({
    appId: app.data?.data.insightsAppId,
    transactionId,
    options: { refetchOnWindowFocus: false }
  })

  const breadcrumbViews = [
    {
      name: 'Invoice AI',
      url: '/admin/invoiceAI/configurations',
      icon: AssessmentIcon
    },
    {
      name: insightsDeluxeTitles[group] + ' Connection',
      url: `/admin/invoiceAI/workday/${group}/${appId}`,
      icon: CheckIcon
    },
    {
      name: 'Transaction Details',
      url: `/admin/invoiceAI/workday/detail/${group}/${appId}/${transactionId}`,
      icon: CheckIcon
    }
  ]

  const columnsLogsView = [
    {
      field: 'id',
      title: 'Id',
      hidden: true
    },
    {
      field: 'type',
      title: 'Type',
      render: rowData => transactionLogType[rowData.type]
    },
    {
      field: 'message',
      title: 'Message'
    },
    {
      field: 'transactionLogDate',
      title: 'Date',
      render: rowData => moment(rowData.transactionLogDate).format('MM/DD/YYYY HH:mm:ss'),
      customSort: (a, b) => {
        return moment(moment(b.transactionLogDate)).isBefore(moment(a.transactionLogDate)) ? 1 : -1
      },
      defaultSort: 'asc'
    }
  ]
  const columnsDetailView = [
    {
      field: 'id',
      title: 'Id',
      hidden: true
    },
    {
      field: 'transaction',
      title: 'Transaction'
    },
    {
      field: 'destination',
      title: 'Destination'
    },
    {
      field: 'fromDate',
      title: 'From Date',
      render: rowData => moment(rowData.fromDate).format('MM/DD/YYYY')
    },
    {
      field: 'creationDate',
      title: 'Creation Date',
      render: rowData => moment(rowData.creationDate).format('MM/DD/YYYY HH:mm'),
      customSort: (a, b) => {
        return moment(moment(b.creationDate)).isBefore(moment(a.creationDate)) ? 1 : -1
      },
      defaultSort: 'desc'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: statusColor(rowData.status) }}>{transactionStatus[rowData.status]}</p>
      )
    },
    {
      field: 'frequency',
      title: 'Frequency',
      render: rowData => frequency[rowData.frequency]
    }
  ]

  return (
    <InsightsDetails
      transactionId={transactionId}
      group={group}
      appId={appId}
      breadcrumbViews={breadcrumbViews}
      columnsDetailView={columnsDetailView}
      columnsLogsView={columnsLogsView}
      app={app}
      transaction={transaction}
    />
  )
}
