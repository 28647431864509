import moment from 'moment'
import React from 'react'
// Router
import { useParams } from 'react-router'

// Functions
import { statusColor } from 'utils/functions'

// Constants
import { transactionStatus, frequency, fieldsByService, InputDefault } from 'utils/Constants'

import InvoiceGroup from '../../../components/Configuration/InvoiceGroup'

import { useGetAppConfigurationById } from 'hooks/useApp'
import * as yup from 'yup'
export default function InvoiceGroupWorkday() {
  const { group, appId } = useParams()
  const app = useGetAppConfigurationById(appId)
  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: true
    },
    {
      field: 'transaction',
      title: 'Transaction'
    },
    {
      field: 'destination',
      title: 'Destination'
    },
    {
      field: 'fromDate',
      title: 'From Date',
      render: rowData => moment(rowData.fromDate).format('MM/DD/YYYY')
    },
    {
      field: 'creationDate',
      title: 'Creation Date',
      render: rowData => moment(rowData.creationDate).format('MM/DD/YYYY HH:mm'),
      customSort: (a, b) => {
        return moment(moment(b.creationDate)).isBefore(moment(a.creationDate)) ? 1 : -1
      },
      defaultSort: 'desc'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: statusColor(rowData.status) }}>{transactionStatus[rowData.status]}</p>
      )
    },
    {
      field: 'frequency',
      title: 'Frequency',
      render: rowData => frequency[rowData.frequency]
    }
  ]

  const schema = {
    restConnectionApi: yup.object().shape({
      type: yup.string().required(),
      siteUrl: yup.string().required(),
      workdayTenant: yup.string().optional(),
      refreshToken: yup.string().optional(),
      user: yup.string().when('type', (type, schema) => {
        switch (type) {
          case 'basic':
            return schema.min(1).required()
          default:
            return schema.optional()
        }
      }),
      password: yup.string().when('type', (type, schema) => {
        switch (type) {
          case 'basic':
            return schema.min(1).required()
          default:
            return schema.optional()
        }
      })
    }),
    soapConnectionApi: yup.object().shape({
      type: yup.string().required(),
      siteUrl: yup.string().required(),
      user: yup.string().when('type', (type, schema) => {
        switch (type) {
          case 'basic':
            return schema.min(1).required()
          default:
            return schema.optional()
        }
      }),
      password: yup.string().when('type', (type, schema) => {
        switch (type) {
          case 'basic':
            return schema.min(1).required()
          default:
            return schema.optional()
        }
      })
    }),
    raasApi: yup.object().shape({
      type: yup.string().required(),
      siteUrl: yup.string().required(),
      user: yup.string().when('type', (type, schema) => {
        switch (type) {
          case 'basic':
            return schema.min(1).required()
          default:
            return schema.optional()
        }
      }),
      password: yup.string().when('type', (type, schema) => {
        switch (type) {
          case 'basic':
            return schema.min(1).required()
          default:
            return schema.optional()
        }
      }),
      name: yup.string().required(),
      concatChar: yup.string().required(),
      workTags: yup.array().optional()
    })
  }

  const connectionObj = {
    restConnectionApi: {
      user: '',
      workdayTenant: '',
      refreshToken: '',
      password: '',
      siteUrl: '',
      type: ''
    },
    soapConnectionApi: {
      user: '',
      password: '',
      siteUrl: '',
      type: ''
    },
    raasApi: {
      user: '',
      password: '',
      siteUrl: '',
      type: 'basic',
      name: '',
      concatChar: '',
      workTags: []
    }
  }

  if (
    !app.isLoading &&
    !app.isError &&
    app.data?.data.params.workday &&
    app.data?.data.params.workday[group]
  ) {
    switch (group) {
      case 'restConnectionApi':
        defaultDataRest()
        break
      case 'soapConnectionApi':
        defaultDataSoap()
        break
      case 'raasApi':
        defaultDataRaas()
        break
      default:
        break
    }
  }
  function defaultDataRest() {
    switch (app.data?.data.params.workday?.[group]?.type) {
      case 'basic':
        connectionObj[group].siteUrl = app.data?.data.params.workday?.[group]?.siteUrl
        connectionObj[group].type = app.data?.data.params.workday?.[group]?.type
        connectionObj[group].workdayTenant = app.data?.data.params.workday[group].workdayTenant
        connectionObj[group].refreshToken = app.data?.data.params.workday[group].refreshToken
        connectionObj[group].user = app.data?.data.params.workday[group].basicParams.user
        connectionObj[group].password = app.data?.data.params.workday[group].basicParams.password

        break
      default:
        break
    }
  }
  function defaultDataSoap() {
    switch (app.data?.data.params.workday?.[group]?.type) {
      case 'basic':
        connectionObj[group].siteUrl = app.data?.data.params.workday?.[group]?.siteUrl ?? ''
        connectionObj[group].type = app.data?.data.params.workday?.[group]?.type ?? ''
        connectionObj[group].user = app.data?.data.params.workday?.[group]?.basicParams.user ?? ''
        connectionObj[group].password =
          app.data?.data.params.workday?.[group]?.basicParams?.password ?? ''

        break
      default:
        break
    }
  }

  function defaultDataRaas() {
    switch (app.data?.data.params.workday?.[group]?.type) {
      case 'basic':
        connectionObj[group].siteUrl = app.data?.data.params.workday?.[group]?.siteUrl ?? ''
        connectionObj[group].type = app.data?.data.params.workday?.[group]?.type ?? ''
        connectionObj[group].user = app.data?.data.params.workday?.[group]?.basicParams.user ?? ''
        connectionObj[group].password =
          app.data?.data.params.workday?.[group]?.basicParams?.password ?? ''
        connectionObj[group].workTags =
          app.data?.data?.params?.workdayAdditionalParams?.accounting?.workTags ?? []
        connectionObj[group].name =
          app.data?.data?.params?.workdayAdditionalParams?.accounting?.accountType?.name ?? ''
        connectionObj[group].concatChar =
          app.data?.data?.params?.workdayAdditionalParams?.accounting?.accountType?.concatChar ?? ''
        break
      default:
        break
    }
  }
  let fields
  if (app.data?.data?.service) {
    fields = fieldsByService[(app.data?.data?.service)][group]
  }

  function modifyDataRest(data) {
    let newData = {}
    switch (data.type) {
      case 'basic':
        newData = {
          group: group,
          service: app.data?.data.service,
          workday: {
            [group]: {
              siteUrl: data.siteUrl,
              type: data.type,
              workdayTenant: data.workdayTenant,
              refreshToken: data.refreshToken,
              connectionType: 'rest',
              basicParams: {}
            }
          }
        }
        if (data.user !== InputDefault) {
          newData.workday[group].basicParams.user = data.user
        }
        if (data.password !== InputDefault) {
          newData.workday[group].basicParams.password = data.password
        }
        break
      default:
        break
    }
    return newData
  }

  function modifyDataSoap(data) {
    let newData = {}
    switch (data.type) {
      case 'basic':
        newData = {
          group: group,
          service: app.data?.data.service,
          workday: {
            [group]: {
              siteUrl: data.siteUrl,
              type: data.type,
              connectionType: 'soap',
              basicParams: {}
            }
          }
        }
        if (data.user !== InputDefault) {
          newData.workday[group].basicParams.user = data.user
        }
        if (data.password !== InputDefault) {
          newData.workday[group].basicParams.password = data.password
        }
        break
      default:
        break
    }
    return newData
  }

  function modifyDataRaas(data) {
    let newData = {}

    switch (data.type) {
      case 'basic':
        let accountingConfig
        if (data.workTags) {
          accountingConfig = {
            accountType: {
              name: data.name,
              concatChar: data.concatChar
            },

            workTags: data.workTags
          }
        }
        newData = {
          group: group,
          service: app.data?.data.service,
          workday: {
            [group]: {
              siteUrl: data.siteUrl,
              type: data.type,
              connectionType: 'rest',
              basicParams: {}
            }
          },
          accountingConfig
        }

        if (data.user !== InputDefault) {
          newData.workday[group].basicParams.user = data.user
        }
        if (data.password !== InputDefault) {
          newData.workday[group].basicParams.password = data.password
        }
        break
      default:
        break
    }

    return newData
  }
  function modifyData(data) {
    let newData = {}
    switch (group) {
      case 'restConnectionApi':
        newData = modifyDataRest(data)
        break
      case 'soapConnectionApi':
        newData = modifyDataSoap(data)
        break
      case 'raasApi':
        newData = modifyDataRaas(data)
        break
      default:
        break
    }
    return newData
  }
  return (
    <InvoiceGroup
      columns={columns}
      group={group}
      appId={appId}
      path={{
        main: `/admin/invoiceAI/workday/${group}/${appId}`,
        addTransaction: `/admin/invoiceAI/workday/transaction/${group}/${appId}`,
        edit: `/admin/invoiceAI/workday/transaction/${group}/${appId}`,
        details: `/admin/invoiceAI/workday/detail/${group}/${appId}`
      }}
      isLoading={app.isLoading}
      schema={schema[group]}
      connectionObj={connectionObj[group]}
      fields={fields}
      modifyData={modifyData}
      connected={app.data?.data.params.workday && app.data?.data.params.workday[group]}
    />
  )
}
