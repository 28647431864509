import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from 'assets/jss/material-dashboard-pro-react/components/emailModalStyle'
import { makeStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import DialogContentText from '@material-ui/core/DialogContentText'

const useStyles = makeStyles(styles)

export function ManualAssignmentDialog(props) {
  const classes = useStyles()
  const { onClose, open, onSubmmit } = props
  const [value, setValue] = useState('')

  const handleClose = () => {
    onClose()
  }

  const fieldChange = value => {
    setValue(value)
  }

  const onSubmmitAssign = () => {
    onSubmmit('manuallyIntegrated', value)
    setValue('')
    handleClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle id="simple-dialog-title">Manually Integration</DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          To manually integrate this payment, please enter your comments here.
          We will not send this payment to Paypal's Drop Zone.
        </DialogContentText>
        <TextField
          label="Comment"
          multiline
          value={value}
          onChange={e => fieldChange(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!value}
          variant="outlined"
          onClick={() => onSubmmitAssign()}
        >
          Agree
        </Button>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ManualAssignmentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmmit: PropTypes.func.isRequired
}
