/*eslint-disable*/
import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import { CircularProgress, Select, MenuItem } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
// material ui icons
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'
import AddAlert from '@material-ui/icons/AddAlert'
import Close from '@material-ui/icons/Close'

// core components
import Heading from 'components/Heading/Heading.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'

// Timezone picker
import SelectTimezoneMaterialUi from 'select-timezone-material-ui'

// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/User/createStyle.js'

const useStyles = makeStyles(styles)

export default function createView (props) {
  const classes = useStyles()
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            App*
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={props.team[`environment`]}
                            onChange={e =>
                              props.onFieldChange(`environment`, e.target.value)
                            }
                            inputProps={{
                              name: `environment`,
                              id: `environment`
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              Select the app
                            </MenuItem>
                            {props.appList.map((app, index) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                style={{
                                  overflowX: 'auto',
                                  textOverflow: 'ellipsis'
                                }}
                                value={app.env}
                                key={index}
                              >
                                {app.env}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Document Type*
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={props.team[`documentType`]}
                            onChange={e =>
                              props.onFieldChange(
                                `documentType`,
                                e.target.value
                              )
                            }
                            inputProps={{
                              name: `documentType`,
                              id: `documentType`
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              Select the document type
                            </MenuItem>
                            {[
                              { name: 'Requisition', value: 'requisitions' },
                              { name: 'Invoice', value: 'invoices' }
                            ].map((documentType, index) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                style={{
                                  overflowX: 'auto',
                                  textOverflow: 'ellipsis'
                                }}
                                value={documentType.value}
                                key={index}
                              >
                                {documentType.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                          success={props.teamState.documentId === 'success'}
                          error={props.teamState.documentId === 'error'}
                          labelText="Document Number*"
                          id="inputDocumentNumber"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              props.onFieldChange(
                                'documentId',
                                event.target.value
                              )
                            },
                            type: 'text',
                            value: props.team.documentId,
                            endAdornment:
                              props.teamState['documentId'] === 'error' ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <div className={classes.formCategory}>
                  <small>*</small> Mandatory fields
                </div>
              </form>
            </CardBody>
          </Card>
          <Snackbar
            place="br"
            color="success"
            icon={AddAlert}
            message="Team associated successfully"
            open={props.createSuccess}
            closeNotification={() => props.setCreateSuccess(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.createError}
            closeNotification={() => props.setCreateError(false)}
            close
          />
        </GridItem>
      </GridContainer>
    </div>
  )
}
