import { compose } from 'recompose'
import { connect } from 'react-redux'
import InvoiceImportView from './UserImportView'
import { updateHeightFunction } from 'store/AppState'

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      updateHeight: state.app.updateHeight
    }),
    { updateHeightFunction }
  )
)(InvoiceImportView)
