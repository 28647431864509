import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import {
  getAppConfigurationsByApp,
  createAppConfiguration,
  getAppConfigurationByAppAndId,
  editAppConfigurationByAppAndId,
  editAppConnectionConfigurationByApp,
  deleteAppConnectionConfigurationByApp
} from 'services/apiApps'
import { v4 as uuidv4 } from 'uuid'

//Views
import IntelligenceView from './IntelligenceView'

//Connection
import { validateAppConnection } from 'services/apiApps.js'

//Models
import { main as mainSchema } from 'models/intelligenceModel'
import { connection } from 'models/intelligenceModel'

//Utils
import { validateProperty, validateObject } from 'utils/validator'
import { InputDefault } from 'utils/Constants'

import { updateHeightFunction } from 'store/AppState'
import { showLoading } from 'utils/functions'
import { hideLoading } from 'utils/functions'

let _isMounted = false

const config = {
  environmentId: '',
  appName: '',
  type: 'Test',
  enabled: true,
  displayMode: 'create'
}

const configState = {
  environmentId: '',
  appName: '',
  type: '',
  enabled: ''
}

const configConnection = {
  connectionId: '',
  name: '',
  fileExtension: '.json',
  unzip: false,
  type: 'intelligence',
  siteUrl: '',
  token: ''
}

const configConnectionState = {
  connectionId: '',
  name: '',
  fileExtension: '',
  unzip: '',
  type: '',
  siteUrl: '',
  token: ''
}

const fieldsType = {
  intelligence: ['siteUrl', 'token', 'fileExtension', 'unzip']
}

async function getAppList (props, callback, selectedApp = undefined) {
  let response = await getAppConfigurationsByApp('intelligence')

  if (response.success) {
    if (_isMounted) {
      props.setAppList(response.data)

      if (response.data.length !== 0) {
        // get app details
        if (!selectedApp) selectedApp = response.data[0].env
        await getAppDetails(props, selectedApp)
      }
    }
  }
  callback(props.setPageIsLoading)
}

async function getAppDetails (props, id) {
  props.setFormIsLoading(true)
  props.setSelectedAppId(id)

  let appDetails = await getAppConfigurationByAppAndId('intelligence', id)

  if (appDetails.success && _isMounted) {
    //Save complete object
    props.setConfig(appDetails.data)
  }
  props.setFormIsLoading(false)
}

function onNewCreateIsCalled (props) {
  props.setFormIsLoading(true)
  props.setDisplayMode('create')
  props.setSelectedAppId(null)

  props.setConfigState({ ...configState })
  props.setConfig({ ...config })

  setTimeout(function () {
    props.setFormIsLoading(false)
  }, 500)
}

function onNewCreateConnectionIsCalled (props) {
  props.setDisplayModeConnection('create')
  props.setConnectionConnected(undefined)
  props.setConnectionTesting(false)
  props.setConnectionMessage(undefined)

  props.setConfigConnectionState({ ...configConnectionState })
  props.setConfigConnection({ ...configConnection })
}

function onEditConnectionIsCalled (props, id, row) {
  row.connectionId = id
  props.setConnectionConnected(true)
  props.setConfigConnection({ ...row })
  props.setDisplayModeConnection('edit')
}

async function onDeleteConnectionIsCalled (props, id) {
  let response = {}
  let successMessage

  response = await deleteAppConnectionConfigurationByApp(
    'intelligence',
    props.selectedAppId,
    id
  )
  successMessage = 'App connection deleted successfully'

  if (response && response.success && _isMounted) {
    props.setValidationMessage(successMessage)
    props.setSubmitSuccess(true)

    await getAppList(props, hideLoading, props.selectedAppId)

    setTimeout(function () {
      if (_isMounted) {
        props.setSubmitSuccess(false)
      }
    }, 5000)
  } else {
    // Show error message
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
  props.setFormIsLoading(false)
}

async function onSubmit (props) {
  let successMessage
  let currentConfiguration = props.config
  let selectedApp

  // Update with the form values
  let appConfiguration = createConfigRequestBody(currentConfiguration, props)
  let response

  if (props.displayMode === 'create') {
    response = await createAppConfiguration('intelligence', appConfiguration)
    selectedApp = appConfiguration.env
    successMessage = 'App created successfully'
  } else {
    response = await editAppConfigurationByAppAndId(
      'intelligence',
      props.selectedAppId,
      appConfiguration
    )
    selectedApp = props.selectedAppId
    successMessage = 'App updated successfully'
  }

  if (response && response.success && _isMounted) {
    props.setValidationMessage(successMessage)
    props.setSubmitSuccess(true)

    if (props.displayMode === 'create') {
      await getAppList(props, hideLoading, selectedApp)
      props.setDisplayMode('edit')
    }
    props.setDisplayModeConnection('list')

    setTimeout(function () {
      if (_isMounted) {
        props.setSubmitSuccess(false)
      }
    }, 5000)
  } else {
    // Show error message
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

async function onSubmitConnection (props, configConnection) {
  let result = {}
  let successMessage
  let currentConfiguration = configConnection
  currentConfiguration.connectionId = currentConfiguration.connectionId
    ? currentConfiguration.connectionId
    : (currentConfiguration.connectionId = uuidv4())
  let response

  response = await editAppConnectionConfigurationByApp(
    'intelligence',
    props.selectedAppId,
    currentConfiguration
  )
  successMessage = 'App updated successfully'

  if (response && response.success && _isMounted) {
    await getAppDetails(props, props.selectedAppId)
    props.setSubmitSuccess(true)
    props.setValidationMessage(successMessage)
    props.setDisplayModeConnection('list')

    setTimeout(function () {
      if (_isMounted) {
        props.setSubmitSuccess(false)
      }
    }, 5000)
  } else {
    // Show error message
    if (result) {
      let message = result.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

function createConfigRequestBody (params, props) {
  // Create external params
  let requestBody = { ...params }
  if (props.displayMode === 'create') {
    requestBody.environmentId = uuidv4()
    requestBody.appType = 'intelligence'
  }
  requestBody.env = requestBody.environmentId
  if (!requestBody.params) {
    requestBody.params = {
      ariba: {
        realm: ""
      },
      remoteConnections: {}
    }
  } else {
    delete requestBody.params
  }

  return requestBody
}

function removeParameters (props, validFields) {
  let configConnection = { ...props.configConnection }
  Object.keys(configConnection).forEach(key => {
    keyExists(key, validFields) || delete configConnection[key]
  })

  let type = configConnection.type
  let bodyField
  for (bodyField of fieldsType[type]) {
    if (configConnection[bodyField] === InputDefault) {
      delete configConnection[bodyField]
    }
  }
  return configConnection
}

async function saveConnection (props, validFields) {
  props.setFormIsSaving(true)
  await onSubmitConnection(props, removeParameters(props, validFields))
  props.setConnectionConnected(undefined)
  props.setConnectionMessage(undefined)
  props.setConnectionTesting(false)
  props.setFormIsSaving(false)
}

function keyExists (key, arr) {
  return arr.some(function (el) {
    return el.key === key
  })
}

async function checkConnection (props, validFields) {
  props.setConnectionConnected(undefined)
  props.setConnectionTesting(true)
  props.setConnectionMessage(undefined)

  let type = props.configConnection.type

  let connectionData = removeParameters(props, validFields)

  let response = await validateAppConnection(
    'intelligence',
    connectionData,
    props.selectedAppId,
    type
  )
  let defaultError = {
    error: 'invalid_request',
    error_description: 'Connection Error'
  }
  defaultError = JSON.stringify(defaultError, null, '\t')

  if (response.success) {
    props.setConnectionConnected(response.data.valid)
    if (response.data.valid) {
      props.setConnectionMessage(undefined)
    } else {
      props.setConnectionMessage(
        JSON.stringify(response.data.data) || defaultError
      )
    }
  } else {
    props.setConnectionConnected(false)
    props.setConnectionMessage(defaultError)
  }
  props.setConnectionTesting(false)
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name
    }),
    { updateHeightFunction }
  ),
  withState('appList', 'setAppList', []),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('selectedApp', 'setSelectedApp', null),
  withState('config', 'setConfig', { ...config }),
  withState('configState', 'setConfigState', { ...configState }),
  withState('configConnection', 'setConfigConnection', {
    ...configConnection
  }),
  withState('configConnectionState', 'setConfigConnectionState', {
    ...configConnectionState
  }),
  withState('displayMode', 'setDisplayMode', 'edit'),
  withState('displayModeConnection', 'setDisplayModeConnection', 'list'),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('formIsSaving', 'setFormIsSaving', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('connectionConnected', 'setConnectionConnected', undefined),
  withState('connectionTesting', 'setConnectionTesting', false),
  withState('connectionMessage', 'setConnectionMessage', undefined),
  withState('showModal', 'setShowModal', false),
  withHandlers({
    onFieldChange: props => (field, value) => {
      props.config[field] = value
      let isValid = validateProperty(mainSchema, props.config, field).isValid
      if (isValid) {
        props.configState[field] = 'success'
      } else {
        props.configState[field] = 'error'
      }
      props.setConfigState(props.configState)
      props.setConfig(props.config)
    },
    onAppChanged: props => id => {
      getAppDetails(props, id)
      props.setConfigState({ ...configState })
      props.setDisplayMode('edit')
      props.setDisplayModeConnection('list')
    },
    onCreateButton: props => () => {
      if (props.displayMode !== 'create') {
        onNewCreateIsCalled(props)
      }
    },
    onCreateConnectionButton: props => () => {
      if (props.displayModeConnection !== 'create') {
        onNewCreateConnectionIsCalled(props)
      }
    },
    onConfigSave: props => async () => {
      props.setFormIsLoading(true)
      await onSubmit(props)
      props.setFormIsLoading(false)
    },
    onFieldChangeConnection: props => (field, value) => {
      props.configConnection[field] = value
      let isValid = validateProperty(connection, props.configConnection, field)
        .isValid
      if (isValid) {
        props.configConnectionState[field] = 'success'
      } else {
        props.configConnectionState[field] = 'error'
      }
      props.setConfigConnectionState(props.configConnectionState)
      let defaultParams = ['path', 'unzip', 'fileExtension', 'name']
      if (!defaultParams.includes(field)) {
        props.setConnectionConnected(undefined)
        props.setConnectionMessage(undefined)
      }
    },
    onSaveConnection: props => fieldsByType => {
      let validation = validateObject(connection, props.configConnection)
      if (!validation.isValid) {
        let field
        for (field in props.configConnection) {
          if (validation.errors[field]) {
            props.configConnectionState[field] = 'error'
          }
        }
        props.setConfigConnectionState(props.configConnectionState)
        return
      }
      let type = props.configConnection.type
      let validFields = [...fieldsByType[type], ...fieldsByType['default']]
      saveConnection(props, validFields)
    },
    onEditConnectionButton: props => (id, row) => {
      if (props.displayModeConnection !== 'edit') {
        onEditConnectionIsCalled(props, id, row)
      }
    },
    onDeleteConnectionButton: props => id => {
      if (props.displayModeConnection !== 'edit') {
        props.setFormIsLoading(true)
        onDeleteConnectionIsCalled(props, id)
      }
    },
    onCancelConnectionButton: props => () => {
      props.setDisplayModeConnection('list')
      props.setConfigConnectionState({ ...configConnectionState })
      props.setConnectionConnected(undefined)
      props.setConnectionMessage(undefined)
      props.setConnectionTesting(false)
    },
    onCheckConnection: props => fieldsByType => {
      let type = props.configConnection.type
      let validFields = [...fieldsByType[type], ...fieldsByType['default']]
      checkConnection(props, validFields)
    }
  }),
  lifecycle({
    componentDidMount () {
      _isMounted = true
      showLoading(this.props.setPageIsLoading)
      getAppList(this.props, hideLoading)
    }
  })
)(IntelligenceView)
