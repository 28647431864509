import React from 'react'
import MaterialTable from 'material-table'

// Material UI
import Grid from '@material-ui/core/Grid'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Constants

const useStyles = makeStyles(styles)

export default function EntitiesView(props) {
  const { selectedRows, setSelectedRows, entities } = props
  const classes = useStyles()

  function modifyText(text) {
    let camelCase = text
    if (camelCase.includes('_')) {
      camelCase = camelCase.replace(/([-_][a-z])/gi, $1 => {
        return $1
          .toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    }
    const result = camelCase.replace(/([A-Z])/g, ' $1')
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)

    return finalResult
  }

  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: true
    },
    {
      field: 'entity',
      title: 'Entity',
      render: rowData => modifyText(rowData.id)
    }
  ]

  function selectRows(rows, rowData) {
    let newSelectedRows = []
    if (rowData) {
      if (rowData?.tableData?.checked) {
        newSelectedRows = [...selectedRows, rowData]
      } else {
        newSelectedRows = selectedRows.filter(row => row.entity !== rowData.entity)
      }
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows(newSelectedRows)
    }
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <h4 className={classes.infoText}>Entities</h4>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MaterialTable
          columns={columns}
          data={entities}
          onSelectionChange={selectRows}
          options={{
            actionsColumnIndex: -1,
            sorting: true,
            showTitle: false,
            selection: true,
            showSelectAllCheckbox: false,
            selectionProps: rowData => {
              const selection = {
                color: 'primary'
              }
              if (selectedRows.find(row => row.entity === rowData.entity)) {
                selection.checked = true
              } else {
                selection.checked = false
              }
              return selection
            }
          }}
        />
      </Grid>
    </Grid>
  )
}
