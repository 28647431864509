import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'

// material-ui/icons
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'

// material-ui/core
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Fab from '@material-ui/core/Fab'
import { CircularProgress } from '@material-ui/core'

// Constants
import { logLevelValues } from 'utils/Constants'

//Services
import { getLoadLogsById } from 'services/apiApps'

import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js'
const useStyles = makeStyles(styles)

export default function CustomMaterialTable (props) {
  const didMount = useDidMount()
  const classes = useStyles()
  const [showModal, setShowModal] = useState(false)
  const [modalInfo, setModalInfo] = useState('')
  const [rows, setRows] = useState([])
  const [logIsLoading, setLogIsLoading] = useState(false)
  const { app, environmentId, transactionId, enabled } = props

  const columns = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1
    },
    {
      field: 'logLevel',
      title: 'Log Level',
      render: rowData => logLevelValues[rowData.logLevel]
    },
    {
      field: 'message',
      title: 'Message'
    },
    {
      field: 'timestamp',
      title: 'Timestamp',
      render: rowData => new Date(rowData.timestamp).toLocaleString(window.navigator.userLanguage || window.navigator.language)
    }
  ]

  function useDidMount () {
    const didMountRef = useRef(true)

    useEffect(() => {
      didMountRef.current = false
    }, [])
    return didMountRef.current
  }

  useEffect(() => {
    if (didMount) {
      setLogs()
    }
  })

  async function setLogs () {
    setLogIsLoading(true)
    await getLogs(app, environmentId, transactionId)
    setLogIsLoading(false)
  }

  async function getLogs (app, environmentId, transactionId) {
    const response = await getLoadLogsById(app, environmentId, transactionId)
    if (response.success) {
      const logs = response.data.logs
      setRows([...logs])
    }
  }

  async function onRefreshLogs () {
    setLogIsLoading(true)
    await getLogs(app, environmentId, transactionId)
    setLogIsLoading(false)
  }

  function modal () {
    return (
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        open={showModal === true}
        keepMounted
        onClose={() => setShowModal(false)}
        aria-labelledby='classic-modal-slide-title'
        aria-describedby='classic-modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Details</h4>
        </DialogTitle>
        <DialogContent
          id='classic-modal-slide-description'
          className={classes.modalBody}
        >
          {modalInfo}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => setShowModal(false)} color='danger' simple>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <GridContainer justify='center'>
      <GridItem xs={12} sm={12}>
        <h5 className={classes.sectionTitle}>
          {rows ? (
                <Fab
                  disabled={!enabled}
                  size='small'
                  color='primary'
                  className={classes.floatRight + ' ' + classes.bgColorPrimary}
                  onClick={() => onRefreshLogs()}
                >
                  <RefreshRoundedIcon fontSize='small' />
                </Fab>
          ) : null}
        </h5>
      </GridItem>
      <GridItem xs={12} sm={12}>
        {rows ? (
          <GridContainer justify='center' style={{ marginTop: '5px' }}>
            {logIsLoading ? (
                <div className={classes.reloadSize}>
                  <CircularProgress />
                </div>
            ) : (
              <GridItem xs={12} sm={12} md={12}>
                <MaterialTable
                  columns={columns}
                  data={rows}
                  actions={[
                    {
                      disabled: !enabled,
                      icon: 'filter_list',
                      tooltip: 'Details',
                      onClick: (event, rowData) => {
                        setShowModal(true)
                        setModalInfo(JSON.stringify(rowData.context))
                      }
                    }
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    sorting: true,
                    showTitle: false,
                    emptyRowsWhenPaging: false
                  }}
                />
                {modal()}
              </GridItem>
            )}
          </GridContainer>
        ) : null}
      </GridItem>
    </GridContainer>
  )
}

CustomMaterialTable.propTypes = {
  transactionId: PropTypes.string,
  environmentId: PropTypes.string,
  app: PropTypes.string,
  enabled: PropTypes.bool
}
