import React from 'react'
import moment from 'moment'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import { CircularProgress, Select, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import MaterialTable from 'material-table'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'

// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'
import CheckIcon from '@material-ui/icons/Check'
import Add from '@material-ui/icons/PlaylistAdd'
import AssessmentIcon from '@material-ui/icons/Assessment'
import CheckBox from '@material-ui/icons/CheckBox'
import Cancel from '@material-ui/icons/Cancel'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import NotInterestedIcon from '@material-ui/icons/NotInterested'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import NavPills from 'components/NavPills/NavPills.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import ExpiredDiv from 'components/ExpiredDiv/ExpiredDiv'
import NotificationAlert from 'components/NotificationAlert/NotificationAlert'
import SyncLoader from 'components/SyncLoader/SyncLoader'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// functions
import { statusColor } from 'utils/functions'

// Constants
import { invoiceTitles } from 'utils/Constants'

const useStyles = makeStyles(styles)

export default function InsightsAriba (props) {
  const classes = useStyles()

  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: true
    },
    {
      field: 'load',
      title: 'Load'
    },
    {
      field: 'destination',
      title: 'Destination',
      hidden: true
    },
    {
      field: 'type',
      title: 'Type'
    },
    {
      field: 'startDate',
      title: 'Start Date'
    },
    {
      field: 'creationDate',
      title: 'Creation Date',
      render: rowData =>
        moment(rowData.creationDate).format('MM/DD/YYYY HH:mm'),
      customSort: (a, b) => {
        return moment(moment(b.creationDate)).isBefore(moment(a.creationDate))
          ? 1
          : -1
      },
      defaultSort: 'desc'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: statusColor(rowData.status) }}>{rowData.status}</p>
      )
    },
    {
      field: 'updateFrequency',
      title: 'Frequency'
    },
    {
      field: 'statusProgress',
      title: 'Progress',
      render: rowData =>
        rowData.status !== 'Scheduled' ? (
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress
                variant="determinate"
                value={rowData.statusProgress}
              />
            </Box>
            <Box minWidth={35}>
              <Typography
                variant="caption"
                color="textSecondary"
              >{`${Math.round(rowData.statusProgress)}%`}</Typography>
            </Box>
          </Box>
        ) : null
    }
  ]

  let breadcrumbViews = [
    {
      name: 'Invoice AI',
      url: '/admin/invoiceAI/configurations',
      icon: AssessmentIcon
    },
    {
      name: invoiceTitles[props.typeApp] + ' Connection',
      url: `/admin/invoiceAI/${props.typeApp}/${props.selectedAppId}`,
      icon: CheckIcon
    }
  ]

  function showModal () {
    return (
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        open={props.showModal === true}
        keepMounted
        onClose={() => props.setShowModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Error connection details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {props.aribaConnectionMessage ? props.aribaConnectionMessage : null}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => props.setShowModal(false)}
            color="danger"
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  function returnConfigurationTab () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12}>
            <h5 className={classes.sectionTitle}>
              {invoiceTitles[props.typeApp]} Loads
              <Tooltip title="Add Load">
                <Button
                  disabled={!props.expirationData.enabled}
                  justIcon
                  round
                  simple
                  color="primary"
                  onClick={() => props.onGoToLoads()}
                >
                  <Add className={classes.icons} />
                </Button>
              </Tooltip>
              {props.loads ? (
                <Fab
                  size="small"
                  color="primary"
                  className={classes.floatRight + ' ' + classes.bgColorPrimary}
                  onClick={() => props.onRefreshLoads()}
                  disabled={!props.expirationData.enabled}
                >
                  <RefreshRoundedIcon fontSize="small" />
                </Fab>
              ) : null}
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12}>
            {props.loads ? (
              <GridContainer justify="center">
                {props.loadIsLoading ? (
                  <CircularProgress />
                ) : (
                  <GridItem xs={12} sm={12} md={12}>
                    <MaterialTable
                      columns={columns}
                      data={props.rows}
                      actions={[
                        {
                          disabled: !props.expirationData.enabled,
                          icon: 'edit',
                          tooltip: 'Edit Load',
                          onClick: (event, rowData) =>
                            props.history.push(
                              `/admin/invoiceAI/load/${props.selectedAppId}/${props.typeApp}/${rowData.id}`
                            )
                        },
                        rowData => ({
                          icon: 'chevron_right',
                          tooltip: 'Start Load',
                          onClick: (event, rowData) =>
                            props.onStartLoad(rowData.id),
                          disabled:
                            (rowData.status !== 'Created' &&
                              rowData.status !== 'Stopped') ||
                            !props.expirationData.enabled
                        }),
                        rowData => ({
                          icon: () => <NotInterestedIcon />,
                          tooltip: 'Stop Load',
                          onClick: (event, rowData) =>
                            props.onStopLoad(rowData.id),
                          disabled:
                            rowData.status === 'Created' ||
                            rowData.status === 'Stopped' ||
                            !props.expirationData.enabled
                        }),
                        {
                          disabled: !props.expirationData.enabled,
                          icon: 'delete',
                          tooltip: 'Delete Load',
                          onClick: (event, rowData) =>
                            props.onDeleteLoad(rowData.id)
                        },
                        {
                          disabled: !props.expirationData.enabled,
                          icon: 'view_column',
                          tooltip: 'Load Details',
                          onClick: (event, rowData) =>
                            props.history.push(
                              `/admin/invoiceAI/details/${props.selectedAppId}/${props.typeApp}/${rowData.id}`
                            )
                        }
                      ]}
                      options={{
                        actionsColumnIndex: -1,
                        sorting: true,
                        showTitle: false,
                        emptyRowsWhenPaging: false
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
            ) : null}
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }

  function returnConnectionTab () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <h5 className={classes.sectionTitle}>SAP Ariba</h5>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <FormLabel className={classes.inputLabelHorizontal}>
                  Realm
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  success={props.configState.realm === 'success'}
                  error={props.configState.realm === 'error'}
                  labelText={
                    <span>
                      Realm{' '}
                      <small>
                        {props.configState.realm === 'error'
                          ? '(required)'
                          : '*'}{' '}
                      </small>
                    </span>
                  }
                  id="realm"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: !props.expirationData.enabled,
                    onChange: event =>
                      props.onFieldChange('realm', event.target.value),
                    value: props.config.realm
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <FormLabel className={classes.inputLabelHorizontal}>
                  Ariba Application Key
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  success={props.configState.apiKey === 'success'}
                  error={props.configState.apiKey === 'error'}
                  labelText={
                    <span>
                      Ariba Application Key{' '}
                      <small>
                        {props.configState.apiKey === 'error'
                          ? '(required)'
                          : '*'}{' '}
                      </small>
                    </span>
                  }
                  id="apiKey"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: !props.expirationData.enabled,
                    type: 'password',
                    value: props.config.apiKey,
                    onChange: event =>
                      props.onFieldChange('apiKey', event.target.value)
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <FormLabel className={classes.inputLabelHorizontal}>
                  Ariba Client Id
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  success={props.configState.clientId === 'success'}
                  error={props.configState.clientId === 'error'}
                  labelText={
                    <span>
                      Ariba Client Id{' '}
                      <small>
                        {props.configState.clientId === 'error'
                          ? '(required)'
                          : '*'}{' '}
                      </small>
                    </span>
                  }
                  id="clientId"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: !props.expirationData.enabled,
                    type: 'password',
                    value: props.config.clientId,
                    onChange: event =>
                      props.onFieldChange('clientId', event.target.value)
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <FormLabel className={classes.inputLabelHorizontal}>
                  Ariba Application Secret
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  success={props.configState.secret === 'success'}
                  error={props.configState.secret === 'error'}
                  labelText={
                    <span>
                      Ariba Secret{' '}
                      <small>
                        {props.configState.secret === 'error'
                          ? '(required)'
                          : '*'}{' '}
                      </small>
                    </span>
                  }
                  id="secret"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: !props.expirationData.enabled,
                    type: 'password',
                    value: props.config.secret,
                    onChange: event =>
                      props.onFieldChange('secret', event.target.value)
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              color={
                props.aribaConnectionConnected !== undefined
                  ? props.aribaConnectionConnected
                    ? 'info'
                    : 'danger'
                  : 'facebook'
              }
              onClick={() => props.onCheckAribaConnection()}
              round
              className={classes.documentation}
              disabled={
                props.aribaConnectionTesting || !props.expirationData.enabled
              }
            >
              {props.aribaConnectionConnected !== undefined ? (
                props.aribaConnectionConnected ? (
                  <div>
                    <CheckBox className={classes.icons} /> Connected
                  </div>
                ) : (
                  <div>
                    <Cancel className={classes.icons} /> Connection Error
                  </div>
                )
              ) : (
                <div>
                  {props.aribaConnectionTesting
                    ? 'Loading...'
                    : 'Test Connectivity'}{' '}
                </div>
              )}
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {props.aribaConnectionMessage ? (
              <Button
                disabled={!props.expirationData.enabled}
                color="danger"
                simple
                round
                className={classes.documentation}
                onClick={() => props.setShowModal(true)}
              >
                Error details
              </Button>
            ) : null}
          </GridItem>
        </GridContainer>
        <br/>
        <GridContainer justify="flex-end">
          <Button
            disabled={
              props.isLoadingSave ||
              !props.aribaConnectionConnected ||
              !props.expirationData.enabled
            }
            variant="outlined"
            color="primary"
            onClick={() => props.onConfigSave()}
          >
            {!props.isLoadingSave ? 'Save' : 'Loading...'}
          </Button>
        </GridContainer>
        {showModal()}
      </GridItem>
    )
  }

  function returnItkConnectionTab () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <h5 className={classes.sectionTitle}>SAP Ariba</h5>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <FormLabel className={classes.inputLabelHorizontal}>
                  Realm
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  success={props.configState.realm === 'success'}
                  error={props.configState.realm === 'error'}
                  labelText={
                    <span>
                      Realm{' '}
                      <small>
                        {props.configState.realm === 'error'
                          ? '(required)'
                          : '*'}{' '}
                      </small>
                    </span>
                  }
                  id="realm"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: !props.expirationData.enabled,
                    onChange: event =>
                      props.onFieldChange('realm', event.target.value),
                    value: props.config.realm
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <FormLabel className={classes.inputLabelHorizontal}>
                  SAP Ariba Site Region
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                      style: { zIndex: 5555 }
                    }}
                    classes={{
                      select: classes.select,
                      disabled: classes.disabled
                    }}
                    value={props.config.siteRegion}
                    onChange={e =>
                      props.onFieldChange('siteRegion', e.target.value)
                    }
                    inputProps={{
                      name: 'siteRegion',
                      id: 'siteRegion'
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                      value={'s1'}
                    >
                      https://s1.ariba.com
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                      value={'s2'}
                    >
                      https://s2.ariba.com
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                      value={'s3'}
                    >
                      https://s3.ariba.com
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <FormLabel className={classes.inputLabelHorizontal}>
                  Ariba Authentication Type
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                      style: { zIndex: 5555 }
                    }}
                    classes={{
                      select: classes.select,
                      disabled: classes.disabled
                    }}
                    value={props.config.authenticationType}
                    onChange={e =>
                      props.onFieldChange('authenticationType', e.target.value)
                    }
                    inputProps={{
                      name: 'authenticationType',
                      id: 'authenticationType'
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                      value={'sharedSecret'}
                    >
                      Shared Secret
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                      value={'certificate'}
                    >
                      Certificate
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
          {props.config.authenticationType === 'certificate' ? (
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <FormLabel className={classes.inputLabelHorizontal}>
                    Ariba Certificate
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6} style={{ paddingTop: '15px' }}>
                  <SyncLoader
                    showLoader={props.certificateIsLoading}
                    textLoader={'Loading...'}
                  />
                  {!props.certificateIsLoading ? (
                    <TextField
                      fullWidth
                      label="Ariba Certificate"
                      multiline
                      rows="15"
                      value={props.config.certificate}
                      inputProps={{
                        readOnly: true,
                        style: { fontSize: '10px' }
                      }}
                    />
                  ) : null}
                </GridItem>
              </GridContainer>
            </GridItem>
          ) : (
            <>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                      Ariba Authentication Key
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      success={
                        props.configState.authenticationKey === 'success'
                      }
                      error={props.configState.authenticationKey === 'error'}
                      labelText={
                        <span>
                          Ariba Authentication Key{' '}
                          <small>
                            {props.configState.authenticationKey === 'error'
                              ? '(required)'
                              : '*'}{' '}
                          </small>
                        </span>
                      }
                      id="authenticationKey"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !props.expirationData.enabled,
                        type: 'password',
                        value: props.config.authenticationKey,
                        onChange: event =>
                          props.onFieldChange(
                            'authenticationKey',
                            event.target.value
                          )
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color={
                    props.aribaConnectionConnected !== undefined
                      ? props.aribaConnectionConnected
                        ? 'info'
                        : 'danger'
                      : 'facebook'
                  }
                  onClick={() => props.onCheckAribaConnection()}
                  round
                  className={classes.documentation}
                  disabled={
                    props.aribaConnectionTesting ||
                    !props.expirationData.enabled
                  }
                >
                  {props.aribaConnectionConnected !== undefined ? (
                    props.aribaConnectionConnected ? (
                      <div>
                        <CheckBox className={classes.icons} /> Connected
                      </div>
                    ) : (
                      <div>
                        <Cancel className={classes.icons} /> Connection Error
                      </div>
                    )
                  ) : (
                    <div>
                      {props.aribaConnectionTesting
                        ? 'Loading...'
                        : 'Test Connectivity'}{' '}
                    </div>
                  )}
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {props.aribaConnectionMessage ? (
                  <Button
                    disabled={!props.expirationData.enabled}
                    color="danger"
                    simple
                    round
                    className={classes.documentation}
                    onClick={() => props.setShowModal(true)}
                  >
                    Error details
                  </Button>
                ) : null}
              </GridItem>
            </>
          )}
        </GridContainer>
        <GridContainer justify="center">
          <Button
            disabled={
              props.isLoadingSave ||
              !props.aribaConnectionConnected ||
              !props.expirationData.enabled ||
              props.certificateIsLoading
            }
            variant="outlined"
            color="primary"
            onClick={() => props.onConfigSave()}
          >
            {!props.isLoadingSave ? 'Save' : 'Loading...'}
          </Button>
        </GridContainer>
        {showModal()}
      </GridItem>
    )
  }

  function returnTabsToDisplay () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <div style={{ position: 'relative' }}>
          <NotificationAlert
            variant="filled"
            severity="warning"
            appInfo={props.expirationData}
          />
          <Card>
            <CardHeader>
              <h3 className={classes.formHeader}>
                Invoice AI - {invoiceTitles[props.typeApp]}
              </h3>
            </CardHeader>
            <CardBody>
              <NavPills
                action={actions => {
                  if (actions) {
                    props.updateHeightFunction(actions.updateHeight)
                  }
                }}
                animateHeight={false}
                color="primary"
                tabs={[
                  {
                    tabButton: 'Configuration',
                    tabContent: returnConfigurationTab()
                  },
                  {
                    tabButton: 'Ariba Connection',
                    tabContent:
                      props.typeApp === 'upstream' ||
                      props.typeApp === 'downstream'
                        ? returnItkConnectionTab()
                        : returnConnectionTab()
                  }
                ]}
              />
            </CardBody>
          </Card>
          <ExpiredDiv appInfo={props.expirationData} />
        </div>
      </GridItem>
    )
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={3}>
        <Card className={classes.appBar}>
          <CardHeader>Application List</CardHeader>
          <CardBody>
            <List>
              {props.appList.map((prop, key) => {
                return (
                  <ListItem
                    className={classes.listItem}
                    button
                    selected={props.selectedAppId === prop.env}
                    key={key}
                    onClick={() => props.onAppChanged(prop.env)}
                  >
                    <ListItemAvatar>
                      {props.selectedAppId === prop.env ? (
                        <Avatar>
                          <TouchApp />
                        </Avatar>
                      ) : (
                        <Avatar className={classes.clearAvatar}></Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={prop.appName}
                      secondary={`Type: ${prop.type}`}
                    />
                  </ListItem>
                )
              })}
            </List>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={9}>
        <Breadcrumbs views={breadcrumbViews} />
        {props.formIsLoading ? (
          <Card>
            <GridContainer justify="center">
              <div className={classes.circularProgress}>
                <CircularProgress color="inherit" />
              </div>
            </GridContainer>
          </Card>
        ) : (
          returnTabsToDisplay()
        )}
      </GridItem>
      <Snackbar
        place="br"
        color="success"
        icon={AddAlert}
        message={props.validationMessage}
        open={props.submitSuccess}
        closeNotification={() => props.setSubmitSuccess(false)}
        close
      />
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={props.validationMessage}
        open={props.createError}
        closeNotification={() => props.setCreateError(false)}
        close
      />
    </GridContainer>
  )
}
