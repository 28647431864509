import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import Button from 'components/CustomButtons/Button.js'
import { PUBLIC_S3_ASSETS } from 'utils/Constants'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const coupaPDF = `${PUBLIC_S3_ASSETS}/documentation/user-manuals/Data-Assure-User-Guide.pdf`

const ShowCoupaPDF = () => {
  const pdfFileName = 'Coupa Data Assure.pdf'
  const [numPages, setNumPages] = useState(null)
  const [pageNum, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function changePage(isFoward) {
    if (isFoward) {
      if (pageNum < numPages) {
        setPageNumber(pageNum + 1)
      }
    } else {
      if (pageNum > 1) {
        setPageNumber(pageNum - 1)
      }
    }
  }

  return (
    <div>
      <Button onClick={() => document.getElementById('link').click()} color={'info'}>
        Download file
      </Button>
      <Document
        file={coupaPDF}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={error => alert('Error while loading document! ' + error.message)}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Page pageNumber={pageNum} renderAnnotationLayer={false} height={700} />
        </div>
      </Document>
      <p style={{ textAlign: 'center' }}>
        Page {pageNum} of {numPages}
      </p>
      <Button onClick={() => changePage(false)} color={'primary'}>
        Prev
      </Button>
      <Button onClick={() => changePage(true)} color={'primary'}>
        Next
      </Button>
      <a id="link" href={coupaPDF} download={pdfFileName} hidden>
        Link
      </a>
    </div>
  )
}

export default ShowCoupaPDF
