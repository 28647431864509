import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Add from "@material-ui/icons/PlaylistAdd";
import Edit from "@material-ui/icons/Edit";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { NavLink } from "react-router-dom";
import { Fade } from "@material-ui/core";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  addButton: {
    marginTop: "-25px",
    float: "right",
  },
};

const useStyles = makeStyles(styles);

export default function ReactTables(props) {
  const classes = useStyles();

  let dataTableInfo = props.userList.map((prop) => {
    return {
      userId: prop.userId,
      name: `${prop.firstName} ${prop.lastName}`,
      email: prop.email,
      company: prop.tenantId,
      enabled: prop.enabled ? "Enabled" : "Disabled",
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          <NavLink to={`/admin/users/edit/${prop.tenantId}/${prop.userId}`}>
            <Tooltip title={"Edit user"}>
              <Button color={"warning"} simple className={classes.actionButton}>
                <Edit className={classes.icon} />
              </Button>
            </Tooltip>
          </NavLink>
        </div>
      ),
    };
  });
  if (props.pageIsLoading) {
    return <div></div>;
  }
  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <SupervisorAccount />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>User list</h4>
              <NavLink to={`/admin/users/create`}>
                <Tooltip title="Create new user">
                  <Button color="primary" className={classes.addButton}>
                    <Add className={classes.icons} />
                  </Button>
                </Tooltip>
              </NavLink>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={dataTableInfo}
                filterable
                columns={[
                  {
                    Header: "Name",
                    accessor: "name",
                  },
                  {
                    Header: "Email",
                    accessor: "email",
                  },
                  {
                    Header: "Company",
                    accessor: "company",
                  },
                  {
                    Header: "Status",
                    accessor: "enabled",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop
                minRows={0}
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Fade>
  );
}
