import React from 'react'

// @material-ui/core components
//import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog'

//import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/customModalStyles.js'
//const useStyles = makeStyles(styles)
export default function CustomModal (props) {
  const { successBtnTxt } = props
  // const classes = useStyles()
  return (
    <Dialog open={true}>
      <div style={{ alignItems: 'center' }}>
        <Grid container style={{ overflow: 'hidden' }}>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <h3 style={{fontSize: '1.25rem', fontWeight: '500', lineHeight: '1.6'}}>{props.title}</h3>
          </div>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {props.children}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: '5%',
              paddingRight: '5%',
              paddingBottom: '2%',
              textAlign: 'right'
            }}
          >
            <Button
              className={props.confirmBtnCssClass}
              variant="outlined"
              color="inherit"
              onClick={props.onConfirm}
            >
              {successBtnTxt}
            </Button>
            <Button
              style={{ marginLeft: '8px'}}
              className={props.cancelBtnCssClass}
              onClick={props.onCancel}
              color="secondary"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

CustomModal.defaultProps = {
  successBtnTxt: 'Confirm'
}
