import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import React from 'react'
const actionTypes = {
  SET_ERROR: 'SET_ERROR',
  DELETE_ERRORS: 'DELETE_ERRORS',
  DELETE_ERROR: 'DELETE_ERROR',
  RESET: 'RESET',
  SET_DATA: 'SET_DATA'
}

export const errorTypes = {
  critical: 'critical',
  high: 'high',
  medium: 'medium',
  low: 'low'
}

export function getError(errorField) {
  let error = errorField?.details?.find(x => x.criticalityType === errorTypes.critical)

  if (error) {
    return error
  }

  error = errorField?.details?.find(x => x.criticalityType === errorTypes.high)

  if (error) {
    return error
  }
  error = errorField?.details?.find(x => x.criticalityType === errorTypes.medium)

  if (error) {
    return error
  }
  error = errorField?.details?.find(x => x.criticalityType === errorTypes.low)

  if (error) {
    return error
  }
}

function undoReducer(state, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.SET_ERROR: {
      const { newError } = payload
      return {
        ...state,
        errors: state.errors.push(newError)
      }
    }
    case actionTypes.DELETE_ERROR: {
      const { field } = payload
      return {
        ...state,
        errors: state.errors.filter(x => x.field !== field)
      }
    }
    case actionTypes.DELETE_ERRORS: {
      const { fields } = payload
      return {
        ...state,
        errors: state.errors.filter(x => !fields.includes(x.field))
      }
    }
    case actionTypes.SET_DATA: {
      const { errors } = payload
      return {
        ...state,
        errors: errors
      }
    }
    case actionTypes.RESET: {
      console.log('RESET')
      break
    }
    default:
      throw new Error('Event not implemented yet')
  }
}
const useStyles = makeStyles(styles)

export function useHeader(initialPresent = {}) {
  const classes = useStyles()
  const [state, dispatch] = React.useReducer(undoReducer, {
    unrecognizedFieldsAnnotations: [],
    summarySelectedId: null,
    summaryFieldsAnnotations: [],
    unrecognizedSelectedId: null,
    errors: [],
    ...initialPresent
  })

  const setErrorHeader = React.useCallback(
    payload => dispatch({ type: actionTypes.SET_ERROR, payload }),
    []
  )
  const deleteErrorHeader = React.useCallback(
    payload => dispatch({ type: actionTypes.DELETE_ERROR, payload }),
    []
  )

  const deleteErrorsHeader = React.useCallback(
    payload => dispatch({ type: actionTypes.DELETE_ERRORS, payload }),
    []
  )
  const setDataHeader = React.useCallback(
    payload => dispatch({ type: actionTypes.SET_DATA, payload }),
    []
  )

  function getColors(error, isDirty) {
    let color = ''
    let inputColor = ''
    let badgeColor = ''
    let tooltipColor = ''

    if (isDirty && !error) {
      color = classes.successColor
      inputColor = classes.inputSuccess
      return { color, inputColor, badgeColor, tooltipColor }
    }

    if (!error) {
      return { color, inputColor, badgeColor, tooltipColor }
    }

    if (error.criticalType === errorTypes.critical) {
      color = classes.errorColor
      inputColor = classes.inputError
      tooltipColor = classes.tooltipError
    } else {
      color = classes.warningColor
      inputColor = classes.inputWarning
      tooltipColor = classes.tooltipWarning
    }

    return { color, inputColor, badgeColor, tooltipColor }
  }

  return [
    state,
    { setErrorHeader, deleteErrorHeader, setDataHeader, getColors, classes, deleteErrorsHeader }
  ]
}
