import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'

//services
import {
  getAppConfigurationsByApp,
  createAppLoad,
  getAppLoadByAppAndId,
  editAppLoadByAppAndId,
  getAppConfigurationByAppAndId,
  editAppConfigurationByAppAndType,
  getAppConstantsByApp
} from 'services/apiApps'

//Views
import IntelligenceLoadView from './IntelligenceLoadView'

import { v4 as uuidv4 } from 'uuid'

// Constants
import { InputDefault } from 'utils/Constants'

import { updateApis } from 'store/IntelligenceState'

let _isMounted = false
let appId
let typeApp
let idLoad

const configuration = {
  name: '',
  type: 'historical',
  updateFrequency: 'daily',
  executionTime: new Date().toLocaleTimeString(
    window.navigator.userLanguage || window.navigator.language,
    {
      hour: '2-digit',
      minute: '2-digit'
    }
  ),
  executionDay: '',
  startDate: new Date().toLocaleDateString(
    window.navigator.userLanguage || window.navigator.language,
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
  ),
  typeApp: '',
  displayMode: 'create'
}

// const connection = {
//   remoteConnection: '',
//   remoteConnections: '',
//   unzip: false,
//   fileExtension: '.json',
//   path: '',
//   typeApp: '',
//   displayMode: 'create'
// }

const journeys = {
  views: [],
  viewsApi: [],
  apis: [],
  typeApp: '',
  displayMode: 'create'
}

const aribaConnections = {
  typeApp: '',
  displayMode: 'create',
  selectedAppId: '',
  connections: '',
  showModal: false,
  realm: '',
  environmentId: '',
  aribaConnectionMessage: '',
  configurations: {
    realm: { value: '', state: undefined },
    apis: {}
  }
}

async function getAppList (props) {
  let response = await getAppConfigurationsByApp('intelligence')

  if (response.success) {
    if (_isMounted) {
      props.setAppList(response.data)
      props.setPageIsLoading(false)
    }
  }
}

async function getAppDetails (props, id) {
  props.setFormIsLoading(true)
  const appDetails = await getAppConfigurationByAppAndId('intelligence', id)
  if (appDetails.success) {
    props.setSelectedAppId(id)
    // props.setConnections(appDetails.data.params.remoteConnections)
    return { params: appDetails.data.params, realm: appDetails.data.params.ariba.realm }
  }
  props.setFormIsLoading(false)
}

async function getAppLoad (
  props,
  id,
  idLoad,
  typeApp,
  connections,
  abConnections,
  realm
) {
  props.setFormIsLoading(true)
  const appLoad = await getAppLoadByAppAndId('intelligence', id, idLoad)
  if (appLoad.success) {
    if (_isMounted) {
      const load = appLoad.data.params
      await setInitialApis(props, load.views)
      // const destination = load.destination
      const viewsInit = load.views.map(view => view.view)
      let journeys = {
        views: viewsInit,
        viewsApi: load.views,
        displayMode: 'edit',
        typeApp: typeApp
      }
      let config = {
        name: load.name,
        type: load.type,
        updateFrequency: load.updateFrequency,
        startDate: new Date(load.startDate).toLocaleDateString(
          window.navigator.userLanguage || window.navigator.language,
          { year: 'numeric', month: '2-digit', day: '2-digit' }
        ),
        executionTime: new Date(load.executionTime).toLocaleTimeString(
          window.navigator.userLanguage || window.navigator.language,
          {
            hour: '2-digit',
            minute: '2-digit'
          }
        ),
        executionDay: load.executionDay,
        displayMode: 'edit',
        typeApp: typeApp,
        creationDate: load.creationDate
      }
      // let connect = {
      //   remoteConnection: destination.remoteConnection,
      //   unzip: destination.unzip,
      //   fileExtension: destination.fileExtension,
      //   path: destination.path,
      //   displayMode: 'edit',
      //   remoteConnections: connections,
      //   typeApp: typeApp
      // }
      let aribaConnections = {
        typeApp: typeApp,
        displayMode: 'edit',
        selectedAppId: id,
        connections: abConnections,
        realm: realm,
        configurations: {
          realm: { value: realm, state: undefined },
          apis: {}
        }
      }
      props.setDisplayMode('edit')
      props.setIdLoad(idLoad)
      props.setLoad(appLoad.data)
      props.setConfig({ ...config })
      // props.setConnection({ ...connect })
      props.setJourneys({ ...journeys })
      props.setAribaConnections({ ...aribaConnections })
      props.setFormIsLoading(false)
    }
  } else {
    props.setFormIsLoading(false)
  }
  props.setPageIsLoading(false)
}

async function setInitialApis (props, loadViews) {
  const constants = await getAppConstantsByApp('intelligence', 'procurement')
  let apis = []
  if (constants && constants.success) {
    apis = loadViews.map(view => {
      let api = {}
      constants.data.journeys.forEach(journey => {
        let eventKeys = Object.keys(journey.event)
        eventKeys.forEach(key => {
          journey.event[key].views.forEach(viewConst => {
            if (viewConst.view === view.view) {
              api = journey.event[key].apis
            }
          })
        })
      })
      return api
    })
  }
  props.updateApis(apis[0])
}

async function setAppLoad (
  props,
  typeApp,
  connections,
  abConnections,
  appId,
  realm
) {
  props.setFormIsLoading(true)
  configuration.typeApp = typeApp
  configuration.executionTime = new Date().toLocaleTimeString(
    window.navigator.userLanguage || window.navigator.language,
    {
        hour: '2-digit',
        minute: '2-digit'
    }
)
  // connection.typeApp = typeApp
  journeys.views = []
  journeys.viewsApi = []
  journeys.typeApp = typeApp
  aribaConnections.typeApp = typeApp
  aribaConnections.selectedAppId = appId
  aribaConnections.connections = abConnections
  aribaConnections.realm = realm
  // if (Object.keys(connections).length > 0) {
  //   connection.remoteConnections = connections
  //   const defaultConnect = connections[Object.keys(connections)[0]]
  //   connection.fileExtension = defaultConnect.fileExtension
  //   connection.unzip = defaultConnect.unzip
  //   connection.path = defaultConnect.path
  //   connection.remoteConnection = Object.keys(connections)[0]
  // }
  props.setConfig({ ...configuration })
  // props.setConnection({ ...connection })
  props.setJourneys({ ...journeys })
  props.setAribaConnections({ ...aribaConnections })
  props.setFormIsLoading(false)
  props.setPageIsLoading(false)
}

async function onSubmit (props, event) {
  let result = {}
  let successMessage

  let loadConfiguration = createLoadRequestBody(props, event)
  let appConfiguration = createAppRequestBody(event)
  let response
  let responseApp

  if (props.displayMode === 'edit') {
    response = await editAppLoadByAppAndId(
      'intelligence',
      props.selectedAppId,
      loadConfiguration,
      props.idLoad
    )
    successMessage = 'Load updated successfully'
  } else {
    response = await createAppLoad(
      'intelligence',
      props.selectedAppId,
      loadConfiguration,
      uuidv4()
    )
    successMessage = 'Load created successfully'
  }
  responseApp = await editAppConfigurationByAppAndType(
    'intelligence',
    props.selectedAppId,
    appConfiguration,
    props.typeApp
  )

  if (
    response &&
    responseApp &&
    response.success &&
    responseApp.success &&
    _isMounted
  ) {
    props.setValidationMessage(successMessage)
    props.setSubmitSuccess(true)

    setTimeout(function () {
      if (_isMounted) {
        props.setSubmitSuccess(false)
        props.setFormIsLoading(false)
        props.history.push(
          `/admin/intelligence/${props.typeApp}/${props.selectedAppId}`
        )
      }
    }, 5000)
  } else {
    // Show error message
    if (result) {
      let message = result.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

async function initFunction (props, appId, idLoad, typeApp) {
  const details = await getAppDetails(props, appId)
  const params = { ...details.params }
  // const connections = { ...params.remoteConnections }
  // delete params.remoteConnections
  const aribaConnections = { ...params.ariba }
  if (idLoad) {
    getAppLoad(props, appId, idLoad, typeApp, null, aribaConnections, details.realm)
  } else {
    setAppLoad(props, typeApp, null, aribaConnections, appId, details.realm)
  }
}

function createLoadRequestBody (props, formValues) {
  if (formValues.config.type === 'historical') {
    formValues.config.updateFrequency = 'oneTime'
  }
  const todayDate = new Date().toLocaleDateString(
    window.navigator.userLanguage || window.navigator.language,
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
  )
  // const connection = formValues.connection
  const params = {
    // destination: {
    //   fileExtension: connection.fileExtension,
    //   path: connection.path ? connection.path : '',
    //   remoteConnection: connection.remoteConnection,
    //   nameConnection:
    //     connection.remoteConnections[connection.remoteConnection].name,
    //   unzip: connection.unzip
    // },
    name: formValues.config.name,
    startDate: new Date(formValues.config.startDate).toISOString(),
    executionDay: formValues.config.executionDay,
    executionTime: new Date(todayDate + ' ' + formValues.config.executionTime).toISOString(),
    type: formValues.config.type,
    updateFrequency: formValues.config.updateFrequency,
    views: formValues.scenarios.viewsApi,
    transformationEnabled: true,
    creationDate: formValues.config.creationDate
  }
  if (props.displayMode === 'create') {
    params.creationDate = new Date().toISOString()
  }
  const requestBody = {
    api: props.typeApp,
    enabled: false,
    params: params
  }

  return requestBody
}

function createAppRequestBody (formValues) {
  let requestBody = { ...formValues.aribaConnections.configurations }

  let object = {
    realm: requestBody.realm.value
  }
  let item
  for (item in requestBody.apis) {
    if (requestBody.apis[item].aribaConnection === true) {
      object[item] = {
        apiKey: requestBody.apis[item].inputs['apiKey_' + item].value,
        secret: requestBody.apis[item].inputs['secret_' + item].value,
        clientId: requestBody.apis[item].inputs['clientId_' + item].value
      }
      let checkProps = ['apiKey', 'secret', 'clientId']
      let bodyField
      for (bodyField of checkProps) {
        if (object[item][bodyField] === InputDefault) {
          delete object[item][bodyField]
        }
      }
    }
  }
  return object
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      apis: state.intelligence.apis
    }),
    { updateApis }
  ),
  withState('appList', 'setAppList', []),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('selectedApp', 'setSelectedApp', null),
  withState('displayMode', 'setDisplayMode', 'edit'),
  withState('config', 'setConfig', { ...configuration }),
  // withState('connection', 'setConnection', { ...connection }),
  withState('journeys', 'setJourneys', { ...journeys }),
  withState('aribaConnections', 'setAribaConnections', { ...aribaConnections }),
  // withState('connections', 'setConnections', null),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('typeApp', 'setTypeApp', null),
  withState('idLoad', 'setIdLoad', null),
  withState('load', 'setLoad', null),
  withState('displayMode', 'setDisplayMode', 'create'),
  withHandlers({
    onAppChanged: props => id => {
      props.history.push(`/admin/intelligence/${props.typeApp}/${id}`)
    },
    onAppSaved: props => async event => {
      props.setFormIsLoading(true)
      await onSubmit(props, event)
    }
  }),
  lifecycle({
    componentDidMount () {
      _isMounted = true
      this.props.updateApis({})
      appId = this.props.match.params.id
      typeApp = this.props.match.params.type
      idLoad = this.props.match.params.idLoad
      this.props.setPageIsLoading(true)
      this.props.setTypeApp(typeApp)
      getAppList(this.props)
      initFunction(this.props, appId, idLoad, typeApp)
    }
  })
)(IntelligenceLoadView)
