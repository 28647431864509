import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'

// @material-ui/icons
import InfoIcon from '@material-ui/icons/Info'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

const useStyles = makeStyles(styles)

export default function Tmobile (props) {
  const classes = useStyles()

  const columns = [
    {
      field: 'uniqueName',
      title: 'Unique Name',
    },
    {
      field: 'lastChangeSequenceId',
      title: 'Last Change Sequence Id',
    },
    {
      field: 'lastChangeType',
      title: 'Last Change Type',
    },
    {
      field: 'status',
      title: 'Status',
    },
  ]

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h3 className={classes.formHeader}>T-Mobile</h3>
          </CardHeader>
          <CardBody>
            <MaterialTable
              columns={columns}
              data={props.rows}
              actions={[
                {
                  icon: () => <InfoIcon />,
                  tooltip: 'Details',
                  onClick: (event, rowData) =>
                    props.history.push(
                      `/admin/tmobile/${rowData.uniqueName}`
                    )
                }
              ]}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
