import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Close from '@material-ui/icons/Close'
import InputAdornment from '@material-ui/core/InputAdornment'
import AddIcon from '@material-ui/icons/Add'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceUserImportStyle'

const useStyles = makeStyles(styles)

const newRoleDefault = {
  name: '',
  relishRole: ''
}

export function RolesHeader({ addRoleTenant, rolesList }) {
  const [newRole, setNewRole] = useState(newRoleDefault)

  const [addError] = useState(false)
  const classes = useStyles()

  function addRole(params) {
    setNewRole({ name: '', relishRole: '' })
    addRoleTenant(newRole)
  }
  return (
    <>
      <GridContainer justify="flex-start">
        <GridItem md={4}>
          <CustomInput
            success={newRole.name.trim() !== ''}
            error={addError}
            labelText="Enter the custom group name*"
            id="newRole"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event =>
                setNewRole({
                  ...newRole,
                  name: event.target.value
                }),
              type: 'text',
              value: newRole.name,
              endAdornment: addError ? (
                <InputAdornment position="end">
                  <Close className={classes.danger} />
                </InputAdornment>
              ) : null
            }}
          />
        </GridItem>
        <GridItem md={4}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select">Relish Role*</InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={newRole.relishRole}
              success={newRole.relishRole !== ''}
              onChange={event =>
                setNewRole({
                  ...newRole,
                  relishRole: event.target.value
                })
              }
              inputProps={{
                name: `roles`,
                id: `roles`
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Select a Relish Role
              </MenuItem>
              {rolesList.map(role => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  style={{
                    overflowX: 'auto',
                    textOverflow: 'ellipsis'
                  }}
                  value={role}
                  key={role}
                >
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem md={4}>
          <div
            style={{
              width: '100%',
              margin: '0 auto 30px'
            }}
          >
            <div className={classes.statusButton} style={{ width: '200px' }}>
              <div>
                <Fab
                  size="small"
                  className={classes.successColor}
                  disabled={
                    newRole.name.trim() === '' || newRole.relishRole === ''
                  }
                  onClick={() => addRole()}
                >
                  <AddIcon />
                </Fab>
                <span className={classes.statusText}>Add Mapping</span>
              </div>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </>
  )
}
