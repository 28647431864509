/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

// Images
import relishLogo from "assets/img/RelishLogo.png";
import relishConnect from "assets/img/RelishConnect.png";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/Teams/detailsStyle.js";

const useStyles = makeStyles(styles);

export default function createView(props) {
    const classes = useStyles();
    return (
        <div>
            <GridContainer justify="center">
                <GridItem xs={12} sm={10} md={8}>
                    <Card>
                        <CardBody>
                            <GridItem xs={12} sm={12} md={12}>
                                <img src={relishLogo} className={classes.nitorLogo} alt="Relish" title="Relish" />
                            </GridItem>
                            <GridContainer justify="center">
                                <img src={relishConnect} className={classes.nitorConnectLogo} alt="Connect" title="Connect" />
                            </GridContainer>
                            <GridItem xs={12} sm={12} md={12} className={classes.documentNumber}>
                                <h1 className={classes.documentNumberText} >{`${props.document.documentType} - ${props.document.uniqueName}`}</h1>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h3 className={classes.detailsHeader}>{`Details`}</h3>
                                        <List className={classes.list}>
                                            <ListItem className={classes.listItem}>
                                                <ListItemText primary={`- Date Created: ${props.document.created}`} />
                                            </ListItem>
                                            <ListItem className={classes.listItem}>
                                                <ListItemText primary={`- Requester: ${props.document.requester}`} />
                                            </ListItem>
                                            <ListItem className={classes.listItem}>
                                                <ListItemText primary={`- Need By Date: ${props.document.needBy}`} />
                                            </ListItem>
                                        </List>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <div className={classes.verticalCenter}>
                                            <Button color="primary" className={classes.detailsButton}
                                                href={props.document.documentUrl} target="_blank">

                                                More Details
                                        </Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            {
                                props.document.additionalTabName ?
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Button color="facebook" simple className={classes.additionalButton}
                                            href={props.document.additionalTabUrl} target="_blank">

                                            {props.document.additionalTabName}
                                        </Button>
                                    </GridItem>
                                    :
                                    null
                            }
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
