import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import moment from 'moment'

//services
import {
  getAppConfigurationsByApp,
  createAppLoad,
  getAppLoadByAppAndId,
  editAppLoadByAppAndId,
  getAppConfigurationByAppAndId
} from 'services/apiApps'
import { checkExpiration } from 'utils/functions'

//Views
import InsightsLoadView from './InsightsLoadView'

import { v4 as uuidv4 } from 'uuid'

let _isMounted = false
let appId
let typeApp
let idLoad
moment.locale(window.navigator.userLanguage || window.navigator.language)

const configuration = {
  name: '',
  type: 'historical',
  updateFrequency: 'daily',
  executionTime: moment(new Date()).format('hh:mm a'),
  executionHour: '',
  executionMinute: '',
  executionDay: '',
  startDate: moment(new Date()).format('MM/DD/YYYY'),
  typeApp: '',
  displayMode: 'create',
  enabled: true
}

const connection = {
  remoteConnection: '',
  remoteConnections: '',
  unzip: false,
  fileExtension: '.json',
  path: '',
  typeApp: '',
  displayMode: 'create',
  enabled: true
}

const views = {
  views: [],
  viewsApi: [],
  typeApp: 'default',
  enabled: true,
  instanceType: 'sap'
}

async function getAppList (props) {
  let response = await getAppConfigurationsByApp('insights')

  if (response.success) {
    if (_isMounted) {
      props.setAppList(response.data)
    }
  }
}

async function getAppDetails (props, id) {
  const appDetails = await getAppConfigurationByAppAndId('insights', id)
  if (appDetails.success) {
    props.setSelectedAppId(id)
    const instance = appDetails.data?.params?.ariba?.instanceType ?? 'sap'
    props.setInstanceType(instance)
    props.setConnections(appDetails.data.params.remoteConnections)
    props.setConnections(
      appDetails.data.params.remoteConnections
        ? appDetails.data.params.remoteConnections
        : []
    )

    return { connections: appDetails.data.params.remoteConnections, instance: instance }
  }
}

async function getAppLoad (props, id, idLoad, typeApp, connections, data, instance) {
  props.setFormIsLoading(true)
  const appLoad = await getAppLoadByAppAndId('insights', id, idLoad)
  if (appLoad.success) {
    if (_isMounted) {
      const load = appLoad.data.params
      const destination = load.destination
      const viewsInit = load.views.map(view => view.view)
      let views = {
        views: viewsInit,
        viewsApi: load.views,
        typeApp: typeApp,
        enabled: data.enabled,
        instanceType: instance
      }
      let config = {
        name: load.name,
        type: load.type,
        updateFrequency: load.updateFrequency,
        startDate: moment(load.startDate).format('MM/DD/YYYY'),
        executionTime: moment(load.executionTime).format('hh:mm a'),
        executionDay: load.executionDay,
        executionHour: load.executionHour,
        executionMinute: load.executionMinute,
        displayMode: 'edit',
        typeApp: typeApp,
        creationDate: load.creationDate,
        enabled: data.enabled
      }
      let connect = {
        remoteConnection: destination.remoteConnection,
        unzip: destination.unzip,
        fileExtension: destination.fileExtension,
        path: destination.path,
        displayMode: 'edit',
        remoteConnections: connections,
        typeApp: typeApp,
        enabled: data.enabled
      }
      props.setDisplayMode('edit')
      props.setIdLoad(idLoad)
      props.setLoad(appLoad.data)
      props.setConfig({ ...config })
      props.setConnection({ ...connect })
      props.setViews({ ...views })
      props.setFormIsLoading(false)
    }
  } else {
    props.setFormIsLoading(false)
  }
  props.setPageIsLoading(false)
}

async function setAppLoad (props, typeApp, connections, data, appId, instance) {
  props.setFormIsLoading(true)
  configuration.typeApp = typeApp
  configuration.enabled = data.enabled
  configuration.executionTime = moment(new Date()).format('hh:mm a')
  connection.typeApp = typeApp
  connection.enabled = data.enabled
  let views = {
    views: [],
    viewsApi: [],
    typeApp: typeApp,
    enabled: data.enabled,
    instanceType: instance
  }
  if (Object.keys(connections).length > 0) {
    connection.remoteConnections = connections
    const defaultConnect = connections[Object.keys(connections)[0]]
    connection.fileExtension = defaultConnect.fileExtension
    connection.unzip = defaultConnect.unzip
    connection.path = defaultConnect.path
    connection.remoteConnection = Object.keys(connections)[0]
  } else {
    props.history.push(`/admin/nitorInsights/${typeApp}/${appId}`)
  }
  props.setConfig({ ...configuration })
  props.setConnection({ ...connection })
  props.setViews({ ...views })
  props.setFormIsLoading(false)
  props.setPageIsLoading(false)
}

async function onSubmit (props, event) {
  let result = {}
  let successMessage

  let appConfiguration = createConfigRequestBody(props, event)
  let response

  if (props.displayMode === 'edit') {
    response = await editAppLoadByAppAndId(
      'insights',
      props.selectedAppId,
      appConfiguration,
      props.idLoad
    )
    successMessage = 'Load updated successfully'
  } else {
    response = await createAppLoad(
      'insights',
      props.selectedAppId,
      appConfiguration,
      uuidv4()
    )
    successMessage = 'Load created successfully'
  }

  if (response && response.success && _isMounted) {
    props.setValidationMessage(successMessage)
    props.setSubmitSuccess(true)

    setTimeout(function () {
      if (_isMounted) {
        props.setSubmitSuccess(false)
        props.setFormIsLoading(false)
        props.history.push(
          `/admin/nitorInsights/${props.typeApp}/${props.selectedAppId}`
        )
      }
    }, 5000)
  } else {
    // Show error message
    if (result) {
      let message = result.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

async function initFunction (props, appId, idLoad, typeApp) {
  const { connections, instance } = await getAppDetails(props, appId)
  const data = checkExpiration(props.enabledApps['insights'])
  props.setExpirationData(data)
  if (idLoad) {
    getAppLoad(props, appId, idLoad, typeApp, connections, data, instance)
  } else {
    setAppLoad(props, typeApp, connections, data, appId, instance)
  }
}

function createConfigRequestBody (props, formValues) {
  if (formValues.config.type === 'historical') {
    formValues.config.updateFrequency = 'oneTime'
  }
  const todayDate = moment(new Date()).format('MM/DD/YYYY')
  const connection = formValues.connection
  const params = {
    destination: {
      fileExtension: connection.fileExtension,
      path: connection.path ? connection.path : '',
      remoteConnection: connection.remoteConnection,
      nameConnection:
        connection.remoteConnections[connection.remoteConnection].name,
      unzip: connection.unzip
    },
    name: formValues.config.name,
    startDate: new Date(formValues.config.startDate).toISOString(),
    executionDay: formValues.config.executionDay,
    executionHour: formValues.config.executionHour,
    executionMinute: formValues.config.executionMinute,
    executionTime: new Date(
      todayDate + ' ' + formValues.config.executionTime
    ).toISOString(),
    type: formValues.config.type,
    updateFrequency: formValues.config.updateFrequency,
    views: formValues.views.viewsApi,
    creationDate: formValues.config.creationDate
  }
  if (props.displayMode === 'create') {
    params.creationDate = new Date().toISOString()
  }
  const requestBody = {
    api: props.typeApp,
    params: params
  }

  return requestBody
}

export default compose(
  connect(state => ({
    isAuthenticated: state.login.isAuthenticated,
    name: state.login.name,
    enabledApps: state.app.enabledApps
  })),
  withState('appList', 'setAppList', []),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('selectedApp', 'setSelectedApp', null),

  withState('config', 'setConfig', { ...configuration }),
  withState('connection', 'setConnection', { ...connection }),
  withState('views', 'setViews', { ...views }),
  withState('connections', 'setConnections', null),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('typeApp', 'setTypeApp', null),
  withState('instanceType', 'setInstanceType', 'sap'),
  withState('idLoad', 'setIdLoad', null),
  withState('load', 'setLoad', null),
  withState('displayMode', 'setDisplayMode', 'create'),
  withState('expirationData', 'setExpirationData', {}),
  withHandlers({
    onAppChanged: props => id => {
      props.history.push(`/admin/nitorInsights/${props.typeApp}/${id}`)
    },
    onAppSaved: props => async event => {
      props.setFormIsLoading(true)
      await onSubmit(props, event)
    },
    onClear: props => () => {
      props.history.push(`/admin/nitorInsights/${props.typeApp}/${props.selectedAppId}`)
    }
  }),
  lifecycle({
    componentDidMount () {
      _isMounted = true
      appId = this.props.match.params.id
      typeApp = this.props.match.params.type
      idLoad = this.props.match.params.idLoad
      this.props.setPageIsLoading(true)
      this.props.setTypeApp(typeApp)
      getAppList(this.props)
      initFunction(this.props, appId, idLoad, typeApp)
    }
  })
)(InsightsLoadView)
