import axios from 'axios'

import { ApiAdminInvoice } from 'utils/Constants'
import { createHeaders } from 'utils/functions'

export async function getBusinessRules({ filters }: GetBusinessRules): Promise<BusinessRule[]> {
  const url = `${ApiAdminInvoice.EndPoint}/management/businessRules`
  const headers = createHeaders()

  const filtersQuery = encodeURIComponent(JSON.stringify(filters))

  return axios({
    method: 'get',
    url: url,
    headers: headers,
    params: { filters: filtersQuery }
  }).then(response => response.data)
}
