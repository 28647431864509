import React from 'react'
// Form Validation
import { useGetBuyerAddress } from 'hooks/useMasterData'
import { useWatch } from 'react-hook-form'

import { HeaderFieldsStandard } from 'utils/Constants'

import AutoCompleteAsyncHeader from './AutoCompleteAsyncHeader'

const AddressSearch = props => {
  const { control, index, headerField, headerFieldsActions, appId, getValues, setValueForm } = props

  const { deleteErrorHeader } = headerFieldsActions

  const fieldType =
    headerField.field === HeaderFieldsStandard['companyProfile.billToAddress.fullAddress'].type
      ? 'shipTo'
      : 'billTo'

  const value = useWatch({
    name: 'headerFields',
    control
  })

  const companyCode = value.find(
    field => field.field === HeaderFieldsStandard['companyProfile.id'].type
  )

  const addresses = useGetBuyerAddress({
    companyCode: companyCode?.value ?? '',
    params: {
      type: fieldType,
      status: 'enabled',
      appId
    }
  })

  const options =
    addresses.data?.map(x => ({
      label: x.companyAddressId,
      value: x.fullAddress
    })) ?? []

  function onChangeAutoComplete(address) {
    const summaryFields = getValues('headerFields')
    const index = summaryFields.findIndex(summaryField => summaryField.field === headerField.field)

    if (index < 0) {
      return
    }

    if (address) {
      setValueForm(`headerFields.${index}.value`, address.label, {
        shouldValidate: true,
        shouldDirty: true
      })
    } else {
      setValueForm(`headerFields.${index}.value`, '', {
        shouldValidate: true,
        shouldDirty: true
      })
    }

    deleteErrorHeader({
      field: headerField.field
    })
  }

  return (
    <AutoCompleteAsyncHeader
      {...props}
      options={options}
      isLoading={addresses.isLoading || addresses.isFetching}
      customOptionLabel={option => option.label || option}
      customName={`headerFields.${index}.address`}
      onChangeAutoComplete={onChangeAutoComplete}
    />
  )
}

export default AddressSearch
