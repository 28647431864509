import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import React from 'react'

const useStyles = makeStyles(styles)
export const HeaderField = props => {
  const { summaryField, setTextInputRef, multiline, label, value, onChangeSummaryField } = props

  const classes = useStyles()

  return (
    <TextField
      style={{ width: '100%' }}
      id={summaryField.id}
      label={label ?? ''}
      variant="outlined"
      size="small"
      disabled={true}
      className={classes.readOnly}
      InputProps={{
        readOnly: true
      }}
      fullWidth
      defaultValue={value ?? ''}
      multiline={multiline}
      rows="3"
      onClick={e =>
        onChangeSummaryField({
          id: summaryField.id,
          type: 'header',
          ocr: null,
          shouldSetValue: false,
          page: summaryField.page
        })
      }
      inputRef={element => setTextInputRef(element, summaryField.id)}
    />
  )
}
