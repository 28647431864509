import RedirectUI, { getPathNewUi } from 'guards/RedirectUI'
import React from 'react'
import OldInvoiceAI from './OldInvoiceAI'
export default function InvoiceAI() {
  const path = getPathNewUi('/platform/invoiceAI/list')
  return (
    <RedirectUI path={path}>
      <OldInvoiceAI />
    </RedirectUI>
  )
}
