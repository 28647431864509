import React from 'react'
import MaterialTable from 'material-table'
import moment from 'moment'

// Material UI
import Grid from '@material-ui/core/Grid'
import TablePagination from '@material-ui/core/TablePagination'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

// Icons
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'

// Components
import { TablePaginationActions } from 'components/Invoice/TablePaginationActions'

// Constants
import { transactionLogType } from 'utils/Constants'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// View
import SyncLoader from 'components/SyncLoader/SyncLoader.js'

const useStyles = makeStyles(styles)

export default function LogsView(props) {
  const { transactionLogs, pagination, setPagination, refetch, isFetching, isLoading } = props
  const classes = useStyles()

  const handleChangePage = (event, newPage) => {
    setPagination(old => {
      return {
        ...old,
        page: newPage
      }
    })
  }

  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: true
    },
    {
      field: 'type',
      title: 'Type',
      render: rowData => transactionLogType[rowData.type]
    },
    {
      field: 'message',
      title: 'Message'
    },
    {
      field: 'transactionLogDate',
      title: 'Date',
      render: rowData => moment(rowData.transactionLogDate).format('MM/DD/YYYY HH:mm:ss'),
      customSort: (a, b) => {
        return moment(moment(b.transactionLogDate)).isBefore(moment(a.transactionLogDate)) ? 1 : -1
      },
      defaultSort: 'asc'
    }
  ]

  return (
    <Grid container justifyContent="center" spacing={3}>
      <SyncLoader showLoader={isFetching && !isLoading} />
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Tooltip title={'Refresh'}>
              <Fab
                size="small"
                color="primary"
                className={classes.floatRight + ' ' + classes.bgColorPrimary}
                onClick={() => refetch({ throwOnError: true })}
              >
                <RefreshRoundedIcon fontSize="small" />
              </Fab>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <p>
              <b>Logs:</b>
            </p>
            <MaterialTable
              columns={columns}
              isLoading={isLoading}
              data={transactionLogs?.rows ?? []}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false,
                emptyRowsWhenPaging: false,
                pageSize: pagination.rowsPage,
                pageSizeOptions: [10, 20, 30, 40, 50]
              }}
              onChangeRowsPerPage={pageSize => {
                setPagination({
                  page: 0,
                  rowsPage: pageSize,
                  lastEvaluatedKeys: []
                })
              }}
              components={{
                Pagination: props => {
                  return (
                    <TablePagination
                      {...props}
                      onChangePage={handleChangePage}
                      ActionsComponent={subProps => <TablePaginationActions {...subProps} pagination={pagination} />}
                    />
                  )
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
