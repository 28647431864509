import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
//import AuthNavbar from "components/Navbars/Auth/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/Auth/authStyle.js";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  //const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  /* const getActiveRoute = routes => {
    let activeRoute = "...";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        let myPath = routes[i].layout + routes[i].path
        myPath = myPath.replace("/:origin?","")
        if (
          window.location.href.indexOf(myPath) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  }; */
  return (
    <div>
      {/* <AuthNavbar brandText={getActiveRoute(props.authMenu)} {...rest} /> */}
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          /* style={{ backgroundImage: "url(" + getBgImage() + ")" }} */
        >
          <Switch>
            {getRoutes(props.authMenu)}
            {/* <Route exact path="/auth" to="/auth/login-page/:origin?"/> */}
            {/* <Route exact path="/" component={Home}/> */}
            <Redirect from="/auth" exact to="/auth/login-page/" />
          </Switch>
          <Footer gray />
        </div>
      </div>
    </div>
  );
}
