import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Heading from "components/Heading/Heading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/mainDashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <Card>
            <CardBody>
              <Heading
                textAlign="center"
                title="Welcome to the Relish App Center"
              />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={8} md={8}>
                      <iframe title="relishVideo" width="100%" height="315" src="https://www.youtube.com/embed/MdcXQ3INFQU" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <div className={classes.verticalCenter}>
                        <p>
                        Relish, the Application Platform to remedy enterprise ecosystem gaps.  Relish bridges enterprise data systems to help 
                        teams collaborate and work where they feel most comfortable.
                      </p>
                      <Button color="behance" className={classes.sapBtn}
                        href="https://www.sapappcenter.com/productLines/19#!/list/page/2" target="_blank">

                        Learn More
                      </Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
