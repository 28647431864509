import React, { useEffect, useState } from 'react'

import MaterialTable from 'material-table'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import DescriptionIcon from '@material-ui/icons/Description'
import GetAppIcon from '@material-ui/icons/GetApp'
import ViewListIcon from '@material-ui/icons/ViewList'
import GridOnIcon from '@material-ui/icons/GridOn'
// components
import { Grid } from '@material-ui/core'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Fab from '@material-ui/core/Fab'
import { Tooltip } from '@material-ui/core'

// Constants
import { invoiceStatus, statusHistory } from 'utils/Constants'

// functions
import { statusColor } from 'utils/functions'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js'
import { formatDate } from 'utils/functions'
import { useGetTenantConfig } from 'hooks/useTenantConfig'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import { useGetInvoicesByImportFile } from 'hooks/useInvoices.js'
import { useGetImportInvoice, useGetImportInvoiceLogs } from 'hooks/useImportInvoices.js'

const useStyles = makeStyles(styles)

export default function InvoiceImportDetails(props) {
  const { importId } = useParams()

  const [createError, setCreateError] = useState({ isOpen: false, message: '' })

  const classes = useStyles()

  const { data: configTenant, isLoading: isLoadingTenantConfig } = useGetTenantConfig()

  const invoices = useGetInvoicesByImportFile(configTenant, importId)

  const {
    data: importInvoice,
    isLoading,
    error,
    isError,
    refetch,
    isFetching
  } = useGetImportInvoice(configTenant, importId)

  const importLogs = useGetImportInvoiceLogs(configTenant, importId)

  useEffect(() => {
    if (!isError) {
      return
    }
    setCreateError({
      isOpen: true,
      message: error.response?.data?.message || 'Something went wrong, try again later'
    })
  }, [isError, error])

  const breadcrumbViews = [
    {
      name: 'Imported Files',
      url: `/admin/invoiceAI/imports`,
      icon: GridOnIcon
    },
    {
      name: 'File Details',
      url: `/admin/invoiceAI/import/${importId}/details`,
      icon: ViewListIcon
    }
  ]

  const columnsHistory = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1
    },
    {
      field: 'date',
      title: 'Date',
      render: rowData => formatDate({ date: rowData.date }),
      customSort: (a, b) => {
        return moment(moment(b.date)).isBefore(moment(a.date)) ? 1 : -1
      },
      defaultSort: 'desc'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => statusHistory[rowData.status] ?? rowData.status
    },
    {
      field: 'description',
      title: 'Description',
      render: rowData => {
        const liData = rowData.description.map((data, i) => {
          return (
            <li key={i}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span>{data}</span>
              </div>
            </li>
          )
        })

        return <ul>{liData}</ul>
      }
    }
  ]

  const actions = [
    {
      position: 'row',
      action: rowData => ({
        icon: () => (
          <NavLink to={`/admin/invoiceAI/${rowData.invoiceId}/details`}>
            <DescriptionIcon fontSize="small" style={{ color: '#081c3e' }} />
          </NavLink>
        ),
        tooltip: 'Details'
      })
    }
  ]

  const invoiceColumns = [
    {
      title: 'Relish Id',
      field: 'invoiceId'
    },
    {
      field: 'invoiceNumber',
      title: 'Invoice Id'
    },
    {
      field: 'invoiceDate',
      title: 'Invoice Date',
      render: rowData =>
        rowData.invoiceDate ? rowData.invoiceDate.substring(0, 10) : 'Invalid Date'
    },
    {
      field: 'supplierId',
      title: 'ERPVendorId'
    },
    {
      field: 'orderId',
      title: 'Purchase Order'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: statusColor(rowData.status) }}>{invoiceStatus[rowData.status]}</p>
      )
    },
    {
      field: 'subStatus',
      title: 'Substatus',
      render: rowData => (
        <p style={{ color: statusColor(rowData.subStatus) }}>
          {invoiceStatus[rowData.subStatus] ?? rowData.subStatus}
        </p>
      )
    }
  ]

  function returnEmailAddonsTab() {
    return (
      <Grid item xs={12} sm={12} md={12}>
        {importInvoice ? (
          <Grid container justifyContent="center">
            <Grid item xs={12} style={{ marginBottom: '25px' }}>
              <Button
                color="primary"
                size="small"
                className={classes.floatRight + ' ' + classes.colorPrimary}
                onClick={() => {
                  refetch({ throwOnError: true })
                  invoices.refetch({ throwOnError: true })
                  importLogs.refetch({ throwOnError: true })
                }}
              >
                <RefreshRoundedIcon fontSize="small" />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <p style={{ fontSize: '20px', color: 'grey' }}>
                Status:{' '}
                <span
                  style={{
                    color: importInvoice.status
                      ? statusColor(importInvoice.status) !== 'black'
                        ? statusColor(importInvoice.status)
                        : 'grey'
                      : 'grey'
                  }}
                >
                  {importInvoice.status ? invoiceStatus[importInvoice.status] : null}
                </span>
              </p>
            </Grid>
            <Grid item xs={6}>
              <p style={{ color: 'grey' }}>
                File Id: {importInvoice.importId ? importInvoice.importId : null}
              </p>
            </Grid>
            <Grid item xs={6}>
              <p style={{ color: 'grey' }}>
                Upload Date:{' '}
                {importInvoice.uploadDate
                  ? formatDate({
                      date: importInvoice.uploadDate,
                      options: {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hourCycle: 'h23'
                      }
                    })
                  : null}
              </p>
            </Grid>
            <Grid item xs={6}>
              <p style={{ color: 'grey' }}>File Name: {importInvoice.inputFile.name}</p>
            </Grid>
            <Grid item xs={6}>
              <p style={{ color: 'grey' }}>User Name: {importInvoice.userInfo.name}</p>
            </Grid>
            <Grid item xs={6}>
              <p style={{ color: 'grey' }}>User Email: {importInvoice.userInfo.email}</p>
            </Grid>

            <Grid item xs={12}>
              <Tooltip title={'Export document'}>
                <Fab
                  size="small"
                  color="primary"
                  style={{ margin: '0 5px 0 5px', color: 'white' }}
                  className={classes.floatRight + ' ' + classes.bgColorPrimary}
                  disabled={!importInvoice.urlFile ? true : false}
                  onClick={() => window.location.assign(importInvoice.urlFile)}
                >
                  <GetAppIcon fontSize="small" />
                </Fab>
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={12} style={{ marginTop: '15px' }}>
              <h4>Invoices</h4>
              <Grid container justifyContent="center" style={{ marginTop: '5px' }}>
                <Grid item xs={12} sm={12} md={12}>
                  <MaterialTable
                    columns={invoiceColumns}
                    data={invoices.data ?? []}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: true,
                      showTitle: false,
                      emptyRowsWhenPaging: false
                    }}
                    actions={actions}
                    isLoading={invoices.isLoading}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} style={{ marginTop: '15px' }}>
              <h4>Import History</h4>
              <Grid container justifyContent="center" style={{ marginTop: '5px' }}>
                <Grid item xs={12} sm={12} md={12}>
                  <MaterialTable
                    columns={columnsHistory}
                    data={importLogs.data ?? []}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: true,
                      showTitle: false,
                      emptyRowsWhenPaging: false
                    }}
                    isLoading={importLogs.isLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          {isLoading || isLoadingTenantConfig ? (
            <Card>
              <Grid container justifyContent="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            </Card>
          ) : (
            <>
              <Card>
                <CardHeader>
                  <h3 className={classes.formHeader} style={{ color: 'grey' }}>
                    File Details
                  </h3>
                </CardHeader>
                <SyncLoader showLoader={isFetching && !isLoading} />
                <CardBody>{returnEmailAddonsTab()}</CardBody>
              </Card>
            </>
          )}
        </Grid>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={(createError.message = '')}
          open={createError.isOpen}
          closeNotification={() => setCreateError({ isOpen: false, message: '' })}
          close
        />
      </Grid>
    </Fade>
  )
}
