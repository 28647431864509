import axios from 'axios'

import { ApiAdminInvoice } from 'utils/Constants'
// eslint-disable-next-line
import { createHeaders, getUserTenant } from 'utils/functions'

export async function getEmailConfiguration(appId) {
  const url = `${ApiAdminInvoice.EndPoint}/configurations/apps/${appId}/emailInvoiceOrigin`
  const requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function createEmailOriginConfiguration(body) {
  const url = `${ApiAdminInvoice.EndPoint}/configurations/invoice-origins/email`
  const requestHeaders = createHeaders()
  console.log('body', body)
  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export async function updateEmailOriginConfiguration(id, body) {
  const url = `${ApiAdminInvoice.EndPoint}/configurations/invoice-origins/email/${id}`
  const requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export async function getTenantProcess(appId) {
  const url = `${ApiAdminInvoice.EndPoint}/configurations/apps/${appId}/invoice-origins/process`
  const requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getEmailOriginConfigById(id, appId) {
  const url = `${ApiAdminInvoice.EndPoint}/configurations/apps/${appId}/invoice-origins/email/${id}`
  const requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getAllOriginConfigs(appId) {
  const url = `${ApiAdminInvoice.EndPoint}/configurations/apps/${appId}/invoice-origins`
  const requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getApiOriginConfigById(id, appId) {
  const url = `${ApiAdminInvoice.EndPoint}/configurations/apps/${appId}/invoice-origins/api/${id}`
  const requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function createApiOriginConfiguration(body) {
  const url = `${ApiAdminInvoice.EndPoint}/configurations/invoice-origins/api`
  const requestHeaders = createHeaders()
  console.log('body', body)
  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export async function updateApiOriginConfiguration(id, body) {
  const url = `${ApiAdminInvoice.EndPoint}/configurations/invoice-origins/api/${id}`
  const requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export async function deleteOriginConfiguration(id, body) {
  const url = `${ApiAdminInvoice.EndPoint}/configurations/invoice-origins/${id}`
  const requestHeaders = createHeaders()

  return axios({
    method: 'delete',
    url: url,
    headers: requestHeaders,
    data: body
  })
}
