import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Select, MenuItem } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import ImportExportIcon from '@material-ui/icons/ImportExport'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Button from 'components/CustomButtons/Button.js'
import NavPills from 'components/NavPills/NavPills.js'
import FormControl from '@material-ui/core/FormControl'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import InputLabel from '@material-ui/core/InputLabel'
// functions
// import { statusColor } from 'utils/functions'

// Constants
//import { invoiceStatus } from 'utils/Constants'
//import Fab from '@material-ui/core/Fab'
//import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceUserImportStyle'
import { useGetAvailableRoles } from 'hooks/useAuth.js'
import { useGetTenantConfig } from 'hooks/useTenantConfig.js'
// import { useGetImportInvoiceHistory } from 'hooks/useInvoices.js'
import { useCreateTenantConfig, useUpdateTenantConfig } from 'hooks/useTenantConfig.js'
import { RolesHeader } from './RolesHeader.js'
//import SyncLoader from 'components/SyncLoader/SyncLoader.js'
import { configTenantKey } from 'hooks/useTenantConfig.js'
import { useQueryClient } from 'react-query'
import { getUserTenant } from 'utils/functions.js'

const useStyles = makeStyles(styles)

const defaultMappingType = 'groups'
const tenantConfigurationsDefault = {
  userAutomationEnabled: false,
  mappedRoles: [],
  userIntegration: {
    mappingType: defaultMappingType
  }
}

export default function UserImport(props) {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const { data: rolesList, isLoading: isLoadingRoles } = useGetAvailableRoles()
  const {
    data: tenantConfigurations,
    isLoading: isLoadingTenantConfig,
    isError: isErrorTenantConfig
  } = useGetTenantConfig()

  /*
  const {
    data: rows,
    isLoading: rowIsLoading,
    refetch,
    isFetching,
    isError: isErrorGetImportHistory,
    error: errorGetImportHistory
  } = useGetImportInvoiceHistory(tenantConfigurations)
*/
  const {
    mutate: updateTenantConfig,
    isLoading: isUpdatedLoading,
    isError: isErrorUpdateTenantConfig,
    isSuccess: isSucessUpdateTenantConfig
  } = useUpdateTenantConfig()

  const {
    mutate: postTenantConfig,
    isLoading: isCreationLoading,
    isError: isErrorPostTenantConfig,
    isSuccess: isSucessPostTenantConfig
  } = useCreateTenantConfig()

  const [existingConfiguration, setExistingConfiguration] = useState('loading')
  const [createError, setCreateError] = useState({ isOpen: false, message: '' })

  const [tenantConfig, setTenantConfig] = useState(tenantConfigurationsDefault)
  const [submitSuccess, setSubmitSuccess] = useState({
    isOpen: false,
    message: ''
  })

  useEffect(() => {
    if (isErrorTenantConfig) {
      setExistingConfiguration('new')
      return
    }

    if (!isLoadingTenantConfig) {
      return
    }

    setExistingConfiguration('existing')
  }, [isErrorTenantConfig, isLoadingTenantConfig])
  /*
  useEffect(() => {
    if (!isErrorGetImportHistory) {
      return
    }
    const { response } = errorGetImportHistory
    setCreateError({
      isOpen: true,
      message:
        response?.data?.message ||
        'Something went wrong, please try again later.'
    })
  }, [isErrorGetImportHistory, errorGetImportHistory])
*/
  useEffect(() => {
    if (isErrorTenantConfig) {
      return
    }
    if (isLoadingTenantConfig) {
      return
    }

    const mappingType = tenantConfigurations.userIntegration.mappingType || defaultMappingType
    const userTenantConfiguration = {
      ...tenantConfigurations,
      userIntegration: { ...tenantConfigurations.userIntegration, mappingType: mappingType }
    }

    setTenantConfig(userTenantConfiguration)
  }, [isLoadingTenantConfig, isErrorTenantConfig, tenantConfigurations])

  useEffect(() => {
    if (isSucessUpdateTenantConfig) {
      setSubmitSuccess({
        isOpen: true,
        message: 'Tenant Configuration updated succesfully!'
      })
    }

    if (isSucessPostTenantConfig) {
      setSubmitSuccess({
        isOpen: true,
        message: 'Tenant Configuration created successfully!'
      })
    }
    setExistingConfiguration('existing')
  }, [isSucessUpdateTenantConfig, isSucessPostTenantConfig])

  useEffect(() => {
    if (isErrorPostTenantConfig || isErrorUpdateTenantConfig) {
      setCreateError({
        isOpen: true,
        message: 'Something went wrong, please try again later.'
      })
    }
  }, [isErrorPostTenantConfig, isErrorUpdateTenantConfig])
  /*
  const columns = [
    {
      field: 'no.',
      title: 'No',
      render: rowData => rowData.tableData.id + 1
    },
    {
      field: 'fileName',
      title: 'File Name'
    },
    {
      field: 'uploadDate',
      title: 'Upload Date',
      render: rowData => new Date(rowData.uploadDate).toLocaleString('en-US'),
      defaultSort: 'desc'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: statusColor(rowData.status) }}>
          {invoiceStatus[rowData.status]}
        </p>
      )
    }
  ]
*/
  const roleColumns = [
    {
      field: 'no.',
      title: 'No.',
      render: rowData => rowData.tableData.id + 1,
      editable: 'never',
      width: '50px'
    },
    {
      field: 'name',
      title: 'Custom Group',
      render: rowData => rowData.name
    },
    {
      field: 'relishRole',
      title: 'Relish Role',
      render: rowData => rowData.relishRole,
      editable: 'never'
    }
  ]

  const roleActions = [
    {
      icon: () => <DeleteForeverIcon style={{ color: '#081c3e' }} />,
      tooltip: 'Delete Mapping',
      onClick: (event, rowData) => {
        const index = rowData.tableData.id
        const copyMappedRoles = [...tenantConfig.mappedRoles]
        copyMappedRoles.splice(index, 1)
        setTenantConfig({ ...tenantConfig, mappedRoles: copyMappedRoles })
      }
    }
  ]

  let breadcrumbViews = [
    {
      name: 'User Integration',
      url: `/admin/invoiceAI/userImport`,
      icon: ImportExportIcon
    }
  ]

  function onSubmit() {
    if (existingConfiguration === 'existing') {
      updateTenantConfig(tenantConfig, {
        onSettled: () => {
          queryClient.invalidateQueries([configTenantKey, getUserTenant()])
        }
      })
    } else {
      postTenantConfig(tenantConfig)
    }
  }

  function addRoleTenant(newRole) {
    setTenantConfig(oldTenant => ({
      ...oldTenant,
      mappedRoles: [...oldTenant.mappedRoles, newRole]
    }))
  }

  const mappingChangeHandler = event => {
    setTenantConfig(previousTenant => {
      return {
        ...previousTenant,
        userIntegration: { ...previousTenant.userIntegration, mappingType: event.target.value }
      }
    })
  }

  function returnConfigurationArea() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer xs={11} sm={11} md={11}>
          <GridItem xs={12} sm={12} md={12}>
            <FormControl className={classes.selectFormControl}>
              <FormControlLabel
                classes={{
                  label: classes.label,
                  labelPlacementStart: classes.activeToggle
                }}
                label={'Enable User Automation Process'}
                labelPlacement="end"
                control={
                  <Switch
                    disabled={false}
                    checked={tenantConfig.userAutomationEnabled}
                    onChange={event =>
                      setTenantConfig({
                        ...tenantConfig,
                        userAutomationEnabled: event.target.checked
                      })
                    }
                    value={false}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                }
              />
            </FormControl>
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Mapping Type*
              </InputLabel>
              <Select
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                value={tenantConfig.userIntegration.mappingType}
                onChange={mappingChangeHandler}
                inputProps={{ name: `mappingType`, id: `mappingType` }}
              >
                {[
                  { name: 'Role Mapping', code: 'roles' },
                  { name: 'Group Mapping', code: 'groups' }
                ].map((mapping, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={mapping.code}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      style={{
                        overflowX: 'auto',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {mapping.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <Card className={classes.appBar}>
            <CardBody>
              <RolesHeader addRoleTenant={addRoleTenant} rolesList={rolesList} />
              <MaterialTable
                columns={roleColumns}
                data={tenantConfig.mappedRoles}
                actions={roleActions}
                options={{
                  columnsButton: true,
                  actionsColumnIndex: -1,
                  filtering: true,
                  sorting: true,
                  showTitle: false,
                  emptyRowsWhenPaging: false
                }}
                cellEditable={{
                  cellStyle: {},
                  onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                    return new Promise((resolve, reject) => {
                      const index = rowData.tableData.id
                      const editedField = columnDef.field
                      const copyMappedRoles = [...tenantConfig.mappedRoles]
                      copyMappedRoles[index][editedField] = newValue
                      setTenantConfig({
                        ...tenantConfig,
                        mappedRoles: [...copyMappedRoles]
                      })
                      setTimeout(resolve, 0)
                    })
                  }
                }}
              />
            </CardBody>
          </Card>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem md={1}>
            <Button
              disabled={isCreationLoading || isUpdatedLoading}
              variant="outlined"
              color="primary"
              onClick={() => onSubmit()}
            >
              {!(isCreationLoading || isUpdatedLoading) ? 'Save' : 'Loading...'}
            </Button>
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }
  /*
  function returnHistoryTab() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12}>
            {rows ? (
              <Fab
                size="small"
                color="primary"
                className={classes.floatRight + ' ' + classes.bgColorPrimary}
                onClick={() => refetch()}
              >
                <RefreshRoundedIcon fontSize="small" />
              </Fab>
            ) : null}
          </GridItem>
          <GridItem xs={12} sm={12}>
            <GridContainer justify="center">
              <SyncLoader showLoader={isFetching && !rowIsLoading} />
              {rowIsLoading ? (
                <CircularProgress />
              ) : (
                <GridItem xs={12} sm={12} md={12}>
                  <MaterialTable
                    columns={columns}
                    data={rows || []}
                    options={{
                      actionsColumnIndex: -1,
                      sorting: true,
                      showTitle: false
                    }}
                  />
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }
  */

  function returnTabsToDisplay() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h3 className={classes.formHeader}>User Integration Configurations</h3>
          </CardHeader>
          <CardBody>
            <NavPills
              action={actions => {
                if (actions) {
                  props.updateHeightFunction(actions.updateHeight)
                }
              }}
              animateHeight={false}
              color="primary"
              tabs={[
                {
                  tabButton: 'User Integration Options',
                  tabContent: returnConfigurationArea()
                } /* ,
                {
                  tabButton: 'User Integration History',
                  tabContent: returnHistoryTab()
                } */
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          {isLoadingRoles || isLoadingTenantConfig ? (
            <Card>
              <GridContainer justify="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </GridContainer>
            </Card>
          ) : (
            returnTabsToDisplay()
          )}
        </GridItem>
        <Snackbar
          place="br"
          color="success"
          icon={AddAlert}
          message={submitSuccess.message}
          open={submitSuccess.isOpen}
          closeNotification={() => setSubmitSuccess({ ...submitSuccess, isOpen: false })}
          close
        />
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={createError.message}
          open={createError.isOpen}
          closeNotification={() => setCreateError({ ...createError, isOpen: false })}
          close
        />
      </GridContainer>
    </Fade>
  )
}
