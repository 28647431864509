import React, { useRef, useState } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@material-ui/core/Fade'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import AssessmentIcon from '@material-ui/icons/Assessment'
import TouchApp from '@material-ui/icons/TouchApp'
import Add from '@material-ui/icons/PlaylistAdd'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

// Views
import AppMainMenu from '../CustomGenericViews/AppMainMenu.js'
import Wizard from '../../components/Wizard/Wizard.js'

// Services
import { updateApp } from 'services/apiPricebook'
import { showLoading, hideLoading } from 'utils/functions.js'
import { getAppDetails } from './PriceBookContainer'
import { cloneObject } from 'utils/functions.js'
import ParametersAccordion from '../CustomGenericViews/ParametersAccordion.js'

const useStyles = makeStyles(styles)

export default function PriceBook(props) {
  const [service, setService] = useState('')
  const refreshTableFunction = useRef(() => {})
  const wizardRef = useRef(null)

  let breadcrumbViews = [
    {
      name: 'PriceBook',
      url: '/admin/pricebook/configurations',
      icon: AssessmentIcon
    }
  ]

  function executeScroll() {
    if (wizardRef.current) {
      wizardRef.current.scrollIntoView()
    }
  }

  async function saveToDataBase(
    configurationProps,
    rollbackFunction,
    successMessage,
    refreshPage = false,
    changeConfigured = true,
    errorMessage = 'Something went wrong, please try again later'
  ) {
    let newConfigurationProp = cloneObject(configurationProps)

    delete newConfigurationProp.displayMode
    delete newConfigurationProp.mapping
    delete newConfigurationProp.localConfis
    delete newConfigurationProp.pk
    delete newConfigurationProp.sk
    delete newConfigurationProp.tenantId
    let keys = Object.keys(newConfigurationProp)
    keys.forEach(key => {
      if (newConfigurationProp[key] && typeof newConfigurationProp[key] === 'object') {
        if (newConfigurationProp[key].configurationOnClick) {
          delete newConfigurationProp[key].configurationOnClick
        }
        if (newConfigurationProp[key].parameters) {
          delete newConfigurationProp[key].parameters
        }
        if (newConfigurationProp[key].mappings) {
          delete newConfigurationProp[key].mappings
        }
      }
    })
    showLoading()
    executeScroll()

    let response = await updateApp(props.validationsForm.appId, newConfigurationProp)
    if (response && response.success) {
      props.setValidationMessage(successMessage)
      props.setSubmitSuccess(true)
      setTimeout(() => {
        props.setSubmitSuccess(false)
      }, 5000)
      await getAppDetails(props, props.validationsForm.appId)
    } else {
      props.setValidationMessage(errorMessage)
      props.setCreateError(true)
      setTimeout(() => {
        props.setCreateError(false)
      }, 5000)
      if (rollbackFunction) {
        rollbackFunction(() => {
          props.setRefresh(!props.refresh)
          if (refreshTableFunction.current) refreshTableFunction.current()
        })
      }
    }
    hideLoading()
  }

  const classes = useStyles()

  async function onSaveApp(params) {
    await props.onAppSaved(params)
  }

  function getView() {
    if (props.pageIsLoading) {
      return <div></div>
    }

    function scrolltoTop() {
      let element = document.getElementById('MainGrid')
      if (element) element.scrollTo(0, 0)
    }

    function getAdminSteps() {
      let validationInitialState = props.validationsForm

      let allGeneralParameters =
        Object.keys(props.validationsForm.parameters).length > 0
          ? props.validationsForm.parameters
          : {}

      let customization = {}
      let generalParameters = {}
      let coreApi = {}
      let parameterKeys = Object.keys(allGeneralParameters)
      parameterKeys.forEach(key => {
        if (allGeneralParameters[key].includeIn === 'coreApi') {
          coreApi[key] = allGeneralParameters[key]
        } else {
          generalParameters[key] = allGeneralParameters[key]
        }
      })

      let mapping = {
        setService: setService,
        service: service,
        configureService: props.displayMode === 'create',
        configureRealm: false,
        enabled: props.validationsForm.enabled,
        mainSwitchText: 'Enable PriceBook',
        mainSwitchOnChanged: (event, state) => {},
        title: 'PriceBook Settings',
        topChildren: '',
        bottomChildren: <div></div>,
        applicationTitle: 'Application',
        switchChildrenTitle: '',
        switchChildren: {},
        additionalChildrenTitle: '',
        additionalConfigurationsChildren: {}
      }

      if (props.displayMode === 'create') {
        validationInitialState.env = ''
        mapping = {
          setService: setService,
          configureService: props.displayMode === 'create',
          configureRealm: false,
          enabled: props.validationsForm.enabled,
          mainSwitchText: 'Enable PriceBook',
          mainSwitchOnChanged: (event, state) => {},
          title: 'PriceBook Settings',
          topChildren: '',
          bottomChildren: <div></div>,
          applicationTitle: 'Application',
          switchChildrenTitle: '',
          switchChildren: {},
          additionalChildrenTitle: '',
          additionalConfigurationsChildren: {}
        }
      }

      validationInitialState.mapping = mapping

      let steps = [
        {
          stepName: 'Configuration',
          stepComponent: AppMainMenu,
          stepId: 'validations',
          initialState: validationInitialState
        }
      ]
      let connectivityParameters = []
      if (props.displayMode === 'edit') {
        steps.push({
          stepName: 'Connectivity',
          stepComponent: ParametersAccordion,
          stepId: 'connectivity',
          initialState: {
            parameters: connectivityParameters
          }
        })
        if (Object.keys(generalParameters).length > 0) {
          connectivityParameters.push({
            title: 'General Configurations',
            parentId: 'GeneralConfigurations',
            conectivityMapping: {
              env: props.validationsForm.appId,
              app: 'priceBook',
              service: props.validationsForm.service,
              setService: setService,
              hideServiceName: true,
              title: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              fieldsTitle: 'Integration',
              hideTestConnection: true,
              fields: generalParameters
            }
          })
        }
        if (props.validationsForm.service === 'coupa') {
          connectivityParameters.push({
            title: 'Core',
            parentId: 'coreApi',
            conectivityMapping: {
              service: props.validationsForm.service,
              setService: setService,
              env: props.validationsForm.appId,
              app: 'priceBook',
              parametersPosition: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              hideServiceName: true,
              hideTestConnection: false,
              title: '',
              fieldsTitle: '',
              fields: coreApi,
              customConnection: true,
              pricebookUrl: true
            }
          })
        }
        if (Object.keys(customization).length > 0) {
          connectivityParameters.push({
            title: 'Customization',
            parentId: 'customization',
            conectivityMapping: {
              env: props.validationsForm.appId,
              app: 'priceBook',
              service: props.validationsForm.service,
              setService: setService,
              hideServiceName: true,
              title: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              fieldsTitle: '',
              hideTestConnection: true,
              fields: customization
            }
          })
        }
      }
      return steps
    }

    function returnWizardToDisplay() {
      return (
        <div ref={wizardRef}>
          <Wizard
            id="Wizard"
            color="primary"
            validate
            hideNavigation={props.displayMode === 'create'}
            title="PriceBook"
            steps={getAdminSteps()}
            subtitle=""
            finishButtonClick={e => {
              if (props.displayMode === 'create') {
                if (e.validations && e.validations.env) onSaveApp(e)
                else {
                  props.setValidationMessage('Please Add Application Name')
                  props.setCreateError(true)
                  setTimeout(() => {
                    props.setCreateError(false)
                  }, 5000)
                }
              } else {
                let validationsForm = props.validationsForm
                validationsForm.enabled = e.validations.mapping.enabled
                saveToDataBase(validationsForm, () => {}, 'Saved', true, true)
              }
            }}
          />
        </div>
      )
    }

    return (
      <Fade in={true} timeout={250}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={3} className={classes.padding10}>
            <Card className={classes.appBar}>
              <CardHeader>
                Application List
                <Tooltip title="Create new app">
                  <Button
                    justIcon
                    round
                    simple
                    color="primary"
                    className={classes.marginRight}
                    onClick={() => {
                      props.onCreateButton()
                      scrolltoTop()
                    }}
                  >
                    <Add className={classes.icons} />
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <List style={{ width: '100%' }}>
                  {props.appList.map((prop, key) => {
                    return (
                      <ListItem
                        className={classes.listItem}
                        button
                        selected={props.selectedAppId === prop.appId}
                        key={key}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ListItemAvatar>
                            {props.selectedAppId === prop.appId ? (
                              <Avatar>
                                <TouchApp />
                              </Avatar>
                            ) : null}
                          </ListItemAvatar>
                          <ListItemText
                            className={classes.listItemText}
                            primary={prop.appName}
                            secondary={`Type: ${prop.type}`}
                            onClick={() => {
                              props.onAppChanged(prop.appId)
                              scrolltoTop()
                            }}
                          />
                        </div>
                      </ListItem>
                    )
                  })}
                </List>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={9} className={classes.padding10}>
            <Breadcrumbs views={breadcrumbViews} />
            <div style={{ position: 'relative' }}>
              {props.formIsLoading ? (
                <Card>
                  <GridContainer justify="center">
                    <div className={classes.circularProgress}>
                      <CircularProgress color="inherit" />
                    </div>
                  </GridContainer>
                </Card>
              ) : props.selectedAppId ||
                props.displayMode === 'edit' ||
                props.displayMode === 'create' ? (
                <Card>
                  <GridContainer spacing={4}>
                    <GridItem xs={12}>{React.cloneElement(returnWizardToDisplay())}</GridItem>
                  </GridContainer>
                </Card>
              ) : (
                <Card>
                  <CardBody>
                    <div className={classes.typo}>
                      <div className={classes.note}>No apps created</div>
                      <h6>Click on the create button to start...</h6>
                    </div>
                  </CardBody>
                </Card>
              )}
            </div>
          </GridItem>
          <Snackbar
            place="br"
            color="success"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.submitSuccess}
            closeNotification={() => props.setSubmitSuccess(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.createError}
            closeNotification={() => props.setCreateError(false)}
            close
          />
        </GridContainer>
      </Fade>
    )
  }

  if (props.refresh) {
    return <div>{getView()}</div>
  } else return getView()
}
