import React from 'react'
import PropTypes from 'prop-types'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
//import DataTable from "react-data-table-component";
import MUIDataTable from 'mui-datatables'

// style
import style from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Constants
import { invoiceViewsAriba, ignoreInvoiceViewsAriba } from 'utils/Constants'

const columns = [
  {
    name: 'view',
    label: 'Item',
    options: {
      filter: true,
      sort: true
    }
  }
]

let selectedViews = []
let selectedViewsApi = []

class views extends React.Component {
  constructor(props) {
    super(props)
    this.state = props.initialState
  }

  sendState() {
    return this.state
  }

  isValidated() {
    if (this.state.views.length > 0) {
      return true
    }
    return false
  }

  formatView(typeApp, view) {
    switch (typeApp) {
      case 'downstream':
      case 'procurement':
        return view
      default:
        return this.isFMDTable(view) ? view : view.toLowerCase()
    }
  }

  isFMDTable(entity) {
    let isThisTable = false
    if (entity) {
      const reg = new RegExp('[0-9]+.fmd_[0-9]+..*')
      isThisTable = reg.test(entity)
    }
    return isThisTable
  }

  getViewsToDisplay() {
    const others = invoiceViewsAriba[this.state.typeApp].map(view => {
      return {
        id: view.viewName,
        view: view.displayName + ': ' + view.viewName,
        api: this.state.typeApp
      }
    })
    const filteredViews = this.filterViews(others)

    return [...this.state.customViews, ...filteredViews]
  }

  filterViews(views) {
    if (!this.state?.appConfiguration?.service || !views || !views.length) {
      return views
    }
    const ignoreList =
      ignoreInvoiceViewsAriba?.[this.state?.appConfiguration?.service]?.[this.state.typeApp] || []
    if (!ignoreList || !ignoreList.length) {
      return views
    }

    const filteredViews = views.filter(x => !ignoreList.some(view => x.id === view))

    return filteredViews
  }

  render() {
    const { classes } = this.props
    let viewsArray = this.state.typeApp ? this.getViewsToDisplay() : []

    const selectedRows = this.state.views
      ? this.state.views.map(view => {
          return viewsArray.findIndex(item => item.id.toLowerCase() === view.toLowerCase())
        })
      : []

    const options = {
      download: false,
      filter: false,
      print: false,
      viewColumns: false,
      selectToolbarPlacement: 'none',
      rowsSelected: selectedRows,
      onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
        selectedViews = rowsSelected.map(row => {
          return viewsArray[row].id
        })
        selectedViewsApi = rowsSelected.map(row => {
          return {
            view: this.formatView(this.state.typeApp, viewsArray[row].id),
            api: viewsArray[row].api
          }
        })
        this.setState({ views: [...selectedViews] })
        this.setState({ viewsApi: [...selectedViewsApi] })
      },
      selectableRowsOnClick: true
    }

    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              {this.state.typeApp === 'upstream' || this.state.typeApp === 'downstream'
                ? 'Integration Toolkit Names'
                : 'Entity types'}
            </h4>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer className={classes.appRow}>
                  <GridItem xs={12} sm={12}>
                    <MUIDataTable
                      title={
                        this.state.typeApp === 'upstream' || this.state.typeApp === 'downstream'
                          ? ' '
                          : ''
                      }
                      data={viewsArray}
                      columns={columns}
                      options={options}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }
}

views.propTypes = {
  classes: PropTypes.object
}

export default withStyles(style)(views)
