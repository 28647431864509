import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import WarningIcon from '@material-ui/icons/Warning'
import { ReactComponent as Excel } from 'assets/img/invoices/excel.svg'
import { ReactComponent as Attachment } from 'assets/img/invoices/paper-clip.svg'
import { ReactComponent as Pdf } from 'assets/img/invoices/pdf.svg'
import { ReactComponent as Picture } from 'assets/img/invoices/picture.svg'
import { ReactComponent as Word } from 'assets/img/invoices/word.svg'
import { ReactComponent as XmlFile } from 'assets/img/invoices/xml-file-format-symbol.svg'
import styles from 'assets/jss/material-dashboard-pro-react/components/emailModalStyle'
import DOMPurify from 'dompurify'
import { useGetEmailDetailsInvoice } from 'hooks/useInvoices'
import PropTypes from 'prop-types'
import React from 'react'
import { formatDate, getExtensionFile } from 'utils/functions'
const useStyles = makeStyles(styles)

function getIconFile(extension) {
  switch (extension) {
    case 'docx':
      return <Word width="20px" height="20px" />
    case 'xlsx':
      return <Excel width="20px" height="20px" />
    case 'pdf':
      return <Pdf width="20px" height="20px" />
    case 'xml':
      return <XmlFile width="20px" height="20px" />
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <Picture width="20px" height="20px" />

    default:
      return <Attachment width="20px" height="20px" />
  }
}

export function EmailDialog(props) {
  const classes = useStyles()
  const { onClose, open, recordId, tenantRequest } = props

  const { data: email, isLoading, isError } = useGetEmailDetailsInvoice(
    recordId,
    tenantRequest.configTenant
  )

  const handleClose = () => {
    onClose()
  }

  if (!recordId) {
    return null
  }

  const attachmentsJsx = attachmentFiles => {
    if (!attachmentFiles) {
      return null
    }

    const attachments = attachmentFiles.map(attachment => {
      return (
        <div
          style={{
            padding: '10px',
            justifyContent: 'center',
            boxShadow: 'rgba(50, 50, 93, 0.1) 2px 2px 4px',
            border: '1px solid rgba(0, 0, 0, 0.14)',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            margin: '5px'
          }}
          key={attachment.name}
        >
          {getIconFile(getExtensionFile(attachment.fileName ?? attachment.name))}
          <a
            href={attachment.presignedUrl}
            download
            style={{ marginLeft: '5px' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {attachment.fileName ?? attachment.name}
          </a>
        </div>
      )
    })

    return (
      <>
        <div style={{ borderTop: '1px solid #dedede', padding: '15px' }}>
          <span style={{ fontWeight: 'bold' }}>{email.attachments.length} attached files</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
          }}
        >
          {attachments}
        </div>
      </>
    )
  }

  if (isLoading || tenantRequest.isLoadingTenantConfig) {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogContent>
          <div
            style={{
              height: '30vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  if (isError) {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogContent>
          <div
            style={{
              height: '30vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <h2>Unable to get the email details</h2>
            <WarningIcon fontSize="large" color="error" />
            <h4 className={classes.description}>Try again later!</h4>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  function createMarkup(body) {
    return { __html: DOMPurify.sanitize(body) }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle id="simple-dialog-title">{email.subject}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div>
          <div className={classes.subTitle}>
            <div style={{ display: 'flex' }}>
              <AccountCircleIcon fontSize="large" />
              <div className={classes.fromTo}>
                <span>
                  <b>From </b>
                  {`${email.fromEmail}`}
                </span>
                <span>
                  <b>To </b> {`${email.toEmail}`}
                </span>
              </div>
            </div>
            <span>{formatDate({ date: email.receivedDate })}</span>
          </div>
        </div>
        <div dangerouslySetInnerHTML={createMarkup(email.body)}></div>
        {attachmentsJsx(email.attachments)}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EmailDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  recordId: PropTypes.string,
  tenantRequest: PropTypes.object
}
