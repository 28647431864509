import { compose } from "recompose";
import { connect } from "react-redux";

import AboutView from "./AboutView";

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{}
	)
)(AboutView);
