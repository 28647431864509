import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import FormLabel from '@material-ui/core/FormLabel'

//Constants
import { aribaApiTypes, InputDefault } from 'utils/Constants'

// icons
import CheckBox from '@material-ui/icons/CheckBox'
import Cancel from '@material-ui/icons/Cancel'

//services
import {
  getAppConstantsByApp,
  validateAppConnectionByType
} from 'services/apiApps'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/widgetFormStyle.js'

import { updateApis } from 'store/IntelligenceState'

let configurations = {
  realm: { value: '', state: undefined },
  apis: {}
}

let changedApis = {}

const mapState = state => ({
  apis: state.intelligence.apis
})
let apisInitial = {}

class aribaConnections extends React.Component {
  constructor (props) {
    super(props)
    this.state = props.initialState
  }

  sendState () {
    return this.state
  }

  isValidated () {
    let isValid = false
    let api
    for (api in this.state.configurations.apis) {
      let aribaConnection = this.state.configurations.apis[api].aribaConnection
      if (aribaConnection && aribaConnection === true) {
        isValid = true
      } else {
        isValid = false
      }
    }
    isValid = this.state.configurations.realm.value !== '' ? true : false
    return isValid
  }

  onFieldChange (item, object, field, value) {
    configurations = { ...this.state.configurations}
    if (item && object) {
      object.value = value
      configurations.apis[item].inputs[field] = object
      configurations.apis[item].aribaConnection = undefined
      configurations.apis[item].aribaConnectionMessage = undefined
    } else if (field === 'realm') {
      configurations.realm.value = value
    }
    this.setState({ configurations: { ...configurations } })
  }

  setShowModal (item) {
    this.setState({ showModal: true })
    this.setState({
      aribaConnectionMessage: this.state.configurations.apis[item].aribaConnectionMessage
    })
  }

  showModal () {
    const { classes } = this.props
    return (
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.showModal === true}
        keepMounted
        onClose={() => this.setState({ showModal: false })}
        aria-labelledby='classic-modal-slide-title'
        aria-describedby='classic-modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Error connection details</h4>
        </DialogTitle>
        <DialogContent
          id='classic-modal-slide-description'
          className={classes.modalBody}
        >
          {this.state.aribaConnectionMessage
            ? this.state.aribaConnectionMessage
            : null}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => this.setState({ showModal: false })}
            color='danger'
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  async checkAribaConnection (item) {
    configurations = { ...this.state.configurations}
    configurations.apis[item].aribaConnection = undefined
    configurations.apis[item].aribaConnectionTesting = true
    configurations.apis[item].aribaConnectionMessage = undefined
    this.setState({ configurations: { ...configurations } })

    let connectionData = {
      secret: this.state.configurations.apis[item].inputs['secret_' + item].value,
      clientId: this.state.configurations.apis[item].inputs['clientId_' + item].value,
      apiKey: this.state.configurations.apis[item].inputs['apiKey_' + item].value,
      realm: this.state.configurations.realm.value
    }

    let checkProps = ['apiKey', 'secret', 'clientId']
    let bodyField
    for (bodyField of checkProps) {
      if (connectionData[bodyField] === InputDefault) {
        delete connectionData[bodyField]
      }
    }
    let connection = await validateAppConnectionByType(
      'intelligence',
      this.state.selectedAppId,
      connectionData,
      item,
      'intelligence'
    )
    let defaultError = {
      error: 'invalid_request',
      error_description: 'Connection Error'
    }
    defaultError = JSON.stringify(defaultError, null, '\t')

    if (connection.success) {
      configurations.apis[item].aribaConnection = connection.data.valid
      this.setState({ configurations: { ...configurations } })
      if (connection.data.valid) {
        configurations.apis[item].aribaConnectionMessage = undefined
        this.setState({ configurations: { ...configurations } })
      } else {
        configurations.apis[item].aribaConnectionMessage =
          JSON.stringify(connection.data.data) || defaultError
        this.setState({ configurations: { ...configurations } })
      }
    } else {
      configurations.apis[item].aribaConnection = false
      configurations.apis[item].aribaConnectionMessage = defaultError
      this.setState({ configurations: { ...configurations } })
    }
    configurations.apis[item].aribaConnectionTesting = false
    this.setState({ configurations: { ...configurations } })
  }

  setInputs (apis, edit = false) {
    let aribaConfig = {}
    let item
    for (item in apis) {
      let inputs = {}
      inputs['apiKey_' + [item]] = {
        key: 'apiKey_' + [item],
        label: 'Ariba Application Key',
        value: apis[item].apiKey ? apis[item].apiKey : '',
        state: undefined
      }
      inputs['clientId_' + [item]] = {
        key: 'clientId_' + [item],
        label: 'Client Id',
        value: apis[item].clientId ? apis[item].clientId : '',
        state: undefined
      }
      inputs['secret_' + [item]] = {
        key: 'secret_' + [item],
        label: 'Secret',
        value: apis[item].secret ? apis[item].secret : '',
        state: undefined
      }
      aribaConfig[item] = {
        inputs: inputs,
        aribaConnection: edit ? true : undefined,
        aribaConnectionMessage: undefined,
        aribaConnectionTesting: false
      }
    }
    return aribaConfig
  }

  async getConstants () {
    const constants = await getAppConstantsByApp('intelligence', 'procurement')

    if (constants.success) {
      constants.data.journeys.forEach(journey => {
        let eventKeys = Object.keys(journey.event)
        eventKeys.forEach(key => {
          apisInitial = { ...apisInitial, ...journey.event[key].apis }
        })
      })
      return this.createInputs()
    }
  }

  createInputs () {
    configurations = { ...this.state.configurations}
    let aribaConfigEdit = {}
    let aribaConfig = { ...this.setInputs(apisInitial) }
    const params = this.state.connections
    if (params) {
      aribaConfigEdit = { ...this.setInputs(params, true) }
    }
    aribaConfig = { ...aribaConfig, ...aribaConfigEdit }
    configurations = {
      realm: { value: this.state.realm, state: undefined },
      apis: { ...aribaConfig }
    }
    if (this.props.apis) {
      Object.keys(configurations.apis).forEach(
        api => {
          Object.keys(this.props.apis).includes(api) ||
          delete configurations.apis[api] }
      )
    }
    this.setState({ configurations: { ...configurations } })
    return configurations
  }

  componentDidMount () {
    this.getConstants()
  }

  apisChanged(apis, changedApis) {
    const apisKeys = Object.keys(apis)
    const changedApisKeys = Object.keys(changedApis)
    if (apisKeys.length !== changedApisKeys.length) {
      return false;
    }
  
    let key
    for (key of apisKeys) {
      if (apisKeys[key] !== changedApisKeys[key]) {
        return false;
      }
    }
  
    return true;
  }

  render () {
    const { classes, apis } = this.props
    if (!this.apisChanged(apis, changedApis)) {
      changedApis = { ...apis}
      this.props.updateApis(changedApis)
      this.createInputs()
    }

    return (
      <GridItem xs={12} sm={12} md={12}>
        <h5 className={classes.sectionTitle}>SAP Ariba</h5>
        <GridContainer>
          {this.state.configurations ? (
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <FormLabel className={classes.inputLabelHorizontal}>
                    Realm
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    success={this.state.configurations.realm.state === 'success'}
                    error={this.state.configurations.realm.state === 'error'}
                    labelText={
                      <span>
                        Realm{' '}
                        <small>
                          {this.state.configurations.realm.state === 'error'
                            ? '(required)'
                            : '*'}{' '}
                        </small>
                      </span>
                    }
                    id='realm'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event =>
                        this.onFieldChange(
                          null,
                          null,
                          'realm',
                          event.target.value
                        ),
                      value: this.state.configurations.realm.value
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          ) : null}
        </GridContainer>
        <GridContainer>
          <div className={classes.root}>
            {this.state.configurations.apis
              ? Object.keys(this.state.configurations.apis).map(item => (
                  <Accordion key={item}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Typography className={classes.heading}>
                        {aribaApiTypes[item]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <GridContainer>
                        {Object.keys(this.state.configurations.apis[item].inputs).map(
                          input => (
                            <GridItem
                              key={this.state.configurations.apis[item].inputs[input].key}
                              md={12}
                            >
                              <GridContainer>
                                <GridItem xs={12} sm={6}>
                                  <FormLabel
                                    className={classes.inputLabelHorizontal}
                                  >
                                    {
                                      this.state.configurations.apis[item].inputs[input]
                                        .label
                                    }
                                  </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                  <CustomInput
                                    success={
                                      this.state.configurations.apis[item].inputs[input]
                                        .state === 'success'
                                    }
                                    error={
                                      this.state.configurations.apis[item].inputs[input]
                                        .state === 'error'
                                    }
                                    labelText={
                                      <span>
                                        {this.state.configurations.apis[item].inputs[input]
                                          .label + ' '}
                                        <small>
                                          {this.state.configurations.apis[item].inputs[
                                            input
                                          ].state === 'error'
                                            ? '(required)'
                                            : '*'}{' '}
                                        </small>
                                      </span>
                                    }
                                    id={
                                      this.state.configurations.apis[item].inputs[input]
                                        .key
                                    }
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      type: 'password',
                                      value:
                                      this.state.configurations.apis[item].inputs[input]
                                          .value,
                                      onChange: event =>
                                        this.onFieldChange(
                                          item,
                                          this.state.configurations.apis[item].inputs[
                                            input
                                          ],
                                          this.state.configurations.apis[item].inputs[
                                            input
                                          ].key,
                                          event.target.value
                                        )
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          )
                        )}
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            color={
                              this.state.configurations.apis[item].aribaConnection !==
                              undefined
                                ? this.state.configurations.apis[item].aribaConnection
                                  ? 'info'
                                  : 'danger'
                                : 'facebook'
                            }
                            onClick={() => this.checkAribaConnection(item)}
                            round
                            className={classes.documentation}
                            disabled={
                              this.state.configurations.apis[item].aribaConnectionTesting
                            }
                          >
                            {this.state.configurations.apis[item].aribaConnection !==
                            undefined ? (
                              this.state.configurations.apis[item].aribaConnection ? (
                                <div>
                                  <CheckBox className={classes.icons} />{' '}
                                  Connected
                                </div>
                              ) : (
                                <div>
                                  <Cancel className={classes.icons} />{' '}
                                  Connection Error
                                </div>
                              )
                            ) : (
                              <div>
                                {this.state.configurations.apis[item]
                                  .aribaConnectionTesting
                                  ? 'Loading...'
                                  : 'Test Connectivity'}{' '}
                              </div>
                            )}
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          {this.state.configurations.apis[item].aribaConnectionMessage ? (
                            <Button
                              color='danger'
                              simple
                              round
                              className={classes.documentation}
                              onClick={() => this.setShowModal(item)}
                            >
                              Error details
                            </Button>
                          ) : null}
                        </GridItem>
                      </GridContainer>
                    </AccordionDetails>
                  </Accordion>
                ))
              : null}
          </div>
        </GridContainer>
        {this.showModal()}
      </GridItem>
    )
  }
}

aribaConnections.propTypes = {
  classes: PropTypes.object
}

export default connect(mapState, { updateApis })(withStyles(styles)(aribaConnections))
