import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { formatDate } from 'utils/functions.js'
import { useGetSupplierHistory } from 'hooks/usePii'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/piiStyle'
const useStyles = makeStyles(styles)

export function PIIDetailsModal(props) {
  const classes = useStyles()
  const { showModal, onClose, tenantId, appId, supplierId } = props

  const { data: history, isLoading, isFetching, isRefetching } = useGetSupplierHistory(tenantId, appId, supplierId)

  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot
      }}
      open={showModal}
      fullWidth={true}
      maxWidth={'lg'}
      onClose={onClose}
    >
      <DialogTitle>PII Supplier Details</DialogTitle>
      <DialogContent className={classes.modalBody}>
        {isLoading || isFetching || isRefetching ? (
          <div className={classes.reloadSize}>
            <CircularProgress />
          </div>
        ) : typeof history === 'object' ? (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history?.length ? (
                history.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {item.date
                        ? formatDate({
                            date: new Date(item.date).toISOString()
                          })
                        : 'Invalid Date'}
                    </TableCell>
                    <TableCell>{item.message ? item.message.replace(/\/n/g, ' ') : ''}</TableCell>
                    <TableCell>{item.status.charAt(0).toUpperCase() + item.status.replace('_', ' ').substring(1)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>No information</TableRow>
              )}
            </TableBody>
          </Table>
        ) : (
          'No information to show'
        )}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => onClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
