import React, { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'

// Material UI
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'

// Icons
import Add from '@material-ui/icons/PlaylistAdd'
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'

// Views
import TabPanel from '../Components/TabPanel'
import IntegrationsView from './IntegrationsView'
import ConnectionsView from './ConnectionsView'

// Form
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

// Components
import Snackbar from 'components/Snackbar/Snackbar'

// Styles
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle'

// Hooks
import { useGetAppList, useGetApp, useCreateApp, useUpdateApp } from 'hooks/useInsights'

// Others
import { v4 as uuidv4 } from 'uuid'

const useStyles = makeStyles(styles)

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const appConfigObj = {
  enabled: true,
  appName: '',
  type: '',
  service: 'ariba',
  appId: ''
}

const schema = yup.object().shape({
  appName: yup
    .string()
    .min(1)
    .required(),
  type: yup
    .string()
    .min(1)
    .required(),
  service: yup
    .string()
    .min(1)
    .required(),
  enabled: yup.boolean().optional()
})

export default function InsightsView() {
  const classes = useStyles()
  const [tab, setTab] = useState(0)
  const [mode, setMode] = useState('create')
  const [appId, setAppId] = useState(null)
  const queryClient = useQueryClient()
  const [createError, setCreateError] = useState({
    message: '',
    isOpen: false,
    color: 'danger'
  })
  const [submitSuccess, setSubmitSuccess] = useState({
    message: '',
    isOpen: false
  })
  const appList = useGetAppList({
    options: { refetchOnWindowFocus: false }
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    mode: 'all',
    defaultValues: appConfigObj,
    resolver: yupResolver(schema),
    shouldUnregister: false
  })

  useEffect(() => {
    if (!appList.data) {
      return
    }

    if (appList.data.length === 0) {
      return
    }

    setMode('edit')
    if (appId) {
      return
    }
    setAppId(appList.data[0].appId)
  }, [appList.data, appId])

  useEffect(() => {
    if (!appList.isLoading && appList.isError) {
      setCreateError({
        message: appList.error.response?.data?.message ?? 'Something went wrong, try again later',
        isOpen: true,
        color: 'danger'
      })
    }
  }, [appList.isLoading, appList.isError, appList.error])

  const app = useGetApp({ appId: appId, options: { refetchOnWindowFocus: false } })

  useEffect(() => {
    if (!app.data) {
      return
    }

    setAppId(app.data.appId)
    reset(app.data)
  }, [app.data, reset])

  useEffect(() => {
    if (!app.isLoading && app.isError) {
      setCreateError({
        message: app.error.response?.data?.message ?? 'Something went wrong, try again later',
        isOpen: true,
        color: 'danger'
      })
    }
  }, [app.isLoading, app.isError, app.error])

  const { mutate: createApp, isLoading: isLoadingCreateApp } = useCreateApp()

  const { mutate: updateApp, isLoading: isLoadingUpdateApp } = useUpdateApp({
    appId: appId
  })

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  const service = useWatch({
    control,
    name: 'service',
    defaultValue: 'ariba'
  })

  function configRequestBody(data) {
    if (data.params) {
      delete data.params
    }

    if (mode === 'create') {
      data.appId = uuidv4()
      data.appType = 'insights'
      data.params = {
        ariba: {
          region: 'us',
          instanceType: 'sap',
          realm: ''
        }
      }
    }

    return data
  }

  function onSubmitHook(data) {
    data = configRequestBody(data)
    if (mode === 'create') {
      createApp(
        { data },
        {
          onSettled: () => {
            queryClient.invalidateQueries(['Apps', app.data.appId])
          },
          onSuccess: (response, data) => {
            if (response.status === 201) {
              setSubmitSuccess({ message: 'Saved', isOpen: true })
              setAppId(data.data.appId)
              appList.refetch({ throwOnError: true })
              app.refetch({ throwOnError: true })
              setMode('edit')
            }
          },
          onError: error => {
            setCreateError({
              message: error.response?.data?.message ?? 'Something went wrong, try again later',
              isOpen: true,
              color: 'danger'
            })
          }
        }
      )
    } else {
      updateApp(
        { data },
        {
          onSettled: () => {
            queryClient.invalidateQueries(['Apps', app.data.appId])
          },
          onSuccess: response => {
            if (response.status === 200) {
              setSubmitSuccess({ message: 'Saved', isOpen: true })
              appList.refetch({ throwOnError: true })
              app.refetch({ throwOnError: true })
              setMode('edit')
            }
          },
          onError: error => {
            setCreateError({
              message: error.response?.data?.message ?? 'Something went wrong, try again later',
              isOpen: true,
              color: 'danger'
            })
          }
        }
      )
    }
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} sm={3} md={3} lg={3}>
        <Card style={{ height: '100%' }}>
          <CardContent>
            Application List
            <Tooltip title="Create new app">
              <IconButton
                color="primary"
                component="span"
                size="small"
                disabled={appList.isLoading}
                className={classes.marginLeft}
                onClick={() => {
                  setMode('create')
                  reset(appConfigObj)
                }}
              >
                <Add fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </CardContent>
          <CardContent>
            {appList.isLoading || isLoadingCreateApp || isLoadingUpdateApp ? (
              <Grid container justifyContent="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            ) : (
              <List>
                {appList.data.map((prop, key) => {
                  return (
                    <ListItem
                      className={classes.listItem}
                      button
                      selected={appId === prop.appId}
                      key={key}
                      onClick={() => {
                        setMode('edit')
                        setAppId(prop.appId)
                      }}
                    >
                      <ListItemAvatar>
                        {appId === prop.appId ? (
                          <Avatar>
                            <TouchApp />
                          </Avatar>
                        ) : (
                          <Avatar className={classes.clearAvatar}></Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText primary={prop.appName} secondary={`Type: ${prop.type}`} />
                    </ListItem>
                  )
                })}
              </List>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={9} md={9} lg={9}>
        <Card>
          <CardContent>
            {appList.isLoading || app.isLoading || isLoadingCreateApp || isLoadingUpdateApp ? (
              <Grid container justifyContent="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            ) : (
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <h3 className={classes.formHeader}>Relish Insights</h3>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.documentation}
                    href="/admin/nitorInsights/documentation"
                    target="_blank"
                  >
                    Version 1.0
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Tabs
                    value={tab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label="Integrations" {...a11yProps(0)} />
                    {mode === 'edit' ? <Tab label="Connections" {...a11yProps(1)} /> : null}
                  </Tabs>
                  <TabPanel value={tab} index={0}>
                    <form onSubmit={handleSubmit(onSubmitHook)}>
                      <IntegrationsView
                        mode={mode}
                        control={control}
                        appConfig={app.data ?? appConfigObj}
                        errors={errors}
                        service={service}
                      />
                    </form>
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <ConnectionsView
                      app={app}
                      appList={appList}
                      setCreateError={setCreateError}
                      setSubmitSuccess={setSubmitSuccess}
                      connections={app.data?.params?.remoteConnections ?? {}}
                    />
                  </TabPanel>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Snackbar
        place="bl"
        color={createError.color ? createError.color : 'danger'}
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '', color: 'danger' })}
        close
      />
      <Snackbar
        place="bl"
        color="success"
        icon={AddAlert}
        message={submitSuccess.message}
        open={submitSuccess.isOpen}
        closeNotification={() => setSubmitSuccess({ isOpen: false, message: '' })}
        close
      />
    </Grid>
  )
}
