import { compose, withState, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { signOut } from 'store/AuthState'
import { updateEnabledApps } from 'store/AppState'

import AdminView from './AdminView'

import { getUserRoles } from 'utils/functions'
import { getTenantForChecking } from 'services/apiTenants'
import { getUserTenant } from 'utils/functions'
import { hideLoading } from 'utils/functions'
import { showLoading } from 'utils/functions'

import { getPermissionsByRole } from 'services/apiAuth'
import {
  mapDefaultRoutes,
  mapAdminRoutes,
  mapSupportRoutes,
  mapAppsRoutes,
  mapInvoiceAi,
  mapDataAssure,
  mapDataAssureDnB
} from 'utils/RoutesConfig'

let _isMounted = false

async function getUserPermissions(props) {
  const tenant = getUserTenant()
  const roles = getUserRoles()
  let localPermissions = []

  // eslint-disable-next-line
  for (const role of roles) {
    const getPermissionsByTenantResponse = await getPermissionsByRole(tenant, role)
    if (getPermissionsByTenantResponse.data?.length > 0) {
      localPermissions.push(...getPermissionsByTenantResponse.data)
    }
  }
  localPermissions = [...new Set(localPermissions)].sort()
  props.setPermissions(localPermissions)
}

async function getApps(props) {
  // Get apps
  let tenant = getUserTenant()
  let result = await getTenantForChecking(tenant)
  let userData
  if (result.success) {
    let enabledApps = []
    let enabledAppsObj = {}
    result.data.enabledApps.forEach(app => {
      if (app.active) {
        enabledApps.push(app.key)
        enabledAppsObj[app.key] = app
      }
    })
    userData = {
      enabledApps: enabledApps
    }
    localStorage.setItem('userData', JSON.stringify(userData))
    props.updateEnabledApps(enabledAppsObj)
  }
  return userData
}

async function buildMenu(props, callback) {
  let menuRoutes = []

  menuRoutes.push(...mapDefaultRoutes())

  const adminMenu = mapAdminRoutes(props.permissions)
  if (adminMenu) {
    menuRoutes.push(...adminMenu)
  }

  // Get Apps
  let apps
  let userData = localStorage.getItem('userData')
  if (userData) {
    userData = JSON.parse(userData)
    if (!userData.enabledApps) {
      await getApps(props)
    }
  } else {
    userData = await getApps(props)
  }

  if (userData) {
    apps = userData.enabledApps
  }

  //Check Apps
  if (apps && apps.length !== 0) {
    menuRoutes.push(...mapInvoiceAi(apps, props.permissions))
    menuRoutes.push(...mapDataAssure(apps, props.permissions))
    menuRoutes.push(...mapDataAssureDnB(apps, props.permissions))
    menuRoutes.push(...mapAppsRoutes(apps, props.permissions))
  }

  menuRoutes.push(...mapSupportRoutes())

  if (_isMounted) {
    props.setAdminMenu(menuRoutes)
  }
  if (callback) callback()
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name
    }),
    { signOut, updateEnabledApps }
  ),
  withState('adminMenu', 'setAdminMenu', []),
  withState('permissions', 'setPermissions', []),
  withState('dnbEnabled', 'setDnbEnabled', false),
  lifecycle({
    async componentDidMount() {
      _isMounted = true
      showLoading()
      await getUserPermissions(this.props)
      buildMenu(this.props, hideLoading)
    }
  })
)(AdminView)
