import { primaryColor } from 'assets/jss/material-dashboard-pro-react.js'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.js'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js'
import {
  blackColor,
  grayColor,
  hexToRgb,
  defaultFont
} from 'assets/jss/material-dashboard-pro-react.js'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.js'
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'

const nitorConnectStyle = theme => ({
  card: { overflow: 'visible', paddingTop: '25px' },
  appBar: {
    overflow: 'auto',
    '@media (min-width: 1300px)': {
      height: '100%'
    }
  },
  displayTable: {
    display: 'table',
    overflow: 'hidden'
  },
  verticalAlign: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  listItem: {
    color: primaryColor[0]
  },
  circularProgress: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  clearAvatar: {
    backgroundColor: 'transparent'
  },
  formHeader: {
    textAlign: 'center'
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  noPadding: { padding: '0 !important' },
  inputAdornment: {
    position: 'relative'
  },
  activeToggle: {
    marginLeft: '0px'
  },
  documentation: {
    float: 'right',
    textTransform: 'none'
  },
  socialButtonsIcons: {
    fontSize: '18px',
    marginTop: '-2px',
    position: 'relative'
  },
  sectionTitle: {
    fontWeight: 'bold'
  },
  contentLabelHorizontal: {
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    fontSize: '14px',
    paddingTop: '50px'
  },
  inputLabelHorizontal: {
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    display: 'inline-flex',
    fontSize: '14px',
    '@media (min-width: 992px)': {
      paddingTop: '30px'
    }
  },
  toggleTitle: {
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    fontSize: '14px'
  },
  row: {
    paddingTop: '20px'
  },
  inputDisabled: {
    background: 'gray'
  },
  customDisabled: {
    '&$disabled': {
      background: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)'
    }
  },
  disabled: {},
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  cardTitle: {
    marginTop: '0',
    marginBottom: '3px',
    color: grayColor[2],
    fontSize: '18px'
  },
  cardHeader: {
    zIndex: '3'
  },
  cardSubtitle: {
    ...defaultFont,
    color: grayColor[0],
    fontSize: '14px',
    margin: '0 0 10px'
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  marginRight: {
    marginRight: '5px'
  },
  modalSectionTitle: {
    marginTop: '30px'
  },
  appRow: {
    paddingTop: '20px',
    borderBottom: grayColor[0] + '!important',
    '& > div': {
      '&:before': {
        borderBottomWidth: '1px !important',
        borderBottomColor: grayColor[4] + '!important'
      },
      '&:after': {
        backgroundColor: grayColor[0] + '!important',
        borderBottomColor: grayColor[0] + '!important'
      }
    }
  },
  addButton: {
    marginTop: '-25px',
    float: 'right'
  },
  floatRight: {
    float: 'right'
  },
  defaultColorFilled: {
    color: 'white',
    borderRadius: '30px',
    backgroundColor: '#999'
  },
  statusButton: {
    display: 'inline-block',
    height: '40px',
    borderRight: '1px solid #80808040',
    textAlign: 'center'
  },
  bgColorPrimary: {
    backgroundColor: '#081c3e'
  },
  statusText: { marginLeft: '7px', color: 'gray', fontSize: '15px' },
  reloadSize: {
    width: "100%",
    height: "240px",
    textAlign: "center",
    padding: "50px"
  },
  ...modalStyle(theme),
  ...buttonStyle
})

export default nitorConnectStyle
