import { compose, withState, lifecycle } from "recompose";
import { connect } from "react-redux";

import DetailsView from "./DetailsView";

const document = {
	documentNumber: "",
	documentType: "",
	requester: "",
	needByDate: "",
	dateCreated: "",
	documentUrl: ""
}

function formatDate(dateString){
	let date = new Date(dateString)
	return date.toDateString()
	/* let dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' }) 
	let [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat .formatToParts(date ) 

	return `${day}-${month}-${year }`  */
}

async function getDocument(props){
	let newDocument = JSON.parse(atob(decodeURIComponent(props.match.params.data))) 
	
	switch (newDocument.documentType) {
		case "requisitions":
			newDocument.documentType = "Purchase requisition"
			break;
		case "invoices":
				newDocument.documentType = "Invoice"
				break;
		default:
			break;
	}
	// created
	newDocument.created = formatDate(newDocument.created)
	// needByDate
	newDocument.needBy = formatDate(newDocument.needBy)

	if(newDocument.additionalTabs && newDocument.additionalTabs.length){
		newDocument.additionalTabName = newDocument.additionalTabs[0].name
		newDocument.additionalTabUrl = newDocument.additionalTabs[0].url
	}

	if(_isMounted){
		props.setDocument(newDocument)
	}
}

let _isMounted = false

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{}
	),
	withState("document", "setDocument", { ...document }),
	lifecycle({
		componentDidMount() {
			_isMounted = true

			this.props.setDocument({ ...document })
			getDocument(this.props)
		},
		componentWillUnmount() {
			_isMounted = false
		}
	})
)(DetailsView);
