import React, { useEffect, useRef, useState } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import CustomInput from '../../components/CustomInput/CustomInput.js'

import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/customModalStyles.js'
import CustomModal from 'components/CustomModal/CustomModal.js'
import { FormControl, InputLabel, Select, MenuItem, Typography } from '@material-ui/core'
const useStyles = makeStyles(styles)
export default function TypesModal ({ ...props }) {
  const [selectedType, setSelectedType] = useState('')
  const [question, setQuestion] = useState(undefined)
  const [refresh, setRefresh] = useState(false)
  const isMounted = useRef(false)
  useEffect(
    () => {
      // did mount
      if (!isMounted.current) {
        if (props.question) {
          for (let index = 0; index < props.types.length; index++) {
            if (props.types[index].type === props.question.type) {
              setSelectedType(index)
              break
            }
          }
          setQuestion(props.question)
        } else if (props.types.length === 1) {
          let vals = props.types[0]
          setSelectedType(0)
          setQuestion(vals)
        }
        isMounted.current = true
      }
      // did update
      else {
      }

      //this function will be ran when the component is re-renderd or unmounted
      return () => {}
    },
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    [setSelectedType, setQuestion, props]
  )
  function getModal () {
    return (
      <CustomModal
        title={props.title}
        confirmBtnCssClass={props.confirmBtnCssClass}
        cancelBtnCssClass={props.cancelBtnCssClass}
        onCancel={props.onCancel}
        onConfirm={() => {
          props.onConfirm(question)
        }}
      >
        <GridContainer container spacing={4}>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
              {props.types.length === 1 ? (
                <Typography>
                  {props.types[0].type === 'generic' ? '' : props.types[0].type}
                </Typography>
              ) : (
                <FormControl className={classes.formControl} style={{ width: '100%' }}>
                  <InputLabel className={classes.inputLabel}>{'Type'}</InputLabel>
                  <Select
                    style={{
                      width: '100%',
                      textAlign: 'center'
                    }}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    multiple={false}
                    value={selectedType}
                    onChange={e => {
                      setSelectedType(e.target.value)
                      let vals = props.types.map(val => {
                        return val
                      })
                      vals = vals[e.target.value]

                      setQuestion(vals)
                    }}
                  >
                    {props.types.map((element, count) => {
                      let label
                      let value
                      if (typeof element === 'object') {
                        if (element.type) label = element.type
                        value = count
                      } else {
                        value = element
                        label = element
                      }
                      if (value !== undefined && label !== undefined)
                        return (
                          <MenuItem
                            key={count}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            style={{
                              overflowX: 'auto',
                              textOverflow: 'ellipsis',
                              textAlign: 'center'
                            }}
                            value={value}
                          >
                            {label}
                          </MenuItem>
                        )
                      else return ''
                    })}
                  </Select>
                </FormControl>
              )}
              {question && !props.hideId ? (
                <CustomInput
                  labelProps={{ style: { width: '100%' } }}
                  labelText={'Id'}
                  id={'QuestionId'}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      //setUniqueName(event.target.value)
                      question.id = event.target.value
                      setQuestion(question)
                      setRefresh(!refresh)
                      //alert('')
                      // console.log(event)
                    },
                    type: 'text',
                    value: question.id
                  }}
                />
              ) : (
                ''
              )}
              {question && question.values
                ? Object.keys(question.values)
                    .sort(function (a, b) {
                      let orderA = parseInt(question.values[a].order)
                      if (!parseInt(question.values[a].order)) {
                        orderA = 50000
                      }
                      let orderB = parseInt(question.values[b].order)
                      if (!parseInt(question.values[b].order)) {
                        orderB = 50000
                      }

                      return orderA - orderB
                    })
                    .map((val, key) => {
                      if (question.values[val].options && question.values[val].options.length > 0) {
                        if (question.values[val].hidden) return ''
                        else
                          return (
                            <div
                              key={key}
                              style={{
                                width: '100%',
                                padding: '13px 0px 13px'
                              }}
                            >
                              <FormControl
                                className={classes.formControl}
                                style={{ width: '100%' }}
                              >
                                <InputLabel className={classes.inputLabel}>
                                  {
                                    <div>
                                      <span>{question.values[val].label}</span>
                                    </div>
                                  }
                                </InputLabel>
                                <Select
                                  style={{
                                    width: '100%',
                                    textAlign: 'center'
                                  }}
                                  MenuProps={{
                                    className: classes.selectMenu
                                  }}
                                  classes={{
                                    select: classes.select
                                  }}
                                  multiple={question.values[val].multiple}
                                  value={question.values[val].value}
                                  onChange={e => {
                                    question.values[val].value = e
                                    setQuestion(question)
                                    setRefresh(!refresh)
                                  }}
                                >
                                  {question.values[val].options.map((element, count) => {
                                    let label
                                    let value
                                    if (typeof element === 'object') {
                                      if (element.label) label = element.label
                                      if (element.value) value = element.value
                                    } else {
                                      value = element
                                      label = element
                                    }
                                    if (value && label)
                                      return (
                                        <MenuItem
                                          key={count}
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                          }}
                                          style={{
                                            overflowX: 'auto',
                                            textOverflow: 'ellipsis',
                                            textAlign: 'center'
                                          }}
                                          value={value}
                                        >
                                          {label}
                                        </MenuItem>
                                      )
                                    else return ''
                                  })}
                                </Select>
                              </FormControl>
                            </div>
                          )
                      } else {
                        return (
                          <div key={key}>
                            <CustomInput
                              labelProps={{ style: { width: '100%' } }}
                              labelText={question.values[val].label}
                              id={val + key}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: event => {
                                  //setUniqueName(event.target.value)
                                  question.values[val].value = event.target.value
                                  setQuestion(question)
                                  setRefresh(!refresh)
                                  //alert('')
                                  // console.log(event)
                                },
                                type: 'text',
                                value: question.values[val].value
                              }}
                              disabled={question.values[val].disabled}
                            />
                          </div>
                        )
                      }
                    })
                : ''}
            </div>
          </GridItem>
        </GridContainer>
      </CustomModal>
    )
  }
  const classes = useStyles()
  if (refresh) return <div>{getModal()}</div>
  else return <div>{getModal()}</div>
}
