import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

const useStyles = makeStyles(styles)

export default function Tmobile (props) {
  const classes = useStyles()

  const columnsDetails = [
    {
      field: 'name',
      title: 'Name',
      render: rowData => rowData.description.shortName
    },
    {
      field: 'description',
      title: 'Description',
      render: rowData => rowData.description.description
    },
    {
      field: 'quantity',
      title: 'Quantity'
    },
    {
      field: 'currency',
      title: 'Currency',
      render: rowData => rowData.amount.currency
    },
    {
      field: 'amount',
      title: 'Amount',
      render: rowData => rowData.amount.amount
    },
    {
      field: 'itemCategory',
      title: 'Category'
    },
    {
      field: 'supplier',
      title: 'Supplier',
      render: rowData => rowData.supplier.name
    }
  ]

  const columnsChanges = [
    {
      field: 'approvableUniqueName',
      title: 'Unique Name'
    },
    {
      field: 'approvableBaseID',
      title: 'Approvable Base ID'
    },
    {
      field: 'changeType',
      title: 'Change Type'
    },
    {
      field: 'changeSequenceId',
      title: 'Change Sequence ID'
    },
    {
      field: 'restResourceName',
      title: 'Rest Resource Name'
    },
    {
      field: 'realm',
      title: 'Realm'
    },
    {
      field: 'approvers',
      title: 'Approvers'
    }
  ]

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h3 className={classes.formHeader}>T-Mobile Details</h3>
          </CardHeader>
          <CardBody>
            {props.requisitions ? (
              <GridContainer>
                <GridItem md={4}>
                  <p>Title: {props.requisitions.title}</p>
                </GridItem>
                <GridItem md={4}>
                  <p>Unique Name: {props.requisitions.uniqueName}</p>
                </GridItem>
                <GridItem md={4}>
                  <p>Status: {props.requisitions.statusString}</p>
                </GridItem>
                <GridItem md={4}>
                  <p>
                    Total Cost:{' '}
                    {props.requisitions.totalCost
                      ? props.requisitions.totalCost.currency +
                        ' ' +
                        props.requisitions.totalCost.amount
                      : null}
                  </p>
                </GridItem>
                <GridItem md={4}>
                  <p>
                    Submit Date:{' '}
                    {new Date(props.requisitions.submitDate).toLocaleDateString(
                      'en-US'
                    )}
                  </p>
                </GridItem>
                <GridItem md={4}>
                  <a
                    href={props.requisitions.fullURL}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Go to Ariba
                  </a>
                </GridItem>
              </GridContainer>
            ) : null}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
        <Card>
          <CardHeader>
            <h3 className={classes.formHeader}>Approvals</h3>
          </CardHeader>
          <CardBody>
            {props.rowsApprovals && props.requester ? (
              <Stepper activeStep={props.activeStep + 1}>
                <Step alternativeLabel>
                  <StepLabel>{props.requester.name} - requester</StepLabel>
                </Step>
                {props.rowsApprovals.map(approval => {
                  return (
                    <Step key={approval.id} alternativeLabel>
                      <StepLabel>
                        {approval.approvers.map((approver, i) => {
                          return (
                            <div key={i}>
                              {approver.type === 'user'
                                ? approver.user.name
                                : approver.group.uniqueName}{' '}
                              - {approver.type}
                            </div>
                          )
                        })}
                      </StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            ) : null}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
        <Card>
          <CardHeader>
            <h3 className={classes.formHeader}>Line Items</h3>
          </CardHeader>
          <CardBody>
            <MaterialTable
              columns={columnsDetails}
              data={props.rowsDetails}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
        <Card>
          <CardHeader>
            <h3 className={classes.formHeader}>History</h3>
          </CardHeader>
          <CardBody>
            <MaterialTable
              columns={columnsChanges}
              data={props.rowsChanges}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
