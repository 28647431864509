import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

// Material UI Components
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

// Icons
import DescriptionIcon from '@material-ui/icons/Description'

// Form
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

// Hooks
import { useUpdateRequisitionPrice } from 'hooks/POC/CoupaIFrameApp/useCoupa'

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  container: {
    marginBottom: '20px',
    marginTop: '20px'
  }
}))

const schema = yup.object().shape({
  requisitionPrice: yup
    .number()
    .min(1)
    .required()
})

export function RequisitionLine(props) {
  const {
    line,
    requisitionId,
    refetch,
    setSubmitError,
    setSubmitSuccess
  } = props
  const classes = useStyles()

  const {
    mutate: updateRequisitionPrice,
    isLoading: formIsLoading
  } = useUpdateRequisitionPrice()

  function onSubmit(data) {
    const body = createPatchBody(data)

    updateRequisitionPrice(
      { requisitionId: requisitionId, body },
      {
        onSuccess: () => {
          setSubmitSuccess(true)
          refetch({ throwOnError: true })
        },
        onError: error => setSubmitError(true)
      }
    )
  }

  function createPatchBody(data) {
    const body = {
      id: requisitionId,
      'requisition-lines': [
        {
          id: line.id,
          'unit-price': data.requisitionPrice
        }
      ]
    }
    return body
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      requisitionPrice: line['unit-price'] ?? ''
    },
    resolver: yupResolver(schema),
    shouldUnregister: false
  })

  useEffect(() => {
    reset({
      requisitionPrice: line['unit-price'] ?? ''
    })
  }, [line, reset])

  function isError(field) {
    if (errors[field]) {
      return true
    }
    return false
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        className={classes.container}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={9} md={9}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <DescriptionIcon
              fontSize="large"
              style={{ margin: '10px', color: '#081c3e' }}
            />
            <h3> {line.description}</h3>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={9} md={9}>
              <Controller
                render={({ field }) => (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    label="Requisition Price"
                    error={isError('requisitionPrice')}
                    {...field}
                  />
                )}
                name="requisitionPrice"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              {!formIsLoading ? (
                <Button
                  style={{
                    color: '#081c3e',
                    fontSize: '11px'
                  }}
                  size="small"
                  type="submit"
                >
                  Update
                </Button>
              ) : (
                <CircularProgress size={20} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{
            backgroundColor: '#f7f7f7',
            padding: '8px 5px 8px 5px'
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={3} md={3}>
              <p>
                <b>Commodity</b>
              </p>
              {line.commodity ? line.commodity.name : ''}
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <p>
                <b>Payment Terms</b>
              </p>
              {line['payment-term'] ? line['payment-term'].code : ''}
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <p>
                <b>Shipping</b>
              </p>
              {line['shipping-term'] ? line['shipping-term'].code : ''}
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <p>
                <b>Contract</b>
              </p>
              {line.contract ? line.contract.name : ''}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </form>
  )
}
