import {
  ColumnsOrderLineItemsStandard,
  HeaderFieldsStandard,
  InvoiceProcessFilter
} from 'utils/Constants'

export function getStandardRule(params) {
  const { requiredFields, level, standardField } = params

  const rule = requiredFields.find(x => x.level === level && x.standardField === standardField)
  return rule
}

export function getValidationRule(rule, type) {
  return rule?.validations?.find(x => x.type === type)
}

export function updateHeader(params) {
  //Aqui se utiliza JMSC
  const { getValues, setValue, trigger, deleteErrorsHeader, delta, requiredFields } = params

  const headerData = Object.values(delta?.header ?? {})

  const summaryFields = getValues('headerFields')

  if (headerData.length > 0) {
    const fieldToChangeValidation = []

    headerData.forEach(function(data) {
      const index = summaryFields.findIndex(summaryField => summaryField.field === data.field)

      const rules = getStandardRule({
        requiredFields,
        level: 'header',
        standardField: data.field
      })
      const typeField = getValidationRule(rules, 'dataType')
      if (index < 0) {
        return
      }
      fieldToChangeValidation.push(data.field)

      let newValue = data.value
      let fieldPosition = `headerFields.${index}.value`
      if (
        (data.field === HeaderFieldsStandard['companyProfile.billToAddress.fullAddress'].type ||
          data.field === HeaderFieldsStandard['companyProfile.shipToAddress.fullAddress'].type) &&
        typeField?.value === 'companyAddress'
      ) {
        fieldPosition = `headerFields.${index}.address`
      }

      if (typeField?.value === 'boolean' || data.field === HeaderFieldsStandard.nonPoInvoice.type) {
        newValue = newValue === 'true'
      }

      setValue(fieldPosition, newValue, {
        shouldValidate: true,
        shouldDirty: true
      })

      if (data.field === HeaderFieldsStandard.nonPoInvoice.type) {
        const indexPO = summaryFields.findIndex(
          summaryField => summaryField.type === HeaderFieldsStandard.poNumber.type
        )
        if (indexPO >= 0) {
          trigger(`summaryFields.${indexPO}.value`)
        }
      }
    })

    deleteErrorsHeader({ fields: fieldToChangeValidation })
  }
}

export function transform(summaryFields, flags) {
  const summaryFieldsCopy = [...summaryFields]

  const indexSupplierAddress = summaryFields.findIndex(
    summaryField => summaryField.field === HeaderFieldsStandard['supplier.address.fullAddress'].type
  )

  const indexSupplierRemitAddress = summaryFields.findIndex(
    summaryField =>
      summaryField.field === HeaderFieldsStandard['supplier.remitToAddress.fullAddress'].type
  )

  const indexBuyerShipAddress = summaryFields.findIndex(
    summaryField =>
      summaryField.field === HeaderFieldsStandard['companyProfile.shipToAddress.fullAddress'].type
  )

  const indexBuyerBillAddress = summaryFields.findIndex(
    summaryField =>
      summaryField.field === HeaderFieldsStandard['companyProfile.billToAddress.fullAddress'].type
  )

  const indexDuplicateInvoice = summaryFields.findIndex(
    summaryField => summaryField.field === HeaderFieldsStandard.duplicateInvoiceFlag.type
  )

  if (indexSupplierAddress >= 0) {
    const supplierAddress = summaryFields[indexSupplierAddress].address ?? ''
    const idSupplierAddress = summaryFields[indexSupplierAddress].value ?? ''
    summaryFieldsCopy[indexSupplierAddress] = {
      field: HeaderFieldsStandard['supplier.address.fullAddress'].type,
      value: supplierAddress
    }

    summaryFieldsCopy.push({
      field: HeaderFieldsStandard['supplier.address.id'].type,
      value: idSupplierAddress
    })
  }

  if (indexSupplierRemitAddress >= 0) {
    const supplierRemitAddress = summaryFields[indexSupplierRemitAddress].address ?? ''
    const idSupplierRemitAddress = summaryFields[indexSupplierRemitAddress].value ?? ''
    summaryFieldsCopy[indexSupplierRemitAddress] = {
      field: HeaderFieldsStandard['supplier.remitToAddress.fullAddress'].type,
      value: supplierRemitAddress
    }

    summaryFieldsCopy.push({
      field: HeaderFieldsStandard['supplier.remitToAddress.id'].type,
      value: idSupplierRemitAddress
    })
  }

  if (indexBuyerShipAddress >= 0) {
    const buyerShipAddress = summaryFields[indexBuyerShipAddress].address ?? ''
    const idBuyerShipAddress = summaryFields[indexBuyerShipAddress].value ?? ''
    summaryFieldsCopy[indexBuyerShipAddress] = {
      field: HeaderFieldsStandard['companyProfile.shipToAddress.fullAddress'].type,
      value: buyerShipAddress
    }

    summaryFieldsCopy.push({
      field: HeaderFieldsStandard['companyProfile.shipToAddress.id'].type,
      value: idBuyerShipAddress
    })
  }

  if (indexBuyerBillAddress >= 0) {
    const buyerBillAddress = summaryFields[indexBuyerBillAddress].address ?? ''
    const idBuyerBillAddress = summaryFields[indexBuyerBillAddress].value ?? ''

    summaryFieldsCopy[indexBuyerBillAddress] = {
      field: HeaderFieldsStandard['companyProfile.billToAddress.fullAddress'].type,
      value: buyerBillAddress
    }

    summaryFieldsCopy.push({
      field: HeaderFieldsStandard['companyProfile.billToAddress.id'].type,
      value: idBuyerBillAddress
    })
  }

  if (indexDuplicateInvoice >= 0) {
    const duplicateInvoiceValue = summaryFields[indexDuplicateInvoice].value ?? false
    summaryFieldsCopy[indexDuplicateInvoice] = {
      field: HeaderFieldsStandard.duplicateInvoiceFlag.type,
      value: flags?.isSubmit ? false : duplicateInvoiceValue
    }
  }

  const updatedSummary = summaryFieldsCopy.map(x => {
    if (x.field.includes('CUSTOM_')) {
      return {
        field: `custom.${x.field}`,
        value: x.value
      }
    }

    return x
  })
  return updatedSummary.filter(x => x.field !== HeaderFieldsStandard.charges.type)
}

function createLine(newItem, avoidAttributes = []) {
  const sanitizedObject = {}
  Object.keys(newItem).forEach(el => {
    if (!avoidAttributes.includes(el)) {
      if (el.includes('CUSTOM_')) {
        sanitizedObject[`custom.${el}`] = newItem[el]
      } else {
        sanitizedObject[el] = newItem[el]
      }
    }
  })
  return sanitizedObject
}

export function transFormLines(lines) {
  const newLines = []
  // eslint-disable-next-line
  for (const line of lines) {
    const sanitizedObject = createLine(line, [
      ColumnsOrderLineItemsStandard.additionalDetails.type,
      ColumnsOrderLineItemsStandard.accounting.type
    ])
    newLines.push(sanitizedObject)
  }

  return newLines
}

export const currencyFormatter = new Intl.NumberFormat(
  typeof window !== 'undefined' ? window.navigator.language : 'en-US',
  {
    maximumFractionDigits: 2
  }
)

export function isTaxLineEnable() {
  return true
}

export function getProcessLookUp(processList, processFilters = {}) {
  const processesToShow = Object.entries(processFilters ?? InvoiceProcessFilter).map(
    ([key, value]) => ({
      key: key,
      value: value ?? ''
    })
  )
  if (processList && processList.length > 0) {
    // eslint-disable-next-line
    for (const proc of processList) {
      if (!processesToShow.find(x => x.key === proc.id)) {
        processesToShow.push({ key: proc.id, value: proc.processName })
      }
    }
  }
  // eslint-disable-next-line
  return processesToShow.reduce((acc, curr) => ((acc[curr.key] = curr.value), acc), {}) ?? {}
}
