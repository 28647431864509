import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

// Material UI
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

// Form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// Icons
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Cancel from '@material-ui/icons/Cancel'

// Inputs
import { TextInput } from './Inputs/TextInput'
import { SelectInput } from './Inputs/SelectInput'
import { BooleanInput } from './Inputs/BooleanInput'

// Components
import InfoModal from './InfoModal'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Hooks
import {
  useUpdateTransactionConnection,
  useValidateTransactionsConnection
} from 'hooks/useInvoiceConf'

const useStyles = makeStyles(styles)

export default function InvoiceGroupForm(props) {
  const classes = useStyles()
  const {
    app,
    setCreateError,
    setSubmitSuccess,
    setTab,
    connectionObj,
    schema,
    connected,
    fields,
    modifyData
  } = props
  const [connectionForm] = useState(connectionObj)
  const queryClient = useQueryClient()
  const [connectionConnected, setConnectionConnected] = useState(connected)
  const [connectionMessage, setConnectionMessage] = useState('')
  const [showModal, setShowModal] = useState({ open: false, message: '' })

  const {
    handleSubmit,
    formState: { isDirty },
    control,
    getValues
  } = useForm({
    mode: 'all',
    defaultValues: connectionForm,
    resolver: yupResolver(schema),
    shouldUnregister: false
  })

  useEffect(() => {
    if (isDirty) {
      setConnectionConnected(undefined)
      setConnectionMessage('')
    }
  }, [isDirty, setConnectionConnected, setConnectionMessage])

  const {
    mutate: updateTransactionConnection,
    isLoading: isLoadingUpdateConnection
  } = useUpdateTransactionConnection({
    appId: app.data?.data.appId
  })

  const {
    mutate: validateTransactionConnection,
    isLoading: isLoadingValidateConnection
  } = useValidateTransactionsConnection({
    appId: app.data?.data.appId
  })

  function onSubmitHook(data) {
    data = modifyData(data)
    updateTransactionConnection(
      { data },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['Apps', app.data?.data.appId])
        },
        onSuccess: response => {
          if (response.status === 200) {
            setSubmitSuccess({ message: 'Saved', isOpen: true })
            app.refetch({ throwOnError: true })
            setTab(0)
          }
        },
        onError: error => {
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }

  function typeForm() {
    return (
      <Grid container justifyContent="center" spacing={3}>
        {fields.map(field => inputs(field))}
      </Grid>
    )
  }

  function inputs(field) {
    let jsxElements = []
    switch (field.type) {
      case 'boolean':
        jsxElements.push(
          <BooleanInput key={field.key} name={field.key} control={control} label={field.label} />
        )
        break
      case 'select':
        jsxElements.push(
          <SelectInput
            key={field.key}
            name={field.key}
            control={control}
            label={field.label}
            values={field.values}
          />
        )
        break
      case 'text':
      case 'password':
      case 'number':
        jsxElements.push(
          <TextInput
            key={field.key}
            name={field.key}
            control={control}
            type={field.type}
            label={field.label}
            multiline={false}
          />
        )
        break
      case 'conditional':
        const conditional = getValues(field.inputCondition)
        if (field.conditional && conditional === field.valueCondition) {
          jsxElements.push(
            <TextInput
              key={field.key}
              name={field.key}
              control={control}
              label={field.label}
              type={field.typeInput}
              multiline={field.multiline}
              rows={field.rows}
            />
          )
        }
        break
      default:
        break
    }

    return jsxElements.map(jsxElement => jsxElement)
  }

  function checkConnection() {
    const data = modifyData(getValues())
    validateTransactionConnection(
      { data },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['Apps', app.data?.data.appId])
        },
        onSuccess: response => {
          if (response.data.valid) {
            setConnectionConnected(true)
            setConnectionMessage('')
          } else {
            let defaultError = {
              error: 'invalid_request',
              error_description: 'Connection Error'
            }
            defaultError = JSON.stringify(defaultError, null, '\t')
            setConnectionConnected(false)
            setConnectionMessage(JSON.stringify(response.data.data) || defaultError)
          }
        },
        onError: error => {
          setConnectionConnected(false)
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHook)}>
      {typeForm()}
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'right' }}>
          <Tooltip title="Check connection">
            <Button
              color={
                connectionConnected !== undefined
                  ? connectionConnected
                    ? 'primary'
                    : 'secondary'
                  : 'default'
              }
              size="small"
              onClick={() => checkConnection()}
              variant="contained"
              disabled={isLoadingValidateConnection}
            >
              {connectionConnected !== undefined ? (
                connectionConnected ? (
                  <>
                    <CheckBoxIcon /> Connected
                  </>
                ) : (
                  <>
                    <Cancel /> Connection Error
                  </>
                )
              ) : isLoadingValidateConnection ? (
                'Loading...'
              ) : (
                'Test Connectivityy'
              )}
            </Button>
          </Tooltip>
        </Grid>
        {connectionMessage ? (
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
            <Tooltip title="View connection error details">
              <Button
                className={classes.documentation}
                style={{ color: '#1769ff' }}
                onClick={() => setShowModal({ open: true, message: connectionMessage })}
              >
                Error details
              </Button>
            </Tooltip>
          </Grid>
        ) : null}
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button
            className={classes.bgColorPrimary}
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              isLoadingUpdateConnection || !connectionConnected || isLoadingValidateConnection
            }
          >
            Save
          </Button>
        </Grid>
        <InfoModal
          showModal={showModal.open}
          onClose={() => setShowModal({ open: false, message: '' })}
          warningMessage={showModal.message}
          title="Error connection details"
        />
      </Grid>
    </form>
  )
}
