import { FormControlLabel, Switch } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'

// Form Validation
import { Controller } from 'react-hook-form'
import { generateJsonValidation } from 'utils/validator'

const ColorSwitch = withStyles({
  switchBase: {
    color: '#fafafa',
    '&$checked': {
      color: '#f15f22'
    },
    '&$checked + $track': {
      backgroundColor: '#f15f22'
    }
  },
  checked: {},
  track: {}
})(Switch)

const SwitchField = props => {
  const {
    summaryField,
    control,
    index,
    rules,
    label,
    onChange,
    validationsError,
    setValidationErrorsState
  } = props

  const [initialErrorMessages] = useState(validationsError)

  // eslint-disable-next-line
  const changeValidationToSuccess = () => {
    setValidationErrorsState(old => {
      const previousValidation = old[summaryField.type] ?? {}
      return {
        ...old,
        [summaryField.type]: {
          ...previousValidation,
          messages: [{ type: 'success', message: 'Accepted by User' }],
          valid: true
        }
      }
    })
  }

  const changeValidationToError = initialErrorMessages => {
    setValidationErrorsState(old => {
      const previousValidation = old[summaryField.type] ?? {}
      if (initialErrorMessages) {
        return {
          ...old,
          [summaryField.type]: {
            ...previousValidation,
            messages: initialErrorMessages,
            valid: null
          }
        }
      } else {
        return old
      }
    })
  }

  return (
    <FormControlLabel
      labelPlacement="start"
      control={
        <Controller
          render={({ field, fieldState: { error } }) => (
            <ColorSwitch
              {...field}
              id={summaryField.id}
              error={!!error}
              onChange={e => {
                field.onChange(Boolean(e.target.checked))
                if (Boolean(e.target.checked)) {
                  changeValidationToSuccess()
                } else {
                  changeValidationToError(initialErrorMessages)
                }
                if (typeof onChange === 'function') {
                  onChange(Boolean(e.target.checked))
                }
              }}
              checked={Boolean(field.value)}
            />
          )}
          name={`summaryFields.${index}.value`}
          control={control}
          rules={generateJsonValidation(rules, summaryField.type)}
        />
      }
      label={label ?? ''}
    />
  )
}

export default SwitchField
