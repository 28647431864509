import React from 'react'

// @material-ui/core components
//import { makeStyles } from '@material-ui/core/styles'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'

//import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/customModalStyles.js'
import CustomModal from 'components/CustomModal/CustomModal.js'
import { Typography } from '@material-ui/core'
//const useStyles = makeStyles(styles)
export default function CustomConfirmAlert(props) {
  //const [stringValue, setStringValue] = useState(props.initValue)
  // const classes = useStyles()
  return (
    <CustomModal
      title={props.title}
      confirmBtnCssClass={props.confirmBtnCssClass}
      cancelBtnCssClass={props.cancelBtnCssClass}
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
    >
      <div style={{ width: '100%' }}>
        <Typography style={{ textAlign: 'center' }}>{props.message}</Typography>
      </div>
      <GridContainer container spacing={4} style={{ paddingTop: '10px' }}>
        <GridItem xs={12} sm={12} md={12} lg={12}></GridItem>
      </GridContainer>
    </CustomModal>
  )
}
