import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Fade } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'

// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'
import Add from '@material-ui/icons/PlaylistAdd'

// General components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import NavPills from 'components/NavPills/NavPills.js'
import Button from 'components/CustomButtons/Button.js'

//components
//import LogsTab from "./Components/LogsTab/LogsTab.js";
import IntegrationsTab from './Components/IntegrationsTab/IntegrationsTab.js'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/intelligenceStyle'

const useStyles = makeStyles(styles)

export default function Intelligence (props) {
  const classes = useStyles()
  if (props.pageIsLoading) {
    return <div></div>
  }
  function returnTabsToDisplay () {
    let tabs = [
      {
        tabButton: 'Integrations',
        tabContent: <IntegrationsTab {...props} />
      }
    ]
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h3 className={classes.formHeader}>
              Relish Intelligence Connector
            </h3>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                color='behance'
                simple
                className={classes.documentation}
                href='/admin/intelligence/documentation'
                target='_blank'
              >
                Version 1.0
              </Button>
            </GridItem>
          </CardHeader>
          <CardBody>
            <NavPills
              action={actions => {
                if (actions) {
                  props.updateHeightFunction(actions.updateHeight)
                }
              }}
              animateHeight={false}
              color='primary'
              tabs={tabs}
            />
          </CardBody>
        </Card>
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={3}>
          <Card className={classes.appBar}>
            <CardHeader>
              Application List
              <Tooltip title='Create new app'>
                <Button
                  justIcon
                  round
                  simple
                  color='primary'
                  className={classes.marginRight}
                  onClick={() => props.onCreateButton()}
                >
                  <Add className={classes.icons} />
                </Button>
              </Tooltip>
            </CardHeader>
            <CardBody>
              <List>
                {props.appList.map((prop, key) => {
                  return (
                    <ListItem
                      className={classes.listItem}
                      button
                      selected={props.selectedAppId === prop.env}
                      key={key}
                      onClick={() => props.onAppChanged(prop.env)}
                    >
                      <ListItemAvatar>
                        {props.selectedAppId === prop.env ? (
                          <Avatar>
                            <TouchApp />
                          </Avatar>
                        ) : (
                          <Avatar className={classes.clearAvatar}></Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={prop.appName}
                        secondary={`Type: ${prop.type}`}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={9}>
          {props.formIsLoading ? (
            <Card>
              <GridContainer justify='center'>
                <div className={classes.circularProgress}>
                  <CircularProgress color='inherit' />
                </div>
              </GridContainer>
            </Card>
          ) : props.selectedAppId || props.displayMode === 'create' ? (
            returnTabsToDisplay()
          ) : (
            <Card>
              <CardBody>
                <div className={classes.typo}>
                  <div className={classes.note}>No apps created</div>
                  <h6>Click on the create button to start...</h6>
                </div>
              </CardBody>
            </Card>
          )}
        </GridItem>
        <Snackbar
          place='br'
          color='success'
          icon={AddAlert}
          message={props.validationMessage}
          open={props.submitSuccess}
          closeNotification={() => props.setSubmitSuccess(false)}
          close
        />
        <Snackbar
          place='bl'
          color='danger'
          icon={AddAlert}
          message={props.validationMessage}
          open={props.createError}
          closeNotification={() => props.setCreateError(false)}
          close
        />
      </GridContainer>
    </Fade>
  )
}
