import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

// Material UI
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

// Form
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

// Constants
import { fieldsByService, InputDefault } from 'utils/Constants'

// Icons
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Cancel from '@material-ui/icons/Cancel'

// Inputs
import { TextInput } from '../Inputs/TextInput'
import { SelectInput } from '../Inputs/SelectInput'
import { BooleanInput } from '../Inputs/BooleanInput'
import { ArrayKeyValueInput } from '../Inputs/ArrayInput'

// Components
import InfoModal from '../Components/InfoModal'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Hooks
import { useUpdateTransactionConnection, useValidateTransactionsConnection } from 'hooks/useInvoiceConf'

const useStyles = makeStyles(styles)

const schema = yup.object().shape({
  type: yup.string().required(),
  siteUrl: yup.string().required(),
  queryParams: yup.string(),
  user: yup.string().when('type', (type, schema) => {
    switch (type) {
      case 'basic':
        return schema.min(1).required()
      default:
        return schema.optional()
    }
  }),
  password: yup.string().when('type', (type, schema) => {
    switch (type) {
      case 'basic':
        return schema.min(1).required()
      default:
        return schema.optional()
    }
  }),
  headerParamsEnabled: yup.boolean(),
  headerParams: yup.array().of(
    yup.object().shape({
      key: yup.string(),
      value: yup.string()
    })
  )
})

const connectionObj = {
  user: '',
  password: '',
  siteUrl: '',
  queryParams: '',
  type: '',
  certificate: '',
  headerParamsEnabled: false,
  headerParams: [{ key: '', value: '' }]
}

export default function InvoiceGroupForm(props) {
  const classes = useStyles()
  const { group, app, setCreateError, setSubmitSuccess, setTab } = props
  const [connectionForm] = useState(connectionObj)
  const queryClient = useQueryClient()
  const [connectionConnected, setConnectionConnected] = useState(undefined)
  const [connectionMessage, setConnectionMessage] = useState('')
  const [showModal, setShowModal] = useState({ open: false, message: '' })

  const {
    handleSubmit,
    formState: { isDirty },
    control,
    getValues,
    reset
  } = useForm({
    mode: 'all',
    defaultValues: connectionForm,
    resolver: yupResolver(schema),
    shouldUnregister: false
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headerParams'
  })

  useEffect(() => {
    if (isDirty) {
      setConnectionConnected(undefined)
      setConnectionMessage('')
    }
  }, [isDirty, setConnectionConnected, setConnectionMessage])

  useEffect(() => {
    if (!app.data?.data) {
      return
    }

    switch (app.data?.data.service) {
      case 's4Hana':
        if (app.data?.data.params.s4Hana && app.data?.data.params.s4Hana[group]) {
          setConnectionConnected(true)
          switch (app.data?.data.params.s4Hana[group].type) {
            case 'basic':
              const connection = {
                siteUrl: app.data?.data.params.s4Hana[group].siteUrl,
                type: app.data?.data.params.s4Hana[group].type,
                queryParams: app.data?.data.params.s4Hana[group].queryParams,
                user: app.data?.data.params.s4Hana[group].basicParams.user,
                password: app.data?.data.params.s4Hana[group].basicParams.password,
                headerParamsEnabled: app.data?.data.params.s4Hana[group].headerParamsEnabled,
                headerParams:
                  app.data?.data.params.s4Hana[group].headerParams?.map(obj => {
                    const key = Object.keys(obj)[0]
                    const value = obj[key]
                    return { key, value }
                  }) ?? []
              }
              reset(connection)
              break
            default:
              break
          }
        }
        break
      default:
        break
    }
  }, [app.data.data, connectionForm, group, reset])

  const { mutate: updateTransactionConnection, isLoading: isLoadingUpdateConnection } = useUpdateTransactionConnection({
    appId: app.data?.data.appId
  })

  const { mutate: validateTransactionConnection, isLoading: isLoadingValidateConnection } = useValidateTransactionsConnection({
    appId: app.data?.data.appId
  })

  function modifyData(data) {
    let newData = {}
    switch (app.data?.data.service) {
      case 's4Hana':
        switch (data.type) {
          case 'basic':
            newData = {
              group: group,
              service: app.data?.data.service,
              s4Hana: {
                [group]: {
                  siteUrl: data.siteUrl,
                  type: data.type,
                  queryParams: data.queryParams,
                  headerParamsEnabled: data.headerParamsEnabled,
                  headerParams: data.headerParamsEnabled ? data.headerParams?.map(({ key, value }) => ({ [key]: value })) ?? [] : [],
                  basicParams: {}
                }
              }
            }
            if (data.user !== InputDefault) {
              newData.s4Hana[group].basicParams.user = data.user
            }
            if (data.password !== InputDefault) {
              newData.s4Hana[group].basicParams.password = data.password
            }
            break
          default:
            break
        }

        break
      default:
        break
    }
    return newData
  }

  function onSubmitHook(data) {
    data = modifyData(data)
    updateTransactionConnection(
      { data },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['Apps', app.data?.data.appId])
        },
        onSuccess: response => {
          if (response.status === 200) {
            setSubmitSuccess({ message: 'Saved', isOpen: true })
            app.refetch({ throwOnError: true })
            setTab(0)
          }
        },
        onError: error => {
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }

  function typeForm() {
    let fields = []
    if (!group || group === '') {
      return
    }
    //get service
    fields = fieldsByService[(app.data?.data.service)]

    return (
      <Grid container justifyContent="center" spacing={3}>
        {fields.map(field => inputs(field))}
      </Grid>
    )
  }

  function inputs(field) {
    let jsxElements = []
    switch (field.type) {
      case 'boolean':
        jsxElements.push(<BooleanInput key={field.key} name={field.key} control={control} label={field.label} />)
        break
      case 'select':
        jsxElements.push(<SelectInput key={field.key} name={field.key} control={control} label={field.label} values={field.values} />)
        break
      case 'text':
      case 'password':
      case 'number':
        jsxElements.push(
          <TextInput key={field.key} name={field.key} control={control} type={field.type} label={field.label} multiline={false} />
        )
        break
      case 'conditional':
        const conditional = getValues(field.inputCondition)
        if (field.conditional && conditional === field.valueCondition) {
          switch (field.typeInput) {
            case 'arrayKeyValue':
              jsxElements.push(
                <ArrayKeyValueInput
                  key={field.key}
                  name={field.key}
                  control={control}
                  label={field.label}
                  fields={fields}
                  append={append}
                  remove={remove}
                />
              )
              break
            default:
              jsxElements.push(
                <TextInput
                  key={field.key}
                  name={field.key}
                  control={control}
                  label={field.label}
                  type={field.typeInput}
                  multiline={field.multiline}
                  rows={field.rows}
                />
              )
              break
          }
        }
        break
      default:
        break
    }

    return jsxElements.map(jsxElement => jsxElement)
  }

  function checkConnection() {
    const data = modifyData(getValues())
    validateTransactionConnection(
      { data },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['Apps', app.data?.data.appId])
        },
        onSuccess: response => {
          if (response.data.valid) {
            setConnectionConnected(true)
            setConnectionMessage('')
          } else {
            let defaultError = {
              error: 'invalid_request',
              error_description: 'Connection Error'
            }
            defaultError = JSON.stringify(defaultError, null, '\t')
            setConnectionConnected(false)
            setConnectionMessage(JSON.stringify(response.data.data) || defaultError)
          }
        },
        onError: error => {
          setConnectionConnected(false)
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHook)}>
      {typeForm()}
      <Grid container justifyContent="center" spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'right' }}>
          <Tooltip title="Check connection">
            <Button
              color={connectionConnected !== undefined ? (connectionConnected ? 'primary' : 'secondary') : 'default'}
              size="small"
              onClick={() => checkConnection()}
              variant="contained"
              disabled={isLoadingValidateConnection}
            >
              {connectionConnected !== undefined ? (
                connectionConnected ? (
                  <>
                    <CheckBoxIcon /> Connected
                  </>
                ) : (
                  <>
                    <Cancel /> Connection Error
                  </>
                )
              ) : isLoadingValidateConnection ? (
                'Loading...'
              ) : (
                'Test Connectivityy'
              )}
            </Button>
          </Tooltip>
        </Grid>
        {connectionMessage ? (
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
            <Tooltip title="View connection error details">
              <Button
                className={classes.documentation}
                style={{ color: '#1769ff' }}
                onClick={() => setShowModal({ open: true, message: connectionMessage })}
              >
                Error details
              </Button>
            </Tooltip>
          </Grid>
        ) : null}
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button
            className={classes.bgColorPrimary}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoadingUpdateConnection || !connectionConnected || isLoadingValidateConnection}
          >
            Save
          </Button>
        </Grid>
        <InfoModal
          showModal={showModal.open}
          onClose={() => setShowModal({ open: false, message: '' })}
          warningMessage={showModal.message}
          title="Error connection details"
        />
      </Grid>
    </form>
  )
}
