import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Material UI Components
import IconButton from '@material-ui/core/IconButton'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import MaterialTable from 'material-table'
import Tooltip from '@material-ui/core/Tooltip'

// Icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

// core components
import Badge from 'components/Badge/Badge'

function TabPanel(props) {
  const { children, tabPanelIndex, currentIndex, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={currentIndex !== tabPanelIndex}
      id={`simple-tabpanel-${tabPanelIndex}`}
      aria-labelledby={`simple-tab-${tabPanelIndex}`}
      {...other}
    >
      {currentIndex === tabPanelIndex && <>{children}</>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  tabPanelIndex: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired
}

export default function PocExecutionApp({ execution }) {
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [selectedValidation, setSelectedValidation] = useState(null)

  return (
    <>
      <TabPanel tabPanelIndex={0} currentIndex={currentIndex}>
        <MaterialTable
          columns={[
            {
              cellStyle: { padding: '0px 16px' },
              title: 'Validation type',
              field: 'name'
              //render: rowData => rowData.name
            },
            {
              cellStyle: { padding: '0px 16px' },
              title: 'Status',
              field: 'result',
              render: rowData => (
                <Badge
                  color={
                    (rowData.result.toUpperCase() === 'PASSED' && 'success') ||
                    (rowData.result.toUpperCase() === 'PASSED WITH CAUTIONS' && 'yellow') ||
                    (rowData.result.toUpperCase() === 'FAILED' && 'danger') ||
                    'gray'
                  }
                >
                  {(rowData.result.toLowerCase() === 'no match' && 'No data Available') ||
                    rowData.result}
                </Badge>
              )
            },
            {
              cellStyle: { padding: '0px 16px' },
              title: 'Details',
              render: rowData => (
                <Tooltip title={'Details'}>
                  <IconButton
                    aria-label="Details"
                    onClick={() => {
                      setSelectedValidation(rowData)
                      setCurrentIndex(1)
                    }}
                  >
                    <MoreHorizIcon fontSize="small" style={{ color: '#081c3e' }} />
                  </IconButton>
                </Tooltip>
              )
            }
          ]}
          data={execution?.validations}
          options={{
            actionsColumnIndex: -1,
            sorting: true,
            showTitle: false,
            search: false,
            paging: false,
            toolbar: false
          }}
        />
      </TabPanel>
      <TabPanel tabPanelIndex={1} currentIndex={currentIndex}>
        <Tooltip title="Return to validations">
          <IconButton
            onClick={e => {
              setSelectedValidation(null)
              setCurrentIndex(0)
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </Tooltip>
        <MaterialTable
          columns={[
            {
              title: 'Message',
              field: 'message',
              render: rowData => (
                <span
                  style={{ whiteSpace: 'pre-wrap' }}
                  dangerouslySetInnerHTML={{ __html: rowData.message }}
                ></span>
              )
            }
          ]}
          data={selectedValidation?.messages}
          options={{
            actionsColumnIndex: -1,
            sorting: true,
            showTitle: false,
            search: false,
            paging: false,
            toolbar: false
          }}
        />
      </TabPanel>
    </>
  )
}
