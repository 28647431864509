import React from 'react'
import ResizeObserver from 'react-resize-observer'
class WizardNavigation extends React.Component {
  constructor (props) {
    super(props)

    var width

    if (this.props.steps.length === 1) {
      width = '100%'
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = '50%'
        } else {
          width = 100 / 3 + '%'
        }
      } else {
        if (this.props.steps.length === 2) {
          width = '50%'
        } else {
          width = 100 / 3 + '%'
        }
      }
    }
    this.state = {
      movingTabStyle: {
        transition: 'transform 0s'
      },
      width: width
    }
    this.refreshAnimation = this.refreshAnimation.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.id !== this.props.id) {
      this.props.refreshAnimation(this.refreshAnimation)
      this.refreshAnimation(0)
    }
  }
  componentDidMount () {
    this.props.refreshAnimation(this.refreshAnimation)
    this.refreshAnimation(0)
    // window.addEventListener('resize', this.updateWidth)
  }
  refreshAnimation (index) {
    if (this.props.wizard.current) {
      var total = this.props.steps.length
      var li_width = 100 / total
      var total_steps = this.props.steps.length
      var move_distance =
        this.props.wizard.current.children[0].offsetWidth / total_steps
      var index_temp = index
      var vertical_level = 0

      var mobile_device = window.innerWidth < 600 && total > 3

      if (mobile_device) {
        move_distance = this.props.wizard.current.children[0].offsetWidth / 2
        index_temp = index % 2
        li_width = 50
      }

      var step_width = move_distance
      move_distance = move_distance * index_temp

      var current = index + 1

      if (current === 1 || (mobile_device === true && index % 2 === 0)) {
        move_distance -= 8
      } else if (
        current === total_steps ||
        (mobile_device === true && index % 2 === 1)
      ) {
        move_distance += 8
      }

      if (mobile_device) {
        vertical_level = parseInt(index / 2, 10)
        vertical_level = vertical_level * 38
      }
      var movingTabStyle = {
        width: step_width,
        transform:
          'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
        transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'
      }

      this.setState({ movingTabStyle: movingTabStyle, width: li_width + '%' })
    }
  }
  getWizardNavigation () {
    return (
      <div>
        <div style={{ position: 'relative' }}>
          <ResizeObserver
            onResize={rect => {
              this.refreshAnimation(this.props.state.currentStep)
            }}
            onPosition={rect => {}}
          />
        </div>
        {!this.props.hideNavigation ? (
          <div className={this.props.classes.wizardNavigation}>
            <ul className={this.props.classes.nav}>
              {!this.props ? (
                <div></div>
              ) : (
                this.props.steps.map((prop, key) => {
                  return (
                    <li
                      className={this.props.classes.steps}
                      key={key}
                      style={{ width: this.state.width }}
                    >
                      <a
                        href='#geo'
                        className={this.props.classes.stepsAnchor}
                        onClick={e => {
                          e.preventDefault()
                          this.props.navigationStepChange(
                            key,
                            this.refreshAnimation
                          )
                        }}
                      >
                        {prop.stepName}
                      </a>
                    </li>
                  )
                })
              )}
            </ul>
            <div
              className={
                this.props.classes.movingTab +
                ' ' +
                this.props.classes[this.props.color]
              }
              style={this.state.movingTabStyle}
            >
              {this.props.steps[this.props.state.currentStep].stepName}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
  render () {
    if (this.props.state.refresh)
      return (
        <div>
          <div></div>
          {this.getWizardNavigation()}
        </div>
      )
    return <div>{this.getWizardNavigation()}</div>
  }
}
export default WizardNavigation
