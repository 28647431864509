import React, { useState } from 'react'
import XLSX from 'xlsx'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
// import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import CustomModal from 'components/CustomModal/CustomModal.js'

//material-ui
import { Select, MenuItem } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

//utils
import { showLoading } from 'utils/functions'
import { hideLoading } from 'utils/functions.js'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

const useStyles = makeStyles(styles)

export default function CustomDownloadButtom (props) {
  const {
    data,
    dataMapping = undefined,
    onSuccess,
    onFailed,
    className,
    disabled = false,
    hidden = false,
    style,
    asyncDataFunction
  } = props

  const classes = useStyles()
  const exportFileTypes = [
    { value: 'csv', label: 'CSV' },
    { value: 'xlsx', label: 'XLSX' },
    { value: 'txt', label: 'TXT' },
    { value: 'pdf', label: 'PDF' }
  ]
  const [showModal, setShowModal] = useState(false)
  const [fileName, setFileName] = useState('')
  const [fileType, setFileType] = useState(exportFileTypes[0].value)

  async function downloadLogs (fileName, fileType, data, asyncDataFunction) {
    if (asyncDataFunction) data = await asyncDataFunction()
    let jsonData = []
    let jsonRow = {}
    let arrayRow = []
    let pdfHeaders = [],
      headersFilled = false
    const exportDate = new Date()
    setShowModal(false)
    showLoading()
    try {
      const date = `${exportDate.getFullYear()}-${(exportDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${exportDate
        .getDate()
        .toString()
        .padStart(2, '0')} ${exportDate
        .getHours()
        .toString()
        .padStart(2, '0')}:${exportDate
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${exportDate
        .getSeconds()
        .toString()
        .padStart(2, '0')}`

      if (!fileName) {
        fileName = date
      }

      if (dataMapping) {
        // eslint-disable-next-line
        for (const item of data) {
          jsonRow = {}
          arrayRow = []
          // eslint-disable-next-line
          for (const map in dataMapping) {
            if (fileType === 'pdf') {
              if (!headersFilled) pdfHeaders.push(map)

              if (dataMapping[map].includes('.')) {
                let aux = dataMapping[map].split('.')
                let auxValue = item
                for (let index = 0; index < aux.length; index++) {
                  auxValue = auxValue[aux[index]]
                }
                arrayRow.push(auxValue)
              } else {
                arrayRow.push(item[dataMapping[map]])
              }
            } else {
              if (dataMapping[map].includes('.')) {
                let aux = dataMapping[map].split('.')
                let auxValue = item
                for (let index = 0; index < aux.length; index++) {
                  auxValue = auxValue[aux[index]]
                }
                jsonRow[map] = auxValue
              } else {
                jsonRow[map] = item[dataMapping[map]]
              }
            }
          }

          if (fileType === 'pdf') {
            jsonData.push(arrayRow)
            headersFilled = true
          } else jsonData.push(jsonRow)
        }
      } else {
        for (let index = 0; index < data.length; index++) {
          const item = data[index]
          jsonRow = {}
          arrayRow = []
          // eslint-disable-next-line
          for (const key in item) {
            if (fileType === 'pdf') {
              arrayRow.push(item[key])
              if (!headersFilled) pdfHeaders.push(key)
            } else {
              jsonRow[key] = item[key]
            }
          }

          if (fileType === 'pdf') {
            jsonData.push(arrayRow)
            headersFilled = true
          } else jsonData.push(jsonRow)
        }
      }

      if (fileType === 'pdf') {
        const doc = new jsPDF({
          orientation: 'landscape',
          unit: 'in',
          format: [15, 30]
        })

        doc.text(`Export Date: ${date}`, 0.5, 0.5)
        autoTable(doc, {
          head: [pdfHeaders],
          body: jsonData,
          theme: 'striped',
          styles: {
            cellWidth: 'wrap',
            lineWidth: 0.01
          }
        })

        doc.save(fileName + '.' + fileType)
      } else {
        let wb = XLSX.utils.book_new()
        let ws

        if (fileType === 'txt') {
          jsonData.unshift({ Date: `Export Date: ${date}` })
          ws = XLSX.utils.json_to_sheet(jsonData)
          const element = document.createElement('a')
          element.setAttribute('download', fileName + '.' + fileType)
          element.setAttribute(
            'href',
            URL.createObjectURL(
              new Blob([XLSX.utils.sheet_to_csv(ws, { FS: '|' })])
            )
          )
          element.style.display = 'none'
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)
        } else {
          if (fileType === 'xlsx') {
            XLSX.utils.book_append_sheet(
              wb,
              XLSX.utils.json_to_sheet([{ 'Export Date': date }]),
              'export_date'
            )
          } else {
            jsonData.unshift({ Date: `Export Date: ${date}` })
          }
          ws = XLSX.utils.json_to_sheet(jsonData)
          XLSX.utils.book_append_sheet(wb, ws, 'data')
          XLSX.writeFile(wb, fileName + '.' + fileType, {
            bookType: fileType,
            type: 'file'
          })
        }

        if (onSuccess) onSuccess()
      }
    } catch (error) {
      console.error('Error trying to dowload files ' + error)
      if (onFailed) onFailed()
    } finally {
      setFileName('')
      setFileType(exportFileTypes[0].value)
      hideLoading()
    }
  }

  function downloadModal () {
    return (
      <CustomModal
        successBtnTxt={'Export'}
        title={'Download File'}
        confirmBtnCssClass={classes.colorPrimary}
        onCancel={() => {
          setFileName('')
          setFileType(exportFileTypes[0].value)
          setShowModal(false)
        }}
        onConfirm={() => {
          downloadLogs(fileName, fileType, data, asyncDataFunction)
        }}
      >
        <GridContainer container spacing={4} style={{ paddingBottom: '5%' }}>
          <GridItem xs={6} sm={6} md={6} lg={6}>
            <CardHeader>Type File Name</CardHeader>
            <CardBody>
              <CustomInput
                labelProps={{ style: { width: '100%' } }}
                id="file"
                formControlProps={{
                  fullWidth: true
                }}
                required
                inputProps={{
                  type: 'text',
                  value: fileName,
                  onChange: event => {
                    setFileName(event.target.value)
                  }
                }}
              />
            </CardBody>
          </GridItem>
          <GridItem xs={6} sm={6} md={6} lg={6}>
            <div style={{ paddingBottom: '20px' }}>
              <CardHeader>File type</CardHeader>
            </div>
            <CardBody>
              <Select
                MenuProps={{
                  style: { zIndex: 5555 }
                }}
                style={{ width: '100%' }}
                value={fileType}
                onChange={e => setFileType(e.target.value)}
                inputProps={{
                  name: 'fieldType',
                  id: 'fieldType'
                }}
              >
                {exportFileTypes.map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </CardBody>
          </GridItem>
        </GridContainer>
      </CustomModal>
    )
  }

  return (
    <div hidden={hidden}>
      {/* <Card>
        <GridContainer>
          <GridItem>{showModal ? downloadModal() : ''}</GridItem>
        </GridContainer>
      </Card> */}
      {showModal ? downloadModal() : ''}
      <Button
        size="small"
        className={className}
        onClick={() => {
          setShowModal(true)
        }}
        style={style}
        disabled={disabled}
      >
        <GetAppIcon />
      </Button>
    </div>
  )
}
