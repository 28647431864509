export const initialState = {
    apis: {}
};

export const UPDATE_API_LIST = "Intelligence/UPDATE_API_LIST";

export const updateApis = (apis) => ({
    type: UPDATE_API_LIST,
    payload: apis
})

export default function IntelligenceReducer(state = initialState, { type , payload }) {
    switch (type) {
        case UPDATE_API_LIST:
            return {
                ...state,
                apis: payload
            };
        default:
            return state;
    }
}