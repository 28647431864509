import { Badge, Grid, TextField, Tooltip } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

import React from 'react'
import { Controller } from 'react-hook-form'
import { generateJsonValidationV2 } from 'utils/validator'
import { errorTypes, getError } from './useHeader'

export const HeaderField = props => {
  const {
    control,
    index,
    readOnly,
    label,
    headerField,
    multiline,
    headerData,
    headerFieldsActions,
    requiredFields,
    getValues
  } = props

  const { deleteErrorHeader, getColors, classes } = headerFieldsActions

  const errorField = headerData.errors.find(x => x.field === headerField.field)

  const errorData = getError(errorField)

  const fieldRule = requiredFields.find(x => x.standardField === headerField.field)
  const rules = readOnly ? {} : generateJsonValidationV2(fieldRule, getValues)

  return (
    <Controller
      render={({ field, fieldState: { error, isDirty } }) => {
        const isError = Boolean(error) || errorData?.criticalityType === errorTypes.critical

        const isVisible = Boolean(error) || Boolean(errorData)

        const errorMessage = errorData ? errorData?.message : error?.message

        return (
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Tooltip
                classes={{
                  arrow: isError ? classes.errorColor : classes.warningColor,
                  tooltip: isError ? classes.tooltipError : classes.tooltipWarning
                }}
                title={isVisible ? errorMessage : ''}
                arrow
                placement="right"
              >
                <Badge
                  style={{ width: '95%' }}
                  classes={{
                    badge: isError ? classes.badgeError : classes.badgeWarning
                  }}
                  badgeContent={
                    isError ? '!' : <WarningIcon className={classes.warningColor}></WarningIcon>
                  }
                  invisible={isVisible ? false : true}
                >
                  <TextField
                    {...field}
                    style={{ width: '100%' }}
                    id={headerField.id}
                    label={label ?? ''}
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline={multiline}
                    rows="3"
                    error={isError}
                    disabled={readOnly ?? false}
                    className={readOnly ? classes.readOnly : ''}
                    InputProps={{
                      readOnly: readOnly ?? false,
                      classes: {
                        notchedOutline: !isError ? getColors(errorData, isDirty).inputColor : ''
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        root: !isError ? getColors(errorData, isDirty).color : '',
                        focused: !isError ? getColors(errorData, isDirty).color : ''
                      }
                    }}
                    onChange={event => {
                      field.onChange(event.target.value)
                      deleteErrorHeader({ field: headerField.field })
                    }}
                  />
                </Badge>
              </Tooltip>
            </Grid>
          </Grid>
        )
      }}
      name={`headerFields.${index}.value`}
      control={control}
      rules={rules}
    />
  )
}
