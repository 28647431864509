import React, { useState } from 'react'

import { Badge, Tooltip } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import WarningIcon from '@material-ui/icons/Warning'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import { checkErrorOrWarning } from 'utils/functions'
import { generateJsonValidation } from 'utils/validator'

// Form Validation
import { Controller } from 'react-hook-form'

const useStyles = makeStyles(styles)

const useStylesSelect = makeStyles(theme => ({
  selectBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green'
    }
  },
  inputWarning: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f3bc07 !important'
    }
  },
  inputSuccess: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green !important'
    }
  },
  inputError: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red !important'
    }
  }
}))

const SelectInvoiceType = props => {
  const { summaryField, control, index, rules, label, validationsError } = props

  const [typeMsg] = useState(checkErrorOrWarning(validationsError))
  const classes = useStyles()
  const classesSelect = useStylesSelect()

  function validationMessages(validationsError) {
    if (!validationsError) {
      return ''
    }

    if (Array.isArray(validationsError)) {
      return (
        <>
          {validationsError.map((message, index) => (
            <div key={index}>{message.message}</div>
          ))}
        </>
      )
    }

    const messages = validationsError.split('\n')

    return (
      <>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </>
    )
  }

  function getColors(isDirty, typeMsg, error) {
    let color = ''
    let inputColor = ''
    let badgeColor = ''
    let tooltipColor = ''

    if (typeMsg === 'error') {
      color = classes.errorColor
      inputColor = classesSelect.inputError
      tooltipColor = classes.tooltipError
    }

    // if (validated) {
    //   color = classes.successColor
    //   inputColor = classesSelect.inputSuccess
    // }

    // if (isValid && !Boolean(error)) {
    //   color = classes.successColor
    //   inputColor = classesSelect.inputSuccess
    // }

    if (isDirty && !Boolean(error)) {
      color = classes.successColor
      inputColor = classesSelect.inputSuccess
    }

    if (!isDirty && typeMsg === 'warning') {
      color = classes.warningColor
      inputColor = classesSelect.inputWarning
      tooltipColor = classes.tooltipWarning
    }

    return { color, inputColor, badgeColor, tooltipColor }
  }

  return (
    <Controller
      render={({ field, fieldState: { error, isDirty } }) => {
        const isWarning = !isDirty && typeMsg === 'warning' && !Boolean(error)
        const isVisible =
          (Boolean(error) && typeMsg === 'error') || (!isDirty && typeMsg === 'error') || isWarning
        return (
          <Tooltip
            classes={{
              arrow: isWarning ? classes.warningColor : classes.errorColor,
              tooltip: isWarning ? classes.tooltipWarning : classes.tooltipError
            }}
            title={isVisible ? validationMessages(validationsError) : ''}
            arrow
            placement="right"
          >
            <Badge
              style={{ width: '95%' }}
              classes={{
                badge: isWarning ? classes.badgeWarning : classes.badgeError
              }}
              badgeContent={
                isWarning ? <WarningIcon className={classes.warningColor}></WarningIcon> : '!'
              }
              invisible={isVisible ? false : true}
            >
              <FormControl
                variant="outlined"
                error={Boolean(error) || typeMsg === 'error'}
                style={{ width: '100%' }}
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  classes={{
                    root: !Boolean(error) ? getColors(isDirty, typeMsg, error).color : '',
                    focused: !Boolean(error) ? getColors(isDirty, typeMsg, error).color : ''
                  }}
                >
                  {label}
                </InputLabel>
                <Select
                  {...field}
                  id={summaryField.id}
                  style={{ width: '100%' }}
                  labelId="demo-simple-select-label"
                  label={label ?? ''}
                  className={!Boolean(error) ? getColors(isDirty, typeMsg, error).inputColor : ''}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'creditMemo'} key={'creditMemo'}>
                    CREDIT MEMO
                  </MenuItem>
                  <MenuItem value={'invoice'} key={'invoice'}>
                    INVOICE
                  </MenuItem>
                  <MenuItem value={'paymentRequest'} key={'paymentRequest'}>
                    PAYMENT REQUEST
                  </MenuItem>
                </Select>
              </FormControl>
            </Badge>
          </Tooltip>
        )
      }}
      name={`summaryFields.${index}.value`}
      control={control}
      rules={generateJsonValidation(rules, summaryField.type)}
    />
  )
}

export default SelectInvoiceType
