import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import { v4 as uuidv4 } from 'uuid'
// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'
import { Checkbox, Divider, FormControlLabel } from '@material-ui/core'
// Style
// Form
const useStyles = makeStyles(styles)
export const WorkTags = props => {
  const { workTags, setWorkTags, label } = props
  const classes = useStyles()
  function onAddHandler() {
    setWorkTags(previousState => [
      ...previousState,
      {
        id: uuidv4(),
        workTagName: '',
        customReportName: '',
        required: false,
        valueTag: '',
        descriptionTag: '',
        xmlTag: ''
      }
    ])
  }

  function onChangeHandler(id, field, value) {
    setWorkTags(previousState => {
      const index = previousState.findIndex(x => x.id === id)
      if (index < 0) {
        return previousState
      }

      const item = previousState[index]
      item[field] = value
      const newState = [...previousState]
      newState[index] = item
      return newState
    })
  }

  function onDeleteHandler(id) {
    setWorkTags(previousState => {
      const newState = previousState.filter(x => x.id !== id)

      return newState
    })
  }

  function details() {
    return workTags.map((x, index) => (
      <>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <TextField
            key={x.id}
            id={`workTagName-${x.id}`}
            label="Worktag Name*"
            value={x.workTagName}
            error={x.workTagName === '' ? true : false}
            onChange={e => onChangeHandler(x.id, 'workTagName', e.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <TextField
            key={x.id}
            id={`customReportName-${x.id}`}
            label="Custom Report Name*"
            value={x.customReportName}
            error={x.customReportName === '' ? true : false}
            onChange={e => onChangeHandler(x.id, 'customReportName', e.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <FormControlLabel
            control={
              <Checkbox
                key={x.id}
                id={`required-${x.id}`}
                checked={x.required}
                onChange={event => onChangeHandler(x.id, 'required', event.target.checked)}
                name={`required-${x.id}`}
                color="primary"
              />
            }
            label="Required"
          />

          <IconButton
            aria-label="Delete"
            onClick={event => {
              event.stopPropagation()
              onDeleteHandler(x.id)
            }}
            onFocus={event => event.stopPropagation()}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            key={x.id}
            id={`valueTag-${x.id}`}
            label="Value Tag*"
            value={x.valueTag}
            error={x.valueTag === '' ? true : false}
            onChange={e => onChangeHandler(x.id, 'valueTag', e.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            key={x.id}
            id={`descriptionTag-${x.id}`}
            label="Description Tag*"
            value={x.descriptionTag}
            error={x.descriptionTag === '' ? true : false}
            onChange={e => onChangeHandler(x.id, 'descriptionTag', e.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            key={x.id}
            id={`xmlTag-${x.id}`}
            label="Xml Tag*"
            value={x.xmlTag}
            error={x.xmlTag === '' ? true : false}
            onChange={e => onChangeHandler(x.id, 'xmlTag', e.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
        {index < workTags.length - 1 ? (
          <Grid item xs={12} style={{ marginBottom: '15px' }}>
            <Divider />
          </Grid>
        ) : null}
      </>
    ))
  }
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '10px' }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h5 className={classes.sectionTitle}>{label}</h5>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row">
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              item
              xs={12}
            >
              <Grid item>
                <IconButton
                  aria-label="Add"
                  onClick={event => {
                    event.stopPropagation()
                    onAddHandler()
                  }}
                  onFocus={event => event.stopPropagation()}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              item
              xs={12}
              spacing={2}
            >
              {details()}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}
