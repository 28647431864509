import React, { useEffect, useRef } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Fade from '@material-ui/core/Fade'
// @material-ui/icons
//import SettingsApplications from '@material-ui/icons/SettingsApplications';
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
// components
import GridContainer from '../../../../components/Grid/GridContainer.js'
import GridItem from '../../../../components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'

// style
import styles from '../../../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

// Views

//utils

import DunsSuppliersReact from '../DunsSuppliersReact.js'

const useStyles = makeStyles(styles)

export default function DataAssureDnBMissing (props) {
  //const changeUniqueElementFunction = useRef(() => {})
  const isMounted = useRef(false)

  useEffect(
    () => {
      // did mount
      if (!isMounted.current) {
        //getMapping()
        isMounted.current = true
      }
      // did update
      else {
      }

      //this function will be ran when the component is re-renderd or unmounted
      return () => {}
    },
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    []
  )

  const classes = useStyles()

  function getView () {
    if (props.pageIsLoading) {
      return <div></div>
    }
    function scrolltoTop () {
      let element = document.getElementById('MainGrid')
      if (element) element.scrollTo(0, 0)
    }

    return (
      <Fade in={true} timeout={250}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={3}
            className={classes.padding10}
          >
            <Card className={classes.appBar}>
              <CardHeader>Application List</CardHeader>
              <CardBody style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <List>
                  {props.appList.map((prop, key) => {
                    return (
                      <ListItem
                        style={{ paddingLeft: '5px', paddingRight: '5px' }}
                        className={classes.listItem}
                        button
                        selected={props.selectedAppId === prop.env}
                        key={key}
                        onClick={() => {
                          props.onAppChanged(prop.env)
                          scrolltoTop()
                        }}
                      >
                        <ListItemAvatar>
                          {props.selectedAppId === prop.env ? (
                            <Avatar>
                              <TouchApp />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.clearAvatar}></Avatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText
                          primary={prop.env}
                          secondary={`Type: ${prop.type}`}
                        />
                        {prop.errors && prop.errors > 0 ? (
                          <ListItemText style={{ marginLeft: '4px' }}>
                            <Button
                              className={classes.textErrors}
                              simple
                              onClick={() => props.onErrors(prop.env)}
                            >
                              <ReportProblemIcon fontSize="small" />
                              {prop.errors}{' '}
                              {prop.errors === 1 ? 'Error' : 'Errors'}
                            </Button>
                          </ListItemText>
                        ) : null}
                      </ListItem>
                    )
                  })}
                </List>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={9}
            className={classes.padding10}
          >
            {props.formIsLoading ? (
              <div />
            ) : (
              <DunsSuppliersReact initialState={props} />
            )}
          </GridItem>
          <Snackbar
            place="br"
            color="success"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.submitSuccess}
            closeNotification={() => props.setSubmitSuccess(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.createError}
            closeNotification={() => props.setCreateError(false)}
            close
          />
        </GridContainer>
      </Fade>
    )
  }
  if (props.refresh) {
    return <div>{getView()}</div>
  } else return getView()
}
