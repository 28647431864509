import React from 'react'

import AutoCompleteAsyncLineItems from 'components/Invoice/AutoCompleteAsyncLineItems'
import { useDebounce } from 'hooks/useDebounce'
import { useGetInvoiceOcrFieldValues } from 'hooks/useOcrInvoice'

const AutoCompleteAsyncDefaultValues = props => {
  const {
    lineItemSelected,
    readOnly = false,
    validationErrorsState,
    removeWarningLineItem,
    standardField,
    label,
    process,
    onChange
  } = props

  const [filter, setFilter] = React.useState('')
  const [debouncedValue] = useDebounce(filter, 500)

  const filedValues = useGetInvoiceOcrFieldValues(standardField, {
    filter: debouncedValue,
    level: 'lineItem',
    status: 'enabled',
    process
  })

  const value = lineItemSelected[standardField] ?? ''

  React.useEffect(() => {
    setFilter(value)
  }, [value])

  return (
    <AutoCompleteAsyncLineItems
      validationErrorsState={validationErrorsState}
      handleChange={onChange}
      value={value}
      lineId={lineItemSelected?.key ?? ''}
      removeWarningLineItem={removeWarningLineItem}
      readOnly={readOnly}
      optionsValues={filedValues.data}
      isLoading={filedValues.isLoading || filedValues.isFetching}
      label={label}
      field={standardField}
      setFilter={setFilter}
      filter={filter}
    />
  )
}

export default AutoCompleteAsyncDefaultValues
