import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import SidebarView from "./SidebarView";

//let _isMounted = false

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{ }
	),
	lifecycle({
		componentDidMount() {
			//_isMounted = true
		}
	})
)(SidebarView);
