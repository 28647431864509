import moment from 'moment'
import { HeaderFieldsSide } from './Constants'
import SchemaValidator from './SchemaValidator'

const patterns = {
  number: /^-?([0]{1}\.{1}[0-9]+|[1-9]{1}[0-9]*\.{1}[0-9]*|[0-9]+|0)$/,
  integer: /^[0-9]*$/
}
/**
 * Validates an object according to the model provided
 */
function validateObject(model, data) {
  let validationResult = {}
  // Validate output
  let validator = new SchemaValidator(model)
  let check = validator.validate(data)

  validationResult.isValid = check.isValid
  validationResult.errors = check.messages
  return validationResult
}

/**
 * Validates an object property according to the model provided
 */
function validateProperty(model, data, property) {
  let validationResult = {}
  // Validate output
  let validator = new SchemaValidator(model)
  let check = validator.validateProperty(property, data)

  validationResult.isValid = check.isValid
  validationResult.errors = check.messages
  return validationResult
}

function formatGerdauInvoiceNumber(number) {
  // Get value inside parenthesis if exists

  let numberFormatted = number

  // Remove non alphanumeric chars and leading 0's

  numberFormatted = numberFormatted

    .replace(/[^a-zA-Z0-9]+/g, '')

    .replace(/^0+/, '')

  return numberFormatted
}

function evaluateConditions(conditions, headerFields) {
  let conditionResults = []

  if (conditions.length === 0) {
    return true
  }
  // eslint-disable-next-line
  for (const condition of conditions) {
    const field = headerFields.find(x => x.field === condition.field)
    const fieldValue = `${field?.value}`

    switch (condition.type) {
      case 'eq':
        if (fieldValue !== null && fieldValue !== undefined) {
          conditionResults.push(fieldValue === `${condition.values}`)
        } else {
          conditionResults.push(false)
        }

        break

      default:
        break
    }
  }

  return conditionResults.every(x => x === true)
}

function generateJsonValidationV2(rule, getValues) {
  //const label = HeaderFieldsSide[field]?.label ?? field

  const validations = rule.validations ?? []
  const validator = {}
  // eslint-disable-next-line
  for (const validation of validations) {
    switch (validation.type) {
      case 'number':
        validator.pattern = {
          value: patterns.number,
          message: validation.message
        }
        break
      case 'integer':
        validator.pattern = {
          value: patterns.integer,
          message: validation.message
        }
        break
      case 'maxLength':
        validator.maxLength = {
          value: validation.value,
          message: validation.message
        }
        break
      case 'minLength':
        validator.pattern = {
          value: validation.value,
          message: validation.message
        }
        break
      case 'notContains':
        validator.validate = {
          ...(validator.validate ?? {}),
          notContains: v =>
            v ? !validation.value.some(x => v.includes(x)) || validation.message : true
        }
        break
      case 'required':
        validator.validate = {
          ...(validator.validate ?? {}),
          required: value => {
            const headerFields = getValues('headerFields')

            // eslint-disable-next-line
            const conditions = validation.conditions ?? []

            const applyValidation = evaluateConditions(conditions, headerFields)
            if (applyValidation) {
              if (value === undefined || value === null || value === '') return validation.message
            } else {
              return true
            }
          }
        }
        break

      default:
        break
    }
  }

  return validator
}

function generateJsonValidation(rule, field) {
  if (Object.keys(rule).length === 0) {
    return {}
  }

  const label = HeaderFieldsSide[field]?.label ?? field

  const validator = {
    required: {
      value: rule.required,
      message: `${label} field is required`
    }
  }

  if (!validator.required) {
    return validator
  }

  switch (rule.typeField) {
    case 'number':
      validator.pattern = {
        value: patterns.number,
        message: `Insert a valid number for ${label} field.`
      }
      break
    case 'integer':
      validator.pattern = {
        value: patterns.integer,
        message: `Insert a valid integer for ${label} field.`
      }
      break
    default:
      break
  }

  if (rule.additionalValidations) {
    const notContains = rule.additionalValidations.find(x => x.type === 'notContains')

    if (notContains) {
      validator.validate = {
        notContains: v =>
          v
            ? !notContains.values.some(x => v.includes(x)) ||
              `Insert a valid value for ${label} field.`
            : true
      }
    }
  }

  return validator
}

function validateInputV2(validations, value) {
  let hexRegex

  const dataType = validations.find(x => x.type === 'dataType')
  const required = validations.find(x => x.type === 'required')

  if (!required) {
    return { valid: true }
  }

  switch (dataType?.value) {
    case 'number':
      hexRegex = patterns.number
      break
    case 'integer':
      hexRegex = patterns.integer
      break
    default:
      return { valid: true }
  }

  return { valid: hexRegex.test(value) }
}

function validateInput(rule, value) {
  let hexRegex

  if (Object.keys(rule).length === 0) {
    return { valid: true }
  }

  if (!rule.required) {
    return { valid: true }
  }

  switch (rule.typeField) {
    case 'number':
      hexRegex = patterns.number
      break
    case 'integer':
      hexRegex = patterns.integer
      break
    case 'string':
      if (rule.descMaxLength) {
        return { valid: value.length <= Number(rule.descMaxLength), message: rule.errorMessage }
      }
      return { valid: true }
    default:
      return { valid: true }
  }

  return { valid: hexRegex.test(value) }
}

/**
 * Formats a numeric field based on the process
 * @param number
 * @param process
 * @returns Formated field
 */
function formatNumber(number = '', process = '') {
  // Get value inside parenthesis if exists
  let numberFormatted = getNumberInsideParenthesis(number)

  // Leave only the first number after an enter
  if (numberFormatted.includes('\n')) {
    numberFormatted = numberFormatted.split('\n').find(value => value.trim()) ?? ''
  }

  // Remove everything after a /
  if (numberFormatted.includes('/')) {
    numberFormatted = numberFormatted.replace(/\/.*/, '')
  }

  // Leave numeric chars, and last period
  numberFormatted = removeNonNumericChars(numberFormatted).replace(/[.](?=.*[.])/g, '')

  if (numberFormatted.endsWith('-') || numberFormatted.startsWith('-')) {
    // Remove negative signs
    numberFormatted = numberFormatted.replace(/-/g, '')

    numberFormatted = `-${numberFormatted}`

    // If value is only '-' leave in blank the number
    if (numberFormatted === '-') {
      numberFormatted = ''
    }
  }

  if (Number.isNaN(Number(numberFormatted))) {
    numberFormatted = `${Number(numberFormatted)}`
  }

  return numberFormatted
}

/**
 * Removes non numeric characters for a possible number string
 * @param number
 * @param process
 * @returns
 */
function removeNonNumericChars(number = '') {
  // Remove enters and keep only valid chars
  number = number
    .replace(/\n/g, ' ')
    .replace(/[^0-9 .,-]+/g, '')
    .trim()

  const innerNumbers = number.split(' ')
  const innerFormattedNumbers = []
  // eslint-disable-next-line
  for (let innerNumber of innerNumbers) {
    // Keep only the last period and comma
    innerNumber = innerNumber.replace(/[,](?=.*[,])/g, '').replace(/[.](?=.*[.])/g, '')
    // decimal separated number
    if (innerNumber.indexOf(',') < innerNumber.indexOf('.')) {
      innerNumber = innerNumber.replace(/,/g, '')
    } else {
      const containsComma = innerNumber.indexOf(',') !== -1
      const containsPoint = innerNumber.indexOf('.') !== -1
      if (containsComma && !containsPoint) {
        if ((innerNumber.split(',').pop()?.length || 0) > 2) {
          innerNumber = innerNumber.replace(/,/g, '')
        } else {
          innerNumber = innerNumber.replace(/,/g, '.')
        }
      } else {
        innerNumber = innerNumber.replace(/\./g, '').replace(/,/g, '.')
      }
    }
    innerFormattedNumbers.push(innerNumber)
  }

  // Check if decimal place is found in more than one number
  const decimalPlaces = innerFormattedNumbers.filter(n => n.includes('.'))
  if (decimalPlaces.length > 1) {
    // Take the first number
    number = innerFormattedNumbers[0]
  } else {
    number = innerFormattedNumbers.join('')
  }
  return number
}

function formatInteger(number) {
  // Get value inside parethesis if exists
  let numberFormatted = getNumberInsideParenthesis(number)

  // Leave only numbers
  numberFormatted = numberFormatted.replace(/[^0-9-]+/g, '')
  return numberFormatted
}

function getNumberInsideParenthesis(number) {
  const regExp = /\(([^)]+)\)/
  const matches = regExp.exec(number)

  let result = number
  if (matches) {
    result = `-${matches[1]}`
  }

  return result
}

function evaluateStrictFormats(strictFormats, forgivingFormats, date) {
  const strictFormatsPriority = [...strictFormats, ...forgivingFormats]

  let formattedDate
  let substringDate = date
  do {
    moment.locale('en')
    // Strict mode en
    // eslint-disable-next-line
    for (const format of strictFormatsPriority) {
      if (moment(substringDate, format, true).isValid()) {
        formattedDate = moment(substringDate, format, true).format()
        formattedDate = formattedDate.substring(0, 10)
        return formattedDate
      }
    }

    moment.locale('es')
    // Strict mode es
    // eslint-disable-next-line
    for (const format of strictFormatsPriority) {
      if (moment(substringDate, format, true).isValid()) {
        formattedDate = moment(substringDate, format, true).format()
        formattedDate = formattedDate.substring(0, 10)
        return formattedDate
      }
    }

    moment.locale('pt')
    // Strict mode pt
    // eslint-disable-next-line
    for (const format of strictFormatsPriority) {
      if (moment(substringDate, format, true).isValid()) {
        formattedDate = moment(substringDate, format, true).format()

        formattedDate = formattedDate.substring(0, 10)
        return formattedDate
      }
    }

    substringDate = substringDate.slice(0, -1)
  } while (formattedDate === null && substringDate.length >= 8)

  return formattedDate
}

function formatDate(date, process) {
  // READ FOR DETAILS: https://momentjs.com/guides/#/parsing/strict-mode/
  date = date.trim().replace(/,/g, '')

  // TODO: Formats custom if required
  const strictFormats = {
    yearMonth: [
      [
        'YYYY-MM-DDTHH:mm:ss[.000Z]',
        'YYYY-MM-DD',
        'YYYY-M-D',
        'YYYY- M-D',
        'YYYY/MM/DD',
        'YYYY/M/D',
        'YYYYMMDD',
        'YYYY MMM DD',
        'YYYY-M-D',
        'YYYY- M-D',
        'YYYYMMMM DD',
        'YYYYMMMM D'
      ]
    ],
    dayMonth: [
      [
        'DD-MM-YYYY',
        'DD.MM.YYYY',
        'DD.MM.YY',
        'DD-MM-YY',
        'DD-MM-YY [1]',
        'DD/MM/YYYY',
        'DDMMYYYY',
        'D/ M/YYYY',
        'DD-MMM-YYYY',
        'DD-MMM-YY',
        'D-MMM-YY',
        'D-M-YYYY',
        'D- M-YYYY',
        'D-MMM-YYYY',
        'DD/MM/YY',
        'D/MM/YY',
        'D/MM/YYYY',
        'D/M/YY',
        'D/M/YYYY',
        'D/M/YYYY HH:mm:ss',
        'DD/MM/YYYY HH:mm:ss',
        'DD/MM/YY HH:mm',
        'DD-MMM-YYYY HH:mm A',
        'DD-MMM-YYYY HH:mm:ss',
        'DD-MM-YYYY, HH:mm:ss',
        'DD-MM-YYYY HH:mm:ss',
        'dddd D MMM YYYY HH:mm [GMT]Z',
        'dddd D MMM YYYY',
        'DD MMMM[-] YYYY',
        'DD [de] MMMM [del] YYYY', // Spanish format
        '[Guatemala] D [de] MMMM [del] YYYY',
        'DD/MM-YY',
        'DD MMMM YYYY'
      ]
    ],
    monthDay: [
      [
        'M/DD/YY',
        'M-D-YY',
        'MM-DD-YYYY',
        'MM-DD-YY',
        'MM/DD/YYYY',
        'MMDDYYYY',
        'M/D/YYYY',
        'M/ D/YYYY',
        'MM/DD/YY',
        'M/D/YY',
        'MMMM DD/YY',
        'M/D/YYYY HH:mm:ss',
        'MM/DD/YYYY HH:mm:ss',
        'MMM DD YY',
        'MMMM [1] DD YYYY',
        'MM/DD/YY [ORIGINAL]',
        'MMM DD/YY',
        'MMMM DDDo YYYY',
        'MMM DD YYYY [REPRINT]',
        'MMM DD YYYY',
        'ddd MMM DD YYYY',
        '[Invoice Date :] ddd MMM D YYYY [IST]',
        'MMMM DD YYYY',
        'dddd MMMM D YYYY',
        'MMMM YYYY',
        'MMMM DDYYYY'
      ]
    ]
  }

  const forgivingFormats = [
    [
      '[0000000/] MM/DD/YYYY',
      'YYYYMMMMDD',
      'DDMMMMY',
      'DDMMMY',
      'MMMMDDY',
      'MMMDDYYYY',
      'YYYYMMMDD'
    ]
  ]

  let formattedDate

  switch (process) {
    case 'mexicanValidation':
    case 'gtValidation':
    case 'svValidation':
    case 'brValidation':
    case 'latamValidation':
      // evaluate year/month formats
      formattedDate = evaluateStrictFormats(strictFormats.yearMonth, forgivingFormats, date)
      if (formattedDate) {
        return formattedDate
      }

      // evaluate day/month formats
      formattedDate = evaluateStrictFormats([...strictFormats.dayMonth], forgivingFormats, date)
      if (formattedDate) {
        return formattedDate
      }

      // evaluate month/day formats
      formattedDate = evaluateStrictFormats(strictFormats.monthDay, forgivingFormats, date)
      if (formattedDate) {
        return formattedDate
      }
      break
    case 'apacValidation':
    case 'emeaValidation':
    case 'northAmericaValidation':
      // evaluate year/month formats
      formattedDate = evaluateStrictFormats(strictFormats.yearMonth, forgivingFormats, date)
      if (formattedDate) {
        return formattedDate
      }

      // evaluate month/day formats
      formattedDate = evaluateStrictFormats(strictFormats.monthDay, forgivingFormats, date)
      if (formattedDate) {
        return formattedDate
      }

      // evaluate day/month formats
      formattedDate = evaluateStrictFormats(strictFormats.dayMonth, forgivingFormats, date)
      if (formattedDate) {
        return formattedDate
      }
      break
    case 'ocr-global':
    case 'ocr':
    default:
      // evaluate year/month formats
      formattedDate = evaluateStrictFormats(strictFormats.yearMonth, forgivingFormats, date)
      if (formattedDate) {
        return formattedDate
      }

      // evaluate month/day formats
      formattedDate = evaluateStrictFormats(strictFormats.monthDay, forgivingFormats, date)
      if (formattedDate) {
        return formattedDate
      }

      // evaluate day/month formats
      formattedDate = evaluateStrictFormats(strictFormats.dayMonth, forgivingFormats, date)
      if (formattedDate) {
        return formattedDate
      }
      break
  }

  moment.locale('en')
  const forgivingFormatsPriority = [
    ...forgivingFormats,
    ...strictFormats.dayMonth,
    ...strictFormats.monthDay
  ]

  // Forgiving mode
  if (date.length >= 4) {
    // eslint-disable-next-line
    for (const format of forgivingFormatsPriority) {
      if (moment(date, format).isValid()) {
        formattedDate = moment(date, format).format()

        formattedDate = formattedDate.substring(0, 10)
        return formattedDate
      }
    }
  }

  return formattedDate ?? ''
}

function formatField(valueRecognition, rule, process) {
  let value = valueRecognition

  if (typeof value === 'string') {
    value = value.trim()
  } else {
    return value
  }

  if (Object.keys(rule).length === 0 || !value) {
    return value
  }

  switch (rule.typeField) {
    case 'number':
    case 'currency':
      value = formatNumber(value, process)
      break
    case 'positiveNumber':
      {
        const number = formatNumber(value, process)
        value = number ? Math.abs(number) : number
      }
      break
    case 'integer':
      value = formatInteger(value)
      break
    case 'date':
      value = formatDate(value, process)
      break
    case 'gerdau-us.invoiceNumber':
      value = formatGerdauInvoiceNumber(value)
      break
    default:
      break
  }

  return `${value}`
}

export function cleanStringValue(value) {
  if (typeof value === 'string') {
    value = (value ?? '')
      .toLowerCase()
      .replace(/\s/g, '')
      .replace(/:/g, '')
      .replace(/=/g, '')
      .replace(/\./g, '')
      .replace(/,/g, '')
  }

  return value
}

export function valueComparison(value1, value2, rule) {
  switch (rule.typeField) {
    case 'number':
    case 'currency':
    case 'integer':
      return Number(value1) === Number(value2)
    case 'positiveNumber': {
      const v1 = formatInteger(value1)
      const v2 = formatInteger(value2)
      return Math.abs(v1) === Math.abs(v2)
    }
    case 'date':
    default:
      const valueField2 = cleanStringValue(value2)
      const valueField1 = cleanStringValue(value1)
      return valueField1 === valueField2
  }
}

function isError(index, errors) {
  if (!errors['summaryFields']) {
    return false
  }
  if (errors['summaryFields'][index]) {
    return true
  }
  return false
}

export {
  formatField,
  formatNumber,
  generateJsonValidation,
  generateJsonValidationV2,
  isError,
  validateInput,
  validateInputV2,
  validateObject,
  validateProperty
}
