import PropTypes from 'prop-types'
import React, { useState } from 'react'

// Material UI
import { Fade } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import AddAlert from '@material-ui/icons/AddAlert'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'

// Components
import InfoModal from 'components/Invoice/InfoModal'
import Snackbar from 'components/Snackbar/Snackbar.js'
import ModalWrapper from '../../../../components/ModalWrapper/ModalWrapper'

// Assets
import invoiceStyles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceStyle.js'

// Hooks
import { useQueryParams } from 'hooks/POC/CoupaIFrameApp/useQueryParams'

// Services
import { slpWriteTransaction } from 'services/apiDataAssure'

// Utils
import { hideLoading, showLoading } from 'utils/functions'

const useInvoiceStyles = makeStyles(invoiceStyles)
const useOcrStyles = makeStyles(theme => ({
  btnColorPrimary: {
    color: '#081c3e',
    border: '1px solid #081c3e',
    '&:hover': {
      backgroundColor: '#081c3e',
      color: 'white',
      border: '1px solid #081c3e'
    }
  }
}))
export default function PocActionButtonsApp({
  parentStates,
  setParentStates,
  refetchExecutions,
  validateText
}) {
  const invoiceClasses = useInvoiceStyles()
  const ocrClasses = useOcrStyles()

  const query = useQueryParams()
  const supplierId = query.get('object_id')

  const [states, setStates] = useState({
    alert: null,
    snackBarMessage: null,
    snackBarShowSuccess: false,
    snackBarShowError: false
  })

  const hideAlert = () => {
    setStates(old => ({
      ...old,
      alert: null
    }))
  }

  const onRefresh = () => {
    setParentStates(old => ({
      ...old,
      lastExecution: null,
      historyExecutions: null
    }))
    refetchExecutions({ throwOnError: true })
  }

  return (
    <>
      {states.alert}
      <Button
        onClick={() => {
          setStates(old => ({
            ...old,
            alert: (
              <ModalWrapper>
                <InfoModal
                  fullWidth={true}
                  maxWidth="sm"
                  showModal={true}
                  onSubmit={async () => {
                    hideAlert()
                    try {
                      setParentStates(old => ({
                        ...old,
                        historyExecutions: null
                      }))
                      showLoading()
                      const response = await slpWriteTransaction(
                        parentStates.selectedApp?.env,
                        'coupa-iframe',
                        { supplierId, pollerId: 'IFRAME' }
                      )

                      if (response.success) {
                        setStates(old => ({
                          ...old,
                          snackBarShowSuccess: true,
                          snackBarMessage: response.data.message
                        }))
                      } else {
                        throw new Error(response.message)
                      }
                    } catch (error) {
                      setStates(old => ({
                        ...old,
                        snackBarShowError: true,
                        snackBarMessage: error?.toString()
                      }))
                    } finally {
                      hideLoading()
                      setTimeout(() => {
                        onRefresh()
                      }, 1500)
                    }
                  }}
                  onClose={() => hideAlert()}
                  warningMessage="Are you sure you want to validate this supplier with Relish Data Assure?"
                  title={'Relish Data Assure'}
                  textSuccessBtn={'Validate'}
                  transitionComponent={Fade}
                />
              </ModalWrapper>
            )
          }))
        }}
        color="primary"
        type="button"
        disabled={parentStates.isLoadingValidations || !parentStates.selectedApp}
        variant="outlined"
        className={ocrClasses.btnColorPrimary}
        style={{ marginRight: '5px' }}
      >
        {validateText || 'Revalidate'}
      </Button>
      <span style={{ color: 'black' }}>{'  '}</span>
      <Tooltip title="Refresh">
        <Button
          style={{
            maxWidth: '40px',
            maxHeight: '30px',
            minWidth: '40px',
            minHeight: '30px'
          }}
          className={
            invoiceClasses.floatRight +
            ' ' +
            invoiceClasses.clearAvatar +
            ' ' +
            invoiceClasses.colorPrimary
          }
          onClick={onRefresh}
          disabled={parentStates.isLoadingValidations || !parentStates.selectedApp}
        >
          <RefreshRoundedIcon />
        </Button>
      </Tooltip>
      <Snackbar
        place="br"
        color="success"
        icon={AddAlert}
        message={states.snackBarMessage || ''}
        open={states.snackBarShowSuccess}
        closeNotification={() =>
          setStates(old => ({
            ...old,
            snackBarShowSuccess: false
          }))
        }
        close
      />
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={states.snackBarMessage || ''}
        open={states.snackBarShowError}
        closeNotification={() =>
          setStates(old => ({
            ...old,
            snackBarShowError: false
          }))
        }
        close
      />
    </>
  )
}

PocActionButtonsApp.propTypes = {
  parentStates: PropTypes.object.isRequired,
  setParentStates: PropTypes.func.isRequired,
  refetchExecutions: PropTypes.func.isRequired
}
