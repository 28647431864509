import React, { useEffect } from 'react'

import { Badge, Box, IconButton, TextField, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'
import Autocomplete from '@material-ui/lab/Autocomplete'

import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import { checkErrorOrWarning } from 'utils/functions'

import { CircularProgress } from '@material-ui/core'
import { useDebounce } from 'hooks/useDebounce'
import { useGetAllCommodity, useGetCommodity } from 'hooks/useMasterData'
import { ColumnsOrderLineItems } from 'utils/Constants'

const useStyles = makeStyles(styles)

const field = ColumnsOrderLineItems.COMMODITY_ID.type
const label = ColumnsOrderLineItems.COMMODITY_ID.label
const CommoditySelectLine = props => {
  const {
    validationErrorsState,
    handleChange,
    value,
    lineId,
    removeWarningLineItem,
    readOnly,
    appId
  } = props

  const classes = useStyles()

  const [filter, setFilter] = React.useState('')
  const [debouncedValue] = useDebounce(filter, 500)

  const commodities = useGetAllCommodity({
    text: debouncedValue,
    status: 'enabled',
    appId
  })

  const commodity = useGetCommodity(value, { appId })

  useEffect(() => {
    if (commodity.data && !filter) {
      setFilter(commodity.data.name)
    }
  }, [commodity.data, filter])

  const validated = validationErrorsState[`${field}.${lineId}`]?.valid ?? null

  const message = validationErrorsState[`${field}.${lineId}`]?.messages

  const typeMsg = checkErrorOrWarning(message, validated)

  let tooltipArrowClass = null
  let tooltipClass = null
  let badgeClass = null

  if (typeMsg === 'warning') {
    tooltipArrowClass = classes.warningColor
    tooltipClass = classes.tooltipWarning
    badgeClass = classes.badgeWarning
  } else if (typeMsg === 'error') {
    tooltipArrowClass = classes.errorColor
    tooltipClass = classes.tooltipError
    badgeClass = classes.badgeError
  }

  function validationMessages(validationsError) {
    if (!validationsError) {
      return ''
    }

    if (Array.isArray(validationsError)) {
      return (
        <>
          {validationsError.map((message, index) => (
            <div key={index}>{message.message}</div>
          ))}
        </>
      )
    }

    const messages = validationsError.split('\n')

    return (
      <>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </>
    )
  }

  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Tooltip
        classes={{
          arrow: tooltipArrowClass,
          tooltip: tooltipClass
        }}
        title={typeMsg !== '' ? validationMessages(message) : ''}
        arrow
        placement="right"
      >
        <Badge
          classes={{
            badge: badgeClass
          }}
          badgeContent={
            typeMsg === 'warning' ? (
              <WarningIcon className={classes.warningColor}></WarningIcon>
            ) : null
          }
          invisible={typeMsg !== '' ? true : false}
        >
          <Autocomplete
            freeSolo
            onChange={(event, newValue) => {
              handleChange(newValue)
            }}
            options={commodities.data ?? []}
            id="asynchronous-commodities"
            style={{ width: 300 }}
            getOptionSelected={(option, value) => option.name === value.name}
            disabled={readOnly}
            inputValue={filter}
            onInputChange={(event, newInputValue) => {
              setFilter(`${newInputValue}`)
            }}
            className={readOnly ? classes.readOnly : ''}
            getOptionLabel={option => option.name}
            renderOption={option => (
              <>
                ({option?.code}) {option?.name}
              </>
            )}
            filterOptions={x => x}
            loading={commodities.isLoading || commodities.isFetching}
            renderInput={params => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                fullWidth
                multiline
                error={typeMsg === 'error' ? true : false}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {commodities.isLoading || commodities.isFetching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                  classes: {
                    notchedOutline: typeMsg === 'warning' ? classes.inputWarning : ''
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: typeMsg === 'warning' ? classes.warningColor : '',
                    focused: typeMsg === 'warning' ? classes.warningColor : ''
                  }
                }}
              />
            )}
          />
        </Badge>
      </Tooltip>
      {typeMsg === 'warning' ? (
        <IconButton
          style={{ padding: '0', marginLeft: '10px' }}
          className={classes.buttonSuccess}
          onClick={() => removeWarningLineItem(field, lineId)}
        >
          <CheckCircleOutlineIcon className={classes.successColor} />
        </IconButton>
      ) : null}
    </Box>
  )
}

export default CommoditySelectLine
