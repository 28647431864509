import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, Hidden, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import validator from 'validator'
import CustomTreeTable from 'components/CustomTreeTable/CustomTreeTable'
import RfcComponent from 'components/RfcComponent/RfcComponent.js'
import TeamsSync from 'views/Connect/TeamsSync.js'
import CustomKISelector from 'views/DataAssure/CustomKISelector/CustomKISelector.js'
import FormLabel from '@material-ui/core/FormLabel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
// @material-ui/icons
import CheckBox from '@material-ui/icons/CheckBox'
import Cancel from '@material-ui/icons/Cancel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HelpIcon from '@material-ui/icons/Help'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'

// @material-ui/icons
import Info from '@material-ui/icons/Info'
// connection
import { validateAppConnection, validateCustomAppConnection } from '../../services/apiApps.js'
import { validateTransformDefinition } from '../../services/apiInvoices.js'

// style
import style from '../../assets/jss/material-dashboard-pro-react/views/Apps/widgetFormStyle.js'
//import Select from '@material-ui/core/Select'
import Select from 'react-select'
import InputLabel from '@material-ui/core/InputLabel'
import Switch from '@material-ui/core/Switch'
import ParametersAccordion from './ParametersAccordion'
import { Link } from 'react-router-dom'
import { cloneObject } from 'utils/functions.js'
import { getUserTenant } from 'utils/functions'
import jwtDecode from 'jwt-decode'

const REMOVE_KEYS = ['errorMessage', 'behaviorOnError', 'error', 'showTestConnection']

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

class connectivity extends React.Component {
  constructor(props) {
    super(props)
    this.state = props.initialState
    this.state.expanded = []
    let mapping = this.state.conectivityMapping
    mapping.sortedFields = []
    this.state.expanded = []
    let fieldKeys = Object.keys(mapping.fields)
    if (mapping.fields && fieldKeys.length > 0) {
      fieldKeys.forEach(key => {
        if (REMOVE_KEYS.includes(key)) {
          return
        }
        if (!mapping.fields[key]) {
          console.log(key)
          return
        }
        if (mapping.fields[key].type === 'array') {
          mapping.fields[key].arrayId = this.state.expanded.length
          this.state.expanded.push({ expanded: true })
          let arrayOptions = mapping.fields[key].newObject
          let arrayValues = mapping.fields[key].value
          while (arrayOptions) {
            let auxArrayOptions = undefined
            let newObjectKeys = Object.keys(arrayOptions)
            for (let index = 0; index < newObjectKeys.length; index++) {
              if (arrayOptions[newObjectKeys[index]].type === 'array') {
                arrayOptions[newObjectKeys[index]].arrayId = this.state.expanded.length

                for (let id = 0; id < arrayValues.length; id++) {
                  arrayValues[id][newObjectKeys[index]].arrayId = this.state.expanded.length
                  this.state.expanded.push({ expanded: true })
                }

                auxArrayOptions = arrayOptions[newObjectKeys[index]].newObject
                // arrayValues = arrayValues.value[0][newObjectKeys[index]]
              }
            }
            arrayOptions = auxArrayOptions
          }
        }

        if (mapping.fields[key].parentObject) {
          mapping.fields[key].expandedAccordion = true
          mapping.fields[key].version = 1
        }
      })
      mapping.sortedFields = Object.keys(mapping.fields)
        .filter(key => !REMOVE_KEYS.includes(key))
        .sort(function(a, b) {
          let orderA = parseInt(mapping.fields[a]?.order ?? 50000)
          let orderB = parseInt(mapping.fields[b]?.order ?? 50000)
          return orderA - orderB
        })
    }
    this.state.dataVersion = 1

    this.state.conectivityMapping = mapping
    if (this.state.conectivityMapping.tableId) this.state.data = this.getTableData(this.state.conectivityMapping.tableId)
    else this.state.data = []

    //Custom KI Fields Selector
    this.customKIFieldSelector = {}
    // fill fields ID that requires KI custom component
    this.initFieldsWithCustomKI(mapping, mapping.sortedFields)
  }
  initFieldsWithCustomKI = (mapping, sortedFields) => {
    if (mapping && mapping.fields && sortedFields) {
      sortedFields.forEach(field => {
        let fieldDefinition = mapping.fields[field]
        if (fieldDefinition && fieldDefinition.requiresCustomKISelector) {
          this.customKIFieldSelector[field] = fieldDefinition.value
        }
      })
    }
  }

  showComponent = field => {
    const { fields } = this.state.conectivityMapping

    if (field?.showDependantField) {
      if (!fields[field.showDependantField] || fields[field.showDependantField].value !== field.showDependantValue) {
        return false
      }
      if (field?.showDependantField2) {
        if (!fields[field.showDependantField2] || fields[field.showDependantField2].value !== field.showDependantValue2) {
          return false
        }
      }
    }

    return true
  }

  updateRFC = fields => {
    let newState = cloneObject(this.state)
    newState.conectivityMapping.fields.rfc.value = fields
    this.setState(newState)
  }
  returnCustomKISelector = field => {
    if (field.requiresCustomKISelector) {
      const { classes } = this.props
      return (
        <CustomKISelector
          initialStateValue={this.state.conectivityMapping.fields.validateFormAddressKI}
          validation={'address'}
          validationMappings={this.state.conectivityMapping.addressMapings}
          classes={classes}
        />
      )
    } else return ''
  }
  updateTeamsFlag = () => {
    let newState = cloneObject(this.state)
    let microsoftGraph = this.state.conectivityMapping.fields.microsoftGraphApi
    let body = JSON.parse(localStorage.getItem('MSResponseBody'))
    localStorage.removeItem('MSResponseBody')
    microsoftGraph.configured.value = true
    microsoftGraph.code.value = body.code
    microsoftGraph.idToken.value = body.idToken
    microsoftGraph.redirectUrl.value = body.redirectUrl
    microsoftGraph.email = body.idToken ? jwtDecode(body.idToken).email : null
    newState.conectivityMapping.fields.microsoftGraphApi = microsoftGraph
    this.setState(newState)
  }

  refreshTable = () => {}
  refreshTableWithData = () => {
    this.refreshTable(this.getTableData(this.state.conectivityMapping.tableId))
  }
  getParentObjectValue() {
    let value = undefined
    if (this.state.conectivityMapping.fields) {
      let keys = Object.keys(this.state.conectivityMapping.fields)
      keys
        .filter(key => !REMOVE_KEYS.includes(key))
        .forEach(element => {
          let childElement = this.state.conectivityMapping.fields[element]
          if (childElement.isParentObjectValue) {
            value = childElement.value
            return
          } else if (childElement.isNegativeParentObjectValue) {
            value = !childElement.value
            return
          }
        })
    }
    return value
  }
  componentDidMount() {
    /*  this.refreshTableWithData = this.refreshTableWithData.bind(this)
    if (this.props.initialState.conectivityMapping.refreshTable)
      this.props.initialState.conectivityMapping.refreshTable(
        this.refreshTableWithData
      )*/
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.dataVersion !== prevState.dataVersion && this.state.conectivityMapping.tableId) ||
      (this.props.initialState.conectivityMapping.fields !== this.state.conectivityMapping.fields && this.state.conectivityMapping.tableId)
    ) {
      let newState = this.state
      newState.conectivityMapping.fields = this.props.initialState.conectivityMapping.fields
      newState.data = this.getTableData(this.state.conectivityMapping.tableId)
      newState.data.dataVersion = this.state.dataVersion
      if (this.state.dataVersion === prevState.dataVersion) newState.dataVersion = newState.dataVersion + 1
      this.setState(newState)
      // this.refreshTable(newState.data)
    } else if (this.props.initialState.conectivityMapping.fields !== this.state.conectivityMapping.fields) {
      let newState = this.state
      newState.conectivityMapping.fields = this.props.initialState.conectivityMapping.fields
      this.setState(newState)
    }
    /*   this.refreshTableWithData = this.refreshTableWithData.bind(this)
    if (this.props.initialState.conectivityMapping.refreshTable)
      this.props.initialState.conectivityMapping.refreshTable(
        this.refreshTableWithData
      )*/
  }
  sendState() {
    return this.state
  }
  onMappingFieldChange2(field, valueId, value, fieldId, validate = true) {
    if (field && valueId) {
      field[valueId] = value
      let stateCopy = this.state
      this.setState(stateCopy)
      if (validate) {
        this.validateField2(this.state.conectivityMapping.fields, fieldId)
      }
    }
  }
  validateAddRepeatedField(valueId, value, validateObjectToAdd, validation) {
    let addField = true
    let arrayToValidate
    let found
    if (validateObjectToAdd) {
      if (this.state.conectivityMapping.fields[validation]) {
        arrayToValidate = this.state.conectivityMapping.fields[validation].value
        if (Array.isArray(arrayToValidate)) {
          found = arrayToValidate.find(element => element?.exceptions?.title[valueId] === value)
          if (found) addField = false
        }
      }
    }
    return addField
  }
  getSelectValue(fieldValue, options, field) {
    let value = fieldValue
    let returnValue = ''
    if ((value || value === 0) && options && Array.isArray(options)) {
      if (typeof value !== 'object' && !Array.isArray(value)) {
        value = [value]
      }
      if (Array.isArray(value)) {
        returnValue = []
        value.forEach(element => {
          let filteredOption = options.filter(option => {
            if (typeof option === 'object') {
              return option.value === element
            } else return option === element
          })
          if (filteredOption && filteredOption.length > 0) {
            let option = filteredOption[0]
            if (typeof option !== 'object') option = { label: option, value: option }
            returnValue.push(option)
          }
        })
      }
    }
    return returnValue
  }
  flickerError2(field, id, errorMessage, times = 2, parentId = undefined, on = true) {
    let element = document.getElementById(field.label + 'showErrorIconDiv' + parentId)
    if (times > 0) {
      if (element) {
        element.style.display = 'block'
        if (on) {
          element.style.visibility = 'visible'
        } else element.style.visibility = 'hidden'
      }

      times--
      setTimeout(() => {
        this.flickerError2(field, id, errorMessage, times, parentId, !on)
      }, 500)
    } else if (element) {
      element.style.display = 'block'
      element.style.visibility = 'visible'
    }
  }

  showError2(field, errorMessage, focus, flicker = false, parentId, fieldId, times = 4) {
    this.onMappingFieldChange2(field, 'errorMessage', errorMessage, fieldId, false)
    this.onMappingFieldChange2(field, 'error', true, fieldId, false, parentId)
    if (focus) {
      let element = document.getElementById(field.label + 'Formlabel' + parentId)
      if (element) {
        if (element.scrollIntoView) {
          element.scrollIntoView()
          setTimeout(() => {
            let element = document.getElementById(field.label + 'Formlabel' + parentId)
            if (element) {
              if (element.scrollIntoView) {
                element.scrollIntoView()
              }
            }
          }, 850)
        }
      }
    }
    if (!flicker) times = 0
    this.flickerError2(field, fieldId, errorMessage, times, parentId)
  }

  checkConnection = async () => {
    this.setState({ connected: undefined })
    this.setState({ connectionTesting: true })
    this.validateEndingCharacter()
    let connection

    if (this.props.initialState.conectivityMapping.customConnection) {
      let appParams = this.props.initialState.conectivityMapping.fields

      if (this.props.initialState.conectivityMapping.parameterId) {
        appParams.parameterId = this.props.initialState.conectivityMapping.parameterId
      }

      connection = await validateCustomAppConnection(
        this.props.initialState.conectivityMapping.app,
        appParams,
        this.props.initialState.conectivityMapping.env,
        null,
        this.props.initialState.conectivityMapping.invoiceUrl,
        this.props.initialState.conectivityMapping.pricebookUrl
      )

      /*
      parameterId: configurationDetails,
                        customConnection: customConnection
      */
    } else {
      let appParams = {
        parametersPosition: this.props.initialState.conectivityMapping.parametersPosition,
        apiKey: this.state.conectivityMapping.fields.apiKey ? this.state.conectivityMapping.fields.apiKey.value : '',
        apiSecret: this.state.conectivityMapping.fields.apiSecret ? this.state.conectivityMapping.fields.apiSecret.value : '',
        clientSecret: this.state.conectivityMapping.fields.clientSecret ? this.state.conectivityMapping.fields.clientSecret.value : '',
        clientId: this.state.conectivityMapping.fields.clientId ? this.state.conectivityMapping.fields.clientId.value : '',
        service: this.state.conectivityMapping.service,
        region: this.props.initialState.conectivityMapping.region ? this.props.initialState.conectivityMapping.region.value : 'US',
        siteUrl: this.props.initialState.conectivityMapping.siteUrl ? this.props.initialState.conectivityMapping.siteUrl.value : '',
        realm: this.state.conectivityMapping.fields.realm ? this.state.conectivityMapping.fields.realm.value : '',
        includein:
          this.state.conectivityMapping.fields.apiKey && this.state.conectivityMapping.fields.apiKey.includeIn
            ? this.state.conectivityMapping.fields.apiKey.includeIn
            : ''
      }
      // por aca tengo que hacer lois camibiosx
      connection = await validateAppConnection(
        this.props.initialState.conectivityMapping.app,
        appParams,
        this.props.initialState.conectivityMapping.env,
        null,
        true,
        this.props.initialState.conectivityMapping.invoiceUrl
      )
    }
    let defaultError = {
      error: 'invalid_request',
      error_description: 'Connection Error'
    }
    defaultError = JSON.stringify(defaultError, null, '\t')

    if (connection.success) {
      this.setState({ connected: connection.data.valid })
      if (connection.data.valid) {
        this.setState({ connectedMessage: undefined })
      } else {
        this.setState({
          connectedMessage: JSON.stringify(connection.data.data) || defaultError
        })
      }
    } else {
      this.setState({ connected: false })
      this.setState({ connectedMessage: defaultError })
    }
    this.setState({ connectionTesting: false })
  }

  isValidJSON(str) {
    try {
      JSON.parse(str)
      return true
    } catch (error) {
      return false
    }
  }

  checkTransformDefinition = async () => {
    this.onMappingFieldChange2(this.state.conectivityMapping.fields.transformValidation, 'value', undefined, 'transformValidation')
    this.setState({ connectionTesting: true })
    let connection

    const transformInput = this.state.conectivityMapping.fields.transformInput.value.replace(/\s/g, '')
    if (!this.isValidJSON(transformInput)) {
      this.onMappingFieldChange2(this.state.conectivityMapping.fields.transformValidation, 'value', false, 'transformValidation')
      this.setState({ connectedMessage: "JSON isn't valid." })
      this.setState({ connectionTesting: false })
      return
    }

    connection = await validateTransformDefinition({
      input: JSON.parse(transformInput)
    })

    let defaultError = {
      error: 'invalid_request',
      error_description: 'Not Valid'
    }
    defaultError = JSON.stringify(defaultError, null, '\t')

    if (connection.success) {
      this.onMappingFieldChange2(
        this.state.conectivityMapping.fields.transformValidation,
        'value',
        connection.data.valid,
        'transformValidation'
      )
      if (connection.data.valid) {
        this.setState({ connectedMessage: undefined })
      } else {
        this.setState({
          connectedMessage: JSON.stringify(connection.data.errors) || defaultError
        })
      }
    } else {
      this.setState({ connected: false })
      this.setState({ connectedMessage: defaultError })
    }
    this.setState({ connectionTesting: false })
  }

  showErrorOnArray(field, message, focus, flicker, parentId, index, key) {
    this.showError2(field, message, focus, flicker, parentId + (index + 1))
    this.onMappingFieldChange2(field, 'errorKey', key, undefined, false)
  }
  resetArrayErrors(field) {
    this.onMappingFieldChange2(field, 'error', false, undefined, false)
    this.onMappingFieldChange2(field, 'errorMessage', '', undefined, false)
    this.onMappingFieldChange2(field, 'errorKey', undefined, undefined, false)
  }
  validateField2(parentObject, fieldId, currentValidatedValue = true, focus = false, flicker = false, parentId = undefined) {
    if (!parentId) parentId = this.props.initialState.parentId
    if (fieldId !== this.state.conectivityMapping.tableId) {
      let field = parentObject[fieldId]
      if (typeof field === 'object') {
        if (field.parentObject) {
          let returnValue = currentValidatedValue
          if (this.showComponent(field)) {
            let keys = Object.keys(field)
            keys.forEach(key => {
              currentValidatedValue = this.validateField2(parentObject[fieldId], key, currentValidatedValue, focus, flicker, fieldId)
              if (!currentValidatedValue) {
                field.expandedAccordion = true
                if (field.version !== undefined) field.version = field.version + 1
                else field.version = 1

                returnValue = false
              }
            })
          }
          return returnValue
        }
        if (field.isArrayChild) {
          let returnValue = true
          if (this.state.conectivityMapping.fields) {
            Object.keys(this.state.conectivityMapping.fields).forEach(id => {
              if (this.state.conectivityMapping.fields[id].type === 'array')
                returnValue = this.validateField2(this.state.conectivityMapping.fields, id, returnValue, true, true)
            })
          }
          return returnValue
        }
        if (field.type === 'array') {
          let returnValue = currentValidatedValue
          if (field.validations) {
            let keys = Object.keys(field.validations)
            for (let index = 0; index < keys.length; index++) {
              const key = keys[index]

              for (let i = 0; i < field.value.length; i++) {
                if (field.validations[key].isHTTPSURL) {
                  if (
                    !validator.isURL(field.value[i][key], {
                      protocols: ['https'],
                      require_protocol: true
                    })
                  ) {
                    this.showErrorOnArray(
                      field.value[i],
                      'Must be an https URL ',
                      currentValidatedValue && focus,
                      flicker,
                      parentId,
                      i,
                      key
                    )
                    returnValue = false
                  } else {
                    this.resetArrayErrors(field.value[i])
                  }
                } else if (field.validations[key].isURL) {
                  if (!validator.isURL(field.value[i][key])) {
                    this.showErrorOnArray(field.value[i], 'Must be an URL ', currentValidatedValue && focus, flicker, parentId, i, key)
                    returnValue = false
                  } else {
                    this.resetArrayErrors(field.value[i])
                  }
                } else if (field.validations[key].starts) {
                  if (!field.value[i][key].startsWith(field.validations[key].starts)) {
                    this.showErrorOnArray(
                      field.value[i],
                      'Must Start With ' + field.validations[key].starts,
                      currentValidatedValue && focus,
                      flicker,
                      parentId,
                      i,
                      key
                    )

                    returnValue = false
                  } else {
                    this.resetArrayErrors(field.value[i])
                  }
                } else if (field.validations[key].required) {
                  if (!field.value[i][key])
                    this.showErrorOnArray(field.value[i], 'Required', currentValidatedValue && focus, flicker, parentId, i, key)
                  else {
                    this.resetArrayErrors(field.value[i])
                  }
                }
              }
            }
          }
          return returnValue
        }
        let value = field.value
        if (field.type !== 'password' && value) {
          if (value.trim) value = value.trim()
        }
        if (this.showComponent(field)) {
          if (field.required && !value && typeof value === 'string') {
            this.showError2(field, 'Field is Required', currentValidatedValue && focus, flicker, parentId)
            return false
          } else if (field.type === 'int') {
            if (!isNaN(Number(field.value)) && Number.isInteger(Number(field.value))) field.value = Number(field.value)
            else {
              this.showError2(field, 'Field is not an Integer', currentValidatedValue && focus, flicker, parentId)
              return false
            }
          } else if (field.type === 'numeric') {
            if (!isNaN(Number(field.value))) field.value = Number(field.value)
            else {
              this.showError2(field, 'Field is not a number', currentValidatedValue && focus, flicker, parentId)
              return false
            }
          }
        }
        let element = document.getElementById(field.label + 'showErrorIconDiv' + parentId)
        if (element) {
          element.style.display = 'none'
        }
        this.onMappingFieldChange2(field, 'error', false, fieldId, false)
        this.onMappingFieldChange2(field, 'errorMessage', '', fieldId, false)
      }
    }
    return currentValidatedValue
  }

  validateEndingCharacter() {
    Object.keys(this.state.conectivityMapping.fields).forEach(id => {
      //endingCharacter
      if (this.state.conectivityMapping.fields[id]?.endingCharacter) {
        if (this.state.conectivityMapping.fields[id].value && this.state.conectivityMapping.fields[id].value.length > 0) {
          if (
            this.state.conectivityMapping.fields[id].value[this.state.conectivityMapping.fields[id].value.length - 1] !==
            this.state.conectivityMapping.fields[id].endingCharacter[0]
          ) {
            this.onMappingFieldChange(
              'value',
              this.state.conectivityMapping.fields[id].value + this.state.conectivityMapping.fields[id].endingCharacter[0],
              id,
              false
            )
          }
        }
      }
    })
  }
  isValidated() {
    let validated = true
    this.validateEndingCharacter()
    //let validation = validateObject(connectivitySchema, this.state)
    if (this.innerReferences !== undefined) {
      for (let index = 0; index <= this.innerReferences; index++) {
        if (this[index] && this[index].isValidated) {
          let indexIsValidated = this[index].isValidated()

          if (validated && !indexIsValidated) validated = false
        }
      }
    }
    if (this.state.conectivityMapping.fields) {
      Object.keys(this.state.conectivityMapping.fields).forEach(id => {
        const currentValidated = validated
        validated = this.validateField2(this.state.conectivityMapping.fields, id, currentValidated, true, true)
      })
    }

    return validated
  }

  showModal() {
    const { classes } = this.props
    return (
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.showModal === true}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.setState({ showModal: false })}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>Error connection details</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          {this.state.connectionErrorMessage}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.setState({ showModal: false })} color="danger" simple>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  customDBListFields(
    fields,
    parrentKey,
    classes,
    arrayKey,
    isTitleArray = false,
    id,
    valueId = 'value',
    fullWidth = false,
    mutualExclusiveField = undefined,
    mutualExclusiveFieldId = undefined,
    arraykey = undefined
  ) {
    let newObject = cloneObject(fields.newObject)
    let newObjectOrder = {}
    if (fields.newObjectOrder) newObjectOrder = cloneObject(fields.newObjectOrder)
    else newObjectOrder = cloneObject(fields.newObject)
    let keys = Object.keys(newObjectOrder).sort(function(a, b) {
      let orderA = parseInt(newObjectOrder[a])
      if (!parseInt(newObjectOrder[a])) {
        orderA = 50000
      }

      let orderB = parseInt(newObjectOrder[b])
      if (!parseInt(newObjectOrder[b])) {
        orderB = 50000
      }

      return orderA - orderB
    })
    if (keys && keys.includes('titleArrayObject')) keys = [newObject.titleArrayObject]
    let parentObjectValue = this.getParentObjectValue()
    let lock = !((parentObjectValue && parentObjectValue === true) || parentObjectValue !== false)
    let addButtonDisable = false
    if (fields.maxValues && fields.value) {
      if (fields.value.length >= fields.maxValues) addButtonDisable = true
    }
    return (
      <Accordion
        expandicon={<ExpandMoreIcon />}
        className={classes.accordion}
        disabled={lock}
        expanded={lock ? false : this.state && this.state.expanded ? this.state.expanded[fields.arrayId].expanded : false}
        onChange={() => {
          if (!isTitleArray) {
            if (this.state && this.state.expanded && this.state.expanded[fields.arrayId] !== undefined) {
              let newState = this.state
              newState.expanded[fields.arrayId].expanded = !newState.expanded[fields.arrayId].expanded
              this.setState(newState)
            }
          }
        }}
        style={
          !lock
            ? { width: '100%' }
            : {
                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                width: '100%'
              }
        }
      >
        <AccordionSummary>
          <GridContainer>
            <GridItem xs={10}>
              {isTitleArray && !(fields && fields.title && fields.title.options) ? (
                <CustomInput
                  inputInternalProps={{
                    style: {
                      width: '100%',
                      textAlign: 'center'
                    }
                  }}
                  labelText={fields.title.label}
                  inputProps={{
                    value: fields.title.value,
                    onChange: event => {
                      this.onMappingFieldChange2(fields.title, 'value', event.target.value, 'id')
                    }
                  }}
                />
              ) : fields && fields.title && fields.title.options ? (
                <Select
                  styles={{
                    valueContainer: (provided, state) => {
                      return {
                        ...provided,
                        maxHeight: '150px',
                        width: '50px',
                        overflowY: 'auto'
                      }
                    }
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={this.getSelectValue(fields.title[valueId], fields.title.options, fields.title)}
                  onChange={e => {
                    let newValue
                    let toAdd = true
                    if (Array.isArray(e)) {
                      newValue = e.map(val => val.value)
                    } else {
                      newValue = e.value
                    }
                    toAdd = this.validateAddRepeatedField(valueId, newValue, fields.validateRepeatedObject, 'listMatchValidation')
                    if ((newValue || newValue === 0) && toAdd === true) {
                      this.onMappingFieldChange2(fields.title, valueId, newValue)
                    }
                  }}
                  isMulti={fields.multiple}
                  options={fields.title.options.map((element, count) => {
                    let label
                    let value
                    if (typeof element === 'object') {
                      if (element.label !== undefined) label = element.label
                      if (element.value !== undefined) value = element.value
                    } else {
                      value = element
                      label = element
                    }
                    if (value !== undefined && label !== undefined) return { label: label, value: value }
                    else return ''
                  })}
                  placeholder={fields.title.label || 'Select...'}
                ></Select>
              ) : (
                <Typography>{fields.label}</Typography>
              )}
            </GridItem>
            <GridItem xs={2}>
              <IconButton
                style={{
                  float: 'right',
                  transform:
                    this.state &&
                    this.state.expanded &&
                    this.state.expanded[fields.arrayId] !== undefined &&
                    this.state.expanded[fields.arrayId].expanded
                      ? 'rotate(180deg)'
                      : ''
                }}
                onClick={() => {
                  if (isTitleArray) {
                    if (this.state && this.state.expanded && this.state.expanded[fields.arrayId] !== undefined) {
                      let newState = this.state
                      newState.expanded[fields.arrayId].expanded = !newState.expanded[fields.arrayId].expanded
                      this.setState(newState)
                    }
                  }
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </GridItem>
          </GridContainer>
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer>
            <GridContainer style={{ margin: 'auto' }}>
              <GridItem xs={12} style={{ margin: 'auto 0' }}>
                <div style={{ width: '100%' }}>
                  <IconButton
                    style={{ float: 'right' }}
                    disabled={addButtonDisable}
                    onClick={() => {
                      let state = this.state
                      if (fields.newObject.titleArrayObject) {
                        newObject[fields.newObject.titleArrayObject].arrayId = state.expanded.length
                        state.expanded.push({ expanded: true })
                      }
                      fields.value.push(newObject)

                      this.setState(state)
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </GridItem>
            </GridContainer>

            <GridItem xs={12}>
              {fields.value.map((field, id) => {
                return (
                  <GridContainer style={{ margin: 'auto' }} key={id}>
                    <GridItem xs={11}>
                      <GridContainer>
                        {keys.map((key, index) => {
                          field.label = key.charAt(0).toUpperCase() + key.slice(1)
                          field.isArrayChild = true
                          return (
                            <GridItem
                              xs={Math.ceil(parseInt(12 / keys.length))}
                              style={{
                                textAlign: 'center'
                              }}
                              key={key + index + id}
                            >
                              {field.titleArrayObject ? (
                                <div
                                  style={{
                                    width: '100%',
                                    margin: '10px'
                                  }}
                                >
                                  {this.customDBListFields(field[field.titleArrayObject], parrentKey, classes, arrayKey, true)}
                                </div>
                              ) : key && field[key].options ? (
                                <div
                                  style={{
                                    width: '100%',
                                    margin: '10px'
                                  }}
                                >
                                  {this.customDBFields(
                                    field[key],
                                    index,
                                    classes,
                                    id,
                                    'value',
                                    true,
                                    true,
                                    index,
                                    undefined,
                                    undefined,
                                    arrayKey
                                  )}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: '100%',
                                    margin: '10px'
                                  }}
                                >
                                  {this.customDBFields(field, index, classes, id, key, true, true, index, undefined, undefined, arrayKey)}
                                </div>
                              )}
                            </GridItem>
                          )
                        })}
                      </GridContainer>
                    </GridItem>

                    <GridItem xs={1} style={{ margin: 'auto 0' }}>
                      <div style={{ width: '100%' }}>
                        <IconButton
                          style={{ float: 'right' }}
                          onClick={() => {
                            fields.value.splice(id, 1)
                            let state = this.state
                            this.setState(state)
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </GridItem>
                  </GridContainer>
                )
              })}
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
    )
  }
  customDBFields(
    field,
    key,
    classes,
    id,
    valueId = 'value',
    fullWidth = false,
    hideTopLabel = false,
    aditionalLabelId = '',
    mutualExclusiveField = undefined,
    mutualExclusiveFieldId = undefined,
    arraykey = undefined
  ) {
    if (!this.showComponent(field)) {
      return ''
    }

    let booleanValue
    if (field.type === 'boolean')
      booleanValue =
        field[valueId] && typeof field.value === 'string'
          ? field[valueId].toLowerCase() === 'false'
          : field[valueId]
          ? field[valueId]
          : false

    let paddingTop = '0px'
    if (!field.help) {
      paddingTop = '15px'
    }
    return (
      <GridItem xs={12} sm={12} md={12} key={key + id}>
        <GridContainer>
          <GridItem xs={12} sm={field.type === 'array' || fullWidth ? 12 : 6}>
            <div className={classes.displayTable} style={{ paddingTop: paddingTop }}>
              {field.error ? (
                <div
                  id={
                    field.label
                      ? field.label + 'showErrorIconDiv' + this.props.initialState.parentId + aditionalLabelId
                      : valueId + 'showErrorIconDiv' + this.props.initialState.parentId + aditionalLabelId
                  }
                  style={
                    hideTopLabel && field.errorKey && field.errorKey === valueId
                      ? { paddingBottom: '15px' }
                      : field.errorKey
                      ? { paddingBottom: '15px', visibility: 'hidden' }
                      : {}
                  }
                >
                  {field.errorMessage ? (
                    <Tooltip title={field.errorMessage}>
                      <ErrorOutlineIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title={''}>
                      <ErrorOutlineIcon />
                    </Tooltip>
                  )}
                </div>
              ) : (
                ''
              )}

              {field.type === 'array' ? (
                ''
              ) : (
                <Hidden only={field.type !== 'boolean' ? ['xs'] : []}>
                  <div className={classes.verticalAlign}>
                    <FormLabel
                      id={
                        field.label
                          ? field.label + 'Formlabel' + this.props.initialState.parentId + aditionalLabelId
                          : valueId + 'Formlabel' + this.props.initialState.parentId + aditionalLabelId
                      }
                      className={classes.inputLabel}
                    >
                      {hideTopLabel ? '' : field.label}
                    </FormLabel>
                    {field.help ? (
                      <Tooltip title={field.help}>
                        <IconButton>
                          <HelpIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </div>
                </Hidden>
              )}
            </div>
          </GridItem>
          {field.type === 'array' ? (
            <GridItem xs={12}>{this.customDBListFields(field, key, classes, id)}</GridItem>
          ) : field.type === 'rfc' ? (
            <GridItem xs={12}>
              <RfcComponent updateRFC={this.updateRFC} field={field} />
            </GridItem>
          ) : field.type === 'msAccount' ? (
            <>
              {this.state.conectivityMapping.fields.microsoftGraphApi.email ? (
                <GridContainer xs={12} justify="center" style={{ margin: 'auto' }}>
                  <GridItem xs={6}>
                    <div className={classes.verticalAlign}>
                      <FormLabel
                        id={
                          field.label
                            ? field.label + 'Formlabel' + this.props.initialState.parentId + aditionalLabelId
                            : valueId + 'Formlabel' + this.props.initialState.parentId + aditionalLabelId
                        }
                        className={classes.inputLabel}
                      >
                        MS Email User
                      </FormLabel>
                    </div>
                  </GridItem>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText={
                        <div className={classes.inputLabel}>
                          <span>MS Email User</span>
                        </div>
                      }
                      id={
                        field.label
                          ? field.label + 'ID' + this.props.initialState.parentId
                          : valueId + 'ID' + this.props.initialState.parentId
                      }
                      inputInternalProps={{
                        style: {
                          width: '100%',
                          textAlign: 'center'
                        }
                      }}
                      inputProps={{
                        type: field.type,
                        value: this.state.conectivityMapping.fields.microsoftGraphApi.email
                      }}
                      disabled={true}
                    />
                  </GridItem>
                </GridContainer>
              ) : null}
              <GridItem xs={12}>
                <TeamsSync
                  onLogin={() => this.updateTeamsFlag()}
                  app={'Invoices-AI'}
                  clientId={field.MGAppId.value}
                  hasRefreshToken={this.state.conectivityMapping.fields.microsoftGraphApi.configured.value}
                  responseType={'code+id_token'}
                  responseMode={'fragment'}
                  scope={['openid', 'profile', 'email', 'Mail.Read', 'Mail.ReadWrite', 'Mail.ReadBasic', 'User.Read', 'offline_access']}
                  prompt={'login'}
                  nonce={'invoices-ai-user-consent'}
                  environment={this.state.conectivityMapping.env}
                  client={getUserTenant()}
                ></TeamsSync>
              </GridItem>
            </>
          ) : (
            <GridItem xs={12} sm={fullWidth ? 12 : 6}>
              {field.options && field.options.length > 0 ? (
                <div
                  style={{
                    width: '100%',
                    padding: '13px 0px 13px'
                  }}
                >
                  <FormControl style={{ width: '100%' }} className={classes.formControl}>
                    <GridContainer>
                      <GridItem xs={12} style={{ height: '20px' }}>
                        <InputLabel className={classes.inputLabel}>
                          {
                            <div>
                              <span>
                                {field.label}
                                <small>{field.required && !field.errorMessage ? '*' : ''}</small>
                                <small>{field.errorMessage ? ' (' + field.errorMessage + ')' : ''}</small>
                              </span>
                            </div>
                          }
                        </InputLabel>
                      </GridItem>
                      <GridItem xs={12}>
                        <Select
                          styles={{
                            valueContainer: (provided, state) => {
                              return {
                                ...provided,
                                maxHeight: '150px',
                                overflowY: 'auto'
                              }
                            }
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.getSelectValue(field[valueId], field.options, field)}
                          onChange={e => {
                            let newValue
                            if (Array.isArray(e)) {
                              newValue = e.map(val => val.value)
                            } else {
                              newValue = e.value
                            }
                            if (newValue || newValue === 0) {
                              this.onMappingFieldChange2(field, valueId, newValue)
                            }
                          }}
                          isMulti={field.multiple}
                          options={field.options.map((element, count) => {
                            let label
                            let value
                            if (typeof element === 'object') {
                              if (element.label !== undefined) label = element.label
                              if (element.value !== undefined) value = element.value
                            } else {
                              value = element
                              label = element
                            }
                            if (value !== undefined && label !== undefined) return { label: label, value: value }
                            else return ''
                          })}
                        ></Select>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </div>
              ) : field.type === 'boolean' ? (
                <GridContainer justify="center">
                  <div className={classes.block}>
                    <FormControlLabel
                      style={{ padding: '10px' }}
                      control={
                        <Switch
                          checked={booleanValue}
                          onChange={event => {
                            /*
                        this.onMappingFieldChange(
                          'value',
                          event.target.checked,
                          id,
                          false
                        )*/
                            if (mutualExclusiveField !== undefined) {
                              if (mutualExclusiveField.value)
                                this.onMappingFieldChange2(mutualExclusiveField, valueId, !event.target.checked, mutualExclusiveFieldId)
                            }
                            this.onMappingFieldChange2(field, valueId, event.target.checked, id)
                            if (field.saveToForm && this.props.initialState.saveFormOnClick) {
                              this.props.initialState.saveFormOnClick()
                            }
                            //Custom KI Field Selector
                            if (field.requiresCustomKISelector !== 'undefined') {
                              this.customKIFieldSelector[id] = event.target.checked
                            }
                          }}
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                    />
                  </div>
                  {/* Checks if Field Has a Custom Selector Property */}
                  <div className={classes.block}>
                    <FormControl style={{ width: '100%' }} className={classes.formControl}>
                      {this.customKIFieldSelector[id] ? this.returnCustomKISelector(field) : ''}
                    </FormControl>
                  </div>
                </GridContainer>
              ) : field.type === 'route' ? (
                <GridContainer justify="center">
                  <div>
                    <Link to={field.route}>{field.value}</Link>
                  </div>
                </GridContainer>
              ) : field.type === 'transform' ? (
                <GridContainer>
                  <GridItem xs={12}>
                    <CustomInput
                      success={field.state === 'success'}
                      error={field.state === 'error'}
                      labelText={
                        <div className={classes.inputLabel}>
                          <span>
                            {field.label}
                            <small>{field.required && !field.errorMessage ? '*' : ''}</small>
                            <small>
                              {(field.errorMessage && field.errorKey && field.errorKey === valueId) ||
                              (field.errorMessage && !field.errorKey)
                                ? ' (' + field.errorMessage + ')'
                                : ''}
                            </small>
                          </span>
                        </div>
                      }
                      id={
                        field.label
                          ? field.label + 'ID' + this.props.initialState.parentId
                          : valueId + 'ID' + this.props.initialState.parentId
                      }
                      inputInternalProps={{
                        style: {
                          width: '100%'
                        }
                      }}
                      inputProps={{
                        type: 'text',
                        value: field[valueId],
                        onChange: event => {
                          this.onMappingFieldChange2(field, valueId, event.target.value, arraykey ? arraykey : id)
                          this.onMappingFieldChange2(
                            this.state.conectivityMapping.fields.transformValidation,
                            'value',
                            undefined,
                            'transformValidation'
                          )
                          this.setState({ connectedMessage: undefined })
                        },
                        multiline: field.multiline,
                        minRows: field.minRows,
                        maxRows: field.maxRows
                      }}
                    />
                  </GridItem>
                  {!this.state.conectivityMapping.fields.transformValidation.value ? (
                    <GridItem xs={12} style={{ color: 'red' }}>
                      ** You need to validate the Transform Input before saving **
                    </GridItem>
                  ) : null}
                  <GridItem xs={12}>
                    <Button
                      color={
                        this.state.conectivityMapping.fields.transformValidation.value !== undefined
                          ? this.state.conectivityMapping.fields.transformValidation.value
                            ? 'info'
                            : 'danger'
                          : 'facebook'
                      }
                      onClick={this.checkTransformDefinition.bind(this)}
                      round
                      className={classes.documentation}
                    >
                      {this.state.conectivityMapping.fields.transformValidation.value !== undefined ? (
                        this.state.conectivityMapping.fields.transformValidation.value ? (
                          <div>
                            <CheckBox className={classes.icons} /> Validated
                          </div>
                        ) : (
                          <div>
                            <Cancel className={classes.icons} /> Error
                          </div>
                        )
                      ) : (
                        <div>{this.state.connectionTesting ? '...' : 'Validate'}</div>
                      )}
                    </Button>
                  </GridItem>
                </GridContainer>
              ) : (
                <CustomInput
                  success={field.state === 'success'}
                  error={field.state === 'error'}
                  labelText={
                    <div className={classes.inputLabel}>
                      <span>
                        {field.label}
                        <small>{field.required && !field.errorMessage ? '*' : ''}</small>
                        <small>
                          {(field.errorMessage && field.errorKey && field.errorKey === valueId) || (field.errorMessage && !field.errorKey)
                            ? ' (' + field.errorMessage + ')'
                            : ''}
                        </small>
                      </span>
                    </div>
                  }
                  id={
                    field.label ? field.label + 'ID' + this.props.initialState.parentId : valueId + 'ID' + this.props.initialState.parentId
                  }
                  inputInternalProps={{
                    style: {
                      width: '100%',
                      textAlign: 'center'
                    }
                  }}
                  inputProps={{
                    type: field.type2 === 'password' ? 'password' : field.type,
                    value: field[valueId],
                    onChange: event => {
                      this.onMappingFieldChange2(field, valueId, event.target.value, arraykey ? arraykey : id)
                    },
                    multiline: field.multiline,
                    minRows: field.maxRows
                  }}
                />
              )}
            </GridItem>
          )}
        </GridContainer>
      </GridItem>
    )
  }
  getDataParentElements(parentElement, data, key = undefined, maxDrill = undefined, currentDrill = undefined) {
    let element = parentElement
    if (!currentDrill) currentDrill = 0
    if (key) element = parentElement[key]
    if (typeof element === 'object') {
      let keys = Object.keys(element)
      if (keys) {
        //get children
        if (keys.length > 0) {
          let foundParrent = false
          keys.forEach(key2 => {
            if (!foundParrent)
              if (!maxDrill || currentDrill <= maxDrill) {
                foundParrent = this.getDataParentElements(element, data, key2, maxDrill, currentDrill + 1)
                if (foundParrent) {
                  if (this.state.conectivityMapping.addParentElementKey) {
                    element.parentKey = key
                  }
                }
              }
          })
        }
      }
    } else {
      //remove "order key since this is used for the order of the components"
      if (key && key !== 'order') {
        if (Object.keys(parentElement).length > 1) {
          data.push(parentElement)
        }
        return true
      }
    }
  }

  getTableData(id) {
    const { classes } = this.props
    let data = []
    let parentData = []
    let parentElement

    if (
      this.props.initialState.conectivityMapping.parameterId === 'tinValidation' ||
      this.props.initialState.conectivityMapping.parameterId === 'listMatchValidation'
    ) {
      parentElement = this.props.initialState.conectivityMapping.dataFields
    } else parentElement = this.props.initialState.conectivityMapping.fields[id]

    this.getDataParentElements(parentElement, parentData, undefined, this.props.initialState.conectivityMapping.maxDrill)
    let parentIndex = -1
    parentData.forEach(element => {
      parentIndex++
      let keys = Object.keys(element)
      if (keys) {
        let customElement = {}
        keys.forEach(elementKey => {
          if (typeof element[elementKey] === 'boolean') {
            customElement[elementKey] = (
              <div className="actions-center">
                <Switch
                  disabled={this.state.conectivityMapping.tableId === 'validationMessages'}
                  checked={element[elementKey]}
                  onChange={event => {
                    element[elementKey] = event.target.checked
                    if (this.props.initialState.conectivityMapping.onBooleanSwitchChange)
                      this.props.initialState.conectivityMapping.onBooleanSwitchChange(refresh => {
                        // element[elementKey] = !element[elementKey]
                        //  this.setState(this.state)
                      })
                    //refresh on table
                    this.setState({ dataVersion: this.state.dataVersion + 1 })
                    // this.refreshTableWithData()
                  }}
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                  }}
                />
              </div>
            )
          } else customElement[elementKey] = element[elementKey]
        })
        if (
          this.props.initialState.conectivityMapping.tableActions &&
          this.props.initialState.conectivityMapping.tableActions.length &&
          this.props.initialState.conectivityMapping.tableActions.length > 0
        ) {
          let length = Number((100 / this.props.initialState.conectivityMapping.tableActions.length).toFixed(2))

          customElement['action'] = this.props.initialState.conectivityMapping.tableActions.map((action, index) => {
            return (
              <div
                onClick={() => {
                  if (
                    this.props.initialState.conectivityMapping.tableActionFunctions &&
                    this.props.initialState.conectivityMapping.tableActionFunctions.length &&
                    this.props.initialState.conectivityMapping.tableActionFunctions.length > index
                  )
                    this.props.initialState.conectivityMapping.tableActionFunctions[index](element, parentData, parentIndex)
                }}
                style={{ width: `${length}%`, float: 'right' }}
                key={index}
              >
                {action}
              </div>
            )
          })
        }
        data.push(customElement)
      }
    })

    return data
  }
  getTableData2(id) {
    const { classes } = this.props

    let data = []
    let parentData = []
    this.getDataParentElements(this.state.conectivityMapping.fields[id], parentData, undefined, this.state.conectivityMapping.maxDrill)
    let parentIndex = -1
    parentData.forEach(element => {
      parentIndex++
      let keys = Object.keys(element)
      if (keys) {
        let customElement = {}
        keys.forEach(elementKey => {
          if (typeof element[elementKey] === 'boolean') {
            customElement[elementKey] = (
              <div className="actions-center">
                <Switch
                  checked={element[elementKey]}
                  onChange={event => {
                    element[elementKey] = event.target.checked
                    this.refreshTableWithData()
                    if (this.state.conectivityMapping.onBooleanSwitchChange)
                      this.state.conectivityMapping.onBooleanSwitchChange(refresh => {
                        element[elementKey] = !element[elementKey]
                        this.setState(this.state)
                        if (refresh) refresh()
                      })
                  }}
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                  }}
                />
              </div>
            )
          } else customElement[elementKey] = element[elementKey]
        })
        if (
          this.state.conectivityMapping.tableActions &&
          this.state.conectivityMapping.tableActions.length &&
          this.state.conectivityMapping.tableActions.length > 0
        ) {
          let length = Number((100 / this.state.conectivityMapping.tableActions.length).toFixed(2))

          customElement['action'] = this.state.conectivityMapping.tableActions.map((action, index) => {
            return (
              <div
                onClick={() => {
                  if (
                    this.state.conectivityMapping.tableActionFunctions &&
                    this.state.conectivityMapping.tableActionFunctions.length &&
                    this.state.conectivityMapping.tableActionFunctions.length > index
                  )
                    this.state.conectivityMapping.tableActionFunctions[index](element, parentData, parentIndex)
                }}
                style={{ width: `${length}%`, float: 'right' }}
                key={index}
              >
                {action}
              </div>
            )
          })
        }
        data.push(customElement)
      }
    })

    return data
  }
  getTableColumns(id, examplePosition = 0) {
    let keys = Object.keys(this.state.conectivityMapping.fields[id])
    if (keys.length > examplePosition) {
      let example = this.state.conectivityMapping.fields[id][keys[examplePosition]]
      if (typeof example !== 'object') return this.getTableColumns(id, examplePosition + 1)
      let columns = Object.keys(example).map((id2, key2) => {
        let column = {
          Header: id2,
          accessor: id2
        }
        return column
      })

      return columns
    } else return []
  }
  getBackButton() {
    if (this.state.conectivityMapping.backButtonOnClick)
      return (
        <div
          style={{
            width: '100%'
          }}
        >
          <IconButton onClick={this.state.conectivityMapping.backButtonOnClick}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </div>
      )
    return ''
  }

  render() {
    const { classes } = this.props
    if (!this.state.conectivityMapping)
      return (
        <GridContainer justify="center">
          {this.getBackButton()}
          <GridItem xs={12} sm={12} md={12}>
            no Mapping Prop
          </GridItem>
        </GridContainer>
      )
    else if (!this.state.conectivityMapping.fields) {
      return (
        <GridItem xs={12} sm={12} md={12}>
          {this.getBackButton()}
          no fields
        </GridItem>
      )
    } else if (!this.state.conectivityMapping.service) {
      return (
        <GridItem xs={12} sm={12} md={12}>
          {this.getBackButton()}
          no service
        </GridItem>
      )
    } else
      return (
        <GridContainer justify="center">
          {this.getBackButton()}
          {this.state.conectivityMapping.documentationPath ? (
            <GridItem xs={12} sm={12} md={12}>
              <Button simple className={classes.documentation} href={this.state.conectivityMapping.documentationPath} target="_blank">
                <Info className={classes.icons} />
                Download Documentation
              </Button>
            </GridItem>
          ) : (
            ''
          )}
          {this.state.conectivityMapping.title ? (
            <GridItem xs={12} sm={12}>
              <h4 className={classes.infoText}>{this.state.conectivityMapping.title}</h4>
            </GridItem>
          ) : (
            ''
          )}
          <GridItem xs={12} sm={12}>
            {this.state.conectivityMapping.hideServiceName ? (
              ''
            ) : (
              <h5 className={classes.sectionTitle}>{this.state.conectivityMapping.service.toUpperCase()}</h5>
            )}
            <GridItem xs={12}>
              {this.props.initialState.conectivityMapping.topChildren ? this.props.initialState.conectivityMapping.topChildren : ''}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              {this.state.conectivityMapping.fields ? (
                <GridContainer>
                  {this.state.conectivityMapping.sortedFields.map((id, key) => {
                    if (
                      (this.state.conectivityMapping.ignoreIds && this.state.conectivityMapping.ignoreIds.includes(id)) ||
                      this.state.conectivityMapping.fields[id]?.hidden
                    )
                      return ''
                    else if (id === this.state.conectivityMapping.tableId) {
                      return (
                        <GridItem xs={12} sm={12} md={12} key={key}>
                          <GridContainer>
                            <CustomTreeTable
                              isLoadingSave={this.state.conectivityMapping.tableIsLoadingSave}
                              hideValue={this.props.initialState.conectivityMapping.hideValue}
                              hideKey={this.props.initialState.conectivityMapping.hideKey}
                              onSave={
                                //props.onConfigSave
                                () => {
                                  if (this.state.conectivityMapping.tableOnSave) {
                                    let table = {}
                                    table[this.state.conectivityMapping.tableId] = this.state.conectivityMapping.fields[
                                      this.state.conectivityMapping.tableId
                                    ]
                                    this.state.conectivityMapping.tableOnSave(table)
                                  }
                                }
                              }
                              showTablePagination={this.state.conectivityMapping.showTablePagination}
                              tableMaxSize={this.state.conectivityMapping.tableMaxSize}
                              hideSaveButton={this.state.conectivityMapping.hideSaveButton}
                              hideAddButton={this.state.conectivityMapping.hideAddButton}
                              onClickAdd={() => {
                                this.state.conectivityMapping.addButtonOnClick()
                              }}
                              formLabel={this.state.conectivityMapping.formLabel}
                              transformedColumnName={this.state.conectivityMapping.transformedColumnName}
                              formTitle={this.state.conectivityMapping.formTitle}
                              addTooltipText={this.state.conectivityMapping.addTooltipText}
                              tableTitle={this.state.conectivityMapping.tableTitle}
                              tableIcon={<div>{this.state.conectivityMapping.tableIcon}</div>}
                              mainAccessor={this.state.conectivityMapping.mainAccessor}
                              noDataDiv={this.state.conectivityMapping.noDataDiv}
                              noDataText={this.state.conectivityMapping.noDataText}
                              dataVersion={this.state.dataVersion}
                              data={this.state.data}
                              columns={
                                this.state.conectivityMapping.columns ? this.state.conectivityMapping.columns : this.getTableColumns(id)
                              }
                              refreshData={refreshChild => {
                                this.refreshTable = refreshChild
                              }}
                              transformCodes={['xs', 'sm']}
                              defaultPageSize={10}
                              showPaginationTop
                              minRows={0}
                              showPaginationBottom={false}
                              className="-striped -highlight"
                            ></CustomTreeTable>
                          </GridContainer>
                        </GridItem>
                      )
                    } else if (
                      typeof this.state.conectivityMapping.fields[id] === 'object' &&
                      this.state.conectivityMapping.fields[id].parentObject
                    ) {
                      return (
                        <>
                          {this.showComponent(this.state.conectivityMapping.fields[id]) ? (
                            <GridItem xs={12} sm={12} md={12} key={key}>
                              <GridContainer>
                                <GridItem xs={12}>
                                  <div key={key} style={{ width: '100%', margin: 'auto' }}>
                                    <div style={{ width: '98%', margin: 'auto' }}>
                                      <ParametersAccordion
                                        innerRef={node => {
                                          if (this.innerReferences) this.innerReferences++
                                          else this.innerReferences = 0
                                          this[this.innerReferences] = node
                                        }}
                                        initialState={{
                                          forceAccordion: true,
                                          parentObject: this.state.conectivityMapping.fields,
                                          parameters: [
                                            {
                                              version: this.state.conectivityMapping.fields[id].version,
                                              expandedAccordion: this.state.conectivityMapping.fields[id].expandedAccordion,
                                              parentId: id,
                                              title: this.state.conectivityMapping.fields[id].label,
                                              conectivityMapping: {
                                                siteUrl: this.props.initialState.conectivityMapping.siteUrl,
                                                region: this.props.initialState.conectivityMapping.region,
                                                env: this.props.initialState.conectivityMapping.env,
                                                app: this.props.initialState.conectivityMapping.app,
                                                hideServiceName: true,
                                                service: this.state.conectivityMapping.service,
                                                hideTestConnection: true,
                                                fields: this.state.conectivityMapping.fields[id]
                                              }
                                            }
                                          ]
                                        }}
                                      ></ParametersAccordion>
                                    </div>
                                  </div>
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          ) : (
                            ''
                          )}
                        </>
                      )
                    } else if (typeof this.state.conectivityMapping.fields[id] === 'object') {
                      return this.customDBFields(
                        this.state.conectivityMapping.fields[id],
                        key,
                        classes,
                        id,
                        'value',
                        false,
                        false,
                        '',
                        this.state.conectivityMapping.fields[id]['mutualExclusive']
                          ? this.state.conectivityMapping.fields[this.state.conectivityMapping.fields[id]['mutualExclusive']]
                          : undefined,
                        this.state.conectivityMapping.fields[id]['mutualExclusive']
                      )
                    } else return ''
                  })}
                  {!this.state.conectivityMapping.hideTestConnection ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Tooltip title={'Check connection to ' + this.state.conectivityMapping.service}>
                        <Button
                          color={this.state.connected !== undefined ? (this.state.connected ? 'info' : 'danger') : 'facebook'}
                          onClick={this.checkConnection.bind(this)}
                          round
                          className={classes.documentation}
                        >
                          {this.state.connected !== undefined ? (
                            this.state.connected ? (
                              <div>
                                <CheckBox className={classes.icons} /> Connected
                              </div>
                            ) : (
                              <div>
                                <Cancel className={classes.icons} /> Connection Error
                              </div>
                            )
                          ) : (
                            <div>
                              {this.state.connectionTesting
                                ? '...'
                                : this.props.initialState.conectivityMapping.customConnection
                                ? 'Test Connectivity'
                                : `Test ${this.state.conectivityMapping.service.toUpperCase()} Connectivity`}
                            </div>
                          )}
                        </Button>
                      </Tooltip>
                    </GridItem>
                  ) : (
                    ''
                  )}
                  <GridItem xs={12} sm={12} md={12}>
                    {this.state.connectedMessage ? (
                      <Tooltip title="View connection error details">
                        <Button
                          color="danger"
                          simple
                          round
                          className={classes.documentation}
                          onClick={() =>
                            this.setState({
                              showModal: true,
                              connectionErrorMessage: this.state.connectedMessage
                            })
                          }
                        >
                          Error details
                        </Button>
                      </Tooltip>
                    ) : null}
                  </GridItem>
                </GridContainer>
              ) : (
                ''
              )}
            </GridItem>
          </GridItem>
          {this.showModal()}
        </GridContainer>
      )
  }
}

connectivity.propTypes = {
  classes: PropTypes.object
}

export default withStyles(style)(connectivity)
