import React, { useState } from 'react'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'

import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Tooltip from '@material-ui/core/Tooltip'

//material-ui
import { Select, MenuItem } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

const useStyles = makeStyles(styles)

const exportFileTypesDefault = [
  { value: '.csv', label: 'CSV' },
  { value: '.xlsx', label: 'XLSX' },
  { value: '.txt', label: 'TXT' },
  { value: '.pdf', label: 'PDF' }
]

export default function CustomDownloadButton(props) {
  const {
    handleConfirm,
    className,
    style,
    disabled = false,
    exportTypes
  } = props

  const classes = useStyles()

  const exportFileTypes = exportTypes ?? exportFileTypesDefault

  const [showModal, setShowModal] = useState(false)
  const [fileType, setFileType] = useState(exportFileTypes[0].value)

  function onCancel(params) {
    setFileType(exportFileTypes[0].value)
    setShowModal(false)
  }

  function onSuccess() {
    handleConfirm(fileType)
    setShowModal(false)
  }

  return (
    <>
      <Dialog
        onClose={onCancel}
        aria-labelledby="simple-dialog-title"
        open={showModal}
        fullWidth={true}
        maxWidth={'xs'}
      >
        <DialogTitle id="simple-dialog-title">Download File</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <GridContainer container spacing={4} style={{ paddingBottom: '5%' }}>
            <GridItem xs={6} sm={6} md={6} lg={6}>
              <div style={{ paddingBottom: '20px' }}>
                <CardHeader>File type</CardHeader>
              </div>
              <CardBody>
                <Select
                  MenuProps={{
                    style: { zIndex: 5555 }
                  }}
                  style={{ width: '100%' }}
                  value={fileType}
                  onChange={e => setFileType(e.target.value)}
                  inputProps={{
                    name: 'fieldType',
                    id: 'fieldType'
                  }}
                >
                  {exportFileTypes.map(item => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </CardBody>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onSuccess()}
            color="primary"
            variant="outlined"
          >
            Export
          </Button>
          <Button onClick={() => onCancel()} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Download">
        <Button
          size="small"
          className={className}
          onClick={() => {
            setShowModal(true)
          }}
          style={style}
          disabled={disabled}
        >
          <GetAppIcon />
        </Button>
      </Tooltip>
    </>
  )
}
