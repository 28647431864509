import { useMutation, useQuery } from 'react-query'
import { getUserFutureFlagsQuery, partialUpdateUser } from 'services/apiUsers'

export function usePartialUpdateUser(userId) {
  return useMutation(body => partialUpdateUser(userId, body))
}

export function useGetUserFutureFlags({ enableQuery, userId }) {
  return useQuery(
    ['User', userId, 'future-flags'],
    () => getUserFutureFlagsQuery(userId).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery && Boolean(userId),
      staleTime: Infinity
    }
  )
}
