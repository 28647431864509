import React from 'react'

import { Badge, Box, IconButton, TextField, Tooltip } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import { checkErrorOrWarning } from 'utils/functions'

const useStyles = makeStyles(styles)

const TextFieldLineItems = props => {
  const {
    label,
    validationErrorsState,
    field,
    handleChange,
    value,
    lineId,
    removeWarningLineItem,
    readOnly
  } = props

  const classes = useStyles()

  const validated = validationErrorsState[`${field}.${lineId}`]?.valid ?? null

  const message = validationErrorsState[`${field}.${lineId}`]?.messages

  const typeMsg = checkErrorOrWarning(message, validated)

  let tooltipArrowClass = null
  let tooltipClass = null
  let badgeClass = null

  if (typeMsg === 'warning') {
    tooltipArrowClass = classes.warningColor
    tooltipClass = classes.tooltipWarning
    badgeClass = classes.badgeWarning
  } else if (typeMsg === 'error') {
    tooltipArrowClass = classes.errorColor
    tooltipClass = classes.tooltipError
    badgeClass = classes.badgeError
  }

  function validationMessages(validationsError) {
    if (!validationsError) {
      return ''
    }

    if (Array.isArray(validationsError)) {
      return (
        <>
          {validationsError.map((message, index) => (
            <div key={index}>{message.message}</div>
          ))}
        </>
      )
    }

    const messages = validationsError.split('\n')

    return (
      <>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </>
    )
  }

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Tooltip
        classes={{
          arrow: tooltipArrowClass,
          tooltip: tooltipClass
        }}
        title={typeMsg !== '' ? validationMessages(message) : ''}
        arrow
        placement="right"
      >
        <Badge
          classes={{
            badge: badgeClass
          }}
          badgeContent={
            typeMsg === 'warning' ? (
              <WarningIcon className={classes.warningColor}></WarningIcon>
            ) : null
          }
          invisible={typeMsg !== '' ? true : false}
        >
          <TextField
            value={value}
            onChange={event => {
              handleChange(event.target.value)
            }}
            style={{ width: '100%' }}
            label={label ?? ''}
            variant="outlined"
            size="medium"
            fullWidth
            error={typeMsg === 'error' ? true : false}
            disabled={readOnly ?? false}
            className={readOnly ? classes.readOnly : ''}
            InputProps={{
              readOnly: readOnly ?? false,
              classes: {
                notchedOutline: typeMsg === 'warning' ? classes.inputWarning : ''
              }
            }}
            InputLabelProps={{
              classes: {
                root: typeMsg === 'warning' ? classes.warningColor : '',
                focused: typeMsg === 'warning' ? classes.warningColor : ''
              }
            }}
          />
        </Badge>
      </Tooltip>
      {typeMsg === 'warning' ? (
        <IconButton
          style={{ padding: '0', marginLeft: '10px' }}
          className={classes.buttonSuccess}
          onClick={() => removeWarningLineItem(field, lineId)}
        >
          <CheckCircleOutlineIcon className={classes.successColor} />
        </IconButton>
      ) : null}
    </Box>
  )
}

export default TextFieldLineItems
