import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import styles from 'assets/jss/material-dashboard-pro-react/components/emailModalStyle'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const useStyles = makeStyles(styles)

export function RejectInvoicesModal(props) {
  const classes = useStyles()
  const { onClose, open, rejectInvoices } = props
  const [value, setValue] = useState('')

  const handleClose = () => {
    onClose()
    setValue('')
  }

  const fieldChange = value => {
    setValue(value)
  }

  const submit = () => {
    rejectInvoices(value)
    setValue('')
    handleClose()
  }

  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot
      }}
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Reject Invoices</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          label="Comment"
          multiline
          rows="3"
          value={value}
          onChange={e => fieldChange(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.colorPrimary}
          color="primary"
          variant="outlined"
          disabled={!Boolean(value)}
          onClick={() => submit()}
        >
          Reject
        </Button>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RejectInvoicesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  rejectInvoices: PropTypes.func.isRequired
}
