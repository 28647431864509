import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

// Router
import { useHistory } from 'react-router'

// Material UI
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'

// Icons
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'

import ClearIcon from '@material-ui/icons/Clear'

// Components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Snackbar from 'components/Snackbar/Snackbar.js'

// Form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// Views
import ConfigurationView from './ConfigurationView'
import EntitiesView from './EntitiesView'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Hooks
import { useGetTransaction, useCreateTransaction, useUpdateTransaction } from 'hooks/useInvoiceConf'

import { useGetAppList } from 'hooks/useApp'

import { useEffect } from 'react'
import * as yup from 'yup'
const useStyles = makeStyles(styles)

const appType = 'invoiceAI'

export default function InvoiceTransaction(props) {
  const classes = useStyles()
  const history = useHistory()
  const { group, appId, transactionId, breadcrumbViews, path, entities, app } = props
  const [configurationForm] = useState({
    name: '',
    frequency: 'days',
    executionTime: moment(new Date()).format('hh:mm a'),
    executionMinute: '',
    executionHour: '',
    executionDay: '',
    fromDate: moment(new Date()).format('MM/DD/YYYY')
  })
  const queryClient = useQueryClient()
  const [selectedRows, setSelectedRows] = useState([])
  const [remoteConnection, setRemoteConnection] = useState('')
  const [frequencyId, setFrequencyId] = useState('days')
  const [createError, setCreateError] = useState({
    message: '',
    isOpen: false,
    color: 'danger'
  })
  const [submitSuccess, setSubmitSuccess] = useState({
    message: '',
    isOpen: false
  })

  const appList = useGetAppList(appType)

  const transaction = useGetTransaction({
    appId: app.data?.data.insightsAppId,
    transactionId,
    options: { refetchOnWindowFocus: false }
  })
  const schemaConf = yup.object().shape({
    name: yup
      .string()
      .min(1)
      .required(),
    frequency: yup
      .string()
      .min(1)
      .required(),
    fromDate: yup
      .string()
      .min(1)
      .required(),
    executionTime: yup
      .string()
      .when('frequency', (frequency, schema) =>
        frequency === 'days' || frequency === 'weeks' || frequency === 'months'
          ? schema.required()
          : schema.optional()
      ),
    executionMinute: yup
      .string()
      .when('frequency', (frequency, schema) =>
        frequency === 'minutes' ? schema.required() : schema.optional()
      ),
    executionHour: yup
      .string()
      .when('frequency', (frequency, schema) =>
        frequency === 'hours' ? schema.required() : schema.optional()
      ),
    executionDay: yup
      .string()
      .when('frequency', (frequency, schema) =>
        frequency === 'days' || frequency === 'weeks' || frequency === 'months'
          ? schema.required()
          : schema.optional()
      )
  })
  useEffect(() => {
    if (!appList.isLoading && appList.isError) {
      setCreateError({
        message: appList.error.response?.data?.message ?? 'Something went wrong, try again later',
        isOpen: true,
        color: 'danger'
      })
    }
  }, [appList.isLoading, appList.isError, appList.error])

  useEffect(() => {
    if (!transaction.isLoading && transaction.isError) {
      setCreateError({
        message:
          transaction.error.response?.data?.message ?? 'Something went wrong, try again later',
        isOpen: true,
        color: 'danger'
      })
    }
  }, [transaction.isLoading, transaction.isError, transaction.error])

  const [activeStep, setActiveStep] = React.useState(0)

  const {
    control: controlConf,
    formState: { isValid: isValidConfForm },
    getValues: getConfValues,
    reset: resetConf
  } = useForm({
    mode: 'all',
    defaultValues: configurationForm,
    resolver: yupResolver(schemaConf),
    shouldUnregister: false
  })

  useEffect(() => {
    if (!app.data) {
      return
    }

    const connectionId = Object.keys(app.data?.data?.params.remoteConnections)[0]
    setRemoteConnection(
      connectionBody(app.data?.data?.params.remoteConnections[connectionId]) ?? []
    )
  }, [app.data, appId, group, history, transactionId])

  useEffect(() => {
    if (!transaction.data) {
      return
    }

    const conf = {
      name: transaction.data.params.name,
      frequency: transaction.data.params.execution.frequency ?? 'days',
      executionTime: moment(transaction.data.params.execution.executionTime ?? '').format(
        'hh:mm a'
      ),
      executionMinute: transaction.data.params.execution.executionMinute ?? '',
      executionHour: transaction.data.params.execution.executionHour ?? '',
      executionDay: transaction.data.params.execution.executionDay ?? '',
      fromDate: moment(transaction.data.params.filters.fromDate ?? '').format('MM/DD/YYYY')
    }

    // setRemoteConnectionId(transaction.data.params.destination.connectionId)
    setFrequencyId(transaction.data.params.execution.frequency)
    resetConf(conf)
    // resetConn(transaction.data.destination)
    setSelectedRows(transaction.data.params.entities)
  }, [transaction.data, resetConf])

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const { mutate: createTransaction, isLoading: isLoadingCreateTransaction } = useCreateTransaction(
    {
      appId: app.data?.data.insightsAppId
    }
  )

  const { mutate: updateTransaction, isLoading: isLoadingUpdateTransaction } = useUpdateTransaction(
    {
      appId: app.data?.data.insightsAppId,
      transactionId: transactionId
    }
  )

  function connectionBody(data) {
    const dataConnection = { connectionId: data.connectionId, name: data.name, type: data.type }
    switch (data.type) {
      case 's3':
        dataConnection.s3Params = {
          path: data.path,
          fileExtension: data.fileExtension,
          zipFile: data.unzip ? false : true
        }
        break
      case 'sharepoint':
        dataConnection.sharepointParams = {
          path: data.path,
          fileExtension: data.fileExtension,
          zipFile: data.unzip ? false : true
        }
        break
      case 'sftp':
        dataConnection.sftpParams = {
          encryptionMethod: data.encryptionMethod,
          encryptionFile: data.encryptionFile,
          encryptionFileObj: data.encryptionFileObj,
          useSingleConnection: data.useSingleConnection,
          path: data.path,
          fileExtension: data.fileExtension,
          zipFile: data.unzip ? false : true
        }
        break
      case 'msSql':
        dataConnection.msSqlParams = {
          dbServer: data.dbServer,
          dbName: data.dbName,
          dbPassword: data.dbPassword,
          dbPort: parseInt(data.dbPort),
          dbUser: data.dbUser
        }
        if (data.dbDomain) {
          dataConnection.msSqlParams.dbDomain = data.dbDomain
        }
        break
      default:
        break
    }
    return dataConnection
  }

  function modifyData(configurationForm, selectedRows) {
    const newData = {}
    newData.params = {}
    if (!transactionId) {
      newData.transactionId = uuidv4()
      newData.status = 'new'
      newData.group = group
      newData.creationDate = new Date().toISOString()
      newData.creationDateEpoc = new Date().getTime()
      newData.params.notifications = {
        sendEmailAfterLoadStarts: false,
        sendEmailAfterLoadEnds: false
      }
    }

    newData.params.name = configurationForm.name
    newData.params.destination = remoteConnection
    newData.params.entities = selectedRows.map(row => {
      return { group: row.group, entity: row.entity }
    })
    const executionTime = moment.isMoment(configurationForm.executionTime)
      ? configurationForm.executionTime.toISOString()
      : moment(moment().format('MM/DD/YYYY') + ' ' + configurationForm.executionTime).toISOString()
    const fromDate = moment.isMoment(configurationForm.fromDate)
      ? configurationForm.fromDate.toISOString()
      : moment(configurationForm.fromDate).toISOString()
    newData.params.execution = {
      frequency: configurationForm.frequency,
      executionTime: executionTime,
      executionMinute: configurationForm.executionMinute,
      executionHour: configurationForm.executionHour,
      executionDay: configurationForm.executionDay
    }
    newData.params.filters = {
      fromDate: fromDate
    }

    return newData
  }

  function manageTransaction() {
    if (isValidConfForm && selectedRows.length > 0) {
      const data = modifyData(getConfValues(), selectedRows)
      if (transactionId) {
        updateTransaction(
          { data },
          {
            onSettled: () => {
              queryClient.invalidateQueries(['Apps', appId])
            },
            onSuccess: response => {
              if (response.status === 200) {
                setSubmitSuccess({ message: 'Saved', isOpen: true })
                history.push(path.main)
              }
            },
            onError: error => {
              setCreateError({
                message: error.response?.data?.message ?? 'Something went wrong, try again later',
                isOpen: true,
                color: 'danger'
              })
            }
          }
        )
      } else {
        createTransaction(
          { data },
          {
            onSettled: () => {
              queryClient.invalidateQueries(['Apps', appId])
            },
            onSuccess: response => {
              if (response.status === 201) {
                setSubmitSuccess({ message: 'Saved', isOpen: true })
                history.push(path.main)
              }
            },
            onError: error => {
              setCreateError({
                message: error.response?.data?.message ?? 'Something went wrong, try again later',
                isOpen: true,
                color: 'danger'
              })
            }
          }
        )
      }
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <ConfigurationView
            control={controlConf}
            frequencyId={frequencyId ?? 'days'}
            setFrequencyId={setFrequencyId}
          />
        )
      case 1:
        return (
          <EntitiesView
            group={group}
            service={app.data?.data?.service}
            instance={app.data?.data?.params.ariba?.instanceType ?? 'sap'}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            entities={entities}
          />
        )
      default:
        return 'Unknown'
    }
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} sm={3} md={3} lg={3}>
        <Card style={{ height: '100%' }}>
          <CardContent>Application List</CardContent>
          <CardContent>
            {appList.isLoading ? (
              <Grid container justifyContent="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            ) : (
              <List>
                {appList.data?.map((prop, key) => {
                  return (
                    <ListItem
                      className={classes.listItem}
                      button
                      selected={appId === prop.env}
                      key={key}
                      onClick={() => {
                        history.push('/admin/invoiceAI/configurations')
                      }}
                    >
                      <ListItemAvatar>
                        {appId === prop.env ? (
                          <Avatar>
                            <TouchApp />
                          </Avatar>
                        ) : (
                          <Avatar className={classes.clearAvatar}></Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText primary={prop.appName} secondary={`Type: ${prop.type}`} />
                    </ListItem>
                  )
                })}
              </List>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={9} md={9} lg={9}>
        <Breadcrumbs views={breadcrumbViews} />
        <Card style={{ overflow: 'visible' }}>
          <CardContent>
            {app.isLoading || transaction.isLoading ? (
              <Grid container justifyContent="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            ) : (
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <IconButton
                    style={{ float: 'right' }}
                    color="primary"
                    component="span"
                    onClick={() => history.push(path.main)}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                      <StepLabel>Configuration</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>Entities</StepLabel>
                    </Step>
                    {/* <Step>
                      <StepLabel>Connection</StepLabel>
                    </Step> */}
                  </Stepper>
                </Grid>
                <Grid item xs={12}>
                  {getStepContent(activeStep)}
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button
                    disabled={
                      activeStep === 0 || isLoadingCreateTransaction || isLoadingUpdateTransaction
                    }
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  {activeStep === 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => manageTransaction()}
                      className={classes.bgColorPrimary}
                      disabled={isLoadingCreateTransaction || isLoadingUpdateTransaction}
                    >
                      Finish
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.bgColorPrimary}
                    >
                      Next
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Snackbar
        place="br"
        color="success"
        icon={AddAlert}
        message={submitSuccess.message}
        open={submitSuccess.isOpen}
        closeNotification={() =>
          setSubmitSuccess({
            isOpen: false,
            message: ''
          })
        }
        close
      />
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() =>
          setCreateError({
            isOpen: false,
            message: ''
          })
        }
        close
      />
    </Grid>
  )
}
