import { useMutation, useQuery } from 'react-query'

import {
  getInvoiceOcrTransaction,
  getInvoicesOcrDiff,
  getInvoicesOcrVersionsQuery,
  getInvoiceTextract,
  getOcrFieldValues,
  postTextRekognition,
  putInvoiceTextract,
  putResetInvoiceTextract,
  putValidationInvoice,
  updateInvoiceOcrData
} from 'services/apiInvoices'

import {
  ColumnsOrderAdditionalCharges,
  ColumnsOrderLineItems,
  HeaderFieldsSide
} from 'utils/Constants'

const DefaultImageWidth = 3024
const DefaultImageHeigth = 4032
function sortElements(a, b) {
  return a.pos - b.pos
}

function sortLines(a, b) {
  return Number(a.LINE_NUMBER) - Number(b.LINE_NUMBER)
}

export function getGeometryAndOtherFields(data) {
  const summaryList = []
  const otherList = data.header.OTHERS ?? []
  const metadata = data.metadata?.header?.fields ?? {}
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(data.header)) {
    if (key === 'OTHERS') {
      continue
    }

    value.visible = metadata[value.type]?.visible ?? true
    summaryList.push(value)
  }

  return { summaryList, otherList }
}

export function getGeometry(data, files) {
  let geometryList = []
  const otherGemometryFields = []
  let imageHeigth
  let imageWidth
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(data.header)) {
    if (key === 'OTHERS') {
      // eslint-disable-next-line
      for (const field of value) {
        const confidenceValue = field?.value?.confidence
        const boundingBoxValue = field?.value?.geometry?.boundingBox
        if (confidenceValue === 0) {
          continue
        }

        if (boundingBoxValue) {
          const { sizeImage } = files[field.page]
          imageHeigth = sizeImage?.height ?? DefaultImageWidth
          imageWidth = sizeImage?.width ?? DefaultImageHeigth
          otherGemometryFields.push({
            mark: {
              height: boundingBoxValue.Height * imageHeigth,
              x: boundingBoxValue.Left * imageWidth,
              y: boundingBoxValue.Top * imageHeigth,
              width: boundingBoxValue.Width * imageWidth
            },
            type: 'RECT',
            id: field.id,
            key: field.id,
            comment: field?.label?.text ?? '',
            section: 'header',
            page: field.page
          })
        }
      }

      continue
    }

    const confidenceValue = value?.value?.confidence
    if (confidenceValue === 0) {
      continue
    }

    const { sizeImage } = files[value.page]

    const boundingBoxValue = value?.value?.geometry?.boundingBox

    if (boundingBoxValue) {
      imageHeigth = sizeImage?.height ?? DefaultImageWidth
      imageWidth = sizeImage?.width ?? DefaultImageHeigth
      geometryList.push({
        mark: {
          height: boundingBoxValue.Height * imageHeigth,
          x: boundingBoxValue.Left * imageWidth,
          y: boundingBoxValue.Top * imageHeigth,
          width: boundingBoxValue.Width * imageWidth
        },
        type: 'RECT',
        id: value.id,
        key: value.id,
        comment: HeaderFieldsSide[value.type]?.label ?? value.type.replace(/_/g, ' '),
        section: 'header',
        page: value.page
      })
    }
  }

  geometryList.push(...getGeometryList(files, data.lineItems, 'lineItems'))

  geometryList.push(...getGeometryList(files, data.additionalCharges, 'additionalCharges'))

  return { geometryList, otherGemometryFields }
}

function getGeometryList(files, data, section) {
  const geometryList = []
  let imageHeigth
  let imageWidth
  if (data) {
    // eslint-disable-next-line
    for (const lineItem of Object.values(data)) {
      const lineKey = lineItem.uuid ?? lineItem.lineNumber
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(lineItem)) {
        const boundingBoxValue = value?.value?.geometry?.boundingBox
        const confidenceValue = value?.value?.confidence

        if (confidenceValue === 0) {
          continue
        }

        if (boundingBoxValue) {
          const { sizeImage } = files[value.page]
          imageHeigth = sizeImage?.height ?? DefaultImageWidth
          imageWidth = sizeImage?.width ?? DefaultImageHeigth
          geometryList.push({
            mark: {
              height: boundingBoxValue.Height * imageHeigth,
              x: boundingBoxValue.Left * imageWidth,
              y: boundingBoxValue.Top * imageHeigth,
              width: boundingBoxValue.Width * imageWidth
            },
            type: 'RECT',
            id: value.id,
            key: lineKey,
            column: key,
            comment: ColumnsOrderLineItems[value.type]
              ? ColumnsOrderLineItems[value.type].label
              : value.type,
            section: section,
            page: value.page
          })
        }
      }
    }
  }
  return geometryList
}

function getDataPerPage({ geometry, data }) {
  let geometryPerPage = {}

  // eslint-disable-next-line
  for (const position of geometry) {
    if (geometryPerPage[position.page]) {
      geometryPerPage = {
        ...geometryPerPage,
        [position.page]: [...geometryPerPage[position.page], position]
      }
    } else {
      geometryPerPage = { ...geometryPerPage, [position.page]: [position] }
    }
  }
  return { geometryPerPage }
}

function transFormLineItems(lines, useLineNumber) {
  const linesUpdated = []

  // eslint-disable-next-line
  for (const lineItem of Object.values(lines ?? {})) {
    const lineItemObject = {}
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(lineItem)) {
      if (
        key === 'page' ||
        key === 'OTHERS' ||
        key === 'uuid' ||
        key === ColumnsOrderLineItems.ACCOUNTING.type
      ) {
        continue
      }

      if (useLineNumber && key === 'lineNumber') {
        lineItemObject['LINE_NUMBER'] = lineItem.lineNumber
        continue
      }

      if (key === 'lineNumber') {
        continue
      }
      if (value.type === 'LINE_NUMBER') {
        lineItemObject['LINE_NUMBER'] =
          value.value?.text !== '' ? value.value?.text : lineItem.lineNumber
        continue
      }

      if (typeof value.value?.text === 'string') {
        const r = value.value?.text ?? ''
        const s = r.replace(/\n/g, ' ')
        lineItemObject[value.type] = s
      } else {
        lineItemObject[value.type] = value.value?.text ?? ''
      }
    }
    lineItemObject['key'] = lineItem.uuid ?? lineItem.lineNumber
    linesUpdated.push(lineItemObject)
  }

  const linesWithNumber = linesUpdated.filter(line => line.LINE_NUMBER !== '')
  const linesWithoutNumber = linesUpdated.filter(line => line.LINE_NUMBER === '')
  const linesSorted = linesWithNumber.sort(sortLines)
  linesSorted.push(...linesWithoutNumber)

  return linesSorted
}

function getSelectOptions(summaryList) {
  const selectOptions = []

  // eslint-disable-next-line
  for (const summaryField of summaryList) {
    selectOptions.push({
      value: summaryField.type,
      label: HeaderFieldsSide[summaryField.type]?.label ?? summaryField.type.replace(/_/g, ' ')
    })
  }

  return selectOptions
}

function getColumns(data) {
  const initialColums = []
  if (data.metadata?.lineItems?.fields) {
    const columnsHeaders = Object.entries(data.metadata?.lineItems?.fields)
    // eslint-disable-next-line
    for (const [key, value] of columnsHeaders) {
      if (key === ColumnsOrderLineItems.ACCOUNTING.type) {
        continue
      }

      let pos
      let label
      const columnMetadata = ColumnsOrderLineItems[key]
      if (columnMetadata) {
        pos = columnMetadata.pos < 0 ? 100 : columnMetadata.pos
        label = columnMetadata.label
      } else {
        pos = 100
        label = value.label ?? value.type
      }
      initialColums.push({ ...value, pos, label, type: key })
    }
    initialColums.sort(sortElements)
    return initialColums
  }
  if (Object.keys(data.lineItems).length > 0) {
    const columnsHeaders = Object.entries(Object.values(data.lineItems)[0])
    // eslint-disable-next-line
    for (const [key, value] of columnsHeaders) {
      if (
        key === 'page' ||
        key === 'lineNumber' ||
        key === 'OTHERS' ||
        key === 'uuid' ||
        key === ColumnsOrderLineItems.ACCOUNTING.type
      ) {
        continue
      }
      let pos
      let label
      const columnMetadata = ColumnsOrderLineItems[key]
      if (columnMetadata) {
        pos = columnMetadata.pos < 0 ? 100 : columnMetadata.pos
        label = columnMetadata.label
      } else {
        pos = 10
        label = value.type
      }
      initialColums.push({ type: key, pos, label, visible: value.visible })
    }
  }
  initialColums.sort(sortElements)
  return initialColums
}

function getColumnsAdditionalCharges(data) {
  const initialColums = []

  if (data.metadata?.additionalCharges?.fields) {
    const columnsHeaders = Object.entries(data.metadata?.additionalCharges?.fields)
    // eslint-disable-next-line
    for (const [key, value] of columnsHeaders) {
      let pos
      let label
      const columnMetadata = ColumnsOrderAdditionalCharges[key]
      if (columnMetadata) {
        pos = columnMetadata.pos
        label = columnMetadata.label
      } else {
        pos = 10
        label = value.type
      }
      initialColums.push({ ...value, pos, label, type: key })
    }
    initialColums.sort(sortElements)
    return initialColums
  }
  if (data.additionalCharges && Object.keys(data.additionalCharges).length > 0) {
    const columnsHeaders = Object.entries(Object.values(data.additionalCharges)[0])
    // eslint-disable-next-line
    for (const [key, value] of columnsHeaders) {
      if (key === 'page' || key === 'lineNumber' || key === 'OTHERS' || key === 'uuid') {
        continue
      }
      let pos
      let label
      const columnMetadata = ColumnsOrderAdditionalCharges[key]
      if (columnMetadata) {
        pos = columnMetadata.pos
        label = columnMetadata.label
      } else {
        pos = 10
        label = value.type
      }
      initialColums.push({ type: key, pos, label, visible: value.visible })
    }
  }
  initialColums.sort(sortElements)
  return initialColums
}

export async function fetchInvoiceTextract({ invoiceId }) {
  return getInvoiceTextract({
    invoiceId
  }).then(result => {
    const {
      textract,
      originalFile: originalFileS3Path,
      files,
      poInformation,
      validationErrors
    } = result.data

    let otherGemometryFieldsResult
    let geometryPerPageResult
    if (Object.keys(files).length > 0) {
      const { geometryList: geometry, otherGemometryFields } = getGeometry(textract, files)
      const { geometryPerPage } = getDataPerPage({
        geometry,
        data: textract
      })
      otherGemometryFieldsResult = otherGemometryFields
      geometryPerPageResult = geometryPerPage
    }

    const { summaryList, otherList } = getGeometryAndOtherFields(textract)
    const selectOptions = getSelectOptions(summaryList)
    const lineItems = transFormLineItems(textract.lineItems, false)
    const additionalCharges = transFormLineItems(textract.additionalCharges, false)
    const lineItemColumns = getColumns(textract)
    const additionalChargesColumns = getColumnsAdditionalCharges(textract)
    return {
      originalFileS3Path,
      files,
      accountSegments: textract.accounting?.lineItems ?? {},
      summaryFields: summaryList,
      otherGemometryFields: otherGemometryFieldsResult,
      notRecognizedFields: otherList,
      poInformation: poInformation,
      originalLineItems: textract.lineItems,
      lineItemColumns,
      geometryPerPage: geometryPerPageResult,
      lineItems,
      numPages: Object.keys(files).length,
      validationErrors: validationErrors,
      selectOptions,
      additionalCharges,
      additionalChargesColumns,
      originalAdditionalCharges: textract.additionalCharges
    }
  })
}
export function useGetFileInformation({ invoiceId, config = {} }) {
  return useQuery(['ocrInvoice', invoiceId], () => fetchInvoiceTextract({ invoiceId }), {
    refetchOnWindowFocus: false,
    ...config
  })
}

export function useUpdateFileInformation({ invoiceId, appId }) {
  return useMutation(values =>
    putInvoiceTextract({
      invoiceId,
      appId,
      body: values
    })
  )
}

export function useResetOcrInformation({ invoiceId, appId }) {
  return useMutation(values =>
    putResetInvoiceTextract({
      invoiceId,
      appId,
      body: values
    })
  )
}

export function useValidationInvoice({ invoiceId, appId }) {
  return useMutation(values =>
    putValidationInvoice({
      invoiceId,
      appId,
      body: values
    })
  )
}

export function useGetTextOcr({ appId, invoiceId }) {
  return useMutation(values =>
    postTextRekognition({
      appId,
      invoiceId,
      body: values
    })
  )
}

export function useGetVersionsOcr({ transactionId, config = {} }) {
  return useQuery(
    ['ocrInvoice', 'transactions', transactionId, 'version'],
    () => getInvoicesOcrVersionsQuery({ transactionId }).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      ...config
    }
  )
}

export function useGetVersionsDiff({ transactionId, latestVersion, previousVersion, config = {} }) {
  const dependency = latestVersion !== null && previousVersion !== null
  return useQuery(
    ['ocrInvoice', 'transactions', transactionId, 'diff', latestVersion, previousVersion],
    () =>
      getInvoicesOcrDiff({
        latestVersion,
        previousVersion,
        transactionId
      }).then(result => {
        const { resultDiff, latestVersion, previousVersion } = result.data
        const versionA = getGeometryAndOtherFields(latestVersion)
        const versionALineItems = transFormLineItems(latestVersion.lineItems, false)
        const versionALineItemColumns = getColumns(latestVersion)
        const versionAAdditionalCharges = transFormLineItems(latestVersion.additionalCharges, true)
        const versionAAdditionalChargesColumns = getColumnsAdditionalCharges(latestVersion)

        const versionB = getGeometryAndOtherFields(previousVersion)
        const versionBLineItems = transFormLineItems(previousVersion.lineItems, false)
        const versionBLineItemColumns = getColumns(previousVersion)
        const versionBAdditionalCharges = transFormLineItems(
          previousVersion.additionalCharges,
          true
        )
        const versionBAdditionalChargesColumns = getColumnsAdditionalCharges(previousVersion)

        return {
          latestVersion: {
            summaryFields: versionA.summaryList,
            lineItemColumns: versionALineItemColumns,
            lineItems: versionALineItems,
            additionalCharges: versionAAdditionalCharges,
            additionalChargesColumns: versionAAdditionalChargesColumns,
            originalLineItems: latestVersion.lineItems,
            originalCharges: latestVersion.additionalCharges
          },
          previousVersion: {
            summaryFields: versionB.summaryList,
            lineItemColumns: versionBLineItemColumns,
            lineItems: versionBLineItems,
            additionalCharges: versionBAdditionalCharges,
            additionalChargesColumns: versionBAdditionalChargesColumns,
            originalLineItems: previousVersion.lineItems,
            originalCharges: previousVersion.additionalCharges
          },
          resultDiff
        }
      }),
    {
      enabled: dependency,
      refetchOnWindowFocus: false,
      ...config
    }
  )
}

export function useGetInvoiceOcrTransaction({ transactionId, config = {} }) {
  return useQuery(
    ['ocrInvoice', 'transactions', transactionId],
    () =>
      getInvoiceOcrTransaction({
        transactionId
      }).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      ...config
    }
  )
}

export function useGetInvoiceOcrFieldValues(field, params, config = {}) {
  return useQuery(
    ['ocrInvoice', 'fields', field, params],
    () => getOcrFieldValues(field, params).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      ...config
    }
  )
}

export function useUpdateInvoiceOcrData(url) {
  return useMutation(values =>
    updateInvoiceOcrData({
      body: values,
      url
    })
  )
}
