import { Badge, Grid, IconButton, TextField, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { checkErrorOrWarning } from 'utils/functions'
import { generateJsonValidation } from 'utils/validator'

const useStyles = makeStyles(styles)
export const HeaderField = props => {
  const {
    summaryField,
    control,
    setTextInputRef,
    onChangeSummaryField,
    index,
    rules,
    multiline,
    label,
    validationsError,
    setValidationErrorsState,
    validationErrorsState,
    readOnly
  } = props
  let validationErrorMessages = validationErrorsState[summaryField.type]?.messages ?? ''
  let validated = validationErrorsState[summaryField.type]?.valid ?? null
  const [isValid, setIsValid] = useState(false)
  const [initialErrorMessages] = useState(validationsError)
  const [updatedValue, setUpdatedValue] = useState(summaryField.value)
  const [initialValue] = useState(summaryField.value)

  const value = useWatch({
    name: 'summaryFields',
    control
  })

  const classes = useStyles()
  const [typeMsg, setTypeMsg] = useState(checkErrorOrWarning(validationErrorMessages, validated))
  const [typeMsgFirst, setTypeMsgFirst] = useState(checkErrorOrWarning(validationsError))

  function validationMessages(validationsError, validated = false) {
    if (!validationsError || validated) {
      return ''
    }

    if (Array.isArray(validationsError)) {
      return (
        <>
          {validationsError.map((message, index) => (
            <div key={index}>{message.message}</div>
          ))}
        </>
      )
    }

    const messages = validationsError.split('\n')

    return (
      <>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </>
    )
  }

  function getColors(isDirty, typeMsg, error) {
    let color = ''
    let inputColor = ''
    let badgeColor = ''
    let tooltipColor = ''

    if (typeMsg === 'error') {
      color = classes.errorColor
      inputColor = classes.inputError
      tooltipColor = classes.tooltipError
    }

    if (validated) {
      color = classes.successColor
      inputColor = classes.inputSuccess
    }

    if (isValid && !Boolean(error)) {
      color = classes.successColor
      inputColor = classes.inputSuccess
    }

    if (isDirty && !Boolean(error)) {
      color = classes.successColor
      inputColor = classes.inputSuccess
    }

    if (!isDirty && typeMsg === 'warning') {
      color = classes.warningColor
      inputColor = classes.inputWarning
      tooltipColor = classes.tooltipWarning
    }

    return { color, inputColor, badgeColor, tooltipColor }
  }

  const changeValidationToSuccess = useCallback(() => {
    setTypeMsg('')
    setIsValid(true)
    setValidationErrorsState(old => {
      const previousValidation = old[summaryField.type] ?? {}
      return {
        ...old,
        [summaryField.type]: {
          ...previousValidation,
          messages: [{ type: 'success', message: 'Accepted by User' }],
          valid: true
        }
      }
    })
  }, [setValidationErrorsState, summaryField.type])

  const changeValidationToWarning = useCallback(
    initialErrorMessages => {
      setTypeMsg('warning')
      setTypeMsgFirst('warning')
      setIsValid(false)
      setValidationErrorsState(old => {
        const previousValidation = old[summaryField.type] ?? {}
        return {
          ...old,
          [summaryField.type]: {
            ...previousValidation,
            messages: initialErrorMessages,
            valid: null
          }
        }
      })
    },
    [setValidationErrorsState, summaryField.type]
  )

  const changeValidationToError = useCallback(
    initialErrorMessages => {
      setTypeMsg('error')
      setTypeMsgFirst('error')
      setIsValid(false)
      setValidationErrorsState(old => {
        const previousValidation = old[summaryField.type] ?? {}
        return {
          ...old,
          [summaryField.type]: {
            ...previousValidation,
            messages: initialErrorMessages,
            valid: null
          }
        }
      })
    },
    [setValidationErrorsState, summaryField.type]
  )

  useEffect(() => {
    if (initialValue !== updatedValue) {
      if (typeMsgFirst !== '') {
        changeValidationToSuccess()
      }
      return
    }
    if (isValid || validated) {
      changeValidationToSuccess()
      return
    }

    if (typeMsgFirst === 'error') {
      changeValidationToError(initialErrorMessages)
    } else if (typeMsgFirst === 'warning') {
      changeValidationToWarning(initialErrorMessages)
    }
  }, [
    updatedValue,
    changeValidationToError,
    changeValidationToWarning,
    changeValidationToSuccess,
    initialValue,
    isValid,
    typeMsgFirst,
    initialErrorMessages,
    validated
  ])

  return (
    <Controller
      render={({ field, fieldState: { error, isDirty } }) => {
        const isWarning = !isDirty && typeMsg === 'warning' && !Boolean(error)
        const isVisible =
          (Boolean(error) && typeMsg === 'error') ||
          (!isDirty && typeMsg === 'error') ||
          isWarning ||
          Boolean(error)
        setUpdatedValue(field.value)

        const backEndErrorMessage = validationMessages(validationErrorMessages, validated)
        const errorMessage = backEndErrorMessage ? backEndErrorMessage : error?.message

        return (
          <Grid container justifyContent="center">
            <Grid item xs={typeMsgFirst === 'warning' && !Boolean(error) && !isDirty ? 11 : 12}>
              <Tooltip
                classes={{
                  arrow: isWarning ? classes.warningColor : classes.errorColor,
                  tooltip: isWarning ? classes.tooltipWarning : classes.tooltipError
                }}
                title={isVisible ? errorMessage : ''}
                arrow
                placement="right"
              >
                <Badge
                  style={{ width: '95%' }}
                  classes={{
                    badge: isWarning ? classes.badgeWarning : classes.badgeError
                  }}
                  badgeContent={
                    isWarning ? <WarningIcon className={classes.warningColor}></WarningIcon> : '!'
                  }
                  invisible={isVisible ? false : true}
                >
                  <TextField
                    {...field}
                    style={{ width: '100%' }}
                    id={summaryField.id}
                    label={label ?? ''}
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline={multiline}
                    rows="3"
                    error={Boolean(error)}
                    disabled={readOnly ?? false}
                    className={readOnly ? classes.readOnly : ''}
                    InputProps={{
                      readOnly: readOnly ?? false,
                      classes: {
                        notchedOutline: !Boolean(error)
                          ? getColors(isDirty, typeMsg, error).inputColor
                          : ''
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        root: !Boolean(error) ? getColors(isDirty, typeMsg, error).color : '',
                        focused: !Boolean(error) ? getColors(isDirty, typeMsg, error).color : ''
                      }
                    }}
                    onClick={e =>
                      onChangeSummaryField({
                        id: summaryField.id,
                        type: 'header',
                        ocr: null,
                        shouldSetValue: false,
                        page: value?.[index]?.page
                      })
                    }
                    inputRef={element => setTextInputRef(element, summaryField.id)}
                  />
                </Badge>
              </Tooltip>
            </Grid>
            {typeMsgFirst === 'warning' && !Boolean(error) && !isDirty ? (
              <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                  style={{ padding: '0' }}
                  className={classes.buttonSuccess}
                  onClick={() =>
                    isValid || validated
                      ? changeValidationToWarning(initialErrorMessages)
                      : changeValidationToSuccess()
                  }
                >
                  {isValid || validated ? (
                    <CheckCircleIcon className={classes.successColor} />
                  ) : (
                    <CheckCircleOutlineIcon className={classes.successColor} />
                  )}
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
        )
      }}
      name={`summaryFields.${index}.value`}
      control={control}
      rules={generateJsonValidation(rules, summaryField.type)}
    />
  )
}
