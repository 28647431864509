import csc from 'countries-states-cities'
import React, { useState } from 'react'

import AutoCompleteAsyncHeader from './AutoCompleteAsyncHeader'

const AutoCompleteCountries = props => {
  const { headerField, headerFieldsActions } = props

  const { deleteErrorHeader } = headerFieldsActions

  const [countries] = useState(() => {
    const dictionary = {}
    const countriesFilter = []
    const countriesCopy = csc.getAllCountries()
    // eslint-disable-next-line
    for (const country of countriesCopy) {
      if (!dictionary[country.currency]) {
        countriesFilter.push({ label: country.currency, value: country.currency })
        dictionary[country.currency] = country.currency
      }
    }
    return countriesFilter
  })

  function onChangeAutoComplete() {
    deleteErrorHeader({
      field: headerField.field
    })
  }

  return (
    <AutoCompleteAsyncHeader
      {...props}
      isOnlyLabel={true}
      options={countries}
      isLoading={false}
      onChangeAutoComplete={onChangeAutoComplete}
    />
  )
}

export default AutoCompleteCountries
