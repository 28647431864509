import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import { CircularProgress } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'

// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'
import CheckIcon from '@material-ui/icons/Check'
import Add from '@material-ui/icons/PlaylistAdd'
import AssessmentIcon from '@material-ui/icons/Assessment'
import CheckBox from '@material-ui/icons/CheckBox'
import Cancel from '@material-ui/icons/Cancel'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NotInterestedIcon from '@material-ui/icons/NotInterested';

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import NavPills from 'components/NavPills/NavPills.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'

//Constants
import { aribaApiTypes } from 'utils/Constants'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js'

// functions
import { statusColor } from 'utils/functions'

const useStyles = makeStyles(styles)

function jsUcfirst (string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

export default function IntelligenceAriba (props) {
  const classes = useStyles()

  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: true
    },
    {
      field: 'load',
      title: 'Load'
    },
    // {
    //   field: 'destination',
    //   title: 'Destination'
    // },
    {
      field: 'type',
      title: 'Type'
    },
    {
      field: 'startDate',
      title: 'Start Date'
    },
    {
      field: 'creationDate',
      title: 'Creation Date',
      defaultSort: 'desc'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: statusColor(rowData.status) }}>{rowData.status}</p>
      )
    },
    {
      field: 'updateFrequency',
      title: 'Frequency'
    }
  ]

  let breadcrumbViews = [
    {
      name: 'Relish Intelligence',
      url: '/admin/intelligence/index',
      icon: AssessmentIcon
    },
    {
      name: jsUcfirst(props.typeApp) + ' Connection',
      url: `/admin/intelligence/${props.typeApp}/${props.selectedAppId}`,
      icon: CheckIcon
    }
  ]

  function showModal () {
    return (
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        open={props.showModal === true}
        keepMounted
        onClose={() => props.setShowModal(false)}
        aria-labelledby='classic-modal-slide-title'
        aria-describedby='classic-modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Error connection details</h4>
        </DialogTitle>
        <DialogContent
          id='classic-modal-slide-description'
          className={classes.modalBody}
        >
          {props.aribaConnectionMessage ? props.aribaConnectionMessage : null}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => props.setShowModal(false)}
            color='danger'
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  function returnConfigurationTab () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h5 className={classes.sectionTitle}>
              Intelligence Loads
              <Tooltip title='Add Load'>
                <Button
                  justIcon
                  round
                  simple
                  color='primary'
                  onClick={() => props.onGoToLoads()}
                >
                  <Add className={classes.icons} />
                </Button>
              </Tooltip>
              {props.loads ? (
                <Fab
                  size='small'
                  color='primary'
                  className={classes.floatRight + ' ' + classes.bgColorPrimary}
                  onClick={() => props.onRefreshLoads()}
                >
                  <RefreshRoundedIcon fontSize='small' />
                </Fab>
              ) : null}
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12}>
            {props.loads ? (
              <GridContainer justify='center'>
                {props.loadIsLoading ? (
                  <CircularProgress />
                ) : (
                  <GridItem xs={12} sm={12} md={12}>
                    <MaterialTable
                      columns={columns}
                      data={props.rows}
                      actions={[
                        {
                          icon: 'edit',
                          tooltip: 'Edit Load',
                          onClick: (event, rowData) =>
                            props.history.push(
                              `/admin/intelligence/load/${props.selectedAppId}/${props.typeApp}/${rowData.id}`
                            )
                        },
                        rowData => ({
                          icon: 'chevron_right',
                          tooltip: 'Start Load',
                          onClick: (event, rowData) =>
                            props.onStartLoad(rowData.id),
                          disabled: rowData.status !== 'Created' && rowData.status !== 'Stopped'
                        }),
                        rowData => ({
                          icon: () => <NotInterestedIcon  />,
                          tooltip: 'Stop Load',
                          onClick: (event, rowData) =>
                            props.onStopLoad(rowData.id),
                          disabled: (rowData.status === 'Created' || rowData.status === 'Stopped')
                        }),
                        {
                          icon: 'delete',
                          tooltip: 'Delete Load',
                          onClick: (event, rowData) =>
                            props.onDeleteLoad(rowData.id)
                        },
                        {
                          icon: 'view_column',
                          tooltip: 'Load Logs',
                          onClick: (event, rowData) =>
                            props.history.push(
                              `/admin/intelligence/logs/${props.selectedAppId}/${props.typeApp}/${rowData.id}`
                            )
                        }
                      ]}
                      options={{
                        actionsColumnIndex: -1,
                        sorting: true,
                        showTitle: false,
                        emptyRowsWhenPaging: false
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
            ) : (
              <p>Add a new load...</p>
            )}
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }

  function returnConnectionTab () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <h5 className={classes.sectionTitle}>SAP Ariba</h5>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <FormLabel className={classes.inputLabelHorizontal}>
                  Realm
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  success={props.config.realm.state === 'success'}
                  error={props.config.realm.state === 'error'}
                  labelText={
                    <span>
                      Realm{' '}
                      <small>
                        {props.config.realm.state === 'error'
                          ? '(required)'
                          : '*'}{' '}
                      </small>
                    </span>
                  }
                  id='realm'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event =>
                      props.onFieldChange(
                        null,
                        null,
                        'realm',
                        event.target.value
                      ),
                    value: props.config.realm.value
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {props.config.apis
              ? Object.keys(props.config.apis).map(item => (
                  <Accordion key={item}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Typography className={classes.heading}>
                        {aribaApiTypes[item]}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <GridContainer>
                        {Object.keys(props.config.apis[item].inputs).map(
                          input => (
                            <GridItem
                              key={props.config.apis[item].inputs[input].key}
                              md={12}
                            >
                              <GridContainer>
                                <GridItem xs={12} sm={6}>
                                  <FormLabel
                                    className={classes.inputLabelHorizontal}
                                  >
                                    {
                                      props.config.apis[item].inputs[input]
                                        .label
                                    }
                                  </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                  <CustomInput
                                    success={
                                      props.config.apis[item].inputs[input]
                                        .state === 'success'
                                    }
                                    error={
                                      props.config.apis[item].inputs[input]
                                        .state === 'error'
                                    }
                                    labelText={
                                      <span>
                                        {props.config.apis[item].inputs[input]
                                          .label + ' '}
                                        <small>
                                          {props.config.apis[item].inputs[input]
                                            .state === 'error'
                                            ? '(required)'
                                            : '*'}{' '}
                                        </small>
                                      </span>
                                    }
                                    id={
                                      props.config.apis[item].inputs[input].key
                                    }
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      type: 'password',
                                      value:
                                        props.config.apis[item].inputs[input]
                                          .value,
                                      onChange: event =>
                                        props.onFieldChange(
                                          item,
                                          props.config.apis[item].inputs[input],
                                          props.config.apis[item].inputs[input]
                                            .key,
                                          event.target.value
                                        )
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          )
                        )}
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            color={
                              props.config.apis[item].aribaConnection !==
                              undefined
                                ? props.config.apis[item].aribaConnection
                                  ? 'info'
                                  : 'danger'
                                : 'facebook'
                            }
                            onClick={() => props.onCheckAribaConnection(item)}
                            round
                            className={classes.documentation}
                            disabled={
                              props.config.apis[item].aribaConnectionTesting
                            }
                          >
                            {props.config.apis[item].aribaConnection !==
                            undefined ? (
                              props.config.apis[item].aribaConnection ? (
                                <div>
                                  <CheckBox className={classes.icons} />{' '}
                                  Connected
                                </div>
                              ) : (
                                <div>
                                  <Cancel className={classes.icons} />{' '}
                                  Connection Error
                                </div>
                              )
                            ) : (
                              <div>
                                {props.config.apis[item].aribaConnectionTesting
                                  ? 'Loading...'
                                  : 'Test Connectivity'}{' '}
                              </div>
                            )}
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          {props.config.apis[item].aribaConnectionMessage ? (
                            <Button
                              color='danger'
                              simple
                              round
                              className={classes.documentation}
                              onClick={() =>
                                props.setShowModal(true) /
                                props.setAribaConnectionMessage(
                                  props.config.apis[item].aribaConnectionMessage
                                )
                              }
                            >
                              Error details
                            </Button>
                          ) : null}
                        </GridItem>
                      </GridContainer>
                    </AccordionDetails>
                  </Accordion>
                ))
              : null}
          </GridItem>
        </GridContainer>
        <GridContainer justify='center'>
          <Button
            disabled={props.isLoadingSave}
            variant='outlined'
            color='primary'
            onClick={() => props.onConfigSave()}
          >
            {!props.isLoadingSave ? 'Save' : 'Loading...'}
          </Button>
        </GridContainer>
        {showModal()}
      </GridItem>
    )
  }

  function returnTabsToDisplay () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h3 className={classes.formHeader}>Requisitions Intelligence</h3>
          </CardHeader>
          <CardBody>
            <NavPills
              action={actions => {
                if (actions) {
                  props.updateHeightFunction(actions.updateHeight)
                }
              }}
              animateHeight={false}
              color='primary'
              tabs={[
                {
                  tabButton: 'Configuration',
                  tabContent: returnConfigurationTab()
                },
                {
                  tabButton: 'Ariba Connection',
                  tabContent: returnConnectionTab()
                }
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    )
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={3}>
        <Card className={classes.appBar}>
          <CardHeader>Application List</CardHeader>
          <CardBody>
            <List>
              {props.appList.map((prop, key) => {
                return (
                  <ListItem
                    className={classes.listItem}
                    button
                    selected={props.selectedAppId === prop.env}
                    key={key}
                    onClick={() => props.onAppChanged(prop.env)}
                  >
                    <ListItemAvatar>
                      {props.selectedAppId === prop.env ? (
                        <Avatar>
                          <TouchApp />
                        </Avatar>
                      ) : (
                        <Avatar className={classes.clearAvatar}></Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={prop.appName}
                      secondary={`Type: ${prop.type}`}
                    />
                  </ListItem>
                )
              })}
            </List>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={9}>
        <Breadcrumbs views={breadcrumbViews} />
        {props.formIsLoading ? (
          <Card>
            <GridContainer justify='center'>
              <div className={classes.circularProgress}>
                <CircularProgress color='inherit' />
              </div>
            </GridContainer>
          </Card>
        ) : (
          returnTabsToDisplay()
        )}
      </GridItem>
      <Snackbar
        place='br'
        color='success'
        icon={AddAlert}
        message={props.validationMessage}
        open={props.submitSuccess}
        closeNotification={() => props.setSubmitSuccess(false)}
        close
      />
      <Snackbar
        place='bl'
        color='danger'
        icon={AddAlert}
        message={props.validationMessage}
        open={props.createError}
        closeNotification={() => props.setCreateError(false)}
        close
      />
    </GridContainer>
  )
}
