import React, { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { makeStyles } from '@material-ui/core/styles'
import { v4 as uuidv4 } from 'uuid'

// Material UI
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

// Icons
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Cancel from '@material-ui/icons/Cancel'

// Form
import { useForm, Controller, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

// Constants
import { fieldsByType, InputDefault } from 'utils/Constants'

// Inputs
import { TextInput } from '../Inputs/TextInput'
import { SelectInput } from '../Inputs/SelectInput'
import { FileInput } from '../Inputs/FileInput'
import { BooleanInput } from '../Inputs/BooleanInput'
import { SharePointInput } from '../Inputs/SharePointInput'

// Styles
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle'

// Hooks
import {
  useUpdateAppConnection,
  useValidateAppConnection,
  useValidateSftpAppConnection,
  useValidateMsSqlAppConnection
} from 'hooks/useInsights'

// Components
import InfoModal from '../Components/InfoModal'

const useStyles = makeStyles(styles)

const schema = yup.object().shape({
  name: yup
    .string()
    .min(1)
    .required(),
  type: yup
    .string()
    .min(1)
    .required(),
  zipFile: yup.boolean().optional(),
  fileExtension: yup.string().when('type', (type, schema) => {
    switch (type) {
      case 's3':
      case 'sharepoint':
      case 'sftp':
        return schema.min(1).required()
      default:
        return schema.optional()
    }
  }),
  path: yup.string().when('type', (type, schema) => {
    switch (type) {
      case 's3':
      case 'sharepoint':
      case 'sftp':
        return schema.min(1).required()
      default:
        return schema.optional()
    }
  }),
  siteUrl: yup.string().when('type', (type, schema) => {
    switch (type) {
      case 'sharepoint':
        return schema.min(1).required()
      default:
        return schema.optional()
    }
  }),
  dbPort: yup.string().when('type', (type, schema) => {
    switch (type) {
      case 'sapHana':
      case 'msSql':
        return schema.min(1).required()
      default:
        return schema.optional()
    }
  }),
  dbUser: yup.string().when('type', (type, schema) => {
    switch (type) {
      case 'sapHana':
      case 'msSql':
        return schema.min(1).required()
      default:
        return schema.optional()
    }
  }),
  dbPassword: yup.string().when('type', (type, schema) => {
    switch (type) {
      case 'sapHana':
      case 'msSql':
        return schema.min(1).required()
      default:
        return schema.optional()
    }
  }),
  //S3
  accessKeyId: yup.string().when('type', (type, schema) => {
    return type === 's3' ? schema.min(1).required() : schema.optional()
  }),
  secretAccessKey: yup.string().when('type', (type, schema) => {
    return type === 's3' ? schema.min(1).required() : schema.optional()
  }),
  bucketName: yup.string().when('type', (type, schema) => {
    return type === 's3' ? schema.min(1).required() : schema.optional()
  }),
  //SharePoint
  tenantId: yup.string().when('type', (type, schema) => {
    return type === 'sharepoint' ? schema.min(1).required() : schema.optional()
  }),
  //SapHana
  dbHost: yup.string().when('type', (type, schema) => {
    return type === 'sapHana' ? schema.min(1).required() : schema.optional()
  }),
  dbSchema: yup.string().when('type', (type, schema) => {
    return type === 'sapHana' ? schema.min(1).required() : schema.optional()
  }),
  //MsSql
  dbName: yup.string().when('type', (type, schema) => {
    return type === 'msSql' ? schema.min(1).required() : schema.optional()
  }),
  dbServer: yup.string().when('type', (type, schema) => {
    return type === 'msSql' ? schema.min(1).required() : schema.optional()
  }),
  dbDomain: yup.string().optional(),
  //SFTP
  host: yup.string().when('type', (type, schema) => {
    return type === 'sftp' ? schema.min(1).required() : schema.optional()
  }),
  user: yup.string().when('type', (type, schema) => {
    return type === 'sftp' ? schema.min(1).required() : schema.optional()
  }),
  port: yup.string().when('type', (type, schema) => {
    return type === 'sftp' ? schema.min(1).required() : schema.optional()
  }),
  encryptionMethod: yup.string().when('type', (type, schema) => {
    return type === 'sftp' ? schema.min(1).required() : schema.optional()
  }),
  encryptionFile: yup.string().when('type', (type, schema) => {
    return type === 'sftp' ? schema.min(1).required() : schema.optional()
  }),
  singleConnection: yup.string().when('type', (type, schema) => {
    return type === 'sftp' ? schema.min(1).required() : schema.optional()
  })
})

export default function ConnectionsForm(props) {
  const { connectionObj, setMode, appList, app, setCreateError, setSubmitSuccess } = props
  const classes = useStyles()
  const queryClient = useQueryClient()
  const [connectionForm] = useState(connectionObj)
  const [connectionConnected, setConnectionConnected] = useState(undefined)
  const [connectionMessage, setConnectionMessage] = useState('')
  const [showModal, setShowModal] = useState({ open: false, message: '' })

  useEffect(() => {
    if (connectionForm.connectionId) {
      setConnectionConnected(true)
    }
  }, [connectionForm])

  const {
    mutate: updateAppConnection,
    isLoading: isLoadingUpdateConnection
  } = useUpdateAppConnection({
    appId: app.data.appId
  })

  const {
    mutate: validateAppConnection,
    isLoading: isLoadingValidateConnection
  } = useValidateAppConnection({
    appId: app.data.appId
  })

  const {
    mutate: validateSftpAppConnection,
    isLoading: isLoadingSftpValidateConnection
  } = useValidateSftpAppConnection({
    appId: app.data.appId
  })

  const {
    mutate: validateMsSqlAppConnection,
    isLoading: isLoadingMsSqlValidateConnection
  } = useValidateMsSqlAppConnection({
    appId: app.data.appId
  })

  function createAppConnectionBody(data) {
    if (!data.connectionId) {
      data.connectionId = uuidv4()
    }
    const dataConnection = { connectionId: data.connectionId, name: data.name, type: data.type }
    switch (data.type) {
      case 's3':
        dataConnection.s3Params = {
          bucketName: data.bucketName,
          path: data.path,
          fileExtension: data.fileExtension,
          zipFile: data.zipFile
        }
        if (data.accessKeyId !== InputDefault) {
          dataConnection.s3Params.accessKeyId = data.accessKeyId
        }
        if (data.secretAccessKey !== InputDefault) {
          dataConnection.s3Params.secretAccessKey = data.secretAccessKey
        }
        break
      case 'sharepoint':
        dataConnection.sharepointParams = {
          siteUrl: data.siteUrl,
          path: data.path,
          fileExtension: data.fileExtension,
          zipFile: data.zipFile
        }
        if (data.tenantId !== InputDefault) {
          dataConnection.sharepointParams.tenantId = data.tenantId
        }
        if (data.clientId !== InputDefault) {
          dataConnection.sharepointParams.clientId = data.clientId
        }
        if (data.appSecret !== InputDefault) {
          dataConnection.sharepointParams.appSecret = data.appSecret
        }
        break
      case 'sftp':
        dataConnection.sftpParams = {
          host: data.host,
          port: data.port,
          user: data.user,
          encryptionMethod: data.encryptionMethod,
          encryptionFile: data.encryptionFile,
          encryptionFileObj: data.encryptionFileObj,
          useSingleConnection: data.useSingleConnection,
          path: data.path,
          fileExtension: data.fileExtension,
          zipFile: data.zipFile
        }
        break
      case 'msSql':
        dataConnection.msSqlParams = {
          dbServer: data.dbServer,
          dbName: data.dbName,
          dbPassword: data.dbPassword,
          dbPort: parseInt(data.dbPort),
          dbUser: data.dbUser
        }
        if (data.dbDomain) {
          dataConnection.msSqlParams.dbDomain = data.dbDomain
        }
        break
      default:
        break
    }
    return dataConnection
  }

  function onSubmitHook(data) {
    data = createAppConnectionBody(data)
    updateAppConnection(
      { data },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['Apps', app.data.appId])
        },
        onSuccess: response => {
          if (response.status === 200) {
            setSubmitSuccess({ message: 'Saved', isOpen: true })
            setMode('list')
            appList.refetch({ throwOnError: true })
            app.refetch({ throwOnError: true })
          }
        },
        onError: error => {
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    setValue,
    getValues
  } = useForm({
    mode: 'all',
    defaultValues: connectionForm,
    resolver: yupResolver(schema),
    shouldUnregister: false
  })

  useEffect(() => {
    if (isDirty) {
      setConnectionConnected(undefined)
      setConnectionMessage('')
    }
  }, [isDirty, setConnectionConnected, setConnectionMessage])

  function checkConnection() {
    const data = createAppConnectionBody(getValues())
    switch (data.type) {
      case 'sftp':
        validateSftpAppConnection(
          { data },
          {
            onSettled: () => {
              queryClient.invalidateQueries(['Apps', app.data.appId])
            },
            onSuccess: response => {
              if (response.data.valid) {
                setConnectionConnected(true)
              } else {
                let defaultError = {
                  error: 'invalid_request',
                  error_description: 'Connection Error'
                }
                defaultError = JSON.stringify(defaultError, null, '\t')
                setConnectionConnected(false)
                setConnectionMessage(JSON.stringify(response.data.data) || defaultError)
              }
            },
            onError: error => {
              setCreateError({
                message: error.response?.data?.message ?? 'Something went wrong, try again later',
                isOpen: true,
                color: 'danger'
              })
            }
          }
        )
        break
      case 'msSql':
        validateMsSqlAppConnection(
          { data },
          {
            onSettled: () => {
              queryClient.invalidateQueries(['Apps', app.data.appId])
            },
            onSuccess: response => {
              if (response.data.valid) {
                setConnectionConnected(true)
              } else {
                let defaultError = {
                  error: 'invalid_request',
                  error_description: 'Connection Error'
                }
                defaultError = JSON.stringify(defaultError, null, '\t')
                setConnectionConnected(false)
                setConnectionMessage(JSON.stringify(response.data.data) || defaultError)
              }
            },
            onError: error => {
              setCreateError({
                message: error.response?.data?.message ?? 'Something went wrong, try again later',
                isOpen: true,
                color: 'danger'
              })
            }
          }
        )
        break
      case 'sharepoint':
      case 's3':
        validateAppConnection(
          { data },
          {
            onSettled: () => {
              queryClient.invalidateQueries(['Apps', app.data.appId])
            },
            onSuccess: response => {
              if (response.data.valid) {
                setConnectionConnected(true)
              } else {
                let defaultError = {
                  error: 'invalid_request',
                  error_description: 'Connection Error'
                }
                defaultError = JSON.stringify(defaultError, null, '\t')
                setConnectionConnected(false)
                setConnectionMessage(JSON.stringify(response.data.data) || defaultError)
              }
            },
            onError: error => {
              setCreateError({
                message: error.response?.data?.message ?? 'Something went wrong, try again later',
                isOpen: true,
                color: 'danger'
              })
            }
          }
        )
        break
      default:
        break
    }
  }

  function isError(field) {
    if (errors[field]) {
      return true
    }
    return false
  }

  const type = useWatch({
    control,
    name: 'type',
    defaultValue: connectionObj.type
  })

  useEffect(() => {
    connectionObj.type = type
    reset(connectionObj)
  }, [type, reset, connectionObj])

  function typeForm() {
    let fields = []
    if (!type || type === '') {
      return
    }
    fields = fieldsByType[type]

    return (
      <Grid container justifyContent="center" spacing={3}>
        {fields.map(field => inputs(field))}
      </Grid>
    )
  }

  function inputs(field) {
    let jsxElements = []
    switch (field.type) {
      case 'boolean':
        jsxElements.push(
          <BooleanInput key={field.key} name={field.key} control={control} label={field.label} />
        )
        break
      case 'select':
        jsxElements.push(
          <SelectInput
            key={field.key}
            name={field.key}
            control={control}
            label={field.label}
            values={field.values}
          />
        )
        break
      case 'sp':
        jsxElements.push(
          <SharePointInput
            key={field.key}
            name={field.key}
            getValues={getValues}
            setValue={setValue}
          />
        )
        break
      case 'file':
        const conditional = getValues(field.inputCondition)
        if (field.conditional && conditional === field.valueCondition) {
          jsxElements.push(
            <FileInput
              key={field.key}
              name={field.key}
              control={control}
              label={field.label}
              type={field.type}
              values={field.values}
            />
          )
        }
        break
      case 'text':
      case 'password':
      case 'number':
        jsxElements.push(
          <TextInput
            key={field.key}
            name={field.key}
            control={control}
            label={field.label}
            type={field.type}
          />
        )
        break
      default:
        break
    }

    return jsxElements.map(jsxElement => jsxElement)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHook)}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Controller
            render={({ field }) => (
              <TextField label="Connection Name*" fullWidth error={isError('name')} {...field} />
            )}
            name="name"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <FormControl fullWidth className={classes.formField} error={isError('type')}>
            <InputLabel id="type-label" className={classes.inputLabel}>
              Connection Type*
            </InputLabel>
            <Controller
              render={({ field }) => (
                <Select labelId="type-label" label="Type" {...field}>
                  <MenuItem disabled>Select Type</MenuItem>
                  {/* <MenuItem value={'sharepoint'}>Microsoft SharePoint</MenuItem> */}
                  <MenuItem value={'s3'}>Amazon S3</MenuItem>
                  <MenuItem value={'msSql'}>Microsoft SQL</MenuItem>
                  {/* <MenuItem value={'sftp'}>SFTP</MenuItem> */}
                </Select>
              )}
              name="type"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h5 className={classes.sectionTitle}>Parameters</h5>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {typeForm()}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
          <Tooltip title="Check connection">
            <Button
              color={
                connectionConnected !== undefined
                  ? connectionConnected
                    ? 'primary'
                    : 'secondary'
                  : 'default'
              }
              size="small"
              onClick={() => checkConnection()}
              variant="contained"
              disabled={
                isLoadingValidateConnection ||
                isLoadingSftpValidateConnection ||
                isLoadingMsSqlValidateConnection
              }
            >
              {connectionConnected !== undefined ? (
                connectionConnected ? (
                  <>
                    <CheckBoxIcon /> Connected
                  </>
                ) : (
                  <>
                    <Cancel /> Connection Error
                  </>
                )
              ) : isLoadingValidateConnection ||
                isLoadingSftpValidateConnection ||
                isLoadingMsSqlValidateConnection ? (
                'Loading...'
              ) : (
                'Test Connectivity'
              )}
            </Button>
          </Tooltip>
        </Grid>
        {connectionMessage && !connectionConnected ? (
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
            <Tooltip title="View connection error details">
              <Button
                className={classes.documentation}
                onClick={() => setShowModal({ open: true, message: connectionMessage })}
              >
                Error details
              </Button>
            </Tooltip>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
          <Button
            className={classes.bgColorPrimary}
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              isLoadingUpdateConnection ||
              !connectionConnected ||
              isLoadingValidateConnection ||
              isLoadingSftpValidateConnection ||
              isLoadingMsSqlValidateConnection
            }
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setMode('list')}
            className={classes.marginLeft}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      <InfoModal
        showModal={showModal.open}
        onClose={() => setShowModal({ open: false, message: '' })}
        warningMessage={showModal.message}
        title="Error connection details"
      />
    </form>
  )
}
