const userCreate = {
  firstName: {
    type: 'string',
    required: true,
    length: {
      min: 1,
      max: 256
    },
    displayName: 'First Name'
  },
  lastName: {
    type: 'string',
    required: true,
    length: {
      min: 1,
      max: 256
    },
    displayName: 'Last Name'
  },
  email: {
    type: 'string',
    required: true,
    isEmail: true,
    displayName: 'Email'
  },
  uniqueName: {
    type: 'string',
    required: true,
    displayName: 'Unique Name'
  },
  company: {
    type: 'string',
    required: true,
    displayName: 'Company'
  },
  enabled: {
    type: 'boolean',
    required: true,
    displayName: 'Enabled'
  },
  language: {
    type: 'string',
    required: true,
    displayName: 'Language'
  },
  locale: {
    type: 'string',
    required: true,
    displayName: 'Locale'
  },
  country: {
    type: 'string',
    required: true,
    displayName: 'Country'
  },
  role: {
    type: 'string',
    required: true,
    displayName: 'Role'
  },
  timezoneName: {
    type: 'string',
    required: false,
    displayName: 'Timezone Name'
  },
  password: {
    type: 'string',
    required: true,
    isPassword: true,
    length: {
      min: 12,
      max: 256
    },
    displayName: 'Password'
  },
  type: {
    type: 'string',
    required: true,
    displayName: 'User type'
  }
}

const userUpdate = {
  firstName: {
    type: 'string',
    required: true,
    length: {
      min: 1,
      max: 256
    },
    displayName: 'First Name'
  },
  lastName: {
    type: 'string',
    required: true,
    length: {
      min: 1,
      max: 256
    },
    displayName: 'Last Name'
  },
  enabled: {
    type: 'boolean',
    required: true,
    displayName: 'Enabled'
  },
  language: {
    type: 'string',
    required: true,
    displayName: 'Language'
  },
  locale: {
    type: 'string',
    required: true,
    displayName: 'Locale'
  },
  country: {
    type: 'string',
    required: true,
    displayName: 'Country'
  },
  timezone: {
    type: 'string',
    required: false,
    displayName: 'Timezone'
  },
  role: {
    type: 'string',
    required: true,
    displayName: 'Role'
  },
  type: {
    type: 'string',
    required: true,
    displayName: 'User type'
  }
}

export { userCreate, userUpdate }
