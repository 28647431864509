import React from 'react'

import { Badge, Box, TextField, Tooltip } from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

import { CircularProgress } from '@material-ui/core'
import { errorTypes, getError } from './useHeader'

const DefaultValuesLineItem = props => {
  const {
    handleChange,
    value,
    lineId,
    readOnly,
    optionsValues,
    isLoading,
    label,
    field,
    filter,
    setFilter,
    lines,
    lineItemsActions
  } = props

  const { getColors, classes } = lineItemsActions

  const lineErrors = lines.errors.find(x => x.id === lineId)

  const fieldError = lineErrors?.errors?.find(x => x.field === field.standardField)
  const errorData = getError(fieldError)
  let typeMessage = ''
  const criticalType = fieldError?.details?.find(x => x.criticalityType === 'critical')

  if (criticalType) {
    typeMessage = 'critical'
  }

  const isError = typeMessage === errorTypes.critical
  const errorMessage = errorData ? errorData?.message : ''
  const isVisible = Boolean(errorData)

  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Tooltip
        classes={{
          arrow: isError ? classes.errorColor : '',
          tooltip: isError ? classes.tooltipError : ''
        }}
        title={isVisible ? errorMessage : ''}
        arrow
        placement="right"
      >
        <Badge
          classes={{
            badge: isError ? classes.badgeError : ''
          }}
          badgeContent={isError ? '!' : null}
          invisible={isVisible ? false : true}
        >
          <Autocomplete
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            value={value}
            onChange={(event, newValue) => {
              handleChange(newValue)
            }}
            inputValue={filter}
            onInputChange={(event, newInputValue) => {
              setFilter(newInputValue)
            }}
            options={optionsValues ?? []}
            id="default-input-values"
            style={{ width: 300 }}
            disabled={readOnly}
            className={readOnly ? classes.readOnly : ''}
            getOptionSelected={(option, value) => option.value === value}
            getOptionLabel={option => option.label || option}
            renderOption={option => (
              <React.Fragment>
                {option.label ? `${option.value} (${option.label})` : option.value}
              </React.Fragment>
            )}
            filterOptions={x => x}
            loading={isLoading}
            renderInput={params => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                fullWidth
                multiline
                error={isError}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                  classes: {
                    notchedOutline: !isError ? getColors(errorData).inputColor : ''
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: !isError ? getColors(errorData).color : '',
                    focused: !isError ? getColors(errorData).color : ''
                  }
                }}
              />
            )}
          />
        </Badge>
      </Tooltip>
    </Box>
  )
}

export default DefaultValuesLineItem
