import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import moment from "moment";

//Services
import {
  getInvoiceConfigurationsByApp,
  getInvoiceAppConfigurationByAppAndId,
  editInvoiceAppConfigurationByAppAndType,
  validateInvoiceAppConnectionByType,
  getInvoiceAppLoadsByAppId,
  setStartInvoiceLoad,
  setStopInvoiceLoad,
  setDeleteInvoiceLoad,
  getAribaCertificate
} from 'services/apiApps'
import { checkExpiration } from 'utils/functions'

//Constants
import {
  loadStatus,
  typeLoad,
  InputDefault,
  updateFrequency
} from 'utils/Constants'

//Views
import InvoiceAribaView from './InvoiceAribaView'

//Models
import { aribaConnection } from 'models/nitorInsightsModel'
import { validateProperty, validateObject } from 'utils/validator'

import { updateHeightFunction } from 'store/AppState'

let _isMounted = false
let appId
let typeApp
moment.locale(window.navigator.userLanguage || window.navigator.language)

const newConfig = {
  appId: '',
  realm: '',
  apiKey: '',
  clientId: '',
  secret: '',
  enabled: false,
  authenticationType: 'sharedSecret',
  authenticationKey: '',
  siteRegion: 's1',
  certificate: '',
  itk: false
}

const newConfigState = {
  appId: '',
  realm: '',
  apiKey: '',
  clientId: '',
  secret: '',
  authenticationType: '',
  authenticationKey: '',
  siteRegion: ''
}

const paramsType = {
  analytics: 'analyticalReportingApi',
  procurement: 'operationalReportingProcurementApi',
  sourcing: 'operationalReportingSourcingApi',
  upstream: 'sourcingApi',
  downstream: 'buyerItkApi',
  sourcingMasterData: 'masterDataRetrievalSourcingApi',
  procurementMasterData: 'masterDataRetrievalProcurementApi'
}

async function getAppList(props) {
  let response = await getInvoiceConfigurationsByApp()
  const data = checkExpiration(props.enabledApps['invoiceAI'])
  props.setExpirationData(data)

  if (response.success) {
    if (_isMounted) {
      props.setAppList(response.data)
      props.setPageIsLoading(false)
    }
  }
}

async function getAppDetails(props, id, typeApp) {
  props.setFormIsLoading(true)
  props.setSelectedAppId(id)
  let type = paramsType[typeApp]
  const appDetails = await getInvoiceAppConfigurationByAppAndId(id)
  if (appDetails.success) {
    const insightsAppId = appDetails.data.data.insightsAppId
    props.setInsightsAppId(insightsAppId)
    await getAppLoads(props, insightsAppId, typeApp)
    const params = appDetails.data.data.params
      ? appDetails.data.data.params.ariba[type]
      : undefined
    const configurations = {
      realm: '',
      appId: appDetails.data.data.appId,
      apiKey: '',
      clientId: '',
      secret: '',
      enabled: appDetails.data.data.enabled,
      authenticationKey: '',
      authenticationType: 'sharedSecret',
      certificate: '',
      siteRegion: 's1',
      itk: false
    }
    if (typeApp === 'upstream' || typeApp === 'downstream') {
      configurations.itk = true
    }
    props.setAribaConnectionConnected(undefined)
    props.setAribaConnectionExist(undefined)
    if (params) {
      props.setAribaConnectionConnected(true)
      props.setAribaConnectionExist(true)
      configurations.realm = params.realm || ''
      if (configurations.itk) {
        configurations.authenticationType = params.authenticationType
        configurations.siteRegion = params.siteRegion || 's1'
        if (params.authenticationType === 'certificate') {
          const response = await getAribaCertificate()
          if (response.success) {
            configurations.certificate = response.data
          }
        } else {
          configurations.authenticationKey = params.authenticationKey
        }
      } else {
        configurations.apiKey = params.apiKey
        configurations.clientId = params.clientId
        configurations.secret = params.secret
      }
    }

    if (_isMounted) {
      props.setConfig(configurations)
      props.setConfigState({ ...newConfigState })
    }
  }
  props.setFormIsLoading(false)
}

async function getAppLoads(props, id, type) {
  const appLoads = await getInvoiceAppLoadsByAppId('invoiceAI', id, type)
  if (appLoads.success) {
    let loads = null
    if (appLoads.data.length > 0) {
      loads = appLoads.data
      formatLoads(loads, props)
    }
    if (_isMounted) {
      props.setLoads(loads)
    }
  }
}

function formatLoads(loads, props) {
  const rows = loads.map(load => {
    return {
      id: load.idLoad,
      load: load.params.name,
      destination: load.params.destination.nameConnection,
      type: typeLoad[load.params.type],
      startDate: moment(load.params.startDate).format('MM/DD/YYYY'),
      creationDate: load.params.creationDate,
      updateFrequency: updateFrequency[load.params.updateFrequency],
      status: loadStatus[load.status],
      statusProgress: load.statusProgress
    }
  })
  props.setRows([...rows])
}

async function onConfigSubmit(props) {
  props.setFormIsLoading(true)
  let successMessage

  //Update with the form values
  let appConfiguration = createConfigRequestBody(props.config)
  let response
  response = await editInvoiceAppConfigurationByAppAndType(
    'invoiceAI',
    props.selectedAppId,
    appConfiguration,
    props.typeApp
  )
  successMessage = 'App updated successfully'

  if (response && response.success && _isMounted) {
    await getAppDetails(props, props.selectedAppId, props.typeApp)
    props.setValidationMessage(successMessage)
    props.setSubmitSuccess(true)

    setTimeout(function() {
      if (_isMounted) {
        props.setSubmitSuccess(false)
        props.setFormIsLoading(false)
      }
    }, 5000)
  } else {
    // Show error message
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
    props.setFormIsLoading(false)
  }
}

function createConfigRequestBody(configuration) {
  let requestBody = { ...configuration }
  delete requestBody.appId
  delete requestBody.certificate

  let checkProps = []
  if (configuration.itk) {
    checkProps = ['authenticationKey']
  } else {
    checkProps = ['apiKey', 'secret', 'clientId']
  }
  let bodyField
  for (bodyField of checkProps) {
    if (requestBody[bodyField] === InputDefault) {
      delete requestBody[bodyField]
    }
  }
  return requestBody
}

async function checkAribaConnection(props) {
  props.setAribaConnectionConnected(undefined)
  props.setAribaConnectionTesting(true)
  props.setAribaConnectionMessage(undefined)

  let connectionData = {}
  let checkProps = []
  if (props.config.itk) {
    checkProps = ['authenticationKey']
    connectionData = {
      authenticationType: props.config.authenticationType,
      authenticationKey: props.config.authenticationKey,
      realm: props.config.realm
    }
  } else {
    checkProps = ['apiKey', 'secret', 'clientId']
    connectionData = {
      secret: props.config.secret,
      clientId: props.config.clientId,
      apiKey: props.config.apiKey,
      realm: props.config.realm
    }
  }

  let bodyField
  for (bodyField of checkProps) {
    if (connectionData[bodyField] === InputDefault) {
      delete connectionData[bodyField]
    }
  }

  let connection = await validateInvoiceAppConnectionByType(
    'invoiceAI',
    props.config.appId,
    connectionData,
    props.typeApp
  )
  let defaultError = {
    error: 'invalid_request',
    error_description: 'Connection Error'
  }
  defaultError = JSON.stringify(defaultError, null, '\t')

  if (connection.success) {
    props.setAribaConnectionConnected(connection.data.valid)
    if (connection.data.valid) {
      props.setAribaConnectionMessage(undefined)
    } else {
      props.setAribaConnectionMessage(
        JSON.stringify(connection.data.data) || defaultError
      )
    }
  } else {
    props.setAribaConnectionConnected(false)
    props.setAribaConnectionMessage(defaultError)
  }
  props.setAribaConnectionTesting(false)
}

async function startLoad(props, idLoad) {
  props.setLoadIsLoading(true)
  let successMessage
  const requestBody = {
    api: props.typeApp,
    enabled: true,
    params: { status: 'active' }
  }
  let response = await setStartInvoiceLoad(
    'invoiceAI',
    requestBody,
    props.insightsAppId,
    idLoad
  )
  successMessage = 'Load started successfully'

  if (response && response.success && _isMounted) {
    await getAppLoads(props, props.insightsAppId, props.typeApp)
    props.setLoadIsLoading(false)
    props.setSubmitSuccess(true)
    props.setValidationMessage(successMessage)

    setTimeout(function() {
      if (_isMounted) {
        props.setSubmitSuccess(false)
      }
    }, 5000)
  } else {
    // Show error message
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
      props.setFormIsLoading(false)
    }
  }
}

async function stopLoad(props, idLoad) {
  props.setLoadIsLoading(true)
  let successMessage
  const requestBody = {
    api: props.typeApp,
    enabled: false,
    params: { status: 'stopped' }
  }
  let response = await setStopInvoiceLoad(
    'invoiceAI',
    requestBody,
    props.insightsAppId,
    idLoad
  )
  successMessage = 'Load stopped successfully'

  if (response && response.success && _isMounted) {
    await getAppLoads(props, props.insightsAppId, props.typeApp)
    props.setLoadIsLoading(false)
    props.setSubmitSuccess(true)
    props.setValidationMessage(successMessage)

    setTimeout(function() {
      if (_isMounted) {
        props.setSubmitSuccess(false)
      }
    }, 5000)
  } else {
    // Show error message
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
      props.setFormIsLoading(false)
    }
  }
}

async function deleteLoad(props, idLoad) {
  props.setLoadIsLoading(true)
  let successMessage
  let response = await setDeleteInvoiceLoad(
    'invoiceAI',
    props.insightsAppId,
    idLoad
  )
  successMessage = 'Load deleted successfully'

  if (response && response.success && _isMounted) {
    await getAppLoads(props, props.insightsAppId, props.typeApp)
    props.setLoadIsLoading(false)
    props.setSubmitSuccess(true)
    props.setValidationMessage(successMessage)

    setTimeout(function() {
      if (_isMounted) {
        props.setSubmitSuccess(false)
      }
    }, 5000)
  } else {
    // Show error message
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
      props.setFormIsLoading(false)
    }
  }
}

async function getCertificate (props) {
  const response = await getAribaCertificate()
  if (response.success) {
    props.config['certificate'] = response.data
    props.setConfig(props.config)
  }
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      updateHeight: state.app.updateHeight,
      enabledApps: state.app.enabledApps
    }),
    { updateHeightFunction }
  ),
  withState('appList', 'setAppList', []),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('insightsAppId', 'setInsightsAppId', null),
  withState('typeApp', 'setTypeApp', null),
  withState('config', 'setConfig', { ...newConfig }),
  withState('configState', 'setConfigState', { ...newConfigState }),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('loadIsLoading', 'setLoadIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('isLoadingSave', 'setIsLoadingSave', false),
  withState(
    'aribaConnectionConnected',
    'setAribaConnectionConnected',
    undefined
  ),
  withState('aribaConnectionExist', 'setAribaConnectionExist', undefined),
  withState('aribaConnectionTesting', 'setAribaConnectionTesting', false),
  withState('aribaConnectionMessage', 'setAribaConnectionMessage', undefined),
  withState('showModal', 'setShowModal', false),
  withState('loads', 'setLoads', null),
  withState('rows', 'setRows', null),
  withState('expirationData', 'setExpirationData', {}),
  withState('certificateIsLoading', 'setCertificateIsLoading', false),
  withHandlers({
    onAppChanged: props => async id => {
      props.history.push('/admin/invoiceAI/configurations')
    },
    onConfigSave: props => async () => {
      let validation = validateObject(aribaConnection, props.config)
      if (!validation.isValid) {
        let field
        for (field in props.config) {
          if (validation.errors[field]) {
            props.configState[field] = 'error'
          }
        }
        props.setConfigState(props.configState)
        props.setAribaConnectionTesting(false)
        return
      }

      props.setIsLoadingSave(true)
      await onConfigSubmit(props)
      props.setConfigState({ ...newConfigState })
      props.setAribaConnectionMessage(undefined)
      props.setAribaConnectionTesting(false)
      props.setIsLoadingSave(false)
    },
    onFieldChange: props => async (field, value) => {
      props.config[field] = value
      props.setConfig(props.config)
      const isValid = validateProperty(aribaConnection, props.config, field)
        .isValid
      if (isValid) {
        props.configState[field] = 'success'
      } else {
        props.configState[field] = 'error'
      }
      props.setConfigState(props.configState)
      props.setAribaConnectionConnected(undefined)
      props.setAribaConnectionExist(undefined)
      props.setAribaConnectionMessage(undefined)
      if (field === 'authenticationType' && value === 'certificate') {
        props.setCertificateIsLoading(true)
        await getCertificate(props)
        props.setCertificateIsLoading(false)
      }
      if (props.config.itk) {
        props.setAribaConnectionConnected(true)
        props.setAribaConnectionMessage(undefined)
      }
    },
    onCheckAribaConnection: props => () => {
      checkAribaConnection(props)
    },
    onStartLoad: props => idLoad => {
      startLoad(props, idLoad)
    },
    onStopLoad: props => idLoad => {
      stopLoad(props, idLoad)
    },
    onDeleteLoad: props => idLoad => {
      deleteLoad(props, idLoad)
    },
    onGoToLoads: props => () => {
      if (props.aribaConnectionExist) {
        props.history.push(
          `/admin/invoiceAI/load/${props.selectedAppId}/${props.typeApp}/`
        )
      } else {
        props.setValidationMessage(
          'Please, create and save a valid Ariba connection.'
        )
        props.setCreateError(true)
      }
    },
    onRefreshLoads: props => async () => {
      props.setLoadIsLoading(true)
      await getAppLoads(props, props.insightsAppId, props.typeApp)
      props.setLoadIsLoading(false)
    }
  }),
  lifecycle({
    componentDidMount() {
      _isMounted = true
      this.props.setPageIsLoading(true)
      appId = this.props.match.params.id
      typeApp = this.props.match.params.type
      this.props.setSelectedAppId(appId)
      this.props.setTypeApp(typeApp)
      getAppDetails(this.props, appId, typeApp)
      getAppList(this.props)
    },
    componentWillUnmount() {
      _isMounted = false
    }
  })
)(InvoiceAribaView)
