import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle'

// Form
import { Controller } from 'react-hook-form'

const useStyles = makeStyles(styles)

export const TextInput = props => {
  const classes = useStyles()
  const { name, control, label, type, multiline, rows, readOnly } = props

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <FormLabel className={classes.inputLabelHorizontal}>{label}*</FormLabel>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <TextField
                  {...field}
                  type={type}
                  multiline={multiline}
                  rows={rows}
                  label={`${label}*`}
                  size="small"
                  fullWidth
                  error={Boolean(error)}
                  inputProps={{
                    disabled: readOnly
                  }}
                />
              )
            }}
            name={name}
            control={control}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
