import axios from 'axios'

import store from 'store'
import { signOut } from 'store/AuthState'
import { AuthService } from 'utils/Constants'
import { createHeaders, getUserTenant } from 'utils/functions'

/*
Get the list of users
*/
async function getListOfuser() {
  try {
    let url = `${AuthService.Endpoint}users`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getListOfuser returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/*
Get the list of users
*/
async function getListOfUsersByTenant(tenantId) {
  try {
    let url = `${AuthService.Endpoint}tenants/${tenantId}/users`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getListOfuser returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/**
 * Get users by tenant simple
 */
async function getListOfUsersByTenantSimple(tenantId) {
  try {
    let url = `${AuthService.Endpoint}tenants/${tenantId}/usersSimple`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getListOfuser returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/*
Create a new user with the specified attributes
*/
async function createUser(company, userBody) {
  try {
    let url = `${AuthService.Endpoint}tenants/${company}/users`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: userBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`createTenant returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for createUser`)

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data }
      } else {
        return { success: false, message: response.data.message }
      }
    } else {
      return { success: false, message: 'Something went wrong, try again later' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

/*
Get the user information
*/
async function getUser(tenant, user) {
  try {
    let url = `${AuthService.Endpoint}tenants/${tenant}/users/${encodeURI(user)}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getTenant returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong, try again later' }
      }
    } else {
      return { success: false, message: 'Something went wrong, try again later' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

async function getUserFutureFlagsQuery(user) {
  let tenant = getUserTenant()
  let url = `${AuthService.Endpoint}tenants/${tenant}/users/${encodeURI(user)}/feature-flags`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Edit the information of a user
*/
async function editUser(tenant, user, userBody) {
  try {
    let url = `${AuthService.Endpoint}tenants/${tenant}/users/${encodeURI(user)}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: userBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`editTenant returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for editTenant`)

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return { success: false, message: response.data.message }
      }
    } else {
      return { success: false, message: 'Something went wrong, try again' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

async function partialUpdateUser(user, userBody) {
  let tenant = getUserTenant()
  let url = `${AuthService.Endpoint}tenants/${tenant}/users/${encodeURI(user)}`
  let requestHeaders = createHeaders()
  return axios({
    method: 'patch',
    url: url,
    headers: requestHeaders,
    data: userBody
  })
}

export {
  getListOfuser,
  getListOfUsersByTenant,
  getListOfUsersByTenantSimple,
  createUser,
  getUser,
  editUser,
  partialUpdateUser,
  getUserFutureFlagsQuery
}
