import React from 'react'

import { useParams } from 'react-router'

// style
import RedirectUI, { getPathNewUi } from 'guards/RedirectUI.jsx'
import EmailView from './EmailView.jsx'

export default function EmailContainer(props) {
  const { emailId } = useParams()
  const path = getPathNewUi(`/platform/invoiceAI/emails/list`)

  return (
    <RedirectUI path={path}>
      <EmailView emailId={emailId} />
    </RedirectUI>
  )
}
