import React from "react";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { Select, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { CircularProgress } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";

//Style
import styles from "assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle";

// General components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

const useStyles = makeStyles(styles);

const IntegrationsTab = (props) => {
    const classes = useStyles();

    function returnOptions() {
        return (
            <GridItem xs={12} sm={12} >
                <h5 className={classes.sectionTitle}>Ariba Integrations</h5>
                <GridItem xs={12} sm={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer className={classes.appRow}>
                                <GridItem xs={12} sm={9}>
                                    <GridContainer>
                                        <div className={classes.block}>
                                            <FormLabel
                                                className={
                                                    classes.inputLabelHorizontal
                                                }
                                            >
                                                <strong>
                                                    Operational Reporting for
                                                    Procurement
                                                </strong>{" "}
                                                &nbsp;
                                            </FormLabel>
                                        </div>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <NavLink
                                        to={`/admin/nitorInsights/procurement/${props.config.environmentId}`}
                                    >
                                        <Button
                                            disabled={!props.expirationData.enabled}
                                            color={"behance"}
                                            simple
                                            className={classes.documentation}
                                        >
                                            <SettingsApplicationsIcon
                                                className={classes.icons}
                                            />
                                            Configure
                                        </Button>
                                    </NavLink>
                                </GridItem>
                            </GridContainer>
                            <GridContainer className={classes.appRow}>
                                <GridItem xs={12} sm={9}>
                                    <GridContainer>
                                        <div className={classes.block}>
                                            <FormLabel
                                                className={
                                                    classes.inputLabelHorizontal
                                                }
                                            >
                                                <strong>
                                                    Analytical Reporting
                                                </strong>
                                                &nbsp;
                                            </FormLabel>
                                        </div>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <NavLink
                                        to={`/admin/nitorInsights/analytics/${props.config.environmentId}`}
                                    >
                                        <Button
                                            disabled={!props.expirationData.enabled}
                                            color={"behance"}
                                            simple
                                            className={classes.documentation}
                                        >
                                            <SettingsApplicationsIcon
                                                className={classes.icons}
                                            />
                                            Configure
                                        </Button>
                                    </NavLink>
                                </GridItem>
                            </GridContainer>
                            <GridContainer className={classes.appRow}>
                                <GridItem xs={12} sm={9}>
                                    <GridContainer>
                                        <div className={classes.block}>
                                            <FormLabel
                                                className={
                                                    classes.inputLabelHorizontal
                                                }
                                            >
                                                <strong>
                                                    Operational Reporting for
                                                    Sourcing
                                                </strong>{" "}
                                                &nbsp;
                                            </FormLabel>
                                        </div>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <NavLink
                                        to={`/admin/nitorInsights/sourcing/${props.config.environmentId}`}
                                    >
                                        <Button
                                            disabled={!props.expirationData.enabled}
                                            color={"behance"}
                                            simple
                                            className={classes.documentation}
                                        >
                                            <SettingsApplicationsIcon
                                                className={classes.icons}
                                            />
                                            Configure
                                        </Button>
                                    </NavLink>
                                </GridItem>
                            </GridContainer>
                            {/* <GridContainer className={classes.appRow}>
                                <GridItem xs={12} sm={9}>
                                    <GridContainer>
                                        <div className={classes.block}>
                                            <FormLabel
                                                className={
                                                    classes.inputLabelHorizontal
                                                }
                                            >
                                                <strong>
                                                    Supplier Data API With
                                                    Pagination
                                                </strong>{" "}
                                                &nbsp;
                                            </FormLabel>
                                        </div>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <NavLink
                                        to={`/admin/nitorInsights/supplier/${props.config.environmentId}`}
                                    >
                                        <Button
                                            disabled={!props.expirationData.enabled}
                                            color={"behance"}
                                            simple
                                            className={classes.documentation}
                                        >
                                            <SettingsApplicationsIcon
                                                className={classes.icons}
                                            />
                                            Configure
                                        </Button>
                                    </NavLink>
                                </GridItem>
                            </GridContainer>
                            <GridContainer className={classes.appRow}>
                                <GridItem xs={12} sm={9}>
                                    <GridContainer>
                                        <div className={classes.block}>
                                            <FormLabel
                                                className={
                                                    classes.inputLabelHorizontal
                                                }
                                            >
                                                <strong>Ariba Network</strong>{" "}
                                                &nbsp;
                                            </FormLabel>
                                        </div>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <NavLink
                                        to={`/admin/nitorInsights/ariba/${props.config.environmentId}`}
                                    >
                                        <Button
                                            disabled={!props.expirationData.enabled}
                                            color={"behance"}
                                            simple
                                            className={classes.documentation}
                                        >
                                            <SettingsApplicationsIcon
                                                className={classes.icons}
                                            />
                                            Configure
                                        </Button>
                                    </NavLink>
                                </GridItem>
                            </GridContainer> */}
                            <GridContainer className={classes.appRow}>
                                <GridItem xs={12} sm={9}>
                                    <GridContainer>
                                        <div className={classes.block}>
                                            <FormLabel
                                                className={
                                                    classes.inputLabelHorizontal
                                                }
                                            >
                                                <strong>File based integration for Upstream</strong>{" "}
                                                &nbsp;
                                            </FormLabel>
                                        </div>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <NavLink
                                        to={`/admin/nitorInsights/upstream/${props.config.environmentId}`}
                                    >
                                        <Button
                                            disabled={!props.expirationData.enabled}
                                            color={"behance"}
                                            simple
                                            className={classes.documentation}
                                        >
                                            <SettingsApplicationsIcon
                                                className={classes.icons}
                                            />
                                            Configure
                                        </Button>
                                    </NavLink>
                                </GridItem>
                            </GridContainer>
                            <GridContainer className={classes.appRow}>
                                <GridItem xs={12} sm={9}>
                                    <GridContainer>
                                        <div className={classes.block}>
                                            <FormLabel
                                                className={
                                                    classes.inputLabelHorizontal
                                                }
                                            >
                                                <strong>File based integration for Downstream</strong>{" "}
                                                &nbsp;
                                            </FormLabel>
                                        </div>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <NavLink
                                        to={`/admin/nitorInsights/downstream/${props.config.environmentId}`}
                                    >
                                        <Button
                                            disabled={!props.expirationData.enabled}
                                            color={"behance"}
                                            simple
                                            className={classes.documentation}
                                        >
                                            <SettingsApplicationsIcon
                                                className={classes.icons}
                                            />
                                            Configure
                                        </Button>
                                    </NavLink>
                                </GridItem>
                            </GridContainer>
                            <GridContainer className={classes.appRow}>
                                <GridItem xs={12} sm={9}>
                                    <GridContainer>
                                        <div className={classes.block}>
                                            <FormLabel
                                                className={
                                                    classes.inputLabelHorizontal
                                                }
                                            >
                                                <strong>File based integration for Spend Visibility</strong>{" "}
                                                &nbsp;
                                            </FormLabel>
                                        </div>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <NavLink
                                        to={`/admin/nitorInsights/spendVisibility/${props.config.environmentId}`}
                                    >
                                        <Button
                                            disabled={!props.expirationData.enabled}
                                            color={"behance"}
                                            simple
                                            className={classes.documentation}
                                        >
                                            <SettingsApplicationsIcon
                                                className={classes.icons}
                                            />
                                            Configure
                                        </Button>
                                    </NavLink>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridItem>
        );
    }

    return (
        <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                    <h5 className={classes.sectionTitle}>
                        Relish Insights Connection
                    </h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                        classes={{
                            label: classes.label,
                            labelPlacementStart: classes.activeToggle,
                        }}
                        label="Enable Relish Insights"
                        labelPlacement="start"
                        control={
                            <Switch
                                disabled={!props.expirationData.enabled}
                                checked={props.config.enabled}
                                onChange={(event) =>
                                    props.onFieldChange(
                                        "enabled",
                                        event.target.checked
                                    )
                                }
                                value="enabled"
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                }}
                            />
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={12} >
                    <GridContainer className={classes.row}>
                        <GridItem xs={12} sm={12}>
                            <h5 className={classes.sectionTitle}>
                                Application
                            </h5>
                        </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={4}>
                                <CustomInput
                                    success={
                                        props.configState.appName ===
                                        "success"
                                    }
                                    error={
                                        props.configState.appName ===
                                        "error"
                                    }
                                    labelText={
                                        <span>
                                            Application Name{" "}
                                            <small>
                                                {props.configState
                                                    .appName === "error"
                                                    ? "(required)"
                                                    : "*"}{" "}
                                            </small>
                                        </span>
                                    }
                                    id="appName"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        disabled:
                                            props.displayMode === "edit" ||
                                            !props.expirationData.enabled,
                                        onChange: (event) =>
                                            props.onFieldChange(
                                                "appName",
                                                event.target.value
                                            ),
                                        value: props.config.appName,
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                    >
                                        Environment Type
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu,
                                        }}
                                        classes={{
                                            select: classes.select,
                                            disabled: classes.disabled,
                                        }}
                                        value={props.config.type}
                                        onChange={(e) =>
                                            props.onFieldChange(
                                                "type",
                                                e.target.value
                                            )
                                        }
                                        inputProps={{
                                            disabled: !props.expirationData.enabled,
                                            name: "type",
                                            id: "type",
                                        }}
                                    >
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected:
                                                    classes.selectMenuItemSelected,
                                            }}
                                            style={{
                                                overflowX: "auto",
                                                textOverflow: "ellipsis",
                                            }}
                                            value={"Test"}
                                        >
                                            Test
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected:
                                                    classes.selectMenuItemSelected,
                                            }}
                                            style={{
                                                overflowX: "auto",
                                                textOverflow: "ellipsis",
                                            }}
                                            value={"Production"}
                                        >
                                            Production
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridItem>
                {props.displayMode === "edit" ? returnOptions() : null}
                <div className={classes.center}>
                    {props.isLoadingSave ? (
                        <CircularProgress color="inherit" />
                    ) : (
                        <Button
                            disabled={!props.expirationData.enabled}
                            color="primary"
                            onClick={() => props.onConfigSave()}
                        >
                            Save
                        </Button>
                    )}
                </div>
            </GridContainer>
        </GridItem>
    );
};

export default IntegrationsTab;
