import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import Button from 'components/CustomButtons/Button.js'
import IconButton from '@material-ui/core/IconButton'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Card from 'components/Card/Card.js'

import wizardStyle from '../../assets/jss/material-dashboard-pro-react/components/wizardStyle.js'
import ResizeObserver from 'react-resize-observer'
import { Fade } from '@material-ui/core'
import WizardNavigation from './WizardNavigation.js'
class Wizard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentStep: 0,
      color: this.props.color,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,

      movingTabStyle: {
        transition: 'transform 0s'
      },
      allStates: {}
    }
    this.navigationStepChange = this.navigationStepChange.bind(this)

    this.previousButtonClick = this.previousButtonClick.bind(this)
    this.previousButtonClick = this.previousButtonClick.bind(this)
    this.finishButtonClick = this.finishButtonClick.bind(this)
    this.updateWidth = this.updateWidth.bind(this)
    this.refreshState = this.refreshState.bind(this)
  }
  wizardNavigationRefreshAnimation = () => {}
  wizard = React.createRef()
  refreshState (resetCurrentStep) {
    this.navigationStepChange = this.navigationStepChange.bind(this)
    this.previousButtonClick = this.previousButtonClick.bind(this)
    this.previousButtonClick = this.previousButtonClick.bind(this)
    this.finishButtonClick = this.finishButtonClick.bind(this)
    this.updateWidth = this.updateWidth.bind(this)
    this.refresh.bind(this)
    this.refreshState = this.refreshState.bind(this)

    this.refresh(resetCurrentStep)
  }
  refresh (resetCurrentStep) {
    let oldState = this.state
    oldState.refresh = !oldState.refresh
    if (resetCurrentStep) {
      oldState.currentStep = 0
    }

    this.setState(oldState)
    // this.wizardNavigationRefreshAnimation(0)
  }
  componentDidMount () {
    this.wizardNavigationRefreshAnimation(0)
    //this.refreshAnimation(0)
    // window.addEventListener('resize', this.updateWidth)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.id !== this.props.id) {
      this.refresh(true)
      this.refresh(true)
    }

    if (
      this.state.nextKey !== this.state.currentStep &&
      this.state.nextKey !== undefined
    ) {
      this.nextButtonClick2.bind(this)()
    } else if (this.state.nextKey !== undefined) {
      let newState = this.state
      newState.nextKey = undefined
      this.setState({ ...newState })
    }
  }
  componentWillUnmount () {
    // window.removeEventListener('resize', this.updateWidth)
  }
  updateWidth () {
    // this.refreshAnimation(this.state.currentStep)
  }
  navigationStepChange (key) {
    if (this.props.steps) {
      var validationState = true
      if (key - this.state.currentStep > 1) {
        /*for (var index = this.state.currentStep; index < key; index++) {
            this.nextButtonClick()
            
        }*/
        let newState = this.state
        newState.nextKey = key
        this.setState({ ...newState })
      } else if (key > this.state.currentStep) {
        for (var i = this.state.currentStep; i < key; i++) {
          if (this[this.props.steps[i].stepId].sendState !== undefined) {
            this.setState({
              allStates: {
                ...this.state.allStates,
                [this.props.steps[i].stepId]: this[
                  this.props.steps[i].stepId
                ].sendState()
              }
            })
          }
          if (
            this[this.props.steps[i].stepId].isValidated !== undefined &&
            this[this.props.steps[i].stepId].isValidated() === false
          ) {
            validationState = false
            break
          }
        }
      }
      if (validationState && !(key - this.state.currentStep > 1)) {
        this.setState({
          currentStep: key,
          nextButton: this.props.steps.length > key + 1 ? true : false,
          previousButton: key > 0 ? true : false,
          finishButton: this.props.steps.length === key + 1 ? true : false
        })
        this.wizardNavigationRefreshAnimation(key)
        // this.refreshAnimation(key)
      }
    }
  }

  nextButtonClick2 () {
    let refDiv = document.getElementById('topPossitionDiv')
    const isValidated =
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.props.steps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined)) ||
      this.props.validate === undefined

    if (refDiv && refDiv.scrollIntoView) refDiv.scrollIntoView()
    if (isValidated) {
      if (
        this[this.props.steps[this.state.currentStep].stepId].sendState !==
        undefined
      ) {
        this.setState({
          allStates: {
            ...this.state.allStates,
            [this.props.steps[this.state.currentStep].stepId]: this[
              this.props.steps[this.state.currentStep].stepId
            ].sendState()
          }
        })
      } else {
        let newState = this.state
        newState.nextKey = undefined
        this.setState({ ...newState })
      }
      var key = this.state.currentStep + 1
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false
      })
      this.wizardNavigationRefreshAnimation(key)
    }
  }

  nextButtonClick () {
    let refDiv = document.getElementById('topPossitionDiv')
    if (refDiv && refDiv.scrollIntoView) refDiv.scrollIntoView()
    if (
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.props.steps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined)) ||
      this.props.validate === undefined
    ) {
      if (
        this[this.props.steps[this.state.currentStep].stepId].sendState !==
        undefined
      ) {
        this.setState({
          allStates: {
            ...this.state.allStates,
            [this.props.steps[this.state.currentStep].stepId]: this[
              this.props.steps[this.state.currentStep].stepId
            ].sendState()
          }
        })
      }
      var key = this.state.currentStep + 1
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false
      })
      this.wizardNavigationRefreshAnimation(key)
    }
  }
  previousButtonClick () {
    if (
      this[this.props.steps[this.state.currentStep].stepId].sendState !==
      undefined
    ) {
      let refDiv = document.getElementById('topPossitionDiv')
      if (refDiv && refDiv.scrollIntoView) refDiv.scrollIntoView()
      this.setState({
        allStates: {
          ...this.state.allStates,
          [this.props.steps[this.state.currentStep].stepId]: this[
            this.props.steps[this.state.currentStep].stepId
          ].sendState()
        }
      })
    }
    var key = this.state.currentStep - 1
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false
      })
      this.wizardNavigationRefreshAnimation(key)
    }
  }
  finishButtonClick () {
    if (
      (this.props.validate === false &&
        this.props.finishButtonClick !== undefined) ||
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId] &&
          this[this.props.steps[this.state.currentStep].stepId].isValidated !==
            undefined &&
          this[
            this.props.steps[this.state.currentStep].stepId
          ].isValidated()) ||
          (this[this.props.steps[this.state.currentStep].stepId] &&
            this[this.props.steps[this.state.currentStep].stepId]
              .isValidated === undefined)) &&
        this.props.finishButtonClick !== undefined)
    ) {
      this.setState(
        {
          allStates: {
            ...this.state.allStates,
            [this.props.steps[this.state.currentStep].stepId]: this[
              this.props.steps[this.state.currentStep].stepId
            ].sendState()
          }
        },
        () => {
          this.props.finishButtonClick(this.state.allStates)
        }
      )
    }
  }

  getWizard (
    classes,
    title,
    subtitle,
    color,
    steps,
    hideNavigation,
    hideNextButton,
    hidePreviousButton,
    hideSaveButton,
    forceFinishButton
  ) {
    return (
      <Fade in={true} timeout={250}>
        <div style={{ position: 'relative' }}>
          <ResizeObserver
            onResize={rect => {
              //this.updateWidth()
            }}
            onPosition={rect => {}}
          />
          <div className={classes.wizardContainer} ref={this.wizard}>
            <Card className={classes.card}>
              {this.props.backButtonOnClick ? (
                <div
                  style={{
                    width: '100%'
                  }}
                >
                  <IconButton
                    onClick={() => {
                      this.wizardNavigationRefreshAnimation(0)
                      this.props.backButtonOnClick()
                    }}
                  >
                    <KeyboardBackspaceIcon />
                  </IconButton>
                </div>
              ) : (
                ''
              )}
              <div className={classes.wizardHeader}>
                <h3 className={classes.title}>{title}</h3>
                <h5 className={classes.subtitle}>{subtitle}</h5>
              </div>

              {!hideNavigation ? (
                <WizardNavigation
                  refreshAnimation={refreshChild => {
                    this.wizardNavigationRefreshAnimation = refreshChild
                  }}
                  wizard={this.wizard}
                  navigationStepChange={this.navigationStepChange}
                  classes={classes}
                  title={title}
                  subtitle={subtitle}
                  color={color}
                  steps={steps}
                  hideNavigation={hideNavigation}
                  state={this.state}
                  {...this.props}
                ></WizardNavigation>
              ) : (
                ''
              )}
              <div className={classes.content}>
                {steps.map((prop, key) => {
                  const stepContentClasses = cx({
                    [classes.stepContentActive]: this.state.currentStep === key,
                    [classes.stepContent]: this.state.currentStep !== key
                  })
                  if (this.state.currentStep === key)
                    return (
                      <div className={stepContentClasses} key={key}>
                        <div id={'topPossitionDiv'}></div>
                        <prop.stepComponent
                          id={key}
                          innerRef={node => {
                            this[prop.stepId] = node
                          }}
                          allStates={this.state.allStates}
                          initialState={prop.initialState}
                        />
                      </div>
                    )
                  else return ''
                })}
              </div>
              <div className={classes.footer}>
                <div className={classes.left}>
                  {this.state.previousButton && !hidePreviousButton ? (
                    <Button
                      className={this.props.previousButtonClasses}
                      onClick={() => this.previousButtonClick()}
                    >
                      {this.props.previousButtonText}
                    </Button>
                  ) : null}
                </div>
                <div className={classes.right}>
                  {this.state.nextButton && !hideNextButton ? (
                    <Button
                      color="primary"
                      className={this.props.nextButtonClasses}
                      onClick={() => this.nextButtonClick()}
                    >
                      {this.props.nextButtonText}
                    </Button>
                  ) : null}
                  {(this.state.finishButton && !hideSaveButton) ||
                  forceFinishButton ? (
                    <Button
                      color="primary"
                      className={this.finishButtonClasses}
                      onClick={() => this.finishButtonClick()}
                    >
                      {this.props.finishButtonText}
                    </Button>
                  ) : null}
                </div>
                <div className={classes.clearfix} />
              </div>
            </Card>
          </div>
        </div>
      </Fade>
    )
  }
  render () {
    const {
      classes,
      title,
      subtitle,
      color,
      steps,
      hideNavigation,
      hideNextButton,
      hidePreviousButton,
      hideFinishButton,
      forceFinishButton
    } = this.props

    if (this.state.refresh)
      return (
        <div>
          <div></div>
          {this.getWizard(
            classes,
            title,
            subtitle,
            color,
            steps,
            hideNavigation,
            hideNextButton,
            hidePreviousButton,
            hideFinishButton,
            forceFinishButton
          )}
        </div>
      )
    return (
      <div>
        {this.getWizard(
          classes,
          title,
          subtitle,
          color,
          steps,
          hideNavigation,
          hideNextButton,
          hidePreviousButton,
          hideFinishButton,
          forceFinishButton
        )}
      </div>
    )
  }
}

Wizard.defaultProps = {
  color: 'rose',
  title: 'Here should go your title',
  subtitle: 'And this would be your subtitle',
  previousButtonText: 'Previous',
  previousButtonClasses: '',
  nextButtonClasses: '',
  nextButtonText: 'Next',
  finishButtonClasses: '',
  finishButtonText: 'Save'
}

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.object.isRequired,
      stepId: PropTypes.string.isRequired
    })
  ).isRequired,
  color: PropTypes.oneOf([
    'primary',
    'warning',
    'danger',
    'success',
    'info',
    'rose'
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  validate: PropTypes.bool
}

export default withStyles(wizardStyle)(Wizard)
