import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import { Auth0 } from 'utils/Constants'

import styles from 'assets/jss/material-dashboard-pro-react/views/User/createStyle.js'

function UserConsentView1(props) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const [agree, setAgree] = React.useState(false)
  const [auth0state, setAuth0State] = React.useState('')

  //Component did mount
  useEffect(() => {
    const search = props.location.search
    const params = new URLSearchParams(search)
    setAuth0State(params.get('state'))
  }, [props])

  const btnHandler = async () => {
    console.log('New value for agree btnHandler: ' + agree)
    window.location.href = `https://${Auth0.Domain}/continue?state=${auth0state}&confirm=${agree ? 'yes' : 'no'}`
  }

  const handleChange = event => {
    setAgree(event.target.checked)
    console.log('New value for agree checkbox handler: ' + agree)
  }

  return (
    <div>
      <div className="container">
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ padding: '0% 20% 0px' }}>
              <CardHeader color="info" icon></CardHeader>
              <CardBody>
                <div className="jumbotron">
                  <div style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <h1>Relish Inc Terms & Conditions </h1>
                    <br></br>
                    <br></br>
                  </div>
                  {/* <h2>Privacy Notice</h2> */}
                  <div style={{ justifyContent: 'center', textAlign: 'left' }}>
                    <Card
                      style={{
                        maxWidth: 1000,
                        alignContent: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <CardHeader color="primary">
                        <h3 style={{ color: 'white' }}>Privacy Notice</h3>
                      </CardHeader>
                      <CardBody>
                        <p>
                          Relish Inc. values your privacy and is committed to protecting your personal data in compliance with applicable
                          data privacy regulations and frameworks, including, but not limited to, the General Data Protection Regulation
                          (GDPR), the Health Insurance Portability and Accountability Act (HIPAA), and the SOC 2 Privacy Trust Service
                          Criteria. When you access Relish's website and applications, we strive to limit the collection of personal data,
                          such as your name, phone number, and email address, unless it is voluntarily provided by you or your organization
                          for specific purposes.
                        </p>
                        <br></br>
                        <p>
                          Relish’s clients may elect to store contact data associated with their users. In your use of these applications,
                          contact data may be processed and transferred outside your country or jurisdiction. Relish Inc. is dedicated to
                          ensuring that appropriate safeguards are in place to protect this data, as required by applicable data privacy
                          laws and regulations
                        </p>
                        <br></br>
                        <p>
                          Relish Inc. acts as a data processor for your contact data, in accordance with the terms set forth in the
                          agreement between your organization and Relish Inc. This agreement outlines the licensing terms for using our
                          cloud-based application.
                        </p>
                        <p>
                          <a href="https://relishiq.com/privacy-policy/">Relish Privacy Policy</a> provides detailed information on Relish’s
                          data handling practices, including how Relish processes, stores, and protects personal data.
                        </p>
                      </CardBody>
                    </Card>
                  </div>

                  {/* <h2>Cookie Usage Statement</h2> */}
                  <div style={{ justifyContent: 'center', textAlign: 'left' }}>
                    <Card
                      style={{
                        maxWidth: 1000,
                        alignContent: 'center',
                        justifyContent: 'center',
                        marginTop: '5%'
                      }}
                    >
                      <CardHeader color="primary">
                        <h3 style={{ color: 'white' }}>Cookie Usage Statement</h3>
                      </CardHeader>
                      <CardBody>
                        <p>Relish Inc. uses cookies on its website and applications for various purposes, including:</p>
                        <ul>
                          <li>Enabling essential website functionality</li>
                          <li>Enhancing user experience</li>
                          <li>Providing additional security measures</li>
                        </ul>
                        <p>
                          Relish Inc. may also uses cookies and similar tracking technologies to collect and process personal data about
                          your usage patterns, as described in Relish’s Privacy Policy. By using Relish’s site, you consent to the placement
                          of these cookies on your device.
                        </p>
                        <p>
                          For information on how we manage cookies, as well as your rights and choices under applicable data privacy laws,
                          please refer to the <a href="https://relishiq.com/privacy-policy/">Relish Privacy Policy</a>.
                        </p>
                      </CardBody>
                    </Card>
                  </div>
                  {/* <p>
                                            Relish LLC’s website and applications use cookies to store information on your computer for some or all of the following reasons:
                                        </p>
                                        <ul>
                                            <li>To make our site work</li>
                                            <li>To help us improve the user experience</li>
                                            <li>To provide added security</li>
                                        </ul>   
                                        <p>By using the site from the device you are using, you consent to the placement of these cookies on that device. Read more about our use of cookies in the Relish Privacy Policy.</p> */}
                  <form onSubmit={btnHandler} className={classes.justifyContentCenter}>
                    <div className="checkbox" style={{ marginTop: '2%' }}>
                      <FormGroup row>
                        <FormControlLabel
                          control={<Checkbox checked={agree} onChange={handleChange} name="checkedB" color="default" />}
                          label="By using Relish’s applications, you acknowledge and agree to the terms of our Cookie Usage Statement and consent to the processing of your personal data as outlined in the Relish’s Privacy Policy."
                        />
                      </FormGroup>
                    </div>
                    <div
                      style={{
                        justifyContent: 'center',
                        textAlign: 'center',
                        marginTop: '2%'
                      }}
                    >
                      <Button variant="contained" disabled={!agree} size="large" onClick={btnHandler}>
                        Agree
                      </Button>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

export default UserConsentView1
