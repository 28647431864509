import {
  Badge,
  Box,
  CircularProgress,
  Fab,
  FormControlLabel,
  Switch,
  Tooltip
} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import AddIcon from '@material-ui/icons/Add'
import AddAlert from '@material-ui/icons/AddAlert'
//import UpdateIcon from '@material-ui/icons/Update'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DeleteSweepOutlinedIcon from '@material-ui/icons/DeleteSweepOutlined'
import ErrorIcon from '@material-ui/icons/Error'
import MergeTypeIcon from '@material-ui/icons/MergeType'
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined'
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined'
import SyncIcon from '@material-ui/icons/Sync'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'
import TabPanel from 'components/Invoice/TabPanel'
import { ValidationsModal } from 'components/Invoice/ValidationsModal'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import {
  checkErrorOrWarning,
  getDistance,
  maxDecimalAndSummation,
  midPointBoundingBox
} from 'utils/functions'
import { v4 as uuidv4 } from 'uuid'

// Form Validation
import Snackbar from 'components/Snackbar/Snackbar'
import {
  useResetOcrInformation,
  useUpdateFileInformation,
  useUpdateInvoiceOcrData,
  useValidationInvoice
} from 'hooks/useOcrInvoice'
import { useFieldArray, useForm } from 'react-hook-form'

import ReactDataGrid from '@inovua/reactdatagrid-community'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import WarningIcon from '@material-ui/icons/Warning'
import {
  AdditionalCharges,
  ChargesBySystem,
  ColumnsOrderAdditionalCharges,
  ColumnsOrderLineItems,
  CustomCharges,
  HeaderFieldsSide,
  specialPOLines,
  specialPOLinesByProcess,
  Taxes
} from 'utils/Constants'
import AutoCompleteCountries from './AutoCompleteCountries'
import DatePickerField from './DatePickerField'
import { HeaderField } from './HeaderField'
import { OtherField } from './OtherField'
import SelectInvoiceType from './SelectInvoiceType'
import SelectProcess from './SelectProcess'
import SupplierSearch from './SupplierSearch'
import SwitchField from './SwitchField'

import '@inovua/reactdatagrid-community/index.css'
import { UpdateStatusDialog } from 'components/Invoice/UpdateStatusModal'
import InfoModal from '../../components/Invoice/InfoModal'
import ModalComments from '../../components/Invoice/ModalComments'
import { DeleteConfirmationModal } from '../../components/Invoice/ModalConfirmDelete'
import PoSearch from './PoSearch'
import ResetModal from './ResetModal'

import {
  fetchInvoiceById,
  useGetInvoiceComments,
  useMoveInvoice,
  useUpdateStatusInvoice,
  wait
} from 'hooks/useInvoices'
import { findBestMatch } from 'string-similarity'
import { formatField, formatNumber, validateInput, valueComparison } from 'utils/validator'
import SupplierSearchName from './supplierSearchName'

import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined'
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined'
//import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import { ReactComponent as DoubleArrowIcon } from 'assets/img/invoices/caret-double-right.svg'

import ModalAccountType from 'components/Invoice/ModalAccountType'

import CombineModal from '../../components/Invoice/CombineModal'
import AdditionalDetailsExceptions from './AdditionalDetailsExceptions'
import AddressSearch from './AddressSearch'
import AutoCompleteAppList from './AutoCompleteAppList'
import AutoCompleteAsyncValuesHeader from './AutoCompleteAsyncValuesHeader'
import IgnoreModal from './IgnoreModal'
import PoModal from './PoModal'
import PoModalDetails from './PoModalDetails'
import SelectCompanyCode from './SelectCompanyCode'
import SupplierAddressSelect from './SupplierAddressSelect'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  marginTexField: {
    flex: '0 0 50%',
    display: 'flex',
    margin: '2rem 0 0 0',
    justifyContent: 'left'
  },
  summaryFileds: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  otherFiledsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  summaryFiledsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  otherFiledsSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  otherFileds: {
    flex: '0 0 50%',
    display: 'flex',
    margin: '2rem 0 0 0',
    alignItems: 'center',
    justifyContent: 'center'
  },
  otherFiled: {},
  formControl: {
    minWidth: 100,
    maxWidth: 100,
    margin: ' 0 2rem 0 1rem'
  },
  notRecognizedLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formControlCountrie: {
    margin: theme.spacing(0.5),
    minWidth: 210,
    maxWidth: 300
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  },
  dropdown: {
    '-webkit-appearance': 'none',
    padding: '7px 40px 7px 12px',
    width: '100%',
    border: '1px solid #E8EAED',
    borderRadius: '5px',
    background: 'white',
    boxShadow: '0 1px 3px -2px #9098A9',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: '16px',
    transition: 'all 150ms ease',
    '&:focus': {
      outline: 'none',
      borderColor: '#0077FF',
      boxShadow: '0 0 0 2px rgba(#0077FF,.2)'
    },
    '&:hover': {
      stroke: '#0077FF'
    }
  },
  btnColorPrimary: {
    color: '#081c3e',
    border: '1px solid #081c3e',
    '&:hover': {
      backgroundColor: '#081c3e',
      color: 'white',
      border: '1px solid #081c3e'
    }
  },
  btnBgColorPrimary: {
    backgroundColor: '#081c3e',
    '&:hover': {
      backgroundColor: '#1E408A',
      border: '1px solid #1E408A'
    }
  },
  tabPanel: {
    maxHeight: '100%'
  },
  primaryColor: {
    color: '#081c3e !important'
  },
  disabledColor: {
    color: '#00000042 !important'
  },
  noHover: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    padding: '0px 16px 0px 16px !important'
  }
}))

const useStylesGridItemHeader = makeStyles(theme => ({
  root: {
    padding: '5px',
    marginBottom: '1rem'
  }
}))

const useStylesButtons = makeStyles(theme => ({
  startICon: {
    margin: 0
  }
}))

const useStylesBadges = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    },
    display: 'flex',
    justifyContent: 'flex-end'
  },
  warningBadge: {
    backgroundColor: '#f3bc07',
    color: 'white'
  },
  errorBadge: {
    backgroundColor: 'red',
    color: 'white'
  },
  badge: {
    backgroundColor: '#081c3e',
    color: 'white'
  }
}))

function sortElements(a, b) {
  return a.pos - b.pos
}

const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
  autoHide: true,
  alwaysShowTrack: true,
  scrollThumbStyle: {
    background: '#1E408A'
  }
})

const validationMsg = (messages, setCreateError, typeMsg) => {
  let messageTxt
  if (Array.isArray(messages)) {
    messageTxt = messages.map((message, i) => <div key={i}>{message.message}</div>)
  } else {
    messageTxt = messages?.split('\n').map((message, i) => <div key={i}>{message}</div>)
  }

  setCreateError({
    isOpen: true,
    message: messageTxt,
    color: typeMsg === 'warning' ? 'warning' : 'danger'
  })
}

function getColor(typeMessage, validated) {
  if (validated) {
    return 'green'
  }

  if (typeMessage === 'warning') {
    return '#f3bc07'
  }

  if (typeMessage === 'error') {
    return 'red'
  }
  return ''
}

export function getColumns(
  data,
  poInformation,
  onClickAlert,
  classes,
  validationErrors,
  setCreateError,
  summaryFields,
  invoiceVersion,
  showAccountSegments,
  accountSegmentsEnabled,
  showAddressesModal,
  requiredFields
) {
  const columns = []
  const columnPos = {}

  const processField = Object.values(summaryFields).find(field => field.type === 'PROCESS')
  const processValue = processField ? processField.value.text ?? '' : ''

  if (data.length > 0) {
    columns.push({
      header: 'key',
      name: 'key',
      defaultFlex: 1,
      minWidth: 100,
      editable: false,
      defaultVisible: false
    })

    let position = 0
    // eslint-disable-next-line
    for (const values of data) {
      if (
        !ColumnsOrderLineItems[values.type] ||
        (ColumnsOrderLineItems[values.type] &&
          ColumnsOrderLineItems[values.type].section !== 'column')
      ) {
        continue
      }

      if (values.visible) {
        columnPos[values.type] = position
        position++
      }
      if (values.type === 'LINE_NUMBER') {
        columns.push({
          header: values.label,
          name: values.type,
          defaultFlex: 1,
          minWidth: 100,
          defaultVisible: values.visible
        })
        continue
      }
      if (values.type === 'PO_LINE_NUMBER') {
        const options = []
        options.push(
          <option key={'none'} value="">
            No Po Match
          </option>
        )
        if (poInformation) {
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(poInformation)) {
            options.push(
              <option key={key} value={key}>
                {value?.summaryLine ?? ''}
              </option>
            )
          }

          columns.push({
            name: values.type,
            header: values.label,
            defaultFlex: 1,
            minWidth: 200,
            editable: false,
            defaultVisible: values.visible,
            render: ({ value, data }) => {
              const validated = validationErrors[`${values.type}.${data.key}`]?.valid ?? null

              const typeMsg = checkErrorOrWarning(
                validationErrors[`${values.type}.${data.key}`]?.messages,
                validated
              )

              const colorInput = getColor(typeMsg, validated)

              const icon =
                typeMsg === 'error' ? (
                  <ErrorIcon style={{ color: 'red' }} />
                ) : typeMsg === 'warning' ? (
                  <WarningIcon style={{ color: '#f3bc07' }} />
                ) : null

              return validationErrors[`${values.type}.${data.key}`]?.messages ? (
                validationErrors[`${values.type}.${data.key}`]?.lineItem === data.key ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <select
                      name="po"
                      value={value}
                      id="po"
                      className={classes.dropdown}
                      onChange={e => onClickAlert({ index: data.key, value: e.target.value })}
                    >
                      {options.length > 0 ? options : null}
                    </select>

                    <div
                      style={{
                        color: colorInput,
                        textAlign: 'right'
                      }}
                    >
                      <IconButton
                        size="small"
                        component="span"
                        onClick={() =>
                          validationMsg(
                            validationErrors[`${values.type}.${data.key}`]?.messages,
                            setCreateError,
                            typeMsg
                          )
                        }
                      >
                        {icon}
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <select
                    name="po"
                    value={value}
                    id="po"
                    className={classes.dropdown}
                    onChange={e => onClickAlert({ index: data.key, value: e.target.value })}
                  >
                    {options.length > 0 ? options : null}
                  </select>
                )
              ) : (
                <select
                  name="po"
                  value={value}
                  id="po"
                  className={classes.dropdown}
                  onChange={e => onClickAlert({ index: data.key, value: e.target.value })}
                >
                  {options.length > 0 ? options : null}
                </select>
              )
            }
          })
        } else {
          columns.push({
            name: values.type,
            header: values.label,
            defaultFlex: 1,
            minWidth: 125,
            editable: false,
            defaultVisible: values.visible,
            render: ({ value, data }) => (
              <select
                name="po"
                value={value}
                id="po"
                className={classes.dropdown}
                onChange={e => onClickAlert({ index: data.key, value: e.target.value })}
              >
                {options.length > 0 ? options : null}
              </select>
            )
          })
        }

        if (!invoiceVersion || invoiceVersion === 1) {
          const specialPOLines = specialPOLinesByProcess[processValue] ?? []
          // eslint-disable-next-line
          for (const line of specialPOLines) {
            options.push(
              <option key={line.value} value={line.value}>
                {line.label}
              </option>
            )
          }
        }

        continue
      }

      let minWidth = 120
      if (values.type === 'ITEM') {
        minWidth = 400
      }
      columns.push({
        name: values.type,
        header: values.label,
        defaultFlex: 1,
        minWidth: minWidth,
        defaultVisible: values.visible,
        render: ({ value, data }) => {
          const validated = validationErrors[`${values.type}.${data.key}`]?.valid ?? null

          const typeMsg = checkErrorOrWarning(
            validationErrors[`${values.type}.${data.key}`]?.messages,
            validated
          )

          const colorInput = getColor(typeMsg, validated)

          const icon =
            typeMsg === 'error' ? (
              <ErrorIcon style={{ color: 'red' }} />
            ) : typeMsg === 'warning' ? (
              <WarningIcon style={{ color: '#f3bc07' }} />
            ) : null

          return validationErrors[`${values.type}.${data.key}`]?.messages ? (
            validationErrors[`${values.type}.${data.key}`]?.lineItem === data.key ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div style={{ color: colorInput }}>{value}</div>
                <div
                  style={{
                    color: colorInput,
                    textAlign: 'right'
                  }}
                >
                  <IconButton
                    size="small"
                    component="span"
                    onClick={() =>
                      validationMsg(
                        validationErrors[`${values.type}.${data.key}`]?.messages,
                        setCreateError,
                        typeMsg
                      )
                    }
                  >
                    {icon}
                  </IconButton>
                </div>
              </div>
            ) : (
              value
            )
          ) : (
            value
          )
        }
      })
    }

    const FIELD_LEVEL = 'lineItems'
    const detailFields = Object.values(ColumnsOrderLineItems)
      .filter(x => x.section === 'details')
      .map(x => x.type)

    const customFieldsSelects = Object.values(requiredFields ?? {}).filter(
      x => x.level === FIELD_LEVEL && x.visible && x.isCustomField
    )

    const standardFields = Object.values(requiredFields ?? {}).filter(
      x => detailFields.includes(x.standardField) && x.visible && x.level === FIELD_LEVEL
    )

    const isLineAccountSegmentsVisible =
      requiredFields[`${FIELD_LEVEL}#${ColumnsOrderLineItems.ACCOUNTING.type}`]?.visible

    customFieldsSelects.forEach(x => {
      columns.push({
        header: x.label,
        name: x.standardField,
        defaultFlex: 1,
        minWidth: 100,
        editable: false,
        defaultVisible: false
      })
    })

    standardFields.forEach(x => {
      switch (x.standardField) {
        case ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.type:
          columns.push({
            header: ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.label,
            name: ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.type,
            defaultFlex: 1,
            minWidth: 100,
            editable: false,
            defaultVisible: false
          })
          columns.push({
            header: ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.label,
            name: `${ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.type}_ID`,
            defaultFlex: 1,
            minWidth: 100,
            editable: false,
            defaultVisible: false
          })
          break
        case ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.type:
          columns.push({
            header: ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.label,
            name: ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.type,
            defaultFlex: 1,
            minWidth: 100,
            editable: false,
            defaultVisible: false
          })
          columns.push({
            header: ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.label,
            name: `${ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.type}_ID`,
            defaultFlex: 1,
            minWidth: 100,
            editable: false,
            defaultVisible: false
          })
          break
        case ColumnsOrderLineItems.ACCOUNTING.type:
          if (accountSegmentsEnabled) {
            columns.push({
              header: ColumnsOrderLineItems.ACCOUNTING.label,
              name: ColumnsOrderLineItems.ACCOUNTING.type,
              defaultFlex: 1,
              minWidth: 100,
              editable: false,
              defaultVisible: false
            })
          }
          break

        default:
          {
            const standardFieldData = ColumnsOrderLineItems[x.standardField]
            if (standardFieldData) {
              columns.push({
                header: standardFieldData.label,
                name: standardFieldData.type,
                defaultFlex: 1,
                minWidth: 100,
                editable: false,
                defaultVisible: false
              })
            }
          }
          break
      }
    })

    const isAccountEnabled = isLineAccountSegmentsVisible && accountSegmentsEnabled
    const isDetailsEnabled = standardFields.length > 0 || customFieldsSelects.length > 0

    if (isDetailsEnabled || isAccountEnabled) {
      columns.push({
        header: ColumnsOrderLineItems.ADDITIONAL_DETAILS.label,
        name: ColumnsOrderLineItems.ADDITIONAL_DETAILS.type,
        defaultFlex: 1,
        minWidth: 250,
        editable: false,
        textAlign: 'center',
        render: ({ value, data }) => {
          let iconAccount = null
          let isErrorAccount = false
          let typeMsgAccount = ''
          const columnNameAccount = ColumnsOrderLineItems.ACCOUNTING.type
          if (accountSegmentsEnabled && isLineAccountSegmentsVisible) {
            const validatedAccount =
              validationErrors[`${columnNameAccount}.${data.key}`]?.valid ?? null

            typeMsgAccount = checkErrorOrWarning(
              validationErrors[`${columnNameAccount}.${data.key}`]?.messages,
              validatedAccount
            )

            iconAccount = typeMsgAccount === 'error' ? <ErrorIcon style={{ color: 'red' }} /> : null

            isErrorAccount =
              validationErrors[`${columnNameAccount}.${data.key}`]?.messages &&
              validationErrors[`${columnNameAccount}.${data.key}`]?.lineItem === data.key
          }

          const specialFields = [
            ...customFieldsSelects.map(x => x.standardField),
            ...standardFields
              .filter(x => x.standardField !== ColumnsOrderLineItems.ACCOUNTING.type)
              .map(x => x.standardField)
          ]

          let isError = false
          let icon = null

          // eslint-disable-next-line
          for (const specialField of specialFields) {
            const validated = validationErrors[`${specialField}.${data.key}`]?.valid ?? null

            if (validated) {
              continue
            }

            const typeMsg = checkErrorOrWarning(
              validationErrors[`${specialField}.${data.key}`]?.messages,
              validated
            )

            icon = typeMsg === 'error' ? <ErrorIcon style={{ color: 'red' }} /> : null

            isError =
              validationErrors[`${specialField}.${data.key}`]?.messages &&
              validationErrors[`${specialField}.${data.key}`]?.lineItem === data.key

            if (isError) {
              break
            }
          }

          return (
            <div style={{ display: 'inline-block' }}>
              <ButtonGroup variant="text" size="small" aria-label="Lite items details">
                {isAccountEnabled ? (
                  <Button
                    color="primary"
                    onClick={showAccountSegments}
                    endIcon={
                      isErrorAccount ? (
                        <IconButton
                          size="small"
                          component="span"
                          onClick={() =>
                            validationMsg(
                              validationErrors[`${columnNameAccount}.${data.key}`]?.messages,
                              setCreateError,
                              typeMsgAccount
                            )
                          }
                        >
                          {iconAccount}
                        </IconButton>
                      ) : null
                    }
                  >
                    Accounting
                  </Button>
                ) : null}

                {isDetailsEnabled ? (
                  <Button
                    color="primary"
                    onClick={showAddressesModal}
                    endIcon={
                      isError ? (
                        <IconButton size="small" component="span">
                          {icon}
                        </IconButton>
                      ) : null
                    }
                  >
                    Details
                  </Button>
                ) : null}
              </ButtonGroup>
            </div>
          )
        }
      })
    }
  }

  return { columns, columnPos }
}

export function getAdditionalColumns(
  data,
  onClickAlert,
  classes,
  validationErrors,
  setCreateError,
  summaryFields,
  appConfig
) {
  const columns = []
  const columnPos = {}

  const processField = Object.values(summaryFields).find(field => field.type === 'PROCESS')
  const processValue = processField ? processField.value.text ?? '' : ''

  if (data.length > 0) {
    columns.push({
      header: 'key',
      name: 'key',
      defaultFlex: 1,
      minWidth: 100,
      editable: false,
      defaultVisible: false
    })

    let position = 0
    // eslint-disable-next-line
    for (const values of data) {
      if (!appConfig.data.params.invoices.showTaxRate && values.type === 'RATE') {
        continue
      }

      if (values.visible) {
        columnPos[values.type] = position
        position++
      }

      if (values.type === 'CHARGE_TYPE') {
        const options = []
        options.push(
          <option key={'none'} value="">
            No Match
          </option>
        )

        const specialPOLines = specialPOLinesByProcess[processValue] ?? []
        const chargesByService = ChargesBySystem[appConfig.data.service] ?? []
        const customCharges = appConfig.data.params.ocr?.showCustomCharges
          ? [CustomCharges.insuranceCharge, CustomCharges.otherCharge]
          : []

        const charges = [...specialPOLines, ...chargesByService, ...customCharges]

        // eslint-disable-next-line
        for (const line of charges) {
          options.push(
            <option key={line.value} value={line.value}>
              {line.label}
            </option>
          )
        }

        columns.push({
          name: values.type,
          header: values.label,
          defaultFlex: 1,
          minWidth: 125,
          editable: false,
          defaultVisible: values.visible,
          render: ({ value, rowIndex }) => (
            <select
              name="type"
              value={value}
              id="type"
              className={classes.dropdown}
              onChange={e => onClickAlert({ index: rowIndex, value: e.target.value })}
            >
              {options.length > 0 ? options : null}
            </select>
          )
        })

        continue
      }

      let minWidth = 120

      if (values.type === 'DESCRIPTION') {
        minWidth = 400
      }
      columns.push({
        name: values.type,
        header: values.label,
        defaultFlex: 1,
        editable: (editValue, cellProps) => {
          if (cellProps.id === 'RATE') {
            if (!cellProps.data?.CHARGE_TYPE?.includes('Tax')) {
              return false
            }
          }
          return true
        },
        minWidth: minWidth,
        defaultVisible: values.visible,
        render: ({ value, data }) => {
          const validated = validationErrors[`${values.type}.${data.key}`]?.valid ?? null

          const typeMsg = checkErrorOrWarning(
            validationErrors[`${values.type}.${data.key}`]?.messages,
            validated
          )

          const colorInput = getColor(typeMsg, validated)

          const icon =
            typeMsg === 'error' ? (
              <ErrorIcon style={{ color: 'red' }} />
            ) : typeMsg === 'warning' ? (
              <WarningIcon style={{ color: '#f3bc07' }} />
            ) : null

          return validationErrors[`${values.type}.${data.key}`]?.messages ? (
            validationErrors[`${values.type}.${data.key}`]?.lineItem === data.key ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div style={{ color: colorInput }}>{value}</div>
                <div
                  style={{
                    color: colorInput,
                    textAlign: 'right'
                  }}
                >
                  <IconButton
                    size="small"
                    component="span"
                    onClick={() =>
                      validationMsg(
                        validationErrors[`${values.type}.${data.key}`]?.messages,
                        setCreateError,
                        typeMsg
                      )
                    }
                  >
                    {icon}
                  </IconButton>
                </div>
              </div>
            ) : (
              value
            )
          ) : (
            value
          )
        }
      })
    }
  }

  return { columns, columnPos }
}
const dataPerPage = 20

function generateConditionalMerge(key, value, isMerge) {
  return isMerge
    ? value || key === 'key'
    : value ||
        key === 'key' ||
        key === 'UNIT_PRICE' ||
        key === 'PRICE' ||
        key === 'QUANTITY' ||
        key === 'TOTAL'
}

function countErrorsAndWarnings(validationErrorsState, formErrors, summaryFields) {
  let errorInfo = []
  let warningInfo = []

  const validationErrorsStateCopy = JSON.parse(JSON.stringify(validationErrorsState))
  // eslint-disable-next-line
  for (const [index, value] of summaryFields.entries()) {
    if (!formErrors || !formErrors[index]) {
      continue
    }

    const previousValidation = validationErrorsState[value.type]

    if (!previousValidation) {
      validationErrorsStateCopy[value.type] = {
        ...previousValidation,
        section: 'Header',
        messages: [{ message: formErrors[index].value.message }],
        valid: null
      }
    } else if (previousValidation && previousValidation.valid) {
      validationErrorsStateCopy[value.type] = {
        ...previousValidation,
        section: 'Header',
        messages: [{ message: formErrors[index].value.message }],
        valid: null
      }
    }
  }

  // eslint-disable-next-line
  for (const validation in validationErrorsStateCopy) {
    if (Array.isArray(validationErrorsStateCopy[validation].messages)) {
      let internalWarning = 0
      let internalError = 0
      // eslint-disable-next-line
      for (const message of validationErrorsStateCopy[validation].messages) {
        if (message.type && message.type === 'warning') {
          internalWarning++
        } else if (!message.type) {
          internalError++
        }
      }
      if (internalError === 0) {
        //warningCount += internalWarning
        if (internalWarning > 0) {
          warningInfo.push(validationErrorsStateCopy[validation])
        }
      } else {
        //errorCount += internalError
        if (internalError > 0) {
          errorInfo.push(validationErrorsStateCopy[validation])
        }
      }
    } else if (
      typeof validationErrorsStateCopy[validation].messages === 'string' &&
      validationErrorsStateCopy[validation].messages !== ''
    ) {
      // errorCount++
      errorInfo.push(validationErrorsStateCopy[validation])
    }
  }

  return {
    validationCount: {
      errorCount: errorInfo.length,
      warningCount: warningInfo.length
    },
    validationInfo: {
      errorInfo,
      warningInfo
    }
  }
}

const options = ['Submit', 'Next', 'Exit']

export const ExceptionFields = React.forwardRef((props, ref) => {
  const {
    ocrInformation,
    appId,
    pageNumber,
    invoiceId,
    onChangeUnrecognizedField,
    getTextOcr,
    onChangeSummaryField,
    summaryFieldId,
    lineItemKey,
    additionalChargeKey,
    tab,
    onChangeTab,
    appConfig,
    dataDelete,
    setDataDelete,
    setAnnotationUnrecognizedField,
    setPageNumber,
    onChangeInvoice,
    invoicesExceptions,
    setActionBoundingBox,
    invoiceData,
    connectivityConfig,
    requiredFields,
    defaultAppId,
    isLocked,
    processList
  } = props

  const accountSegmentsEnabled = connectivityConfig?.data?.enabled ?? false
  const isAccountValidationEnabled =
    connectivityConfig?.data?.isExistingAccountValidationEnabled ?? false

  const isCustomerMappingsEnabled = appConfig.data?.params?.ocr?.isCustomerDefinedMappingsEnabled

  const {
    summaryFields,
    notRecognizedFields,
    selectOptions,
    originalLineItems,
    originalAdditionalCharges,
    lineItemColumns,
    additionalChargesColumns,
    validationErrors,
    lineItems,
    additionalCharges
  } = ocrInformation.data

  const history = useHistory()
  const classesContainer = useStylesGridItemHeader()
  const classesButtons = useStylesButtons()
  const classesBadges = useStylesBadges()
  const gridStyle = { minHeight: lineItems.length >= 20 ? 725 : 500 }
  const gridChargesStyle = {
    minHeight: additionalCharges.length >= 20 ? 725 : 500
  }
  const classes = useStyles()
  const [validationErrorsState, setValidationErrorsState] = useState({})

  const [validationErrorsStateCopy, setValidationErrorsStateCopy] = useState({ summaryFields: [] })

  const [anchorEl, setAnchorEl] = React.useState(null)
  const openMenuAddress = Boolean(anchorEl)

  const theme = useTheme()
  const queryClient = useQueryClient()

  const [createError, setCreateError] = useState({
    message: '',
    isOpen: false,
    color: 'danger'
  })

  const [lines, setLines] = useState({
    lineItems: [],
    lineItemsBoundingBox: {},
    lineItemsAccountSegments: {}
  })

  const [charges, setCharges] = useState({
    additionalCharges: [],
    additionalChargesBoundingBox: {}
  })
  const firstRunPOField = useRef(false)
  const [gridRef, setGridRef] = useState(null)
  const [gridAdditionalRef, setGridAdditionalRef] = useState(null)
  const [dropDownItem, setDropDownItem] = useState(null)
  const [dropDownItemAdditional, setDropDownItemAdditional] = useState(null)
  const [submitModal, setSubmitModal] = useState({
    isOpen: false,
    data: null,
    messageBody: null
  })
  const [poModal, setPoModal] = useState({
    update: false,
    details: false
  })

  const [validation, setValidation] = useState({
    PONF: true,
    SNMPO: true,
    POSNVSISN: true
  })
  const [submitSuccess, setSubmitSuccess] = useState({
    message: '',
    isOpen: false
  })

  const [selectedLines, setSelectedLines] = useState({ selected: {}, unselected: {} })

  const [customPagination, setCustomPagination] = useState({
    skip: 0,
    numberOfPages: Math.ceil(lineItems.length / dataPerPage),
    currentPage: 1
  })
  const [customPaginationAdditional, setCustomPaginationAdditional] = useState({
    skip: 0,
    numberOfPages: Math.ceil(additionalCharges.length / dataPerPage),
    currentPage: 1
  })
  const [isPoWarning, setIsPoWarning] = useState(true)

  const [poInformation, setPoInformation] = useState(null)

  const [cellSelected, setCellSelected] = useState(null)
  const selectedLine =
    Object.keys(cellSelected ?? {}).length === 1
      ? Object.keys(cellSelected ?? {})[0].split(',')
      : []

  const [showModal, setShowModal] = useState({
    isOpen: false,
    info: [],
    type: ''
  })
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, rows: null })
  const [invoiceVersion, setInvoiceVersion] = useState(invoiceData?.version ?? null)

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const { mutate: updateData, isLoading: isLoadingUpdate } = useUpdateFileInformation({
    appId,
    invoiceId
  })

  const commentsInformation = useGetInvoiceComments({ appId, invoiceId })

  const { mutate: updateReset, isLoading: isLoadingReset } = useResetOcrInformation({
    appId,
    invoiceId
  })

  const [isLoadingAppUpdate, setIsLoadingAppUpdate] = useState(false)
  const moveInvoice = useMoveInvoice()

  const [simpleModal, setSimpleModal] = useState({
    isOpenAddressModal: false,
    isOpenIgnoreModal: false,
    isOpenRejectModal: false,
    isOpenCombineModal: false,
    isOpenResetModal: false,
    isOpenCommentsModal: false,
    isOpenAccountTypeModal: false,
    isOpenSaveModal: false,
    isOpenSubmitModal: false,
    isOpenAppListModal: false,
    isOpenAppUpdateModal: false,
    deleteAccounting: false
  })

  const validationInvoice = useValidationInvoice({ appId, invoiceId })

  const updateStatusInvoice = useUpdateStatusInvoice(appId, invoiceId)

  const [mappings, setMappings] = useState(false)

  const {
    formState: { errors },
    control,
    watch,
    setValue: setValueForm,
    reset,
    getValues,
    trigger
  } = useForm({
    mode: 'all'
  })

  const { fields: summaryFieldsArray, update: updateSummaryField } = useFieldArray({
    control,
    name: 'summaryFields'
  })

  const { fields: otherFields, update: updateOtherField } = useFieldArray({
    control,
    name: 'otherFields'
  })

  const applyPoLineMappingsOcr = useUpdateInvoiceOcrData(`/invoices/${invoiceId}/poLineMappings`)

  function onPoLineChange(data) {
    setDropDownItem(data)

    const isVisibleCommodity =
      requiredFields[`lineItems#${ColumnsOrderLineItems.COMMODITY_ID.type}`]?.visible

    if (!isVisibleCommodity) {
      return
    }

    const headerFields = getValues()

    const po = headerFields.summaryFields.find(
      summaryField => summaryField.type === HeaderFieldsSide.PURCHASE_ORDER.type
    )

    const line = lines.lineItems.find(line => line.key === data?.index)

    if (!po?.value || !line) {
      return
    }

    const body = {
      lineItems: { ...lines, lineItems: [line] },
      poNumber: po.value,
      lineItemId: data.index
    }

    applyPoLineMappingsOcr.mutate(body, {
      onSuccess: (response, variables, context) => {
        const { data } = response
        updateLineItem(data, body.lineItemId)
      },
      onError: error => {
        setCreateError({
          isOpen: true,
          message: error.response?.data?.message ?? 'Something went wrong, try again later',
          color: 'danger'
        })
      }
    })
  }

  const columnsData = getColumns(
    lineItemColumns,
    poInformation?.lineItemsPo,
    data => onPoLineChange(data),
    classes,
    validationErrorsState,
    setCreateError,
    summaryFields,
    invoiceVersion,
    () => setSimpleModal(old => ({ ...old, isOpenAccountTypeModal: true })),
    accountSegmentsEnabled,
    () => setSimpleModal(old => ({ ...old, isOpenAddressModal: true })),
    requiredFields
  )

  const columnsAdditionalData = getAdditionalColumns(
    additionalChargesColumns,
    data => setDropDownItemAdditional(data),
    classes,
    validationErrorsState,
    setCreateError,
    summaryFields,
    appConfig
  )

  const applyCustomerMappings = useUpdateInvoiceOcrData(
    `/apps/${appId}/invoices/${invoiceId}/ocr/customerMappings`
  )

  const applyPOMappingsOcr = useUpdateInvoiceOcrData(
    `/apps/${appId}/invoices/${invoiceId}/ocr/purchaseOrderMappings`
  )

  const { validationCount, validationInfo } = countErrorsAndWarnings(
    validationErrorsState,
    tab === 0 ? errors?.summaryFields : validationErrorsStateCopy.summaryFields,
    summaryFieldsArray
  )

  const updateSkip = useCallback(
    amount => {
      return () => {
        let newSkip = customPagination.skip + amount
        newSkip = Math.min(newSkip, 100)

        newSkip = Math.max(newSkip, 0)

        let tempPage
        if (amount < 0) {
          tempPage = customPagination.currentPage - 1
        } else {
          tempPage = customPagination.currentPage + 1
        }
        setCustomPagination({
          skip: newSkip,
          numberOfPages: customPagination.numberOfPages,
          currentPage: tempPage
        })
      }
    },
    [customPagination.skip, customPagination.currentPage, customPagination.numberOfPages]
  )

  const updateSkipAdditional = useCallback(
    amount => {
      return () => {
        let newSkip = customPaginationAdditional.skip + amount
        newSkip = Math.min(newSkip, 100)

        newSkip = Math.max(newSkip, 0)

        let tempPage
        if (amount < 0) {
          tempPage = customPaginationAdditional.currentPage - 1
        } else {
          tempPage = customPaginationAdditional.currentPage + 1
        }
        setCustomPaginationAdditional({
          skip: newSkip,
          numberOfPages: customPaginationAdditional.numberOfPages,
          currentPage: tempPage
        })
      }
    },
    [
      customPaginationAdditional.skip,
      customPaginationAdditional.currentPage,
      customPaginationAdditional.numberOfPages
    ]
  )

  const changePo = useCallback(data => {
    setPoInformation(data)
    if (!data) {
      setLines(old => {
        const linesCopy = [...old.lineItems]
        // eslint-disable-next-line
        for (const [i, v] of linesCopy.entries()) {
          if (specialPOLines.some(line => line.value === linesCopy[i]['PO_LINE_NUMBER'])) {
            continue
          }
          linesCopy[i]['PO_LINE_NUMBER'] = ''
        }
        return { ...old, lineItems: linesCopy }
      })
      return
    }
    setLines(old => {
      const linesCopy = [...old.lineItems]
      const po = Object.values(data.lineItemsPo ?? {})
      const descriptionDetailsPO = po.map(line => line.description ?? '')

      if (descriptionDetailsPO.length === 0) {
        return old
      }

      // eslint-disable-next-line
      for (const [i, v] of linesCopy.entries()) {
        if (specialPOLines.some(line => line.value === linesCopy[i]['PO_LINE_NUMBER'])) {
          continue
        }
        const matches = findBestMatch(v.ITEM, descriptionDetailsPO)
        const { bestMatchIndex } = matches

        const targetPo = po[bestMatchIndex]
        linesCopy[i]['PO_LINE_NUMBER'] = targetPo.lineNumber
      }
      return { ...old, lineItems: linesCopy }
    })
  }, [])

  useEffect(() => {
    if (!lineItemKey?.key) {
      setCellSelected(null)
      return
    }
    const key = `${lineItemKey.key},${lineItemKey.column}`
    setCellSelected({ [key]: true })

    const lineItemIndex = lines.lineItems.findIndex(line => line.key === lineItemKey?.key)

    if (lineItemIndex < 0) {
      return
    }

    const linePage = Math.floor(lineItemIndex / dataPerPage) + 1
    const trimStart = (linePage - 1) * dataPerPage
    setCustomPagination(old => ({
      ...old,
      skip: trimStart,
      currentPage: linePage
    }))
    if (gridRef && gridRef.current) {
      gridRef.current.scrollToId(lineItemKey.key, {
        duration: 300,
        force: true
      })
      gridRef.current.scrollToColumn(columnsData.columnPos[lineItemKey.column] ?? 0, {
        force: true,
        duration: 300
      })
    }
    // eslint-disable-next-line
  }, [lineItemKey?.key, lineItemKey?.column, gridRef])

  useEffect(() => {
    if (!additionalChargeKey?.key) {
      setCellSelected(null)
      return
    }
    const key = `${additionalChargeKey.key},${additionalChargeKey.column}`
    setCellSelected({ [key]: true })

    const additionalChargeIndex = charges.additionalCharges.findIndex(
      charge => charge.key === additionalChargeKey?.key
    )

    if (additionalChargeIndex < 0) {
      return
    }

    const linePage = Math.floor(additionalChargeIndex / dataPerPage) + 1
    const trimStart = (linePage - 1) * dataPerPage
    setCustomPaginationAdditional(old => ({
      ...old,
      skip: trimStart,
      currentPage: linePage
    }))
    if (gridAdditionalRef && gridAdditionalRef.current) {
      gridAdditionalRef.current.scrollToId(additionalChargeKey.key, {
        duration: 300,
        force: true
      })
      gridAdditionalRef.current.scrollToColumn(
        columnsAdditionalData.columnPos[additionalChargeKey.column] ?? 0,
        {
          force: true,
          duration: 300
        }
      )
    }
    // eslint-disable-next-line
  }, [additionalChargeKey?.key, additionalChargeKey?.column, gridAdditionalRef])

  useEffect(() => {
    const {
      validationErrors,
      lineItems,
      additionalCharges,
      summaryFields,
      poInformation,
      accountSegments
    } = ocrInformation.data
    const validationErrorsTemp = _.merge({}, validationErrors)
    setValidationErrorsState(validationErrorsTemp)
    setInvoiceVersion(invoiceData?.version ?? null)
    setLines({
      lineItems: _.cloneDeep(lineItems),
      lineItemsBoundingBox: {},
      lineItemsAccountSegments: _.cloneDeep(accountSegments)
    })
    setPoInformation({ lineItemsPo: poInformation })
    setCharges({
      additionalCharges: _.cloneDeep(additionalCharges),
      additionalChargesBoundingBox: {}
    })

    const nonPO = summaryFields.find(field => field.type === 'NON_PO')
    if (nonPO?.value.text === 'true') {
      setValidation(old => ({
        ...old,
        PONF: false,
        SNMPO: false,
        POSNVSISN: false
      }))
    } else {
      setValidation(old => ({
        ...old,
        PONF: true,
        SNMPO: true,
        POSNVSISN: true
      }))
    }
  }, [ocrInformation.data, classes, invoiceVersion, invoiceData.version, accountSegmentsEnabled])

  useEffect(() => {
    const fields = summaryFields.map(summaryField => {
      const rules = requiredFields[`header#${summaryField.type}`] ?? {}
      if (summaryField.type === 'VENDOR_ID') {
        return {
          id: summaryField.id,
          type: summaryField.type,
          geometry: {},
          value: {
            supplierId: summaryField.value?.text ?? '',
            name: ''
          },
          page: summaryField.page,
          visible: summaryField.visible,
          isCustomField: summaryField.isCustomField
        }
      }
      if (summaryField.type === 'VENDOR_NAME') {
        return {
          id: summaryField.id,
          type: summaryField.type,
          geometry: {},
          value: {
            supplierId: '',
            name: summaryField.value?.text ?? ''
          },
          page: summaryField.page,
          visible: summaryField.visible,
          isCustomField: summaryField.isCustomField
        }
      }
      if (summaryField.type === 'INVOICE_RECEIPT_DATE') {
        return {
          id: summaryField.id,
          type: summaryField.type,
          geometry: {},
          value:
            summaryField.value?.text && summaryField.value?.text !== ''
              ? summaryField.value?.text
              : null,
          page: summaryField.page,
          visible: summaryField.visible,
          isCustomField: summaryField.isCustomField
        }
      }

      if (
        (summaryField.type === 'BILL_TO' || summaryField.type === 'RECEIVER_ADDRESS') &&
        rules.typeField === 'companyAddress'
      ) {
        return {
          id: summaryField.id,
          type: summaryField.type,
          geometry: {},
          value: {
            fullAddress: summaryField.value?.text ?? '',
            companyAddressId: ''
          },
          page: summaryField.page,
          visible: summaryField.visible,
          isCustomField: summaryField.isCustomField
        }
      }

      if (summaryField.type === 'NON_PO') {
        return {
          id: summaryField.id,
          type: summaryField.type,
          geometry: {},
          value: summaryField.value?.text === 'true' ? true : false,
          page: summaryField.page,
          visible: summaryField.visible
        }
      }

      if (summaryField.type === 'HIGH_PRIORITY_FLAG') {
        return {
          id: summaryField.id,
          type: summaryField.type,
          geometry: {},
          value: summaryField.value?.text === 'true' ? true : false,
          page: summaryField.page,
          visible: summaryField.visible
        }
      }
      if (rules?.typeField === 'boolean') {
        //if (summaryField.type === 'QA_APPROVAL') {
        return {
          id: summaryField.id,
          type: summaryField.type,
          geometry: {},
          value: summaryField.value?.text === 'true',
          page: summaryField.page,
          visible: summaryField.visible,
          isCustomField: rules?.isCustomField
        }
      }

      return {
        id: summaryField.id,
        type: summaryField.type,
        geometry: {},
        value: summaryField.value?.text ?? '',
        page: summaryField.page,
        visible: summaryField.visible,
        isCustomField: summaryField.isCustomField
      }
    })

    const otherFields = notRecognizedFields.map(otherField => {
      return {
        id: otherField.id,
        geometry: {
          type: 'OTHER',
          id: otherField.id,
          boundingBox: otherField.value?.geometry?.boundingBox
        },
        type: '',
        value: otherField.value?.text,
        label: otherField.label?.text,
        page: otherField.page
      }
    })
    reset({ summaryFields: fields, otherFields })
  }, [reset, summaryFields, notRecognizedFields, requiredFields])

  useEffect(() => {
    if (!summaryFieldsArray) {
      return
    }
    trigger()
  }, [summaryFieldsArray, trigger, tab])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') {
        return
      }

      const arrayData = name.split('.')
      const mainField = arrayData[0]
      const position = arrayData[1]
      const subField = arrayData[2]

      if (mainField === 'summaryFields') {
        return
      }
      if (subField !== 'type') {
        return
      }

      const subFieldValue = value[mainField][position][subField]

      if (subFieldValue === '') {
        return
      }

      const index = value['summaryFields'].findIndex(
        summaryField => summaryField.type === subFieldValue
      )

      if (index < 0) {
        return
      }

      const processField = value['summaryFields'].find(
        summaryField => summaryField.type === 'PROCESS'
      )

      const processFieldValue = processField?.value ?? ''

      const targetField = value[mainField][position]
      const typeExist = value['otherFields'].findIndex(
        otherField => otherField.type === subFieldValue && otherField.id !== targetField.id
      )

      const summaryField = value['summaryFields'][index]

      const rules = requiredFields[`header#${summaryField.type}`] ?? {}
      const data = formatField(targetField.value, rules, processFieldValue)

      if (summaryField.type === 'PURCHASE_ORDER') {
        setIsPoWarning(true)
        changePo({ poData: null })
        firstRunPOField.current = true
      }

      if (summaryField.type === 'VENDOR_NAME') {
        setValueForm(
          `summaryFields.${index}.value`,
          {
            supplierId: '',
            name: data
          },
          {
            shouldValidate: true,
            shouldDirty: true
          }
        )
      } else if (summaryField.type === 'VENDOR_ID') {
        setValueForm(
          `summaryFields.${index}.value`,
          {
            supplierId: data,
            name: ''
          },
          {
            shouldValidate: true,
            shouldDirty: true
          }
        )
      } else if (
        (summaryField.type === 'BILL_TO' || summaryField.type === 'RECEIVER_ADDRESS') &&
        rules.typeField === 'companyAddress'
      ) {
        setValueForm(
          `summaryFields.${index}.value`,
          {
            fullAddress: data,
            companyAddressId: ''
          },
          {
            shouldValidate: true,
            shouldDirty: true
          }
        )
      } else {
        setValueForm(`summaryFields.${index}.value`, data, {
          shouldValidate: true,
          shouldDirty: true
        })
      }

      setValueForm(`summaryFields.${index}.geometry`, targetField.geometry)
      if (typeExist >= 0) {
        setValueForm(`otherFields.${typeExist}.type`, '')
      }
      setMappings(true)
      setAnnotationUnrecognizedField({
        unrecognizedField: targetField.geometry.id,
        recognizedField: value['summaryFields'][index].id,
        pageRecognizedField: value['summaryFields'][index].page
      })
    })
    return () => subscription.unsubscribe()
  }, [
    watch,
    updateSummaryField,
    updateOtherField,
    setValueForm,
    setAnnotationUnrecognizedField,
    requiredFields,
    changePo
  ])

  const removeWarningLineItem = useCallback((columnId, lineItemKey) => {
    setValidationErrorsState(old => {
      const previousValidation = old[`${columnId}.${lineItemKey}`] ?? {}
      const newErrorState = {
        ...old,
        [`${columnId}.${lineItemKey}`]: {
          ...previousValidation,
          lineItem: lineItemKey,
          messages: [{ type: 'success', message: 'Accepted by User' }],
          valid: true
        }
      }

      return newErrorState
    })
  }, [])

  function addErrorAndWarningLineItem(rows) {
    // eslint-disable-next-line
    for (const row of rows) {
      setValidationErrorsState(old => {
        const newErrorState = {
          ...old,
          [`${row.columnId}.${row.lineItemKey}`]: row.validation
        }
        return newErrorState
      })
    }
  }

  useEffect(() => {
    if (!summaryFieldId?.id || !getTextOcr.data || !summaryFieldId?.shouldSetValue) {
      return
    }
    if (getTextOcr.isError) {
      return
    }
    if (!getTextOcr.isSuccess) {
      return
    }

    let data = getTextOcr.data.data.value
    const summaryFields = getValues('summaryFields')
    const processField = summaryFields.find(summaryField => summaryField.type === 'PROCESS')

    const processValue = processField?.value ?? ''

    if (summaryFieldId.type === 'header') {
      const index = summaryFields.findIndex(summaryField => summaryField.id === summaryFieldId.id)
      if (index < 0) {
        onChangeSummaryField(null)
        return
      }
      const summaryField = summaryFields[index]

      if (summaryField.type === 'PURCHASE_ORDER') {
        firstRunPOField.current = true
      }

      const rules = requiredFields[`${summaryFieldId.type}#${summaryField.type}`] ?? {}

      const otherFields = getValues('otherFields')
      const otherFieldsMatch = otherFields.filter(otherField => {
        return (
          valueComparison(data, otherField.value, rules) &&
          otherField.page === summaryFieldId.ocr.page
        )
      })
      data = formatField(data, rules, processValue)

      let otherField
      let indexOtherField = -1
      if (otherFieldsMatch.length === 1) {
        otherField = otherFieldsMatch[0]
        indexOtherField = otherFields.findIndex(x => x.id === otherField.id)
      } else if (otherFieldsMatch.length > 1) {
        const distance = otherFieldsMatch
          .map(x => {
            const midPointOtherField = midPointBoundingBox(x.geometry.boundingBox)
            const midPointNewField = midPointBoundingBox(summaryFieldId.ocr.boundingBox)
            const distance = getDistance({
              xA: midPointOtherField.x,
              yA: midPointOtherField.y,
              xB: midPointNewField.x,
              yB: midPointNewField.y
            })
            return {
              ...x,
              distance
            }
          })
          .sort(function(a, b) {
            return a.distance - b.distance
          })

        otherField = distance[0]
        indexOtherField = otherFields.findIndex(x => x.id === otherField.id)
      }

      if (otherField?.geometry?.type === 'OTHER' && indexOtherField >= 0) {
        const typeExist = otherFields.findIndex(
          field => field.type === otherField.type && field.id !== otherField.id
        )
        setValueForm(`otherFields.${indexOtherField}.type`, summaryField.type)

        if (typeExist >= 0) {
          setValueForm(`otherFields.${typeExist}.type`, '')
        }
        setValueForm(`summaryFields.${index}.geometry`, otherField.geometry)
        setValueForm(`summaryFields.${index}.page`, otherField.page)
        setMappings(true)
      } else {
        setValueForm(`summaryFields.${index}.geometry`, summaryFieldId.ocr)
        setValueForm(`summaryFields.${index}.page`, summaryFieldId.ocr.page)
      }

      if (summaryField.type === 'VENDOR_NAME') {
        setValueForm(
          `summaryFields.${index}.value`,
          {
            supplierId: '',
            name: data
          },
          {
            shouldValidate: true,
            shouldDirty: true
          }
        )
        onChangeSummaryField(null)
        return
      }

      if (
        (summaryField.type === 'BILL_TO' || summaryField.type === 'RECEIVER_ADDRESS') &&
        rules.typeField === 'companyAddress'
      ) {
        setValueForm(
          `summaryFields.${index}.value`,
          {
            fullAddress: data,
            companyAddressId: ''
          },
          {
            shouldValidate: true,
            shouldDirty: true
          }
        )
        onChangeSummaryField(null)
        return
      }

      setValueForm(`summaryFields.${index}.value`, data, {
        shouldValidate: true,
        shouldDirty: true
      })
    } else {
      const copyLines =
        summaryFieldId.type === 'additionalCharges'
          ? [...charges.additionalCharges]
          : [...lines.lineItems]

      const index = copyLines.findIndex(line => line.key === summaryFieldId?.id)
      const lineItem = copyLines.find(line => line.key === summaryFieldId?.id)

      if (index < 0) {
        onChangeSummaryField(null)
        return
      }

      const rules = requiredFields[`${summaryFieldId.type}#${summaryFieldId.column}`] ?? {}
      data = formatField(data, rules, processValue)

      const columnData =
        summaryFieldId.type === 'additionalCharges'
          ? ColumnsOrderAdditionalCharges[summaryFieldId.column]
          : ColumnsOrderLineItems[summaryFieldId.column]

      const validate = validateInput(rules, data)
      if (!validate.valid) {
        setCreateError({
          message: `Invalid Input for ${columnData.label}`,
          isOpen: true,
          color: 'danger'
        })
        onChangeSummaryField(null)
        return
      }

      copyLines[index][summaryFieldId.column] = data

      if (summaryFieldId.type === 'additionalCharges') {
        setCharges(charges => ({
          ...charges,
          additionalCharges: [...copyLines],
          additionalChargesBoundingBox: {
            ...charges.additionalChargesBoundingBox,
            [`${summaryFieldId?.id}#${summaryFieldId.column}`]: {
              boundingBox: summaryFieldId.ocr.boundingBox,
              page: summaryFieldId.ocr.page,
              type: 'ADDED'
            }
          }
        }))
      } else {
        setLines(lines => ({
          ...lines,
          lineItems: [...copyLines],
          lineItemsBoundingBox: {
            ...lines.lineItemsBoundingBox,
            [`${summaryFieldId?.id}#${summaryFieldId.column}`]: {
              boundingBox: summaryFieldId.ocr.boundingBox,
              page: summaryFieldId.ocr.page,
              type: 'ADDED'
            }
          }
        }))
      }

      removeWarningLineItem(summaryFieldId.column, lineItem.key)
    }
    onChangeSummaryField(null)
    // eslint-disable-next-line
  }, [
    getTextOcr,
    getValues,
    setValueForm,
    summaryFieldId,
    onChangeSummaryField,
    setLines,
    removeWarningLineItem,
    setCharges
  ])

  useEffect(() => {
    if (!dataDelete.isDelete) {
      return
    }
    if (dataDelete.section === 'header') {
      const summaryFields = getValues('summaryFields')

      const index = summaryFields.findIndex(summaryField => summaryField.id === dataDelete.key)

      if (index < 0) {
        setDataDelete({ isDelete: false })
        return
      }

      const summaryField = summaryFields[index]

      let value = ''

      if (summaryField.type === 'PURCHASE_ORDER') {
        changePo({ poData: null })
      }

      if (summaryField.type === 'INVOICE_RECEIPT_DATE') {
        value = null
      }

      if (summaryField.type === 'VENDOR_ID' || summaryField.type === 'VENDOR_NAME') {
        value = {
          name: '',
          supplierId: ''
        }
      }

      setValueForm(`summaryFields.${index}.value`, value, {
        shouldValidate: true,
        shouldDirty: true
      })
      setValueForm(`summaryFields.${index}.geometry`, { type: 'OCR_DELETED' })
      const otherFields = getValues('otherFields')
      const typeExist = otherFields.findIndex(otherField => otherField.type === summaryField.type)
      if (typeExist >= 0) {
        setValueForm(`otherFields.${typeExist}.type`, '')
        const isEmpty = otherFields
          .filter(otherField => otherField.type === summaryField.type)
          .every(otherField => otherField.type === '')

        if (isEmpty) {
          setMappings(false)
        }
      }
    } else {
      const copyLines =
        dataDelete.section === 'additionalCharges'
          ? [...charges.additionalCharges]
          : [...lines.lineItems]

      const index = copyLines.findIndex(line => line.key === dataDelete.key)

      if (index < 0) {
        setDataDelete({ isDelete: false })
        return
      }

      copyLines[index][dataDelete.column] = ''
      const copyBoundingBox =
        dataDelete.section === 'additionalCharges'
          ? { ...charges.additionalChargesBoundingBox }
          : { ...lines.lineItemsBoundingBox }

      copyBoundingBox[`${dataDelete.key}#${dataDelete.column}`] = {
        boundingBox: null,
        page: null,
        type: 'DELETED'
      }
      if (dataDelete.section === 'additionalCharges') {
        setCharges({
          ...charges,
          additionalCharges: copyLines,
          additionalChargesBoundingBox: copyBoundingBox
        })
      } else {
        setLines({
          ...lines,
          lineItems: copyLines,
          lineItemsBoundingBox: copyBoundingBox
        })
      }
    }
    setDataDelete({ isDelete: false })
    // eslint-disable-next-line
  }, [
    dataDelete.isDelete,
    // eslint-disable-next-line
    dataDelete?.column,
    // eslint-disable-next-line
    dataDelete?.section,
    // eslint-disable-next-line
    dataDelete?.key,
    getValues,
    setValueForm,
    setLines,
    setCharges,
    setDataDelete
  ])

  useEffect(() => {
    if (!dropDownItem) {
      return
    }
    const data = [...lines.lineItems]
    const indexRow = data.findIndex(line => line.key === dropDownItem?.index)

    if (indexRow < 0) {
      return
    }
    data[indexRow]['PO_LINE_NUMBER'] = dropDownItem?.value
    setLines({ ...lines, lineItems: data })
    removeWarningLineItem('PO_LINE_NUMBER', data[indexRow].key)
    setDropDownItem(null)
    // eslint-disable-next-line
  }, [dropDownItem?.index, dropDownItem?.value, removeWarningLineItem])

  useEffect(() => {
    if (!dropDownItemAdditional) {
      return
    }
    const data = [...charges.additionalCharges]
    data[(dropDownItemAdditional?.index)]['CHARGE_TYPE'] = dropDownItemAdditional?.value

    setCharges({ ...charges, additionalCharges: data })
    removeWarningLineItem('CHARGE_TYPE', data[(dropDownItemAdditional?.index)].key)
    setDropDownItemAdditional(null)
    // eslint-disable-next-line
  }, [dropDownItemAdditional?.index, dropDownItemAdditional?.value])

  useEffect(() => {
    if (tab !== 2) {
      setGridRef(null)
      setSelectedLines({ selected: {}, unselected: {} })
    }
    if (tab !== 1) {
      setGridAdditionalRef(null)
      setSelectedLines({ selected: {}, unselected: {} })
    }
  }, [tab])

  useEffect(() => {
    if (!validationInvoice.isLoading && validationInvoice.isError) {
      setCreateError({
        message:
          validationInvoice.error.response?.data?.message ??
          'Something went wrong, try again later',
        isOpen: true,
        color: 'danger'
      })
    }
  }, [validationInvoice.isLoading, validationInvoice.isError, validationInvoice.error])

  const onCellClick = useCallback(
    (event, cellProps) => {
      const { name, data } = cellProps
      let tempPage = pageNumber
      const ocr =
        tab === 1
          ? charges.additionalChargesBoundingBox[`${data.key}#${name}`]
          : lines.lineItemsBoundingBox[`${data.key}#${name}`]
      const originalPage =
        tab === 1 ? originalAdditionalCharges[data.key] : originalLineItems[data.key]

      if (ocr) {
        if (ocr.type === 'ADDED') {
          tempPage = ocr.page
        }
      } else if (originalPage && originalPage[name]) {
        tempPage = originalPage[name].page
      }

      onChangeSummaryField({
        id: data.key,
        type: tab === 1 ? 'additionalCharges' : 'lineItems',
        column: name,
        page: tempPage
      })
      setCellSelected({ [`${data.key},${name}`]: true })
    },
    [
      onChangeSummaryField,
      originalLineItems,
      pageNumber,
      lines.lineItemsBoundingBox,
      charges.additionalChargesBoundingBox,
      originalAdditionalCharges,
      tab
    ]
  )

  const onEditComplete = useCallback(
    ({ value, columnId, data }) => {
      const section = tab === 1 ? 'additionalCharges' : 'lineItems'
      const rules = requiredFields[`${section}#${columnId}`] ?? {}

      const columnData =
        tab === 1 ? ColumnsOrderAdditionalCharges[columnId] : ColumnsOrderLineItems[columnId]
      const validate = validateInput(rules, value)
      if (!validate.valid) {
        setCreateError({
          message: validate.message ?? `Invalid Input for ${columnData.label}`,
          isOpen: true,
          color: 'danger'
        })
        return
      }
      const copyLineItems = tab === 1 ? [...charges.additionalCharges] : [...lines.lineItems]
      const lineItemIndex = copyLineItems.findIndex(line => line.key === data?.key)
      const lineItem = copyLineItems.find(line => line.key === data?.key)

      if (lineItemIndex < 0) {
        return
      }
      copyLineItems[lineItemIndex][columnId] = value

      if (tab === 1) {
        setCharges({ ...charges, additionalCharges: copyLineItems })
      } else {
        setLines({ ...lines, lineItems: copyLineItems })
      }

      removeWarningLineItem(columnId, lineItem.key)
    },
    [lines, requiredFields, removeWarningLineItem, charges, tab]
  )

  const handleChange = (event, newValue) => {
    onChangeTab(old => {
      if (old === 0 && old !== newValue) {
        setValidationErrorsStateCopy(JSON.parse(JSON.stringify(errors)))
      }
      return newValue
    })
  }

  const handleChangeIndex = index => {
    onChangeTab(old => {
      if (old === 0 && old !== index) {
        setValidationErrorsStateCopy(JSON.parse(JSON.stringify(errors)))
      }
      return index
    })
  }

  const onSelectionChange = useCallback(({ selected, unselected }) => {
    setSelectedLines({ selected: selected, unselected: unselected })
  }, [])

  const setTextInputRef = (element, index) => {
    ref.current[index] = element
  }

  const onIgnore = () => {
    history.push(`/admin/invoiceAI/${invoiceId}/details`)
  }

  const onSubmit = event => {
    event.preventDefault()

    if (
      accountSegmentsEnabled &&
      requiredFields[`lineItems#${ColumnsOrderLineItems.ACCOUNTING.type}`]?.required
    ) {
      let isValidSegments = true

      const rows = []
      // eslint-disable-next-line
      for (const row of Object.values(lines.lineItems)) {
        let isValidLineAccount = true
        const accountSegment = lines.lineItemsAccountSegments[row.key]

        if (!accountSegment || accountSegment.accountSegments.length === 0) {
          isValidSegments = false
          rows.push({
            columnId: ColumnsOrderLineItems.ACCOUNTING.type,
            lineItemKey: row.key,
            validation: {
              lineItem: row.key,
              messages: 'Invalid Accounting Information',
              ruleId: '',
              messageTitle: 'Accounting Information',
              section: 'Line Item'
            }
          })

          continue
        }
        isValidLineAccount = accountSegment.accountSegments.every(x => x.accountId)
        if (!isValidLineAccount) {
          isValidSegments = false
          rows.push({
            columnId: ColumnsOrderLineItems.ACCOUNTING.type,
            lineItemKey: row.key,
            validation: {
              lineItem: row.key,
              messages: 'Invalid Accounting Information',
              ruleId: '',
              messageTitle: 'Accounting Information',
              section: 'Line Item'
            }
          })
          continue
        }
      }

      if (!isValidSegments) {
        addErrorAndWarningLineItem(rows)
        setSubmitModal({
          isOpen: true,
          data: null,
          messageBody: 'One or more lines are missing accounting information.',
          successBtn: false
        })
        return
      }
    }

    const data = getValues()
    const newData = transFormData(data)
    if (!newData) {
      return
    }

    const dataUpdated = {
      summaryFields: newData.summaryFields,
      lineItems: lines,
      additionalCharges: charges,
      mappings: mappings,
      validations: validation,
      validationErrors: validationErrorsState
    }

    let messageBody = <p>Are you sure you want to submit this invoice?</p>

    const isProcessChanged = isProcessDocumentChanged(data)

    if (!isProcessChanged) {
      setSubmitModal({ isOpen: true, data: dataUpdated, messageBody })
      return
    }

    messageBody = (
      <>
        <p>
          Invoice Country change has been detected, this action will add or remove information from
          the invoice.
        </p>
        <p>Are you sure you want to submit this invoice?</p>
      </>
    )
    setSubmitModal({ isOpen: true, data: dataUpdated, messageBody })
  }

  const onSubmitModal = () => {
    validationInvoice.mutate(submitModal.data, {
      onSettled: () => {
        queryClient.resetQueries(['ocrInvoice', invoiceId], {
          exact: true
        })
      },
      onSuccess: () => {
        switch (options[selectedIndex]) {
          case 'Submit':
            history.push(`/admin/invoiceAI/${invoiceId}/details`)
            break
          case 'Next':
            if (invoicesExceptions) {
              if (!invoicesExceptions.next) {
                if (!invoicesExceptions.previous) {
                  history.push(`/admin/invoiceAI/index`)
                }
                handleOnChangeInvoice(invoicesExceptions?.previous)
              } else {
                handleOnChangeInvoice(invoicesExceptions?.next)
              }
            } else {
              history.push(`/admin/invoiceAI/index`)
            }
            break
          case 'Exit':
            history.push(`/admin/invoiceAI/index`)
            break
          default:
            break
        }
      }
    })
  }

  const transFormData = data => {
    const summaryFieldsCopy = [...data.summaryFields]
    const indexVendorId = summaryFields.findIndex(summaryField => summaryField.type === 'VENDOR_ID')

    const indexVendorName = summaryFields.findIndex(
      summaryField => summaryField.type === 'VENDOR_NAME'
    )

    const indexBillAddress = summaryFields.findIndex(
      summaryField => summaryField.type === 'BILL_TO'
    )

    const indexReceiverAddress = summaryFields.findIndex(
      summaryField => summaryField.type === 'RECEIVER_ADDRESS'
    )

    const indexReceiverNonPO = summaryFields.findIndex(
      summaryField => summaryField.type === 'NON_PO'
    )

    const indexHighPriorityFlag = summaryFields.findIndex(
      summaryField => summaryField.type === 'HIGH_PRIORITY_FLAG'
    )

    const indexQaApprovalFlag = summaryFields.findIndex(
      summaryField => summaryField.type === 'CUSTOM_QA_APPROVAL'
    )

    const indexManualApprovalFlag = summaryFields.findIndex(
      summaryField => summaryField.type === 'CUSTOM_MANUAL_APPROVAL'
    )

    if (
      indexVendorId < 0 ||
      indexVendorName < 0 ||
      indexBillAddress < 0 ||
      indexReceiverAddress < 0 ||
      indexReceiverNonPO < 0
    ) {
      setCreateError({
        message: 'Something went wrong, try again later',
        isOpen: true,
        color: 'danger'
      })
      return
    }

    if (indexHighPriorityFlag > 0) {
      summaryFieldsCopy[indexHighPriorityFlag] = {
        ...data.summaryFields[indexHighPriorityFlag],
        value: `${data.summaryFields[indexHighPriorityFlag].value}`
      }
    }

    summaryFieldsCopy[indexVendorId] = {
      ...data.summaryFields[indexVendorId],
      value: data.summaryFields[indexVendorId].value.supplierId
    }

    summaryFieldsCopy[indexReceiverNonPO] = {
      ...data.summaryFields[indexReceiverNonPO],
      value: `${data.summaryFields[indexReceiverNonPO].value}`
    }

    summaryFieldsCopy[indexVendorName] = {
      ...data.summaryFields[indexVendorName],
      value: data.summaryFields[indexVendorName].value.name
    }

    const billToValue = data.summaryFields[indexBillAddress].value?.fullAddress ?? ''

    const formBillValue = data.summaryFields[indexBillAddress].value

    if (typeof formBillValue === 'object' && 'fullAddress' in formBillValue) {
      summaryFieldsCopy[indexBillAddress] = {
        ...data.summaryFields[indexBillAddress],
        value: billToValue
      }
    }

    const shipToValue = data.summaryFields[indexReceiverAddress].value?.fullAddress ?? ''

    const formShipValue = data.summaryFields[indexReceiverAddress].value

    if (typeof formShipValue === 'object' && 'fullAddress' in formShipValue) {
      summaryFieldsCopy[indexReceiverAddress] = {
        ...data.summaryFields[indexReceiverAddress],
        value: shipToValue
      }
    }

    if (indexQaApprovalFlag > 0) {
      summaryFieldsCopy[indexQaApprovalFlag] = {
        ...data.summaryFields[indexQaApprovalFlag],
        value: `${data.summaryFields[indexQaApprovalFlag].value}`
      }
    }

    if (indexManualApprovalFlag > 0) {
      summaryFieldsCopy[indexManualApprovalFlag] = {
        ...data.summaryFields[indexManualApprovalFlag],
        value: `${data.summaryFields[indexManualApprovalFlag].value}`
      }
    }

    return { ...data, summaryFields: summaryFieldsCopy }
  }

  const onUpdateData = data => {
    const newData = transFormData(data)
    if (!newData) {
      return
    }

    updateData(
      {
        summaryFields: newData.summaryFields,
        lineItems: lines,
        additionalCharges: charges,
        mappings: mappings,
        validationErrors: validationErrorsState
      },
      {
        onSettled: (response, err, data) => {
          const isProcessChanged = isProcessDocumentChanged(data)
          if (isProcessChanged) {
            queryClient.resetQueries(['ocrInvoice', invoiceId], {
              exact: true
            })
          } else {
            queryClient.invalidateQueries(['invoices', invoiceId])
          }
          queryClient.invalidateQueries(['ocrInvoice', invoiceId])
        },
        onSuccess: (_, data) => {
          const isProcessChanged = isProcessDocumentChanged(data)
          if (isProcessChanged) {
            history.push(`/admin/invoiceAI/${invoiceId}/details`)
            return
          }
          setSubmitSuccess({ message: 'Saved', isOpen: true })
        },
        onError: error => {
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }

  const onConfirmSaveModal = () => {
    const data = getValues()
    onUpdateData(data)
  }

  function isProcessDocumentChanged(data) {
    const localProcess = data.summaryFields.find(field => field.type === 'PROCESS')
    const originalProcess = summaryFields.find(field => field.type === 'PROCESS')
    if (!localProcess || !originalProcess) {
      return false
    }

    if (localProcess.value === originalProcess.value?.text) {
      return false
    }

    return true
  }

  const onSave = () => {
    const data = getValues()

    const isProcessChanged = isProcessDocumentChanged(data)

    if (!isProcessChanged) {
      onUpdateData(data)
      return
    }
    setSimpleModal(old => ({ ...old, isOpenSaveModal: true }))
  }

  const handleClickMenuAddress = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenuAddress = () => {
    setAnchorEl(null)
  }

  function leftRightFields() {
    const invoiceData = []
    const vendorInfo = []
    const invoiceAmount = []
    const addressInfo = []
    const start = []
    const taxInformation = []
    const customInformation = []

    // eslint-disable-next-line
    for (const [index, summaryField] of summaryFieldsArray.entries()) {
      const rules = requiredFields[`header#${summaryField.type}`] ?? {}

      if (!summaryField.visible) {
        continue
      }

      const validationsError = validationErrors[summaryField.type]?.messages ?? ''
      let jsxElement
      const pos = HeaderFieldsSide[summaryField.type]?.pos ?? 0
      let label = HeaderFieldsSide[summaryField.type]?.label

      label = label ?? rules.label
      label = label ?? summaryField.type.replace(/_/g, ' ')

      switch (summaryField.type) {
        case HeaderFieldsSide.CURRENCY.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                <AutoCompleteCountries
                  summaryField={summaryField}
                  control={control}
                  rules={rules}
                  index={index}
                  key={summaryField.id}
                  label={label}
                  validationsError={validationsError}
                  setValidationErrorsState={setValidationErrorsState}
                  validationErrorsState={validationErrorsState}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.VENDOR_ID.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                <SupplierSearch
                  summaryField={summaryField}
                  control={control}
                  index={index}
                  rules={rules}
                  key={summaryField.id}
                  getValues={getValues}
                  setValueForm={setValueForm}
                  label={label}
                  validationsError={validationsError}
                  setValidationErrorsState={setValidationErrorsState}
                  validationErrorsState={validationErrorsState}
                  appId={appId}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.INVOICE_RECEIPT_DATE.type:
        case HeaderFieldsSide.DUE_DATE.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                <DatePickerField
                  summaryField={summaryField}
                  onChangeSummaryField={onChangeSummaryField}
                  control={control}
                  rules={rules}
                  index={index}
                  key={summaryField.id}
                  label={label}
                  validationsError={validationsError}
                  setValidationErrorsState={setValidationErrorsState}
                  validationErrorsState={validationErrorsState}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.BILL_TO.type:
        case HeaderFieldsSide.RECEIVER_ADDRESS.type:
          if (rules.typeField === 'companyAddress') {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                  <AddressSearch
                    summaryField={summaryField}
                    onChangeSummaryField={onChangeSummaryField}
                    setTextInputRef={setTextInputRef}
                    control={control}
                    index={index}
                    rules={rules}
                    appId={appId}
                    getValues={getValues}
                    label={label}
                    setValueForm={setValueForm}
                    validationsError={validationsError}
                    setValidationErrorsState={setValidationErrorsState}
                    validationErrorsState={validationErrorsState}
                  />
                </Grid>
              ),
              pos
            }
          } else {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                  <HeaderField
                    summaryField={summaryField}
                    onChangeSummaryField={onChangeSummaryField}
                    setTextInputRef={setTextInputRef}
                    control={control}
                    index={index}
                    rules={rules}
                    multiline={true}
                    key={summaryField.id}
                    label={label}
                    validationsError={validationsError}
                    setValidationErrorsState={setValidationErrorsState}
                    validationErrorsState={validationErrorsState}
                  />
                </Grid>
              ),
              pos
            }
          }

          break
        case HeaderFieldsSide.NON_PO.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                <SwitchField
                  summaryField={summaryField}
                  control={control}
                  rules={rules}
                  index={index}
                  key={summaryField.id}
                  label={label}
                  onChange={value => {
                    setValidation(old => ({
                      ...old,
                      PONF: !value,
                      SNMPO: !value,
                      POSNVSISN: !value
                    }))
                    trigger()
                  }}
                  validationsError={validationsError}
                  setValidationErrorsState={setValidationErrorsState}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.HIGH_PRIORITY_FLAG.type:
          jsxElement = {
            jsx: (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                key={summaryField.id}
                style={{ marginBottom: '20px' }}
              >
                <SwitchField
                  summaryField={summaryField}
                  control={control}
                  rules={rules}
                  index={index}
                  key={summaryField.id}
                  label={label}
                  validationsError={validationsError}
                  setValidationErrorsState={setValidationErrorsState}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.INVOICE_TYPE.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={6} lg={6} key={summaryField.id}>
                <SelectInvoiceType
                  summaryField={summaryField}
                  control={control}
                  rules={rules}
                  index={index}
                  key={summaryField.id}
                  label={label}
                  validationsError={validationsError}
                  setValidationErrorsState={setValidationErrorsState}
                  validationErrorsState={validationErrorsState}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.PURCHASE_ORDER.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                <PoSearch
                  setIsPoWarning={setIsPoWarning}
                  isPoWarning={isPoWarning}
                  summaryField={summaryField}
                  onChangeSummaryField={onChangeSummaryField}
                  control={control}
                  index={index}
                  rules={rules}
                  key={summaryField.id}
                  getValues={getValues}
                  setTextInputRef={setTextInputRef}
                  setValueForm={setValueForm}
                  appConfig={appConfig}
                  label={label}
                  onChangePo={data => {
                    changePo(data)
                    applyPoMappings({
                      companyCode: false,
                      currency: false,
                      vendorIdVendorName: false,
                      buyerShipAddress: false,
                      buyerBillAddress: false,
                      paymentTerms: false,
                      matchPoVsLine: true,
                      accounting: false,
                      details: false,
                      supplierShipAddress: false
                    })
                  }}
                  validationsError={validationsError}
                  setValidationRules={setValidation}
                  lines={lines}
                  ref={firstRunPOField}
                  setValidationErrorsState={setValidationErrorsState}
                  validationErrorsState={validationErrorsState}
                  setPoInformation={setPoInformation}
                  setPoModal={setPoModal}
                  appId={appId}
                  //onApplyMappings={applyPoMappings}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.VENDOR_NAME.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                <SupplierSearchName
                  summaryField={summaryField}
                  onChangeSummaryField={onChangeSummaryField}
                  setTextInputRef={setTextInputRef}
                  control={control}
                  index={index}
                  rules={rules}
                  key={summaryField.id}
                  getValues={getValues}
                  setValueForm={setValueForm}
                  label={label}
                  validationsError={validationsError}
                  setValidationErrorsState={setValidationErrorsState}
                  validationErrorsState={validationErrorsState}
                  appId={appId}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.PROCESS.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={6} lg={6} key={summaryField.id}>
                <SelectProcess
                  summaryField={summaryField}
                  onChangeSummaryField={onChangeSummaryField}
                  control={control}
                  index={index}
                  rules={rules}
                  key={summaryField.id}
                  appConfig={appConfig.data}
                  label={label}
                  validationsError={validationsError}
                  setValidationErrorsState={setValidationErrorsState}
                  validationErrorsState={validationErrorsState}
                  processList={processList}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.COMPANY_CODE.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                <SelectCompanyCode
                  summaryField={summaryField}
                  onChangeSummaryField={onChangeSummaryField}
                  control={control}
                  index={index}
                  rules={rules}
                  key={summaryField.id}
                  getValues={getValues}
                  setValueForm={setValueForm}
                  appConfig={appConfig.data}
                  label={label}
                  validationsError={validationsError}
                  setValidationErrorsState={setValidationErrorsState}
                  validationErrorsState={validationErrorsState}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.TAX.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                <HeaderField
                  summaryField={summaryField}
                  onChangeSummaryField={onChangeSummaryField}
                  setTextInputRef={setTextInputRef}
                  control={control}
                  index={index}
                  rules={rules}
                  multiline={false}
                  key={summaryField.id}
                  label={label}
                  validationsError={validationsError}
                  setValidationErrorsState={setValidationErrorsState}
                  validationErrorsState={validationErrorsState}
                  readOnly={true}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.OTHER_CHARGES.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                <HeaderField
                  summaryField={summaryField}
                  onChangeSummaryField={onChangeSummaryField}
                  setTextInputRef={setTextInputRef}
                  control={control}
                  index={index}
                  rules={rules}
                  multiline={false}
                  key={summaryField.id}
                  label={label}
                  validationsError={validationsError}
                  setValidationErrorsState={setValidationErrorsState}
                  validationErrorsState={validationErrorsState}
                  readOnly={true}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.SUPPLIER_REMIT_TO_ADDRESS.type:
        case HeaderFieldsSide.SUPPLIER_SHIP_FROM_ADDRESS.type:
          if (!rules.visible) {
            break
          }
          if (
            rules.typeField === 'supplierLocation' ||
            rules.typeField === 'supplierRemitToAddress'
          ) {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                  <SupplierAddressSelect
                    summaryField={summaryField}
                    onChangeSummaryField={onChangeSummaryField}
                    setTextInputRef={setTextInputRef}
                    control={control}
                    index={index}
                    rules={rules}
                    getValues={getValues}
                    label={label}
                    setValueForm={setValueForm}
                    validationsError={validationsError}
                    setValidationErrorsState={setValidationErrorsState}
                    validationErrorsState={validationErrorsState}
                    appId={appId}
                  />
                </Grid>
              ),
              pos
            }
          } else {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                  <HeaderField
                    summaryField={summaryField}
                    onChangeSummaryField={onChangeSummaryField}
                    setTextInputRef={setTextInputRef}
                    control={control}
                    index={index}
                    rules={rules}
                    multiline={true}
                    key={summaryField.id}
                    label={label}
                    validationsError={validationsError}
                    setValidationErrorsState={setValidationErrorsState}
                    validationErrorsState={validationErrorsState}
                  />
                </Grid>
              ),
              pos
            }
          }
          break
        case HeaderFieldsSide.APP_ID.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={6} lg={6} key={summaryField.id}>
                <AutoCompleteAppList
                  summaryField={summaryField}
                  onChangeSummaryField={onChangeSummaryField}
                  setTextInputRef={setTextInputRef}
                  control={control}
                  index={index}
                  rules={rules}
                  label={label}
                  validationsError={validationsError}
                  setValidationErrorsState={setValidationErrorsState}
                  validationErrorsState={validationErrorsState}
                  onChangeAutoComplete={data => {
                    if (data.value === appId) {
                      return
                    }

                    setSimpleModal(old => ({ ...old, isOpenAppListModal: true }))
                  }}
                  appId={appId}
                  defaultAppId={defaultAppId}
                />
              </Grid>
            ),
            pos
          }
          break
        default: {
          if (rules.typeField?.startsWith('ocrFieldValues#')) {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                  <AutoCompleteAsyncValuesHeader
                    summaryField={summaryField}
                    onChangeSummaryField={onChangeSummaryField}
                    setTextInputRef={setTextInputRef}
                    control={control}
                    index={index}
                    rules={rules}
                    label={label}
                    validationsError={validationsError}
                    setValidationErrorsState={setValidationErrorsState}
                    validationErrorsState={validationErrorsState}
                  />
                </Grid>
              ),
              pos
            }
          } else if (rules?.typeField === 'boolean') {
            jsxElement = {
              jsx: (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  key={summaryField.id}
                  style={{ marginBottom: '20px' }}
                >
                  <SwitchField
                    summaryField={summaryField}
                    control={control}
                    rules={rules}
                    index={index}
                    key={summaryField.id}
                    label={label}
                    validationsError={validationsError}
                    setValidationErrorsState={setValidationErrorsState}
                  />
                </Grid>
              ),
              pos
            }
          } else {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                  <HeaderField
                    summaryField={summaryField}
                    onChangeSummaryField={onChangeSummaryField}
                    setTextInputRef={setTextInputRef}
                    control={control}
                    index={index}
                    rules={rules}
                    multiline={false}
                    key={summaryField.id}
                    label={label}
                    validationsError={validationsError}
                    setValidationErrorsState={setValidationErrorsState}
                    validationErrorsState={validationErrorsState}
                    readOnly={rules.isReadOnly}
                  />
                </Grid>
              ),
              pos
            }
          }

          break
        }
      }

      if (!jsxElement) {
        continue
      }

      if (rules?.isCustomField) {
        customInformation.push(jsxElement)
        continue
      }

      switch (HeaderFieldsSide[summaryField.type]?.section) {
        case 'invoiceData':
          invoiceData.push(jsxElement)
          break
        case 'vendorInfo':
          vendorInfo.push(jsxElement)
          break
        case 'invoiceAmount':
          invoiceAmount.push(jsxElement)
          break
        case 'addressInfo':
          addressInfo.push(jsxElement)
          break
        case 'start':
          start.push(jsxElement)
          break
        case 'taxInfo':
          taxInformation.push(jsxElement)
          break
        default:
          break
      }
    }

    invoiceData.sort(sortElements)
    vendorInfo.sort(sortElements)
    invoiceAmount.sort(sortElements)
    addressInfo.sort(sortElements)
    start.sort(sortElements)
    taxInformation.sort(sortElements)
    customInformation.sort(sortElements)

    const invoiceDataJsx = invoiceData.map(jsxElement => jsxElement.jsx)
    const vendorInfoJsx = vendorInfo.map(jsxElement => jsxElement.jsx)
    const invoiceAmountJsx = invoiceAmount.map(jsxElement => jsxElement.jsx)
    const addressInfoJsx = addressInfo.map(jsxElement => jsxElement.jsx)
    const startJsx = start.map(jsxElement => jsxElement.jsx)
    const taxInfoJsx = taxInformation.map(jsxElement => jsxElement.jsx)
    const customInfoJsx = customInformation.map(jsxElement => jsxElement.jsx)

    const isLineSupplierShipAddressVisible =
      requiredFields[`lineItems#${ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.type}`]?.visible
    const isLineBuyerShipAddressVisible =
      requiredFields[`lineItems#${ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.type}`]?.visible

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={3}>
            {startJsx.length > 2 ? <Grid item xs={6} sm={6} md={6} lg={6}></Grid> : null}
            {startJsx.length !== 0 ? startJsx : null}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Key Invoice Data</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {invoiceDataJsx.length !== 0 ? invoiceDataJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Invoice Amount Information</b>
                  </h5>

                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {invoiceAmountJsx.length !== 0 ? invoiceAmountJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Vendor Information</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {vendorInfoJsx.length !== 0 ? vendorInfoJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    style={{
                      display: 'flex'
                    }}
                  >
                    <h5 className={classes.colorPrimary}>
                      <b>Address Information</b>
                    </h5>
                    {isLineSupplierShipAddressVisible || isLineBuyerShipAddressVisible ? (
                      <>
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={handleClickMenuAddress}
                        >
                          <SyncIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={openMenuAddress}
                          onClose={handleCloseMenuAddress}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5,
                              width: '30ch'
                            }
                          }}
                        >
                          <MenuItem onClick={copyHeaderAddressToRows}>
                            synchronize with line items
                          </MenuItem>
                        </Menu>
                      </>
                    ) : null}
                  </Box>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {addressInfoJsx.length !== 0 ? addressInfoJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {taxInfoJsx.length !== 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h5 className={classes.colorPrimary}>
                      <b>Tax Information</b>
                    </h5>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2} alignItems="center">
                      {taxInfoJsx}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            </Grid>
          </Grid>
        ) : null}
        {customInfoJsx.length !== 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h5 className={classes.colorPrimary}>
                      <b>Client Managed Fields</b>
                    </h5>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2} alignItems="center">
                      {customInfoJsx}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    )
  }

  const notRecognizedFieldsJsx = otherFields.map((notRecognizedField, index) => {
    return (
      <OtherField
        notRecognizedField={notRecognizedField}
        control={control}
        setTextInputRef={setTextInputRef}
        onChangeField={onChangeUnrecognizedField}
        key={notRecognizedField.id}
        selectOptions={selectOptions}
        index={index}
      />
    )
  })

  const cloneLineItem = rowProps => {
    const newElement = {}
    const columns = tab === 1 ? columnsAdditionalData.columns : columnsData.columns

    // eslint-disable-next-line
    for (const column of columns) {
      if (column.name === 'key') {
        newElement[column.name] = uuidv4()
        continue
      }
      if (column.name === 'LINE_NUMBER') {
        newElement[column.name] = ''
        continue
      }
      newElement[column.name] = rowProps.data[column.name] ?? ''
    }

    if (tab === 1) {
      setCharges(old => {
        const newAdditionalCharges = {
          ...old,
          additionalCharges: [...old.additionalCharges, newElement]
        }
        updatePagination(newAdditionalCharges.additionalCharges)
        return newAdditionalCharges
      })
    } else {
      setLines(old => {
        const newLineItems = {
          ...old,
          lineItems: [...old.lineItems, newElement]
        }
        updatePagination(newLineItems.lineItems)
        return newLineItems
      })
    }

    const rows = []
    const key = rowProps.id
    // eslint-disable-next-line
    for (const validation in validationErrorsState) {
      if (validation.includes(key) && !validationErrorsState[validation].valid) {
        rows.push({
          columnId: validation.split('.')[0],
          lineItemKey: newElement.key,
          validation: {
            lineItem: newElement.key,
            messages: validationErrorsState[validation].messages,
            ruleId: validationErrorsState[validation].ruleId,
            messageTitle: validationErrorsState[validation].messageTitle,
            section: tab === 1 ? 'Additional Charge' : 'Line Item'
          }
        })
      }
    }
    addErrorAndWarningLineItem(rows)
  }

  const updatePagination = lineItems => {
    const newNumberOfPages = Math.ceil(lineItems.length / dataPerPage)
    const newSkip = (newNumberOfPages - 1) * dataPerPage

    if (tab === 1) {
      setCustomPaginationAdditional(oldPagination => ({
        ...oldPagination,
        skip: newSkip,
        numberOfPages: newNumberOfPages,
        currentPage: newNumberOfPages
      }))
    } else {
      setCustomPagination(oldPagination => ({
        ...oldPagination,
        skip: newSkip,
        numberOfPages: newNumberOfPages,
        currentPage: newNumberOfPages
      }))
    }
  }

  const splitLineItem = rowProps => {
    const newLineItems = []

    const cantLineItems = Number(rowProps.data.QUANTITY ?? '0')
    let unitPrice = rowProps.data.UNIT_PRICE
    const total = rowProps.data.PRICE

    if (cantLineItems === 0 || cantLineItems === 1 || cantLineItems < 0) {
      return
    }

    if (!unitPrice && !total) {
      return
    }

    if (!unitPrice && total) {
      unitPrice = `${Number(total) / cantLineItems}`
    }

    newLineItems.push({
      ...rowProps.data,
      QUANTITY: '1',
      UNIT_PRICE: unitPrice,
      PRICE: unitPrice
    })

    for (let index = 0; index < cantLineItems - 1; index++) {
      const newElement = {}
      // eslint-disable-next-line
      for (const column of columnsData.columns) {
        if (column.name === 'key') {
          newElement[column.name] = uuidv4()
          continue
        }
        if (column.name === 'LINE_NUMBER') {
          newElement[column.name] = ''
          continue
        }

        if (column.name === 'QUANTITY') {
          newElement[column.name] = '1'
          continue
        }

        if (column.name === 'UNIT_PRICE' || column.name === 'PRICE') {
          newElement[column.name] = unitPrice
          continue
        }
        newElement[column.name] = rowProps.data[column.name] ?? ''
      }
      newLineItems.push(newElement)
    }

    setLines(old => {
      const lineItemsUpdated = old.lineItems.filter(item => item.key !== rowProps.id)

      const lineItemsInvoice = {
        ...old,
        lineItems: [...lineItemsUpdated, ...newLineItems]
      }
      updatePagination(lineItemsInvoice.lineItems)

      return lineItemsInvoice
    })

    const rows = []
    const key = rowProps.id
    // eslint-disable-next-line
    for (const newLine of newLineItems) {
      // eslint-disable-next-line
      for (const validation in validationErrorsState) {
        if (validation.includes(key) && !validationErrorsState[validation].valid) {
          rows.push({
            columnId: validation.split('.')[0],
            lineItemKey: newLine.key,
            validation: {
              lineItem: newLine.key,
              messages: validationErrorsState[validation].messages,
              ruleId: validationErrorsState[validation].ruleId,
              messageTitle: validationErrorsState[validation].messageTitle,
              section: 'Line Item'
            }
          })
        }
      }
    }
    addErrorAndWarningLineItem(rows)
  }

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    menuProps.autoDismiss = true
    menuProps.items = [
      {
        label: 'Clone Line',
        onClick: () => cloneLineItem(rowProps)
      }
    ]
    if (tab === 2) {
      menuProps.items.splice(1, 0, {
        label: 'Split Line',
        onClick: () => splitLineItem(rowProps)
      })
    }

    const typeMsg = checkErrorOrWarning(
      validationErrorsState[`${cellProps.id}.${rowProps.id}`]?.messages
    )

    const validated = validationErrorsState[`${cellProps.id}.${rowProps.id}`]?.valid ?? null

    if (typeMsg === 'warning' && !validated) {
      menuProps.items.unshift({
        label: 'Accept Warning',
        onClick: () => removeWarningLineItem(cellProps.id, rowProps.id)
      })
    }
  }

  const onClose = () => {
    setDeleteModal({ isOpen: false, index: null })
  }

  const copyHeaderAddressToRows = () => {
    const summaryFields = getValues('summaryFields')
    const headerSupplierAddress = summaryFields.find(
      summaryField => summaryField.type === HeaderFieldsSide.SUPPLIER_SHIP_FROM_ADDRESS.type
    )
    const headerBuyerAddress = summaryFields.find(
      summaryField => summaryField.type === HeaderFieldsSide.RECEIVER_ADDRESS.type
    )

    const headerSupplierAddressId = summaryFields.find(
      summaryField => summaryField.type === `${HeaderFieldsSide.SUPPLIER_SHIP_FROM_ADDRESS.type}_ID`
    )
    const headerBuyerAddressId = summaryFields.find(
      summaryField => summaryField.type === `${HeaderFieldsSide.RECEIVER_ADDRESS.type}_ID`
    )

    setLines(oldLines => {
      return {
        ...oldLines,
        lineItems: oldLines.lineItems.map(x => {
          const lineBuyerAddress =
            headerBuyerAddress?.value?.fullAddress ??
            x[ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.type]
          const lineBuyerAddressId =
            headerBuyerAddressId?.value ?? x[ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.id]
          const lineSupplierAddress =
            headerSupplierAddress?.value ?? x[ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.type]
          const lineSupplierAddressId =
            headerSupplierAddressId?.value ?? x[ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.id]
          return {
            ...x,
            [ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.type]: lineBuyerAddress,
            [ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.id]: lineBuyerAddressId,
            [ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.type]: lineSupplierAddress,
            [ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.id]: lineSupplierAddressId
          }
        })
      }
    })

    const rows = []
    const lineBuyerAddressId = headerBuyerAddressId?.value

    const lineSupplierAddressId = headerSupplierAddressId?.value
    // eslint-disable-next-line
    for (const lineItem of lines.lineItems) {
      if (lineBuyerAddressId) {
        rows.push({
          columnId: ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.type,
          lineItemKey: lineItem.key
        })
      }
      if (lineSupplierAddressId) {
        rows.push({
          columnId: ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.type,
          lineItemKey: lineItem.key
        })
      }
    }

    removeErrorAndWarningLineItem(rows)
    handleCloseMenuAddress()
  }

  function removeErrorAndWarningLineItem(rows) {
    if (rows.length === 0) {
      return
    }
    setValidationErrorsState(old => {
      const newErrorState = { ...old }
      // eslint-disable-next-line
      for (const row of rows) {
        const previousValidation = old[`${row.columnId}.${row.lineItemKey}`] ?? {}
        newErrorState[`${row.columnId}.${row.lineItemKey}`] = {
          ...previousValidation,
          lineItem: row.lineItemKey,
          messages: [{ type: 'success', message: 'Accepted by User' }],
          valid: true
        }
      }

      return newErrorState
    })
  }

  const deleteRows = (selectedLines, charges, lines, options = {}) => {
    const deleteBoundingBox = Object.values(selectedLines)
    const boundingBoxToDelete = Object.entries(selectedLines)
    const isAdditionalChargesTab = tab === 1 || options.isForAdditionalChargesTab

    if (tab !== 1) {
      deleteBoundingBox.sort((first, second) => {
        return Number(first.LINE_NUMBER ?? 10000) - Number(second.LINE_NUMBER ?? 10000)
      })

      boundingBoxToDelete.sort((first, second) => {
        return Number(first[1].LINE_NUMBER ?? 10000) - Number(second[1].LINE_NUMBER ?? 10000)
      })
    }

    if (options.actionType === 'MERGE') {
      deleteBoundingBox.shift()
      boundingBoxToDelete.shift()
    }

    const linesToDelete = deleteBoundingBox.map(x => x.key)

    if (linesToDelete.length === 0) {
      return
    }

    const data = isAdditionalChargesTab ? charges.additionalCharges : lines.lineItems
    const dataDelete = data.filter(item => !linesToDelete.includes(item.key))

    const newNumberOfPages = Math.ceil(dataDelete.length / dataPerPage)
    setActionBoundingBox({
      rows: deleteBoundingBox,
      section: isAdditionalChargesTab ? 'additionalCharges' : 'lineItems',
      actionType: 'DELETE',
      ...options
    })

    if (isAdditionalChargesTab) {
      // eslint-disable-next-line
      for (const [key, row] of boundingBoxToDelete) {
        // eslint-disable-next-line
        for (const column of Object.keys(row)) {
          charges.additionalChargesBoundingBox[`${key}#${column}`] = {
            boundingBox: null,
            page: null,
            type: 'DELETED'
          }
        }
      }
      setCharges({
        ...charges,
        additionalCharges: dataDelete,
        additionalChargesBoundingBox: charges.additionalChargesBoundingBox
      })
      setCustomPaginationAdditional(old => ({
        ...old,
        numberOfPages: newNumberOfPages
      }))
    } else {
      // eslint-disable-next-line
      for (const [key, row] of boundingBoxToDelete) {
        // eslint-disable-next-line
        for (const column of Object.keys(row)) {
          lines.lineItemsBoundingBox[`${key}#${column}`] = {
            boundingBox: null,
            page: null,
            type: 'DELETED'
          }
        }
      }
      setLines({
        ...lines,
        lineItems: dataDelete,
        lineItemsBoundingBox: lines.lineItemsBoundingBox
      })
      setCustomPagination(old => ({
        ...old,
        numberOfPages: newNumberOfPages
      }))
    }

    const rowsValidationUpdate = Object.values(selectedLines).map(x => x.key)
    const rows = []
    // eslint-disable-next-line
    for (const deletedLine of rowsValidationUpdate) {
      // eslint-disable-next-line
      for (const validation in validationErrorsState) {
        if (validation.includes(deletedLine) && !validationErrorsState[validation].valid) {
          rows.push({
            columnId: validation.split('.')[0],
            lineItemKey: deletedLine
          })
        }
      }
    }

    removeErrorAndWarningLineItem(rows)
    setSelectedLines({ selected: {}, unselected: {} })
  }

  const onChangeSkip = skip => {
    setCustomPagination(old => {
      return {
        ...old,
        skip: skip,
        currentPage: 1
      }
    })
  }

  const onAddNewRow = () => {
    if (lines.lineItems.length > 0) {
      let isEmpty = true
      const lastItem = lines.lineItems[lines.lineItems.length - 1]
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(lastItem)) {
        if (key === 'key') {
          continue
        }
        if (value !== '') {
          isEmpty = false
        }
      }

      if (isEmpty) {
        setCreateError({
          isOpen: true,
          message: 'More than one empty row is not allowed',
          color: 'danger'
        })
        return
      }
    }

    const copyLines = [...lines.lineItems]

    const newElement = {}

    const rows = []
    const lineKey = uuidv4()
    // eslint-disable-next-line
    for (const column of columnsData.columns) {
      if (column.name === 'key') {
        newElement[column.name] = lineKey
        continue
      }
      const rules = requiredFields[`lineItems#${column.name}`]
      if (rules && rules.required) {
        let label = ColumnsOrderLineItems[column.name]?.label ?? rules.label
        label = label ?? column.name
        rows.push({
          columnId: column.name,
          lineItemKey: lineKey,
          validation: {
            lineItem: lineKey,
            messages: `${label} field is required`,
            ruleId: '',
            messageTitle: column.name,
            section: 'Line Item'
          }
        })
      }

      newElement[column.name] = ''
    }
    copyLines.push(newElement)

    setLines({ ...lines, lineItems: copyLines })

    const newNumberOfPages = Math.ceil(copyLines.length / dataPerPage)
    const newSkip = (newNumberOfPages - 1) * dataPerPage
    addErrorAndWarningLineItem(rows)
    setCustomPagination(old => ({
      ...old,
      skip: newSkip,
      numberOfPages: newNumberOfPages,
      currentPage: newNumberOfPages
    }))
  }

  const onAddNewRowAdditional = () => {
    if (charges.additionalCharges.length > 0) {
      let isEmpty = true
      const lastItem = charges.additionalCharges[charges.additionalCharges.length - 1]
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(lastItem)) {
        if (key === 'key') {
          continue
        }
        if (value !== '') {
          isEmpty = false
        }
      }

      if (isEmpty) {
        setCreateError({
          isOpen: true,
          message: 'More than one empty row is not allowed',
          color: 'danger'
        })
        return
      }
    }

    const copyLines = [...charges.additionalCharges]

    const newElement = {}

    // eslint-disable-next-line
    for (const column of columnsAdditionalData.columns) {
      if (column.name === 'key') {
        newElement[column.name] = uuidv4()
        continue
      }
      newElement[column.name] = ''
    }
    copyLines.push(newElement)

    setCharges({ ...charges, additionalCharges: copyLines })

    const newNumberOfPages = Math.ceil(copyLines.length / dataPerPage)
    const newSkip = (newNumberOfPages - 1) * dataPerPage

    setCustomPaginationAdditional(old => ({
      ...old,
      skip: newSkip,
      numberOfPages: newNumberOfPages,
      currentPage: newNumberOfPages
    }))
  }

  async function changeInvoiceStatus(status, message) {
    const body = { status: status, message: message }
    updateStatusInvoice.mutate(body, {
      onSettled: () => {
        queryClient.invalidateQueries(['invoices', invoiceId])
      },
      onSuccess: () => {
        history.push(`/admin/invoiceAI/${invoiceId}/details`)
      },
      onError: error => {
        setCreateError({
          isOpen: true,
          message: error.response?.data?.message ?? 'Something went wrong, try again later',
          color: 'danger'
        })
      }
    })
  }

  function updateLineItemsAndHeader(data) {
    const headerData = Object.values(data.header ?? {})
    const lineItemsData = Object.entries(data.lineItems ?? {})
    const accountingLineItemsData = Object.values(data.accounting?.lineItems ?? {})
    const summaryFields = getValues('summaryFields')

    if (headerData.length > 0) {
      const fieldToChangeValidation = []

      headerData.forEach(function(data) {
        const index = summaryFields.findIndex(summaryField => summaryField.type === data.field)
        const rules = requiredFields[`header#${data.field}`] ?? {}
        if (index < 0) {
          return
        }
        fieldToChangeValidation.push(data.field)

        let newValue = data.value

        if (data.field === HeaderFieldsSide.VENDOR_ID.type) {
          newValue = {
            supplierId: data.value ?? '',
            name: ''
          }
        } else if (data.field === HeaderFieldsSide.VENDOR_NAME.type) {
          newValue = {
            supplierId: '',
            name: data.value ?? ''
          }
        } else if (
          (data.field === HeaderFieldsSide.BILL_TO.type ||
            data.field === HeaderFieldsSide.RECEIVER_ADDRESS.type) &&
          rules.typeField === 'companyAddress'
        ) {
          newValue = {
            fullAddress: data.value ?? '',
            companyAddressId: ''
          }
        }

        setValueForm(`summaryFields.${index}.value`, newValue, {
          shouldValidate: true,
          shouldDirty: true
        })

        if (data.field === HeaderFieldsSide.NON_PO.type) {
          const indexPO = summaryFields.findIndex(
            summaryField => summaryField.type === HeaderFieldsSide.PURCHASE_ORDER.type
          )
          if (indexPO >= 0) {
            trigger(`summaryFields.${indexPO}.value`)
          }
        }
      })

      removeHeaderFieldsErrorsAndWarning(fieldToChangeValidation)
    }

    const rowsToRemoveErrors = []
    const copyLineItems = [...lines.lineItems]
    let newLineItems = []
    const newLineErrors = []
    let rowsToDelete = []
    if (lineItemsData.length > 0) {
      const unselectedKeys = Object.keys(data.lineItems ?? {})
      rowsToDelete = Object.values(lines.lineItems).filter(x => !unselectedKeys.includes(x.key))

      // eslint-disable-next-line
      for (const [key, value] of lineItemsData) {
        const lineData = Object.values(value)

        const lineIndex = copyLineItems.findIndex(x => x.key === key)
        if (lineIndex < 0) {
          // New Line
          const newElement = {}

          const lineKey = uuidv4()
          // eslint-disable-next-line
          for (const column of columnsData.columns) {
            if (column.name === 'key') {
              newElement[column.name] = lineKey
              continue
            }
            newElement[column.name] = ''
          }
          lineData.forEach(function(data) {
            newElement[data.field] = data.value
          })

          Object.keys(newElement).forEach(columnName => {
            const rules = requiredFields[`lineItems#${columnName}`]
            if (rules && rules.required && !newElement[columnName]) {
              let label = ColumnsOrderLineItems[columnName]?.label ?? rules.label
              label = label ?? columnName
              newLineErrors.push({
                columnId: columnName,
                lineItemKey: lineKey,
                validation: {
                  lineItem: lineKey,
                  messages: `${label} field is required`,
                  ruleId: '',
                  messageTitle: columnName,
                  section: 'Line Item'
                }
              })
            }
          })

          newLineItems.push(newElement)
        } else {
          // Update Line
          const lineUpdated = { ...copyLineItems[lineIndex] }
          lineData.forEach(function(data) {
            lineUpdated[data.field] = data.value

            rowsToRemoveErrors.push({
              columnId: data.field,
              lineItemKey: key
            })
          })
          newLineItems.push(lineUpdated)
        }
      }
    }

    const copyAccountingLineItems = { ...lines.lineItemsAccountSegments }
    if (accountingLineItemsData.length > 0) {
      accountingLineItemsData.forEach(function(data) {
        if (copyAccountingLineItems[data.id]) {
          copyAccountingLineItems[data.id] = {
            ...copyAccountingLineItems[data.id],
            accountSegments: data.accountSegments.map(x => {
              const oldAccountSegments = copyAccountingLineItems[data.id].accountSegments?.find(
                newSegment => newSegment.id === x.id
              )
              if (oldAccountSegments) {
                return {
                  ...x,
                  accountId: x.accountId ? x.accountId : 'relishTBD',
                  segments: {
                    ...oldAccountSegments.segments,
                    ...x.segments
                  }
                }
              }
              return {
                ...x,
                accountId: x.accountId ? x.accountId : 'relishTBD'
              }
            })
          }
        } else {
          copyAccountingLineItems[data.id] = {
            ...data,
            accountSegments: data.accountSegments.map(x => ({
              ...x,
              accountId: x.accountId ? x.accountId : 'relishTBD'
            }))
          }
        }

        rowsToRemoveErrors.push({
          columnId: ColumnsOrderLineItems.ACCOUNTING.type,
          lineItemKey: data.id
        })
      })
    }

    if (newLineItems.length === 0) {
      newLineItems = copyLineItems
    }

    if (rowsToDelete.length === 0) {
      setLines(old => ({
        ...old,
        lineItems: newLineItems,
        lineItemsAccountSegments: copyAccountingLineItems
      }))
    } else {
      const tempLines = {
        ...lines,
        lineItems: newLineItems,
        lineItemsAccountSegments: copyAccountingLineItems
      }
      deleteRows(rowsToDelete, charges, tempLines)
    }
    addErrorAndWarningLineItem(newLineErrors)
    removeErrorAndWarningLineItem(rowsToRemoveErrors)
  }

  function updateAdditionalCharges(data) {
    const additionalChargesData = Object.entries(data.additionalCharges ?? {})

    const rowsToRemoveErrors = []
    const copyAdditionalCharges = [...charges.additionalCharges]
    let newAdditionalCharges = []
    const newAdditionalChargesErrors = []
    let rowsToDelete = []
    if (additionalChargesData.length > 0) {
      const unselectedKeys = Object.keys(data.additionalCharges ?? {})
      rowsToDelete = Object.values(charges.additionalCharges).filter(
        x => !unselectedKeys.includes(x.key)
      )

      // eslint-disable-next-line
      for (const [key, value] of additionalChargesData) {
        const chargeData = Object.values(value)

        const chargeIndex = copyAdditionalCharges.findIndex(x => x.key === key)
        if (chargeIndex < 0) {
          // New Line
          const newElement = {}

          const chargeKey = uuidv4()
          // eslint-disable-next-line
          for (const column of columnsData.columns) {
            if (column.name === 'key') {
              newElement[column.name] = chargeKey
              continue
            }
            newElement[column.name] = ''
          }
          chargeData.forEach(function(data) {
            newElement[data.field] = data.value
          })

          Object.keys(newElement).forEach(columnName => {
            const rules = requiredFields[`additionalCharges#${columnName}`]
            if (rules && rules.required && !newElement[columnName]) {
              let label = ColumnsOrderLineItems[columnName]?.label ?? rules.label
              label = label ?? columnName
              newAdditionalChargesErrors.push({
                columnId: columnName,
                lineItemKey: chargeKey,
                validation: {
                  lineItem: chargeKey,
                  messages: `${label} field is required`,
                  ruleId: '',
                  messageTitle: columnName,
                  section: 'Line Item'
                }
              })
            }
          })

          newAdditionalCharges.push(newElement)
        } else {
          // Update Charge
          const chargeUpdated = { ...copyAdditionalCharges[chargeIndex] }
          chargeData.forEach(function(data) {
            chargeUpdated[data.field] = data.value

            rowsToRemoveErrors.push({
              columnId: data.field,
              lineItemKey: key
            })
          })
          newAdditionalCharges.push(chargeUpdated)
        }
      }
    }

    if (newAdditionalCharges.length === 0) {
      newAdditionalCharges = copyAdditionalCharges
    }

    if (rowsToDelete.length === 0) {
      setCharges(old => ({
        ...old,
        additionalCharges: newAdditionalCharges
      }))
    } else {
      const tempCharges = {
        ...charges,
        additionalCharges: newAdditionalCharges
      }

      deleteRows(rowsToDelete, tempCharges, lines, { isForAdditionalChargesTab: true })
    }
    addErrorAndWarningLineItem(newAdditionalChargesErrors)
    removeErrorAndWarningLineItem(rowsToRemoveErrors)
  }

  function applyPoMappingsLineItems(rowsSelected) {
    const { selected, unselected } = rowsSelected
    let rowsSelectedTemp = []

    if (typeof selected === 'boolean') {
      if (!selected) {
        return
      }

      rowsSelectedTemp = [...lines.lineItems]

      const unselectedKeys = Object.keys(unselected ?? {})
      rowsSelectedTemp = Object.values(rowsSelectedTemp).filter(
        x => !unselectedKeys.includes(x.key)
      )
    } else {
      rowsSelectedTemp = Object.values(selected)
    }

    return rowsSelectedTemp
  }

  function applyPoMappings(flags) {
    const data = getValues()
    const newData = transFormData(data)
    if (!newData) {
      return
    }

    const po = newData.summaryFields.find(
      summaryField => summaryField.type === HeaderFieldsSide.PURCHASE_ORDER.type
    )

    if (!po?.value) {
      return
    }

    const linesBody =
      tab === 0 ? lines : { ...lines, lineItems: applyPoMappingsLineItems(selectedLines) }

    const body = {
      summaryFields: newData.summaryFields,
      flags,
      lineItems: linesBody,
      poNumber: po.value,
      additionalCharges: charges
    }

    applyPOMappingsOcr.mutate(body, {
      onSuccess: (response, variables, context) => {
        const { data } = response

        updateLineItemsAndHeader(data)
        updateAdditionalCharges(data)
      },
      onError: error => {
        setCreateError({
          isOpen: true,
          message: error.response?.data?.message ?? 'Something went wrong, try again later',
          color: 'danger'
        })
      }
    })
  }

  function updateInvoiceOcrDataCustomRules() {
    const data = getValues()
    const newData = transFormData(data)
    if (!newData) {
      return
    }

    const body = {
      summaryFields: newData.summaryFields,
      lineItems: lines,
      additionalCharges: charges
    }

    applyCustomerMappings.mutate(body, {
      onSuccess: (response, variables, context) => {
        const { data } = response

        updateLineItemsAndHeader(data)
      },
      onError: error => {
        setCreateError({
          isOpen: true,
          message: error.response?.data?.message ?? 'Something went wrong, try again later',
          color: 'danger'
        })
      }
    })
  }

  function onResetModal(isActiveApplyMappings) {
    updateReset(
      { isActiveApplyMappings },
      {
        onSuccess: (_, data) => {
          const { isActiveApplyMappings } = data

          if (isActiveApplyMappings) {
            history.push(`/admin/invoiceAI/${invoiceId}/details`)
          }
          setPageNumber(1)
          onChangeTab(0)
          onChangeSummaryField(null)
        },
        onSettled: () => {
          queryClient.resetQueries(['ocrInvoice', invoiceId], {
            exact: true
          })
          queryClient.invalidateQueries(['invoices', invoiceId])
        },
        onError: error => {
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }

  function removeHeaderFieldsErrorsAndWarning(fieldToChangeValidation) {
    if (fieldToChangeValidation.length === 0) {
      return
    }

    setValidationErrorsState(old => {
      const newValidation = { ...old }
      // eslint-disable-next-line
      for (const summaryField of fieldToChangeValidation) {
        const previousValidation = newValidation[summaryField]
        if (!previousValidation) {
          continue
        }
        newValidation[summaryField] = {
          ...previousValidation,
          messages: [{ type: 'success', message: 'Accepted by User' }],
          valid: true
        }
      }

      return newValidation
    })
  }

  function updateInvoice() {
    const lineItemsUpdatedAmount = lines.lineItems.map(item => {
      if (item.PRICE && Number(item.PRICE) !== 0) {
        return item
      }

      if (item.UNIT_PRICE && item.QUANTITY) {
        const total = Number(item.UNIT_PRICE) * Number(item.QUANTITY)
        item.PRICE = `${total}`
        return item
      }

      return item
    })

    const summaryFields = getValues('summaryFields')

    const index = summaryFields.findIndex(
      summaryField => summaryField.type === HeaderFieldsSide.TAX.type
    )

    const totalFieldIndex = summaryFields.findIndex(
      summaryField => summaryField.type === HeaderFieldsSide.TOTAL.type
    )
    const subtotalFieldIndex = summaryFields.findIndex(
      summaryField => summaryField.type === HeaderFieldsSide.SUBTOTAL.type
    )
    const discountFieldIndex = summaryFields.findIndex(
      summaryField => summaryField.type === HeaderFieldsSide.DISCOUNT.type
    )

    const otherChargesFieldIndex = summaryFields.findIndex(
      summaryField => summaryField.type === HeaderFieldsSide.OTHER_CHARGES.type
    )

    if (
      index < 0 ||
      totalFieldIndex < 0 ||
      subtotalFieldIndex < 0 ||
      discountFieldIndex < 0 ||
      otherChargesFieldIndex < 0
    ) {
      return
    }
    let subtotalSum
    let taxSum
    let otherChargesSum

    const totalField = summaryFields[totalFieldIndex]
    const discountField = summaryFields[discountFieldIndex]

    let total = Number(totalField?.value ?? 0)
    let additionalChargesUpdated
    if (!invoiceVersion || invoiceVersion === 1) {
      const taxesLines = lineItemsUpdatedAmount.filter(x => x.PO_LINE_NUMBER?.includes('Tax'))

      const taxValues = taxesLines.map(line => Number(formatNumber(line.PRICE) ?? 0))
      const subtotal = []
      const otherCharges = [0]
      const additionalChargesValue = Object.values({ ...AdditionalCharges, ...CustomCharges }).map(
        additionalCharge => additionalCharge.value
      )
      const taxesValue = Object.values(Taxes).map(tax => tax.value)
      // eslint-disable-next-line
      for (const lineItem of lineItemsUpdatedAmount) {
        if (additionalChargesValue.includes(lineItem.PO_LINE_NUMBER)) {
          otherCharges.push(Number(lineItem.PRICE ?? 0))
        } else if (!taxesValue.includes(lineItem.PO_LINE_NUMBER)) {
          subtotal.push(Number(lineItem.PRICE ?? 0))
        }
      }

      taxSum = maxDecimalAndSummation(taxValues).summation
      otherChargesSum = maxDecimalAndSummation(otherCharges).summation
      subtotalSum = maxDecimalAndSummation(subtotal).summation
    } else {
      const additionalChargesValue = Object.values({ ...AdditionalCharges, ...CustomCharges }).map(
        additionalCharge => additionalCharge.value
      )
      const taxesLines = charges.additionalCharges.filter(x => x.CHARGE_TYPE?.includes('Tax'))
      const subtotal = lineItemsUpdatedAmount.map(lineItem => Number(lineItem.PRICE ?? 0))
      const taxValues = taxesLines.map(line => Number(formatNumber(line.TOTAL) ?? 0))
      const otherCharges = charges.additionalCharges.map(line => {
        if (additionalChargesValue.includes(line.CHARGE_TYPE)) {
          return Number(line.TOTAL ?? 0)
        }
        return 0
      })

      taxSum = maxDecimalAndSummation(taxValues).summation
      otherChargesSum = maxDecimalAndSummation(otherCharges).summation
      subtotalSum = maxDecimalAndSummation(subtotal).summation

      const taxableAmount = subtotalSum + otherChargesSum
      additionalChargesUpdated = charges.additionalCharges.map(line => {
        if (line.CHARGE_TYPE?.includes('Tax')) {
          if (line.RATE && Number(line.RATE) === 0) {
            const taxAmount = Number(line.TOTAL ?? 0)
            const taxRate = ((taxAmount / taxableAmount) * 100).toFixed(2)
            line.RATE = formatNumber(`${taxRate}`)
          }
        }
        return line
      })
    }
    const values = [subtotalSum, taxSum, otherChargesSum, -Number(discountField?.value ?? 0)]

    const { summation: totalSum } = maxDecimalAndSummation(values)
    total = totalSum

    setLines(old => ({
      ...old,
      lineItems: lineItemsUpdatedAmount
    }))

    setCharges(old => ({
      ...old,
      additionalCharges: additionalChargesUpdated
    }))

    setValueForm(`summaryFields.${index}.value`, `${taxSum}`, {
      shouldValidate: true,
      shouldDirty: true
    })
    setValueForm(`summaryFields.${totalFieldIndex}.value`, `${total}`, {
      shouldValidate: true,
      shouldDirty: true
    })
    setValueForm(`summaryFields.${otherChargesFieldIndex}.value`, `${otherChargesSum}`, {
      shouldValidate: true,
      shouldDirty: true
    })
    setValueForm(`summaryFields.${subtotalFieldIndex}.value`, `${subtotalSum}`, {
      shouldValidate: true,
      shouldDirty: true
    })
    setSubmitSuccess({
      message: 'Totals have been calculated',
      isOpen: true
    })

    const fieldToChangeValidation = [
      HeaderFieldsSide.TAX.type,
      HeaderFieldsSide.TOTAL.type,
      HeaderFieldsSide.SUBTOTAL.type,
      HeaderFieldsSide.DISCOUNT.type,
      HeaderFieldsSide.OTHER_CHARGES.type
    ]

    removeHeaderFieldsErrorsAndWarning(fieldToChangeValidation)

    handleChangeIndex(0)
  }

  function handleOnChangeInvoice(invoiceId) {
    onChangeInvoice(invoiceId)
    setIsPoWarning(true)
    setMappings(false)
  }

  function handleDeleteBatchLineItems() {
    setDeleteModal({ isOpen: true })
  }

  function handleMergeRows() {
    setSimpleModal(old => ({ ...old, isOpenCombineModal: true }))
  }

  function onSuccessMergeRows(isMerge, rowsSelected) {
    const tempCharges = { ...charges }
    const tempLines = { ...lines }

    const { selected, unselected } = rowsSelected

    let rowsSelectedTemp = {}
    let linesToDelete = []

    if (typeof selected === 'boolean') {
      if (!selected) {
        return
      }

      rowsSelectedTemp = tab === 1 ? { ...charges.additionalCharges } : { ...lines.lineItems }

      const unselectedKeys = Object.keys(unselected ?? {})
      const rowsUpdated = Object.values(rowsSelectedTemp).filter(
        x => !unselectedKeys.includes(x.key)
      )
      // eslint-disable-next-line
      rowsSelectedTemp = rowsUpdated.reduce((acc, curr) => ((acc[curr.key] = curr), acc), {})

      linesToDelete = Object.values(rowsSelectedTemp)
    } else {
      linesToDelete = Object.values(selected)
      rowsSelectedTemp = selected
      if (linesToDelete.length < 2) {
        return
      }
    }

    if (tab !== 1) {
      linesToDelete.sort((first, second) => {
        return Number(first.LINE_NUMBER ?? 10000) - Number(second.LINE_NUMBER ?? 10000)
      })
    }

    const firstRow = linesToDelete[0]
    const firstRowTemp = { ...firstRow }
    const updatedLineBox = []
    linesToDelete.shift()

    // eslint-disable-next-line
    for (const [key, value] of Object.entries(firstRow)) {
      if (generateConditionalMerge(key, value, isMerge)) {
        continue
      }
      // eslint-disable-next-line
      for (const row of linesToDelete) {
        const valueRow = row[key]
        if (valueRow) {
          firstRowTemp[key] = valueRow
          updatedLineBox.push(`${row.key}#${key}`)
          break
        }
      }
    }

    if (tab === 1) {
      if (!isMerge) {
        const totalValues = linesToDelete.map(x => Number(x.TOTAL ?? 0))
        totalValues.push(Number(firstRow.TOTAL ?? 0))
        const totalValue = maxDecimalAndSummation(totalValues).summation
        firstRowTemp.TOTAL = `${totalValue}`
      }
      const index = tempCharges.additionalCharges.findIndex(item => item.key === firstRow.key)
      if (index < 0) {
        return
      }
      tempCharges.additionalCharges[index] = firstRowTemp
      // eslint-disable-next-line
      for (const boundingBox of updatedLineBox) {
        const [key, column] = boundingBox.split('#')
        const ocr =
          tab === 1
            ? charges.additionalChargesBoundingBox[`${key}#${column}`]
            : lines.lineItemsBoundingBox[`${key}#${column}`]
        const originalPage = tab === 1 ? originalAdditionalCharges[key] : originalLineItems[key]

        let page = null
        let newBoundingBox = null
        if (ocr) {
          page = ocr.page
          newBoundingBox = ocr.boundingBox
        } else if (originalPage && originalPage[column]) {
          page = originalPage[column].page
          newBoundingBox = originalPage[column].value.geometry.boundingBox
        }

        tempCharges.additionalChargesBoundingBox[`${firstRow.key}#${column}`] = {
          boundingBox: newBoundingBox,
          page: page,
          type: 'ADDED'
        }
      }
    } else {
      if (!isMerge) {
        const totalValues = linesToDelete.map(x => Number(x.PRICE ?? 0))
        totalValues.push(Number(firstRow.PRICE ?? 0))
        const totalValue = maxDecimalAndSummation(totalValues).summation
        firstRowTemp.QUANTITY = '1'
        firstRowTemp.UNIT_PRICE = `${totalValue}`
        firstRowTemp.PRICE = `${totalValue}`
      }

      const index = tempLines.lineItems.findIndex(item => item.key === firstRow.key)
      if (index < 0) {
        return
      }
      tempLines.lineItems[index] = firstRowTemp
      // eslint-disable-next-line
      for (const boundingBox of updatedLineBox) {
        const [key, column] = boundingBox.split('#')
        const ocr =
          tab === 1
            ? charges.additionalChargesBoundingBox[`${key}#${column}`]
            : lines.lineItemsBoundingBox[`${key}#${column}`]
        const originalPage = tab === 1 ? originalAdditionalCharges[key] : originalLineItems[key]

        let page = null
        let newBoundingBox = null
        if (ocr) {
          page = ocr.page
          newBoundingBox = ocr.boundingBox
        } else if (originalPage && originalPage[column]) {
          page = originalPage[column].page
          newBoundingBox = originalPage[column].value.geometry.boundingBox
        }

        tempLines.lineItemsBoundingBox[`${firstRow.key}#${column}`] = {
          boundingBox: newBoundingBox,
          page: page,
          type: 'ADDED'
        }
      }
    }

    const options = {
      actionType: 'MERGE',
      idRow: firstRow.key,
      originRow: updatedLineBox
    }

    deleteRows(rowsSelectedTemp, tempCharges, tempLines, options)
  }

  function handleDelete(rowsSelected, unselectedRows) {
    let rowsSelectedTemp = {}

    if (typeof rowsSelected == 'boolean') {
      if (!rowsSelected) {
        return
      }
      const unselectedKeys = Object.keys(unselectedRows ?? {})
      rowsSelectedTemp = tab === 1 ? charges.additionalCharges : lines.lineItems
      const rowsUpdated = Object.values(rowsSelectedTemp).filter(
        x => !unselectedKeys.includes(x.key)
      )
      // eslint-disable-next-line
      rowsSelectedTemp = rowsUpdated.reduce((acc, curr) => ((acc[curr.key] = curr), acc), {})
    } else {
      rowsSelectedTemp = rowsSelected
    }

    deleteRows(rowsSelectedTemp, charges, lines)
  }

  function handleOnCompleteAccountSegments(data) {
    if (!selectedLine || selectedLine.length === 0 || !data) {
      return
    }

    setLines(old => ({
      ...old,
      lineItemsAccountSegments: {
        ...old.lineItemsAccountSegments,
        [selectedLine[0]]: {
          id: selectedLine[0],
          accountSegments: data
        }
      }
    }))

    removeWarningLineItem(ColumnsOrderLineItems.ACCOUNTING.type, selectedLine[0])
  }

  const accountSegmentsRow =
    selectedLine.length > 0 && lines.lineItemsAccountSegments
      ? lines.lineItemsAccountSegments[selectedLine[0]]
      : null

  const lineSelected = lines.lineItems.find(x => x.key === selectedLine[0])

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    setOpen(false)
  }

  const handleOnChangeAppListModal = () => {
    const summaryFields = getValues('summaryFields')

    const newAppId = summaryFields.find(
      summaryField => summaryField.type === HeaderFieldsSide.APP_ID.type
    )

    if (!newAppId) {
      return
    }

    setSimpleModal(old => ({ ...old, isOpenAppListModal: false, isOpenAppUpdateModal: true }))
    setIsLoadingAppUpdate(true)

    moveInvoice.mutate(
      {
        invoiceId,
        newAppId: newAppId.value
      },
      {
        onError: error => {
          setCreateError({
            isOpen: true,
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            color: 'danger'
          })
          setIsLoadingAppUpdate(false)
          setSimpleModal(old => ({ ...old, isOpenAppUpdateModal: false }))
        },
        onSuccess: async () => {
          try {
            let invoice
            do {
              invoice = await fetchInvoiceById({ invoiceId })
              await wait(3 * 1500)
            } while (invoice.status === 'movingInvoiceApp' || invoice.status === 'processing')
            queryClient.resetQueries(['ocrInvoice', invoiceId], {
              exact: true
            })
            queryClient.invalidateQueries(['invoices', invoiceId])
            if (invoice.status !== 'invoiceException') {
              history.push(`/admin/invoiceAI/${invoiceId}/details`)
              return
            }
            setIsLoadingAppUpdate(false)
            setSimpleModal(old => ({ ...old, isOpenAppUpdateModal: false }))
          } catch (error) {
            history.push(`/admin/invoiceAI/${invoiceId}/details`)
          }
        }
      }
    )
  }

  const handleOnCloseAppListWarningModal = onlyClose => {
    if (onlyClose) {
      setSimpleModal(old => ({ ...old, isOpenAppListModal: false }))
      return
    }

    const summaryFields = getValues('summaryFields')

    const index = summaryFields.findIndex(
      summaryField => summaryField.type === HeaderFieldsSide.APP_ID.type
    )

    if (index < 0) {
      setSimpleModal(old => ({ ...old, isOpenAppListModal: false }))
      return
    }

    setValueForm(`summaryFields.${index}.value`, appId, {
      shouldValidate: true,
      shouldDirty: true
    })
    setSimpleModal(old => ({ ...old, isOpenAppListModal: false }))
  }

  function removeAccounting(invoicesId) {
    if (invoicesId.length === 0) {
      return
    }

    setLines(old => {
      const accounting = Object.values(old.lineItemsAccountSegments).filter(
        x => !invoicesId.includes(x.id)
      )

      const updatedAccounting = accounting.reduce((acc, curr) => {
        acc[curr.id] = curr
        return acc
      }, {})

      return {
        ...old,
        lineItemsAccountSegments: updatedAccounting
      }
    })

    const rowsDeleteError = invoicesId.map(x => ({
      columnId: ColumnsOrderLineItems.ACCOUNTING.type,
      lineItemKey: x
    }))

    removeErrorAndWarningLineItem(rowsDeleteError)
  }

  const handleOnCloseAppListModal = () => {
    queryClient.resetQueries(['ocrInvoice', invoiceId], {
      exact: true
    })
    queryClient.invalidateQueries(['invoices', invoiceId])
    history.push(`/admin/invoiceAI/${invoiceId}/details`)
  }

  function updateLineItem(data, lineId) {
    const lineItemsData = Object.values(data ?? {})

    const rowsToRemoveErrors = []
    const copyLineItems = [...lines.lineItems]
    const lineIndex = copyLineItems.findIndex(x => x.key === lineId)

    if (lineItemsData.length > 0 && lineIndex >= 0) {
      // eslint-disable-next-line
      for (const newFieldData of lineItemsData) {
        // Update Line
        const lineUpdated = { ...copyLineItems[lineIndex] }
        lineUpdated[newFieldData.field] = newFieldData.value

        if (newFieldData.value) {
          rowsToRemoveErrors.push({
            columnId: newFieldData.field,
            lineItemKey: lineId
          })
        }

        copyLineItems[lineIndex] = lineUpdated
      }
    }

    setLines(old => ({
      ...old,
      lineItems: copyLineItems
    }))

    removeErrorAndWarningLineItem(rowsToRemoveErrors)
  }

  return (
    <Grid container direction="column">
      <Grid container direction="row" className={classesContainer.root}>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <Button
            style={{ maxWidth: '36px', minWidth: '36px' }}
            classes={{ startIcon: classesButtons.startICon }}
            className={classes.btnColorPrimary}
            variant="outlined"
            color="primary"
            disabled={!Boolean(invoicesExceptions?.previous)}
            onClick={() => handleOnChangeInvoice(invoicesExceptions.previous)}
            startIcon={<ArrowBackIosOutlinedIcon style={{ fontSize: 20 }} />}
          />
        </Grid>

        <Grid item xs={10} sm={10} md={10} lg={10}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button
              className={classes.btnColorPrimary}
              onClick={() => onSave()}
              disabled={isLoadingUpdate || isLocked}
              color="primary"
              variant="outlined"
              style={{ marginRight: '5px' }}
            >
              {isLoadingUpdate ? 'Saving...' : 'Save'}
            </Button>
            <Button
              disabled={isLoadingReset || isLocked}
              onClick={() => setSimpleModal(old => ({ ...old, isOpenResetModal: true }))}
              color="primary"
              type="button"
              variant="outlined"
              className={classes.btnColorPrimary}
              style={{ marginRight: '5px' }}
            >
              {isLoadingReset ? 'Resetting data...' : 'Reset'}
            </Button>
            <Button
              onClick={() => setSimpleModal(old => ({ ...old, isOpenRejectModal: true }))}
              color="primary"
              type="button"
              disabled={updateStatusInvoice.isLoading || isLocked}
              variant="outlined"
              className={classes.btnColorPrimary}
              style={{ marginRight: '5px' }}
            >
              {updateStatusInvoice.isLoading ? 'Rejecting...' : 'Reject'}
            </Button>
            <Button
              onClick={() => setSimpleModal(old => ({ ...old, isOpenIgnoreModal: true }))}
              color="primary"
              type="button"
              disabled={isLocked}
              /*disabled={ignoringInvoice.isLoading}*/
              variant="outlined"
              className={classes.btnColorPrimary}
              style={{ marginRight: '5px' }}
            >
              {'Ignore'}
            </Button>
            <form onSubmit={onSubmit}>
              <ButtonGroup color="primary" ref={anchorRef} variant="contained" disabled={isLocked}>
                <Button
                  disabled={validationInvoice.isLoading}
                  type="submit"
                  className={classes.btnBgColorPrimary}
                  variant="contained"
                  onClick={() => setSelectedIndex(0)}
                >
                  {validationInvoice.isLoading ? 'Submitting...' : 'Submit'}
                </Button>
                <Button
                  color="primary"
                  size="small"
                  disabled={validationInvoice.isLoading}
                  className={classes.btnBgColorPrimary}
                  onClick={() => handleToggle()}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                          <MenuItem>
                            <Button
                              size="small"
                              disableRipple
                              disabled={validationInvoice.isLoading}
                              type="submit"
                              className={classes.noHover}
                              onClick={() => setSelectedIndex(1)}
                            >
                              {validationInvoice.isLoading ? 'Submitting...' : 'Submit/Next'}
                            </Button>
                          </MenuItem>
                          <MenuItem>
                            <Button
                              size="small"
                              disableRipple
                              disabled={validationInvoice.isLoading}
                              type="submit"
                              className={classes.noHover}
                              onClick={() => setSelectedIndex(2)}
                            >
                              {validationInvoice.isLoading ? 'Submitting...' : 'Submit/Exit'}
                            </Button>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </form>
          </div>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              style={{ maxWidth: '36px', minWidth: '36px' }}
              classes={{ startIcon: classesButtons.startICon }}
              className={classes.btnColorPrimary}
              color="primary"
              variant="outlined"
              disabled={!Boolean(invoicesExceptions?.next)}
              onClick={() => handleOnChangeInvoice(invoicesExceptions.next)}
              startIcon={<ArrowForwardIosOutlinedIcon style={{ fontSize: 20 }} />}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: '10px' }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <AppBar position={'static'} color="default">
            <>
              <div className={classesBadges.root}>
                {isCustomerMappingsEnabled ? (
                  <IconButton
                    onClick={() => updateInvoiceOcrDataCustomRules()}
                    size="small"
                    disabled={applyCustomerMappings.isLoading}
                  >
                    <Badge classes={{ badge: classesBadges.badge }}>
                      <TrackChangesIcon fontSize="small" style={{ color: '#081c3e' }} />
                    </Badge>
                  </IconButton>
                ) : null}

                <IconButton
                  onClick={() =>
                    setShowModal({
                      isOpen: true,
                      info: validationInfo.errorInfo,
                      type: 'error'
                    })
                  }
                  size="small"
                  disabled={validationCount?.errorCount <= 0}
                >
                  <Badge
                    badgeContent={validationCount?.errorCount}
                    style={{ color: 'red' }}
                    classes={{
                      badge: classesBadges.errorBadge
                    }}
                  >
                    <ErrorIcon
                      fontSize="small"
                      className={
                        validationCount?.errorCount > 0
                          ? classes.primaryColor
                          : classes.disabledColor
                      }
                    />
                  </Badge>
                </IconButton>

                <IconButton
                  onClick={() =>
                    setShowModal({
                      isOpen: true,
                      info: validationInfo.warningInfo,
                      type: 'warning'
                    })
                  }
                  size="small"
                  disabled={validationCount?.warningCount <= 0}
                >
                  <Badge
                    badgeContent={validationCount?.warningCount}
                    style={{ color: '#f3bc07' }}
                    classes={{
                      badge: classesBadges.warningBadge
                    }}
                  >
                    <WarningIcon
                      fontSize="small"
                      className={
                        validationCount?.warningCount > 0
                          ? classes.primaryColor
                          : classes.disabledColor
                      }
                    />
                  </Badge>
                </IconButton>

                <IconButton
                  onClick={() => setSimpleModal(old => ({ ...old, isOpenCommentsModal: true }))}
                  size="small"
                >
                  <Badge
                    badgeContent={commentsInformation.data?.length}
                    classes={{ badge: classesBadges.badge }}
                  >
                    <SpeakerNotesIcon fontSize="small" style={{ color: '#081c3e' }} />
                  </Badge>
                </IconButton>
              </div>
            </>
            <Tabs
              value={tab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Header Fields" {...a11yProps(0)} />
              <Tab label="Tax & Other Charges" {...a11yProps(1)} />
              <Tab label="Line item fields" {...a11yProps(2)} />
              <Tab label="Unrecognized Fields" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={tab}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={tab} index={0} dir={theme.direction}>
              <Grid container justifyContent="center" style={{ width: '100%' }} spacing={2}>
                {getTextOcr.isLoading ||
                applyPOMappingsOcr.isLoading ||
                applyCustomerMappings.isLoading ? (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '1300',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#f8f8f8ad'
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </div>
                ) : null}
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                  <h3 style={{ color: '#081c3e' }}>Required Fields</h3>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {leftRightFields()}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1} dir={theme.direction}>
              <Grid container justifyContent="center" spacing={2} style={{ paddingTop: '15px' }}>
                <Grid item xs={7} sm={7} md={7} lg={7}>
                  <Tooltip title={'Previous'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      disabled={
                        customPaginationAdditional.skip === 0 ||
                        (!invoiceVersion || invoiceVersion === 1)
                      }
                      onClick={updateSkipAdditional(-dataPerPage)}
                    >
                      <NavigateBeforeOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Next'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      onClick={updateSkipAdditional(dataPerPage)}
                      disabled={
                        customPaginationAdditional.currentPage ===
                          customPaginationAdditional.numberOfPages ||
                        (!invoiceVersion || invoiceVersion === 1)
                      }
                    >
                      <NavigateNextOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Add Row'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      onClick={onAddNewRowAdditional}
                      disabled={!invoiceVersion || invoiceVersion === 1}
                    >
                      <AddIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Delete Rows'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      onClick={handleDeleteBatchLineItems}
                    >
                      <DeleteForeverIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Combine Rows'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      onClick={handleMergeRows}
                    >
                      <MergeTypeIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      onClick={() => updateInvoice()}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className={classes.btnColorPrimary}
                      disabled={!invoiceVersion || invoiceVersion === 1}
                    >
                      Update Totals
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ReactDataGrid
                    onReady={setGridAdditionalRef}
                    onEditComplete={onEditComplete}
                    editable={true}
                    showColumnMenuTool={false}
                    renderRowContextMenu={renderRowContextMenu}
                    cellSelection={cellSelected}
                    onCellSelectionChange={setCellSelected}
                    pagination
                    loading={getTextOcr.isLoading}
                    sortable={false}
                    cellStyle={{}}
                    enableKeyboardNavigation={false}
                    defaultLimit={dataPerPage}
                    idProperty="key"
                    activeIndexThrottle={300}
                    scrollProps={scrollProps}
                    skip={customPaginationAdditional.skip}
                    onSkipChange={onChangeSkip}
                    renderPaginationToolbar={() => null}
                    emptyText="There are no taxes and other charges to show"
                    style={gridChargesStyle}
                    columns={columnsAdditionalData.columns}
                    dataSource={charges.additionalCharges}
                    onCellClick={onCellClick}
                    selected={selectedLines.selected}
                    unselected={selectedLines.unselected}
                    onSelectionChange={onSelectionChange}
                    checkboxColumn={true}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={2} dir={theme.direction}>
              <Grid container justifyContent="center" spacing={2} style={{ paddingTop: '15px' }}>
                {applyCustomerMappings.isLoading || applyPOMappingsOcr.isLoading ? (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '1300',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#f8f8f8ad'
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </div>
                ) : null}
                <Grid item xs={7} sm={7} md={7} lg={7}>
                  <Tooltip title={'Previous'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      disabled={customPagination.skip === 0}
                      onClick={updateSkip(-dataPerPage)}
                    >
                      <NavigateBeforeOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Next'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      onClick={updateSkip(dataPerPage)}
                      disabled={customPagination.currentPage === customPagination.numberOfPages}
                    >
                      <NavigateNextOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Add Row'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      onClick={onAddNewRow}
                    >
                      <AddIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Delete Rows'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      onClick={handleDeleteBatchLineItems}
                    >
                      <DeleteForeverIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Combine Rows'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      onClick={handleMergeRows}
                    >
                      <MergeTypeIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Update Lines with Po Information'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      onClick={() => setPoModal(old => ({ ...old, update: true }))}
                    >
                      <DoubleArrowIcon width="25px" height="25px" />
                    </Fab>
                  </Tooltip>
                  {accountSegmentsEnabled ? (
                    <Tooltip title={'Delete Accounting'}>
                      <Fab
                        size="small"
                        style={{
                          margin: '0 5px 0 5px',
                          backgroundColor: '#081c3e'
                        }}
                        onClick={() => setSimpleModal(old => ({ ...old, deleteAccounting: true }))}
                      >
                        <DeleteSweepOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                      </Fab>
                    </Tooltip>
                  ) : null}
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      onClick={() => updateInvoice()}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className={classes.btnColorPrimary}
                    >
                      Update Totals
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ReactDataGrid
                    idProperty="key"
                    selected={selectedLines.selected}
                    unselected={selectedLines.unselected}
                    checkboxColumn={true}
                    onSelectionChange={onSelectionChange}
                    onReady={setGridRef}
                    columns={columnsData.columns}
                    dataSource={lines.lineItems}
                    style={gridStyle}
                    showColumnMenuTool={false}
                    editable={true}
                    onEditComplete={onEditComplete}
                    renderRowContextMenu={renderRowContextMenu}
                    loading={getTextOcr.isLoading || applyPoLineMappingsOcr.isLoading}
                    sortable={false}
                    pagination
                    cellStyle={{}}
                    emptyText="There are no line items to show"
                    cellSelection={cellSelected}
                    onCellSelectionChange={setCellSelected}
                    enableKeyboardNavigation={false}
                    onCellClick={onCellClick}
                    activeIndexThrottle={300}
                    scrollProps={scrollProps}
                    checkboxOnlyRowSelect={true}
                    defaultLimit={dataPerPage}
                    skip={customPagination.skip}
                    onSkipChange={onChangeSkip}
                    renderPaginationToolbar={() => null}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={3} dir={theme.direction}>
              <div className={classes.otherFiledsContainer}>
                {applyCustomerMappings.isLoading ? (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '1300',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#f8f8f8ad'
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </div>
                ) : null}
                <div className={classes.notRecognizedLabel}>
                  <h3 style={{ color: '#081c3e' }}>Unrecognized Header Fields</h3>
                  <h5
                    style={{
                      color: '#666666',
                      fontSize: '12px',
                      textAlign: 'left'
                    }}
                  >
                    The following fields were identified on the Invoice but have no known mapping.
                    If you would like to map one of these fields to a system field, select the
                    system field from the drop down. If you would like to keep this mapping for the
                    future, select "Use these mappings for future invoices". <br />
                    Please note that some unrecognized fields may not be present for all languages.
                  </h5>
                  <FormControlLabel
                    style={{ margin: '1rem 0' }}
                    control={
                      <Switch
                        color="primary"
                        onChange={e => setMappings(e.target.checked)}
                        checked={mappings}
                      />
                    }
                    label="Use these mappings for future invoices"
                  />
                </div>
                <div className={classes.otherFiledsSection}>{notRecognizedFieldsJsx}</div>
              </div>
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
      <Snackbar
        place="bl"
        color={createError.color ? createError.color : 'danger'}
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '', color: 'danger' })}
        close
      />
      <Snackbar
        place="bl"
        color="success"
        icon={AddAlert}
        message={submitSuccess.message}
        open={submitSuccess.isOpen}
        closeNotification={() => setSubmitSuccess({ isOpen: false, message: '' })}
        close
      />
      <InfoModal
        showModal={submitModal.isOpen}
        onSubmit={onSubmitModal}
        onClose={() => setSubmitModal({ isOpen: false, data: null })}
        warningMessage={submitModal.messageBody}
        title="Invoice Submission"
        textSuccessBtn={'Submit'}
        successBtn={submitModal.successBtn}
      />

      <InfoModal
        showModal={simpleModal.isOpenAppListModal}
        onClose={onlyClose => handleOnCloseAppListWarningModal(onlyClose)}
        onSubmit={handleOnChangeAppListModal}
        warningMessage={
          <p>
            Invoice application change has been detected, this action will save the current changes
            and load the rules and data associated to the selected application.
          </p>
        }
        title="Invoice"
        textSuccessBtn={'Agree'}
      />

      <InfoModal
        isLoading={isLoadingAppUpdate}
        showModal={simpleModal.isOpenAppUpdateModal}
        onClose={handleOnCloseAppListModal}
        warningMessage={
          <p>
            This could take some time to complete. If you'd like you can close this dialog box and
            continue working
          </p>
        }
        title="We're currently changing your invoice System"
        successBtn={false}
        fullWidthBtn={true}
        variantBtn={'contained'}
        isRelishPrimaryColor={true}
      />

      <InfoModal
        showModal={simpleModal.isOpenSaveModal}
        onSubmit={onConfirmSaveModal}
        onClose={() => history.push(`/admin/invoiceAI/${invoiceId}/details`)}
        warningMessage={
          <p>
            Invoice Country change has been detected, this action will add or remove information
            from the invoice.
          </p>
        }
        title="Invoice"
        textSuccessBtn={'Agree'}
      />

      {accountSegmentsEnabled ? (
        <ModalAccountType
          isOpen={simpleModal.isOpenAccountTypeModal}
          onClose={() => setSimpleModal(old => ({ ...old, isOpenAccountTypeModal: false }))}
          defaultDataSource={accountSegmentsRow?.accountSegments ?? []}
          onComplete={handleOnCompleteAccountSegments}
          isAccountValidationEnabled={isAccountValidationEnabled}
          appId={appId}
        />
      ) : null}

      <ModalComments
        showModal={simpleModal.isOpenCommentsModal}
        onClose={() => setSimpleModal(old => ({ ...old, isOpenCommentsModal: false }))}
        commentsInformation={commentsInformation}
        appId={appId}
        invoiceId={invoiceId}
      />
      <ResetModal
        showModal={simpleModal.isOpenResetModal}
        onClose={() => setSimpleModal(old => ({ ...old, isOpenResetModal: false }))}
        onSubmit={onResetModal}
      />
      <CombineModal
        showModal={simpleModal.isOpenCombineModal}
        onClose={() => setSimpleModal(old => ({ ...old, isOpenCombineModal: false }))}
        onSubmit={onSuccessMergeRows}
        selectedRows={selectedLines}
      />
      <UpdateStatusDialog
        open={simpleModal.isOpenRejectModal}
        onClose={() => setSimpleModal(old => ({ ...old, isOpenRejectModal: false }))}
        changeInvoiceStatus={changeInvoiceStatus}
        status={'reject'}
        label={'Reject'}
      />
      <DeleteConfirmationModal
        showModal={deleteModal.isOpen}
        title={'Are you sure you want to delete the rows selected?'}
        onSuccess={() => handleDelete(selectedLines.selected, selectedLines.unselected)}
        onClose={onClose}
      />
      {accountSegmentsEnabled ? (
        <DeleteConfirmationModal
          showModal={simpleModal.deleteAccounting}
          title={'Are you sure you want to delete the accounting information?'}
          onSuccess={() => removeAccounting(lines.lineItems.map(x => x.key))}
          onClose={() => setSimpleModal(old => ({ ...old, deleteAccounting: false }))}
        />
      ) : null}

      <ValidationsModal
        showModal={showModal.isOpen}
        type={showModal.type}
        modalInfo={showModal.info ?? []}
        onClose={() =>
          setShowModal({
            isOpen: false
          })
        }
      />
      <IgnoreModal
        showModal={simpleModal.isOpenIgnoreModal}
        onClose={() => setSimpleModal(old => ({ ...old, isOpenIgnoreModal: false }))}
        onSubmit={onIgnore}
        documentId={invoiceId}
      />
      <PoModal
        showModal={poModal.update}
        onClose={() => setPoModal(old => ({ ...old, update: false }))}
        onSubmit={applyPoMappings}
        control={control}
        accountSegmentsEnabled={accountSegmentsEnabled}
        isHeaderSection={tab === 0}
        requiredFields={requiredFields}
        appId={appId}
      />
      <PoModalDetails
        showModal={poModal.details}
        onClose={() => setPoModal(old => ({ ...old, details: false }))}
        control={control}
        appConfig={appConfig}
        appId={appId}
      />
      <AdditionalDetailsExceptions
        isOpen={simpleModal.isOpenAddressModal}
        onClose={() => setSimpleModal(old => ({ ...old, isOpenAddressModal: false }))}
        control={control}
        onEditComplete={onEditComplete}
        validationErrorsState={validationErrorsState}
        removeWarningLineItem={removeWarningLineItem}
        originalErrors={validationErrors}
        lineItemSelected={lineSelected}
        requiredFields={requiredFields}
        process={invoiceData.process}
        appId={appId}
      />
    </Grid>
  )
})
