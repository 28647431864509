import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'
import AddAlert from '@material-ui/icons/AddAlert'
import Snackbar from 'components/Snackbar/Snackbar'
import { useLocalStorageState } from 'hooks/useLocalStorage'
import { useGetTenantConfig } from 'hooks/useTenantConfig'
import { useGetUserFutureFlags, usePartialUpdateUser } from 'hooks/useUser'
import React, { useEffect, useState } from 'react'

const ColorSwitch = withStyles({
  switchBase: {
    color: '#fafafa',
    '&$checked': {
      color: '#f15f22'
    },
    '&$checked + $track': {
      backgroundColor: '#f15f22'
    }
  },
  checked: {},
  track: {}
})(Switch)

export default function SwitchNewUI() {
  const [userInfo] = useLocalStorageState('userInfo', {})
  const { data: configTenant } = useGetTenantConfig()
  const userData = useGetUserFutureFlags({ userId: userInfo?.sub })
  const [createError, setCreateError] = useState({ message: '', isOpen: false })
  const [newUI, setNewUI] = useState(false)
  const updateUser = usePartialUpdateUser(userInfo?.sub)

  const isNewUiActivated = userData.data?.featureFlags?.newInvoiceAI?.status ?? false

  useEffect(() => {
    if (isNewUiActivated) {
      setNewUI(isNewUiActivated)
    }
  }, [isNewUiActivated])

  const handleChange = event => {
    setNewUI(event.target.checked)
    updateUser.mutate(
      {
        featureFlags: {
          newInvoiceAI: {
            status: event.target.checked
          }
        }
      },
      {
        onError: () =>
          setCreateError({
            message: 'Unable to save user configuration',
            isOpen: true
          }),
        onSuccess: (_response, body) => {
          const { featureFlags } = body
          if (window.top && featureFlags.newInvoiceAI.status) {
            const origin = window.top.location.origin
            window.top.location.href = `${origin}/platform/invoiceAI/list`
            localStorage.setItem('origin', 'Old_webApp')
          }
        }
      }
    )
  }

  if (
    configTenant?.featureFlags?.newInvoiceAI?.showAlways ||
    !configTenant?.featureFlags?.newInvoiceAI?.status ||
    window.self !== window.top
  ) {
    return null
  }

  return (
    <>
      <FormControlLabel
        control={<ColorSwitch checked={newUI} onChange={handleChange} />}
        label="New Invoice AI"
      />
      <Snackbar
        place="bl"
        color={'danger'}
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '' })}
        close
      />
    </>
  )
}
