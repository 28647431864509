import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import moment from 'moment'
import Datetime from 'react-datetime'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'
import InputLabel from '@material-ui/core/InputLabel'
import { CircularProgress } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import GetAppIcon from '@material-ui/icons/GetApp'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import SyncLoader from 'components/SyncLoader/SyncLoader'

// Constants
import { invoiceStatus, downloadTypes, fileExtension } from 'utils/Constants'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceStyle.js'

import { downloadByEntityType } from 'services/apiInvoices'
import { useGetDownloads } from 'hooks/useInvoices'
import { useGetTenantConfig } from 'hooks/useTenantConfig.js'
import { useGetAppList } from 'hooks/useApp.js'
const useStyles = makeStyles(styles)

export default function MasterDataDownload() {
  const classes = useStyles()
  //const [createError, setCreateError] = useState(false)
  const [createError, setCreateError] = useState({ isOpen: false, message: '' })
  const [filters, setFilters] = useState({
    type: '',
    startDate: moment(new Date()).format('MM/DD/YYYY'),
    endDate: moment(new Date()).format('MM/DD/YYYY'),
    extension: '.csv',
    appId: ''
  })

  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const [isDownloading, setIsDownloading] = useState(false)

  const { data: configTenant, isLoading: isLoadingTenantConfig } = useGetTenantConfig()

  const { data: downloads, isLoading, error, isError, refetch, isFetching } = useGetDownloads(
    configTenant
  )
  const appList = useGetAppList('invoiceAI')
  let appConfigurations = []
  if (appList.data) {
    appConfigurations = [...appList.data]
    appConfigurations.push({
      appName: 'All',
      env: 'all'
    })
  }

  useEffect(() => {
    if (isError) {
      setCreateError({
        isOpen: true,
        message: error.response?.data?.message || 'Something went wrong, try again later'
      })
    }
    if (appList.isError) {
      setCreateError({
        isOpen: true,
        message: 'Something went wrong, try again later'
      })
    }
  }, [isError, error, appList.isError])

  async function downloadEntityType() {
    setIsDownloading(true)
    const data = { ...filters }
    const startDate = new Date(new Date(data.startDate).setHours(0, 0, 0, 0)).toISOString()
    data.startDate = startDate
    const endDate = new Date(new Date(data.endDate).setHours(23, 59, 59, 999)).toISOString()

    data.endDate = endDate
    const response = await downloadByEntityType(data, configTenant.defaultApp)
    if (response && response.success) {
      refetch({ throwOnError: true })
      setSubmitSuccess(true)
      setValidationMessage('Download started.')
    } else {
      if (response) {
        let message = response.message
        if (!message) {
          message = 'Something went wrong, please try again later.'
        }
        setValidationMessage(message)
        setCreateError({
          isOpen: true,
          message: message || 'Something went wrong, please try again later'
        })
      }
    }
    setIsDownloading(false)
  }

  const columns = [
    {
      field: 'types',
      title: 'File Type',
      render: rowData => {
        let types = []
        // eslint-disable-next-line
        for (const i of rowData.types) {
          types.push(downloadTypes[i])
        }
        return types.join(', ')
      }
    },
    {
      field: 'filters',
      title: 'App Name',
      render: rowData => {
        const appId = rowData.filters?.appId || rowData.appId
        const appName = appConfigurations.find(element => element.env === appId)
        return appName?.appName ?? ''
      }
    },
    {
      field: 'extension',
      title: 'File Extension',
      render: rowData => fileExtension[rowData.extension]
    },
    {
      field: 'uniqueName',
      title: 'Created By'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => invoiceStatus[rowData.status]
    },
    {
      field: 'date',
      title: 'Creation Date',
      render: rowData => moment(rowData.date).format('MM/DD/YYYY HH:mm'),
      customSort: (a, b) => {
        return moment(moment(b.date)).isBefore(moment(a.date)) ? 1 : -1
      },
      defaultSort: 'desc'
    }
    // {
    //   field: 'details',
    //   title: 'Details'
    // }
  ]

  function onFieldChange(field, value) {
    const newFilters = { ...filters }
    if (field === 'startDate' || field === 'endDate') {
      if (typeof value === 'object' && value !== null) {
        value = moment(value._d).format('MM/DD/YYYY')
      }
    }
    newFilters[field] = value
    setFilters(newFilters)
  }

  function onSelectChange(event) {
    setFilters(prevState => {
      return { ...prevState, appId: event.target.value }
    })
  }

  let breadcrumbViews = [
    {
      name: 'Data Export',
      url: `/admin/invoiceAI/dataexport`,
      icon: GetAppIcon
    }
  ]

  function returnMasterData() {
    const types = [
      //{ key: 'organization', label: 'Organizations' },
      { key: 'supplier', label: 'Suppliers' },
      { key: 'supplierAddress', label: 'Supplier Address' },
      // { key: 'remittanceLocation', label: 'Remittance Locations' },
      // { key: 'address', label: 'Addresses' },
      { key: 'purchaseOrderHeader', label: 'Purchase Orders Header' },
      { key: 'purchaseOrderLine', label: 'Purchase Orders Lines' },
      { key: 'invoice', label: 'Invoices' },
      { key: 'payment', label: 'Payments' },
      { key: 'companyProfile', label: 'Company Profiles' },
      { key: 'companyAddress', label: 'Company Addresses' }
    ]

    const extensions = [{ key: '.json', label: 'JSON' }, { key: '.csv', label: 'CSV' }]
    return (
      <GridContainer justify="left" style={{ marginTop: '20px' }}>
        <GridItem xs={12} sm={12}>
          <GridContainer justifyContent="flex-start">
            <GridItem xs={12} sm={3}>
              <InputLabel className={classes.label}>App Name</InputLabel>
              <FormControl fullWidth className={classes.selectFormControl}>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                    style: { zIndex: 5555 }
                  }}
                  classes={{
                    select: classes.select,
                    disabled: classes.disabled
                  }}
                  value={filters.appId}
                  onChange={onSelectChange}
                  inputProps={{
                    name: 'appName',
                    id: 'appName'
                  }}
                >
                  {appConfigurations.map(app => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                      value={app.env}
                      key={app.env}
                    >
                      {app.appName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={3}>
          <InputLabel className={classes.label}>File Type</InputLabel>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              MenuProps={{
                className: classes.selectMenu,
                style: { zIndex: 5555 }
              }}
              classes={{
                select: classes.select,
                disabled: classes.disabled
              }}
              value={filters.type}
              onChange={e => onFieldChange('type', e.target.value)}
              inputProps={{
                name: 'type',
                id: 'type'
              }}
            >
              {types.map(type => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                  value={type.key}
                  key={type.key}
                >
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={3}>
          <InputLabel className={classes.label}>File Extension</InputLabel>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              MenuProps={{
                className: classes.selectMenu,
                style: { zIndex: 5555 }
              }}
              classes={{
                select: classes.select,
                disabled: classes.disabled
              }}
              value={filters.extension}
              onChange={e => onFieldChange('extension', e.target.value)}
              inputProps={{
                name: 'extension',
                id: 'extension'
              }}
            >
              {extensions.map(extension => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                  value={extension.key}
                  key={extension.key}
                >
                  {extension.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        {filters.type === 'payment' || filters.type === 'invoice' ? (
          <>
            <GridItem xs={12} sm={3}>
              <InputLabel className={classes.label}>From</InputLabel>
              <FormControl fullWidth>
                <Datetime
                  closeOnSelect={true}
                  dateFormat="MM/DD/YYYY"
                  value={filters.startDate}
                  timeFormat={false}
                  onChange={event => onFieldChange('startDate', event)}
                  inputProps={{
                    placeholder: 'From',
                    value: filters.startDate
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <InputLabel className={classes.label}>To</InputLabel>
              <FormControl fullWidth>
                <Datetime
                  closeOnSelect={true}
                  dateFormat="MM/DD/YYYY"
                  value={filters.endDate}
                  timeFormat={false}
                  onChange={event => onFieldChange('endDate', event)}
                  inputProps={{
                    placeholder: 'To',
                    value: filters.endDate
                  }}
                />
              </FormControl>
            </GridItem>
          </>
        ) : null}
        <GridItem xs={12} sm={12}>
          <Button
            className={classes.floatRight + ' ' + classes.bgColorPrimary}
            color="primary"
            variant="contained"
            disabled={
              !filters.appId ||
              !filters.type ||
              isDownloading ||
              isLoadingTenantConfig ||
              appList.isLoading
            }
            onClick={() => downloadEntityType()}
          >
            {isDownloading ? 'Exporting...' : 'Export'}
          </Button>
        </GridItem>
        <GridItem md={12}>
          <h4>History</h4>
          <GridContainer justify="center" style={{ marginTop: '5px' }}>
            <GridItem md={12}>
              <Button
                size="small"
                style={{ color: '#081c3e', marginBottom: '10px' }}
                className={classes.floatRight}
                onClick={() => refetch({ throwOnError: true })}
              >
                <RefreshRoundedIcon />
              </Button>
            </GridItem>
            <SyncLoader showLoader={isFetching && !isLoading} />
            {isLoading || isLoadingTenantConfig || appList.isLoading ? (
              <div className={classes.reloadSize}>
                <CircularProgress />
              </div>
            ) : (
              <GridItem xs={12} sm={12} md={12}>
                <MaterialTable
                  columns={columns}
                  data={downloads || []}
                  actions={[
                    rowData => ({
                      disabled: rowData.presignedUrl ? false : true,
                      icon: () => <GetAppIcon />,
                      tooltip: 'Download',
                      onClick: (event, rowData) => {
                        window.location.assign(rowData.presignedUrl)
                      }
                    })
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    sorting: true,
                    showTitle: false,
                    emptyRowsWhenPaging: false
                  }}
                />
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
      </GridContainer>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          <Card>
            <CardHeader>
              <h3 className={classes.formHeader}>Data Export</h3>
            </CardHeader>
            <CardBody style={{ padding: '30px' }}>{returnMasterData()}</CardBody>
          </Card>
        </GridItem>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={createError.message}
          open={createError.isOpen}
          closeNotification={() => setCreateError({ isOpen: false, message: '' })}
          close
        />
        <Snackbar
          place="bl"
          color="success"
          icon={AddAlert}
          message={validationMessage}
          open={submitSuccess}
          closeNotification={() => setSubmitSuccess(false)}
          close
        />
      </GridContainer>
    </Fade>
  )
}
