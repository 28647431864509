import { ApiAdminPricebook } from 'utils/Constants'
import { createHeaders, getUserTenant } from 'utils/functions'
import axios from 'axios'

export async function getPriceBookConfigurationList() {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminPricebook.EndPoint}tenants/${tenant}/apps`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    })

    if (response) {
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

export async function getApp(appId) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminPricebook.EndPoint}tenants/${tenant}/apps/${appId}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    })

    if (response) {
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/*
    Create the app
*/
export async function createApp(body) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminPricebook.EndPoint}tenants/${tenant}/apps`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: body
    })

    if (response) {
      if (response.status === 201) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
      Edit the app
  */
export async function updateApp(appId, body) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminPricebook.EndPoint}tenants/${tenant}/apps/${appId}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: body
    })
    if (response) {
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}
