import React from 'react'
import MaterialTable from 'material-table'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { CardActions } from '@material-ui/core'
import Button from '@material-ui/core/Button'


// Assets
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import poweredByRelish from 'assets/img/POC/powered-by-relish.png'

// Components
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardText from 'components/Card/CardText'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { Box } from '@material-ui/core'

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: '#FFFFFF',
    marginTop: '0'
  },
  cardCategory: {
    color: '#999999',
    marginTop: '10px'
  },
  cardCategoryWhite: {
    margin: '0',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '.875rem'
  }
}

const useStyles = makeStyles(styles)

export default function SupplierInformationDetailApp(props) {
  const classes = useStyles()
  return (
    <>
      <GridContainer
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '30px',
          width: '100%',
          margin: '0'
        }}
      >
        <GridItem xs={12}></GridItem>

        <GridItem xs={12} sm={12} md={10} lg={8}>
            <Card>
              <CardHeader
                color='gray'
                text='PepsiCO'
              >
                <CardText
                  color='gray'
                >
                  <h4 className={classes.cardTitleWhite}>PepsiCO</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <p style={{ textAlign: 'left' }}>
                  <b>
                  Below is the enriched data for this supplier using DnB Data.
                  </b>
                  <a
                    href="https://relishiq.com/solutions/relish-data-assure/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#3c78c8' }}
                  >
                    {' Click to learn More'}
                  </a>
                  <br />
                  <h4 className={classes.cardTitleWhite}>Company Data</h4>
                </p>
                <MaterialTable
                    columns={[
                      {
                        cellStyle: { padding: '5px 16px' },
                        title: 'Field',
                        field: 'label'
                      },
                      {
                        cellStyle: { padding: '5px 16px' },
                        title: 'Value',
                        field: 'data'
                      }
                    ]}
                    data={[
                      {	
                        label: 'Company Name',
                        data: 'Pepsico, Inc.'
                      },
                      {
                        label: 'DUNS',
                        data: '001287762'
                      },
                      {
                        label: 'Address Line 1',
                        data: '700 Anderson Hill Rd'
                      },
                      {
                        label: 'Address Line 2',
                        data: ''
                      },
                      {
                        label: 'Postal Code',
                        data: '10577-1444'
                      },
                      {
                        label: 'Region',
                        data: 'New York'
                      },
                      {
                        label: 'Country',
                        data: 'United States'
                      },
                      {
                        label: 'Type of Entity',
                        data: 'Corporation'
                      },
                      {
                        label: 'Employee Count',
                        data: '291005'
                      },
                      {
                        label: 'Website',
                        data: 'www.pepsico.com'
                      }
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      sorting: true,
                      showTitle: false,
                      search: false,
                      paging: false,
                      toolbar: false
                    }}
                  />
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Box textAlign='center'>
                            <Button
                              color="inherit"
                              type="button"
                              disabled= {false}
                              variant="contained"
                              style={{ marginRight: '5px' }}
                              onClick={() => {
                                }}
                            >
                            {'Unmatch DUNS number'}
                            </Button>
                          </Box>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
              </CardBody>
              <CardActions>
                <GridContainer>
                  <GridItem xs={6} style={{ textAlign: 'left' }}>
                    <a
                      href="https://relishiq.com/solutions/relish-data-assure/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#3c78c8' }}
                    >
                      <img src={poweredByRelish} alt="Go to Relish" style={{ maxWidth: '200px' }} />
                    </a>
                  </GridItem>
                </GridContainer>
              </CardActions>
            </Card>
        </GridItem>
      </GridContainer>
    </>
  )
}
