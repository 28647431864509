import axios from 'axios'

import { ApiAdminInvoice } from 'utils/Constants'
import { createHeaders } from 'utils/functions'

/*
 Purchase Orders
 */

export async function getPurchaseOrders({ rowsPage, lastEvaluatedKey, appId }) {
  let url = `${ApiAdminInvoice.EndPoint}/master-data/appId/${appId}/purchase-orders`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: {
      limit: rowsPage,
      key: lastEvaluatedKey
    }
  })
}

/*
 Purchase Order
 */

export async function getPurchaseOrder({ poId, appId }) {
  let url = `${ApiAdminInvoice.EndPoint}/master-data/appId/${appId}/purchase-orders/api/${poId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
 Create Purchase Order
 */

export async function createPurchaseOrder({ poBody, appId }) {
  let url = `${ApiAdminInvoice.EndPoint}/master-data/appId/${appId}/purchase-orders`
  let requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: poBody
  })
}

/*
 Update Purchase Order
 */

export async function updatePurchaseOrder({ poId, poBody, appId }) {
  let url = `${ApiAdminInvoice.EndPoint}/master-data/appId/${appId}/purchase-orders/${poId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: poBody
  })
}

export async function getAccountTypesByTenant(params) {
  const url = `${ApiAdminInvoice.EndPoint}/master-data/internal/account-types`
  const requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params
  })
}

export async function getSegmentsByAccountTypes(accountType, params) {
  const url = `${ApiAdminInvoice.EndPoint}/master-data/internal/account-types/${accountType}/segments`
  const requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params
  })
}

export async function getAccountByIdAndCode({ accountType, code, params }) {
  const url = `${ApiAdminInvoice.EndPoint}/master-data/internal/accounts/account-types/${accountType}/code/${code}`
  const requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params
  }).then(response => response.data)
}

/* async function fakeGet() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([])
    }, 1500)
  })
} */

export async function getSupplierAddresses(supplierId, params) {
  const url = `${ApiAdminInvoice.EndPoint}/master-data/internal/supplier-addresses/suppliers/${supplierId}`
  const requestHeaders = createHeaders()
  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params
  })
}

export async function getCommodities(params) {
  const url = `${ApiAdminInvoice.EndPoint}/master-data/internal/commodities/tenant`
  const requestHeaders = createHeaders()
  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params
  })
}

export async function getCommodity(id, params) {
  const url = `${ApiAdminInvoice.EndPoint}/master-data/internal/commodities/${id}`
  const requestHeaders = createHeaders()
  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params
  })
}

export async function getBuyerAddresses({ companyCode, params }) {
  let url = `${ApiAdminInvoice.EndPoint}/master-data/internal/company-addresses/company-profiles/${companyCode}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params
  })
}

export async function getPurchaseOrderMatchesQuery(debouncedFilter, params) {
  let url = `${ApiAdminInvoice.EndPoint}/master-data/internal/purchase-orders/lookup/${debouncedFilter}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params
  })
}

export async function getPurchaseOrderDetailsQuery(poId, params) {
  let url = `${ApiAdminInvoice.EndPoint}/master-data/internal/purchase-orders/${poId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params
  })
}

export async function getSuppliersQuery(debouncedFilter, params) {
  let url = `${ApiAdminInvoice.EndPoint}/master-data/internal/suppliers/lookup/id/${debouncedFilter}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params
  })
}

export async function getSuppliersByNameQuery(debouncedFilter = '', params) {
  let url = `${
    ApiAdminInvoice.EndPoint
  }/master-data/internal/suppliers/lookup/name/${debouncedFilter.toLowerCase()}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params
  })
}

export async function getAccounts(params) {
  const filtersQuery = encodeURIComponent(JSON.stringify(params))
  const url = `${ApiAdminInvoice.EndPoint}/relational/v1/master-data/accounts?filters=${filtersQuery}`
  const requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  }).then(result => result.data)
}
