import React from 'react'

import { Badge, Box, IconButton, TextField, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'
import Autocomplete from '@material-ui/lab/Autocomplete'

import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import { checkErrorOrWarning } from 'utils/functions'

import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(styles)

const AutoCompleteAsyncLineItems = props => {
  const {
    validationErrorsState,
    handleChange,
    value,
    lineId,
    removeWarningLineItem,
    readOnly,
    optionsValues,
    isLoading,
    label,
    field,
    filter,
    setFilter
  } = props

  const classes = useStyles()

  const validated = validationErrorsState[`${field}.${lineId}`]?.valid ?? null

  const message = validationErrorsState[`${field}.${lineId}`]?.messages

  const typeMsg = checkErrorOrWarning(message, validated)

  let tooltipArrowClass = null
  let tooltipClass = null
  let badgeClass = null

  if (typeMsg === 'warning') {
    tooltipArrowClass = classes.warningColor
    tooltipClass = classes.tooltipWarning
    badgeClass = classes.badgeWarning
  } else if (typeMsg === 'error') {
    tooltipArrowClass = classes.errorColor
    tooltipClass = classes.tooltipError
    badgeClass = classes.badgeError
  }

  function validationMessages(validationsError) {
    if (!validationsError) {
      return ''
    }

    if (Array.isArray(validationsError)) {
      return (
        <>
          {validationsError.map((message, index) => (
            <div key={index}>{message.message}</div>
          ))}
        </>
      )
    }

    const messages = validationsError.split('\n')

    return (
      <>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </>
    )
  }

  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Tooltip
        classes={{
          arrow: tooltipArrowClass,
          tooltip: tooltipClass
        }}
        title={typeMsg !== '' ? validationMessages(message) : ''}
        arrow
        placement="right"
      >
        <Badge
          classes={{
            badge: badgeClass
          }}
          badgeContent={
            typeMsg === 'warning' ? (
              <WarningIcon className={classes.warningColor}></WarningIcon>
            ) : null
          }
          invisible={typeMsg !== '' ? true : false}
        >
          <Autocomplete
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            value={value}
            onChange={(event, newValue) => {
              handleChange(newValue)
            }}
            inputValue={filter}
            onInputChange={(event, newInputValue) => {
              setFilter(newInputValue)
            }}
            options={optionsValues ?? []}
            id="asynchronous-commodities"
            style={{ width: 300 }}
            disabled={readOnly}
            className={readOnly ? classes.readOnly : ''}
            getOptionSelected={(option, value) => option.value === value}
            getOptionLabel={option => option.label || option}
            renderOption={option => (
              <React.Fragment>
                {option.label ? `${option.value} (${option.label})` : option.value}
              </React.Fragment>
            )}
            filterOptions={x => x}
            loading={isLoading}
            renderInput={params => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                fullWidth
                multiline
                error={typeMsg === 'error' ? true : false}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                  classes: {
                    notchedOutline: typeMsg === 'warning' ? classes.inputWarning : ''
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: typeMsg === 'warning' ? classes.warningColor : '',
                    focused: typeMsg === 'warning' ? classes.warningColor : ''
                  }
                }}
              />
            )}
          />
        </Badge>
      </Tooltip>
      {typeMsg === 'warning' ? (
        <IconButton
          style={{ padding: '0', marginLeft: '10px' }}
          className={classes.buttonSuccess}
          onClick={() => removeWarningLineItem(field, lineId)}
        >
          <CheckCircleOutlineIcon className={classes.successColor} />
        </IconButton>
      ) : null}
    </Box>
  )
}

export default AutoCompleteAsyncLineItems
