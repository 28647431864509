/*eslint-disable*/
import Grid from '@material-ui/core/Grid'
import React, { useState } from 'react'
import Datetime from 'react-datetime'
// @material-ui/core components
import { CircularProgress, Fade, MenuItem, Select } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

// material ui icons
import AddIcon from '@material-ui/icons/Add'
import AddAlert from '@material-ui/icons/AddAlert'
import BusinessIcon from '@material-ui/icons/Business'
import ClearIcon from '@material-ui/icons/Clear'
import Close from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Flag from '@material-ui/icons/Flag'

// core components
import CoreButton from '@material-ui/core/Button'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Heading from 'components/Heading/Heading.js'
import Snackbar from 'components/Snackbar/Snackbar.js'

// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/Tenant/createStyle.js'
import { enabledApps } from 'utils/Constants'
import RateLimitModal from '../RateLimitModal'

const useStyles = makeStyles(styles)

export default function createView(props) {
  const [limitModal, setLimitModal] = useState({ open: false, key: null })
  const onCloseModal = () => {
    setLimitModal({ open: false, key: null })
  }

  const classes = useStyles()
  if (props.pageIsLoading) {
    return <div></div>
  }

  const breadcrumbViews = [
    {
      name: 'Tenants',
      url: `/admin/tenants/index`,
      icon: BusinessIcon
    },
    {
      name: 'New Tenant',
      url: `/admin/tenants/create`,
      icon: AddIcon
    }
  ]

  function getSubsCriptions(app) {
    const appInfo = enabledApps[app]

    if (appInfo && appInfo.subscriptions && appInfo.subscriptions.length > 0) {
      return appInfo.subscriptions
    }
  }

  function onChangeRateLimit(value, key) {
    if (value === 'custom') {
      setLimitModal({ open: true, key: key })
    }
  }

  function onSuccessModalLimits(limits, key) {
    props.onFieldChange('optionsLimits', limits, key)
  }

  const modalDefaultValues = props.tenant.enabledApps.find(x => x.key === limitModal.key)

  function generateSubscriptionSection(item) {
    const subscriptions = getSubsCriptions(item.key)
    const appState = props.tenantState.enabledApps[item.key] ?? {}
    const options = subscriptions
      ? subscriptions.map(x => (
          <MenuItem
            key={x.id}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            style={{
              overflowX: 'auto',
              textOverflow: 'ellipsis'
            }}
            value={x.id}
          >
            {x.label}
          </MenuItem>
        ))
      : null

    const isValidApp = item.key !== 'assistant'
    return (
      <Accordion key={item.key}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>{item.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer>
            <GridItem md={12}>
              <GridContainer>
                <GridItem md={4}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <InputLabel className={classes.label}>Suscription Type</InputLabel>
                      <FormControl fullWidth className={classes.selectFormControl}>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={item.suscriptionType}
                          onChange={e => props.onFieldChange('suscriptionType', e.target.value, item.key)}
                          inputProps={{
                            name: item.suscriptionType,
                            id: item.suscriptionType
                          }}
                        >
                          <MenuItem
                            key="paid"
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            style={{
                              overflowX: 'auto',
                              textOverflow: 'ellipsis'
                            }}
                            value="paid"
                          >
                            Paid
                          </MenuItem>
                          {!subscriptions && item.freeTrial ? (
                            <MenuItem
                              key="freeTrial"
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              style={{
                                overflowX: 'auto',
                                textOverflow: 'ellipsis'
                              }}
                              value="freeTrial"
                            >
                              Free Trial
                            </MenuItem>
                          ) : null}
                          {options}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem md={4}>
                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <InputLabel className={classes.label}>Start Date</InputLabel>
                      <FormControl fullWidth>
                        <Datetime
                          locale="en-US"
                          closeOnSelect={true}
                          dateFormat="MM/DD/YYYY"
                          value={item.startDate}
                          timeFormat={false}
                          onChange={event => props.onFieldChange('startDate', event, item.key)}
                          inputProps={{
                            placeholder: 'Start Date',
                            value: item.startDate
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem md={4}>
                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <InputLabel className={classes.label}>Finish Date</InputLabel>
                      <FormControl fullWidth>
                        <Datetime
                          locale="en-US"
                          closeOnSelect={true}
                          dateFormat="MM/DD/YYYY"
                          value={item.endDate}
                          timeFormat={false}
                          onChange={event => props.onFieldChange('endDate', event, item.key)}
                          inputProps={{
                            placeholder: 'End Date',
                            value: item.endDate
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
            {isValidApp ? (
              <GridItem md={12}>
                <GridContainer>
                  <GridItem xs>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs>
                        <FormControl fullWidth className={classes.selectFormControl}>
                          <InputLabel htmlFor="demo-dialog-native">Usage Limit</InputLabel>
                          <Select
                            value={item.rateLimitValue}
                            onChange={e => {
                              onChangeRateLimit(e.target.value, item.key)
                              props.onFieldChange('rateLimitValue', e.target.value, item.key)
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              style={{
                                overflowX: 'auto',
                                textOverflow: 'ellipsis'
                              }}
                              aria-label="None"
                              value=""
                            />
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              style={{
                                overflowX: 'auto',
                                textOverflow: 'ellipsis'
                              }}
                              value={'unlimited'}
                            >
                              Unlimited
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              style={{
                                overflowX: 'auto',
                                textOverflow: 'ellipsis'
                              }}
                              value={'custom'}
                            >
                              Custom
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      {item.rateLimitValue === 'custom' ? (
                        <Grid item xs>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            style={{ height: '100%' }}
                          >
                            <CoreButton
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => setLimitModal({ open: true, key: item.key })}
                            >
                              View
                            </CoreButton>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </GridItem>

                  <GridItem xs>
                    <CustomInput
                      success={appState.consumptionValues === 'success'}
                      error={appState.consumptionValues === 'error'}
                      labelText="Send notifications when consumption at"
                      id="inputConsumptionAt"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          props.onFieldChange('consumptionValues', event.target.value, item.key)
                        },
                        type: 'text',
                        value: item.consumptionValues
                      }}
                    />
                  </GridItem>
                  <GridItem xs>
                    <CustomInput
                      success={appState.consumptionNotificationEmail === 'success'}
                      error={appState.consumptionNotificationEmail === 'error'}
                      labelText="Consumption Notification Email"
                      id="inputConsumptionNotification"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          props.onFieldChange(
                            'consumptionNotificationEmail',
                            event.target.value,
                            item.key
                          )
                        },
                        type: 'text',
                        value: item.consumptionNotificationEmail
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            ) : null}

            <GridItem md={12}>
              <GridContainer>
                <GridItem md={4}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControlLabel
                        className={classes.activeToggle}
                        control={
                          <Switch
                            checked={item.active}
                            onChange={event => props.onFieldChange('active', event.target.checked, item.key)}
                            value={item.active}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Active"
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <>
        <Breadcrumbs views={breadcrumbViews} />
        <Heading textAlign="center" title="New Tenant" />
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <Flag />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>New</h4>
                <IconButton style={{ float: 'right' }} color="primary" component="span" onClick={() => props.onClear()}>
                  <ClearIcon />
                </IconButton>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={4}>
                          <CustomInput
                            success={props.tenantState.name === 'success'}
                            error={props.tenantState.name === 'error'}
                            labelText="Tenant Name*"
                            id="inputName"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                props.onFieldChange('name', event.target.value)
                              },
                              type: 'text',
                              value: props.tenant.name,
                              endAdornment:
                                props.tenantState['name'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                          <CustomInput
                            success={props.tenantState.tenantId === 'success'}
                            error={props.tenantState.tenantId === 'error'}
                            labelText="Unique Name*"
                            id="inputTenant"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                props.onFieldChange('tenantId', event.target.value)
                              },
                              type: 'text',
                              value: props.tenant.tenantId,
                              endAdornment:
                                props.tenantState['tenantId'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                          <CustomInput
                            success={props.tenantState.city === 'success'}
                            error={props.tenantState.city === 'error'}
                            labelText="City*"
                            id="inputCity"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                props.onFieldChange('city', event.target.value)
                              },
                              type: 'text',
                              value: props.tenant.city,
                              endAdornment:
                                props.tenantState['city'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={8} md={8}>
                          <CustomInput
                            success={props.tenantState.address === 'success'}
                            error={props.tenantState.address === 'error'}
                            labelText="Full Address*"
                            id="inputAddress"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                props.onFieldChange('address', event.target.value)
                              },
                              type: 'text',
                              value: props.tenant.address,
                              endAdornment:
                                props.tenantState['address'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                              Type*
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={props.tenant[`type`]}
                              onChange={e => props.onFieldChange(`type`, e.target.value)}
                              inputProps={{
                                name: `type`,
                                id: `type`
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Select the type
                              </MenuItem>
                              {[{ name: 'Production', code: 'prod' }, { name: 'Test', code: 'test' }].map((type, index) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  style={{
                                    overflowX: 'auto',
                                    textOverflow: 'ellipsis'
                                  }}
                                  value={type.code}
                                  key={index}
                                >
                                  {type.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                              Primary Communication Language*
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={props.tenant[`language`]}
                              onChange={e => props.onFieldChange(`language`, e.target.value)}
                              inputProps={{
                                name: `language`,
                                id: `language`
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Select the language
                              </MenuItem>
                              {[{ name: 'English', code: 'en' }, { name: 'Spanish', code: 'es' }].map((language, index) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  style={{
                                    overflowX: 'auto',
                                    textOverflow: 'ellipsis'
                                  }}
                                  value={language.code}
                                  key={index}
                                >
                                  {language.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                          <CustomInput
                            success={props.tenantState.email === 'success'}
                            error={props.tenantState.email === 'error'}
                            labelText="Tenant Email*"
                            id="inputEmail"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                props.onFieldChange('email', event.target.value)
                              },
                              type: 'text',
                              value: props.tenant.email,
                              endAdornment:
                                props.tenantState['email'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                          <CustomInput
                            success={props.tenantState.webSiteUrl === 'success'}
                            error={props.tenantState.webSiteUrl === 'error'}
                            labelText="Tenant Web Address*"
                            id="inputWebSiteUrl"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                props.onFieldChange('webSiteUrl', event.target.value)
                              },
                              type: 'text',
                              value: props.tenant.webSiteUrl,
                              endAdornment:
                                props.tenantState['webSiteUrl'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <FormControlLabel
                            className={classes.activeToggle}
                            control={
                              <Switch
                                checked={props.tenant.enabled}
                                onChange={event => props.onFieldChange('enabled', event.target.checked)}
                                value="enabled"
                                classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="Enabled"
                          />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <FormControlLabel
                            className={classes.activeToggle}
                            control={
                              <Switch
                                checked={props.tenant.hasSubdomain}
                                onChange={event => props.onFieldChange('hasSubdomain', event.target.checked)}
                                value="hasSubdomain"
                                classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="Has Subdomain"
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <h5>Enabled Apps</h5>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          {props.tenant.enabledApps ? props.tenant.enabledApps.map(item => generateSubscriptionSection(item)) : null}
                          <br />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <h5>Customization</h5>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            success={props.tenantState.colorCode === 'success'}
                            error={props.tenantState.colorCode === 'error'}
                            labelText="Primary Color Code*"
                            id="inputColorCode"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                props.onFieldChange('colorCode', event.target.value)
                              },
                              type: 'text',
                              value: props.tenant.colorCode,
                              endAdornment:
                                props.tenantState['colorCode'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            success={props.tenantState.secondColorCode === 'success'}
                            error={props.tenantState.secondColorCode === 'error'}
                            labelText="Secondary Color Code*"
                            id="inputSecondColorCode"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                props.onFieldChange('secondColorCode', event.target.value)
                              },
                              type: 'text',
                              value: props.tenant.secondColorCode,
                              endAdornment:
                                props.tenantState['secondColorCode'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            success={props.tenantState.imageLogo === 'success'}
                            error={props.tenantState.imageLogo === 'error'}
                            labelText="Logo URL*"
                            id="inputImageLogo"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                props.onFieldChange('imageLogo', event.target.value)
                              },
                              type: 'url',
                              value: props.tenant.imageLogo,
                              endAdornment:
                                props.tenantState['imageLogo'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <div className={classes.formCategory}>
                    <small>*</small> Mandatory fields
                  </div>
                  <div className={classes.center}>
                    {props.isLoadingSave ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <Button color="primary" onClick={props.onTenantSave}>
                        Save
                      </Button>
                    )}
                  </div>
                </form>
              </CardBody>
            </Card>
            <Snackbar
              place="br"
              color="success"
              icon={AddAlert}
              message="Tenant created successfully"
              open={props.createSuccess}
              closeNotification={() => props.setCreateSuccess(false)}
              close
            />
            <Snackbar
              place="bl"
              color="danger"
              icon={AddAlert}
              message={props.validationMessage}
              open={props.createError}
              closeNotification={() => props.setCreateError(false)}
              close
            />
          </GridItem>
        </GridContainer>
        <RateLimitModal
          key={limitModal.key}
          appKey={limitModal.key}
          open={limitModal.open}
          handleClose={onCloseModal}
          defaultValues={modalDefaultValues?.optionsLimits ?? []}
          onSuccess={onSuccessModalLimits}
        />
      </>
    </Fade>
  )
}
