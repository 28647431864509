import React from 'react'
// @material-ui/core components
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'
import GridItem from 'components/Grid/GridItem.js'
import { useGetPoDetails } from 'hooks/useMasterData.js'
import { useWatch } from 'react-hook-form'
import { HeaderFieldsSide } from 'utils/Constants.js'
import GridContainer from '../../components/Grid/GridContainer.js'

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export default function PoModalDetails(props) {
  const classes = useStyles()
  const { showModal, onClose, appConfig, control, appId } = props

  const aribaApi = appConfig?.data?.params?.ariba?.buyerApi
  const value = useWatch({
    name: 'summaryFields',
    control
  })

  const po = value?.find(summaryField => summaryField.type === HeaderFieldsSide.PURCHASE_ORDER.type)

  const { isLoading, data: poInfo } = useGetPoDetails(po?.value, { appId })

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      fullWidth={true}
      maxWidth={'sm'}
      open={showModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose()}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
    >
      <DialogTitle id="notice-modal-slide-title">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          Purchase Order Information
        </div>
      </DialogTitle>
      <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : poInfo ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <p>
                  <strong>PO Number:</strong> {po?.value}
                </p>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <p>
                  <strong>PO Title:</strong> {poInfo?.title}
                </p>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <p>
                  <strong>Requisition Number:</strong>{' '}
                  {aribaApi ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://${aribaApi.siteRegion}.ariba.com/Buyer/Main/ad/webjumper?realm=${aribaApi.realm}&itemUniqueName=${poInfo?.requisitionNumber}&itemClassName=ariba.purchasing.core.Requisition`}
                    >
                      {poInfo?.requisitionNumber}
                    </a>
                  ) : (
                    <strong>{poInfo?.requisitionNumber}</strong>
                  )}
                </p>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <p>
                  <strong>Supplier:</strong> {poInfo.vendor?.id}
                </p>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <p>
                  <strong>Payment Terms:</strong> {poInfo?.paymentTerms}
                </p>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <p>
                  <strong>PO Requestor:</strong> {poInfo?.requestor}
                </p>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          <p>This PO number does not exist in our system.</p>
        )}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => onClose()} color="primary" variant="outlined">
          Ok
        </Button>
        <Button onClick={() => onClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
