import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function SyncLoader(props) {
  const { showLoader, textLoader } = props

  return showLoader ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
        marginTop: '10px'
      }}
    >
      <CircularProgress
        style={{
          width: '20px',
          height: '20px',
          marginRight: '20px'
        }}
      />
      <span>{textLoader}</span>
    </div>
  ) : null
}

SyncLoader.defaultProps = {
  showLoader: true,
  textLoader: 'Synchronizing...'
}

SyncLoader.propTypes = {
  showLoader: PropTypes.bool,
  textLoader: PropTypes.string
}
