import MomentUtils from '@date-io/moment'
import { Badge, Grid, Tooltip } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import React from 'react'
import { Controller } from 'react-hook-form'
import { generateJsonValidationV2 } from 'utils/validator'
import { errorTypes, getError } from './useHeader'

moment.locale(window.navigator.userLanguage || window.navigator.language)

function DatePickerField(props) {
  const {
    control,
    index,
    readOnly,
    label,
    headerField,
    headerData,
    headerFieldsActions,
    getValues,
    requiredFields
  } = props

  const { deleteErrorHeader, getColors, classes } = headerFieldsActions

  const errorField = headerData.errors.find(x => x.field === headerField.field)

  const errorData = getError(errorField)

  const fieldRule = requiredFields.find(x => x.standardField === headerField.field)
  const rules = readOnly ? {} : generateJsonValidationV2(fieldRule, getValues)

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <Controller
        name={`headerFields.${index}.value`}
        control={control}
        rules={rules}
        render={({ field: { ref, ...rest }, fieldState: { error, isDirty } }) => {
          const isError = Boolean(error) || errorData?.criticalityType === errorTypes.critical
          const isVisible = Boolean(error) || Boolean(errorData)
          const errorMessage = errorData ? errorData?.message : error?.message

          return (
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Tooltip
                  classes={{
                    arrow: isError ? classes.errorColor : classes.warningColor,
                    tooltip: isError ? classes.tooltipError : classes.tooltipWarning
                  }}
                  title={isVisible ? errorMessage : ''}
                  arrow
                  placement="right"
                >
                  <Badge
                    style={{ width: '95%' }}
                    classes={{
                      badge: isError ? classes.badgeError : classes.badgeWarning
                    }}
                    badgeContent={
                      isError ? '!' : <WarningIcon className={classes.warningColor}></WarningIcon>
                    }
                    invisible={isVisible ? false : true}
                  >
                    <KeyboardDatePicker
                      {...rest}
                      clearable
                      style={{ width: '100%' }}
                      variant="inline"
                      inputVariant="outlined"
                      id={headerField.id}
                      label={label ?? ''}
                      format="YYYY-MM-DD"
                      error={isError}
                      onChange={date => {
                        rest.onChange(moment(date).format('YYYY-MM-DD'))
                        deleteErrorHeader({ field: headerField.field })
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      InputProps={{
                        readOnly: readOnly ?? false,
                        classes: {
                          notchedOutline: !isError ? getColors(errorData, isDirty).inputColor : ''
                        }
                      }}
                      InputLabelProps={{
                        classes: {
                          root: !isError ? getColors(errorData, isDirty).color : '',
                          focused: !isError ? getColors(errorData, isDirty).color : ''
                        }
                      }}
                      disabled={readOnly ?? false}
                      className={readOnly ? classes.readOnly : ''}
                    />
                  </Badge>
                </Tooltip>
              </Grid>
            </Grid>
          )
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePickerField
