import { useGetRolesPermissions } from 'hooks/useAuth'
// import { useLocalStorageState } from 'hooks/useLocalStorage'
import * as React from 'react'
import { useSelector } from 'react-redux'

export const PermissionContext = React.createContext()
export const PermissionProvider = props => {
  const isAuthenticated = useSelector(state => state.login.isAuthenticated)

  const [roles, setRoles] = React.useState([])
  const [permissions, setPermissions] = React.useState([])
  const getPermissions = useGetRolesPermissions(roles)

  React.useEffect(() => {
    if (isAuthenticated) {
      const userInfo = window.localStorage.getItem('userInfo')
      if (userInfo) {
        const valueInLocalStorage = JSON.parse(userInfo)

        setRoles(valueInLocalStorage.roles ?? [])
      }
    }
  }, [isAuthenticated])

  React.useEffect(() => {
    if (getPermissions.isLoading) {
      return
    }

    if (getPermissions.isError) {
      return
    }

    if (getPermissions.isSuccess) {
      setPermissions(getPermissions.data)
    }
  }, [
    getPermissions.isLoading,
    getPermissions.isError,
    getPermissions.isSuccess,
    getPermissions.data
  ])

  return <PermissionContext.Provider value={{ permissions }} {...props} />
}
