import React, { useState } from "react";
import { useHistory } from 'react-router-dom'
// react component for creating dynamic tables
import MaterialTable from 'material-table'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Add from "@material-ui/icons/PlaylistAdd";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { NavLink } from "react-router-dom";
import { Fade } from "@material-ui/core";

//hooks
import { useCheckPermissions } from '../../../hooks/useAuth.js';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  addButton: {
    marginTop: "-25px",
    float: "right",
  },
};

const useStyles = makeStyles(styles);

export default function ReactTables(props) {
  const [userFilters, setUserFilters] = useState({ status: ['Opened']})
  const { data: pmCreateTenants } = useCheckPermissions('CreateTenants', ['Manage.Support.CloseTickets'])
  const { data: pmListTicketDetails } = useCheckPermissions('ListTicketDetails', ['Manage.Support.ListTicketDetails'])

  let history = useHistory()
  const classes = useStyles();

  const actions = [
    {
      icon: () => <MoreHorizIcon style={{ color: '#081c3e' }} />,
      tooltip: 'Details',
      color: 'info',
      onClick: (event, rowData) => {
        history.push(`/admin/support/ticket/${rowData.id}/tenant/${rowData.tenantId}`)
      }
    }
  ]

  function filterColumns(columns) {
    const configCopy = { ...userFilters }
    let column
    for (column of columns) {
      configCopy[column.column.field] = column.value
    }
    setUserFilters(configCopy)
  }

  let dataTableInfo = props.ticketsList.map((prop) => {
    return {
      id: prop.ticketId,
      tenantId: prop.tenantId,
      title: prop.ticketDescription.title,
      application: prop.ticketDescription.appId,
      instance: prop.ticketDescription.instance,
      status: prop.status,
      priority: prop.ticketDescription.priority,
      associatedUser: prop.associatedUser
        ? prop.associatedUser.email
        : (typeof prop.creationUser === 'string'
        ? prop.creationUser
        : prop.creationUser.email),
      createdBy:
        typeof prop.creationUser === 'string'
          ? prop.creationUser
          : prop.creationUser.email,
      createdTime: prop.createdDate,
      updatedBy:
        typeof prop.lastUpdatedUser === 'string'
          ? prop.lastUpdatedUser
          : prop.lastUpdatedUser.email,
      updatedTime: prop.lastUpdatedDate
    }
  });

  let ticketsData = {}
  let obj = {}
  let data = {}
  if (dataTableInfo) {
    let transaction
    for (transaction of dataTableInfo) {
      if (transaction.tenantId) {
        obj = {}
        obj[transaction.tenantId] = transaction.tenantId
        data['tenantId'] = { ...data['tenantId'], ...obj }
      }

      if (transaction.title) {
        obj = {}
        obj[transaction.title] = transaction.title
        data['title'] = { ...data['title'], ...obj }
      }

      if (transaction.application) {
        obj = {}
        obj[transaction.application] = transaction.application
        data['application'] = { ...data['application'], ...obj }
      }
      
      if (transaction.instance) {
        obj = {}
        obj[transaction.instance] = transaction.instance
        data['instance'] = { ...data['instance'], ...obj }
      }

      if (transaction.status) {
        obj = {}
        obj[transaction.status] = transaction.status
        data['status'] = { ...data['status'], ...obj }
      }

      if (transaction.priority) {
        obj = {}
        obj[transaction.priority] = transaction.priority
        data['priority'] = { ...data['priority'], ...obj }
      }

      if (transaction.associatedUser) {
        obj = {}
        obj[transaction.associatedUser] = transaction.associatedUser
        data['associatedUser'] = { ...data['associatedUser'], ...obj }
      }

      if (transaction.createdBy) {
        obj = {}
        obj[transaction.createdBy] = transaction.createdBy
        data['createdBy'] = { ...data['createdBy'], ...obj }
      }

      if (transaction.createdTime) {
        obj = {}
        obj[transaction.createdTime] = new Date(transaction.createdTime).toISOString()
        data['createdTime'] = { ...data['createdTime'], ...obj }
      }

      if (transaction.updatedBy) {
        obj = {}
        obj[transaction.updatedBy] = transaction.updatedBy
        data['updatedBy'] = { ...data['updatedBy'], ...obj }
      }

      if (transaction.updatedTime) {
        obj = {}
        obj[transaction.updatedTime] = new Date(transaction.createdTime).toISOString()
        data['updatedTime'] = { ...data['updatedTime'], ...obj }
      }
    }
    ticketsData = data
  }

  if (props.pageIsLoading) {
    return <div></div>;
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <ContactSupportIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Support Tickets list</h4>
              { pmCreateTenants 
              ? <NavLink to={`/admin/support/ticket/create`}>
                <Tooltip title="Create new support ticket">
                  <Button color="primary" className={classes.addButton}>
                    <Add className={classes.icons} />
                  </Button>
                </Tooltip>
              </NavLink>
              : <></> }
            </CardHeader>
            <CardBody>
              <MaterialTable
                data={dataTableInfo}
                columns={ dataTableInfo.length <= 0 ? [] : ([
                  {
                    title: "Tenant",
                    field: "tenantId",
                    hiddenByColumnsButton: true,
                    align: 'center',
                    lookup: ticketsData['tenantId'],
                    defaultFilter: userFilters['tenantId']
                  },
                  {
                    title: "Title",
                    field: "title",
                    hiddenByColumnsButton: true,
                    align: 'center',
                    lookup: ticketsData['title'],
                    defaultFilter: userFilters['title']
                  },
                  {
                    title: "Application",
                    field: "application",
                    hiddenByColumnsButton: true,
                    align: 'center',
                    width: 120,
                    lookup: ticketsData['application'],
                    defaultFilter: userFilters['application']
                  },
                  {
                    title: "Instance",
                    field: "instance",
                    hiddenByColumnsButton: true,
                    align: 'center',
                    width: 120,
                    lookup: ticketsData['instance'],
                    defaultFilter: userFilters['instance']
                  },
                  {
                    title: "Priority",
                    field: "priority",
                    hiddenByColumnsButton: true,
                    align: 'center',
                    width: 90,
                    lookup: ticketsData['priority'],
                    defaultFilter: userFilters['priority']
                  },
                  {
                    title: "Status",
                    field: "status",
                    hiddenByColumnsButton: true,
                    align: 'center',
                    width: 90,
                    lookup: ticketsData['status'],
                    defaultFilter: userFilters['status']
                  },
                  {
                    title: "Associated User",
                    field: "associatedUser",
                    hiddenByColumnsButton: true,
                    align: 'center',
                    lookup: ticketsData['associatedUser'],
                    defaultFilter: userFilters['associatedUser']
                  },
                  {
                    title: "Created By",
                    field: "createdBy",
                    hiddenByColumnsButton: true,
                    align: 'center',
                    lookup: ticketsData['createdBy'],
                    defaultFilter: userFilters['createdBy']
                  },
                  {
                    //accessor: d => new Date(d.createdTime).toISOString(),
                    title: "Creation Time",
                    field: "createdTime",
                    hiddenByColumnsButton: true,
                    align: 'center',
                    lookup: ticketsData['createdTime'],
                    defaultFilter: userFilters['createdTime'],
                    sorting: true,
                    render: d => new Date(d.createdTime).toISOString()
                  },
                  // {
                  //   Header: "Updated By",
                  //   accessor: "updatedBy",
                  // },
                  // {
                  //   Header: "Updated Time",
                  //   id: "updatedTime",
                  //   accessor: d => new Date(d.updatedTime).toString(),
                  // },
                ])}
                actions={pmListTicketDetails ? actions : []}
                onFilterChange={filterColumns}
                options={{
                  columnsButton: true,
                  actionsColumnIndex: -1,
                  search: false,
                  sorting: true,
                  showTitle: false,
                  filtering: true,
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Fade>
  );
}
