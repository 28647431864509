import React from 'react'
import moment from 'moment'
import MaterialTable from 'material-table'
import { useHistory } from 'react-router-dom'

// Material UI
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import TablePagination from '@material-ui/core/TablePagination'

// Components
import { TablePaginationActions } from 'components/Invoice/TablePaginationActions'

// Icons
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

// Functions
import { statusColor } from 'utils/functions'
import { newTransactionExecution } from 'services/apiInvoicesConf'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Constants
import { frequency, transactionStatus } from 'utils/Constants'

// View
import SyncLoader from 'components/SyncLoader/SyncLoader.js'

const useStyles = makeStyles(styles)

export default function DetailsView(props) {
  const {
    transaction,
    childTransactions,
    pagination,
    setPagination,
    appId,
    group,
    setSubmitSuccess,
    setCreateError
  } = props
  const classes = useStyles()
  const history = useHistory()

  function modifyText(text) {
    let camelCase = text
    if (camelCase.includes('_')) {
      camelCase = camelCase.toLowerCase().replace(/([-_][a-z])/gi, $1 => {
        return $1
          .toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    }
    const result = camelCase.replace(/([A-Z])/g, ' $1')
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)

    return finalResult
  }

  const handleChangePage = (event, newPage) => {
    setPagination(old => {
      return {
        ...old,
        page: newPage
      }
    })
  }

  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: true
    },
    {
      field: 'transaction',
      title: 'Transaction'
    },
    {
      field: 'destination',
      title: 'Destination'
    },
    {
      field: 'fromDate',
      title: 'From Date',
      render: rowData => moment(rowData.fromDate).format('MM/DD/YYYY')
    },
    {
      field: 'creationDate',
      title: 'Creation Date',
      render: rowData => moment(rowData.creationDate).format('MM/DD/YYYY HH:mm'),
      customSort: (a, b) => {
        return moment(moment(b.creationDate)).isBefore(moment(a.creationDate)) ? 1 : -1
      },
      defaultSort: 'desc'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: statusColor(rowData.status) }}>{transactionStatus[rowData.status]}</p>
      )
    },
    {
      field: 'frequency',
      title: 'Frequency',
      render: rowData => frequency[rowData.frequency]
    }
  ]

  async function onNewRun() {
    setSubmitSuccess({
      isOpen: true,
      message: 'New execution initialized.'
    })
    const response = await newTransactionExecution({
      appId: transaction.data.appId,
      transactionId: transaction.data.transactionId
    })
    if (response && response.success) {
      childTransactions.refetch({ throwOnError: false })
    } else {
      if (response) {
        let message = response.message
        if (!message) {
          message = 'Something went wrong, please try again later.'
        }
        setCreateError({
          message,
          isOpen: true,
          color: 'danger'
        })
      }
    }
  }

  const executionFrequency = transaction.data?.params?.execution?.frequency ?? 'oneTime'

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item md={4}>
        <p>
          <b>Name:</b> {transaction.data.params.name}
        </p>
      </Grid>
      <Grid item md={4}>
        <p>
          <b>Frequency:</b> {frequency[transaction.data.params.execution.frequency]}
        </p>
      </Grid>
      <Grid item md={4}>
        <p>
          <b>From Date:</b> {moment(transaction.data.params.filters.fromDate).format('MM/DD/YYYY')}
        </p>
      </Grid>
      <Grid item md={transaction.data.params.execution.frequency === 'oneTime' ? 12 : 4}>
        <p>
          <b>Status:</b> {transactionStatus[transaction.data.status]}
        </p>
      </Grid>
      {transaction.data.params.execution.frequency === 'days' ||
      transaction.data.params.execution.frequency === 'weeks' ||
      transaction.data.params.execution.frequency === 'months' ? (
        <Grid item md={4}>
          <p>
            <b>Execution Time:</b>{' '}
            {moment(transaction.data.params.execution.executionTime).format('hh:mm a')}
          </p>
        </Grid>
      ) : null}
      {transaction.data.params.execution.frequency === 'days' ? (
        <Grid item md={4}>
          <p>
            <b>Execution:</b> Every {transaction.data.params.execution.executionDay} days
          </p>
        </Grid>
      ) : null}
      {transaction.data.params.execution.frequency === 'weeks' ? (
        <Grid item md={4}>
          <p>
            <b>Execution:</b> Every {transaction.data.params.execution.executionDay} weeks
          </p>
        </Grid>
      ) : null}
      {transaction.data.params.execution.frequency === 'months' ? (
        <Grid item md={4}>
          <p>
            <b>Execution:</b> Every {transaction.data.params.execution.executionDay} months
          </p>
        </Grid>
      ) : null}
      {transaction.data.params.execution.frequency === 'hours' ? (
        <Grid item md={8}>
          <p>
            <b>Execution:</b> Every {transaction.data.params.execution.executionHour} hours
          </p>
        </Grid>
      ) : null}
      {transaction.data.params.execution.frequency === 'minutes' ? (
        <Grid item md={8}>
          <p>
            <b>Execution:</b> Every {transaction.data.params.execution.executionMinute} minutes
          </p>
        </Grid>
      ) : null}
      <Grid item md={12}>
        <p>
          <b>Entities:</b>{' '}
        </p>
        <List>
          {transaction.data.params.entities.map(entity => (
            <ListItem button key={entity.entity}>
              <ListItemText
                disableTypography
                primary={
                  <Typography style={{ fontSize: '14px' }}>{modifyText(entity.entity)}</Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
      <SyncLoader showLoader={childTransactions.isFetching && !childTransactions.isLoading} />
      <Grid item xs={12} sm={12}>
        {childTransactions.isLoading ? (
          <Grid container justifyContent="center">
            <div className={classes.circularProgress}>
              <CircularProgress color="inherit" />
            </div>
          </Grid>
        ) : executionFrequency !== 'oneTime' && !transaction.data?.parentTransactionId ? (
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Tooltip title={'New Execution'}>
                <Fab
                  style={{ marginLeft: '8px' }}
                  size="small"
                  color="primary"
                  className={classes.floatRight + ' ' + classes.bgColorPrimary}
                  onClick={() => onNewRun()}
                >
                  <PlayArrowIcon fontSize="small" />
                </Fab>
              </Tooltip>
              <Tooltip title={'Refresh'}>
                <Fab
                  size="small"
                  color="primary"
                  className={classes.floatRight + ' ' + classes.bgColorPrimary}
                  onClick={() => childTransactions.refetch({ throwOnError: true })}
                >
                  <RefreshRoundedIcon fontSize="small" />
                </Fab>
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p>
                <b>Executions:</b>
              </p>
              <MaterialTable
                columns={columns}
                data={childTransactions.data?.rows ?? []}
                options={{
                  actionsColumnIndex: -1,
                  sorting: true,
                  showTitle: false,
                  emptyRowsWhenPaging: false,
                  pageSize: pagination.rowsPage,
                  pageSizeOptions: [10, 20, 30, 40, 50]
                }}
                actions={[
                  {
                    icon: 'view_column',
                    tooltip: 'Logs',
                    onClick: (event, rowData) =>
                      history.push(`/admin/invoiceAI/coupa/detail/${group}/${appId}/${rowData.id}`)
                  }
                ]}
                onChangeRowsPerPage={pageSize => {
                  setPagination({
                    page: 0,
                    rowsPage: pageSize,
                    lastEvaluatedKeys: []
                  })
                }}
                components={{
                  Pagination: props => {
                    return (
                      <TablePagination
                        {...props}
                        onChangePage={handleChangePage}
                        ActionsComponent={subProps => (
                          <TablePaginationActions {...subProps} pagination={pagination} />
                        )}
                      />
                    )
                  }
                }}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  )
}
