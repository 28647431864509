import React, { useState, useEffect, useRef } from 'react'

import Grid from '@material-ui/core/Grid'

import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import { Button } from '@material-ui/core'
import CustomInput from 'components/CustomInput/CustomInput'
import { Typography } from '@material-ui/core'
import TypesModal from '../TypesModal'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

// import LinkIcon from '@material-ui/icons/Link'

const useStyles = makeStyles(styles)

export default function DnBQuestions (props) {
  const isMounted = useRef(false)
  const classes = useStyles()
  const [showModalTypes, setShowModalTypes] = useState(false)
  const [supplierInformation, setSupplierInformation] = useState(
    (Array.isArray(props.questions) && props.questions.length === 0) ||
      !Array.isArray(props.questions)
      ? undefined
      : props.questions[0].supplierInformation
  )
  const [addressInformation, setAddressInformation] = useState(
    (Array.isArray(props.questions) && props.questions.length === 0) ||
      !Array.isArray(props.questions)
      ? undefined
      : props.questions[0].addressInformation
  )
  useEffect(() => {
    if (!isMounted.current) {
      if (addressInformation) {
        for (let index = 0; index < props.types.address.length; index++) {
          const element = props.types.address[index]
          if (element.type === addressInformation.type)
            props.types.address[index] = addressInformation
        }
      }
      isMounted.current = true
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  }, [supplierInformation, addressInformation, props.types.address])

  console.log(props.types.address)
  return (
    <Card>
      <CardBody style={{ margin: '20px' }}>
        {showModalTypes ? (
          <TypesModal
            hideId={true}
            question={addressInformation}
            types={props.types.address}
            title={
              /* tableElement
            ? 'Modify Questionnaire Name'
            : 'Create new questionnaire'*/
              'Add Question'
            }
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            onCancel={() => {
              setShowModalTypes(false)
            }}
            onConfirm={newQuestionalue => {
              if (newQuestionalue) {
                setAddressInformation(newQuestionalue)
              }
              setShowModalTypes(false)
            }}
          ></TypesModal>
        ) : (
          ''
        )}
        <Grid container>
          {Array.isArray(props.questions) && props.questions.length > 0 ? (
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Typography>Address Information</Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Grid container>
                        {addressInformation && addressInformation.values
                          ? Object.keys(addressInformation.values).map(
                              (key, id) => {
                                return (
                                  <Grid item xs={12} key={id}>
                                    <span style={{ fontWeight: 'bold' }}>
                                      {addressInformation.values[key].label +
                                        ' : '}
                                    </span>
                                    <span>
                                      {addressInformation.values[key].value}
                                    </span>
                                  </Grid>
                                )
                              }
                            )
                          : ''}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ width: '100%' }}>
                        <Button
                          className={classes.button + ' ' + classes.success}
                          onClick={() => {
                            setShowModalTypes(true)
                          }}
                        >
                          Set Address
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>Supplier Information</Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <CustomInput
                      labelText={'Legal Name'}
                      inputInternalProps={{ style: { textAlign: 'center' } }}
                      inputProps={{
                        onChange: event => {
                          console.log(event.target.value)
                          const newSupplierInformation = JSON.parse(
                            JSON.stringify(supplierInformation)
                          )
                          newSupplierInformation.values.legalName.value =
                            event.target.value
                          setSupplierInformation(newSupplierInformation)
                        },

                        type: 'text',
                        value: supplierInformation.values.legalName.value
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Grid>

        <div style={{ width: '100%' }}>
          <Button
            className={classes.button}
            style={{ float: 'right' }}
            onClick={() => {
              props.questions[0].addressInformation = addressInformation
              props.questions[0].supplierInformation = supplierInformation
              props.onSave()
            }}
          >
            SAVE
          </Button>
        </div>
      </CardBody>
    </Card>
  )
}
