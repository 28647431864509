import React from 'react'
import PocRequisitionUpdate from 'views/POC/CoupaIFrameApp/RequisitionUpdate'
import PocDataAssureResult from 'views/POC/CoupaIFrameApp/DataAssure/ResultsApp'
import SupplierInformationApp from 'views/DnBConnector/CoupaIFrameApp/Suppliers/SupplierInformationMock'
import SupplierInformationDetailApp from 'views/DnBConnector/CoupaIFrameApp/Suppliers/SupplierInformationDetailMock'
import DnBSupplier from 'views/DnBConnector/CoupaIFrameApp/Suppliers/SupplierInformation'
import ErrorPage from 'views/Pages/ErrorPage'

import { Route, Switch } from 'react-router-dom'
import DnbDaSearchApp from 'views/DnBConnector/DataAssure/search'
import DnbDaDashboardApp from 'views/DnBConnector/DataAssure/dashboard'
import DnbDaDashboardV2App from 'views/DnBConnector/DataAssure/DashboardV2/dashboardV2App'

const CoupaIFrameAppLayout = props => {
  return (
    <>
      <Switch>
        <Route exact path="/coupa/update">
          <PocRequisitionUpdate />
        </Route>
        <Route exact path="/coupa/data-assure-results">
          <PocDataAssureResult />
        </Route>
        <Route exact path="/coupa/dnb/supplierMatchMock">
          <SupplierInformationApp />
        </Route>
        <Route exact path="/coupa/dnb/supplierInformationMock">
          <SupplierInformationDetailApp />
        </Route>
        <Route exact path="/coupa/dnb/supplierInformation">
          <DnBSupplier />
        </Route>
        <Route exact path="/dnb/da/supplier/:supplierId/app/:appId">
          <DnbDaSearchApp />
        </Route>
        <Route exact path="/coupa/dnb/da/supplier/:supplierId/app/:appId">
          <DnbDaSearchApp />
        </Route>
        <Route exact path="/coupa/dnb/da/app/:appId/dashboard/:dashboardId">
          <DnbDaDashboardApp />
        </Route>
        <Route exact path="/dnb/v2/da/app/:appId/dashboard/:dashboardId">
          <DnbDaDashboardV2App />
        </Route>
        <Route exact path="/dnb/da/app/:appId/search">
          <DnbDaSearchApp />
        </Route>
        <Route component={ErrorPage} />
      </Switch>
    </>
  )
}

export default CoupaIFrameAppLayout
