/*eslint-disable*/
import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import { CircularProgress, Select, MenuItem, Fade } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import IconButton from '@material-ui/core/IconButton'

// material ui icons
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'
import AddAlert from '@material-ui/icons/AddAlert'
import Close from '@material-ui/icons/Close'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import GroupIcon from '@material-ui/icons/Group'
import ClearIcon from '@material-ui/icons/Clear'

// core components
import Heading from 'components/Heading/Heading.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'

// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/User/createStyle.js'

import { validateProperty, validateObject } from 'utils/validator'
import {
  showLoading,
  hideLoading,
  getUserTenant,
  getUserRoles
} from 'utils/functions'
import { userCreate as userCreateSchema } from 'models/userModel'
import countryList from 'react-select-country-list'
import LocalesList from 'locales-list'
import { createUser } from 'services/apiUsers'
import { getListOfTenants } from 'services/apiTenants'
import { getAvailableRoles } from 'services/apiAuth'

const useStyles = makeStyles(styles)
let _isMounted = false

export default function createView() {
  const classes = useStyles()
  const history = useHistory()
  const [isLoadingSave, setIsLoadingSave] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [createSuccess, setCreateSuccess] = useState(false)
  const [createError, setCreateError] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const countryListOptions = useMemo(() => countryList().getData(), [])
  const localeList = useMemo(() => LocalesList.getAll(), [])
  const [rolesList, setRolesList] = useState([])
  const [, setPageIsLoading] = useState(true)
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    uniqueName: '',
    role: 'Admin',
    company: '',
    language: 'en',
    locale: 'en-US',
    country: 'US',
    password: '',
    enabled: true,
    roles1: [],
    type: ''
  })

  const [userState, setUserState] = useState({
    firstName: '',
    lastName: '',
    uniqueName: '',
    email: '',
    role: '',
    company: '',
    language: '',
    locale: '',
    country: '',
    password: '',
    enabled: '',
    roles1: '',
    type: ''
  })

  const breadcrumbViews = [
    {
      name: 'Users',
      url: `/admin/users/index`,
      icon: GroupIcon
    },
    {
      name: 'New User',
      url: `/admin/users/create`,
      icon: PersonAddIcon
    }
  ]

  function onClear() {
    history.push(`/admin/users/index`)
  }

  function handleChange(event) {
    const newRoles = event.target.value
    setUser(old => ({
      ...old,
      roles1: newRoles
    }))
  }

  function onFieldChange(field, value) {
    const newUser = { ...user }
    if (field === 'email' || field === 'uniqueName') {
      if (value) {
        value = value.toLowerCase()
      }
    }

    newUser[field] = value
    let isValid = validateProperty(userCreateSchema, newUser, field).isValid
    if (isValid) {
      setUserState(old => ({
        ...old,
        [field]: 'success'
      }))
    } else {
      setUserState(old => ({
        ...old,
        [field]: 'error'
      }))
    }

    setUser(old => ({
      ...old,
      [field]: value
    }))
  }

  async function onUserCreate() {
    setIsLoadingSave(true)

    let validation = validateObject(userCreateSchema, user)
    if (validation.isValid) {
      showLoading()
      await onSubmit()
    } else {
      let field
      for (field in user) {
        if (validation.errors[field]) {
          setUserState(old => ({
            ...old,
            [field]: 'error'
          }))
        }
      }
    }
    setIsLoadingSave(false)
  }

  async function onSubmit() {
    const newUser = {
      ...user,
      roles: user.roles1
    }

    let result = await createUser(newUser.company, newUser)
    // Show confirmation message
    if (result.success && _isMounted) {
      setCreateSuccess(true)
      setTimeout(function() {
        if (_isMounted) {
          setCreateSuccess(false)
          history.push('/admin/users/index')
        }
      }, 1500)
    } else {
      // Show error message
      if (result) {
        let message = result.message
        if (!message) {
          message = 'Something went wrong, please try again later.'
        }
        setValidationMessage(message)
        setCreateError(true)
      }
      hideLoading(setPageIsLoading)
    }
  }

  async function getCompanyList(callback) {
    let roleTypes = ['user']
    if (getUserRoles().includes('Super Admin')) {
      let result = await getListOfTenants()
      roleTypes.push('admin')
      if (_isMounted && result.success) {
        setCompanyList(result.data)

        if (result.data.length !== 0) {
          setUser(old => ({
            ...old,
            company: result.data[0].tenantId
          }))
        }
      }
    } else {
      if (_isMounted) {
        setCompanyList([{ tenantId: getUserTenant() }])
        setUser(old => ({
          ...old,
          company: getUserTenant()
        }))
      }
    }

    const rolesResult = await getAvailableRoles(getUserTenant())
    if (rolesResult.success) {
      const sanitizedArray = [
        ...new Set(
          rolesResult.data
            .filter(role => role.enabled && roleTypes.includes(role.type))
            .map(role => role.roleName)
        )
      ]
      setRolesList(sanitizedArray)

      if (sanitizedArray.length !== 0) {
        setUser(old => ({
          ...old,
          roles1: [sanitizedArray[0]]
        }))
      }
    }
    setPageIsLoading(false)
    callback(setPageIsLoading)
  }

  useEffect(() => {
    showLoading(setPageIsLoading)
    _isMounted = true
    getCompanyList(hideLoading)
  }, [])

  return (
    <Fade in={true} timeout={250}>
      <div>
        <Breadcrumbs views={breadcrumbViews} />
        <Heading textAlign="center" title="New User" />
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <SupervisorAccount />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>New</h4>
                <IconButton
                  style={{ float: 'right' }}
                  color="primary"
                  component="span"
                  onClick={() => onClear()}
                >
                  <ClearIcon />
                </IconButton>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            success={userState.firstName === 'success'}
                            error={userState.firstName === 'error'}
                            labelText="First Name*"
                            id="inputFirstName"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                onFieldChange('firstName', event.target.value)
                              },
                              type: 'text',
                              value: user.firstName,
                              endAdornment:
                                userState['firstName'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            success={userState.lastName === 'success'}
                            error={userState.lastName === 'error'}
                            labelText="Last Name*"
                            id="inputLastName"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                onFieldChange('lastName', event.target.value)
                              },
                              type: 'text',
                              value: user.lastName,
                              endAdornment:
                                userState['lastName'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="multiple-select"
                              className={classes.selectLabel}
                            >
                              Role*
                            </InputLabel>
                            <Select
                              labelId="multiple-select"
                              id="multiple-select"
                              multiple
                              value={user.roles1}
                              onChange={e => handleChange(e)}
                              input={<Input />}
                              renderValue={selected => selected.join(', ')}
                              inputProps={{
                                name: `roles`,
                                id: `roles`
                              }}
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Select the role
                              </MenuItem>
                              {rolesList.map(role => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  key={role}
                                  value={role}
                                >
                                  {role}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            success={userState.uniqueName === 'success'}
                            error={userState.uniqueName === 'error'}
                            labelText="Unique Name*"
                            id="inputUniqueName"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                onFieldChange('uniqueName', event.target.value)
                              },
                              type: 'text',
                              value: user.uniqueName,
                              endAdornment:
                                userState['uniqueName'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            success={userState.email === 'success'}
                            error={userState.email === 'error'}
                            labelText="Email*"
                            id="inputEmail"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                onFieldChange('email', event.target.value)
                              },
                              type: 'text',
                              value: user.email,
                              endAdornment:
                                userState['email'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            success={userState.password === 'success'}
                            error={userState.password === 'error'}
                            labelText="Password*"
                            id="inputPassword"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                onFieldChange('password', event.target.value)
                              },
                              type: 'password',
                              value: user.password,
                              endAdornment:
                                userState['password'] === 'error' ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Tenant*
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={user.company}
                              onChange={e =>
                                onFieldChange(`company`, e.target.value)
                              }
                              inputProps={{
                                name: `company`,
                                id: `company`
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Select the company
                              </MenuItem>
                              {companyList.map((company, index) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  style={{
                                    overflowX: 'auto',
                                    textOverflow: 'ellipsis'
                                  }}
                                  value={company.tenantId}
                                  key={index}
                                >
                                  {company.tenantId}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Language*
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={user[`language`]}
                              onChange={e =>
                                onFieldChange(`language`, e.target.value)
                              }
                              inputProps={{
                                name: `language`,
                                id: `language`
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Select the language
                              </MenuItem>
                              {[
                                { name: 'English', code: 'en' },
                                { name: 'Spanish', code: 'es' }
                              ].map((language, index) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  style={{
                                    overflowX: 'auto',
                                    textOverflow: 'ellipsis'
                                  }}
                                  value={language.code}
                                  key={index}
                                >
                                  {language.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Locale*
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={user[`locale`]}
                              onChange={e =>
                                onFieldChange(`locale`, e.target.value)
                              }
                              inputProps={{
                                name: `locale`,
                                id: `locale`
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Select the locale
                              </MenuItem>
                              {localeList.map((locale, index) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  style={{
                                    overflowX: 'auto',
                                    textOverflow: 'ellipsis'
                                  }}
                                  value={locale.locale.iso_639_with_country}
                                  key={index}
                                >
                                  {locale.locale.iso_639_with_country}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Country*
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={user[`country`]}
                              onChange={e =>
                                onFieldChange(`country`, e.target.value)
                              }
                              inputProps={{
                                name: `country`,
                                id: `country`
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Select the country
                              </MenuItem>
                              {countryListOptions.map((country, index) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  style={{
                                    overflowX: 'auto',
                                    textOverflow: 'ellipsis'
                                  }}
                                  value={country.value}
                                  key={index}
                                >
                                  {country.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              User Type*
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={user[`type`]}
                              onChange={e =>
                                onFieldChange(`type`, e.target.value)
                              }
                              inputProps={{
                                name: `type`,
                                id: `type`
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Select the type
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                style={{
                                  overflowX: 'auto',
                                  textOverflow: 'ellipsis'
                                }}
                                value={'thirdParty'}
                              >
                                Third Party
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                style={{
                                  overflowX: 'auto',
                                  textOverflow: 'ellipsis'
                                }}
                                value={'internal'}
                              >
                                Internal
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <FormControlLabel
                            className={classes.activeToggle}
                            control={
                              <Switch
                                checked={user.enabled}
                                onChange={event =>
                                  onFieldChange('enabled', event.target.checked)
                                }
                                value="enabled"
                                classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="Enabled"
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <div className={classes.formCategory}>
                    <small>*</small> Mandatory fields
                  </div>
                  <div className={classes.center}>
                    {isLoadingSave ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <Button color="primary" onClick={() => onUserCreate()}>
                        Save
                      </Button>
                    )}
                  </div>
                </form>
              </CardBody>
            </Card>
            <Snackbar
              place="br"
              color="success"
              icon={AddAlert}
              message="User created successfully"
              open={createSuccess}
              closeNotification={() => setCreateSuccess(false)}
              close
            />
            <Snackbar
              place="bl"
              color="danger"
              icon={AddAlert}
              message={validationMessage}
              open={createError}
              closeNotification={() => setCreateError(false)}
              close
            />
          </GridItem>
        </GridContainer>
      </div>
    </Fade>
  )
}
