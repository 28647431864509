
const dashboardStyle = {
  sapBtn: {
    float: "right",
    textTransform: "none"
  },
  verticalCenter: {
    marginTop: "20%"
  }
};

export default dashboardStyle;
