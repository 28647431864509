import React from 'react'
import MaterialTable from 'material-table'
import Datetime from 'react-datetime'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Fab from '@material-ui/core/Fab'
import Fade from '@material-ui/core/Fade'
import InputLabel from '@material-ui/core/InputLabel'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import SearchIcon from '@material-ui/icons/Search'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'

// Constans
// import { validationTypes } from '../../utils/Constants'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

const useStyles = makeStyles(styles)

export default function DataAssureCount(props) {
  const classes = useStyles()

  let breadcrumbViews = [
    {
      name: 'Logs',
      url: `/admin/dataAssure/transactions/count`,
      icon: AccountBalanceWalletIcon
    }
  ]

  const columns = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1
    },
    {
      field: 'validationType',
      title: 'Validation Type',
      render: rowData => rowData.label
    },
    {
      field: 'count',
      title: 'Count'
    }
  ]

  function returnCountTab() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12}>
            <h5 className={classes.sectionTitle}>
              <Fab
                size="small"
                color="primary"
                className={classes.floatRight + ' ' + classes.bgColorPrimary}
                onClick={() => props.onSearchValues()}
              >
                <SearchIcon fontSize="small" />
              </Fab>
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12}>
            {props.rows ? (
              <GridContainer justify="center" style={{ marginTop: '5px' }}>
                <GridItem xs={12} sm={6} md={4}>
                  <InputLabel className={classes.label}>Start Date</InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      locale="en-US"
                      closeOnSelect={true}
                      dateFormat="MM/DD/YYYY"
                      value={props.dates.startDate}
                      timeFormat={false}
                      onChange={event => props.onFieldChange('startDate', event)}
                      inputProps={{
                        placeholder: 'Start Date',
                        value: props.dates.startDate
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <InputLabel className={classes.label}>End Date</InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      locale="en-US"
                      closeOnSelect={true}
                      dateFormat="MM/DD/YYYY"
                      value={props.dates.endDate}
                      timeFormat={false}
                      onChange={event => props.onFieldChange('endDate', event)}
                      inputProps={{
                        placeholder: 'End Date',
                        value: props.dates.endDate
                      }}
                    />
                  </FormControl>
                </GridItem>
                {props.countIsLoading ? (
                  <div className={classes.reloadSize}>
                    <CircularProgress />
                  </div>
                ) : (
                  <GridItem xs={12} sm={12} md={12}>
                    <MaterialTable
                      columns={columns}
                      data={props.rows}
                      actions={[
                        rowData => ({
                          icon: () => <MoreHorizIcon style={{ color: '#081c3e' }} />,
                          tooltip: 'Details',
                          onClick: (event, rowData) => {
                            props.onDetailsCount(rowData.validation)
                          }
                        })
                      ]}
                      options={{
                        actionsColumnIndex: -1,
                        sorting: true,
                        showTitle: false
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
            ) : null}
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          {props.formIsLoading ? (
            <Card>
              <GridContainer justify="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </GridContainer>
            </Card>
          ) : (
            <Card>
              <CardHeader>
                <h3 className={classes.formHeader}>Data Assure - Count</h3>
              </CardHeader>
              <CardBody>{returnCountTab()}</CardBody>
            </Card>
          )}
        </GridItem>
        <Snackbar
          place="br"
          color="success"
          icon={AddAlert}
          message={props.validationMessage}
          open={props.submitSuccess}
          closeNotification={() => props.setSubmitSuccess(false)}
          close
        />
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={props.validationMessage}
          open={props.createError}
          closeNotification={() => props.setCreateError(false)}
          close
        />
      </GridContainer>
    </Fade>
  )
}
