import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import SelectField from './SelectField'

const SelectInvoiceType = props => {
  return (
    <SelectField {...props}>
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      <MenuItem value={'creditMemo'} key={'creditMemo'}>
        CREDIT MEMO
      </MenuItem>
      <MenuItem value={'invoice'} key={'invoice'}>
        INVOICE
      </MenuItem>
      <MenuItem value={'paymentRequest'} key={'paymentRequest'}>
        PAYMENT REQUEST
      </MenuItem>
    </SelectField>
  )
}
export default SelectInvoiceType
