import React, { useCallback, useEffect, useState } from 'react'

import { Badge, Grid, IconButton, TextField, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'
import Autocomplete from '@material-ui/lab/Autocomplete'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import { checkErrorOrWarning } from 'utils/functions'

// Form Validation
import { Controller, useWatch } from 'react-hook-form'

import { CircularProgress } from '@material-ui/core'
import { useDebounce } from 'hooks/useDebounce'
import { useGetSuppliers } from 'hooks/useMasterData'
import { HeaderFieldsSide } from 'utils/Constants'
import { generateJsonValidation } from 'utils/validator'

const useStyles = makeStyles(styles)

const SupplierSearch = props => {
  const {
    control,
    summaryField,
    index,
    rules,
    getValues,
    setValueForm,
    label,
    validationsError,
    setValidationErrorsState,
    validationErrorsState,
    appId
  } = props

  let validationErrorMessages = validationErrorsState[summaryField.type]?.messages ?? ''
  let validated = validationErrorsState[summaryField.type]?.valid ?? null
  const [isValid, setIsValid] = useState(false)
  const [initialErrorMessages] = useState(validationsError)
  const [updatedValue, setUpdatedValue] = useState(summaryField.value?.supplierId)
  const [initialValue] = useState(summaryField.value?.supplierId)

  const classes = useStyles()
  const [typeMsg, setTypeMsg] = useState(checkErrorOrWarning(validationErrorMessages, validated))
  const [typeMsgFirst, setTypeMsgFirst] = useState(checkErrorOrWarning(validationsError))
  const [open, setOpen] = useState(false)
  const [filter, setFilter] = React.useState(undefined)
  const [debouncedValue] = useDebounce(filter, 500)

  const supplierIds = useGetSuppliers(debouncedValue, { appId })

  const rulesVendorId = generateJsonValidation(rules, summaryField.type)

  const value = useWatch({
    name: 'summaryFields',
    control
  })

  const nonPOIndex = value.findIndex(field => field.type === HeaderFieldsSide.NON_PO.type)
  const nonPO = nonPOIndex >= 0 ? value[nonPOIndex] : {}
  const nonPoValue = nonPO?.value ?? false

  const changeValidationToSuccess = useCallback(
    (nonPoValue = false) => {
      setValidationErrorsState(old => {
        const previousValidation = old[summaryField.type] ?? {}

        const newUpdate = {
          ...old,
          [summaryField.type]: {
            ...previousValidation,
            messages: [{ type: 'success', message: 'Accepted by User' }],
            valid: true
          }
        }
        if (nonPoValue) {
          if (previousValidation.ruleId === 'SNMPO') {
            setTypeMsg('')
            setIsValid(true)
            return newUpdate
          }
          return old
        }
        setTypeMsg('')
        setIsValid(true)
        return newUpdate
      })
    },
    [setValidationErrorsState, summaryField.type]
  )

  const changeValidationToWarning = useCallback(
    initialErrorMessages => {
      setTypeMsg('warning')
      setTypeMsgFirst('warning')
      setIsValid(false)
      setValidationErrorsState(old => {
        const previousValidation = old[summaryField.type] ?? {}
        return {
          ...old,
          [summaryField.type]: {
            ...previousValidation,
            messages: initialErrorMessages,
            valid: null
          }
        }
      })
    },
    [setValidationErrorsState, summaryField.type]
  )

  const changeValidationToError = useCallback(
    initialErrorMessages => {
      setTypeMsg('error')
      setTypeMsgFirst('error')
      setIsValid(false)
      setValidationErrorsState(old => {
        const previousValidation = old[summaryField.type] ?? {}
        return {
          ...old,
          [summaryField.type]: {
            ...previousValidation,
            messages: initialErrorMessages,
            valid: null
          }
        }
      })
    },
    [setValidationErrorsState, summaryField.type]
  )

  useEffect(() => {
    if (nonPoValue) {
      changeValidationToSuccess(nonPoValue)
      return
    }

    if (typeMsgFirst === 'error') {
      changeValidationToError(initialErrorMessages)
    } else if (typeMsgFirst === 'warning') {
      changeValidationToWarning(initialErrorMessages)
    }
    // eslint-disable-next-line
  }, [
    nonPoValue,
    changeValidationToSuccess,
    changeValidationToError,
    changeValidationToWarning,
    initialErrorMessages
  ])

  useEffect(() => {
    if (initialValue !== updatedValue) {
      if (typeMsgFirst !== '') {
        changeValidationToSuccess()
      }
      return
    }

    if (isValid || validated) {
      changeValidationToSuccess()
      return
    }

    if (typeMsgFirst === 'error') {
      changeValidationToError(initialErrorMessages)
    } else if (typeMsgFirst === 'warning') {
      changeValidationToWarning(initialErrorMessages)
    }
  }, [
    updatedValue,
    changeValidationToError,
    changeValidationToWarning,
    changeValidationToSuccess,
    initialValue,
    isValid,
    typeMsgFirst,
    initialErrorMessages,
    validated
  ])

  const supplierInfo =
    supplierIds.data?.length === 1
      ? supplierIds.data?.map(x => {
          if (x.status === 'disabled') {
            x.bold = true
          }
          return x
        })
      : supplierIds.data?.filter(x => x.status === 'enabled')

  useEffect(() => {
    if (!open) {
      return
    }
    const summaryFields = getValues('summaryFields')
    const indexVendorId = summaryFields.findIndex(
      summaryField => summaryField.type === HeaderFieldsSide.VENDOR_ID.type
    )

    if (indexVendorId < 0) {
      return
    }

    if (!summaryFields[indexVendorId].value) {
      return
    }
    const supplier = summaryFields[indexVendorId].value.supplierId

    setFilter(supplier)
  }, [open, getValues])

  const handleChange = event => {
    setFilter(event.target.value)
  }

  function changeSupplierName(value) {
    if (!value) {
      return
    }
    const summaryFields = getValues('summaryFields')
    const index = summaryFields.findIndex(
      summaryField => summaryField.type === HeaderFieldsSide.VENDOR_NAME.type
    )
    const indexSupRemitAddrField = summaryFields.findIndex(
      summaryField => summaryField.type === HeaderFieldsSide.SUPPLIER_REMIT_TO_ADDRESS.type
    )
    const indexSupShipAddrField = summaryFields.findIndex(
      summaryField => summaryField.type === HeaderFieldsSide.SUPPLIER_SHIP_FROM_ADDRESS.type
    )
    const indexFieldIdRemit = summaryFields.findIndex(
      summaryField => `${HeaderFieldsSide.SUPPLIER_REMIT_TO_ADDRESS.type}_ID` === summaryField.type
    )
    const indexFieldIdShip = summaryFields.findIndex(
      summaryField => `${HeaderFieldsSide.SUPPLIER_SHIP_FROM_ADDRESS.type}_ID` === summaryField.type
    )
    if (index < 0) {
      return
    }
    setValueForm(`summaryFields.${index}.value`, value, {
      shouldValidate: true,
      shouldDirty: true
    })

    setValidationErrorsState(old => {
      const previousValidation = old[HeaderFieldsSide.VENDOR_NAME.type] ?? {}
      return {
        ...old,
        [HeaderFieldsSide.VENDOR_NAME.type]: {
          ...previousValidation,
          messages: [{ type: 'success', message: 'Accepted by User' }],
          valid: true
        }
      }
    })

    if (typeMsgFirst === 'warning' && !validated) {
      setTypeMsg('warning')
    }
    changeValidationToSuccess()

    if (indexSupRemitAddrField >= 0 && indexFieldIdRemit >= 0) {
      setValueForm(`summaryFields.${indexSupRemitAddrField}.value`, '', {
        shouldValidate: true,
        shouldDirty: true
      })
      setValueForm(`summaryFields.${indexFieldIdRemit}.value`, '')
    }

    if (indexSupShipAddrField >= 0 && indexFieldIdShip >= 0) {
      setValueForm(`summaryFields.${indexSupShipAddrField}.value`, '', {
        shouldValidate: true,
        shouldDirty: true
      })
      setValueForm(`summaryFields.${indexFieldIdShip}.value`, '')
    }
  }

  function validationMessages(validationsError, validated = false) {
    if (!validationsError || validated) {
      return ''
    }

    if (Array.isArray(validationsError)) {
      return (
        <>
          {validationsError.map((message, index) => (
            <div key={index}>{message.message}</div>
          ))}
        </>
      )
    }

    const messages = validationsError.split('\n')

    return (
      <>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </>
    )
  }

  function getColors(isDirty, typeMsg, error) {
    let color = ''
    let inputColor = ''
    let badgeColor = ''
    let tooltipColor = ''

    if (typeMsg === 'error') {
      color = classes.errorColor
      inputColor = classes.inputError
      tooltipColor = classes.tooltipError
    }

    if (validated) {
      color = classes.successColor
      inputColor = classes.inputSuccess
    }

    if (isValid && !Boolean(error)) {
      color = classes.successColor
      inputColor = classes.inputSuccess
    }

    if (isDirty && !Boolean(error)) {
      color = classes.successColor
      inputColor = classes.inputSuccess
    }

    if (!isDirty && typeMsg === 'warning') {
      color = classes.warningColor
      inputColor = classes.inputWarning
      tooltipColor = classes.tooltipWarning
    }

    return { color, inputColor, badgeColor, tooltipColor }
  }

  return (
    <Controller
      render={({ field, fieldState: { error, isDirty } }) => {
        const isWarning = !isDirty && typeMsg === 'warning' && !Boolean(error)
        const isVisible =
          (Boolean(error) && typeMsg === 'error') ||
          (!isDirty && typeMsg === 'error') ||
          isWarning ||
          Boolean(error)
        setUpdatedValue(field.value?.supplierId)

        const backEndErrorMessage = validationMessages(validationErrorMessages, validated)
        const errorMessage = backEndErrorMessage ? backEndErrorMessage : error?.message

        return (
          <Grid container justifyContent="center">
            <Grid item xs={typeMsgFirst === 'warning' && !Boolean(error) && !isDirty ? 11 : 12}>
              <Tooltip
                classes={{
                  arrow: isWarning ? classes.warningColor : classes.errorColor,
                  tooltip: isWarning ? classes.tooltipWarning : classes.tooltipError
                }}
                title={isVisible ? errorMessage : ''}
                arrow
                placement="right"
              >
                <Badge
                  style={{ width: '95%' }}
                  classes={{
                    badge: isWarning ? classes.badgeWarning : classes.badgeError
                  }}
                  badgeContent={
                    isWarning ? <WarningIcon className={classes.warningColor}></WarningIcon> : '!'
                  }
                  invisible={isVisible ? false : true}
                >
                  <Autocomplete
                    {...field}
                    style={{ width: '100%' }}
                    disableClearable
                    options={supplierInfo ?? []}
                    classes={{
                      option: classes.option
                    }}
                    open={open}
                    onOpen={() => {
                      setOpen(true)
                    }}
                    onClose={() => {
                      setOpen(false)
                    }}
                    getOptionSelected={(option, value) => option?.supplierId === value?.supplierId}
                    getOptionLabel={option => option?.supplierId ?? ''}
                    renderOption={option =>
                      option?.bold ? (
                        <b>
                          {'(Inactive) '} {option?.name} ({option?.supplierId})
                        </b>
                      ) : (
                        <>
                          {option?.name} ({option?.supplierId})
                        </>
                      )
                    }
                    loading={supplierIds.isLoading || supplierIds.isFetching}
                    renderInput={params => (
                      <TextField
                        {...params}
                        value={filter}
                        onChange={handleChange}
                        id={summaryField.id}
                        label={label}
                        variant="outlined"
                        fullWidth
                        error={Boolean(error)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {supplierIds.isLoading || supplierIds.isFetching ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                          classes: {
                            notchedOutline: !Boolean(error)
                              ? getColors(isDirty, typeMsg, error).inputColor
                              : ''
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: !Boolean(error) ? getColors(isDirty, typeMsg, error).color : '',
                            focused: !Boolean(error) ? getColors(isDirty, typeMsg, error).color : ''
                          }
                        }}
                      />
                    )}
                    onChange={(_, data) => {
                      field.onChange(data)
                      changeSupplierName(data)
                    }}
                  />
                </Badge>
              </Tooltip>
            </Grid>
            {typeMsgFirst === 'warning' && !Boolean(error) && !isDirty ? (
              <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                  style={{ padding: '0' }}
                  className={classes.buttonSuccess}
                  onClick={() =>
                    isValid || validated
                      ? changeValidationToWarning(initialErrorMessages)
                      : changeValidationToSuccess()
                  }
                >
                  {isValid || validated ? (
                    <CheckCircleIcon className={classes.successColor} />
                  ) : (
                    <CheckCircleOutlineIcon className={classes.successColor} />
                  )}
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
        )
      }}
      name={`summaryFields.${index}.value`}
      control={control}
      rules={{
        validate: {
          required: value => {
            if (!value || !value.supplierId) return 'This input is required'

            return value.supplierId ? true : 'This input is required'
          },
          pattern: value => {
            if (!rulesVendorId.pattern?.value) return true
            const exp = new RegExp(rulesVendorId.pattern.value)
            return exp.test(value) || rulesVendorId.pattern.message
          }
        }
      }}
    />
  )
}

export default SupplierSearch
