import { compose, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import { getListOfTenants } from "services/apiTenants";

import IndexView from "./IndexView";
import { showLoading } from "utils/functions";
import { hideLoading } from "utils/functions";

let _isMounted = false;

async function getTenants(props, callback) {
  let result = await getListOfTenants();

  if (_isMounted && result.success) {
    props.setTenantList(result.data);
  }
  callback(props.setPageIsLoading);
}

export default compose(
  connect(
    (state) => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
    }),
    {}
  ),
  withState("tenantList", "setTenantList", []),
  withState("pageIsLoading", "setPageIsLoading", true),
  lifecycle({
    componentDidMount() {
      showLoading(this.props.setPageIsLoading);
      _isMounted = true;
      getTenants(this.props, hideLoading);
    },
  })
)(IndexView);
