import React, { useState, useEffect, useContext } from 'react'
import { InvoiceContext } from 'App.js'
import { useHistory } from 'react-router-dom'
import MaterialTable from 'material-table'
import moment from 'moment'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { ReactComponent as ReceiptIcon } from 'assets/img/invoices/receipt-outline.svg'
import AddIcon from '@material-ui/icons/Add'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'

// components
import GridContainer from '../../../components/Grid/GridContainer.js'
import GridItem from '../../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import SyncLoader from 'components/SyncLoader/SyncLoader'

// Constants
import { invoiceStatus } from 'utils/Constants'

// functions
import { statusColor } from 'utils/functions'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceStyle.js'

import { useLocalStorageState } from 'hooks/useLocalStorage.js'
import { formatDate } from 'utils/functions.js'
import { useGetTenantConfig } from 'hooks/useTenantConfig.js'
import { useGetPayments } from 'hooks/usePayments'

const useStyles = makeStyles(styles)

const dictionaryColums = {
  'No.': false,
  'Relish ID': false,
  'Processing Date': false,
  'Supplier Name': false,
  Status: false,
  'CFDI Status': false,
  'Integration Status': false,
  ERPVendorID: false,
  UUID: false,
  'Invoice Assignment Status': false
}

const filters = ['new', 'processing', 'rejected', 'failed', 'completed']

export function PaymentList () {
  const { initialPagePayment, setInitialPagePayment } = useContext(
    InvoiceContext
  )
  let history = useHistory()
  const classes = useStyles()
  const [createError, setCreateError] = useState(false)
  const [status, setStatus] = useState(filters)
  const [userFilters, setUserFilters] = useLocalStorageState(
    'payment.filters',
    {}
  )
  const [pageSize, setPageSize] = useLocalStorageState('payment.pageSize', 5)
  const [selectText, setSelectText] = useState('Deselect All')
  const [userColumns, setUserColumns] = useLocalStorageState(
    'payment.config',
    dictionaryColums
  )

  const {
    data: configTenant,
    isLoading: isLoadingTenantConfig
  } = useGetTenantConfig()

  const {
    data: payments,
    isLoading,
    error,
    isError,
    refetch,
    isFetching
  } = useGetPayments({ status, configTenant })

  async function onSearchInvoices (newStatus) {
    let statusArray = [...status]
    if (newStatus === 'all') {
      if (statusArray.length === 5) {
        statusArray = []
        setSelectText('Select All')
      } else {
        statusArray = filters
        setSelectText('Deselect All')
      }
    } else {
      let selected = statusArray.findIndex(x => x === newStatus)
      if (selected !== -1) {
        statusArray.splice(selected, 1)
      } else {
        statusArray.push(newStatus)
        statusArray = [...new Set(statusArray)]
      }
      if (statusArray.length === 5) {
        setSelectText('Deselect All')
      } else {
        setSelectText('Select All')
      }
    }
    setStatus(statusArray)
  }

  useEffect(() => {
    if (!isError) {
      return
    }
    setCreateError(true)
  }, [isError])

  let breadcrumbViews = [
    {
      name: 'Payments',
      url: `/admin/invoiceAI/payments`,
      icon: ReceiptIcon
    }
  ]

  const actions = [
    {
      icon: () => <MoreHorizIcon style={{ color: '#081c3e' }} />,
      tooltip: 'Details',
      onClick: (event, rowData) => {
        history.push(`/admin/invoiceAI/payment/${rowData.paymentId}/details`)
      }
    }
  ]
  let paymentData = {}

  let obj = {}
  let data = {}
  if (payments) {
    // eslint-disable-next-line
    for (const payment of payments) {
      if (payment.RelishId) {
        obj = {}
        obj[payment.RelishId] = payment.RelishId
        data['RelishId'] = { ...data['RelishId'], ...obj }
      }

      if (payment.UUID) {
        obj = {}
        obj[payment.UUID] = payment.UUID
        data['UUID'] = { ...data['UUID'], ...obj }
      }

      if (payment.processingDate) {
        obj = {}
        obj[payment.processingDate] = formatDate({
          date: payment.processingDate,
          options: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h23'
          }
        })
        data['processingDate'] = { ...data['processingDate'], ...obj }
      }

      if (payment.supplierId) {
        obj = {}
        obj[payment.supplierId] = payment.supplierId
        data['supplierId'] = { ...data['supplierId'], ...obj }
      }

      if (payment.supplierName) {
        obj = {}
        obj[payment.supplierName] = payment.supplierName
        data['supplierName'] = { ...data['supplierName'], ...obj }
      }

      if (payment.status) {
        obj = {}
        obj[payment.status] = invoiceStatus[payment.status]
        data['status'] = { ...data['status'], ...obj }
      }

      if (payment.cfdiStatus) {
        obj = {}
        obj[payment.cfdiStatus] = invoiceStatus[payment.cfdiStatus]
        data['cfdiStatus'] = { ...data['cfdiStatus'], ...obj }
      }

      if (payment.integrationStatus) {
        obj = {}
        obj[payment.integrationStatus] =
          invoiceStatus[payment.integrationStatus]
        data['integrationStatus'] = { ...data['integrationStatus'], ...obj }
      }

      if (payment.invoiceAssignmentStatus) {
        obj = {}
        obj[payment.invoiceAssignmentStatus] =
          invoiceStatus[payment.invoiceAssignmentStatus]
        data['invoiceAssignmentStatus'] = {
          ...data['invoiceAssignmentStatus'],
          ...obj
        }
      }
    }
    paymentData = data
  }

  const columns = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1,
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['No.'],
      align: 'center',
      sorting: false
    },
    {
      field: 'RelishId',
      render: rowData => rowData.RelishId,
      title: 'Relish ID',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Relish ID'],
      align: 'center',
      lookup: paymentData['RelishId'],
      defaultFilter: userFilters['RelishId']
    },
    {
      field: 'processingDate',
      title: 'Processing Date',
      render: rowData =>
        formatDate({
          date: rowData.processingDate,
          options: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h23'
          }
        }),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Processing Date'],
      align: 'center',
      lookup: paymentData['processingDate'],
      defaultFilter: userFilters['processingDate'],
      customSort: (a, b) => {
        return moment(moment(b.processingDate)).isBefore(
          moment(a.processingDate)
        )
          ? 1
          : -1
      },
      defaultSort: 'desc'
    },
    {
      field: 'UUID',
      title: 'UUID',
      render: rowData => rowData.UUID ?? '',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['UUID'],
      align: 'center',
      lookup: paymentData['UUID'],
      defaultFilter: userFilters['UUID']
    },
    {
      field: 'supplierId',
      render: rowData => rowData.supplierId ?? '',
      title: 'ERPVendorID',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['ERPVendorID'],
      align: 'center',
      lookup: paymentData['supplierId'],
      defaultFilter: userFilters['supplierId']
    },
    {
      field: 'supplierName',
      render: rowData => rowData.supplierName ?? '',
      title: 'Supplier Name',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Supplier Name'],
      align: 'center',
      lookup: paymentData['supplierName'],
      defaultFilter: userFilters['supplierName']
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: statusColor(rowData.status) }}>
          {invoiceStatus[rowData.status]}
        </p>
      ),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Status'],
      align: 'center',
      lookup: paymentData['status'],
      defaultFilter: userFilters['status']
    },
    {
      field: 'integrationStatus',
      title: 'Integration Status',
      render: rowData => invoiceStatus[rowData.integrationStatus],
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Integration Status'],
      align: 'center',
      lookup: paymentData['integrationStatus'],
      defaultFilter: userFilters['integrationStatus']
    },
    {
      field: 'cfdiStatus',
      render: rowData => invoiceStatus[rowData.cfdiStatus],
      title: 'CFDI Status',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['CFDI Status'],
      align: 'center',
      lookup: paymentData['cfdiStatus'],
      defaultFilter: userFilters['cfdiStatus']
    },
    {
      field: 'invoiceAssignmentStatus',
      render: rowData =>
        rowData.invoiceAssignmentStatus
          ? invoiceStatus[rowData.invoiceAssignmentStatus]
          : '',
      title: 'Invoice Assigned',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Invoice Assignment Status'],
      align: 'center',
      lookup: paymentData['invoiceAssignmentStatus'],
      defaultFilter: userFilters['invoiceAssignmentStatus']
    }
  ]

  function hideColumns (column, hidden) {
    const configCopy = { ...userColumns }
    configCopy[column.title] = hidden
    setUserColumns(configCopy)
  }

  function filterColumns (columns) {
    const configCopy = { ...userFilters }
    let column
    for (column of columns) {
      configCopy[column.column.field] = column.value
    }
    setUserFilters(configCopy)
  }

  function returnPaymentsTab () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justifyContent="center">
          <GridItem md={12}>
            <Button
              color="primary"
              size="small"
              className={classes.floatRight}
              onClick={() => refetch({ throwOnError: true })}
            >
              <RefreshRoundedIcon />
            </Button>
          </GridItem>
          <GridItem md={12}>
            <div
              style={{
                width: '100%',
                margin: '0 auto 10px'
              }}
            >
              <div className={classes.statusButton} style={{ width: '100px' }}>
                <div>
                  <Fab
                    size="small"
                    className={
                      status.find(x => x === 'new')
                        ? classes.defaultColorFilled
                        : classes.defaultColor
                    }
                    onClick={() => {
                      onSearchInvoices('new')
                    }}
                  >
                    <AddIcon />
                  </Fab>
                  <span className={classes.statusText}>New</span>
                </div>
              </div>
              <div className={classes.statusButton} style={{ width: '145px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('processing')
                    }}
                    className={
                      status.find(x => x === 'processing')
                        ? classes.primaryColorFilled
                        : classes.primaryColor
                    }
                  >
                    <TimelapseIcon />
                  </Fab>
                  <span className={classes.statusText}>Processing</span>
                </div>
              </div>
              <div className={classes.statusButton} style={{ width: '115px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('failed')
                    }}
                    className={
                      status.find(x => x === 'failed') !== undefined
                        ? classes.warningColorFilled
                        : classes.warningColor
                    }
                  >
                    <PriorityHighIcon />
                  </Fab>
                  <span className={classes.statusText}>Failed</span>
                </div>
              </div>
              <div className={classes.statusButton} style={{ width: '145px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('completed')
                    }}
                    className={
                      status.find(x => x === 'completed')
                        ? classes.successColorFilled
                        : classes.successColor
                    }
                  >
                    <CheckIcon />
                  </Fab>
                  <span className={classes.statusText}>Completed</span>
                </div>
              </div>
              <div className={classes.statusButton} style={{ width: '130px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('rejected')
                    }}
                    className={
                      status.find(x => x === 'rejected')
                        ? classes.dangerColorFilled
                        : classes.dangerColor
                    }
                  >
                    <CloseIcon />
                  </Fab>
                  <span className={classes.statusText}>Rejected</span>
                </div>
              </div>
              <div
                className={classes.statusButton}
                style={{ width: '115px', border: 'none' }}
              >
                <div>
                  <Button
                    size="small"
                    onClick={() => {
                      onSearchInvoices('all')
                    }}
                    style={{ color: '#999' }}
                  >
                    {selectText}
                  </Button>
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <GridContainer justifyContent="center" style={{ marginTop: '5px' }}>
              <SyncLoader showLoader={isFetching && !isLoading} />
              {isLoading || isLoadingTenantConfig ? (
                <div className={classes.reloadSize}>
                  <CircularProgress />
                </div>
              ) : (
                <GridItem xs={12} sm={12} md={12}>
                  <MaterialTable
                    columns={columns}
                    data={payments || []}
                    actions={actions}
                    onChangeColumnHidden={hideColumns}
                    onFilterChange={filterColumns}
                    onChangePage={page => setInitialPagePayment(page)}
                    onChangeRowsPerPage={pageSize => setPageSize(pageSize)}
                    options={{
                      columnsButton: true,
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: true,
                      showTitle: false,
                      emptyRowsWhenPaging: false,
                      pageSize: pageSize,
                      initialPage: initialPagePayment
                    }}
                  />
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          <Card>
            <CardHeader>
              <h3 className={classes.formHeader}>Payment receipts</h3>
            </CardHeader>
            <CardBody>{returnPaymentsTab()}</CardBody>
          </Card>
        </GridItem>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={
            isError
              ? error.response?.data?.message ||
                'Something went wrong, try again later'
              : ''
          }
          open={createError}
          closeNotification={() => setCreateError(false)}
          close
        />
      </GridContainer>
    </Fade>
  )
}
