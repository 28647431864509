import { useMutation, useQuery } from 'react-query'
import {
  createRejectAppConfiguration,
  getConnectivityConfiguration,
  getRejectAppConfiguration,
  updateRejectAppConfiguration
} from 'services/apiInvoices'

export function useGetConnectivityConfiguration(appId) {
  return useQuery(['Apps', appId, 'connectivity'], () => getConnectivityConfiguration(appId).then(result => result.data), {
    enabled: Boolean(appId),
    refetchOnWindowFocus: false,
    staleTime: Infinity
  })
}

export function useGetRejectConfiguration(appId) {
  return useQuery(['Apps', appId, 'reject', 'configuration'], () => getRejectAppConfiguration(appId), {
    refetchOnWindowFocus: false,
    staleTime: Infinity
  })
}

export function useCreateRejectConfiguration() {
  return useMutation(params => createRejectAppConfiguration(params))
}

export function useUpdateRejectConfiguration() {
  return useMutation(params => updateRejectAppConfiguration(params))
}
