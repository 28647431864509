import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'

import TextField from '@material-ui/core/TextField'
//import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

export default function RateLimitModal(props) {
  const { open, handleClose, onSuccess, defaultValues, appKey } = props
  const [limits, setLimits] = React.useState(defaultValues)
  const [errors, setErrors] = React.useState([])
  function addLimit() {
    setLimits(old => {
      const copy = [...old]

      if (copy.length >= 1) {
        const previous = copy[copy.length - 1]
        copy.push({
          id: uuidv4(),
          min: Number(previous.max) + 1,
          max: 0,
          name: ''
        })
      } else {
        copy.push({
          id: uuidv4(),
          min: 0,
          max: 0,
          name: ''
        })
      }

      return copy
    })
  }

  function validateMinMax(min, max) {
    // Ensure both inputs are numbers
    if (isNaN(min) || isNaN(max)) {
      return false
    }

    // Convert inputs to numbers
    min = Number(min)
    max = Number(max)

    // Check if min is less than or equal to max
    return min <= max
  }

  function validateLimits() {
    const errors = []
    // eslint-disable-next-line
    for (const limit of limits) {
      const isValid = validateMinMax(limit.min, limit.max)
      if (!isValid) {
        errors.push(limit.id)
      }
    }
    setErrors(errors)

    return errors.length > 0
  }

  function onChange(params) {
    const { id, event } = params

    const field = event.target.id

    const value = event.target.value

    if (!field) {
      return
    }
    setLimits(old => {
      const copy = [...old]
      const recordIndex = copy.findIndex(x => x.id === id)

      if (recordIndex < 0) {
        return old
      }

      copy[recordIndex][field] = value

      return copy
    })
  }

  function onDelete(id) {
    setLimits(old => old.filter(x => x.id !== id))
  }

  const content = limits.map(x => {
    const errorLimit = errors.find(error => error === x.id)
    const isError = Boolean(errorLimit)
    return (
      <Grid item key={x.id} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              error={isError}
              id="min"
              label="Min"
              type="number"
              value={x.min}
              onChange={event => onChange({ id: x.id, event })}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              error={isError}
              id="max"
              label="Mx"
              type="number"
              value={x.max}
              onChange={event => onChange({ id: x.id, event })}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="name"
              label="Band Name"
              value={x.name}
              onChange={event => onChange({ id: x.id, event })}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <IconButton aria-label="delete" onClick={() => onDelete(x.id)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          {isError ? (
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom color="error">
                The minimum value must be less than or equal to the maximum value.
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    )
  })

  function onClose() {
    //setLimits([])
    handleClose()
  }

  function handleSuccess() {
    const hasErrors = validateLimits()
    if (hasErrors) {
      return
    }

    onSuccess(limits, appKey)
    onClose()
  }

  return (
    <Dialog fullWidth maxWidth={'md'} open={open} onClose={onClose}>
      <DialogTitle>Custom Limits</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={2}>
            <Button variant="outlined" color="primary" size="small" onClick={addLimit}>
              Add
            </Button>
          </Grid>

          {content.length > 0 ? content : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSuccess} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
