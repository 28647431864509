import React, { useState, forwardRef, useEffect, useRef } from 'react'

// Style
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'

import ArrowDownward from '@material-ui/icons/ArrowDownward'

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'

import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { getDnBPendingSuppliers } from 'services/apiApps'
import Check from '@material-ui/icons/Check'
import { Spinner } from 'components/Loader/Spinner'
// import LinkIcon from '@material-ui/icons/Link'

const useStyles = makeStyles({})
const columns = [
  { title: 'SupplierName', field: 'supplierName' },
  { title: 'Supplier Id', field: 'supplierId' }
]
export default function Missing (props) {
  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      getInfoSearch()
      isMounted.current = true
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  })
  const classes = useStyles()
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  async function getInfoSearch () {
    //await
    const pending = await getDnBPendingSuppliers(
      props.validationsForm.environment
    )
    if (pending) setData(pending.data)
    setIsLoading(false)
  }
  const tableIcons = {
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  }

  const actions = [
    {
      icon: () => <Check style={{ color: '#081c3e' }} />,
      tooltip: 'Map',
      onClick: (event, rowData) => {
        props.setSupplierId(rowData.supplierId)
        props.setSupplierName(rowData.supplierName)
        props.setAssignDuns(true)
      }
    }
  ]
  return (
    <div style={{ width: '100%' }}>
      <h3 className={classes.h3}>
        {props.title ? props.title : 'Suppliers Without Duns Number'}
      </h3>
      {isLoading ? <Spinner /> : ''}
      <MaterialTable
        defaultOrder={'desc'}
        icons={tableIcons}
        columns={columns}
        data={data}
        options={{
          actionsColumnIndex: -1,
          sorting: true,
          showTitle: false
        }}
        treeTableWidth={1410}
        actions={actions}
      />
    </div>
  )
}
