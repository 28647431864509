import React from 'react'
import { TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

export const OtherField = props => {
  const { notRecognizedField, setTextInputRef, onChangeField, selectOptions, value, label } = props

  selectOptions.sort((a, b) => {
    let fa = a.value.toLowerCase(),
      fb = b.value.toLowerCase()

    if (fa < fb) {
      return -1
    }
    if (fa > fb) {
      return 1
    }
    return 0
  })

  const newLabel = label?.length > 25 ? `${label.substring(0, 25)}...` : label
  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <TextField
        id={notRecognizedField.id}
        label={newLabel ?? ''}
        variant="outlined"
        size="small"
        fullWidth
        defaultValue={value ?? ''}
        InputProps={{
          readOnly: true
        }}
        onMouseOut={e =>
          onChangeField(e, {
            current: -1,
            previus: notRecognizedField.id,
            page: notRecognizedField.page
          })
        }
        onMouseOver={e =>
          onChangeField(e, {
            current: notRecognizedField.id,
            previus: notRecognizedField.id,
            page: notRecognizedField.page
          })
        }
        inputRef={element => setTextInputRef(element, notRecognizedField.id)}
      />
    </Grid>
  )
}
