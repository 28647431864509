import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import React, { useState, useEffect } from 'react'
import { Select, MenuItem } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'
export default function CustomKISelector (props) {
  console.log('props custom selector', {
    context: props
  })
  const [refresh] = useState(false)
  const [questionnaires, setQuestionnaires] = useState([])
  const [questionnaireChanged, setQuestionnaireChanged] = useState(false)
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(
    props.initialStateValue.address.questionnaire
  )
  const [questions, setQuestions] = useState([])
  const [questionChanged, setQuestionChanged] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState(
    props.initialStateValue.address.questionIndex
  )
  useEffect(() => {
    loadQuestionnaires(props.validationMappings)
    // eslint-disable-next-line
  }, [questionnaireChanged, questionChanged])
  function loadQuestionnaires (mapppingObject) {
    let data = []
    if (mapppingObject) {
      let keys = Object.keys(mapppingObject)
      if (keys) {
        keys.forEach(elementKey => {
          console.log('to get quetionnaires', { context: mapppingObject[elementKey] })
          data.push(mapppingObject[elementKey].uniqueName)
        })
      }
    }
    if (data.length > 0) loadQuestions(props.validationMappings, selectedQuestionnaire)
    setQuestionnaires(data)
  }
  function loadQuestions (validationMapping, questionnaire) {
    let questionnaireObj
    let keys = Object.keys(validationMapping)
    if (keys) {
      keys.forEach(elementKey => {
        if (validationMapping[elementKey].uniqueName === questionnaire) {
          questionnaireObj = validationMapping[elementKey]
          return
        }
      })
      console.log('questionnareOBJ', { context: questionnaireObj })
      if (questionnaireObj && questionnaireObj.questions) {
        setQuestions(questionnaireObj.questions)
      }
    }
  }
  function updateInitialValue (parentField, field, value) {
    if (props.initialStateValue && props.initialStateValue[parentField]) {
      props.initialStateValue[parentField][field] = value
    }
    //props.initialStateValue.address.addressKI += '_TEST'
  }
  function returnCustomKISelector () {
    return (
      <GridContainer justify="center">
        <GridItem xs={12} style={{ height: '10px' }}>
          <FormLabel className={props.classes.label}>Select a Questionnaire</FormLabel>
        </GridItem>
        <GridItem xs={10}>
          <Select
            classes={{
              select: props.classes.select
            }}
            MenuProps={{
              className: props.classes.selectMenu
            }}
            style={{
              width: '100%',
              paddingLeft: 'auto'
            }}
            value={selectedQuestionnaire}
            onChange={e => {
              setQuestionnaireChanged(true)
              setSelectedQuestionnaire(e.target.value)
              updateInitialValue(props.validation, 'questionnaire', e.target.value)
              //load questions
              loadQuestions(props.validationMappings, e.target.value)
              setSelectedQuestion(0)
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: props.classes.selectMenuItem,
                selected: props.classes.selectMenuItemSelected
              }}
              style={{
                overflowX: 'auto',
                textOverflow: 'ellipsis'
              }}
            >
              Select a Questionnaire
            </MenuItem>

            {questionnaires.map(questionnaire => (
              <MenuItem
                classes={{
                  root: props.classes.selectMenuItem,
                  selected: props.classes.selectMenuItemSelected
                }}
                style={{
                  overflowX: 'auto',
                  textOverflow: 'ellipsis'
                }}
                value={questionnaire}
                key={questionnaire}
              >
                {questionnaire}
              </MenuItem>
            ))}
          </Select>
        </GridItem>
        <div>
          <br></br>
        </div>
        {/* Questions */}
        <GridItem xs={12} style={{ height: '10px' }}>
          <FormLabel className={props.classes.label}>Select a question</FormLabel>
        </GridItem>
        <GridItem xs={10}>
          <Select
            MenuProps={{
              className: props.classes.selectMenu
            }}
            classes={{
              select: props.classes.select
            }}
            style={{
              width: '100%',
              paddingLeft: 'auto'
            }}
            value={selectedQuestion}
            onChange={e => {
              setQuestionChanged(true)
              setSelectedQuestion(e.target.value)
              console.log('selected Question', { context: e.target })
              updateInitialValue(props.validation, 'questionIndex', e.target.value)
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: props.classes.selectMenuItem,
                selected: props.classes.selectMenuItemSelected
              }}
              style={{
                overflowX: 'auto',
                textOverflow: 'ellipsis'
              }}
            >
              Select a Question
            </MenuItem>

            {questions.map(question => (
              <MenuItem
                classes={{
                  root: props.classes.selectMenuItem,
                  selected: props.classes.selectMenuItemSelected
                }}
                style={{
                  overflowX: 'auto',
                  textOverflow: 'ellipsis'
                }}
                value={question.index}
                key={question.index}
              >
                {question.id}
              </MenuItem>
            ))}
          </Select>
        </GridItem>
      </GridContainer>
    )
  }
  if (refresh) {
    return returnCustomKISelector()
  } else {
    return <div>{returnCustomKISelector()}</div>
  }
}
