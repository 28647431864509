import React from 'react'
import { Switch, FormControlLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const ColorSwitch = withStyles({
  switchBase: {
    color: '#fafafa',
    '&$checked': {
      color: '#f15f22'
    },
    '&$checked + $track': {
      backgroundColor: '#f15f22'
    }
  },
  checked: {},
  track: {}
})(Switch)

const SwitchField = props => {
  const { summaryField, label, value } = props

  return (
    <FormControlLabel
      labelPlacement="start"
      control={
        <ColorSwitch
          id={summaryField.id}
          checked={value === 'true' ? true : false}
        />
      }
      label={label ?? ''}
    />
  )
}

export default SwitchField
