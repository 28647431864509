import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'
import AssessmentIcon from '@material-ui/icons/Assessment'
import CheckIcon from '@material-ui/icons/Check'
import AddIcon from '@material-ui/icons/PlaylistAdd'
import EditRounded from '@material-ui/icons/EditRounded'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Wizard from 'components/WizardBasic/Wizard.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import ExpiredDiv from 'components/ExpiredDiv/ExpiredDiv'
import NotificationAlert from 'components/NotificationAlert/NotificationAlert'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

// Views
// import ConnectionView from './WizardSteps/ConnectionView.js'
import ConfigurationView from './WizardSteps/ConfigurationView.js'
import ViewsView from './WizardSteps/ViewsViews.js'

// Constants
import { invoiceTitles } from 'utils/Constants'

const useStyles = makeStyles(styles)

export default function InsightsLoad(props) {
  const classes = useStyles()

  function returnWizardToDisplay() {
    const steps = [
      {
        stepName: 'Configuration',
        stepComponent: ConfigurationView,
        stepId: 'config',
        initialState: props.config
      },
      {
        stepName:
          props.typeApp === 'upstream' || props.typeApp === 'downstream'
            ? 'Integration Toolkit Names'
            : 'Views',
        stepComponent: ViewsView,
        stepId: 'views',
        initialState: { customViews: props.customViews, appConfiguration:props.appConfiguration, ...props.views }
      }
    ]
    // if (props.typeApp !== 'downstream') {
    //   steps.push({
    //     stepName: 'Connection',
    //     stepComponent: ConnectionView,
    //     stepId: 'connection',
    //     initialState: props.connection
    //   })
    // }
    return (
      <Wizard
        color="primary"
        validate
        steps={steps}
        title="Load settings"
        subtitle=""
        finishButtonClick={e => props.onAppSaved(e)}
        onClear={() => props.onClear()}
      />
    )
  }

  let breadcrumbViews = [
    {
      name: 'Invoice AI',
      url: '/admin/invoiceAI/configurations',
      icon: AssessmentIcon
    },
    {
      name: invoiceTitles[props.typeApp] + ' Connection',
      url: `/admin/invoiceAI/${props.typeApp}/${props.selectedAppId}`,
      icon: CheckIcon
    },
    {
      name: 'Load',
      url: `/admin/invoiceAI/load/${props.typeApp}/${props.selectedAppId}`,
      icon: props.displayMode === 'edit' ? EditRounded : AddIcon
    }
  ]

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={3}>
        <Card className={classes.appBar}>
          <CardHeader>Application List</CardHeader>
          <CardBody>
            <List>
              {props.appList.map((prop, key) => {
                return (
                  <ListItem
                    className={classes.listItem}
                    button
                    selected={props.selectedAppId === prop.env}
                    key={key}
                    onClick={() => props.onAppChanged(prop.env)}
                  >
                    <ListItemAvatar>
                      {props.selectedAppId === prop.env ? (
                        <Avatar>
                          <TouchApp />
                        </Avatar>
                      ) : (
                        <Avatar className={classes.clearAvatar}></Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText primary={prop.appName} secondary={`Type: ${prop.type}`} />
                  </ListItem>
                )
              })}
            </List>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={9}>
        <Breadcrumbs views={breadcrumbViews} />
        <div style={{ position: 'relative' }}>
          {props.formIsLoading ? (
            <Card>
              <GridContainer justify="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </GridContainer>
            </Card>
          ) : props.selectedAppId ? (
            <div>
              <NotificationAlert
                variant="filled"
                severity="warning"
                appInfo={props.expirationData}
              />
              {returnWizardToDisplay()}
            </div>
          ) : (
            <Card>
              <CardBody>
                <div className={classes.typo}>
                  <div className={classes.note}>No apps created</div>
                  <h6>Click on the create button to start...</h6>
                </div>
              </CardBody>
            </Card>
          )}
          <ExpiredDiv appInfo={props.expirationData} />
        </div>
      </GridItem>
      <Snackbar
        place="br"
        color="success"
        icon={AddAlert}
        message={props.validationMessage}
        open={props.submitSuccess}
        closeNotification={() => props.setSubmitSuccess(false)}
        close
      />
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={props.validationMessage}
        open={props.createError}
        closeNotification={() => props.setCreateError(false)}
        close
      />
    </GridContainer>
  )
}
