import React, { useEffect, useRef, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import Switch from '@material-ui/core/Switch'
import { Button, Typography } from '@material-ui/core'
import styles from '../../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'
import { Spinner } from '../../../components/Loader/Spinner'
import { makeStyles } from '@material-ui/core/styles'
import {
  getDnBMapping,
  saveDataBlocksMappings,
  getDnBBlockConfiguration
} from 'services/apiApps.js'
import { cloneObject } from 'utils/functions.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
// import LinkIcon from '@material-ui/icons/Link'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
const useStyles = makeStyles(styles)

const dataBlocks = {
  companyfinancials: {
    label: 'Company Financials',
    id: 'companyfinancials',
    blockIds: {
      L1: { value: 'companyfinancials_L1' },
      L2: { value: 'companyfinancials_L2' },
      L3: { value: 'companyfinancials_L3' }
    }
  },
  companyinfo: {
    label: 'Company Information',
    id: 'companyinfo',
    blockIds: {
      L1: { value: 'companyinfo_L1' },
      L2: { value: 'companyinfo_L2' },
      L3: { value: 'companyinfo_L3' }
    }
  },
  principalscontacts: {
    label: 'Principal Contacts',
    id: 'principalscontacts',
    blockIds: {
      L1: { value: 'principalscontacts_L1' },
      L2: { value: 'principalscontacts_L2' },
      L3: { value: 'principalscontacts_L3' }
    }
  },
  diversityinsight: {
    label: 'Diversity Insights',
    id: 'diversityinsight',
    blockIds: {
      L1: { value: 'diversityinsight_L1' },
      L2: { value: 'diversityinsight_L2' },
      L3: { value: 'diversityinsight_L3' }
    }
  },
  eventfilings: {
    label: 'Event Filings',
    id: 'eventfilings',
    blockIds: {
      L1: { value: 'eventfilings_L1' },
      L2: { value: 'eventfilings_L2' },
      L3: { value: 'eventfilings_L3' }
    }
  },
  financialstrengthinsight: {
    label: 'Financial Strength Insights',
    id: 'financialstrengthinsight',
    blockIds: {
      L1: { value: 'financialstrengthinsight_L1' },
      L2: { value: 'financialstrengthinsight_L2' },
      L3: { value: 'financialstrengthinsight_L3' }
    }
  },
  hierarchyconnections: {
    label: 'Hierarchy & Connections',
    id: 'hierarchyconnections',
    blockIds: {
      L1: { value: 'hierarchyconnections_L1' },
      L2: { value: 'hierarchyconnections_L2' },
      L3: { value: 'hierarchyconnections_L3' }
    }
  },
  ownershipinsight: {
    label: 'Ownership Insights',
    id: 'ownershipinsight',
    blockIds: {
      L1: { value: 'ownershipinsight_L1' },
      L2: { value: 'ownershipinsight_L2' },
      L3: { value: 'ownershipinsight_L3' }
    }
  },
  salesmarketinginsight: {
    label: 'Sales & Marketing Insights',
    id: 'salesmarketinginsight',
    blockIds: {
      L1: { value: 'salesmarketinginsight_L1' },
      L2: { value: 'salesmarketinginsight_L2' },
      L3: { value: 'salesmarketinginsight_L3' }
    }
  },
  paymentinsight: {
    label: 'Payment Insights',
    id: 'paymentinsight',
    blockIds: {
      L1: { value: 'paymentinsight_L1' },
      L2: { value: 'paymentinsight_L2' },
      L3: { value: 'paymentinsight_L3' }
    }
  },
  thirdpartyriskinsight: {
    label: 'Third Party Risk Insights',
    id: 'thirdpartyriskinsight',
    blockIds: {
      L1: { value: 'thirdpartyriskinsight_L1' },
      L2: { value: 'thirdpartyriskinsight_L2' },
      L3: { value: 'thirdpartyriskinsight_L3' }
    }
  },
  businessactivityinsight: {
    label: 'Business Activity Insight',
    id: 'businessactivityinsight',
    blockIds: {
      L1: { value: 'businessactivityinsight_L1' },
      L2: { value: 'businessactivityinsight_L2' },
      L3: { value: 'businessactivityinsight_L3' }
    }
  },
  inquiryinsight: {
    label: 'Inquiry Insight',
    id: 'inquiryinsight',
    blockIds: {
      L1: { value: 'inquiryinsight_L1' },
      L2: { value: 'inquiryinsight_L2' },
      L3: { value: 'inquiryinsight_L3' }
    }
  },
  spendinsight: {
    label: 'Spend Insight',
    id: 'spendinsight',
    blockIds: {
      L1: { value: 'spendinsight_L1' },
      L2: { value: 'spendinsight_L2' },
      L3: { value: 'spendinsight_L3' }
    }
  },
  dtri: {
    label: 'Derived Trade Insight',
    id: 'dtri',
    blockIds: {
      L1: { value: 'dtri_L1' },
      L2: { value: 'dtri_L2' },
      L3: { value: 'dtri_L3' }
    }
  },
  externaldisruptioninsight: {
    label: 'External Disruption Insight',
    id: 'externaldisruptioninsight',
    blockIds: {
      L1: { value: 'externaldisruptioninsight_L1' },
      L2: { value: 'externaldisruptioninsight_L2' },
      L3: { value: 'externaldisruptioninsight_L3' }
    }
  },
  shippinginsight: {
    label: 'Shipping Insight',
    id: 'shippinginsight',
    blockIds: {
      L1: { value: 'shippinginsight_L1' },
      L2: { value: 'shippinginsight_L2' },
      L3: { value: 'shippinginsight_L3' }
    }
  },
  globalfinancials: {
    label: 'Global Financials',
    id: 'globalfinancials',
    blockIds: {
      L1: { value: 'globalfinancials_L1' },
      L2: { value: 'globalfinancials_L2' },
      L3: { value: 'globalfinancials_L3' }
    }
  },
  globalbusinessranking: {
    label: 'Global Business Ranking',
    id: 'globalbusinessranking',
    blockIds: {
      L1: { value: 'globalbusinessranking_L1' },
      L2: { value: 'globalbusinessranking_L2' },
      L3: { value: 'globalbusinessranking_L3' }
    }
  },
  indateinsight: {
    label: 'InDate Insights',
    id: 'indateinsight',
    blockIds: {
      L1: { value: 'indateinsight_L1' },
      L2: { value: 'indateinsight_L2' },
      L3: { value: 'indateinsight_L3' }
    }
  }
}

export default function DnBMapping (props) {
  async function getDnBMappingFunction () {
    setDataBlocksInfo(undefined)
    let mapping = await getDnBMapping(props.selectedAppId)
    setDataBlocksMappings(mapping.data)
    const dataBlocksAux = cloneObject(dataBlocks)
    if (mapping.data && Array.isArray(mapping.data)) {
      mapping.data.forEach(mapping => {
        if (mapping.enabled && mapping.level) {
          dataBlocksAux[mapping.dataBlockId].blockIds[
            mapping.level
          ].enabled = true
        }
        dataBlocksAux[mapping.dataBlockId].enabled = true
      })

      setDataBlocksInfo(dataBlocksAux)
    }

    setShowBlockConfigurations(false)
    setIsLoading(false)
  }
  const [isLoading, setIsLoading] = useState(true)
  const [dataBlocksMapping, setDataBlocksMappings] = useState([])
  const [dataBlocksInfo, setDataBlocksInfo] = useState(undefined)
  const [selectedLevel, setSelectedLevel] = useState(undefined)
  const [selectedBlock, setSelectedBlock] = useState(undefined)
  const [blockConfiguration, setBlockConfiguration] = useState(undefined)
  const [showBlockConfigurations, setShowBlockConfigurations] = useState(false)
  const [reset, setReset] = useState(false)
  const isMounted = useRef(false)
  const classes = useStyles()
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      getDnBMappingFunction()
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  })
  async function getConfig (blockId, level) {
    setIsLoading(true)
    const blockConfig = await getDnBBlockConfiguration(
      props.selectedAppId,
      `${blockId}_${level}_v1`
    )

    if (blockConfig.success) {
      setBlockConfiguration(blockConfig.data)
      setShowBlockConfigurations(true)
    } else {
      props.setValidationMessage(
        blockConfig.message
          ? blockConfig.message
          : 'Something went wrong try again later'
      )
      setSelectedBlock(undefined)
      setSelectedLevel(undefined)
      setShowBlockConfigurations(false)
      props.setCreateError(true)
      setTimeout(() => {
        props.setValidationMessage('')
        props.setCreateError(false)
      }, 2000)
    }
    setIsLoading(false)
  }
  function getSelectedDataBlock () {
    let dataBlockMapFields = <div></div>
    if (blockConfiguration) {
      let dataBlock = undefined
      dataBlocksMapping.forEach(data => {
        if (data.dataBlockId === selectedBlock && data.level === selectedLevel)
          dataBlock = data
      })
      if (!dataBlock && selectedLevel && selectedBlock) {
        dataBlock = {
          dataBlock: selectedBlock + '_' + selectedLevel + '_v1',
          dataBlockId: selectedBlock,
          enabled: false,
          label: selectedBlock + ' ' + selectedLevel + ' v1',
          level: selectedLevel,
          mapping: [],
          version: 'v1'
        }
        dataBlocksMapping.push(dataBlock)
      }
      if (dataBlock && dataBlock.mapping) {
        if (dataBlock.mapping)
          dataBlockMapFields = (
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <div style={{ float: 'right', width: '100%' }}>
                      <Button
                        onClick={() => {
                          setSelectedBlock(undefined)
                          setSelectedLevel(undefined)
                          setShowBlockConfigurations(false)
                        }}
                      >
                        <ArrowBackIcon />
                      </Button>
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <div style={{ float: 'right', width: '100%' }}>
                      <Button
                        onClick={() => {
                          dataBlock.mapping.push({
                            ki: '',
                            value: null
                          })
                          setReset(!reset)
                        }}
                        className={classes.button}
                        style={{ float: 'right' }}
                      >
                        +
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <div>
                  <Grid container>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography>Enabled</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Switch
                            onClick={event => {
                              dataBlock.enabled = event.target.checked
                              setReset(!reset)
                            }}
                            checked={dataBlock.enabled === true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {dataBlock.mapping.map((b, key) => {
                return (
                  <Grid container key={key}>
                    <Grid item xs={6}>
                      <div style={{ paddingTop: '15px', width: '90%' }}>
                        <Select
                          value={b.value}
                          options={blockConfiguration.result}
                          onChange={value => {
                            dataBlock.mapping[key].value = value
                            dataBlock.mapping[key].APILocation = value.mapping
                            setReset(!reset)
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput
                        labelText={
                          <div className={classes.inputLabel}>
                            <span>{'KI'}</span>
                          </div>
                        }
                        inputProps={{
                          value: b.ki,
                          onChange: event => {
                            dataBlock.mapping[key].ki = event.target.value

                            setReset(!reset)
                          }
                        }}
                        inputInternalProps={{
                          style: {
                            width: '100%',
                            textAlign: 'center'
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                )
              })}
              <Grid container>
                <div style={{ float: 'right', width: '100%' }}>
                  <Button
                    onClick={() => {
                      let newDataBlocksMapping = cloneObject(dataBlocksMapping)

                      saveData(newDataBlocksMapping, () => {
                        setSelectedBlock(undefined)
                        setSelectedLevel(undefined)

                        getDnBMappingFunction()
                      })
                    }}
                    className={classes.button}
                    style={{ float: 'right' }}
                  >
                    SAVE
                  </Button>
                </div>
              </Grid>
            </Grid>
          )
      }
    }
    return dataBlockMapFields
  }
  function getDataBlockKIs () {
    return (
      <div>
        {isLoading ? <Spinner /> : ''}
        {getSelectedDataBlock()}
      </div>
    )
  }
  async function saveData (newDataBlocksMapping, callback) {
    setDataBlocksMappings(newDataBlocksMapping)
    setIsLoading(true)
    const resp = await saveDataBlocksMappings(
      props.selectedAppId,
      newDataBlocksMapping
    )
    if (resp?.data?.success) {
      setIsLoading(false)
      props.setSubmitSuccess(true)
      setTimeout(() => {
        props.setValidationMessage('')
        props.setSubmitSuccess(false)
      }, 2000)
      props.setValidationMessage(
        resp.data.message ? resp.data.message : 'Success'
      )
    } else {
      setIsLoading(false)

      props.setValidationMessage(
        resp.message ? resp.message : 'Something went wrong try again later'
      )
      props.setCreateError(true)
      setTimeout(() => {
        props.setValidationMessage('')
        props.setCreateError(false)
      }, 2000)
    }
    if (callback) {
      callback()
    }
  }
  function getLevels (block) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          {block.label}
        </Grid>
        <Grid item xs={4} style={{ margin: 'auto' }}>
          <div style={{ width: '50%' }}>
            <Switch
              onClick={() => {
                let newDataBlocksInfo = cloneObject(dataBlocksInfo)
                let newDataBlocksMapping = cloneObject(dataBlocksMapping)

                newDataBlocksMapping.forEach(map => {
                  if (block.id === map.dataBlockId) {
                    if (block.enabled) {
                      map.enabled = false
                      newDataBlocksInfo[block.id].enabled = false
                      newDataBlocksInfo[block.id].blockIds['L1'].enabled = false
                      newDataBlocksInfo[block.id].blockIds['L2'].enabled = false
                      newDataBlocksInfo[block.id].blockIds['L3'].enabled = false
                    } else newDataBlocksInfo[block.id].enabled = true
                  }
                })
                setDataBlocksInfo(newDataBlocksInfo)
                saveData(newDataBlocksMapping)
              }}
              checked={block.enabled === true}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={4}>
              <Button
                className={classes.button}
                style={{
                  border: '2px solid lightgray ',
                  backgroundColor: block.blockIds['L1'].enabled
                    ? 'lightred'
                    : 'white',
                  color: 'lightgray',
                  borderRadius: '50px'
                }}
                onClick={() => {
                  getConfig(block.id, 'L1')
                  setSelectedBlock(block.id)
                  setSelectedLevel('L1')
                }}
              >
                L1
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                className={classes.button}
                style={{
                  border: '2px solid lightgray ',
                  backgroundColor: block.blockIds['L2'].enabled
                    ? 'lightred'
                    : 'white',
                  color: 'lightgray',
                  borderRadius: '50px'
                }}
                onClick={() => {
                  getConfig(block.id, 'L2')
                  setSelectedBlock(block.id)
                  setSelectedLevel('L2')
                }}
              >
                L2
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                className={classes.button}
                style={{
                  border: '2px solid lightgray ',
                  backgroundColor: block.blockIds['L3'].enabled
                    ? 'lightred'
                    : 'white',
                  color: 'lightgray',
                  borderRadius: '50px'
                }}
                onClick={() => {
                  getConfig(block.id, 'L3')
                  setSelectedBlock(block.id)
                  setSelectedLevel('L3')
                }}
              >
                L3
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  function getMainConfiguration () {
    return (
      <div style={{ margin: '10px' }}>
        {isLoading ? <Spinner /> : ''}
        {Object.keys(dataBlocksInfo).map((key, id) => {
          const block = dataBlocksInfo[key]
          return <Grid key={id}>{getLevels(block)}</Grid>
        })}
      </div>
    )
  }
  if (dataBlocksInfo) {
    if (showBlockConfigurations) {
      if (reset) return <div>{getDataBlockKIs()}</div>
      else return <div>{getDataBlockKIs()}</div>
    } else {
      if (reset) return <div>{getMainConfiguration()}</div>
      else return <div>{getMainConfiguration()}</div>
    }
  } else return <div> {isLoading ? <Spinner /> : ''}</div>
}
