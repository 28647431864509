import { ApiAdmin, Web } from 'utils/Constants'
import axios from 'axios'
import store from 'store'
import { signOut } from 'store/AuthState'
import { v4 as uuidv4 } from 'uuid'
import { getUserTenant, getUserInfo, createHeaders } from 'utils/functions'
import { getHasTenantSubdomain } from './apiTenants'

/*
Get the list of users
*/
async function getListOfTickets() {
  try {
    const tenantId = getUserTenant()
    const userEmail = getUserInfo().email

    const url = `${ApiAdmin.EndPoint}/tickets/tenants/${tenantId}/users/${userEmail}`
    const requestHeaders = createHeaders()
    const response = await axios({
      method: 'GET',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getListOfTickets returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/*
Create a new support ticket with the specified attributes
*/
async function createSupportTicket(ticketBody) {
  ticketBody.ticketId = uuidv4()
  let url = `${ApiAdmin.EndPoint}/tenants/${ticketBody.tenantId}/createSupportTicket`
  let requestHeaders = createHeaders()

  const externalDomain = (await getHasTenantSubdomain(ticketBody.tenantId))
    ? Web.EndPoint.replace('https://', `https://${ticketBody.tenantId}.`)
    : Web.EndPoint

  ticketBody.internalLink = `${Web.EndPoint}admin/support/ticket/${ticketBody.ticketId}/tenant/${ticketBody.tenantId}`
  ticketBody.externalLink = `${externalDomain}admin/support/ticket/${ticketBody.ticketId}/tenant/${ticketBody.tenantId}`
  return axios({
    method: 'POST',
    url: url,
    headers: requestHeaders,
    data: ticketBody
  }).catch(error => {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch(signOut())
      } else if (error.response.data) {
        return error.response
      } else {
        // Throw error if it's not a success response
        throw new Error(`createSupportTicket returned a status code ${error.response.status}`)
      }
    }
  })
}

/**
 * Upload attachments
 * attachmetns: { preSignedUrl, file }
 * @param {*} attachments
 * @returns
 */
async function uploadAttachments(attachments) {
  return new Promise(async resolve => {
    let attach
    for await (attach of attachments) {
      /* Upload Attachment */
      if (attach.preSignedUrl) {
        const data = new FormData()
        data.append('file', attach.file)
        await axios({
          method: 'put',
          url: attach.preSignedUrl,
          headers: { 'Content-Type': attach.contentType },
          data: attach.file
        })
      }
    }
    resolve(true)
  })
}

/*
Get details of a specific Support Ticket
*/
async function getSupportTicketDetails({ tenant, ticketId, userTenant, userEmail }) {
  try {
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/supportTicket/ticketId/${ticketId}?userTenant=${userTenant}&userEmail=${userEmail}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getSupportTicketDetails returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else if ([403, 404].includes(response.status)) {
        return { success: false, code: response.status, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/*
Update and close a support Ticket
*/
async function closeSupportTicket(tenant, ticketId, updateTicketBody) {
  try {
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/updateTicket/ticketId/${ticketId}`
    let requestHeaders = createHeaders()
    const user = getUserInfo()
    updateTicketBody.user = {
      name: user.name,
      id: user.sub,
      email: user.email,
      tenantId: user.metadata.tenantId
    }

    const externalDomain = (await getHasTenantSubdomain(tenant))
      ? Web.EndPoint.replace('https://', `https://${tenant}.`)
      : Web.EndPoint

    updateTicketBody.internalLink = `${Web.EndPoint}admin/support/ticket/${ticketId}/tenant/${tenant}`
    updateTicketBody.externalLink = `${externalDomain}admin/support/ticket/${ticketId}/tenant/${tenant}`
    let response = await axios({
      method: 'PUT',
      url: url,
      headers: requestHeaders,
      data: updateTicketBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`closeSupportTicket returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for createSupportTicket`)

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return { success: false, message: response.data.errors[0].msg }
      }
    } else {
      return { success: false, message: 'Something went wrong, try again later' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

/*
Get comments of a specific Support Ticket
*/
async function getSupportTicketComments(tenant, ticketId) {
  const requestHeaders = createHeaders()
  const userTenant = getUserTenant()
  const userEmail = getUserInfo().email
  const url = `${ApiAdmin.EndPoint}/tenants/${tenant}/supportTicket/ticketId/${ticketId}/comments?userTenant=${userTenant}&userEmail=${userEmail}`

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
    Add comment to ticket
*/
async function addCommentToSupportTicket({ tenant, ticketId, comment }) {
  let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/supportTicket/ticketId/${ticketId}/comments`
  let requestHeaders = createHeaders()
  const user = getUserInfo()
  const body = {
    comment,
    creationUser: {
      name: user.name,
      id: user.sub,
      email: user.email
    }
  }

  const externalDomain = (await getHasTenantSubdomain(tenant))
    ? Web.EndPoint.replace('https://', `https://${tenant}.`)
    : Web.EndPoint

  body.internalLink = `${Web.EndPoint}admin/support/ticket/${ticketId}/tenant/${tenant}`
  body.externalLink = `${externalDomain}admin/support/ticket/${ticketId}/tenant/${tenant}`
  return axios({
    method: 'POST',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export {
  getListOfTickets,
  createSupportTicket,
  uploadAttachments,
  getSupportTicketDetails,
  closeSupportTicket,
  getSupportTicketComments,
  addCommentToSupportTicket
}
