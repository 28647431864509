import React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
// authentication
import MsalAuth from "authentication/MsalAuth";

export default function LoginStart(props) {
    microsoftTeams.initialize()

    const msalAuth = new MsalAuth();
    msalAuth.loginRedirect()
    return (
        <div>
        </div>
    );
}
