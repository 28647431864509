import moment from 'moment'
import React from 'react'
// Router
import { useParams } from 'react-router'

// Functions
import { statusColor } from 'utils/functions'

// Constants
import { transactionStatus, frequency, fieldsByService, InputDefault, aribaParamsConfigurationType } from 'utils/Constants'

import InvoiceGroup from '../../../components/Configuration/InvoiceGroup'

import { useGetAppConfigurationById } from 'hooks/useApp'
import * as yup from 'yup'
export default function InvoiceGroupAriba() {
  const { group, appId } = useParams()
  const app = useGetAppConfigurationById(appId)
  const type = aribaParamsConfigurationType[group]
  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: true
    },
    {
      field: 'transaction',
      title: 'Transaction'
    },
    {
      field: 'destination',
      title: 'Destination'
    },
    {
      field: 'fromDate',
      title: 'From Date',
      render: rowData => moment(rowData.fromDate).format('MM/DD/YYYY')
    },
    {
      field: 'creationDate',
      title: 'Creation Date',
      render: rowData => moment(rowData.creationDate).format('MM/DD/YYYY HH:mm'),
      customSort: (a, b) => {
        return moment(moment(b.creationDate)).isBefore(moment(a.creationDate)) ? 1 : -1
      },
      defaultSort: 'desc'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => <p style={{ color: statusColor(rowData.status) }}>{transactionStatus[rowData.status]}</p>
    },
    {
      field: 'frequency',
      title: 'Frequency',
      render: rowData => frequency[rowData.frequency]
    }
  ]

  const schema = {
    aribaNetworkPurchaseOrdersBuyer: yup.object().shape({
      apiKey: yup.string().required(),
      clientId: yup.string().required(),
      secret: yup.string().required(),
      aribaNetworkId: yup.string().required()
    }),
    aribaNetworkInvoiceHeaderDataExtraction: yup.object().shape({
      apiKey: yup.string().required(),
      clientId: yup.string().required(),
      secret: yup.string().required(),
      aribaNetworkId: yup.string().required()
    })
  }

  const connectionObj = {
    aribaNetworkPurchaseOrdersBuyer: {
      apiKey: '',
      clientId: '',
      secret: '',
      aribaNetworkId: ''
    },
    aribaNetworkInvoiceHeaderDataExtraction: {
      apiKey: '',
      clientId: '',
      secret: '',
      aribaNetworkId: ''
    }
  }

  if (!app.isLoading && !app.isError && app.data?.data.params.ariba && app.data?.data.params.ariba[type]) {
    switch (group) {
      case 'aribaNetworkInvoiceHeaderDataExtraction':
      case 'aribaNetworkPurchaseOrdersBuyer':
        defaultDataAribaNetworkPurchaseOrders()
        break
      default:
        break
    }
  }
  function defaultDataAribaNetworkPurchaseOrders() {
    if (app.data?.data.params.ariba?.[type]) {
      connectionObj[group].apiKey = app.data?.data.params.ariba?.[type]?.apiKey
      connectionObj[group].clientId = app.data?.data.params.ariba[type].clientId
      connectionObj[group].secret = app.data?.data.params.ariba[type].secret
      connectionObj[group].aribaNetworkId = app.data?.data.params.ariba[type].aribaNetworkId
    }
  }

  let fields
  if (app.data?.data?.service) {
    let service = 'ariba'
    switch (group) {
      case 'aribaNetworkPurchaseOrdersBuyer':
        service = 'aribaNetworkPurchaseOrdersBuyer'
        break
      case 'aribaNetworkInvoiceHeaderDataExtraction':
        service = 'aribaNetworkInvoiceHeaderDataExtraction'
        break
      default:
        break
    }
    fields = fieldsByService[service]
  }

  function modifyDataAribaNetworkt(data) {
    let newData = {
      group: group,
      service: app.data?.data.service,
      ariba: {
        [group]: {
          aribaNetworkId: data.aribaNetworkId
        }
      }
    }
    if (data.apiKey !== InputDefault) {
      newData.ariba[group].apiKey = data.apiKey
    }
    if (data.clientId !== InputDefault) {
      newData.ariba[group].clientId = data.clientId
    }
    if (data.secret !== InputDefault) {
      newData.ariba[group].secret = data.secret
    }
    return newData
  }

  function modifyData(data) {
    let newData = {}
    switch (group) {
      case 'aribaNetworkInvoiceHeaderDataExtraction':
      case 'aribaNetworkPurchaseOrdersBuyer':
        newData = modifyDataAribaNetworkt(data)
        break
      default:
        break
    }
    return newData
  }
  return (
    <InvoiceGroup
      columns={columns}
      group={group}
      appId={appId}
      path={{
        main: `/admin/invoiceAI/ariba/${group}/${appId}`,
        addTransaction: `/admin/invoiceAI/ariba/transaction/${group}/${appId}`,
        edit: `/admin/invoiceAI/ariba/transaction/${group}/${appId}`,
        details: `/admin/invoiceAI/ariba/detail/${group}/${appId}`
      }}
      isLoading={app.isLoading}
      schema={schema[group]}
      connectionObj={connectionObj[group]}
      fields={fields}
      modifyData={modifyData}
      connected={app.data?.data.params.ariba && app.data?.data.params.ariba[type]}
    />
  )
}
