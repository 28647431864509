import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import TabPanel from 'components/Invoice/TabPanel'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import SwipeableViews from 'react-swipeable-views'

import {
  AdditionalCharges,
  CustomCharges,
  HeaderFieldsSide,
  InvoiceProcess,
  Taxes
} from 'utils/Constants'
import SwitchField from 'views/OcrInvoiceExceptionROnly/SwitchField'
import { HeaderField } from 'views/OcrInvoiceExceptions/Diff/HeaderField'

import {
  useGetInvoiceOcrTransaction,
  useGetVersionsDiff,
  useGetVersionsOcr
} from 'hooks/useOcrInvoice'
import { useGeInvoicesAiTenantConfig } from 'hooks/useTenantConfig'

import { CircularProgress } from '@material-ui/core'

import DescriptionIcon from '@material-ui/icons/Description'
import InboxIcon from '@material-ui/icons/Inbox'
import { ReactComponent as InvoiceOcrCompare } from 'assets/img/invoices/git-compare-outline.svg'
import { useGetAppList } from 'hooks/useApp'
import { formatDate } from 'utils/functions'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const gridStyle = { minHeight: 550 }

function sortElements(a, b) {
  return a.pos - b.pos
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    border: `1px solid #d1d5db`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper
  },
  rootGridCurrentVersion: {
    borderRight: `2px solid ${theme.palette.divider}`,
    backgroundColor: `#eeeeee`
  },
  rootGridHeaderPreviousVersion: {
    marginBottom: '1rem'
  },
  rootGridCircularProgress: {
    height: '50vh'
  },
  btnColorPrimary: {
    color: '#081c3e',
    border: '1px solid #081c3e',
    '&:hover': {
      backgroundColor: '#081c3e',
      color: 'white',
      border: '1px solid #081c3e'
    }
  }
}))

const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
  autoHide: true,
  alwaysShowTrack: true,
  scrollThumbStyle: {
    background: '#1E408A'
  }
})

const taxAndCharges = {
  ...Taxes,
  ...AdditionalCharges,
  ...CustomCharges
}

function onRenderRow(cellProps, { data }, lineItemsDiff, side) {
  const { name } = cellProps
  const status = lineItemsDiff[data.key]?.[name]?.status

  let background = ''

  switch (status) {
    case 'changed':
      if (side === 'left') {
        background = 'rgba(204, 122, 122, 0.27)'
        break
      }
      background = 'rgba(129, 204, 122, 0.27)'
      break
    case 'new':
      if (side === 'left') {
        break
      }
      background = 'rgba(129, 204, 122, 0.27)'
      break
    case 'deleted':
      if (side === 'right') {
        break
      }
      background = 'rgba(204, 122, 122, 0.27)'
      break
    default:
      break
  }
  cellProps.style.background = background
}

export function getColumns(data, lineItemsDiff, side, appConfig) {
  const columns = []
  const columnPos = {}

  if (data.length > 0) {
    columns.push({
      header: 'key',
      name: 'key',
      defaultFlex: 1,
      minWidth: 100,
      editable: false,
      defaultVisible: false
    })

    let position = 0
    // eslint-disable-next-line
    for (const values of data) {
      if (values.type === 'LINE_NUMBER') {
        columns.push({
          header: values.label,
          name: values.type,
          defaultFlex: 1,
          minWidth: 100,
          defaultVisible: false
        })
        continue
      }

      if (!appConfig.data?.params?.invoices?.showTaxRate && values.type === 'RATE') {
        continue
      }

      if (values.visible) {
        columnPos[values.type] = position
        position++
      }

      let minWidth = 120
      if (values.type === 'ITEM') {
        minWidth = 400
      }

      if (values.type === 'PO_LINE_NUMBER') {
        minWidth = 300
      }
      if (values.type === 'CHARGE_TYPE') {
        columns.push({
          name: values.type,
          header: values.label,
          defaultFlex: 1,
          minWidth: minWidth,
          defaultVisible: values.visible,
          render: ({ value }) => {
            return taxAndCharges[value] ? taxAndCharges[value].label : ''
          },
          onRender: (cellProps, cellParams) =>
            onRenderRow(cellProps, cellParams, lineItemsDiff, side)
        })
        continue
      }
      columns.push({
        name: values.type,
        header: values.label,
        defaultFlex: 1,
        minWidth: minWidth,
        defaultVisible: values.visible,
        onRender: (cellProps, cellParams) => onRenderRow(cellProps, cellParams, lineItemsDiff, side)
      })
    }
  }
  return { columns, columnPos }
}

function sortLines(a, b) {
  return a.version - b.version
}

export default function InvoiceAIOcrDiff(props) {
  const { invoiceFetch } = props
  const theme = useTheme()
  const classes = useStyles()
  const { invoiceId } = useParams()
  const [tab, setTab] = useState(0)
  const [inputSelected, setInputSelected] = useState(null)
  const [gridRefPreviousLineItems, setGridRefPreviousLineItems] = useState(null)
  const [gridRefCurrentLineItems, setGridRefCurrentLineItems] = useState(null)
  const [gridRefPreviousTax, setGridRefPreviousTax] = useState(null)
  const [gridRefCurrentTax, setGridRefCurrentTax] = useState(null)
  const [cellSelectedTax, setCellSelectedTax] = useState(null)
  const [cellSelectedLineItems, setCellSelectedLineItems] = useState(null)
  const [fileVersion, setFileVersion] = useState({ previous: null, current: null })

  const appConfig = useGeInvoicesAiTenantConfig('invoiceAI', invoiceFetch.data?.appId)
  const appList = useGetAppList('invoiceAI')

  const transactionInformation = useGetInvoiceOcrTransaction({
    transactionId: invoiceId
  })

  const transactionVersions = useGetVersionsOcr({
    transactionId: invoiceId
  })

  const transactionDiff = useGetVersionsDiff({
    transactionId: invoiceId,
    latestVersion: fileVersion.current,
    previousVersion: fileVersion.previous
  })

  useEffect(() => {
    if (transactionInformation.data) {
      const versionFile = transactionInformation.data?.activeVersion
      const latestVersion = versionFile ? versionFile : null
      setFileVersion({
        previous: versionFile ? (versionFile > 0 ? versionFile - 1 : versionFile) : null,
        current: latestVersion
      })
    }
  }, [transactionInformation.data])

  useEffect(() => {
    if (tab !== 1) {
      setGridRefPreviousTax(null)
      setGridRefCurrentTax(null)
    }
    if (tab !== 2) {
      setGridRefPreviousLineItems(null)
      setGridRefCurrentLineItems(null)
    }
  }, [tab])

  const columnsLatestVersion = getColumns(
    transactionDiff.data?.latestVersion?.lineItemColumns ?? [],
    transactionDiff.data?.resultDiff?.lineItems ?? {},
    'right',
    appConfig
  )
  const columnsPreviousVersion = getColumns(
    transactionDiff.data?.previousVersion?.lineItemColumns ?? [],
    transactionDiff.data?.resultDiff?.lineItems ?? {},
    'left',
    appConfig
  )

  const columnsChargesLatestVersion = getColumns(
    transactionDiff.data?.latestVersion?.additionalChargesColumns ?? [],
    transactionDiff.data?.resultDiff?.additionalCharges ?? {},
    'right',
    appConfig
  )
  const columnsChargesPreviousVersion = getColumns(
    transactionDiff.data?.previousVersion?.additionalChargesColumns ?? [],
    transactionDiff.data?.resultDiff?.additionalCharges ?? {},
    'left',
    appConfig
  )

  const onCellClickTax = useCallback(
    (event, cellProps) => {
      const { name, data } = cellProps

      if (gridRefPreviousTax && gridRefPreviousTax.current) {
        gridRefPreviousTax.current.scrollToId(data.key, {
          duration: 300,
          force: true
        })
        gridRefPreviousTax.current.scrollToColumn(
          columnsChargesPreviousVersion.columnPos[name] ?? 0,
          {
            force: true,
            duration: 300
          }
        )
      }
      if (gridRefCurrentTax && gridRefCurrentTax.current) {
        gridRefCurrentTax.current.scrollToId(data.key, {
          duration: 300,
          force: true
        })
        gridRefCurrentTax.current.scrollToColumn(columnsChargesLatestVersion.columnPos[name] ?? 0, {
          force: true,
          duration: 300
        })
      }
      setCellSelectedTax({ [`${data.key},${name}`]: true })
    },
    [
      gridRefPreviousTax,
      gridRefCurrentTax,
      columnsChargesLatestVersion.columnPos,
      columnsChargesPreviousVersion.columnPos
    ]
  )

  const onCellClickLineItems = useCallback(
    (event, cellProps) => {
      const { name, data } = cellProps
      if (gridRefPreviousLineItems && gridRefPreviousLineItems.current) {
        gridRefPreviousLineItems.current.scrollToId(data.key, {
          duration: 300,
          force: true
        })
        gridRefPreviousLineItems.current.scrollToColumn(
          columnsPreviousVersion.columnPos[name] ?? 0,
          {
            force: true,
            duration: 300
          }
        )
      }
      if (gridRefCurrentLineItems && gridRefCurrentLineItems.current) {
        gridRefCurrentLineItems.current.scrollToId(data.key, {
          duration: 300,
          force: true
        })
        gridRefCurrentLineItems.current.scrollToColumn(columnsLatestVersion.columnPos[name] ?? 0, {
          force: true,
          duration: 300
        })
      }
      setCellSelectedLineItems({ [`${data.key},${name}`]: true })
    },
    [
      gridRefPreviousLineItems,
      gridRefCurrentLineItems,
      columnsLatestVersion.columnPos,
      columnsPreviousVersion.columnPos
    ]
  )

  const breadcrumbViews = [
    {
      name: 'Invoices',
      url: `/admin/invoiceAI/index`,
      icon: InboxIcon
    },
    {
      name: 'Invoice Details',
      url: `/admin/invoiceAI/${invoiceId}/details`,
      icon: DescriptionIcon
    },
    {
      name: 'Compare Invoice',
      url: `/admin/invoiceAI/${invoiceId}/compare`,
      icon: InvoiceOcrCompare
    }
  ]

  const handleChangeIndex = index => {
    setTab(index)
  }

  const onClickTextField = summaryField => {
    setInputSelected({ id: summaryField.id, type: summaryField.type })
  }

  if (
    transactionInformation.isLoading ||
    transactionVersions.isLoading ||
    transactionDiff.isLoading ||
    appConfig.isLoading
  ) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    )
  }

  if (transactionInformation.isError || transactionVersions.isError || transactionDiff.isError) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Something went wrong, try again later</p>
      </div>
    )
  }

  const versionSelected = transactionVersions.data.find(
    versionFile => Number(versionFile.version) === fileVersion.previous
  )

  const latestVersionInformation = transactionVersions.data.find(
    versionFile => Number(versionFile.version) === fileVersion.current
  )

  const selectVersionOptions = transactionVersions.data
    .filter(versionTransaction => Number(versionTransaction.version) !== fileVersion.current)
    .sort(sortLines)
    .map(versionTransaction => {
      const numberVersion = Number(versionTransaction.version)
      const date = formatDate({
        date: versionTransaction.creationDate,
        options: {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23'
        }
      })
      return (
        <option key={numberVersion} value={numberVersion}>
          {`V${numberVersion + 1} (${date} - ${versionTransaction.userName.substring(0, 12)})${
            versionTransaction?.userName?.length > 12 ? '...' : ''
          }`}
        </option>
      )
    })

  const selectVersionOptionsB = transactionVersions.data
    .filter(versionTransaction => Number(versionTransaction.version) > fileVersion.previous)
    .sort(sortLines)
    .map(versionTransaction => {
      const numberVersion = Number(versionTransaction.version)
      const date = formatDate({
        date: versionTransaction.creationDate,
        options: {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23'
        }
      })
      return (
        <option key={numberVersion} value={numberVersion}>
          {`V${numberVersion + 1} (${date} - ${versionTransaction.userName.substring(0, 12)}${
            versionTransaction?.userName?.length > 12 ? '...' : ''
          })`}
        </option>
      )
    })

  function leftRightFields(summaryFieldsParam, side) {
    const summaryFields = summaryFieldsParam ?? []
    const invoiceData = []
    const vendorInfo = []
    const invoiceAmount = []
    const addressInfo = []
    const start = []
    const taxInformation = []
    const customInformation = []

    // eslint-disable-next-line
    for (const [index, summaryField] of summaryFields.entries()) {
      if (!summaryField.visible) {
        continue
      }

      let jsxElement
      const pos = HeaderFieldsSide[summaryField.type]?.pos ?? 0
      const label =
        HeaderFieldsSide[summaryField.type]?.label ?? summaryField.type.replace(/_/g, ' ')

      const fieldStatus = transactionDiff.data.resultDiff.header[summaryField.type]
      switch (summaryField.type) {
        case 'CUSTOM_QA_APPROVAL':
        case HeaderFieldsSide.NON_PO.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} key={`${summaryField.id}-${summaryField.value?.text}`}>
                <SwitchField
                  summaryField={summaryField}
                  value={summaryField.value.text}
                  key={summaryField.id}
                  label={label}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.HIGH_PRIORITY_FLAG.type:
          jsxElement = {
            jsx: (
              <Grid
                item
                xs={6}
                key={`${summaryField.id}-${summaryField.value?.text}`}
                style={{ marginBottom: '20px' }}
              >
                <SwitchField
                  summaryField={summaryField}
                  value={summaryField.value.text}
                  key={summaryField.id}
                  label={label}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.SHIP_TO.type:
        case HeaderFieldsSide.BILL_TO.type:
          jsxElement = {
            jsx: (
              <Grid item xs={12} key={`${summaryField.id}-${summaryField.value?.text}`}>
                <HeaderField
                  summaryField={summaryField}
                  onClick={onClickTextField}
                  value={summaryField.value?.text ?? ''}
                  multiline={true}
                  inputSelected={inputSelected}
                  key={summaryField.id}
                  fieldStatus={fieldStatus.status}
                  label={label}
                  side={side}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.PROCESS.type:
          jsxElement = {
            jsx: (
              <Grid item xs={6} key={`${summaryField.id}-${summaryField.value?.text}`}>
                <HeaderField
                  summaryField={summaryField}
                  onClick={onClickTextField}
                  value={InvoiceProcess[(summaryField.value?.text)] ?? ''}
                  multiline={false}
                  inputSelected={inputSelected}
                  key={summaryField.id}
                  fieldStatus={fieldStatus.status}
                  label={label}
                  side={side}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.INVOICE_TYPE.type:
          jsxElement = {
            jsx: (
              <Grid item xs={6} key={summaryField.id}>
                <HeaderField
                  summaryField={summaryField}
                  onClick={onClickTextField}
                  value={summaryField.value?.text ?? ''}
                  multiline={false}
                  inputSelected={inputSelected}
                  key={summaryField.id}
                  fieldStatus={fieldStatus.status}
                  label={label}
                  side={side}
                />
              </Grid>
            ),
            pos
          }
          break
        case HeaderFieldsSide.APP_ID.type: {
          const appData = appList.data?.find(x => x.env === summaryField.value?.text)
          jsxElement = {
            jsx: (
              <Grid item xs={6} sm={6} md={6} lg={6} key={`${summaryField.id}-${appData?.appName}`}>
                <HeaderField
                  summaryField={summaryField}
                  onClick={onClickTextField}
                  value={appData?.appName ?? ''}
                  multiline={false}
                  inputSelected={inputSelected}
                  key={summaryField.id}
                  fieldStatus={fieldStatus.status}
                  label={label}
                  side={side}
                />
              </Grid>
            ),
            pos
          }
          break
        }
        default:
          jsxElement = {
            jsx: (
              <Grid item xs={12} key={`${summaryField.id}-${summaryField.value?.text}`}>
                <HeaderField
                  summaryField={summaryField}
                  onClick={onClickTextField}
                  value={summaryField.value?.text ?? ''}
                  multiline={false}
                  inputSelected={inputSelected}
                  key={summaryField.id}
                  fieldStatus={fieldStatus.status}
                  label={label}
                  side={side}
                />
              </Grid>
            ),
            pos
          }
          break
      }

      if (summaryField.isCustomField) {
        customInformation.push(jsxElement)
        continue
      }

      switch (HeaderFieldsSide[summaryField.type]?.section) {
        case 'invoiceData':
          invoiceData.push(jsxElement)
          break
        case 'vendorInfo':
          vendorInfo.push(jsxElement)
          break
        case 'invoiceAmount':
          invoiceAmount.push(jsxElement)
          break
        case 'addressInfo':
          addressInfo.push(jsxElement)
          break
        case 'start':
          start.push(jsxElement)
          break
        case 'taxInfo':
          taxInformation.push(jsxElement)
          break
        default:
          break
      }
    }

    invoiceData.sort(sortElements)
    vendorInfo.sort(sortElements)
    invoiceAmount.sort(sortElements)
    addressInfo.sort(sortElements)
    start.sort(sortElements)
    taxInformation.sort(sortElements)
    customInformation.sort(sortElements)

    const invoiceDataJsx = invoiceData.map(jsxElement => jsxElement.jsx)
    const vendorInfoJsx = vendorInfo.map(jsxElement => jsxElement.jsx)
    const invoiceAmountJsx = invoiceAmount.map(jsxElement => jsxElement.jsx)
    const addressInfoJsx = addressInfo.map(jsxElement => jsxElement.jsx)
    const startJsx = start.map(jsxElement => jsxElement.jsx)
    const taxInfoJsx = taxInformation.map(jsxElement => jsxElement.jsx)
    const customInfoJsx = customInformation.map(jsxElement => jsxElement.jsx)

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={3}>
            {startJsx.length > 2 ? <Grid item xs={6} sm={6} md={6} lg={6}></Grid> : null}
            {startJsx.length !== 0 ? startJsx : null}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Key Invoice Data</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {invoiceDataJsx.length !== 0 ? invoiceDataJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Invoice Amount Information</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {invoiceAmountJsx.length !== 0 ? invoiceAmountJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Vendor Information</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {vendorInfoJsx.length !== 0 ? vendorInfoJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Address Information</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {addressInfoJsx.length !== 0 ? addressInfoJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {taxInfoJsx.length !== 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h5 className={classes.colorPrimary}>
                      <b>Tax Information</b>
                    </h5>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2} alignItems="center">
                      {taxInfoJsx}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            </Grid>
          </Grid>
        ) : null}
        {customInfoJsx.length !== 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h5 className={classes.colorPrimary}>
                      <b>Client Managed Fields</b>
                    </h5>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2} alignItems="center">
                      {customInfoJsx}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    )
  }

  const handleChange = event => {
    setFileVersion(old => ({ ...old, previous: Number(event.target.value) }))
    setTab(0)
  }

  const handleChangeCurrent = event => {
    setFileVersion(old => ({ ...old, current: Number(event.target.value) }))
    setTab(0)
  }

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }, lines, originalLines, side) => {
    const lineItems = lines ?? {}
    const lineData = lineItems[rowProps.id]
    const columnStatus = lineData?.[cellProps.id]

    if (side === 'left' && columnStatus?.status === 'new') {
      return
    }

    if (side === 'right' && columnStatus?.status === 'deleted') {
      return
    }

    const changedBy = originalLines[rowProps.id][cellProps.id].modifiedBy
    if (!changedBy) {
      return
    }

    menuProps.autoDismiss = true
    menuProps.items = [
      {
        label: `Changed By: ${changedBy.userName}`
      },
      {
        label: `Email: ${changedBy.email}`
      },
      {
        label: `Date: ${formatDate({
          date: changedBy.changeDate,
          options: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h23'
          }
        })}`
      }
    ]
  }

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Breadcrumbs views={breadcrumbViews} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-around">
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.rootGridCurrentVersion}
              >
                <div style={{ padding: '2rem' }}>
                  <Grid
                    item
                    xs={12}
                    justifyContent="flex-start"
                    alignItems="center"
                    container
                    className={classes.rootGridHeaderPreviousVersion}
                  >
                    <Grid item xs={7}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl className={classes.formControl}>
                          <Select
                            native
                            value={fileVersion.previous}
                            onChange={handleChange}
                            inputProps={{
                              name: 'age',
                              id: 'age-native-simple'
                            }}
                          >
                            {selectVersionOptions}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <p style={{ fontSize: '20px', marginRight: '1rem' }}>
                          {`Changed By: ${versionSelected?.userName?.substring(0, 12) ?? ''}${
                            versionSelected?.userName?.length > 12 ? '...' : ''
                          }`}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AppBar position={'static'} color="default">
                      <Tabs
                        value={tab}
                        onChange={(event, newValue) => setTab(newValue)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Header Fields" {...a11yProps(0)} />
                        <Tab label="Tax & Other Charges" {...a11yProps(1)} />
                        <Tab label="Line item fields" {...a11yProps(2)} />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={tab}
                      onChangeIndex={handleChangeIndex}
                    >
                      <TabPanel value={tab} index={0} dir={theme.direction}>
                        {leftRightFields(
                          transactionDiff.data?.previousVersion?.summaryFields,
                          'left'
                        )}
                      </TabPanel>
                      <TabPanel value={tab} index={1} dir={theme.direction}>
                        <ReactDataGrid
                          onReady={setGridRefCurrentTax}
                          idProperty="key"
                          columns={columnsChargesPreviousVersion.columns}
                          dataSource={
                            transactionDiff.data?.previousVersion?.additionalCharges ?? []
                          }
                          style={gridStyle}
                          onCellClick={onCellClickTax}
                          cellSelection={cellSelectedTax}
                          onCellSelectionChange={setCellSelectedTax}
                          cellStyle={{}}
                          enableKeyboardNavigation={false}
                          scrollProps={scrollProps}
                          activeIndexThrottle={300}
                          pagination
                          showZebraRows={false}
                          sortable={false}
                          renderRowContextMenu={(menuProps, menuParams) =>
                            renderRowContextMenu(
                              menuProps,
                              menuParams,
                              transactionDiff.data?.resultDiff?.additionalCharges,
                              transactionDiff.data?.previousVersion?.originalCharges,
                              'left'
                            )
                          }
                        />
                      </TabPanel>
                      <TabPanel value={tab} index={2} dir={theme.direction}>
                        <ReactDataGrid
                          onReady={setGridRefCurrentLineItems}
                          idProperty="key"
                          columns={columnsPreviousVersion.columns}
                          dataSource={transactionDiff.data?.previousVersion?.lineItems ?? []}
                          style={gridStyle}
                          onCellClick={onCellClickLineItems}
                          cellSelection={cellSelectedLineItems}
                          onCellSelectionChange={setCellSelectedLineItems}
                          cellStyle={{}}
                          enableKeyboardNavigation={false}
                          activeIndexThrottle={300}
                          scrollProps={scrollProps}
                          pagination
                          showZebraRows={false}
                          sortable={false}
                          renderRowContextMenu={(menuProps, menuParams) =>
                            renderRowContextMenu(
                              menuProps,
                              menuParams,
                              transactionDiff.data?.resultDiff?.lineItems,
                              transactionDiff.data?.previousVersion?.originalLineItems,
                              'left'
                            )
                          }
                        />
                      </TabPanel>
                    </SwipeableViews>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div style={{ padding: '2rem' }}>
                  <Grid
                    item
                    xs={12}
                    justifyContent="flex-start"
                    alignItems="center"
                    container
                    className={classes.rootGridHeaderPreviousVersion}
                  >
                    <Grid item xs={7}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl className={classes.formControl}>
                          <Select
                            native
                            value={fileVersion.current}
                            onChange={handleChangeCurrent}
                            inputProps={{
                              name: 'age',
                              id: 'age-native-simple'
                            }}
                          >
                            {selectVersionOptionsB}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <p style={{ fontSize: '20px', marginRight: '1rem' }}>
                          {`Changed By: ${latestVersionInformation?.userName?.substring(0, 12) ??
                            ''}${latestVersionInformation?.userName?.length > 12 ? '...' : ''}`}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AppBar position={'static'} color="default">
                      <Tabs
                        value={tab}
                        onChange={(event, newValue) => setTab(newValue)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Header Fields" {...a11yProps(0)} />
                        <Tab label="Tax & Other Charges" {...a11yProps(1)} />
                        <Tab label="Line item fields" {...a11yProps(2)} />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={tab}
                      onChangeIndex={handleChangeIndex}
                    >
                      <TabPanel value={tab} index={0} dir={theme.direction}>
                        {leftRightFields(
                          transactionDiff.data?.latestVersion?.summaryFields,
                          'right'
                        )}
                      </TabPanel>
                      <TabPanel value={tab} index={1} dir={theme.direction}>
                        <ReactDataGrid
                          onReady={setGridRefPreviousTax}
                          idProperty="key"
                          columns={columnsChargesLatestVersion.columns}
                          dataSource={transactionDiff.data?.latestVersion?.additionalCharges ?? []}
                          style={gridStyle}
                          onCellClick={onCellClickTax}
                          cellSelection={cellSelectedTax}
                          onCellSelectionChange={setCellSelectedTax}
                          cellStyle={{}}
                          enableKeyboardNavigation={false}
                          scrollProps={scrollProps}
                          activeIndexThrottle={300}
                          pagination
                          showZebraRows={false}
                          renderRowContextMenu={(menuProps, menuParams) =>
                            renderRowContextMenu(
                              menuProps,
                              menuParams,
                              transactionDiff.data?.resultDiff?.additionalCharges,
                              transactionDiff.data?.latestVersion.originalCharges,
                              'right'
                            )
                          }
                        />
                      </TabPanel>
                      <TabPanel value={tab} index={2} dir={theme.direction}>
                        <ReactDataGrid
                          onReady={setGridRefPreviousLineItems}
                          idProperty="key"
                          columns={columnsLatestVersion.columns}
                          dataSource={transactionDiff.data?.latestVersion?.lineItems ?? []}
                          style={gridStyle}
                          onCellClick={onCellClickLineItems}
                          cellSelection={cellSelectedLineItems}
                          onCellSelectionChange={setCellSelectedLineItems}
                          cellStyle={{}}
                          enableKeyboardNavigation={false}
                          scrollProps={scrollProps}
                          activeIndexThrottle={300}
                          pagination
                          showZebraRows={false}
                          sortable={false}
                          renderRowContextMenu={(menuProps, menuParams) =>
                            renderRowContextMenu(
                              menuProps,
                              menuParams,
                              transactionDiff.data?.resultDiff?.lineItems,
                              transactionDiff.data?.latestVersion.originalLineItems,
                              'right'
                            )
                          }
                        />
                      </TabPanel>
                    </SwipeableViews>
                  </Grid>
                </div>
              </Grid>
            </>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
