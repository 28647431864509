import React from 'react'
// @material-ui/core components
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle'
const useStyles = makeStyles(styles)

export function DeleteConfirmationModal(props) {
  const classes = useStyles()
  const { showModal, onSuccess, onClose, title } = props
  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot
      }}
      open={showModal}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={() => onClose()}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={() => {
            onSuccess()
            onClose()
          }}
          color="primary"
          variant="outlined"
        >
          Delete
        </Button>
        <Button onClick={() => onClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
