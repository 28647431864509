import React from 'react'
//import InvoiceExceptions from 'views/OcrInvoiceExceptions/ExceptionView'
import InvoiceExceptions from 'views/InvoiceException/InvoiceException'
import InvoiceExceptionsReadonly from 'views/InvoiceException/InvoiceExceptionReadOnly'
import ErrorPage from 'views/Pages/ErrorPage'

import { Route, Switch } from 'react-router-dom'

const InvoiceAI = props => {
  return (
    <>
      <Switch>
        <Route exact path="/view/invoiceAI/:invoiceId/exception-details">
          <InvoiceExceptions />
        </Route>
        <Route exact path="/view/invoiceAI/:invoiceId/exception-read-only">
          <InvoiceExceptionsReadonly />
        </Route>
        <Route component={ErrorPage} />
      </Switch>
    </>
  )
}

export default InvoiceAI
