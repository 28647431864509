import React, { useState, useEffect, useRef } from 'react'
import { ReactPictureAnnotation, defaultShapeStyle } from '@blackteam/react-picture-annotation'
import {
  HeaderFieldsSide,
  ColumnsOrderLineItems,
  ColumnsOrderAdditionalCharges
} from 'utils/Constants'
import Grid from '@material-ui/core/Grid'
import Snackbar from 'components/Snackbar/Snackbar'
import AddAlert from '@material-ui/icons/AddAlert'
import Button from 'components/CustomButtons/Button.js'
import DeleteIcon from '@material-ui/icons/Delete'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import RestoreIcon from '@material-ui/icons/Restore'
import DownloadIcon from '@material-ui/icons/GetApp'
import { Tooltip, Fab, Switch, FormControlLabel } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { v4 as uuidv4 } from 'uuid'

export const style = {
  /** stroke style **/
  fontColor: 'green',
  fontBackground: 'green',
  lineWidth: 2, // stroke width
  shapeBackground: 'hsla(90, 100%, 49.4%, 0.2)', // background color in the middle of the marker
  shapeStrokeStyle: 'green', // shape stroke color
  shadowBlur: 10, // stroke shadow blur
  shapeShadowStyle: 'rgba(135, 149, 150, 0.3)', // shape shadow color
  initShapeBackground: 'hsla(207, 44%, 49%, 0.2)',

  /** transformer style **/
  transformerBackground: 'blue',
  transformerSize: 5
}

const useStyles = makeStyles(theme => ({
  divResponsive: {
    overflow: 'auto',
    height: '100%',
    maxHeight: '100%'
  },
  annotationComment: {
    color: 'white',
    backgroundColor: '#081c3e',
    border: '0',
    outline: 'none',
    padding: '7px',
    fontSize: '17px'
  },
  annotationDeleteIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35px',
    color: 'white',
    fontSize: '12px',
    backgroundColor: '#f15f22',
    borderRadius: '0 5px 5px 0',
    cursor: 'pointer'
  },
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
    minWidth: '31.87rem',
    height: '100%'
  },
  headerExceptionDocument: {
    padding: '1.9rem 2rem'
  }
}))

const ColorSwitch = withStyles({
  switchBase: {
    color: '#fafafa',
    '&$checked': {
      color: '#f15f22'
    },
    '&$checked + $track': {
      backgroundColor: '#f15f22'
    }
  },
  checked: {},
  track: {}
})(Switch)

const useStyleGridHeaderContainer = makeStyles(theme => ({
  root: {
    marginBottom: '1rem',
    padding: '1rem'
  }
}))

export const ExceptionDocument = React.forwardRef((props, ref) => {
  const {
    pageNumber,
    changePage,
    geometryPerPage,
    unrecognizedFieldSelected,
    summaryFieldId,
    getTextOcr,
    onChangeSummaryField,
    ocrInformation,
    setLineItemKey,
    setAdditionalChargeKey,
    onChangeTab,
    tab,
    setPageNumber,
    setDataDelete,
    annotationUnrecognizedField,
    actionBoundingBox,
    setActionBoundingBox
  } = props
  const { current, previus, page: unrecognizedFieldPage } = unrecognizedFieldSelected
  const {
    originalFileS3Path,
    otherGemometryFields,
    numPages,
    files,
    summaryFields,
    lineItems,
    additionalCharges
  } = ocrInformation.data
  const { url, sizeImage } = files[pageNumber]

  const unrecognizedFieldId = annotationUnrecognizedField?.unrecognizedField
  const recognizedFieldId = annotationUnrecognizedField?.recognizedField
  const pageRecognizedField = annotationUnrecognizedField?.pageRecognizedField
  const classes = useStyles()
  const classesGridHeaderContainer = useStyleGridHeaderContainer()
  const [pageSize, setPageSize] = useState({
    width: 1170,
    height: 1550
  })

  const [reset, setReset] = useState(1)
  const [zoom, setZoom] = useState({ isActive: false, scrollSpeed: 0 })

  const refPicture = useRef(null)
  const [createError, setCreateError] = useState({ message: '', isOpen: false })

  const [selectedId, setSelectedId] = useState(null)
  const [annotationData, setAnnotationData] = useState({})

  const annotationDataPerPage = annotationData[pageNumber] ?? []

  useEffect(() => {
    if (!actionBoundingBox.actionType) {
      return
    }

    switch (actionBoundingBox.actionType) {
      case 'DELETE':
        if (!actionBoundingBox.rows) {
          break
        }
        const linesToDelete = Object.values(actionBoundingBox.rows).map(x => x.key)

        setAnnotationData(oldData => {
          const boundingBoxes = Object.entries(oldData)
          const newData = {}
          // eslint-disable-next-line
          for (const [key, value] of boundingBoxes) {
            newData[key] = oldData[key].filter(item => !linesToDelete.includes(item.key))
          }
          return newData
        })
        break
      case 'MERGE':
        if (!actionBoundingBox.idRow) {
          break
        }
        setAnnotationData(oldData => {
          const boundingBoxes = Object.values(oldData)
          const linesToDelete = Object.values(actionBoundingBox.rows).map(x => x.key)

          const newData = { ...oldData }

          const linesOrigin = []

          // eslint-disable-next-line
          for (const boundingBox of boundingBoxes) {
            linesOrigin.push(
              ...boundingBox.filter(item =>
                actionBoundingBox.originRow.includes(`${item.key}#${item.column}`)
              )
            )
          }

          const keysToUpdate = linesOrigin.map(x => `${actionBoundingBox.idRow}#${x.column}`)

          let lineToUpdate = []
          // eslint-disable-next-line
          for (const boundingBox of boundingBoxes) {
            lineToUpdate.push(
              ...boundingBox.filter(item => keysToUpdate.includes(`${item.key}#${item.column}`))
            )
          }

          lineToUpdate = keysToUpdate.map(x => {
            const key = x.split('#')

            const exist = lineToUpdate.find(x => x.key === key[0] && x.column === key[1])
            if (exist) {
              return exist
            }

            return {
              mark: {},
              type: 'RECT',
              id: uuidv4(),
              key: actionBoundingBox.idRow,
              column: key[1],
              comment: ColumnsOrderLineItems[key[1]] ? ColumnsOrderLineItems[key[1]].label : key[1],
              section: 'lineItems',
              page: 1
            }
          })

          // eslint-disable-next-line
          for (const boundingBox of linesOrigin) {
            const boxToUpdateIndex = lineToUpdate.findIndex(
              x => x.key === actionBoundingBox.idRow && x.column === boundingBox.column
            )

            if (boxToUpdateIndex < 0) {
              continue
            }

            if (newData[lineToUpdate[boxToUpdateIndex].page]) {
              newData[lineToUpdate[boxToUpdateIndex].page] = [
                ...newData[lineToUpdate[boxToUpdateIndex].page].filter(
                  x => x.id !== lineToUpdate[boxToUpdateIndex].id
                )
              ]
            }

            lineToUpdate[boxToUpdateIndex].mark = { ...boundingBox.mark }
            lineToUpdate[boxToUpdateIndex].page = boundingBox.page
          }

          // eslint-disable-next-line
          for (const boundingBox of lineToUpdate) {
            if (!newData[boundingBox.page]) {
              continue
            }

            newData[boundingBox.page] = [...newData[boundingBox.page], { ...boundingBox }]
          }

          const resultData = {}
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(newData)) {
            resultData[key] = newData[key].filter(item => !linesToDelete.includes(item.key))
          }

          return { ...resultData }
        })
        break
      default:
        break
    }

    setActionBoundingBox({
      rows: null,
      section: null,
      actionType: null,
      idRow: null,
      originRow: null
    })
    setSelectedId('-1')
    // eslint-disable-next-line
  }, [
    actionBoundingBox.actionType,
    actionBoundingBox.rows,
    setActionBoundingBox,
    actionBoundingBox.idRow,
    actionBoundingBox.originRow,
    setSelectedId
  ])

  useEffect(() => {
    setAnnotationData(geometryPerPage)
  }, [geometryPerPage])

  useEffect(() => {
    if (!selectedId || selectedId === '-1') {
      return
    }

    const field = annotationDataPerPage.find(item => item.id === selectedId)
    if (!field) {
      return
    }

    setDataDelete(old => ({
      ...old,
      key: field.key,
      section: field.section,
      column: field.column
    }))
    switch (field.section) {
      case 'header':
        if (ref.current[field.id]) {
          ref.current[field.id].focus()
          return
        }

        const annotationId = ref.current['annotation'][field.id]
        if (ref.current[annotationId]) {
          ref.current[annotationId].focus()
        }
        setLineItemKey(null)
        setAdditionalChargeKey(null)
        if (tab !== 0) {
          onChangeTab(0)
        }
        break
      case 'lineItems':
        setLineItemKey({ key: field.key, column: field.column })
        if (tab !== 2) {
          onChangeTab(2)
        }
        break
      case 'additionalCharges':
        setAdditionalChargeKey({ key: field.key, column: field.column })
        if (tab !== 1) {
          onChangeTab(1)
        }
        break
      default:
        break
    }

    // eslint-disable-next-line
  }, [selectedId, ref, onChangeTab])

  useEffect(() => {
    const el = refPicture.current

    if (el) {
      const rect = el.getBoundingClientRect()
      setPageSize({ width: rect.width, height: rect.height + 20 })
    }
  }, [reset])

  useEffect(() => {
    const el = refPicture.current
    const onWheel = e => {
      e.preventDefault()

      return false
    }

    if (!el) {
      return
    }

    if (zoom.isActive) {
      el.addEventListener('wheel', onWheel)
      return () => el.removeEventListener('wheel', onWheel)
    } else {
      el.removeEventListener('wheel', onWheel)
    }
  }, [zoom.isActive])

  useEffect(() => {
    if (!current) {
      return
    }

    if (current === -1) {
      setAnnotationData(oldData => {
        if (!oldData[unrecognizedFieldPage]) {
          return oldData
        }
        return {
          ...oldData,
          [unrecognizedFieldPage]: oldData[unrecognizedFieldPage].filter(
            item => item.id !== previus
          )
        }
      })

      setSelectedId('-1')
    } else {
      const field = otherGemometryFields.find(item => item.id === current)
      if (!field) {
        return
      }

      setAnnotationData(oldData => {
        const newDataPage = oldData[unrecognizedFieldPage]
          ? [...oldData[unrecognizedFieldPage], field]
          : [field]
        return {
          ...oldData,
          [unrecognizedFieldPage]: newDataPage
        }
      })

      if (refPicture.current) {
        const rect = refPicture.current.getBoundingClientRect()

        const top = rect.height * (field.mark.y / sizeImage.height)
        const left = rect.width * (field.mark.x / sizeImage.width)

        refPicture.current.scroll({
          top,
          left,
          behavior: 'smooth'
        })
      }
      setPageNumber(unrecognizedFieldPage)
      setSelectedId(field.id)
    }
    // eslint-disable-next-line
  }, [previus, current, unrecognizedFieldPage, otherGemometryFields])

  useEffect(() => {
    if (!summaryFieldId?.id || !annotationData) {
      return
    }
    const annotationPage = annotationData[summaryFieldId.page ?? pageNumber] ?? []

    let field = null
    if (summaryFieldId.type === 'header') {
      field = annotationPage.find(item => item.id === summaryFieldId.id)

      if (!field) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(ref.current['annotation'])) {
          if (value === summaryFieldId?.id) {
            field = annotationPage.find(item => item.id === key)
            break
          }
        }
      }
    } else {
      field = annotationPage.find(
        item => item.key === summaryFieldId.id && item.column === summaryFieldId.column
      )
    }

    if (!field) {
      setSelectedId('-1')
      return
    }

    if (refPicture.current) {
      const rect = refPicture.current.getBoundingClientRect()

      const top = rect.height * (field.mark.y / sizeImage.height)
      //const top = field.mark.y
      const left = rect.width * (field.mark.x / sizeImage.width)

      refPicture.current.scroll({
        top,
        left,
        behavior: 'smooth'
      })
    }
    setPageNumber(summaryFieldId.page)
    setSelectedId(field.id)
    // eslint-disable-next-line
  }, [summaryFieldId])

  useEffect(() => {
    if (!unrecognizedFieldId || !recognizedFieldId) {
      return
    }
    const field = otherGemometryFields.find(field => field.id === unrecognizedFieldId)
    if (!field) {
      return
    }

    setAnnotationData(oldData => {
      let tempPreviusAnnotation = summaryFields.find(field => field.id === recognizedFieldId)

      if (!tempPreviusAnnotation || !oldData[pageRecognizedField]) {
        return oldData
      }

      let annotationUpdated = {
        ...oldData,
        [pageRecognizedField]: oldData[pageRecognizedField].filter(
          field => field.id !== recognizedFieldId
        )
      }

      annotationUpdated = {
        ...annotationUpdated,
        [field.page]: [
          ...annotationUpdated[field.page],
          {
            ...field,
            id: recognizedFieldId,
            key: recognizedFieldId,
            comment:
              HeaderFieldsSide[tempPreviusAnnotation.type]?.label ??
              tempPreviusAnnotation.type.replace(/_/g, ' ')
          }
        ]
      }
      return annotationUpdated
    })
  }, [
    unrecognizedFieldId,
    recognizedFieldId,
    pageRecognizedField,
    otherGemometryFields,
    setAnnotationData,
    summaryFields
  ])

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  const onSelect = selectedId => {
    setSelectedId(selectedId)
  }
  const onChange = data => {
    setAnnotationData({ ...annotationData, [pageNumber]: data })
  }
  const onFinish = data => {
    if (!summaryFieldId?.id) {
      setCreateError({
        message: 'Select a field first.',
        isOpen: true
      })
      setAnnotationData(oldData => {
        return {
          ...oldData,
          [pageNumber]: oldData[pageNumber].filter(item => item.id !== data.id)
        }
      })
      return
    }
    const isHeader = summaryFieldId.type === 'header'

    if (isHeader) {
      const field = annotationDataPerPage.find(item => item.id === summaryFieldId.id)
      if (field) {
        setAnnotationData(oldData => {
          return {
            ...oldData,
            [pageNumber]: oldData[pageNumber].filter(item => item.id !== summaryFieldId.id)
          }
        })
      } else {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(ref.current['annotation'])) {
          if (value === summaryFieldId?.id) {
            setAnnotationData(oldData => {
              return {
                ...oldData,
                [pageNumber]: oldData[pageNumber].filter(item => item.id !== key)
              }
            })

            delete ref.current['annotation'][key]
            break
          }
        }
      }
    } else {
      const lineItem = annotationDataPerPage.find(
        item => item.key === summaryFieldId.id && item.column === summaryFieldId.column
      )

      if (lineItem) {
        setAnnotationData(oldData => {
          return {
            ...oldData,
            [pageNumber]: oldData[pageNumber].filter(
              item => item.key !== summaryFieldId.id || item.column !== summaryFieldId.column
            )
          }
        })
      }
    }

    let fieldTarget

    switch (summaryFieldId.type) {
      case 'header':
        fieldTarget = summaryFields.find(summaryField => summaryField.id === summaryFieldId?.id)
        break
      case 'lineItems':
        fieldTarget = lineItems.find(lineItem => lineItem.key === summaryFieldId.id)
        break
      case 'additionalCharges':
        fieldTarget = additionalCharges.find(
          additionalCharge => additionalCharge.key === summaryFieldId.id
        )
        break
      default:
        break
    }

    const boundingBox = {
      Height: Math.abs(data.mark.height / sizeImage.height),
      Width: Math.abs(data.mark.width / sizeImage.width),
      Left:
        data.mark.width < 0
          ? data.mark.x / sizeImage.width + data.mark.width / sizeImage.width
          : data.mark.x / sizeImage.width,
      Top:
        data.mark.height < 0
          ? data.mark.y / sizeImage.height + data.mark.height / sizeImage.height
          : data.mark.y / sizeImage.height
    }

    getTextOcr.mutate(
      {
        regionsOfInterest: [boundingBox],
        numPage: pageNumber
      },
      {
        onError: () => {
          onChangeSummaryField(null)
          setAnnotationData(oldData => {
            return {
              ...oldData,
              [pageNumber]: oldData[pageNumber].filter(item => item.id !== data.id)
            }
          })
          setCreateError({ isOpen: true, message: 'Something went wrong' })
        },
        onSuccess: () => {
          ref.current['annotation'][data.id] = summaryFieldId?.id
          onChangeSummaryField({
            ...summaryFieldId,
            annotationId: data.id,
            ocr: {
              boundingBox,
              type: 'OCR',
              page: pageNumber
            },
            shouldSetValue: true,
            page: pageNumber
          })
          editComment({
            id: data.id,
            description: descriptionComment(summaryFieldId.type, fieldTarget),
            section: summaryFieldId.type,
            column: summaryFieldId.column ?? '',
            key: summaryFieldId.id
          })
        }
      }
    )
  }

  const descriptionComment = (type, fieldTarget) => {
    switch (type) {
      case 'header':
        return HeaderFieldsSide[(fieldTarget?.type)]?.label ?? fieldTarget.type.replace(/_/g, ' ')
      case 'lineItems':
        return ColumnsOrderLineItems[summaryFieldId.column]?.label ?? summaryFieldId.column
      case 'additionalCharges':
        return ColumnsOrderAdditionalCharges[summaryFieldId.column]?.label ?? summaryFieldId.column
      default:
        return
    }
  }

  const handleChange = event => {
    if (event.target.checked) {
      setZoom({ isActive: Boolean(event.target.checked), scrollSpeed: 0.0005 })
    } else {
      setZoom({ isActive: Boolean(event.target.checked), scrollSpeed: 0 })
    }
  }

  const editComment = comment => {
    if (!comment?.id) {
      return
    }

    setAnnotationData(oldData => {
      const index = oldData[pageNumber].findIndex(annotation => annotation.id === comment.id)
      if (index < 0) {
        return oldData
      }

      const dataMark = oldData[pageNumber][index].mark

      const boundingAdjusted = {
        height: Math.abs(dataMark.height),
        width: Math.abs(dataMark.width),
        x: dataMark.width < 0 ? dataMark.x + dataMark.width : dataMark.x,
        y: dataMark.height < 0 ? dataMark.y + dataMark.height : dataMark.y
      }

      oldData[pageNumber][index] = {
        ...oldData[pageNumber][index],
        page: pageNumber,
        comment: comment.description,
        section: comment.section,
        column: comment.column,
        mark: boundingAdjusted,
        key: comment.key
      }
      return oldData
    })
  }

  const handleDelete = callback => {
    setDataDelete(old => ({ ...old, isDelete: true }))
    callback()
  }

  return (
    <div className={classes.root}>
      <Grid
        className={classesGridHeaderContainer.root}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '5px'
            }}
          >
            <FormControlLabel
              labelPlacement="start"
              control={<ColorSwitch onChange={handleChange} checked={zoom.isActive} />}
              label={'Zoom'}
            />
          </div>
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Tooltip title={'First Page'}>
              <Fab
                size="small"
                color="primary"
                style={{
                  margin: '0 5px 0 5px',
                  backgroundColor: '#081c3e'
                }}
                onClick={() => setPageNumber(1)}
              >
                <FirstPageIcon style={{ fontSize: 20 }} />
              </Fab>
            </Tooltip>
            <Tooltip title={'Previous Page'}>
              <Fab
                size="small"
                color="primary"
                style={{
                  margin: '0 5px 0 5px',
                  backgroundColor: '#081c3e'
                }}
                onClick={() => {
                  if (pageNumber <= 1) {
                    return
                  }
                  previousPage()
                }}
              >
                <ChevronLeftIcon style={{ fontSize: 20 }} />
              </Fab>
            </Tooltip>
            <Tooltip title={'Restore Image'}>
              <Fab
                size="small"
                color="primary"
                style={{
                  margin: '0 5px 0 5px',
                  backgroundColor: 'white'
                }}
                onClick={() => setReset(c => c + 1)}
              >
                <RestoreIcon style={{ color: '#081c3e', fontSize: 20 }} />
              </Fab>
            </Tooltip>

            <Tooltip title={'Next Page'}>
              <Fab
                size="small"
                color="primary"
                style={{
                  margin: '0 5px 0 5px',
                  backgroundColor: '#081c3e'
                }}
                onClick={() => {
                  if (pageNumber >= numPages) {
                    return
                  }
                  nextPage()
                }}
              >
                <ChevronRightIcon style={{ fontSize: 20 }} />
              </Fab>
            </Tooltip>
            <Tooltip title={'Last Page'}>
              <Fab
                size="small"
                color="primary"
                style={{
                  margin: '0 5px 0 5px',
                  backgroundColor: '#081c3e'
                }}
                onClick={() => setPageNumber(numPages)}
              >
                <LastPageIcon style={{ fontSize: 20 }} />
              </Fab>
            </Tooltip>
          </div>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '5px',
              marginRight: '15px'
            }}
          >
            <Tooltip title="Download">
              <a
                href={originalFileS3Path}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit' }}
                download
              >
                <Button
                  color="transparent"
                  justIcon
                  aria-owns={null}
                  aria-haspopup="true"
                  size={'lg'}
                  muiClasses={{
                    label: ''
                  }}
                >
                  <DownloadIcon fontSize={'large'} />
                </Button>
              </a>
            </Tooltip>
          </div>
        </Grid>
      </Grid>

      <div ref={refPicture} className={classes.divResponsive} key={reset}>
        <ReactPictureAnnotation
          image={url}
          onSelect={onSelect}
          onChange={onChange}
          width={pageSize.width}
          height={pageSize.height}
          onFinish={onFinish}
          annotationStyle={{ ...defaultShapeStyle, ...style }}
          annotationData={annotationDataPerPage}
          scrollSpeed={zoom.scrollSpeed}
          selectedId={selectedId}
          inputElement={(value, onChange, onDelete) => (
            <div style={{ display: 'flex', flex: '1' }}>
              <div className={classes.annotationComment}>
                <span>{value}</span>
              </div>

              <div className={classes.annotationDeleteIcon}>
                <DeleteIcon onClick={() => handleDelete(onDelete)} fontSize="small" />
              </div>
            </div>
          )}
        />
        <Grid
          className={classesGridHeaderContainer.root}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              height: '40px',
              marginTop: '40px'
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <p style={{ fontSize: '1rem' }}>
                Page <strong>{pageNumber || (numPages ? 1 : '--')}</strong> of{' '}
                <strong>{numPages || '--'}</strong>
              </p>
            </Grid>
          </div>
        </Grid>
      </div>
      <Snackbar
        place="tr"
        color="warning"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '' })}
        close
      />
    </div>
  )
})
