import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Alert from '@material-ui/lab/Alert'

import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'
const useStyles = makeStyles(styles)

export default function ExpiredDiv (props) {
  const classes = useStyles()
  const { appInfo } = props
  let type = 'suscription'
  let showDiv = false

  if (appInfo.suscriptionType === 'freeTrial') {
    type = 'free trial'
  }

  if (appInfo.enabled === false) {
    showDiv = true
  }

  return showDiv ? (
    <div className={classes.overlay}>
      <Alert variant='filled' severity='error'>
        Your {type} has expired, please contact your administrator
        info@relishiq.com
      </Alert>
    </div>
  ) : null
}

ExpiredDiv.propTypes = {
  appInfo: PropTypes.object
}
