import React from 'react'
// @material-ui/core components

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'

import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'

import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'
import { ColumnsOrderLineItemsStandard } from 'utils/Constants'
import AutoCompleteAsyncDefaultValues from './AutoCompleteAsyncDefaultValues'
import CommoditySelectLine from './CommoditySelect'
import TextFieldLineItems from './TextFieldLineItems'
import { getValidationRule } from './utils'

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const FIELD_LEVEL = 'lineItem'

export default function AdditionalDetails(props) {
  const classes = useStyles()

  const {
    isOpen,
    onClose,
    lineItemSelected,
    readOnly = false,
    onEditComplete,
    requiredFields,
    process,
    lines,
    lineItemsActions,
    appId
  } = props

  const customFields = Object.values(requiredFields ?? {}).filter(
    x => x.level === FIELD_LEVEL && x.isCustomField && x.isVisible
  )

  const customFieldsStrings = customFields.filter(
    x => getValidationRule(x, 'dataType')?.value === 'string'
  )

  const customFieldsSelects = customFields.filter(x => {
    const dataType = getValidationRule(x, 'dataType')?.value
    return typeof dataType === 'string' && dataType.startsWith('ocrFieldValues#')
  })

  const isVisibleCommodity = Object.values(requiredFields ?? {}).find(
    x =>
      x.level === FIELD_LEVEL &&
      x.standardField === ColumnsOrderLineItemsStandard['commodity.id'].type &&
      x.isVisible
  )

  const commodityId = lineItemSelected
    ? lineItemSelected[ColumnsOrderLineItemsStandard['commodity.id'].type]
    : ''

  function onChangeInputText(value, columnName) {
    if (!lineItemSelected) {
      return
    }

    onEditComplete({
      value: value ?? '',
      columnId: columnName,
      data: { id: lineItemSelected.id }
    })
  }

  const customFieldsInputs = customFieldsStrings.map(x => {
    return (
      <Grid item xs={6} key={x.standardField}>
        <TextFieldLineItems
          field={x.standardField}
          value={lineItemSelected ? lineItemSelected[x.standardField] : ''}
          label={x.displayName}
          handleChange={value => onChangeInputText(value, x.standardField)}
          readOnly={readOnly}
          lineId={lineItemSelected?.id ?? ''}
          lines={lines}
          lineItemsActions={lineItemsActions}
        />
      </Grid>
    )
  })

  const customFieldsSelectsJsx = customFieldsSelects.map(x => {
    return (
      <Grid item xs={6} key={x.standardField}>
        <AutoCompleteAsyncDefaultValues
          standardField={x.standardField}
          label={x.displayName}
          lines={lines}
          lineItemsActions={lineItemsActions}
          readOnly={readOnly}
          lineItemSelected={lineItemSelected}
          onChange={newValue => {
            onChangeInputText(newValue?.value, x.standardField)
          }}
          process={process}
        />
      </Grid>
    )
  })

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isOpen}
        TransitionComponent={Transition}
        onClose={onClose}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            Additional Details
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container justifyContent="center" spacing={2}>
            {isVisibleCommodity ? (
              <Grid item xs={6}>
                <CommoditySelectLine
                  handleChange={newValue => {
                    onChangeInputText(newValue?.id, isVisibleCommodity.standardField)
                  }}
                  value={commodityId ?? ''}
                  readOnly={readOnly}
                  lineId={lineItemSelected?.key ?? ''}
                  appId={appId}
                  lineItemsActions={lineItemsActions}
                  label={isVisibleCommodity.displayName}
                  field={isVisibleCommodity}
                  lines={lines}
                />
              </Grid>
            ) : null}
            {customFieldsInputs.length > 0 ? customFieldsInputs : null}
            {customFieldsSelectsJsx.length > 0 ? customFieldsSelectsJsx : null}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={onClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
