import Auth0Js from 'auth0-js'
import { Auth0, LoginUser } from 'utils/Constants'

export default class Auth {
  constructor(organizationId) {
    let redirectUrl = `${window.location.origin}/auth/login-page`

    this.auth0 = new Auth0Js.WebAuth({
      domain: Auth0.Domain,
      clientID: Auth0.ClientID,
      redirectUri: redirectUrl,
      audience: Auth0.Audience,
      responseType: 'token id_token',
      scope: 'openid profile email',
      organization: organizationId,
      __tryLocalStorageFirst: true // avoid cookies issues
    })
  }

  login() {
    this.auth0.authorize()
  }

  popupLogin(params, callback) {
    this.auth0.popup.authorize(params, callback)
  }

  popupLoginCallback() {
    this.auth0.popup.callback()
  }

  parseHash() {
    return new Promise((resolve, reject) =>
      this.auth0.parseHash(resolve, (err, parsed) => {
        if (err) {
          return reject(err)
        }
        resolve(parsed)
      })
    )
  }

  userInfo(accessToken) {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(accessToken, (err, user) => {
        if (err) {
          return reject(err)
        }
        resolve(user)
      })
    })
  }

  checkSession() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession((err, result) => {
        if (err) {
          return reject(err)
        }
        resolve(result)
      })
    })
  }

  isAuthenticated() {
    const expires_At = localStorage.getItem('expires_At')
    if (expires_At) {
      let expiresAt = JSON.parse(localStorage.getItem('expires_At'))
      return new Date().getTime() < expiresAt
    } else {
      return false
    }
  }

  async handleAuthentication() {
    const authResults = await this.parseHash()

    if (authResults?.accessToken && authResults?.idToken) {
      await this.setSession(authResults)
    }
  }

  async handlePopupAuthentication(authResults) {
    if (authResults?.accessToken && authResults?.idToken) {
      await this.setSession(authResults)
    }
  }

  async setSession(authResults) {
    //Assign local storage items
    let expiresAt = JSON.stringify(authResults.expiresIn * 1000 + new Date().getTime())
    localStorage.setItem('access_token', authResults.accessToken)
    // localStorage.setItem("id_token", authResults.idToken);
    localStorage.setItem('expires_At', expiresAt)
    //Trying to get the user info
    const user = await this.userInfo(authResults.accessToken)
    localStorage.setItem('userInfo', JSON.stringify(user))

    return user
  }

  async renewSession() {
    try {
      const authResults = await this.checkSession()
      console.log('authResults: ', authResults)
      if (authResults && authResults.accessToken && authResults.idToken) {
        this.setSession(authResults)
      }
    } catch (error) {
      console.error(error)
      this.logout()
    }
  }

  removeCookies() {
    try {
      const domain = window.location.hostname
      const cookies = document.cookie.split(';')

      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i]
        while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1)
        }

        if (cookie.indexOf(domain) === 0) {
          const cookieName = cookie.split('=')[0]
          document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        }
      }
    } catch (error) {
      console.error('error:', error)
    }
  }

  logout(returnUrl) {
    let logoutRedirectUrl = window.location.origin
    // redirect to teams login
    const loginMethod = localStorage.getItem('login.method')
    switch (loginMethod) {
      case 'teams':
        logoutRedirectUrl += '/auth/login-page/teams'
        break
      case 'ariba':
        logoutRedirectUrl += '/auth/login-page/ariba'
        break
      case 'external':
        logoutRedirectUrl += '/auth/login-page/external'
        break
      default:
        logoutRedirectUrl += '/auth/login-page'
        break
    }

    // Remove tokens and expiry time
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('userData')
    localStorage.removeItem('expires_At')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userName')
    localStorage.removeItem('userId')
    localStorage.removeItem('nonce')
    localStorage.removeItem('picture')
    localStorage.removeItem('login.method')
    localStorage.removeItem('invoice.config')
    localStorage.removeItem('invoice.pageSize')
    localStorage.removeItem('invoice.filters')
    localStorage.removeItem('invoice.poModal.header')
    localStorage.removeItem('invoice.poModal.lineItems')
    localStorage.removeItem('payment.config')
    localStorage.removeItem('payment.pageSize')
    localStorage.removeItem('payment.filters')

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key.includes('auth0')) {
        localStorage.removeItem(key)
      }
    }
    // Save the return value
    if (returnUrl && (typeof returnUrl === 'string' || returnUrl instanceof String)) {
      logoutRedirectUrl += `#returnUrl=${returnUrl}`
    }

    this.auth0.logout({
      returnTo: logoutRedirectUrl
    })
    this.removeCookies()
  }

  sessionExpired() {
    // Remove tokens and expiry time
    this.cleanSession()
    this.auth0.logout({
      returnTo: LoginUser.ExpiredEndPoint
    })
  }

  cleanSession() {
    // Remove tokens and expiry time
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_At')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userName')
    localStorage.removeItem('userId')
    localStorage.removeItem('nonce')
    localStorage.removeItem('picture')
  }
}
