import React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import jwtDecode from "jwt-decode";
import { getHashParameters } from "utils/functions";

export default function LoginEnd(props) {

    microsoftTeams.initialize()

    let hashParams = getHashParameters(props.location.hash);
    if (hashParams["error"]) {
        // Authentication/authorization failed
        localStorage.setItem("simple.error", JSON.stringify(hashParams))
        microsoftTeams.authentication.notifyFailure(hashParams["error"])
    } else if (hashParams["access_token"]) {
        // Get the stored state parameter and compare with incoming state
        let expectedState = localStorage.getItem("login.state");
        if (expectedState !== hashParams["state"]) {
            // State does not match, report error
            localStorage.setItem("simple.error", JSON.stringify(hashParams));
            microsoftTeams.authentication.notifyFailure("StateDoesNotMatch");
        } else {
            localStorage.setItem("access_token", hashParams["access_token"]);
            // localStorage.setItem("id_token", hashParams["id_token"]);
            localStorage.setItem("expires_At", hashParams["expires_in"]);

            // get the roles of the user
            const tokenPayload = jwtDecode(hashParams["id_token"])
            localStorage.setItem("userInfo", JSON.stringify(tokenPayload))
            // Success -- return token information to the parent page
            microsoftTeams.authentication.notifySuccess({
                hash: props.location.hash,
                accessToken: hashParams["access_token"]
            })
        }
    } else {
        // Unexpected condition: hash does not contain error or access_token parameter
        localStorage.setItem("simple.error", JSON.stringify(hashParams));
        microsoftTeams.authentication.notifyFailure("UnexpectedFailure");
    }


    return (
        <div>
        </div>
    );
}
