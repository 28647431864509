import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'

//Services
import { getAppConfigurationsByApp } from 'services/apiApps'

//Views
import TmobileView from './TmobileView'

//Functions
import { showLoading } from "utils/functions";
import { hideLoading } from "utils/functions";

let _isMounted = false

async function getRequisitions (props) {
  let response = await getAppConfigurationsByApp('requisitions')
  if (response && response.success && _isMounted) {
    if (_isMounted) {
      props.setRows(response.data)
    }
  } else {
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
  hideLoading(props.setPageIsLoading)
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
    })
  ),
  withState('rows', 'setRows', []),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withHandlers({
    onAppChanged: props => id => {}
  }),
  lifecycle({
    componentDidMount () {
      _isMounted = true
      showLoading(this.props.setPageIsLoading);
      getRequisitions(this.props)
    },
    componentWillUnmount () {
      _isMounted = false
    }
  })
)(TmobileView)
