import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'

// Form Validation
const useStyles = makeStyles(styles)
const SelectInvoiceType = props => {
  const { summaryField, label, value } = props
  const classes = useStyles()

  return (
    <FormControl variant="outlined" style={{ width: '95%' }}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        id={summaryField.id}
        style={{ width: '100%' }}
        labelId="demo-simple-select-label"
        label={label ?? ''}
        value={value}
        disabled={true}
        className={classes.readOnlySelect}
        inputProps={{ readOnly: true }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={'creditMemo'} key={'creditMemo'}>
          CREDIT MEMO
        </MenuItem>
        <MenuItem value={'invoice'} key={'invoice'}>
          INVOICE
        </MenuItem>
        <MenuItem value={'paymentRequest'} key={'paymentRequest'}>
          PAYMENT REQUEST
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default SelectInvoiceType
