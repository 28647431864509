import { useMutation, useQuery } from 'react-query'
import {
  applyMappingsInvoiceStandard,
  getInvoiceStandardDiff,
  getStandardInvoice,
  getStandardInvoiceV2,
  getStandardInvoiceVersions,
  saveStandardInvoice,
  validateStandardInvoice
} from 'services/apiInvoices'

function sortLines(a, b) {
  return a.lineNumber - b.lineNumber
}

export function useGetInvoiceStandard({ invoiceId, config = {} }) {
  return useQuery(
    ['invoices', invoiceId, 'standard'],
    () => getStandardInvoice(invoiceId).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      ...config
    }
  )
}

export function useGetInvoiceStandardV2({ invoiceId, config = {} }) {
  return useQuery(['invoices', invoiceId, 'standard'], () => getStandardInvoiceV2(invoiceId), {
    refetchOnWindowFocus: false,
    ...config
  })
}

export function useGetInvoiceVersions({ invoiceId, config = {} }) {
  return useQuery(
    ['invoices', invoiceId, 'standard', 'versions'],
    () => getStandardInvoiceVersions(invoiceId).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      ...config
    }
  )
}

export function useGetInvoiceStandardDiff({
  invoiceId,
  latestVersion,
  previousVersion,
  config = {}
}) {
  const dependency = latestVersion !== null && previousVersion !== null
  return useQuery(
    ['invoices', invoiceId, 'standard', 'diff', latestVersion, previousVersion],
    () =>
      getInvoiceStandardDiff({
        latestVersion,
        previousVersion,
        invoiceId
      }).then(result => {
        const dataChargesPrevious =
          result.data.previousVersion.invoice.invoice.detail.additionalCharges ?? []

        const linesInvoicePrevious =
          result.data.previousVersion.invoice.invoice.detail.lineItems ?? []

        const dataTaxesPrevious = result.data.previousVersion.invoice.invoice.detail.taxLines ?? []

        const dataChargesLatest =
          result.data.latestVersion.invoice.invoice.detail.additionalCharges ?? []

        const linesInvoiceLatest = result.data.latestVersion.invoice.invoice.detail.lineItems ?? []
        const dataTaxesLatest = result.data.latestVersion.invoice.invoice.detail.taxLines ?? []

        const deletedCharges = result.data.resultDiff.additionalCharges.deletedLines.map(x => ({
          id: x
        }))
        const deletedTaxes = result.data.resultDiff.taxLines.deletedLines.map(x => ({ id: x }))

        const newCharges = result.data.resultDiff.additionalCharges.newLines.map(x => ({
          id: x
        }))
        const newTaxes = result.data.resultDiff.taxLines.newLines.map(x => ({ id: x }))

        const deletedLines = result.data.resultDiff.lineItems.deletedLines.map(x => ({
          id: x
        }))

        const newLines = result.data.resultDiff.lineItems.newLines.map(x => ({
          id: x
        }))

        const chargesLatest = [...dataChargesLatest, ...deletedCharges]
          .map((x, index) => ({ ...x, lineNumber: index }))
          .sort(sortLines)

        const taxesLatest = [...dataTaxesLatest, ...deletedTaxes]
          .map((x, index) => ({ ...x, lineNumber: index }))
          .sort(sortLines)

        const chargesPrevious = [...dataChargesPrevious, ...newCharges]
          .map((x, index) => ({ ...x, lineNumber: index }))
          .sort(sortLines)

        const taxesPrevious = [...dataTaxesPrevious, ...newTaxes]
          .map((x, index) => ({ ...x, lineNumber: index }))
          .sort(sortLines)

        const linesLatest = [...linesInvoiceLatest, ...deletedLines]
          .map((x, index) => ({
            ...x,
            lineNumber: index
          }))
          .sort(sortLines)

        const linesPrevious = [...linesInvoicePrevious, ...newLines]
          .map((x, index) => ({
            ...x,
            lineNumber: index
          }))
          .sort(sortLines)

        return {
          ...result.data,
          latestVersion: {
            ...result.data.latestVersion,
            charges: chargesLatest,
            taxes: taxesLatest,
            lineItems: linesLatest,
            metadata: {
              ...result.data.latestVersion.metadata,
              chargesAndTaxes: {
                ...result.data.latestVersion.metadata.additionalCharges,
                ...result.data.latestVersion.metadata.taxLines
              }
            }
          },
          previousVersion: {
            ...result.data.previousVersion,
            charges: chargesPrevious,
            taxes: taxesPrevious,
            lineItems: linesPrevious,
            metadata: {
              ...result.data.previousVersion.metadata,
              chargesAndTaxes: {
                ...result.data.previousVersion.metadata.additionalCharges,
                ...result.data.previousVersion.metadata.taxLines
              }
            }
          },
          resultDiff: {
            header: result.data.resultDiff.header,
            lineItems: result.data.resultDiff.lineItems,
            chargesAndTaxes: {
              deletedLines: [
                ...result.data.resultDiff.additionalCharges.deletedLines,
                ...result.data.resultDiff.taxLines.deletedLines
              ],
              newLines: [
                ...result.data.resultDiff.additionalCharges.newLines,
                ...result.data.resultDiff.taxLines.newLines
              ],
              result: [
                ...result.data.resultDiff.additionalCharges.result,
                ...result.data.resultDiff.taxLines.result
              ]
            }
          }
        }
      }),
    {
      enabled: dependency,
      refetchOnWindowFocus: false,
      ...config
    }
  )
}

export function useUpdateInvoiceStandard(invoiceId) {
  return useMutation(values => saveStandardInvoice(invoiceId, values))
}

export function useValidateInvoiceStandard(invoiceId) {
  return useMutation(values => validateStandardInvoice(invoiceId, values))
}

export function useMappingsInvoiceStandard() {
  return useMutation(({ url, body }) => applyMappingsInvoiceStandard(url, body))
}
