import React, { createContext, useState, useMemo } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'

import AdminLayout from './layouts/Admin'
import InvoiceAI from './layouts/InvoiceAI/InvoiceAI'
import CoupaIFrameAppLayout from './layouts/POC/CoupaIFrameApp'
import TeamsLayout from './layouts/Teams'
import PublicLayout from './layouts/Public'
import AuthLayout from './layouts/Auth'
import { overrides } from './themes'

const theme = createTheme({ overrides })
export const InvoiceContext = createContext({
  initialPageInvoice: 0,
  setInitialPageInvoice: () => {},
  initialPagePayment: 0,
  setInitialPagePayment: () => {}
})
const PrivateRoute = ({ component, ...rest }) => {
  if (rest.path === '/coupa') {
    localStorage.setItem('login.method', 'external')
  }
  const [initialPageInvoice, setInitialPageInvoice] = useState(0)
  const [initialPagePayment, setInitialPagePayment] = useState(0)
  const value = useMemo(
    () => ({
      initialPageInvoice,
      setInitialPageInvoice,
      initialPagePayment,
      setInitialPagePayment
    }),
    [initialPageInvoice, initialPagePayment]
  )

  let redirectUrl
  switch (localStorage.getItem('login.method')) {
    case 'teams':
      redirectUrl = '/auth/login-page/teams'
      break
    case 'ariba':
      redirectUrl = '/auth/login-page/ariba'
      break
    case 'external':
      redirectUrl = '/auth/login-page/external'
      break
    default:
      redirectUrl = '/auth/login-page'
      break
  }

  return (
    <InvoiceContext.Provider value={value}>
      <Route
        {...rest}
        render={props =>
          localStorage.getItem('id_token') ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: redirectUrl,
                state: { from: props.location }
              }}
            />
          )
        }
      />
    </InvoiceContext.Provider>
  )
}

const TeamsPrivateRoute = ({ component, ...rest }) => {
  if (localStorage.getItem('login.method') !== 'teams') {
    localStorage.setItem('login.method', 'teams')
  }

  let redirectUrl = '/auth/login-page/teams'

  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('id_token') ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

const PublicRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('id_token') ? (
          <Redirect
            to={{
              pathname: '/'
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  )
}

const App = () => (
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <Switch>
        <Route path="/public" component={PublicLayout} />
        <Route exact path="/" render={() => <Redirect to="/admin/home" />} />
        <PrivateRoute path="/view" component={InvoiceAI} />
        <PrivateRoute path="/coupa" component={CoupaIFrameAppLayout} />
        <PrivateRoute path="/dnb" component={CoupaIFrameAppLayout} />
        <PrivateRoute path="/admin" component={AdminLayout} />
        <TeamsPrivateRoute path="/teams" component={TeamsLayout} />
        <PublicRoute path="/auth" component={AuthLayout} />
      </Switch>
    </BrowserRouter>
  </MuiThemeProvider>
)

export default App
