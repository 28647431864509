import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const Modal = (props) => {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const {
    showModal,
    onClose,
    onSubmit,
    warningMessage,
    title,
    textSuccessBtn,
    textCloseBtn
  } = props
  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      open={showModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose()}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
    >
      <DialogTitle id="notice-modal-slide-title">{title}</DialogTitle>
      <DialogContent
        id="notice-modal-slide-description"
        className={classes.modalBody}
      >
        <p>{warningMessage}</p>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => onSubmit()} color="primary" variant="outlined">
          {textSuccessBtn}
        </Button>
        <Button onClick={() => onClose()} color="secondary">
        {textCloseBtn}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function Prompt(props) {
  const { when, onOK, onCancel, title, okText, cancelText, warningMessage } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return false;
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Modal
      title={title}
      showModal={showPrompt}
      onSubmit={handleOK}
      textSuccessBtn={okText}
      onClose={handleCancel}
      textCloseBtn={cancelText}
      warningMessage={warningMessage || 'There are unsaved changes. Are you sure want to leave this page ?' }
    />
  ) : null;
}

export default Prompt;
