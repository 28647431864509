import React from 'react';
import { useQueryClient } from 'react-query';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// import MaterialTable from 'material-table'
// import Stepper from '@material-ui/core/Stepper'
// import Step from '@material-ui/core/Step'
// import StepLabel from '@material-ui/core/StepLabel'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Button from "components/CustomButtons/Button.js";
import Prompt from "components/CustomPrompt/Prompt.js"
import ReactTable from "react-table";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";

//Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// style
import style from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js';
import style2 from "assets/jss/material-dashboard-pro-react/views/User/createStyle.js";
import { styled } from '@material-ui/core/styles';

//hooks
import { useCheckPermissions } from '../../hooks/useAuth.js';
import { useAddComment, useGetComments } from 'hooks/useSupportTickets.js';

// functions
import { decodeUrl } from "utils/functions";

const useStyles = makeStyles(style)
const useStyles2 = makeStyles(style2)

export default function SupportTicket (props) {
  const classes = useStyles()
  const classes2 = useStyles2()
  const queryClient = useQueryClient()

  let breadcrumbViews = [
    {
        name: "Support Tickets",
        url: "/admin/support/index",
        icon: ContactSupportIcon,
    },
    {
        name: "Ticket details",
        url: `/admin/support/ticket/${props.match.params.ticketId}/tenant/${props.match.params.tenantId}`,
        icon: MoreHorizIcon,
    }
  ]

  const { data: pmCloseTickets } = useCheckPermissions('CloseTickets', ['Manage.Support.CloseTickets'])
  const { data: comments } = useGetComments(props.match.params.tenantId, props.match.params.ticketId)
  const { mutate: addComment, isLoading: addCommentIsLoading } = useAddComment(queryClient)

  const printCommentDate = (creationTime) => {
    let hours = creationTime.getHours()
    let minutes = creationTime.getMinutes()
    let day = creationTime.getDate()
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dic'][creationTime.getMonth()]
    const ampm = hours >= 12 ? 'pm' : 'am'

    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes
    day = day < 10 ? `0${day}` : day
    return `${day} ${month} ${creationTime.getFullYear()}     at    ${hours}:${minutes} ${ampm}`
  }

  const AreYouSureLeavePage = () => {
    return (
      <form
        onSubmit={event => {
          event.preventDefault()
          event.target.reset()
        }}
      >
      <Prompt
        when={props.currentComment !== ''}
        title="Leave this page"
        cancelText="Cancel"
        okText="Confirm"
        onOK={() => {
          props.setCurrentComment('')
          return true
        }}
        onCancel={() => false}
        warningMessage="There is an unsaved comment. Are you sure want to leave this page?"
      />
      </form>
    );
  }

  const ExpandMore = styled(props => {
    const { expand, ...other } = props
    return <IconButton {...other} />
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }))
  
  return (
    <div>
      { props.isNotFound || props.isUnauthorizer ? 
      <h3 className={classes.formHeader}>{ props.isUnauthorizer ? '403 Unauthorized' : '404 Ticket was not found' }</h3> : 
      <>
      <AreYouSureLeavePage/>
      <Breadcrumbs views={breadcrumbViews} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        {Object.keys(props.supportTicket).length > 0 ? (<Card>
            <CardHeader>
              <h3 className={classes.formHeader}>Support Ticket Details</h3>
            </CardHeader>
            <CardBody>
              {Object.keys(props.supportTicket).length > 0 ? (
                <GridContainer>
                  <GridItem md={4}>
                    <h4><b>Tenant: </b></h4>
                    <subtitle1>{props.supportTicket.tenantId}</subtitle1>
                  </GridItem>
                  <GridItem md={4}>
                    <h4><b>Associated User: </b></h4>
                    <subtitle1>{props.supportTicket.associatedUser.email}</subtitle1>
                  </GridItem>
                  <GridItem md={4}>
                    <h4><b>Ticket ID: </b></h4>
                    <subtitle1>{props.supportTicket.ticketId}</subtitle1>
                  </GridItem>
                  <GridItem md={4}>
                    <h4><b>Title: </b></h4>
                    <subtitle1>{props.supportTicket.ticketDescription.title}</subtitle1>
                  </GridItem>
                  <GridItem md={4}>
                    <h4><b>Application: </b></h4>
                    <subtitle1>{props.supportTicket.ticketDescription.appId}</subtitle1>
                  </GridItem>
                  <GridItem md={4}>
                    <h4><b>App Configuration: </b></h4>
                    <subtitle1>{props.supportTicket.ticketDescription.instance}</subtitle1>
                  </GridItem>
                  <GridItem md={4}>
                    <h4><b>Priority: </b></h4>
                    <subtitle1 style={{ color: props.supportTicket.ticketDescription.priority === 'High' ? 'red' : props.supportTicket.ticketDescription.priority === 'Medium' ? 'darkkhaki' : 'green' }}>{props.supportTicket.ticketDescription.priority}</subtitle1>
                  </GridItem>
                  <GridItem md={4}>
                    <h4><b>Ticket Status: </b></h4>
                    <subtitle1 style={{ color: props.supportTicket.status === 'Opened' ? 'green' : 'red' }}>{props.supportTicket.status}</subtitle1>
                  </GridItem>
                  <GridItem md={12}>
                    <h4><b>Ticket Description: </b></h4>
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={6}
                      defaultValue={props.supportTicket.ticketDescription.description}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem md={12}>
                    <h4><b>Attachments: </b></h4>
                    { props.supportTicket.ticketDescription.attachments.map(x => (
                      <GridItem>
                        <a href={x} target="_blank" rel="noopener noreferrer">{decodeUrl(x).split('?')[0]?.split('/').pop()}</a>
                      </GridItem>
                    ))}
                  </GridItem>
                </GridContainer>
              ) : null}
            </CardBody>
          </Card>) : null }
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
        {Object.keys(props.supportTicket).length > 0 ? (
          <Card>
            <CardHeader>
              <GridContainer>
              <GridItem xs={11} sm={11} md={11}>
                <h3 className={classes.formHeader}>Support Ticket History</h3>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <ExpandMore
                  expand={props.isExpandHistory}
                  onClick={() => props.setIsExpandHistory(!props.isExpandHistory)}
                  aria-expanded={props.isExpandHistory}
                  aria-label="show more"
                  style={{display: "flex", justifyContent: 'flex-end'}}
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </GridItem>
            </GridContainer>
            </CardHeader>
            <CardBody>
            <Collapse in={props.isExpandHistory} timeout="auto" unmountOnExit>
              {Object.keys(props.supportTicket).length > 0 ? (
                  <ReactTable
                    data={props.supportTicket.ticketHistory}
                    columns={[
                      {
                        Header: "Datetime",
                        id: "dateTime",
                        accessor: d => new Date(d.dateTime).toISOString(),
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "User",
                        accessor: "user",
                        width: 300,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        accessor: "status",
                        Header: () => <div style={{ textAlign: "left" }}>Ticket Status</div>,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: () => <div style={{ textAlign: "left" }}>Message</div>,
                        id: "supportTeamComment",
                        accessor: d => d.status === 'Closed' ?  props.supportTicket.supportTeamComment : '',
                        sortable: false,
                        filterable: false,
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    minRows={0}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                ) : null}
            </Collapse>
            </CardBody>
          </Card>
        ) : null } 
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
        {Object.keys(props.supportTicket).length > 0 ? (
          <Card>
          <CardHeader>
            <GridContainer>
              <GridItem xs={11} sm={11} md={11}>
                <h3 className={classes.formHeader}>Comments</h3>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <ExpandMore
                  expand={props.isExpandComments}
                  onClick={() => props.setIsExpandComments(!props.isExpandComments)}
                  aria-expanded={props.isExpandComments}
                  style={{display: "flex", justifyContent: 'flex-end'}}
                >
                  <ExpandMoreIcon/>
                </ExpandMore>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Collapse in={props.isExpandComments} timeout="auto" unmountOnExit>
              {props.supportTicket.status === 'Opened' ?
              <>
              { addCommentIsLoading ?  
                <div className={classes2.circularProgress} style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <CircularProgress color='inherit' size={50}/>
                </div>  :  
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="comment"
                      label="Add your comment"
                      type="textarea"
                      multiline={true}
                      rows= "6"
                      fullWidth
                      value={props.currentComment}
                      onChange={(event) => {
                        props.setCurrentComment(event.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} style={{ marginTop: '10px', marginBottom: '10px', display: "flex", justifyContent: 'flex-end' }}>
                      <Button color="primary" disabled={props.currentComment === ''} onClick={() => {
                        addComment({
                          tenant: props.supportTicket.tenantId,
                          ticketId: props.supportTicket.ticketId,
                          comment: props.currentComment
                        })
                        props.setCurrentComment('')
                      }}>
                        Comment
                      </Button>
                      <Button color="primary" disabled={props.currentComment === ''} onClick={() => {
                        props.setCurrentComment('')
                      }}>
                        Cancel
                      </Button>
                  </GridItem>
                </GridContainer>
              }
              </> :  <></>}
              {Object.keys(props.supportTicket).length > 0 ? (
                <ReactTable
                data={comments || []}
                columns={[
                  {
                    Header: "",
                    id: "creationTime",
                    accessor: d => {
                      const initials = `${d.creationUser.name[0]}${d.creationUser.name[1]}`.toLowerCase()
                      const creationTime = new Date(d.creationTime)
                      return (
                        <GridContainer>
                          <GridItem xs={1} sm={1} md={1} style={{ marginTop: '10px' }}>
                            <img src={`https://s.gravatar.com/avatar/2ecc61de2b2afecef7f8043946b68526?s=480&r=pg&d=https%3A%2f%2fcdn.auth0.com%2favatars%2f${initials}.png`} 
                            style={{ borderRadius: '50%', width: '30%'}}
                            alt={initials}/>
                          </GridItem>
                          <GridItem xs={11} sm={11} md={11} style={{ marginTop: '10px', marginLeft: '-5%'}}>
                          <p style={{ fontSize: '80%' }}><b>{d.creationUser.name}</b> {`  commented  ${printCommentDate(creationTime)}`}</p>
                            {d.comment}
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    sortable: false,
                    filterable: false,
                  }
                ]}
                defaultPageSize={3}
                pageSizeOptions= {[3, 5, 10, 20, 25, 50, 100]}
                showPaginationTop
                minRows={0}
                showPaginationBottom={false}
                className="-striped -highlight"
              />
              ) : null}
            </Collapse>
          </CardBody>
        </Card>
        ) : null } 
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: '10px'}}>
          {props.supportTicket.status === 'Opened' && pmCloseTickets ? 
           (( <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
              <Button color="primary" onClick={() => {
                props.setCurrentComment('')
                props.handleClickOpen()
              }}>
                Close Support Ticket
              </Button>
              <Dialog maxWidth="md" fullWidth="false" open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Close Support Ticket</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Enter a comment for the final user:
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Relish Support Team Comment"
                    type="textarea"
                    multiline={true}
                    rows= "6"
                    fullWidth
                    onChange={(event) => {props.onFieldChange(event.target.value)}}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={props.handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={props.updateSupportTicket} disabled={props.supportComment === ''} color="primary">
                    Close Support Ticket
                  </Button>
                </DialogActions>
              </Dialog>
              <Snackbar
                place="br"
                color="success"
                icon={AddAlert}
                message="Support Ticket closed successfully"
                open={props.submitSuccess}
                closeNotification={() => props.setSubmitSuccess(false)}
                close
              />
              <Snackbar
                place="bl"
                color="danger"
                icon={AddAlert}
                message={props.validationMessage}
                open={props.createError}
                closeNotification={() => props.setCreateError(false)}
                close
              />
            </div>
          )) : null }
        </GridItem>
      </GridContainer>
      </>
    }
    </div>
  )
}
