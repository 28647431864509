import MenuItem from '@material-ui/core/MenuItem'

import React from 'react'
import { getInvoiceProcess } from 'utils/functions'

import { InvoiceProcess } from 'utils/Constants'
import SelectField from './SelectField'
import { getProcessLookUp } from './utils'

function getSelectOptions(process, processList) {
  const jsxResult = []

  const processLookUp = getProcessLookUp(processList, process)
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(processLookUp)) {
    if (value === InvoiceProcess['ocr-global']) {
      jsxResult.push(
        <MenuItem value={key} key={key} disabled={true}>
          <em>{value}</em>
        </MenuItem>
      )
    } else {
      jsxResult.push(
        <MenuItem value={key} key={key}>
          {value}
        </MenuItem>
      )
    }
  }

  if (jsxResult.length > 0) {
    return jsxResult
  } else {
    return null
  }
}
const SelectProcess = props => {
  const { appConfig, processList } = props

  const invoiceProcess = getInvoiceProcess(appConfig)
  const options = getSelectOptions(invoiceProcess, processList)

  return <SelectField {...props}>{options}</SelectField>
}
export default SelectProcess
