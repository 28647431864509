import React from 'react'
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import { CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

// material ui icons
import AddAlertIcon from '@material-ui/icons/AddAlert'

// core components
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Snackbar from 'components/Snackbar/Snackbar.js'

// images
import LoginImage from 'assets/img/LoginImage.png'

import styles from 'assets/jss/material-dashboard-pro-react/views/loginViewStyle.js'

const useStyles = makeStyles(styles)

function getTypeLogin() {
  const origin = localStorage.getItem('login.method') || ''

  return origin === 'external'
}

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden')
  const [isAutomaticLogin, setIsAutomaticLogin] = React.useState(false)
  setTimeout(function() {
    setCardAnimation('')
  }, 700)
  const classes = useStyles()

  React.useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin === window.origin && event.data?.type === 'FROM_PARENT') {
        try {
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(event.data.data)) {
            localStorage.setItem(key, value)
          }
          setIsAutomaticLogin(getTypeLogin())
        } catch (error) {
          console.error('🚀 ~External login error:', error)
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  React.useEffect(() => {
    if (isAutomaticLogin) {
      props.handleLoginAutomatic()
    }
    // eslint-disable-next-line
  }, [isAutomaticLogin])

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="primary">
                <h2 className={classes.cardTitle}>Relish</h2>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={12}>
                  <img width="100%" src={LoginImage} alt="logo" className={classes.logotypeImage} />
                </GridItem>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {props.isLoadingLogin ? (
                  <Grid container className={classes.progressContainer}>
                    <div className={classes.circularProgress}>
                      <CircularProgress color="inherit" />
                    </div>
                  </Grid>
                ) : (
                  <Button color="primary" simple size="lg" block onClick={props.handleLoginButtonClick}>
                    Login
                  </Button>
                )}
                {props.loginOrigin === 'teams' ? (
                  <p className={classes.right} style={{ color: '#2c786e' }}>
                    <NavLink to={`/public/about`}>{'New to Relish Apps?'}</NavLink>
                  </p>
                ) : null}
              </CardFooter>
            </Card>
          </form>
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlertIcon}
            message={props.errorMessage}
            open={props.error}
            closeNotification={() => props.setError(false)}
            close
          />
        </GridItem>
      </GridContainer>
    </div>
  )
}
