import { useEffect, useMemo } from 'react'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import {
  createImportInvoice,
  getImportInvoice,
  getImportInvoiceLogs,
  getImportInvoices,
  startImportInvoice,
  uploadImportInvoice
} from 'services/apiInvoices'

export function useGetImportInvoices(status, configTenant, pagination) {
  const queryClient = useQueryClient()
  const { lastEvaluatedKeys, page, rowsPage } = pagination
  const lastEvaluatedKey = page === 0 ? null : lastEvaluatedKeys[page - 1]

  const statusMemo = useMemo(
    // eslint-disable-next-line
    () => status.reduce((acc, curr) => ((acc[curr] = curr), acc), {}),
    [status]
  )
  const { defaultApp } = configTenant ?? {}
  const useQueryResult = useQuery(
    ['import', 'invoices', defaultApp, page, rowsPage, statusMemo],
    () =>
      getImportInvoices({
        appId: defaultApp,
        lastEvaluatedKey,
        rowsPage,
        status: statusMemo
      }).then(result => {
        const { imports, lastEvaluatedKey } = result.data

        const rows = imports.map(x => {
          return {
            importId: x.importId,
            fileName: x.inputFile.name,
            userName: x.userInfo.name,
            userEmail: x.userInfo.email,
            uploadDate: x.uploadDate,
            status: x.status
          }
        })
        return { rows, lastEvaluatedKey: lastEvaluatedKey ?? null }
      }),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!configTenant
    }
  )

  useEffect(() => {
    if (useQueryResult?.data?.lastEvaluatedKey) {
      queryClient.prefetchQuery(
        ['import', 'invoices', defaultApp, page + 1, rowsPage, statusMemo],
        () =>
          getImportInvoices({
            appId: defaultApp,
            lastEvaluatedKey: useQueryResult?.data?.lastEvaluatedKey,
            status: statusMemo,
            rowsPage
          }).then(result => {
            const { imports, lastEvaluatedKey } = result.data
            return { rows: imports, lastEvaluatedKey: lastEvaluatedKey ?? null }
          })
      )
    }
  }, [useQueryResult.data, page, rowsPage, queryClient, defaultApp, statusMemo])

  return useQueryResult
}

export function useGetImportInvoice(configTenant, importId) {
  const { defaultApp } = configTenant ?? {}
  return useQuery(
    ['import', 'invoices', defaultApp, importId],
    () =>
      getImportInvoice({
        appId: defaultApp,
        importId
      }).then(result => result.data),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!configTenant
    }
  )
}

export function useGetImportInvoiceLogs(configTenant, importId) {
  const { defaultApp } = configTenant ?? {}
  return useQuery(
    ['import', 'invoices', 'logs', defaultApp, importId],
    () =>
      getImportInvoiceLogs({
        appId: defaultApp,
        importId
      }).then(result => {
        return result.data.map(x => {
          return {
            date: x.logDate,
            status: x.status,
            description: [x.message]
          }
        })
      }),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!configTenant
    }
  )
}

export function useCreateImportInvoices(appId) {
  return useMutation(body => createImportInvoice({ appId, body }))
}

export function useStartImportInvoices() {
  return useMutation(({ appId, importId }) => startImportInvoice({ appId, importId }))
}

export function useUploadImportInvoices() {
  return useMutation(({ file, presignedUploadUrl }) =>
    uploadImportInvoice({ presignedUploadUrl, file })
  )
}
