import React, { useCallback, useEffect, useState } from 'react'

// import clsx from 'clsx'
import { Badge, CircularProgress, Fab, Tooltip } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import AddAlert from '@material-ui/icons/AddAlert'
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined'
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import TabPanel from 'components/Invoice/TabPanel'
import { ValidationsResponseModal } from 'components/Invoice/ValidationsResponseModal'
import Snackbar from 'components/Snackbar/Snackbar'
import SwipeableViews from 'react-swipeable-views'

import {
  ChargesBySystem,
  ColumnsOrderLineItems,
  CustomCharges,
  HeaderFieldsSide,
  InvoiceProcess,
  specialPOLinesByProcess
} from 'utils/Constants'
import { HeaderField } from './HeaderField'

import ReactDataGrid from '@inovua/reactdatagrid-community'
import DatePickerField from './DatePickerField'
import SelectInvoiceType from './SelectInvoiceType'
import SwitchField from './SwitchField'

import '@inovua/reactdatagrid-community/index.css'
import ModalAccountType from 'components/Invoice/ModalAccountType'
import ModalComments from 'components/Invoice/ModalComments'
import { useGetAppList } from 'hooks/useApp'
import { useGetInvoiceComments } from 'hooks/useInvoices'
import AdditionalDetailsRO from './AdditionalDetailsRO'
import { OtherField } from './OtherField'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  marginTexField: {
    flex: '0 0 50%',
    display: 'flex',
    margin: '2rem 0 0 0',
    justifyContent: 'left'
  },
  summaryFileds: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  otherFiledsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  summaryFiledsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  otherFiledsSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  otherFileds: {
    flex: '0 0 50%',
    display: 'flex',
    margin: '2rem 0 0 0',
    alignItems: 'center',
    justifyContent: 'center'
  },
  otherFiled: {},
  formControl: {
    minWidth: 100,
    maxWidth: 100,
    margin: ' 0 2rem 0 1rem'
  },
  notRecognizedLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formControlCountrie: {
    margin: theme.spacing(0.5),
    minWidth: 210,
    maxWidth: 300
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  },
  dropdown: {
    '-webkit-appearance': 'none',
    padding: '7px 40px 7px 12px',
    width: '100%',
    border: '1px solid #E8EAED',
    borderRadius: '5px',
    background: 'white',
    boxShadow: '0 1px 3px -2px #9098A9',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: '16px',
    transition: 'all 150ms ease',
    '&:focus': {
      outline: 'none',
      borderColor: '#0077FF',
      boxShadow: '0 0 0 2px rgba(#0077FF,.2)'
    },
    '&:hover': {
      stroke: '#0077FF'
    }
  },
  btnColorPrimary: {
    color: '#081c3e',
    border: '1px solid #081c3e',
    '&:hover': {
      backgroundColor: '#081c3e',
      color: 'white',
      border: '1px solid #081c3e'
    }
  },
  btnBgColorPrimary: {
    backgroundColor: '#081c3e',
    '&:hover': {
      backgroundColor: '#1E408A',
      border: '1px solid #1E408A'
    }
  }
}))

const useStylesGridItemHeader = makeStyles(theme => ({
  root: {
    padding: '5px',
    marginBottom: '1rem'
  }
}))

const useStylesBadges = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    },
    display: 'flex',
    justifyContent: 'flex-end'
  },
  badge: {
    backgroundColor: '#081c3e',
    color: 'white'
  }
}))

function sortElements(a, b) {
  return a.pos - b.pos
}

const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
  autoHide: true,
  alwaysShowTrack: true,
  scrollThumbStyle: {
    background: '#1E408A'
  }
})

export function getColumns(
  data,
  poInformation,
  onClickAlert,
  classes,
  summaryFields,
  invoiceData,
  showAccountSegments,
  accountSegmentsEnabled,
  showAddressesModal,
  requiredFields
) {
  const columns = []
  const columnPos = {}

  const processField = Object.values(summaryFields).find(field => field.type === 'PROCESS')
  const processValue = processField ? processField.value.text ?? '' : ''

  if (data.length > 0) {
    columns.push({
      header: 'key',
      name: 'key',
      defaultFlex: 1,
      minWidth: 100,
      editable: false,
      defaultVisible: false
    })

    let position = 0
    // eslint-disable-next-line
    for (const values of data) {
      if (
        !ColumnsOrderLineItems[values.type] ||
        (ColumnsOrderLineItems[values.type] &&
          ColumnsOrderLineItems[values.type].section !== 'column')
      ) {
        continue
      }

      if (values.visible) {
        columnPos[values.type] = position
        position++
      }
      if (values.type === 'LINE_NUMBER') {
        columns.push({
          header: values.label,
          name: values.type,
          defaultFlex: 1,
          minWidth: 100,
          defaultVisible: values.visible
        })
        continue
      }
      if (values.type === 'PO_LINE_NUMBER') {
        const options = []
        options.push(
          <option key={'none'} value="">
            No Po Match
          </option>
        )
        if (poInformation) {
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(poInformation)) {
            options.push(
              <option key={key} value={key}>
                {value?.summaryLine ?? ''}
              </option>
            )
          }

          columns.push({
            name: values.type,
            header: values.label,
            defaultFlex: 1,
            minWidth: 125,
            editable: false,
            defaultVisible: values.visible,
            render: ({ value, rowIndex }) => (
              <select
                name="po"
                value={value}
                id="po"
                className={classes.dropdown}
                onChange={e => onClickAlert({ index: rowIndex, value: e.target.value })}
              >
                {options.length > 0 ? options : null}
              </select>
            )
          })
        } else {
          columns.push({
            name: values.type,
            header: values.label,
            defaultFlex: 1,
            minWidth: 125,
            editable: false,
            defaultVisible: values.visible,
            render: ({ value, rowIndex }) => (
              <select
                name="po"
                value={value}
                id="po"
                className={classes.dropdown}
                onChange={e => onClickAlert({ index: rowIndex, value: e.target.value })}
              >
                {options.length > 0 ? options : null}
              </select>
            )
          })
        }

        if (!invoiceData.version || invoiceData.version === 1) {
          const specialPOLines = specialPOLinesByProcess[processValue] ?? []
          // eslint-disable-next-line
          for (const line of specialPOLines) {
            options.push(
              <option key={line.value} value={line.value}>
                {line.label}
              </option>
            )
          }
        }

        continue
      }

      if (values.type === 'ITEM') {
        columns.push({
          name: values.type,
          header: values.label,
          defaultFlex: 1,
          minWidth: 400,
          defaultVisible: values.visible
        })
        continue
      }
      columns.push({
        name: values.type,
        header: values.label,
        defaultFlex: 1,
        minWidth: 120,
        defaultVisible: values.visible
      })
    }

    const FIELD_LEVEL = 'lineItems'
    const detailFields = Object.values(ColumnsOrderLineItems)
      .filter(x => x.section === 'details')
      .map(x => x.type)

    const customFieldsSelects = Object.values(requiredFields ?? {}).filter(
      x => x.level === FIELD_LEVEL && x.visible && x.isCustomField
    )

    const standardFields = Object.values(requiredFields ?? {}).filter(
      x => detailFields.includes(x.standardField) && x.visible && x.level === FIELD_LEVEL
    )

    const isLineAccountSegmentsVisible =
      requiredFields[`lineItems#${ColumnsOrderLineItems.ACCOUNTING.type}`]?.visible

    const isAccountEnabled = isLineAccountSegmentsVisible && accountSegmentsEnabled
    const isDetailsEnabled = standardFields.length > 0 || customFieldsSelects.length > 0

    if (isDetailsEnabled || isAccountEnabled) {
      columns.push({
        header: ColumnsOrderLineItems.ADDITIONAL_DETAILS.label,
        name: ColumnsOrderLineItems.ADDITIONAL_DETAILS.type,
        defaultFlex: 1,
        minWidth: 250,
        editable: false,
        textAlign: 'center',
        render: ({ value, data }) => {
          return (
            <div style={{ display: 'inline-block' }}>
              <ButtonGroup variant="text" size="small" aria-label="Lite items details">
                {isAccountEnabled ? (
                  <Button color="primary" onClick={showAccountSegments}>
                    Accounting
                  </Button>
                ) : null}

                {isDetailsEnabled ? (
                  <Button color="primary" onClick={showAddressesModal}>
                    Details
                  </Button>
                ) : null}
              </ButtonGroup>
            </div>
          )
        }
      })
    }
  }

  return { columns, columnPos }
}

export function getAdditionalColumns(data, onClickAlert, classes, summaryFields, appConfig) {
  const columns = []
  const columnPos = {}

  const processField = Object.values(summaryFields).find(field => field.type === 'PROCESS')
  const processValue = processField ? processField.value.text ?? '' : ''

  if (data.length > 0) {
    columns.push({
      header: 'key',
      name: 'key',
      defaultFlex: 1,
      minWidth: 100,
      editable: false,
      defaultVisible: false
    })

    let position = 0
    // eslint-disable-next-line
    for (const values of data) {
      if (!appConfig.data.params.invoices.showTaxRate && values.type === 'RATE') {
        continue
      }

      if (values.visible) {
        columnPos[values.type] = position
        position++
      }

      if (values.type === 'CHARGE_TYPE') {
        const options = []
        options.push(
          <option key={'none'} value="">
            No Match
          </option>
        )

        const specialPOLines = specialPOLinesByProcess[processValue] ?? []
        const chargesByService = ChargesBySystem[appConfig.data.service] ?? []
        const customCharges = appConfig.data.params.ocr?.showCustomCharges
          ? [CustomCharges.insuranceCharge, CustomCharges.otherCharge]
          : []

        const charges = [...specialPOLines, ...chargesByService, ...customCharges]

        // eslint-disable-next-line
        for (const line of charges) {
          options.push(
            <option key={line.value} value={line.value}>
              {line.label}
            </option>
          )
        }

        columns.push({
          name: values.type,
          header: values.label,
          defaultFlex: 1,
          minWidth: 125,
          editable: false,
          defaultVisible: values.visible,
          render: ({ value, rowIndex }) => (
            <select
              name="type"
              value={value}
              id="type"
              className={classes.dropdown}
              onChange={e => onClickAlert({ index: rowIndex, value: e.target.value })}
            >
              {options.length > 0 ? options : null}
            </select>
          )
        })

        continue
      }

      let minWidth = 120

      if (values.type === 'DESCRIPTION') {
        minWidth = 400
      }
      columns.push({
        name: values.type,
        header: values.label,
        defaultFlex: 1,
        minWidth: minWidth,
        defaultVisible: values.visible
      })
    }
  }

  return { columns, columnPos }
}

const dataPerPage = 20
export const ExceptionFields = React.forwardRef((props, ref) => {
  const {
    ocrInformation,
    pageNumber,
    onChangeUnrecognizedField,
    onChangeSummaryField,
    lineItemKey,
    additionalChargeKey,
    tab,
    onChangeTab,
    invoiceData,
    appId,
    invoiceId,
    connectivityConfig,
    requiredFields,
    appConfig,
    processList
  } = props

  const accountSegmentsEnabled = connectivityConfig?.data?.enabled || false

  const {
    originalLineItems,
    originalAdditionalCharges,
    lineItems,
    poInformation,
    summaryFields,
    notRecognizedFields,
    lineItemColumns,
    additionalChargesColumns,
    additionalCharges,
    accountSegments
  } = ocrInformation.data

  const classesContainer = useStylesGridItemHeader()
  const classesBadges = useStylesBadges()
  const gridStyle = { minHeight: lineItems.length >= 20 ? 725 : 500 }
  const gridChargesStyle = {
    minHeight: additionalCharges.length >= 20 ? 725 : 500
  }
  const classes = useStyles()

  const theme = useTheme()

  const appList = useGetAppList('invoiceAI')
  const [createError, setCreateError] = useState({ message: '', isOpen: false })
  const [lines, setLines] = useState({
    lineItems: [],
    lineItemsBoundingBox: {},
    lineItemsAccountSegments: {}
  })
  const [charges, setCharges] = useState({
    additionalCharges: [],
    additionalChargesBoundingBox: {}
  })
  const [accountTypeModal, setAccountTypeModal] = useState(false)
  const [gridRef, setGridRef] = useState(null)
  const [gridAdditionalRef, setGridAdditionalRef] = useState(null)
  const [showCommentsModal, setShowCommentsModal] = useState(false)
  const [addressModal, setAddressModal] = useState(false)
  const [customPagination, setCustomPagination] = useState({
    skip: 0,
    numberOfPages: Math.ceil(lineItems.length / dataPerPage),
    currentPage: 1
  })
  const [customPaginationAdditional, setCustomPaginationAdditional] = useState({
    skip: 0,
    numberOfPages: Math.ceil(additionalCharges.length / dataPerPage),
    currentPage: 1
  })

  const [cellSelected, setCellSelected] = useState(null)
  const selectedLine =
    Object.keys(cellSelected ?? {}).length === 1
      ? Object.keys(cellSelected ?? {})[0].split(',')
      : []

  const [columnsData] = useState(() =>
    getColumns(
      lineItemColumns,
      poInformation,
      () => {},
      classes,
      summaryFields,
      invoiceData,
      () => setAccountTypeModal(true),
      accountSegmentsEnabled,
      () => setAddressModal(true),
      requiredFields
    )
  )
  const [columnsAdditionalData] = useState(() =>
    getAdditionalColumns(additionalChargesColumns, () => {}, classes, summaryFields, appConfig)
  )

  const [showModal, setShowModal] = useState(false)

  const commentsInformation = useGetInvoiceComments({ appId, invoiceId })

  useEffect(() => {
    if (!lineItemKey?.key) {
      setCellSelected(null)
      return
    }
    const key = `${lineItemKey.key},${lineItemKey.column}`
    setCellSelected({ [key]: true })

    const lineItemIndex = lines.lineItems.findIndex(line => line.key === lineItemKey?.key)

    if (lineItemIndex < 0) {
      return
    }

    const linePage = Math.floor(lineItemIndex / dataPerPage) + 1
    const trimStart = (linePage - 1) * dataPerPage
    setCustomPagination(old => ({
      ...old,
      skip: trimStart,
      currentPage: linePage
    }))
    if (gridRef && gridRef.current) {
      gridRef.current.scrollToId(lineItemKey.key, {
        duration: 300,
        force: true
      })
      gridRef.current.scrollToColumn(columnsData.columnPos[lineItemKey.column] ?? 0, {
        force: true,
        duration: 300
      })
    }
    // eslint-disable-next-line
  }, [lineItemKey?.key, lineItemKey?.column, gridRef])

  useEffect(() => {
    if (!additionalChargeKey?.key) {
      setCellSelected(null)
      return
    }
    const key = `${additionalChargeKey.key},${additionalChargeKey.column}`
    setCellSelected({ [key]: true })

    const additionalChargeIndex = charges.additionalCharges.findIndex(
      charge => charge.key === additionalChargeKey?.key
    )

    if (additionalChargeIndex < 0) {
      return
    }

    const linePage = Math.floor(additionalChargeIndex / dataPerPage) + 1
    const trimStart = (linePage - 1) * dataPerPage
    setCustomPaginationAdditional(old => ({
      ...old,
      skip: trimStart,
      currentPage: linePage
    }))
    if (gridAdditionalRef && gridAdditionalRef.current) {
      gridAdditionalRef.current.scrollToId(additionalChargeKey.key, {
        duration: 300,
        force: true
      })

      gridAdditionalRef.current.scrollToColumn(
        columnsAdditionalData.columnPos[additionalChargeKey.column] ?? 0,
        {
          force: true,
          duration: 300
        }
      )
    }
    // eslint-disable-next-line
  }, [additionalChargeKey?.key, additionalChargeKey?.column, gridAdditionalRef])

  useEffect(() => {
    setLines({ lineItems, lineItemsBoundingBox: {}, lineItemsAccountSegments: accountSegments })
  }, [lineItems, accountSegments])

  useEffect(() => {
    setCharges({ additionalCharges, additionalChargesBoundingBox: {} })
  }, [additionalCharges])

  useEffect(() => {
    if (tab !== 2) {
      setGridRef(null)
    }
    if (tab !== 1) {
      setGridAdditionalRef(null)
    }
  }, [tab])

  const onCellClick = useCallback(
    (event, cellProps) => {
      const { name, data } = cellProps
      let tempPage = pageNumber
      const ocr =
        tab === 1
          ? charges.additionalChargesBoundingBox[`${data.key}#${name}`]
          : lines.lineItemsBoundingBox[`${data.key}#${name}`]
      const originalPage =
        tab === 1 ? originalAdditionalCharges[data.key] : originalLineItems[data.key]

      if (ocr) {
        if (ocr.type === 'ADDED') {
          tempPage = ocr.page
        }
      } else if (originalPage && originalPage[name]) {
        tempPage = originalPage[name].page
      }
      onChangeSummaryField({
        id: data.key,
        type: tab === 1 ? 'additionalCharges' : 'lineItems',
        column: name,
        page: tempPage
      })
      setCellSelected({ [`${data.key},${name}`]: true })
    },
    [
      onChangeSummaryField,
      originalLineItems,
      pageNumber,
      lines.lineItemsBoundingBox,
      charges.additionalChargesBoundingBox,
      originalAdditionalCharges,
      tab
    ]
  )

  const updateSkip = useCallback(
    amount => {
      return () => {
        let newSkip = customPagination.skip + amount
        newSkip = Math.min(newSkip, 100)

        newSkip = Math.max(newSkip, 0)

        let tempPage
        if (amount < 0) {
          tempPage = customPagination.currentPage - 1
        } else {
          tempPage = customPagination.currentPage + 1
        }
        setCustomPagination({
          skip: newSkip,
          numberOfPages: customPagination.numberOfPages,
          currentPage: tempPage
        })
      }
    },
    [customPagination.skip, customPagination.currentPage, customPagination.numberOfPages]
  )

  const updateSkipAdditional = useCallback(
    amount => {
      return () => {
        let newSkip = customPaginationAdditional.skip + amount
        newSkip = Math.min(newSkip, 100)

        newSkip = Math.max(newSkip, 0)

        let tempPage
        if (amount < 0) {
          tempPage = customPaginationAdditional.currentPage - 1
        } else {
          tempPage = customPaginationAdditional.currentPage + 1
        }
        setCustomPaginationAdditional({
          skip: newSkip,
          numberOfPages: customPaginationAdditional.numberOfPages,
          currentPage: tempPage
        })
      }
    },
    [
      customPaginationAdditional.skip,
      customPaginationAdditional.currentPage,
      customPaginationAdditional.numberOfPages
    ]
  )

  const handleChange = (event, newValue) => {
    onChangeTab(newValue)
  }

  const handleChangeIndex = index => {
    onChangeTab(index)
  }

  const setTextInputRef = (element, index) => {
    ref.current[index] = element
  }

  function getProcessValue(process, appId, processList) {
    if (appId && processList) {
      const customProcess = processList.find(x => x.id === process && x.appId === appId)
      if (customProcess) {
        return customProcess.processName
      } else {
        return InvoiceProcess[process] ?? process
      }
    } else {
      return InvoiceProcess[process] ?? process
    }
  }

  function leftRightFields() {
    const invoiceData = []
    const vendorInfo = []
    const invoiceAmount = []
    const addressInfo = []
    const start = []
    const taxInformation = []
    const customInformation = []

    // eslint-disable-next-line
    for (const [index, summaryField] of summaryFields.entries()) {
      if (!summaryField.visible) {
        continue
      }

      let jsxElement
      const pos = HeaderFieldsSide[summaryField.type]?.pos ?? 0
      const label =
        HeaderFieldsSide[summaryField.type]?.label ?? summaryField.type.replace(/_/g, ' ')
      const rules = requiredFields[`header#${summaryField.type}`] ?? {}
      switch (summaryField.type) {
        case 'INVOICE_RECEIPT_DATE':
        case 'DUE_DATE':
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                <DatePickerField
                  summaryField={summaryField}
                  key={summaryField.id}
                  value={summaryField.value.text}
                  label={label}
                />
              </Grid>
            ),
            pos
          }
          break
        case 'RECEIVER_ADDRESS':
        case 'BILL_TO':
        case 'SHIP_TO':
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                <HeaderField
                  onChangeSummaryField={onChangeSummaryField}
                  summaryField={summaryField}
                  setTextInputRef={setTextInputRef}
                  multiline={true}
                  value={summaryField.value.text}
                  key={summaryField.id}
                  label={label}
                />
              </Grid>
            ),
            pos
          }
          break
        case 'NON_PO':
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                <SwitchField
                  summaryField={summaryField}
                  value={summaryField.value.text}
                  key={summaryField.id}
                  label={label}
                />
              </Grid>
            ),
            pos
          }
          break
        case 'HIGH_PRIORITY_FLAG':
          jsxElement = {
            jsx: (
              <Grid item xs={6} key={summaryField.id} style={{ marginBottom: '20px' }}>
                <SwitchField
                  summaryField={summaryField}
                  value={summaryField.value.text}
                  key={summaryField.id}
                  label={label}
                />
              </Grid>
            ),
            pos
          }
          break
        case 'INVOICE_TYPE':
          jsxElement = {
            jsx: (
              <Grid item xs={6} sm={6} md={6} lg={6} key={summaryField.id}>
                <SelectInvoiceType
                  summaryField={summaryField}
                  key={summaryField.id}
                  value={summaryField.value.text}
                  label={label}
                />
              </Grid>
            ),
            pos
          }
          break
        case 'PROCESS':
          jsxElement = {
            jsx: (
              <Grid item xs={6} sm={6} md={6} lg={6} key={summaryField.id}>
                <HeaderField
                  onChangeSummaryField={onChangeSummaryField}
                  summaryField={summaryField}
                  setTextInputRef={setTextInputRef}
                  value={getProcessValue(summaryField.value?.text, appId, processList)}
                  multiline={false}
                  key={summaryField.id}
                  label={label}
                />
              </Grid>
            ),
            pos
          }
          break
        case 'APP_ID': {
          const appData = appList.data?.find(x => x.env === summaryField.value?.text)
          jsxElement = {
            jsx: (
              <Grid item xs={6} sm={6} md={6} lg={6} key={`${summaryField.id}-${appData?.appName}`}>
                <HeaderField
                  onChangeSummaryField={onChangeSummaryField}
                  summaryField={summaryField}
                  setTextInputRef={setTextInputRef}
                  value={appData?.appName ?? ''}
                  multiline={false}
                  key={summaryField.id}
                  label={label}
                />
              </Grid>
            ),
            pos
          }
          break
        }
        default:
          if (rules?.typeField === 'boolean') {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                  <SwitchField
                    summaryField={summaryField}
                    value={summaryField.value.text}
                    key={summaryField.id}
                    label={label}
                  />
                </Grid>
              ),
              pos
            }
          } else {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={summaryField.id}>
                  <HeaderField
                    onChangeSummaryField={onChangeSummaryField}
                    summaryField={summaryField}
                    setTextInputRef={setTextInputRef}
                    value={summaryField.value?.text ?? ''}
                    multiline={false}
                    key={summaryField.id}
                    label={label}
                  />
                </Grid>
              ),
              pos
            }
          }
          break
      }

      if (
        (summaryField.type === HeaderFieldsSide.SUPPLIER_REMIT_TO_ADDRESS.type ||
          summaryField.type === HeaderFieldsSide.SUPPLIER_SHIP_FROM_ADDRESS.type) &&
        !rules.visible
      ) {
        continue
      }

      if (rules?.isCustomField) {
        customInformation.push(jsxElement)
        continue
      }

      switch (HeaderFieldsSide[summaryField.type]?.section) {
        case 'invoiceData':
          invoiceData.push(jsxElement)
          break
        case 'vendorInfo':
          vendorInfo.push(jsxElement)
          break
        case 'invoiceAmount':
          invoiceAmount.push(jsxElement)
          break
        case 'addressInfo':
          addressInfo.push(jsxElement)
          break
        case 'start':
          start.push(jsxElement)
          break
        case 'taxInfo':
          taxInformation.push(jsxElement)
          break
        default:
          break
      }
    }
    invoiceData.sort(sortElements)
    vendorInfo.sort(sortElements)
    invoiceAmount.sort(sortElements)
    addressInfo.sort(sortElements)
    start.sort(sortElements)
    taxInformation.sort(sortElements)
    customInformation.sort(sortElements)

    const invoiceDataJsx = invoiceData.map(jsxElement => jsxElement.jsx)
    const vendorInfoJsx = vendorInfo.map(jsxElement => jsxElement.jsx)
    const invoiceAmountJsx = invoiceAmount.map(jsxElement => jsxElement.jsx)
    const addressInfoJsx = addressInfo.map(jsxElement => jsxElement.jsx)
    const startJsx = start.map(jsxElement => jsxElement.jsx)
    const taxInfoJsx = taxInformation.map(jsxElement => jsxElement.jsx)
    const customInfoJsx = customInformation.map(jsxElement => jsxElement.jsx)
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={3}>
            {startJsx.length > 2 ? <Grid item xs={6} sm={6} md={6} lg={6}></Grid> : null}
            {startJsx.length !== 0 ? startJsx : null}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Key Invoice Data</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {invoiceDataJsx.length !== 0 ? invoiceDataJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Invoice Amount Information</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {invoiceAmountJsx.length !== 0 ? invoiceAmountJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Vendor Information</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {vendorInfoJsx.length !== 0 ? vendorInfoJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Address Information</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {addressInfoJsx.length !== 0 ? addressInfoJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {taxInfoJsx.length !== 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h5 className={classes.colorPrimary}>
                      <b>Tax Information</b>
                    </h5>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2} alignItems="center">
                      {taxInfoJsx}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            </Grid>
          </Grid>
        ) : null}
        {customInfoJsx.length !== 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h5 className={classes.colorPrimary}>
                      <b>Client Managed Fields</b>
                    </h5>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2} alignItems="center">
                      {customInfoJsx}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    )
  }

  const notRecognizedFieldsJsx = notRecognizedFields.map(notRecognizedField => {
    return (
      <OtherField
        notRecognizedField={notRecognizedField}
        onChangeField={onChangeUnrecognizedField}
        setTextInputRef={setTextInputRef}
        key={notRecognizedField.id}
        selectOptions={[]}
        value={notRecognizedField.value?.text ?? ''}
        label={notRecognizedField.label?.text ?? ''}
      />
    )
  })

  const onChangeSkip = skip => {
    setCustomPagination(old => {
      return {
        ...old,
        skip: skip,
        currentPage: 1
      }
    })
  }

  const accountSegmentsRow =
    selectedLine.length > 0 ? lines.lineItemsAccountSegments[selectedLine[0]] : null

  const lineSelected = lines.lineItems.find(x => x.key === selectedLine[0])

  return (
    <Grid container direction="column">
      <Grid container direction="row" className={classesContainer.root}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AppBar position={'sticky'} color="default">
              <>
                <div className={classesBadges.root}>
                  <IconButton onClick={() => setShowCommentsModal(true)} size="small">
                    <Badge
                      badgeContent={commentsInformation.data?.length}
                      classes={{ badge: classesBadges.badge }}
                    >
                      <SpeakerNotesIcon fontSize="small" style={{ color: '#081c3e' }} />
                    </Badge>
                  </IconButton>
                </div>
              </>
              <Tabs
                value={tab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Header Fields" {...a11yProps(0)} />
                <Tab label="Tax & Other Charges" {...a11yProps(1)} />
                <Tab label="Line item fields" {...a11yProps(2)} />
                <Tab label="Unrecognized Fields" {...a11yProps(3)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={tab}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={tab} index={0} dir={theme.direction}>
                <Grid container justifyContent="center" style={{ width: '100%' }} spacing={2}>
                  {appList.isLoading ? (
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: '1300',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#f8f8f8ad'
                      }}
                    >
                      <CircularProgress color="inherit" />
                    </div>
                  ) : null}
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                    <h3 style={{ color: '#081c3e' }}>Required Fields</h3>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {leftRightFields()}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1} dir={theme.direction}>
                <Grid container justifyContent="center" spacing={2} style={{ paddingTop: '15px' }}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Tooltip title={'Previous'}>
                      <Fab
                        size="small"
                        style={{
                          margin: '0 5px 0 5px',
                          backgroundColor: '#081c3e'
                        }}
                        disabled={customPaginationAdditional.skip === 0}
                        onClick={updateSkipAdditional(-dataPerPage)}
                      >
                        <NavigateBeforeOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                      </Fab>
                    </Tooltip>
                    <Tooltip title={'Next'}>
                      <Fab
                        size="small"
                        style={{
                          margin: '0 5px 0 5px',
                          backgroundColor: '#081c3e'
                        }}
                        onClick={updateSkipAdditional(dataPerPage)}
                        disabled={
                          customPaginationAdditional.currentPage ===
                          customPaginationAdditional.numberOfPages
                        }
                      >
                        <NavigateNextOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                      </Fab>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ReactDataGrid
                      onReady={setGridAdditionalRef}
                      editable={false}
                      showColumnMenuTool={false}
                      cellSelection={cellSelected}
                      onCellSelectionChange={setCellSelected}
                      pagination
                      sortable={false}
                      cellStyle={{}}
                      enableKeyboardNavigation={false}
                      defaultLimit={dataPerPage}
                      idProperty="key"
                      activeIndexThrottle={300}
                      scrollProps={scrollProps}
                      skip={customPaginationAdditional.skip}
                      onSkipChange={onChangeSkip}
                      renderPaginationToolbar={() => null}
                      emptyText="There are no taxes & other charges to show"
                      style={gridChargesStyle}
                      columns={columnsAdditionalData.columns}
                      dataSource={charges.additionalCharges}
                      onCellClick={onCellClick}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={2} dir={theme.direction}>
                <Grid container justifyContent="center" spacing={2} style={{ paddingTop: '15px' }}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Tooltip title={'Previous'}>
                      <Fab
                        size="small"
                        style={{
                          margin: '0 5px 0 5px',
                          backgroundColor: '#081c3e'
                        }}
                        disabled={customPagination.skip === 0}
                        onClick={updateSkip(-dataPerPage)}
                      >
                        <NavigateBeforeOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                      </Fab>
                    </Tooltip>
                    <Tooltip title={'Next'}>
                      <Fab
                        size="small"
                        style={{
                          margin: '0 5px 0 5px',
                          backgroundColor: '#081c3e'
                        }}
                        onClick={updateSkip(dataPerPage)}
                        disabled={customPagination.currentPage === customPagination.numberOfPages}
                      >
                        <NavigateNextOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                      </Fab>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ReactDataGrid
                      onReady={setGridRef}
                      editable={false}
                      showColumnMenuTool={false}
                      cellSelection={cellSelected}
                      onCellSelectionChange={setCellSelected}
                      pagination
                      sortable={false}
                      cellStyle={{}}
                      enableKeyboardNavigation={false}
                      defaultLimit={dataPerPage}
                      idProperty="key"
                      activeIndexThrottle={300}
                      scrollProps={scrollProps}
                      skip={customPagination.skip}
                      onSkipChange={onChangeSkip}
                      renderPaginationToolbar={() => null}
                      emptyText="There are no line items to show"
                      style={gridStyle}
                      columns={columnsData.columns}
                      dataSource={lines.lineItems}
                      onCellClick={onCellClick}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={3} dir={theme.direction}>
                <Grid container justifyContent="center" style={{ width: '100%' }} spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                    <h3 style={{ color: '#081c3e' }}>Unrecognized Header Fields</h3>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="center" spacing={3}>
                      {notRecognizedFieldsJsx}
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
            </SwipeableViews>
          </Grid>
        </Grid>
        <Snackbar
          place="tr"
          color="danger"
          icon={AddAlert}
          message={createError.message}
          open={createError.isOpen}
          closeNotification={() => setCreateError({ isOpen: false, message: '' })}
          close
        />

        <ModalComments
          showModal={showCommentsModal}
          commentsInformation={commentsInformation}
          appId={appId}
          invoiceId={invoiceId}
          onClose={() => setShowCommentsModal(false)}
          disabled={true}
        />

        <ValidationsResponseModal
          showModal={showModal}
          modalInfo={invoiceData?.header?.validationsResponse ?? {}}
          onClose={() => setShowModal(false)}
        />
        {accountSegmentsEnabled ? (
          <ModalAccountType
            isOpen={accountTypeModal}
            onClose={() => setAccountTypeModal(false)}
            defaultDataSource={accountSegmentsRow?.accountSegments ?? []}
            readOnly={true}
            appId={appId}
          />
        ) : null}

        <AdditionalDetailsRO
          isOpen={addressModal}
          onClose={() => setAddressModal(false)}
          lineItemSelected={lineSelected}
          requiredFields={requiredFields}
          readOnly={true}
          appId={appId}
        />
      </Grid>
    </Grid>
  )
})
