import { useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { getEmailsQuery, getEmailQuery } from 'services/apiInvoices'

export function useGetEmails(status, configTenant, pagination) {
  const queryClient = useQueryClient()
  const { lastEvaluatedKeys, page, rowsPage } = pagination
  const lastEvaluatedKey = page === 0 ? null : lastEvaluatedKeys[page - 1]

  const statusMemo = useMemo(
    // eslint-disable-next-line
    () => status.reduce((acc, curr) => ((acc[curr] = curr), acc), {}),
    [status]
  )
  const { defaultApp } = configTenant ?? {}
  const useQueryResult = useQuery(
    ['emails', defaultApp, page, rowsPage, statusMemo],
    () =>
      getEmailsQuery({
        appId: defaultApp,
        lastEvaluatedKey,
        rowsPage,
        status: statusMemo
      }).then(result => {
        const { emails, lastEvaluatedKey } = result.data
        return { rows: emails, lastEvaluatedKey: lastEvaluatedKey ?? null }
      }),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!configTenant
    }
  )

  useEffect(() => {
    if (useQueryResult?.data?.lastEvaluatedKey) {
      queryClient.prefetchQuery(['emails', defaultApp, page + 1, rowsPage, statusMemo], () =>
        getEmailsQuery({
          appId: defaultApp,
          lastEvaluatedKey: useQueryResult?.data?.lastEvaluatedKey,
          status: statusMemo,
          rowsPage
        }).then(result => {
          const { emails, lastEvaluatedKey } = result.data
          return { rows: emails, lastEvaluatedKey: lastEvaluatedKey ?? null }
        })
      )
    }
  }, [useQueryResult.data, page, rowsPage, queryClient, defaultApp, statusMemo])

  return useQueryResult
}

export function useGetEmail(configTenant, emailId) {
  const { defaultApp } = configTenant ?? {}
  return useQuery(
    ['emails', defaultApp, emailId],
    () =>
      getEmailQuery({
        appId: defaultApp,
        emailId
      }).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      enabled: !!configTenant
    }
  )
}
