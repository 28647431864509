import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'

//Services
import {
  getTenatsConnect,
  getTenatAppsConnect,
  saveTenatAppsConnect
} from 'services/apiApps'

//Views
import ConnectDefaultView from './ConnectDefaultView'

import { updateHeightFunction } from 'store/AppState'

import { showLoading } from 'utils/functions'
import { hideLoading } from 'utils/functions'

let _isMounted

async function getTenants (props) {
  showLoading(props.setPageIsLoading)
  props.setIsTenantsLoading(true)
  props.setIsAppsLoading(true)
  const response = await getTenatsConnect('teams')
  if (response && response.success) {
    const tenants = response.data.microsoftTenants
    if (tenants.length > 0) {
      if (_isMounted) {
        props.setTenants(tenants)
        props.setSelectedTenant(tenants[0])
        await getTenantApps(props, tenants[0])
      }
    }
  }
  props.setIsAppsLoading(false)
  props.setIsTenantsLoading(false)
  hideLoading(props.setPageIsLoading)
}

async function getTenantApps (props, tenant) {
  props.setIsAppsLoading(true)
  const response = await getTenatAppsConnect('teams', tenant.tenantId)
  if (response && response.success) {
    const tenantApps = response.data.applications
    if (tenantApps.length > 0) {
      if (_isMounted) {
        let defaultAppIndex = tenantApps.findIndex(app => app.default === true)
        props.setApps(tenantApps)
        props.setSelectedAppIndex(defaultAppIndex ? defaultAppIndex : 0)
        props.setSelectedApp(
          defaultAppIndex ? tenantApps[defaultAppIndex] : tenantApps[0]
        )
      }
    }
  }
  props.setIsAppsLoading(false)
}

async function onConfigSubmit (props) {
  props.setFormIsLoading(true)
  let successMessage
  let response

  let appConfiguration = {
    teamsTenantId: props.selectedTenant.tenantId,
    ...props.selectedApp
  }

  response = await saveTenatAppsConnect('teams', appConfiguration)
  successMessage = 'Connection updated successfully'

  if (response && response.success && _isMounted) {
    props.setValidationMessage(successMessage)
    props.setSubmitSuccess(true)
    await getTenants(props)
    props.setSubmitSuccess(false)
    props.setFormIsLoading(false)
  } else {
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
    props.setFormIsLoading(false)
  }
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      updateHeight: state.app.updateHeight,
      enabledApps: state.app.enabledApps
    }),
    { updateHeightFunction }
  ),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('isLoadingSave', 'setIsLoadingSave', false),
  withState('isTenantsLoading', 'setIsTenantsLoading', false),
  withState('isAppsLoading', 'setIsAppsLoading', false),
  withState('tenants', 'setTenants', [{}]),
  withState('apps', 'setApps', [{}]),
  withState('selectedTenantIndex', 'setSelectedTenantIndex', 0),
  withState('selectedAppIndex', 'setSelectedAppIndex', 0),
  withState('selectedTenant', 'setSelectedTenant', {}),
  withState('selectedApp', 'setSelectedApp', {}),
  withHandlers({
    onSave: props => async () => {
      await onConfigSubmit(props)
    },
    onTenantListItemClick: props => async (tenant, i) => {
      props.setSelectedTenantIndex(i)
      props.setSelectedTenant(tenant)
      await getTenantApps(props, tenant)
    },
    onAppListItemClick: props => (app, i) => {
      props.setSelectedApp(app)
      props.setSelectedAppIndex(i)
    }
  }),
  lifecycle({
    componentDidMount () {
      _isMounted = true
      this.props.setPageIsLoading(true)
      getTenants(this.props)
    },
    componentWillUnmount () {
      _isMounted = false
    }
  })
)(ConnectDefaultView)
