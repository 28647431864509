import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle'

// Form
import { Controller } from 'react-hook-form'

const useStyles = makeStyles(styles)

export const SelectInput = props => {
  const classes = useStyles()
  const { name, control, label, values, readOnly } = props

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormLabel className={classes.inputLabelHorizontal}>{label}</FormLabel>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth className={classes.formField}>
            <InputLabel id={`${name}-label`} className={classes.inputLabel}>
              {label}
            </InputLabel>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Select
                  error={Boolean(error)}
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select,
                    disabled: classes.disabled
                  }}
                  labelId={`${name}-label`}
                  label={`${label}`}
                  {...field}
                  inputProps={{
                    disabled: readOnly
                  }}
                >
                  {values.map(value => (
                    <MenuItem
                      key={value.key}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      style={{
                        overflowX: 'auto',
                        textOverflow: 'ellipsis'
                      }}
                      value={value.key}
                    >
                      {value.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name={name}
              control={control}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )
}
