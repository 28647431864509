import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}))

export function TablePaginationActions(props) {
  const classes = useStyles1()
  const theme = useTheme()
  const { onChangePage: onPageChange, pagination } = props

  const handleBackButtonClick = event => {
    onPageChange(event, pagination.page - 1)
  }

  const handleNextButtonClick = event => {
    onPageChange(event, pagination.page + 1)
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={pagination.page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        disabled={pagination.page === pagination.lastPage}
        onClick={handleNextButtonClick}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  onChangePage: PropTypes.func,
  pagination: PropTypes.object
}
