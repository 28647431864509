import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle'

// Form
import { Controller } from 'react-hook-form'
import { Button, IconButton } from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'

const useStyles = makeStyles(styles)

export const ArrayKeyValueInput = props => {
  const classes = useStyles()
  const { name, control, label, fields, append, remove } = props

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormLabel className={classes.inputLabelHorizontal}>{label}*</FormLabel>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button startIcon={<Add />} onClick={() => append({ key: '', value: '' })}>
            Add Param
          </Button>
          {fields.map((item, index) => (
            <Grid container spacing={3} key={item.id}>
              <Grid item xs={12} sm={5}>
                <Controller
                  name={`${name}.${index}.key`}
                  control={control}
                  render={({ field }) => <TextField {...field} label="Key" fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Controller
                  name={`${name}.${index}.value`}
                  control={control}
                  render={({ field }) => <TextField {...field} label="Value" fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <IconButton onClick={() => remove(index)}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
