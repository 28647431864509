import React, { useState, useEffect, useRef } from 'react'

import Grid from '@material-ui/core/Grid'

import Missing from './Missing'
import SuppliersCriteria from './SuppliersCriteria'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
// import LinkIcon from '@material-ui/icons/Link'

export default function DunsSuppliers (props) {
  const [assignDuns, setAssignDuns] = useState(false)
  const [supplierId, setSupplierId] = useState('')
  const [supplierName, setSupplierName] = useState('')
  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  })

  return (
    <Card>
      <CardBody>
        <Grid container>
          {!assignDuns ? (
            <Grid container>
              <Missing
                {...props}
                setSupplierId={setSupplierId}
                setAssignDuns={setAssignDuns}
                setSupplierName={setSupplierName}
              />
            </Grid>
          ) : (
            <Grid container>
              <SuppliersCriteria
                {...props}
                setAssignDuns={setAssignDuns}
                supplierId={supplierId}
                supplierName={supplierName}
              />
            </Grid>
          )}
        </Grid>
      </CardBody>
    </Card>
  )
}
