import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { userUpdate as userEditSchema } from "models/userModel";
import countryList from "react-select-country-list";
import LocalesList from 'locales-list';
import { editUser, getUser } from "services/apiUsers";
import { getAvailableRoles } from "services/apiAuth"
import { showLoading, hideLoading, getUserTenant, getUserRoles } from 'utils/functions'
import EditView from "./EditView";

const newUser = {
  firstName: "",
  lastName: "",
  email: "",
  uniqueName: "",
  tenantId: "",
  language: "en",
  role: "Admin",
  locale: "en-US",
  country: "US",
  enabled: true,
  roles1: [],
  type: ''
};

const newUserState = {
  firstName: "",
  lastName: "",
  email: "",
  uniqueName: "",
  tenantId: "",
  language: "",
  locale: "",
  role: "",
  country: "",
  enabled: "",
  roles1: "",
  type: ''
};

let _isMounted = false;
let userId;
let tenantId;

async function onSubmit(props) {
  const user = {
    ...props.user,
    roles: props.user.roles1
  }

  let result = await editUser(props.tenantId, props.userId, user);
  // Show confirmation message
  if (result.success && _isMounted) {
    props.setCreateSuccess(true);
    setTimeout(function() {
      if (_isMounted) {
        props.setCreateSuccess(false);
        props.history.push("/admin/users/index");
      }
    }, 1500);
  } else {
    // Show error message
    if (result) {
      let message = result.message;
      if (!message) {
        message = "Something went wrong, please try again later.";
      }
      props.setValidationMessage(message);
      props.setCreateError(true);
    }
    hideLoading(props.setPageIsLoading)
  }
}

async function getUserInformation(props, callback) {
  let result = await getUser(tenantId, userId);
  if (_isMounted && result.success) {
    const user = {
      ...result.data,
      role: result.data.roles[0],
      roles1: result.data.roles
    }
    props.setUser(user);

    setTimeout(function() {
      props.setUserState({ ...newUserState });
    }, 100);
  }
  callback(props.setPageIsLoading);
}

async function getCompanyList(props) {
  if (_isMounted) {
    props.setCompanyList([{ tenantId: tenantId }]);
  }
  let roleTypes = ['user']
  if (getUserRoles().includes('Super Admin')) {
    roleTypes.push('admin')
  }
  const rolesResult = await getAvailableRoles(getUserTenant())
  if (rolesResult.success) {
    const sanitizedArray =  [...new Set(rolesResult.data.filter(role => role.enabled && roleTypes.includes(role.type)).map(role => role.roleName))]
    // console.log(sanitizedArray)
    props.setRolesList(sanitizedArray)
  }
}

export default compose(
  connect(
    (state) => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
    }),
    {}
  ),
  withState("user", "setUser", { ...newUser }),
  withState("userId", "setUserId", undefined),
  withState("tenantId", "setTenantId", undefined),
  withState("userState", "setUserState", { ...newUserState }),
  withState("companyList", "setCompanyList", []),
  withState("createSuccess", "setCreateSuccess", false),
  withState("createError", "setCreateError", false),
  withState("validationMessage", "setValidationMessage", ""),
  withState("isLoadingSave", "setIsLoadingSave", false),
  withState("countryList", "setCountryList", []),
  withState("localeList", "setLocaleList", []),
  withState("pageIsLoading", "setPageIsLoading", true),
  withState("rolesList", "setRolesList", []),
  withHandlers({
    onFieldChange: (props) => (field, value) => {
      props.user[field] = value;
      let isValid = validateProperty(userEditSchema, props.user, field).isValid;
      if (isValid) {
        props.userState[field] = "success";
      } else {
        props.userState[field] = "error";
      }
      props.setUserState(props.userState);
      props.setUser(props.user);
    },
    onUserSave: (props) => async () => {
      props.setIsLoadingSave(true);

      let validation = validateObject(userEditSchema, props.user);
      if (validation.isValid) {
        showLoading();
        await onSubmit(props);
      } else {
        let field;
        for (field in props.user) {
          if (validation.errors[field]) {
            props.userState[field] = "error";
          }
        }
        props.setUserState(props.userState);
      }
      props.setIsLoadingSave(false);
    },
    onClear: props => () => {
      props.history.push(`/admin/users/index`)
    },
    handleChange: (props) => (event) => {
      
      const newRoles = event.target.value
      props.user.roles1 = newRoles
      props.setUser(props.user)
    }
  }),
  lifecycle({
    componentDidMount() {
      showLoading(this.props.setPageIsLoading);
      _isMounted = true;

      userId = this.props.match.params.id;
      this.props.setUserId(userId);
      // console.debug(userId);

      tenantId = this.props.match.params.idTenant;
      this.props.setTenantId(tenantId);

      getCompanyList(this.props);
      this.props.setCountryList(countryList().getData());
      this.props.setUserState({ ...newUserState });
      this.props.setCreateSuccess(false);
      this.props.setLocaleList(LocalesList.getAll());
      getUserInformation(this.props, hideLoading);
    },
    componentWillUnmount() {
      _isMounted = false;
    },
  })
)(EditView);
