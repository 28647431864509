import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

// Material UI Components
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'

// Icons
import AddAlert from '@material-ui/icons/AddAlert'
import WarningIcon from '@material-ui/icons/Warning'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'

// Components
import Snackbar from 'components/Snackbar/Snackbar'
import Button from 'components/CustomButtons/Button'
import { RequisitionLine } from 'components/Coupa/RequisitionLine'
import SyncLoader from 'components/SyncLoader/SyncLoader.js'

// Hooks
import { useGetRequisition } from 'hooks/POC/CoupaIFrameApp/useCoupa'
import { useQueryParams } from 'hooks/POC/CoupaIFrameApp/useQueryParams'

const useStyles = makeStyles(theme => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '100vh'
  },
  reloadSize: {
    width: '500px',
    height: '240px',
    textAlign: 'center',
    padding: '50px'
  }
}))

export default function PocRequisitionUpdate() {
  const classes = useStyles()
  const query = useQueryParams()
  const requisitionId = query.get('object_id')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const {
    data: requisition,
    isLoading,
    isError: requisitionError,
    refetch,
    isFetching
  } = useGetRequisition(requisitionId)

  useEffect(() => {
    if (!requisitionError) {
      return
    }
    setSubmitError(true)
  }, [requisitionError])

  return (
    <>
      <div className={classes.center}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Tooltip title="Refresh">
                  <Button
                    style={{
                      maxWidth: '40px',
                      maxHeight: '30px',
                      minWidth: '40px',
                      minHeight: '30px',
                      color: '#081c3e',
                      float: 'right',
                      backgroundColor: 'transparent'
                    }}
                    size="sm"
                    onClick={() => refetch({ throwOnError: true })}
                  >
                    <RefreshRoundedIcon />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <SyncLoader showLoader={isFetching && !isLoading} />
                {isLoading ? (
                  <div className={classes.reloadSize}>
                    <CircularProgress />
                  </div>
                ) : requisition ? (
                  requisition['requisition-lines'].map(line => (
                    <RequisitionLine
                      key={line.id}
                      line={line}
                      requisitionId={requisitionId}
                      refetch={refetch}
                      setSubmitSuccess={setSubmitSuccess}
                      setSubmitError={setSubmitError}
                    />
                  ))
                ) : (
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          width: '500px',
                          height: '300px'
                        }}
                      >
                        <WarningIcon
                          fontSize="large"
                          style={{ color: '#ff9800' }}
                        />{' '}
                        <h3 style={{ margin: '20px' }}>
                          No information was found!
                        </h3>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <Snackbar
        place="br"
        color="success"
        icon={AddAlert}
        message={'Successfully Updated'}
        open={submitSuccess}
        closeNotification={() => setSubmitSuccess(false)}
        close
      />
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={'Something went wrong!'}
        open={submitError}
        closeNotification={() => setSubmitError(false)}
        close
      />
    </>
  )
}
