import React, { useState, useMemo, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import csc from 'countries-states-cities'

// Core
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

//Components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

// Style
import { hexToRgb, blackColor } from 'assets/jss/material-dashboard-pro-react.js'

// Form
import { useForm, Controller, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import puertoRicoStates from './puertoRicoStates.json'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  inputLabel: {
    paddingBottom: '10px !important',
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    fontSize: '14px'
  },
  formField: {
    width: '95%',
    paddingBottom: '15px'
  }
}))

const rfcObject = {
  rfc: '',
  name: '',
  address1: '',
  address2: '',
  state: '',
  city: '',
  zipcode: '',
  country: 233,
  companyCode: ''
}

const schema = yup.object().shape({
  rfc: yup
    .string()
    .min(1)
    .required(),
  name: yup
    .string()
    .min(1)
    .required(),
  companyCode: yup.string().optional(),
  address1: yup
    .string()
    .min(1)
    .required(),
  address2: yup.string().optional(),
  state: yup
    .string()
    .min(1)
    .required(),
  city: yup
    .string()
    .min(1)
    .required(),
  zipcode: yup
    .string()
    .min(1)
    .required(),
  country: yup
    .string()
    .min(1)
    .required()
})

export default function RfcComponent(props) {
  const classes = useStyles()
  const { updateRFC, field } = props
  const [open, setOpen] = useState(false)
  const [rfcForm, setRfcForm] = useState(rfcObject)
  const [states, setStates] = useState(csc.getStatesOfCountry(rfcForm.country))
  const countryListOptions = useMemo(() => csc.getAllCountries(), [])
  const [mode, setMode] = useState({ mode: 'create', index: null })
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    mode: 'all',
    defaultValues: useMemo(() => rfcForm, [rfcForm]),
    resolver: yupResolver(schema),
    shouldUnregister: false
  })

  useEffect(() => {
    const countryInt = parseInt(rfcForm.country)
    let states = csc.getStatesOfCountry(countryInt)
    if (countryInt === 178 && states.length === 0) {
      states = puertoRicoStates
    }
    setStates(states)
    reset(rfcForm)
  }, [rfcForm, reset])

  const country = useWatch({
    control,
    name: 'country',
    defaultValue: ''
  })

  useEffect(() => {
    if (country === '') {
      return
    }
    const countryInt = parseInt(country)
    let states = csc.getStatesOfCountry(countryInt)
    if (countryInt === 178 && states.length === 0) {
      states = puertoRicoStates
    }
    setStates(states)
  }, [country])

  const handleClose = () => {
    setOpen(false)
  }

  function onSubmitHook(data) {
    data.countryIso = csc.getCountryById(parseInt(data.country))
    data.countryIso = data.countryIso.iso3
    if (mode.mode === 'create') {
      field.value.push(data)
    } else {
      field.value[mode.index] = data
    }
    updateRFC(field.value)
    handleClose()
  }

  function onDelete(fields) {
    updateRFC(fields)
  }

  function isError(field) {
    if (errors[field]) {
      return true
    }
    return false
  }

  function modal() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <form onSubmit={handleSubmit(onSubmitHook)}>
          <DialogTitle id="max-width-dialog-title">Tax Form</DialogTitle>
          <DialogContent>
            <GridContainer>
              <GridItem md={4}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                      className={classes.formField}
                      label="TaxID*"
                      error={isError('rfc')}
                      {...field}
                    />
                  )}
                  name="rfc"
                  control={control}
                />
              </GridItem>
              <GridItem md={4}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                      className={classes.formField}
                      label="Name*"
                      error={isError('name')}
                      {...field}
                    />
                  )}
                  name="name"
                  control={control}
                />
              </GridItem>
              <GridItem md={4}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                      className={classes.formField}
                      label="Company Code*"
                      error={isError('companyCode')}
                      {...field}
                    />
                  )}
                  name="companyCode"
                  control={control}
                />
              </GridItem>
              <GridItem md={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                      className={classes.formField}
                      label="Address 1*"
                      error={isError('address1')}
                      {...field}
                    />
                  )}
                  name="address1"
                  control={control}
                />
              </GridItem>
              <GridItem md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                      className={classes.formField}
                      label="Address 2"
                      error={isError('address2')}
                      {...field}
                    />
                  )}
                  name="address2"
                  control={control}
                />
              </GridItem>
              <GridItem md={3}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                      className={classes.formField}
                      label="City*"
                      error={isError('city')}
                      {...field}
                    />
                  )}
                  name="city"
                  control={control}
                />
              </GridItem>
              <GridItem md={3}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                      className={classes.formField}
                      label="Zip*"
                      error={isError('zipcode')}
                      {...field}
                    />
                  )}
                  name="zipcode"
                  control={control}
                />
              </GridItem>
              <GridItem md={6}>
                <FormControl className={classes.formField} error={isError('country')}>
                  <InputLabel id="country-label" className={classes.inputLabel}>
                    Country*
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select labelId="country-label" label="Country" {...field}>
                        <MenuItem disabled>Select Country</MenuItem>
                        {countryListOptions.map(country => (
                          <MenuItem value={country.id} key={country.id}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    name="country"
                    control={control}
                  />
                </FormControl>
              </GridItem>
              <GridItem md={6}>
                <FormControl className={classes.formField} error={isError('state')}>
                  <InputLabel id="state-label" className={classes.inputLabel}>
                    State*
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select labelId="state-label" label="State" {...field}>
                        <MenuItem disabled>Select State</MenuItem>
                        {states.map(state => (
                          <MenuItem
                            value={state.country_code + '-' + state.state_code}
                            key={state.state_code}
                          >
                            {state.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={'N/A'} key={'N/A'}>
                          {'N/A'}
                        </MenuItem>
                      </Select>
                    )}
                    name="state"
                    control={control}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              {mode.mode === 'create' ? 'Add' : 'Save'}
            </Button>
            <Button onClick={handleClose} color="danger">
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Company Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer>
            <GridItem md={12} style={{ margin: 'auto 0' }}>
              <div style={{ width: '100%' }}>
                <IconButton
                  style={{ float: 'right' }}
                  onClick={() => {
                    setOpen(true)
                    setMode({ mode: 'create', index: null })
                    setRfcForm(rfcObject)
                    reset(rfcForm)
                  }}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </GridItem>
            <GridItem xs={12}>
              {field.value?.length > 0
                ? field.value.map((value, id) => {
                    return (
                      <React.Fragment key={id}>
                        <GridContainer style={{ margin: 'auto' }}>
                          <GridItem md={9}>
                            <p>
                              TaxID: {value.rfc} <br />
                              Company Code: {value.companyCode} <br />
                              Name: {value.name} <br />
                              Address:{' '}
                              {(value.address1 ? value.address1 + ', ' : '') +
                                (value.address2 ? value.address1 + ', ' : '') +
                                (value.city ? value.city + ', ' : '') +
                                (value.state ? value.state + ', ' : '') +
                                (value.zipcode ? value.zipcode + ', ' : '') +
                                (value.countryIso ? value.countryIso : '')}
                            </p>
                          </GridItem>
                          <GridItem md={3} style={{ margin: 'auto' }}>
                            <div style={{ width: '100%' }}>
                              <IconButton
                                onClick={() => {
                                  setOpen(true)
                                  setMode({ mode: 'edit', index: id })
                                  setRfcForm(value)
                                  reset(rfcForm)
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  field.value.splice(id, 1)
                                  onDelete(field.value)
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </GridItem>
                        </GridContainer>
                        <Divider style={{ marginBottom: '10px' }} />
                      </React.Fragment>
                    )
                  })
                : null}
            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      {modal()}
    </div>
  )
}
