import React from 'react'

import { Badge, Box, CircularProgress, IconButton, TextField, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'
import Autocomplete from '@material-ui/lab/Autocomplete'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import { useGetSupplierAddresses } from 'hooks/useMasterData'
import { HeaderFieldsSide } from 'utils/Constants'
import { checkErrorOrWarning } from 'utils/functions'

const useStyles = makeStyles(styles)

const SupplierAddressSelectLine = props => {
  const {
    label,
    validationErrorsState,
    field,
    handleChange,
    value,
    lineId,
    supplierId,
    removeWarningLineItem,
    readOnly,
    appId
  } = props

  const classes = useStyles()

  const typeAddress =
    HeaderFieldsSide.SUPPLIER_REMIT_TO_ADDRESS.type === field ? 'remitToLocation' : 'location'

  const addresses = useGetSupplierAddresses({
    supplierId,
    params: {
      status: 'enabled',
      type: typeAddress,
      appId
    }
  })

  const validated = validationErrorsState[`${field}.${lineId}`]?.valid ?? null

  const message = validationErrorsState[`${field}.${lineId}`]?.messages

  const typeMsg = checkErrorOrWarning(message, validated)

  let tooltipArrowClass = null
  let tooltipClass = null
  let badgeClass = null

  if (typeMsg === 'warning') {
    tooltipArrowClass = classes.warningColor
    tooltipClass = classes.tooltipWarning
    badgeClass = classes.badgeWarning
  } else if (typeMsg === 'error') {
    tooltipArrowClass = classes.errorColor
    tooltipClass = classes.tooltipError
    badgeClass = classes.badgeError
  }

  function validationMessages(validationsError) {
    if (!validationsError) {
      return ''
    }

    if (Array.isArray(validationsError)) {
      return (
        <>
          {validationsError.map((message, index) => (
            <div key={index}>{message.message}</div>
          ))}
        </>
      )
    }

    const messages = validationsError.split('\n')

    return (
      <>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </>
    )
  }

  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Tooltip
        classes={{
          arrow: tooltipArrowClass,
          tooltip: tooltipClass
        }}
        title={typeMsg !== '' ? validationMessages(message) : ''}
        arrow
        placement="right"
      >
        <Badge
          classes={{
            badge: badgeClass
          }}
          badgeContent={
            typeMsg === 'warning' ? (
              <WarningIcon className={classes.warningColor}></WarningIcon>
            ) : null
          }
          invisible={typeMsg !== '' ? true : false}
        >
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              handleChange(newValue)
            }}
            id="asynchronous-demo"
            disableClearable
            style={{ width: 300 }}
            getOptionSelected={(option, value) =>
              option.supplierAddressFullAddress === value.supplierAddressFullAddress
            }
            getOptionLabel={option => option.supplierAddressFullAddress}
            options={addresses.data ?? []}
            loading={addresses.isLoading}
            disabled={readOnly}
            className={readOnly ? classes.readOnly : ''}
            renderInput={params => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                fullWidth
                multiline
                error={typeMsg === 'error' ? true : false}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {addresses.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                  classes: {
                    notchedOutline: typeMsg === 'warning' ? classes.inputWarning : ''
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: typeMsg === 'warning' ? classes.warningColor : '',
                    focused: typeMsg === 'warning' ? classes.warningColor : ''
                  }
                }}
              />
            )}
          />
        </Badge>
      </Tooltip>
      {typeMsg === 'warning' ? (
        <IconButton
          style={{ padding: '0', marginLeft: '10px' }}
          className={classes.buttonSuccess}
          onClick={() => removeWarningLineItem(field, lineId)}
        >
          <CheckCircleOutlineIcon className={classes.successColor} />
        </IconButton>
      ) : null}
    </Box>
  )
}

export default SupplierAddressSelectLine
