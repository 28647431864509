import React from 'react'
// @material-ui/core components

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle'
import { CircularProgress } from '@material-ui/core'
const useStyles = makeStyles(styles)

export function ConfirmationModal(props) {
  const classes = useStyles()
  const { showModal, onClose, title, dialogActions, dialogContent, isLoading } = props

  const bodyDialog = () => {
    if (isLoading) {
      return (
        <DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        </DialogContent>
      )
    }

    return <DialogContent>{dialogContent}</DialogContent>
  }
  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot
      }}
      open={showModal}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={() => onClose()}
    >
      <DialogTitle>{`${title}`}</DialogTitle>
      {bodyDialog()}
      <DialogActions className={classes.modalFooter}>{dialogActions}</DialogActions>
    </Dialog>
  )
}
