import React from 'react'
import PropTypes from 'prop-types'
import styles from 'assets/jss/material-dashboard-pro-react/components/emailModalStyle'
import { makeStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(styles)

export function ReapplyMappingsModal (props) {
  const classes = useStyles()
  const { onClose, open, reapplyMappings } = props

  const handleClose = () => {
    onClose()
  }

  const submit = () => {
    reapplyMappings()
    handleClose()
  }

  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot
      }}
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Reapply Mappings</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        Do you want to reapply mappings to the selected invoices?
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.colorPrimary}
          color="primary"
          variant="outlined"
          onClick={() => submit()}
        >
          Reapply Mappings
        </Button>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ReapplyMappingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reapplyMappings: PropTypes.func.isRequired
}
