import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import MaterialTable from 'material-table'
import moment from 'moment'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import EmailIcon from '@material-ui/icons/Email'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import TablePagination from '@material-ui/core/TablePagination'
import { TablePaginationActions } from 'components/Invoice/TablePaginationActions'

// Constants
import { invoiceStatus } from 'utils/Constants'

// functions
import { statusColor } from 'utils/functions'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceStyle.js'

import { useLocalStorageState } from 'hooks/useLocalStorage.js'

import { formatDate } from 'utils/functions.js'
import { useGetTenantConfig } from 'hooks/useTenantConfig.js'

import { useGetEmails } from 'hooks/useEmails.js'

const useStyles = makeStyles(styles)

const dictionaryColums = {
  'No.': false,
  rk: false,
  subject: false,
  fromEmail: false,
  toEmail: false,
  receivedDate: false,
  processingDate: false,
  status: false,
  attachments: false,
  process: false
}

const filters = ['new', 'rejected', 'completed']

export default function EmailsView() {
  /*  const { initialPageInvoice, setInitialPageInvoice } = useContext(
    InvoiceContext
  ) */
  let history = useHistory()
  const classes = useStyles()
  const [createError, setCreateError] = useState({ isOpen: false, message: '' })
  const [status, setStatus] = useState(filters)
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPage: 10,
    lastPage: -1,
    lastEvaluatedKeys: {}
  })
  // const [pageSize, setPageSize] = useLocalStorageState('invoice.pageSize', 5)
  const [selectText, setSelectText] = useState('Deselect All')
  const [userColumns, setUserColumns] = useLocalStorageState(
    'invoice.emails',
    dictionaryColums
  )

  const {
    data: configTenant,
    isLoading: isLoadingTenantConfig
  } = useGetTenantConfig()

  const {
    data: emails,
    isLoading,
    error,
    isError,
    refetch,
    isFetching
  } = useGetEmails(status, configTenant, pagination)

  useEffect(() => {
    if (!isError) {
      return
    }
    setCreateError({
      isOpen: true,
      message:
        error.response?.data?.message || 'Something went wrong, try again later'
    })
  }, [isError, error])

  useEffect(() => {
    if (!emails) {
      return
    }

    if (!emails?.lastEvaluatedKey) {
      setPagination(old => {
        return {
          ...old,
          lastPage: old.page
        }
      })
      return
    }

    setPagination(old => {
      return {
        ...old,
        lastEvaluatedKeys: {
          ...old.lastEvaluatedKeys,
          [old.page]: emails?.lastEvaluatedKey
        }
      }
    })
  }, [emails])

  let breadcrumbViews = [
    {
      name: 'Emails',
      url: `/admin/invoiceAI/emails`,
      icon: EmailIcon
    }
  ]

  const actions = [
    {
      icon: () => <MoreHorizIcon style={{ color: '#081c3e' }} />,
      tooltip: 'Details',
      onClick: (event, rowData) => {
        history.push(`/admin/invoiceAI/email/${rowData.rk}/details`)
      }
    }
  ]

  const columns = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1,
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['No.'],
      align: 'center',
      sorting: false
    },
    {
      field: 'rk',
      title: 'Relish Id',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['rk'],
      align: 'center'
    },
    {
      field: 'receivedDate',
      title: 'Received Date',
      render: rowData =>
        formatDate({
          date: rowData.receivedDate,
          options: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h23'
          }
        }),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['receivedDate'],
      align: 'center',
      customSort: (a, b) => {
        return moment(moment(b.receivedDate)).isBefore(moment(a.receivedDate))
          ? 1
          : -1
      }
    },
    {
      field: 'processingDate',
      title: 'Processing Date',
      render: rowData =>
        formatDate({
          date: rowData.processingDate,
          options: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h23'
          }
        }),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['processingDate'],
      align: 'center',
      customSort: (a, b) => {
        return moment(moment(b.processingDate)).isBefore(
          moment(a.processingDate)
        )
          ? 1
          : -1
      }
    },
    {
      field: 'fromEmail',
      title: 'From',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['fromEmail'],
      align: 'center'
    },
    {
      field: 'toEmail',
      title: 'To',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['toEmail'],
      align: 'center'
    },
    {
      field: 'subject',
      title: 'Subject',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['subject'],
      align: 'center'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: statusColor(rowData.status) }}>
          {invoiceStatus[rowData.status]}
        </p>
      ),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['status'],
      align: 'center'
    },
    {
      field: 'attachments',
      title: 'Attachments',
      render: rowData => (rowData.attachments ? rowData.attachments.length : 0),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['attachments'],
      align: 'center'
    }
  ]

  function hideColumns(column, hidden) {
    const configCopy = { ...userColumns }
    configCopy[column.field] = hidden
    setUserColumns(configCopy)
  }

  async function onSearchInvoices(newStatus) {
    let statusArray = [...status]
    if (newStatus === 'all') {
      if (statusArray.length === 3) {
        statusArray = []
        setSelectText('Select All')
      } else {
        statusArray = filters
        setSelectText('Deselect All')
      }
    } else {
      let selected = statusArray.findIndex(x => x === newStatus)
      if (selected !== -1) {
        statusArray.splice(selected, 1)
      } else {
        statusArray.push(newStatus)
        statusArray = [...new Set(statusArray)]
      }
      if (statusArray.length === 3) {
        setSelectText('Deselect All')
      } else {
        setSelectText('Select All')
      }
    }
    setStatus(statusArray)
    setPagination({
      page: 0,
      rowsPage: pagination.rowsPage,
      lastEvaluatedKeys: []
    })
  }

  const handleChangePage = (event, newPage) => {
    setPagination(old => {
      return {
        ...old,
        page: newPage
      }
    })
  }

  function returnInvoicesTab() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12}>
            <Button
              style={{
                maxWidth: '40px',
                maxHeight: '30px',
                minWidth: '40px',
                minHeight: '30px'
              }}
              size="small"
              className={
                classes.floatRight +
                ' ' +
                classes.clearAvatar +
                ' ' +
                classes.colorPrimary
              }
              onClick={() => refetch({ throwOnError: true })}
            >
              <RefreshRoundedIcon />
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{
                width: '100%',
                margin: '0 auto 10px'
              }}
            >
              <div className={classes.statusButton} style={{ width: '100px' }}>
                <div>
                  <Fab
                    size="small"
                    className={
                      status.find(x => x === 'new')
                        ? classes.defaultColorFilled
                        : classes.defaultColor
                    }
                    onClick={() => {
                      onSearchInvoices('new')
                    }}
                  >
                    <AddIcon />
                  </Fab>
                  <span className={classes.statusText}>New</span>
                </div>
              </div>

              <div className={classes.statusButton} style={{ width: '145px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('completed')
                    }}
                    className={
                      status.find(x => x === 'completed')
                        ? classes.successColorFilled
                        : classes.successColor
                    }
                  >
                    <CheckIcon />
                  </Fab>
                  <span className={classes.statusText}>Completed</span>
                </div>
              </div>
              <div className={classes.statusButton} style={{ width: '130px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('rejected')
                    }}
                    className={
                      status.find(x => x === 'rejected')
                        ? classes.dangerColorFilled
                        : classes.dangerColor
                    }
                  >
                    <CloseIcon />
                  </Fab>
                  <span className={classes.statusText}>Rejected</span>
                </div>
              </div>
              <div
                className={classes.statusButton}
                style={{ width: '115px', border: 'none' }}
              >
                <div>
                  <Button
                    size="small"
                    onClick={() => {
                      onSearchInvoices('all')
                    }}
                    style={{ color: '#999' }}
                  >
                    {selectText}
                  </Button>
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justifyContent="center" style={{ marginTop: '5px' }}>
              <SyncLoader showLoader={isFetching && !isLoading} />
              {isLoading || isLoadingTenantConfig ? (
                <div className={classes.reloadSize}>
                  <CircularProgress />
                </div>
              ) : (
                <GridItem xs={12} sm={12} md={12}>
                  <MaterialTable
                    columns={columns}
                    data={emails?.rows ?? []}
                    actions={actions}
                    onChangeColumnHidden={hideColumns}
                    // onChangePage={page => setInitialPageInvoice(page)}
                    // onChangeRowsPerPage={pageSize => setPageSize(pageSize)}
                    options={{
                      columnsButton: true,
                      actionsColumnIndex: -1,
                      filtering: false,
                      sorting: true,
                      showTitle: false,
                      emptyRowsWhenPaging: false,
                      pageSize: pagination.rowsPage,
                      pageSizeOptions: [10, 20, 30, 40, 50]
                      // pageSize: pageSize,
                      // initialPage: initialPageInvoice
                    }}
                    onChangeRowsPerPage={pageSize => {
                      setPagination({
                        page: 0,
                        rowsPage: pageSize,
                        lastEvaluatedKeys: []
                      })
                    }}
                    components={{
                      Pagination: props => {
                        return (
                          <TablePagination
                            {...props}
                            onChangePage={handleChangePage}
                            ActionsComponent={subProps => (
                              <TablePaginationActions
                                {...subProps}
                                pagination={pagination}
                              />
                            )}
                          />
                        )
                      }
                    }}
                  />
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          <Card>
            <CardHeader>
              <h3 className={classes.formHeader}>Emails</h3>
            </CardHeader>
            <CardBody>{returnInvoicesTab()}</CardBody>
          </Card>
        </GridItem>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={createError.message}
          open={createError.isOpen}
          closeNotification={() =>
            setCreateError({ isOpen: false, message: '' })
          }
          close
        />
      </GridContainer>
    </Fade>
  )
}
