import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getTenantConfigInvoicesEndPoint } from 'services/apiApps'
import { getAppConfigurationByAppIdQuery } from 'services/apiInvoices'
import { getInvoiceProcessByTenant } from 'services/apiInvoicesConf'
import { getTenantV2 } from 'services/apiTenants'
import { ApiAdminInvoice } from 'utils/Constants'
import { getUserTenant } from 'utils/functions'

export const configTenantKey = 'invoicesConfigTenant'

function createHeaders() {
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`
  }

  return headers
}

async function patchTenantConfigDefaultApp(tenant, defaultApp) {
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/configurations/defaultApp/${defaultApp}`

  let requestHeaders = createHeaders()

  return axios({
    method: 'patch',
    url: url,
    headers: requestHeaders
  })
}

export async function putTenantConfigInvoicesEndPoint(tenant, newTenantConfig) {
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/configurations`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: newTenantConfig
  })
}

export async function postTenantConfigForInvoicesQuery(company, tenantConfig) {
  tenantConfig.tenantId = company
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${company}/configurations`
  let requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: tenantConfig
  })
}

export function useGetTenantConfig(props = {}) {
  const tenantId = getUserTenant()
  return useQuery(
    [configTenantKey, tenantId],
    () =>
      getTenantConfigInvoicesEndPoint(tenantId).then(result => {
        return {
          ...result.data,
          mappedRoles: result.data.mappedRoles || [],
          userIntegration: result.data.userIntegration || {}
        }
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...props
    }
  )
}

export function useCreateTenantConfig() {
  const company = getUserTenant()
  return useMutation(tenantConfig => postTenantConfigForInvoicesQuery(company, tenantConfig), {
    onMutate: async newDefaultApp => {}
  })
}

export function useUpdateTenantConfig() {
  const tenantId = getUserTenant()
  // const queryClient = useQueryClient()
  return useMutation(newTenant => putTenantConfigInvoicesEndPoint(tenantId, newTenant), {
    onMutate: async newDefaultApp => {}
  })
}

export function useUpdateTenantConfigDefaultApp() {
  const tenantId = getUserTenant()
  const queryClient = useQueryClient()
  return useMutation(defaultApp => patchTenantConfigDefaultApp(tenantId, defaultApp), {
    onMutate: async newDefaultApp => {
      await queryClient.cancelQueries([configTenantKey, tenantId])

      // Snapshot the previous value
      const previousTenant = queryClient.getQueryData([configTenantKey, tenantId])

      if (!previousTenant) {
        return
      }

      const configUpdated = {
        ...previousTenant,
        defaultApp: newDefaultApp
      }

      // Optimistically update to the new value
      queryClient.setQueryData([configTenantKey, tenantId], configUpdated)

      // Return a rollback function
      return () => {
        queryClient.setQueryData([configTenantKey, tenantId], previousTenant)
      }
    }
  })
}

export function useGeInvoicesAiTenantConfig(app, appId) {
  return useQuery(
    ['Apps', app, 'configurations', appId],
    () => getAppConfigurationByAppIdQuery(app, appId).then(result => result.data),
    {
      enabled: Boolean(appId),
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )
}

export function useGetInvoiceProcessByTenant(tenantId) {
  return useQuery(['processConfig', tenantId], () => getInvoiceProcessByTenant(), {
    enabled: Boolean(tenantId)
  })
}

export function useGetTenant() {
  const tenantId = getUserTenant()
  return useQuery(['tenant', tenantId], () => getTenantV2(tenantId), {
    enabled: Boolean(tenantId)
  })
}
