import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { validateProperty, validateObject } from 'utils/validator'
import { supportTicketCreate as supportTicketCreateSchema } from 'models/supportTicketModel'
import { getSimpleListOfTenants, getSimpleListOfApps } from 'services/apiTenants'
import { getListOfUsersByTenantSimple } from 'services/apiUsers'
import {
  getAppConfigurationsByApp,
  getInvoiceConfigurationsByApp
} from 'services/apiApps'

import CreateView from './CreateView'
import { getUserInfo, getUserTenant, showLoading, hideLoading } from 'utils/functions'

let _isMounted = false

const newTicket = {
  user: '',
  title: '',
  description: '',
  priority: '',
  ticketId: '',
  appId: '',
  instance: '',
  status: 'Opened',
  tenantId: '',
  associatedUser: {},
  creationUser: {},
  attachments: []
}

const newTicketState = {
  user: '',
  title: '',
  description: '',
  priority: '',
  ticketId: '',
  appId: '',
  instance: '',
  status: 'Opened',
  tenantId: '',
  associatedUser: {},
  creationUser: {},
  attachments: []
}

async function preRefillData(props) {
  const userTenant = getUserTenant()
  await getUsercreatorInfo(props)
  await getTenantList(props, userTenant)
  await getUsersByTenant(props, userTenant)
  await getAppList(props, userTenant)
  hideLoading(props.setPageIsLoading)
}

async function getUsercreatorInfo(props) {
  const info = getUserInfo()
  const user = {
    name: info.name,
    id: info.sub,
    email: info.email,
    tenantId: info.metadata.tenantId
  }
  props.ticket.user = info.sub
  props.ticket.creationUser = user
  props.ticket.associatedUser = user
  props.setTicket(props.ticket)
}

async function getTenantList(props, userTenant) {
  let result = await getSimpleListOfTenants()
  props.ticket.tenantId = userTenant
  props.setTicket(props.ticket)
  if (_isMounted && result.success) {
    props.setTenantList(result.data.filter(t => { return t.enabled }))
  }
}

async function getAppList(props, tenantId) {
  props.setAppsAreLoading(true)
  let result = await getSimpleListOfApps(tenantId)
  if(result.success){
    props.setAppsList(result.data.filter(t => { return t.enabled }))
  }
  props.setAppsAreLoading(false)
}

async function getConfigurationsForApp(props, appId) {
  props.setInstancesList([])
  props.setInstancesAreLoading(true)
  let result =
  appId === 'invoiceAI'
      ? await getInvoiceConfigurationsByApp()
      : await getAppConfigurationsByApp(appId)
  if (result.success) {
    if (Array.isArray(result.data) && result.data.length > 0) {
      const instances = result.data
      props.setInstancesList(instances)
    } else {
      const instances = result.data.appList
      props.setInstancesList(instances)
    }
  }
  props.setInstancesAreLoading(false)
}

async function getUsersByTenant(props, tenantId) {
  props.setUsersAreLoading(true)
  let result = await getListOfUsersByTenantSimple(tenantId);
  if(result.success){
    props.setUsersList(result.data.filter(t => { return t.enabled }))
  }
  props.setUsersAreLoading(false)
}

async function setInitialTicket(props) {
  Object.keys(newTicket).forEach(key => {
    props.ticket[key] = newTicket[key]
  })

  Object.keys(newTicketState).forEach(key => {
    props.ticketState[key] = newTicketState[key]
  })
  props.setTicket(props.ticket)
  props.setTicketState(props.ticketState)
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name
    }),
    {}
  ),
  withState('ticket', 'setTicket', {}),
  withState('ticketState', 'setTicketState', {}),
  withState('attachments', 'setAttachments', []),
  withState('tenantList', 'setTenantList', []),
  withState('usersList', 'setUsersList', []),
  withState('appsList', 'setAppsList', []),
  withState('instancesList', 'setInstancesList', []),
  withState('createSuccess', 'setCreateSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('isLoadingSave', 'setIsLoadingSave', false),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('usersAreLoading', 'setUsersAreLoading', false),
  withState('appsAreLoading', 'setAppsAreLoading', false),
  withState('instancesAreLoading', 'setInstancesAreLoading', false),
  withHandlers({
    onFieldChange: props => (field, value) => {
      props.ticket[field] = value
      let isValid = validateProperty(
        supportTicketCreateSchema,
        props.ticket,
        field
      ).isValid
      if (isValid) {
        props.ticketState[field] = 'success'
      } else {
        props.ticketState[field] = 'error'
      }
      props.setTicketState(props.ticketState)
      props.setTicket(props.ticket)
    },
    onSupportTicketCreate: props => async (createTicketMutation) => {
      props.setIsLoadingSave(true)

      let validation = validateObject(supportTicketCreateSchema, props.ticket)
      if (validation.isValid) {
        showLoading()
        await createTicketMutation(props.ticket)
      } else {
        Object.keys(validation.errors).forEach(field => {
          props.ticketState[field] = 'error'
        })
        props.setValidationMessage('Complete the form with valid information.')
        props.setCreateError(true)
        props.setTicketState(props.ticketState)
      }
      props.setIsLoadingSave(false)
    },
    onChangeAppOption: props => async (appId) => {
      props.ticket.instance = ''
      props.setTicket(props.ticket)
      getConfigurationsForApp(props, appId)
    },
    onChangeTenantOption: props => async (tenantId)  => {
      await getUsersByTenant(props, tenantId)
      await getAppList(props, tenantId)
    }
  }),
  lifecycle({
    componentDidMount() {
      showLoading(this.props.setPageIsLoading)
      _isMounted = true
      setInitialTicket(this.props)
      preRefillData(this.props)
      this.props.setCreateSuccess(false)
    },
  })
)(CreateView)
