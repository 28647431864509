import React /*, { useState }*/ from 'react'
//import Cancel from '@material-ui/icons/Cancel'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
//import Tooltip from '@material-ui/core/Tooltip'
//import CheckBox from '@material-ui/icons/CheckBox'
import Computer from '@material-ui/icons/Computer'
import Link from '@material-ui/icons/Link'

// Core

import Button from '@material-ui/core/Button'

// Icon

//Style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/widgetFormStyle.js'

import { makeStyles } from '@material-ui/core/styles'
// import like this

let currentWindow = undefined

const useStyles = makeStyles(styles)

export default function ExternalLogin(props) {
  function checkUser(openWindow) {
    if (!window.localStorage.getItem('externalLogin'))
      setTimeout(async () => {
        checkUser(openWindow)
      }, 500)
    else {
      if (openWindow) {
        const success = window.localStorage.getItem('MSResponseStatus') === 'true'
        const message = window.localStorage.getItem('MSResponseMessage')

        window.localStorage.removeItem('MSResponseMessage')
        window.localStorage.removeItem('MSResponseStatus')
        openWindow.close()
        if (props.onLogin) props.onLogin(success, message)
      }
    }
  }
  const classes = useStyles()

  //const [teamsConnectedMessage] = useState(false)
  //const [teamsConnectionTesting] = useState(false)

  return (
    <GridContainer justify="flex-end">
      <GridItem item xs={6} md={4}>
        <GridItem xs={12} sm={12}>
          <Button
            style={{
              width: '90%',
              margin: 'auto',
              color: '#1769ff',
              borderRadius: '12px',
              fontSize: '15px'
            }}
            className={classes.documentation}
            disabled={props.disabled ? true : false}
            onClick={() => {
              const redirectUrl = window.location.origin + '/admin/nitorConnect/login-end'
              window.localStorage.setItem('MSScope', props.scope)
              window.localStorage.setItem('MSApp', props.app)
              window.localStorage.setItem('SelectedTenant', props.client)
              window.localStorage.setItem('SelectedAppId', props.environment)
              window.localStorage.setItem('MSRedirect', redirectUrl)
              let scope = props.scope ? props.scope.join('%20') : ''
              let url = ''
              url += `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${props.clientId}`
              url += `&response_type=${
                props.responseType ? props.responseType : 'code'
              }&redirect_uri=${redirectUrl}`
              url += `&response_mode=${props.responseMode ? props.responseMode : 'query'}`
              url += `&scope=${scope}`
              url += props.nonce ? `&nonce=${props.nonce}` : ''
              url += `&prompt=${props.prompt ? props.prompt : 'login'}`

              window.localStorage.removeItem('externalLogin')
              if (currentWindow) currentWindow.close()
              const h = 570
              const w = 520
              var left = document.documentElement.clientWidth / 2 - w / 2
              var top = document.documentElement.clientHeight / 2 - h / 2
              currentWindow = window.open(
                url,
                'Login',
                'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
                  w +
                  ', height=' +
                  h +
                  ', top=' +
                  top +
                  ', left=' +
                  left
              )

              checkUser(currentWindow)
            }}
          >
            {props.hasRefreshToken ? (
              <Computer className={classes.icons} />
            ) : (
              <Link className={classes.icons} />
            )}
            {props.hasRefreshToken ? 'Connected with Microsoft' : 'Connect with Microsoft'}
          </Button>
        </GridItem>
        {/*<GridItem xs={12} sm={12}>
          <Tooltip title="Check connection to Teams">
            <Button
              className={classes.documentation}
              style={{
                width: '90%',
                margin: 'auto',
                background: '#3b5998',
                borderRadius: '12px',
                color: 'white',
                fontSize: '15px'
              }}
              disabled={props.disabled}
              color={
                teamsConnected !== undefined
                  ? teamsConnected
                    ? 'info'
                    : 'danger'
                  : 'facebook'
              }
              onClick={() => {}}
              round
            >
              {teamsConnected !== undefined ? (
                teamsConnected ? (
                  <div>
                    <CheckBox style={{ fontSize: '15px' }} /> Connected
                  </div>
                ) : (
                  <div>
                    <Cancel style={{ fontSize: '15px' }} /> Connection Error
                  </div>
                )
              ) : (
                <div>
                  {teamsConnectionTesting ? '...' : 'Test Connectivity'}{' '}
                </div>
              )}
            </Button>
          </Tooltip>
        </GridItem>
        <GridItem xs={12} sm={12}>
          {teamsConnectedMessage ? (
            <Tooltip title="View connection error details">
              <Button
                className={classes.documentation}
                style={{
                  width: '90%',
                  margin: 'auto',
                  background: 'red',
                  borderRadius: '12px',
                  color: 'white',
                  fontSize: '15px'
                }}
                onClick={() => {}}
              >
                Error details
              </Button>
            </Tooltip>
          ) : null}
        </GridItem>*/}
      </GridItem>
    </GridContainer>
  )
}
