import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { useQueryClient } from 'react-query'
// Material UI Components
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'

// Components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Snackbar from 'components/Snackbar/Snackbar'
import MasterDataForm from './MasterDataForm'

// Icons
import AssignmentIcon from '@material-ui/icons/Assignment'
import AddAlert from '@material-ui/icons/AddAlert'

// Hooks
import {
  useGetValidationRule,
  useSaveValidationRule
} from 'hooks/useValidationRules'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceStyle.js'
import SyncLoader from 'components/SyncLoader/SyncLoader'

const useStyles = makeStyles(styles)

const action = 'edit'

export default function EditMasterDataRules() {
  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { appId, ruleId } = useParams()
  const [createError, setCreateError] = useState({ isOpen: false, message: '' })

  const breadcrumbViews = [
    {
      name: 'Validation Rules',
      url: '/admin/invoiceAi/validationRules',
      icon: AssignmentIcon
    },
    {
      name: `Master Data Validation Rules`,
      url: `/admin/invoiceAi/${appId}/masterDataValidationRules/${ruleId}/${action}`,
      icon: AssignmentIcon
    }
  ]

  const {
    data: validationRule,
    isLoading,
    isError: isErrorVR,
    error,
    isFetching
  } = useGetValidationRule(appId, 'masterData', ruleId)

  const saveValidationRule = useSaveValidationRule(appId, 'masterData', ruleId)

  useEffect(() => {
    if (isErrorVR || saveValidationRule.isError) {
      setCreateError({
        message: 'Something went wrong, try again later',
        isOpen: true
      })
    }
  }, [isErrorVR, error, saveValidationRule.isError, setCreateError])

  function onSubmit(data) {
    saveValidationRule.mutate(data, {
      onSuccess: () => {
        history.push('/admin/invoiceAi/validationRules')
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          'validationRule',
          appId,
          'masterData',
          ruleId
        ])
        queryClient.invalidateQueries(['validationRule', appId, 'masterData'])
      },
      onError: error => {
        setCreateError({
          isOpen: true,
          message:
            error.response?.data?.message ??
            'Something went wrong, try again later',
          color: 'danger'
        })
      }
    })
  }

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader
              title={`Master Data Validation Rules`}
              titleTypographyProps={{
                style: {
                  textAlign: 'center',
                  fontSize: '1.825em',
                  fontWeight: '300'
                }
              }}
            />
            <CardContent style={{ padding: '25px' }}>
              <SyncLoader showLoader={isFetching && !isLoading} />
              {!isLoading ? (
                <MasterDataForm
                  defaultValues={validationRule}
                  onSubmitForm={onSubmit}
                />
              ) : (
                <div className={classes.reloadSize}>
                  <CircularProgress />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '' })}
        close
      />
    </>
  )
}
