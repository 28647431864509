const supportTicketCreate = {
    tenantId: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 256
        },
        displayName: "Tenant"
    },
    user: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 256
        },
        displayName: "User"
    },
    title: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 256
        },
        displayName: "Title"
    },
    description: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 1024
        },
        displayName: "Description"
    },
    priority: {
        type: "string",
        required: true,
        displayName: "Priority"
    },
    appId: {
        type: "string",
        required: true, 
        displayName: "Application"
    },
    instance: {
        type: "string",
        required: true, 
        displayName: "Instance"
    }
}

export {
    supportTicketCreate
};
