import React from 'react'

// Assets
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'

// Components
import { Divider, Grid, makeStyles } from '@material-ui/core'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardText from 'components/Card/CardText'
import DnbDaSubCardV2App from './subCardV2App'

const useStyles = makeStyles({
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: '#FFFFFF',
    marginTop: '0'
  }
})

export default function DnbDaCardV2App({ cardName, cardValue }) {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader>
        <Grid container>
          <Grid item xs={6}>
            <CardText style={{ backgroundColor: '#81abcd', padding: '5px 10px' }}>
              <h4 className={classes.cardTitleWhite}>{cardName}</h4>
            </CardText>
          </Grid>
        </Grid>
      </CardHeader>
      <CardBody>
        <Grid container>
          {Object.entries(cardValue).map(([propName, propValue], index) => (
            <>
              <Grid item xs={12} key={propName}>
                {!!index && <Divider style={{ margin: '10px' }} />}
                <Grid container alignItems="center">
                  <Grid item xs={12} md={3}>
                    {propName}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Card>
                      <CardBody>
                        <DnbDaSubCardV2App cardValue={propValue} />
                      </CardBody>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
      </CardBody>
    </Card>
  )
}
