import React, { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import { CircularProgress } from '@material-ui/core'

// @material-ui/icons

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from '@material-ui/core/Button'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

// Constants

import { useGetAppConfigurationById } from 'hooks/useApp'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import { Select, MenuItem } from '@material-ui/core'
import {
  useGetTenantProcess,
  useGetApiOriginConfigurationById,
  useCreateApiOriginConfiguration,
  useUpdateApiOriginConfiguration
} from 'hooks/useInvoiceOrigin.js'
import CustomInput from 'components/CustomInput/CustomInput'
const useStyles = makeStyles(styles)
const useStyles2 = makeStyles(styles2)

export default function ApiConfigurationForm(props) {
  const classes = useStyles()
  const classes2 = useStyles2()
  const { setUserInfo } = props
  const [processForEmail, setProcessForEmail] = useState([])
  const [defaultProcess, setDefaultProcess] = useState('')
  const app = useGetAppConfigurationById(props.appId)
  const processByTenantApp = useGetTenantProcess(props.appId)
  const apiConfigById = useGetApiOriginConfigurationById(props.id, props.appId)
  const createApiConfig = useCreateApiOriginConfiguration()
  const updateApiConfig = useUpdateApiOriginConfiguration()
  const queryClient = useQueryClient()
  const [apiUrl, setApiUrl] = useState('')

  let invoiceProcess = []
  let defaultProcessOptions = []
  if (!processByTenantApp.isLoading && processByTenantApp.data?.processConfig) {
    invoiceProcess = processByTenantApp.data?.processConfig
  }
  if (processForEmail.length > 0 && processByTenantApp.data?.processConfig) {
    // eslint-disable-next-line
    for (const process of processForEmail) {
      const processOption = invoiceProcess.filter(x => x.id === process)
      if (processOption) {
        defaultProcessOptions.push(...processOption)
      }
    }
  }

  useEffect(() => {
    if (apiConfigById.data) {
      setProcessForEmail(apiConfigById.data.process)
      setDefaultProcess(apiConfigById.data.defaultProcess)
      setApiUrl(apiConfigById.data.url)
      setUserInfo({
        createdBy: apiConfigById.data.createdBy,
        updatedBy: apiConfigById.data.updatedBy
      })
    }
  }, [apiConfigById.data, setUserInfo])

  const handleChangeProcess = event => {
    setProcessForEmail([...event.target.value])
  }

  const handleChangeDefaultProcess = event => {
    const defaultProcess = event.target.value
    setDefaultProcess(defaultProcess)
  }

  const onCreateConfig = data => {
    createApiConfig.mutate(
      { data },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['invoiceOrigin', 'all', props.appId])
        },
        onSuccess: response => {
          props.showValidationMessage('success', 'Saved')
          onClose()
        },
        onError: error => {
          props.showValidationMessage(
            'error',
            error.response?.data?.message ?? 'Something went wrong, try again later'
          )
        }
      }
    )
  }
  const onUpdateConfig = data => {
    updateApiConfig.mutate(
      { data },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['invoiceOrigin', 'all', props.appId])
        },
        onSuccess: response => {
          props.showValidationMessage('success', 'Saved')
          onClose()
        },
        onError: error => {
          props.showValidationMessage(
            'error',
            error.response?.data?.message ?? 'Something went wrong, try again later'
          )
        }
      }
    )
  }

  function validateBeforeSave() {
    let data = {
      appId: props.appId,
      process: processForEmail,
      defaultProcess: defaultProcess,
      api: props.apiType,
      url: apiUrl
    }
    if (props.action === 'edit') {
      data = {
        appId: props.appId,
        id: props?.id ?? '',
        process: processForEmail,
        defaultProcess: defaultProcess,
        url: apiUrl
      }
    }

    onSaveHandler(data)
  }

  const onSaveHandler = data => {
    setUserInfo(null)
    if (props.action === 'create') {
      onCreateConfig(data)
    } else {
      onUpdateConfig({ id: data.id, data })
    }
  }
  const disabledButton =
    processForEmail.length === 0 ||
    !defaultProcess ||
    (props.apiType === 'peppolNetwork' && !apiUrl)
  const onChangeValueText = value => {
    setApiUrl(value)
  }

  const onClose = () => {
    setUserInfo(null)
    props.onClose()
  }

  function returnTabsToDisplay() {
    return (
      <>
        <GridContainer
          style={{ margin: 'auto' }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <GridItem xs={6}>
            <div className={classes2.verticalAlign}>
              <FormLabel id={'process'} className={classes2.inputLabel}>
                Process
              </FormLabel>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
                Process*
              </InputLabel>
              <Select
                labelId="multiple-select"
                id="multiple-select"
                multiple
                value={processForEmail}
                onChange={handleChangeProcess}
                input={<Input />}
                inputProps={{
                  name: `process`,
                  id: `process`
                }}
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Select the process
                </MenuItem>
                {invoiceProcess.map(process => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={process.id}
                    value={process.id}
                  >
                    {process.processName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{ margin: 'auto' }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <GridItem xs={6}>
            <div className={classes2.verticalAlign}>
              <FormLabel id={'defaultProcess'} className={classes2.inputLabel}>
                Default Process
              </FormLabel>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel className={classes.selectLabel}>Default Process*</InputLabel>
              <Select
                value={defaultProcess}
                onChange={handleChangeDefaultProcess}
                inputProps={{
                  name: `defaultProcess`,
                  id: `defaultProcess`
                }}
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Select the default process
                </MenuItem>
                {defaultProcessOptions.map(process => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={process.id}
                    value={process.id}
                  >
                    {process.processName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>

        <GridContainer
          style={{ margin: 'auto' }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <GridItem xs={6}>
            <div className={classes2.verticalAlign}>
              <FormLabel id={'apiOriginUrl'} className={classes2.inputLabel}>
                URL
              </FormLabel>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <CustomInput
              labelText={<span>'Write here'</span>}
              id="custom-input"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: false,
                onChange: event => {
                  let value = event.target.value
                  if (!value || value === '') {
                    value = undefined
                  }
                  onChangeValueText(value)
                },
                value: apiUrl
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{ marginTop: '20px' }}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <GridItem>
            <Button
              style={{ marginRight: '10px' }}
              disabled={disabledButton}
              variant="contained"
              color="primary"
              onClick={() => validateBeforeSave()}
            >
              Save
            </Button>
            <Button variant="contained" color="secondary" onClick={() => onClose()}>
              Cancel
            </Button>
          </GridItem>
        </GridContainer>
      </>
    )
  }
  if (
    app.isError ||
    processByTenantApp.isError ||
    apiConfigById.isError ||
    createApiConfig.isError
  ) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Something went wrong, try again later</p>
      </div>
    )
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {app.isLoading ||
        processByTenantApp.isLoading ||
        apiConfigById.isLoading ||
        apiConfigById.isFetching ||
        createApiConfig.isLoading ? (
          <GridContainer justifyContent="center" alignItems="center">
            <div>
              <CircularProgress color="inherit" />
            </div>
          </GridContainer>
        ) : (
          returnTabsToDisplay()
        )}
      </GridItem>
    </GridContainer>
  )
}
