import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'
import AddAlert from '@material-ui/icons/AddAlert'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import DescriptionIcon from '@material-ui/icons/Description'
import ErrorIcon from '@material-ui/icons/Error'
import InboxIcon from '@material-ui/icons/Inbox'
import Alert from '@material-ui/lab/Alert'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import AdminNavbarLinks from 'components/Navbars/Admin/AdminNavbarLinks'
import Snackbar from 'components/Snackbar/Snackbar'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import {
  fetchInvoiceById,
  useGetPreviousAndNextInvoice,
  useUpdateStatusInvoice
} from 'hooks/useInvoices'

import { fetchInvoiceTextract, useGetFileInformation, useGetTextOcr } from 'hooks/useOcrInvoice'
import { useGeInvoicesAiTenantConfig, useGetTenantConfig } from 'hooks/useTenantConfig'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { signOut } from 'store/AuthState'

import { ExceptionFields as ExceptionFieldsRO } from '../OcrInvoiceExceptionROnly/ExceptionFields'
import { ExceptionDocument } from './ExceptionDocument'
import { ExceptionFields } from './ExceptionFields'

import Grid from '@material-ui/core/Grid'
import { useGetConnectivityConfiguration } from 'hooks/useInvoiceConfig'
import { useGetValidationRuleFields } from 'hooks/useValidationRules'
//import { useInterval } from 'hooks/utis'

const useStylesContainer = makeStyles(theme => ({
  root: {
    height: '100vh'
  }
}))

const useStylesGridItemLeft = makeStyles(theme => ({
  root: {
    width: '50%',
    maxHeight: '100vh'
  }
}))

const useStylesGridItemRight = makeStyles(theme => ({
  root: {
    width: '50%',
    maxHeight: '100vh',
    borderLeft: '3px solid rgb(0, 0, 0, 0.14)',
    backgroundColor: 'white',
    overflow: 'auto'
  }
}))

const useStylesGridItemRightHeader = makeStyles(theme => ({
  root: {
    padding: '2px'
  }
}))
const useStylesGridItemRightBody = makeStyles(theme => ({
  root: {
    padding: '0 5px 5px 5px'
  }
}))

const useStylesAlert = makeStyles({
  myAlert: {
    color: '#000000',
    backgroundColor: '#f3bc07'
  }
})

function isTaxLineEnable(obj) {
  return obj && 'taxAtLine' in obj && 'enabled' in obj && obj.taxAtLine && obj.enabled
}

function isChargesLineEnable(obj) {
  return obj && 'chargeAtLine' in obj && obj.chargeAtLine && 'enabled' in obj && obj.enabled
}

function getInfoTaxChargeMessage(isTaxEnabled, isChargeEnabled) {
  if (isTaxEnabled && isChargeEnabled) {
    return 'Taxes and Charges at line level not supported on this version.'
  }

  if (isTaxEnabled) {
    return 'Taxes at line level not supported on this version.'
  }

  if (isChargeEnabled) {
    return 'Charges at line level not supported on this version.'
  }

  return ''
}

export default function ExceptionViewOcr(props) {
  const { invoiceFetch, processList } = props
  const classesContainer = useStylesContainer()
  const classesAlert = useStylesAlert()
  const dispatch = useDispatch()
  const classesGridItemLeft = useStylesGridItemLeft()
  const classesGridItemRight = useStylesGridItemRight()
  const classesGridItemRightHeader = useStylesGridItemRightHeader()
  const classesGridItemRightBody = useStylesGridItemRightBody()
  const { invoiceId } = useParams()
  const history = useHistory()
  const queryClient = useQueryClient()

  const [pageNumber, setPageNumber] = useState(1)

  const [unrecognizedFieldSelected, setUnrecognizedFieldSelected] = useState({
    previus: null,
    current: null
  })

  const [actionBoundingBox, setActionBoundingBox] = useState({
    actionType: null,
    section: null
  })

  const [lineItemKey, setLineItemKey] = useState(null)
  const [additionalChargeKey, setAdditionalChargeKey] = useState(null)
  const [summaryFieldId, setSummaryFieldId] = useState(null)
  const [tab, setTab] = useState(0)
  const [annotationUnrecognizedField, setAnnotationUnrecognizedField] = useState(null)
  const [createError, setCreateError] = useState({ message: '', isOpen: false })
  const [dataDelete, setDataDelete] = useState({ isDelete: false })
  const inputRef = useRef({ annotation: {} })

  const {
    data: configTenant,
    isLoading: isLoadingTenantConfig,
    isError: isErrorConfig
  } = useGetTenantConfig()

  const updateStatusInvoice = useUpdateStatusInvoice(invoiceFetch.data?.appId, invoiceId)
  const { mutate: mutateUpdateStatusInvoice } = updateStatusInvoice

  const connectivityConfig = useGetConnectivityConfiguration(invoiceFetch.data?.appId)

  const ocrInformation = useGetFileInformation({
    invoiceId,
    config: {
      staleTime: Infinity
    }
  })

  const ruleFields = useGetValidationRuleFields(
    invoiceFetch.data?.appId,
    invoiceFetch.data?.process
  )

  const { data: invoices } = useGetPreviousAndNextInvoice(['invoiceException'], invoiceId, '')
  const nextInvoice = invoices?.next

  const appConfig = useGeInvoicesAiTenantConfig('invoiceAI', invoiceFetch.data?.appId)

  const onChangeTab = useCallback(index => {
    setTab(index)
  }, [])

  const getTextOcr = useGetTextOcr({
    invoiceId,
    appId: invoiceFetch.data?.appId
  })

  const isLocked = invoiceFetch.data?.isLocked

  useEffect(() => {
    if (isLocked === false) {
      const body = { status: 'locked' }
      mutateUpdateStatusInvoice(body, {
        onError: error => {
          console.error('error', error)
        }
      })

      const handler = setInterval(() => {
        const body = { status: 'locked' }
        mutateUpdateStatusInvoice(body, {
          onError: error => {
            console.error('error', error)
          }
        })
      }, 20000)
      return () => {
        clearInterval(handler)
      }
    }
  }, [isLocked, invoiceId, mutateUpdateStatusInvoice])

  useEffect(() => {
    setSummaryFieldId(null)
  }, [tab])

  useEffect(() => {
    if (!getTextOcr.data) {
      return
    }
    if (getTextOcr.isError) {
      setCreateError({
        message: 'Something went wrong, try again later',
        isOpen: true
      })
    }
  }, [getTextOcr])

  useEffect(() => {
    if (nextInvoice) {
      queryClient.prefetchQuery(['ocrInvoice', nextInvoice], () =>
        fetchInvoiceTextract({
          invoiceId: nextInvoice
        })
      )
      queryClient.prefetchQuery(['invoices', nextInvoice], () =>
        fetchInvoiceById({
          invoiceId: nextInvoice
        })
      )
    }
  }, [nextInvoice, invoiceId, queryClient])

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  const onChangeUnrecognizedField = (e, data) => {
    setUnrecognizedFieldSelected(data)
  }

  const geometryMemo = useMemo(() => {
    if (!ocrInformation.data) {
      return []
    }
    return ocrInformation.data.geometryPerPage
  }, [ocrInformation])

  if (
    ocrInformation.isLoading ||
    isLoadingTenantConfig ||
    appConfig.isLoading ||
    connectivityConfig.isLoading ||
    ruleFields.isLoading
  ) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    )
  }

  if (ocrInformation.isError || isErrorConfig || ruleFields.isError) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Something went wrong, try again later</p>
      </div>
    )
  }

  const breadcrumbViews = [
    {
      name: 'Invoices',
      url: `/admin/invoiceAI/index`,
      icon: InboxIcon
    },
    {
      name: 'Invoice Details',
      url: `/admin/invoiceAI/${invoiceId}/details`,
      icon: DescriptionIcon
    },
    {
      name: 'Invoice Exception',
      url: `/view/invoiceAI/${invoiceId}/exception-details`,
      icon: ArtTrackIcon
    }
  ]

  function getInvoiceId(invoiceId) {
    setPageNumber(1)
    setTab(0)
    setUnrecognizedFieldSelected({
      previus: null,
      current: null
    })
    setSummaryFieldId(null)
    setLineItemKey(null)
    setAdditionalChargeKey(null)
    history.push(`/view/invoiceAI/${invoiceId}/exception-details`)
  }

  const processConfig = appConfig.data.data.params[invoiceFetch.data.process]
  const isTaxEnabled = isTaxLineEnable(processConfig) || isChargesLineEnable(processConfig)
  const taxChargeMessageInfo = getInfoTaxChargeMessage(
    isTaxLineEnable(processConfig),
    isChargesLineEnable(processConfig)
  )

  const isChargeTaxMessage = !isLocked && isTaxEnabled

  return (
    <Grid container className={classesContainer.root}>
      <Grid item className={classesGridItemLeft.root}>
        <ExceptionDocument
          ocrInformation={ocrInformation}
          changePage={changePage}
          geometryPerPage={geometryMemo}
          pageNumber={pageNumber}
          unrecognizedFieldSelected={unrecognizedFieldSelected}
          getTextOcr={getTextOcr}
          onChangeSummaryField={setSummaryFieldId}
          summaryFieldId={summaryFieldId}
          setLineItemKey={setLineItemKey}
          setAdditionalChargeKey={setAdditionalChargeKey}
          onChangeTab={onChangeTab}
          tab={tab}
          annotationUnrecognizedField={annotationUnrecognizedField}
          setPageNumber={setPageNumber}
          setDataDelete={setDataDelete}
          ref={inputRef}
          actionBoundingBox={actionBoundingBox}
          setActionBoundingBox={setActionBoundingBox}
        />
      </Grid>
      <Grid item className={classesGridItemRight.root}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classesGridItemRightHeader.root}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {isLocked || isTaxEnabled ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Alert
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => history.push(`/admin/invoiceAI/index`)}
                    >
                      Exit
                    </Button>
                  }
                  icon={
                    <ErrorIcon
                      fontSize="small"
                      style={{
                        color: '#000000'
                      }}
                    />
                  }
                  className={classesAlert.myAlert}
                >
                  {isChargeTaxMessage ? (
                    taxChargeMessageInfo
                  ) : (
                    <>
                      This invoice is currently read-only because
                      {invoiceFetch.data?.lockedBy?.userName} is currently editing the document.
                      {invoiceFetch.data?.lockedBy?.userName} must finish editing the invoice by
                      leaving the screen or logging out.
                    </>
                  )}
                </Alert>
              </Grid>
            ) : null}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                height: '25px'
              }}
            >
              <Hidden smDown implementation="css">
                <AdminNavbarLinks signOut={() => dispatch(signOut())} rtlActive={true} />
              </Hidden>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Breadcrumbs views={breadcrumbViews} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <SyncLoader showLoader={ocrInformation.isFetching && !ocrInformation.isLoading} />
          </Grid>
        </Grid>
        <Grid container className={classesGridItemRightBody.root}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {isLocked || isTaxEnabled ? (
              <ExceptionFieldsRO
                ocrInformation={ocrInformation}
                appId={invoiceFetch.data?.appId}
                invoiceId={invoiceId}
                pageNumber={pageNumber}
                onChangeUnrecognizedField={onChangeUnrecognizedField}
                onChangeSummaryField={setSummaryFieldId}
                summaryFieldId={summaryFieldId}
                lineItemKey={lineItemKey}
                additionalChargeKey={additionalChargeKey}
                setLineItemKey={setLineItemKey}
                setAdditionalChargeKey={setAdditionalChargeKey}
                setAnnotationUnrecognizedField={setAnnotationUnrecognizedField}
                onChangeTab={onChangeTab}
                tab={tab}
                ref={inputRef}
                appConfig={appConfig.data}
                invoiceData={invoiceFetch.data}
                setPageNumber={setPageNumber}
                dataDelete={dataDelete}
                setDataDelete={setDataDelete}
                connectivityConfig={connectivityConfig.data}
                requiredFields={ruleFields.data}
                processList={processList}
              />
            ) : (
              <ExceptionFields
                ocrInformation={ocrInformation}
                appId={invoiceFetch.data.appId}
                invoiceId={invoiceId}
                pageNumber={pageNumber}
                onChangeUnrecognizedField={onChangeUnrecognizedField}
                getTextOcr={getTextOcr}
                onChangeSummaryField={setSummaryFieldId}
                summaryFieldId={summaryFieldId}
                lineItemKey={lineItemKey}
                additionalChargeKey={additionalChargeKey}
                setLineItemKey={setLineItemKey}
                setAdditionalChargeKey={setAdditionalChargeKey}
                setAnnotationUnrecognizedField={setAnnotationUnrecognizedField}
                onChangeTab={onChangeTab}
                tab={tab}
                ref={inputRef}
                appConfig={appConfig.data}
                invoiceData={invoiceFetch.data}
                setPageNumber={setPageNumber}
                dataDelete={dataDelete}
                setDataDelete={setDataDelete}
                onChangeInvoice={getInvoiceId}
                invoicesExceptions={invoices}
                setActionBoundingBox={setActionBoundingBox}
                connectivityConfig={connectivityConfig.data}
                requiredFields={ruleFields.data}
                defaultAppId={configTenant.defaultApp}
                isLocked={isLocked}
                processList={processList}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '' })}
        close
      />
    </Grid>
  )
}
