import { Fab, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import AddAlert from '@material-ui/icons/AddAlert'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import RestoreIcon from '@material-ui/icons/Restore'
import Snackbar from 'components/Snackbar/Snackbar'
import React, { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import noInvoice from '../../assets/files/NoInvoice.pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const style = {
  /** stroke style **/
  fontColor: 'green',
  fontBackground: 'green',
  lineWidth: 2, // stroke width
  shapeBackground: 'hsla(90, 100%, 49.4%, 0.2)', // background color in the middle of the marker
  shapeStrokeStyle: 'green', // shape stroke color
  shadowBlur: 10, // stroke shadow blur
  shapeShadowStyle: 'rgba(135, 149, 150, 0.3)', // shape shadow color
  initShapeBackground: 'hsla(207, 44%, 49%, 0.2)',

  /** transformer style **/
  transformerBackground: 'blue',
  transformerSize: 5
}

export const ExceptionDocumentSimplePdf = React.forwardRef((props, ref) => {
  const { pageNumber, changePage, setPageNumber, filePath } = props

  const file = filePath || noInvoice
  const [pageSize, setPageSize] = useState({
    height: 1000,
    width: 700
  })
  const [reset, setReset] = useState(1)

  const [numPages, setNumPages] = useState(1)

  const refPicture = useRef(null)
  const [createError, setCreateError] = useState({ message: '', isOpen: false })

  useEffect(() => {
    const el = refPicture.current

    if (el) {
      const rect = el.getBoundingClientRect()
      setPageSize({ height: rect.height, width: rect.width })
    }
  }, [reset])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
            <Tooltip title={'First Page'}>
              <Fab
                size="small"
                color="primary"
                style={{
                  margin: '0 5px 0 5px',
                  backgroundColor: '#081c3e'
                }}
                onClick={() => setPageNumber(1)}
              >
                <FirstPageIcon style={{ fontSize: 20 }} />
              </Fab>
            </Tooltip>
            <Tooltip title={'Previous Page'}>
              <Fab
                size="small"
                color="primary"
                style={{
                  margin: '0 5px 0 5px',
                  backgroundColor: '#081c3e'
                }}
                onClick={() => {
                  if (pageNumber <= 1) {
                    return
                  }
                  previousPage()
                }}
              >
                <ChevronLeftIcon style={{ fontSize: 20 }} />
              </Fab>
            </Tooltip>
            <Tooltip title={'Restore Image'}>
              <Fab
                size="small"
                color="primary"
                style={{
                  margin: '0 5px 0 5px',
                  backgroundColor: 'white'
                }}
                onClick={() => setReset(c => c + 1)}
              >
                <RestoreIcon style={{ color: '#081c3e', fontSize: 20 }} />
              </Fab>
            </Tooltip>

            <Tooltip title={'Next Page'}>
              <Fab
                size="small"
                color="primary"
                style={{
                  margin: '0 5px 0 5px',
                  backgroundColor: '#081c3e'
                }}
                onClick={() => {
                  if (pageNumber >= numPages) {
                    return
                  }
                  nextPage()
                }}
              >
                <ChevronRightIcon style={{ fontSize: 20 }} />
              </Fab>
            </Tooltip>
            <Tooltip title={'Last Page'}>
              <Fab
                size="small"
                color="primary"
                style={{
                  margin: '0 5px 0 5px',
                  backgroundColor: '#081c3e'
                }}
                onClick={() => setPageNumber(numPages)}
              >
                <LastPageIcon style={{ fontSize: 20 }} />
              </Fab>
            </Tooltip>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div ref={refPicture} style={{ minHeight: '80vh', maxHeight: '80vh', overflow: 'auto' }}>
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Page
                  pageNumber={pageNumber}
                  renderAnnotationLayer={false}
                  height={pageSize.height}
                />
              </div>
            </Document>
          </div>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem' }}>
          <p style={{ fontSize: '1rem' }}>
            Page <strong>{pageNumber || (numPages ? 1 : '--')}</strong> of{' '}
            <strong>{numPages || '--'}</strong>
          </p>
        </Grid>
      </Grid>

      <Snackbar
        place="tr"
        color="warning"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '' })}
        close
      />
    </>
  )
})
