import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import InputLabel from '@material-ui/core/InputLabel'
import Datetime from 'react-datetime'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle'

// Form
import { Controller } from 'react-hook-form'

const useStyles = makeStyles(styles)

export const DatetimeInput = props => {
  const classes = useStyles()
  const { name, control, label, timeFormat, dateFormat } = props

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormLabel className={classes.inputLabelHorizontal}>{label}*</FormLabel>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputLabel
            style={{ fontSize: '10.5px', color: '#999', marginTop: '17px', marginBottom: '-9px' }}
          >
            {label}
          </InputLabel>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Datetime
                {...field}
                closeOnSelect={true}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
              />
            )}
            name={name}
            control={control}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
