import React, { useEffect, useState } from 'react'

import MaterialTable from 'material-table'
import moment from 'moment'
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import AttachmentIcon from '@material-ui/icons/Attachment'
import DescriptionIcon from '@material-ui/icons/Description'
import EmailIcon from '@material-ui/icons/Email'
import EmailRoundedIcon from '@material-ui/icons/EmailRounded'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
// components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
// Constants
import { invoiceStatus, statusHistory } from 'utils/Constants'

// functions
import { statusColor } from 'utils/functions'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js'
import { EmailDialog } from 'components/Invoice/EmailModal'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import { useGetEmail } from 'hooks/useEmails'
import { useGetInvoicesByEmail } from 'hooks/useInvoices.js'
import { useGetTenantConfig } from 'hooks/useTenantConfig'
import { formatDate } from 'utils/functions'

const useStyles = makeStyles(styles)

export default function EmailView(props) {
  const { emailId } = props

  const [createError, setCreateError] = useState({ isOpen: false, message: '' })

  const [showEmailModal, setShowEmailModal] = useState({
    isOpen: false,
    data: null
  })

  const classes = useStyles()

  const { data: configTenant, isLoading: isLoadingTenantConfig } = useGetTenantConfig()

  const invoices = useGetInvoicesByEmail(configTenant, emailId)

  const { data: email, isLoading, error, isError, refetch, isFetching } = useGetEmail(
    configTenant,
    emailId
  )

  useEffect(() => {
    if (!isError) {
      return
    }
    setCreateError({
      isOpen: true,
      message: error.response?.data?.message || 'Something went wrong, try again later'
    })
  }, [isError, error])

  const breadcrumbViews = [
    {
      name: 'Emails',
      url: `/admin/invoiceAI/emails`,
      icon: EmailIcon
    },
    {
      name: 'Email Details',
      url: `/admin/invoiceAI/email/${emailId}/details`,
      icon: AttachmentIcon
    }
  ]

  const columnsHistory = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1
    },
    {
      field: 'date',
      title: 'Date',
      render: rowData => formatDate({ date: rowData.date }),
      customSort: (a, b) => {
        return moment(moment(b.date)).isBefore(moment(a.date)) ? 1 : -1
      },
      defaultSort: 'desc'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => statusHistory[rowData.status] ?? rowData.status
    },
    {
      field: 'description',
      title: 'Description',
      render: rowData => {
        const liData = rowData.description.map((data, i) => {
          return (
            <li key={i}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span>{data}</span>
              </div>
            </li>
          )
        })

        return <ul>{liData}</ul>
      }
    }
  ]

  const actions = [
    {
      position: 'row',
      action: rowData => ({
        icon: () => (
          <NavLink to={`/admin/invoiceAI/${rowData.invoiceId}/details`}>
            <DescriptionIcon fontSize="small" style={{ color: '#081c3e' }} />
          </NavLink>
        ),
        tooltip: 'Details'
      })
    }
  ]

  const invoiceColumns = [
    {
      title: 'Relish Id',
      field: 'invoiceId'
    },
    {
      field: 'invoiceNumber',
      title: 'Invoice Id'
    },
    {
      field: 'invoiceDate',
      title: 'Invoice Date'
    },
    {
      field: 'supplierId',
      title: 'ERPVendorId'
    },
    {
      field: 'orderId',
      title: 'Purchase Order'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: statusColor(rowData.status) }}>{invoiceStatus[rowData.status]}</p>
      )
    },
    {
      field: 'subStatus',
      title: 'Substatus',
      render: rowData => (
        <p style={{ color: statusColor(rowData.subStatus) }}>
          {invoiceStatus[rowData.subStatus] ?? rowData.subStatus}
        </p>
      )
    }
  ]

  function returnEmailAddonsTab() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        {email ? (
          <GridContainer justifyContent="center">
            <GridItem md={12} style={{ marginBottom: '25px' }}>
              <Button
                color="primary"
                size="small"
                className={classes.floatRight + ' ' + classes.colorPrimary}
                onClick={() => {
                  refetch({ throwOnError: true })
                  invoices.refetch({ throwOnError: true })
                }}
              >
                <RefreshRoundedIcon fontSize="small" />
              </Button>
            </GridItem>
            <GridItem md={12}>
              <p style={{ fontSize: '20px', color: 'grey' }}>
                Status:{' '}
                <span
                  style={{
                    color: email.status
                      ? statusColor(email.status) !== 'black'
                        ? statusColor(email.status)
                        : 'grey'
                      : 'grey'
                  }}
                >
                  {email.status ? invoiceStatus[email.status] : null}
                </span>
              </p>
            </GridItem>
            <GridItem md={6}>
              <p style={{ color: 'grey' }}>Email Id: {email.rk ? email.rk : null}</p>
            </GridItem>
            <GridItem md={6}>
              <p style={{ color: 'grey' }}>
                Email Date: {email.receivedDate ? email.receivedDate.substring(0, 10) : null}
              </p>
            </GridItem>
            <GridItem md={6}>
              <p style={{ color: 'grey' }}>
                Email Processing Date:{' '}
                {email.processingDate ? email.processingDate.substring(0, 10) : null}
              </p>
            </GridItem>
            <GridItem md={6}>
              <Button
                className={classes.colorPrimary}
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<EmailRoundedIcon />}
                onClick={() =>
                  setShowEmailModal({
                    isOpen: true,
                    data: emailId
                  })
                }
              >
                See Details
              </Button>
            </GridItem>
            {/* <GridItem md={6}>
              <p
                style={{ color: 'grey', cursor: 'pointer' }}
                onClick={() =>
                  setShowEmailModal({
                    isOpen: true,
                    data: emailId
                  })
                }
              >
                See Details
              </p>
            </GridItem> */}

            <GridItem xs={12} sm={12} style={{ marginTop: '15px' }}>
              <h4>Invoices</h4>
              <GridContainer justifyContent="center" style={{ marginTop: '5px' }}>
                <GridItem xs={12} sm={12} md={12}>
                  <MaterialTable
                    columns={invoiceColumns}
                    data={invoices.data ?? []}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: true,
                      showTitle: false,
                      emptyRowsWhenPaging: false
                    }}
                    actions={actions}
                    isLoading={invoices.isLoading}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={12} style={{ marginTop: '15px' }}>
              <h4>Email History</h4>
              <GridContainer justifyContent="center" style={{ marginTop: '5px' }}>
                <GridItem xs={12} sm={12} md={12}>
                  <MaterialTable
                    columns={columnsHistory}
                    data={email.history ?? []}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: true,
                      showTitle: false,
                      emptyRowsWhenPaging: false
                    }}
                    isLoading={isLoading}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        ) : null}
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          {isLoading || isLoadingTenantConfig ? (
            <Card>
              <GridContainer justifyContent="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </GridContainer>
            </Card>
          ) : (
            <>
              <EmailDialog
                open={showEmailModal.isOpen}
                onClose={() => setShowEmailModal({ ...showEmailModal, isOpen: false })}
                recordId={showEmailModal.data}
                tenantRequest={{ configTenant, isLoadingTenantConfig }}
              />

              <Card>
                <CardHeader>
                  <h3 className={classes.formHeader} style={{ color: 'grey' }}>
                    Email
                  </h3>
                </CardHeader>
                <SyncLoader showLoader={isFetching && !isLoading} />
                <CardBody>{returnEmailAddonsTab()}</CardBody>
              </Card>
            </>
          )}
        </GridItem>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={(createError.message = '')}
          open={createError.isOpen}
          closeNotification={() => setCreateError({ isOpen: false, message: '' })}
          close
        />
      </GridContainer>
    </Fade>
  )
}
