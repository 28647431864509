import React, { useState } from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { ReactComponent as Word } from 'assets/img/invoices/word.svg'
import { ReactComponent as Excel } from 'assets/img/invoices/excel.svg'
import { ReactComponent as Pdf } from 'assets/img/invoices/pdf.svg'
import { ReactComponent as Picture } from 'assets/img/invoices/picture.svg'
import { ReactComponent as XmlFile } from 'assets/img/invoices/xml-file-format-symbol.svg'
import { ReactComponent as Attachment } from 'assets/img/invoices/paper-clip.svg'
import { getExtensionFile } from 'utils/functions'
import { CircularProgress } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

function getIconFile(extension) {
  switch (extension) {
    case 'docx':
      return <Word width="40px" height="40px" />
    case 'xlsx':
      return <Excel width="40px" height="40px" />
    case 'pdf':
      return <Pdf width="40px" height="40px" />
    case 'xml':
      return <XmlFile width="40px" height="40px" />
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <Picture width="40px" height="40px" />

    default:
      return <Attachment width="40px" height="40px" />
  }
}

export function Attachments({ dataAttachments }) {
  const [isOpen, setIsOpen] = useState(false)

  const { attachments, isLoading, isError, cantAttachments } = dataAttachments

  const bodyDialog = () => {
    if (isLoading) {
      return (
        <DialogContent>
          <div
            style={{
              height: '30vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        </DialogContent>
      )
    }

    if (isError) {
      return (
        <DialogContent>
          <div
            style={{
              height: '30vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <h2>Unable to get the attachments</h2>
            <WarningIcon fontSize="large" color="error" />
            <h4>try again later!</h4>
          </div>
        </DialogContent>
      )
    }

    return (
      <DialogContent
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}
      >
        {attachments.map(attachment => {
          return (
            <div
              style={{
                padding: '40px',
                justifyContent: 'center',
                boxShadow: 'rgba(50, 50, 93, 0.1) 2px 2px 4px',
                border: '1px solid rgba(0, 0, 0, 0.14)',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '5px'
              }}
              key={attachment.fileName ?? attachment.name}
            >
              {getIconFile(
                getExtensionFile(attachment.fileName ?? attachment.name)
              )}
              <a
                style={{ marginTop: '10px' }}
                href={attachment.presignedUrl}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                {attachment.fileName ?? attachment.name}
              </a>
            </div>
          )
        })}
      </DialogContent>
    )
  }

  const onClickAttachment = () => {
    if (attachments.length === 0) {
      return
    }
    setIsOpen(true)
  }
  return (
    <>
      <p
        style={{ color: 'grey', cursor: 'pointer' }}
        onClick={() => onClickAttachment()}
      >
        {`Attachments (${cantAttachments})`}
      </p>
      <Dialog
        onClose={() => setIsOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle
          id="simple-dialog-title"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          Attachments
        </DialogTitle>
        {cantAttachments > 0 ? bodyDialog() : null}
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
