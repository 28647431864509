import React from 'react'
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

// core components
import Button from 'components/CustomButtons/Button.js'

// @material-ui/icons
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import styles from 'assets/jss/material-dashboard-pro-react/components/breadcrumbsStyle.js'

const useStyles = makeStyles(styles)

export default function Breadcrumbs(props) {
  const classes = useStyles()
  return (
    <div className={classes.buttonGroup}>
      {props.views.map((view, key) => {
        let color
        let className
        if (key === props.views.length - 1) {
          color = 'transparent'
          if (key === 0) {
            className = 'firstButton'
          } else {
            className = 'lastButton'
          }
        } else if (key === 1) {
          color = 'github'
          className = 'firstButton'
        } else {
          color = 'github'
          className = 'middleButton'
        }

        return (
          <div key={key}>
            <NavLink to={view.url}>
              <Tooltip title={view.name}>
                <Button simple color={color} className={classes[className]}>
                  <view.icon className={classes.icons} /> {view.name}
                </Button>
              </Tooltip>
            </NavLink>
            {key !== props.views.length - 1 ? (
              <KeyboardArrowRight className={classes.arrowIcon} />
            ) : null}
          </div>
        )
      })}
    </div>
  )
}
