import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
//import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'
import { CircularProgress, FormLabel, MenuItem, Select } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useGetIgnoreRulePreCreationInfo, useIgnoreDocument } from 'hooks/useIgnoreDocuments'
import { useGetInvoice } from 'hooks/useInvoices'

import { useState } from 'react'
import { shownComponentByExternalFactors } from 'utils/ComponentsConfig'
import { ViewComponentShownByExternalFactors } from 'utils/Constants'
const useStyles = makeStyles(styles)

const ignoreActions = {
  justThisTime: 1,
  createIgnoreRule: 2
}

const scopeOpts = {
  fromEmail: 1,
  domain: 2
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export default function IgnoreModal(props) {
  const classes = useStyles()
  const { showModal, onClose, onSubmit, documentId } = props
  const [currentAction, setCurrentAction] = useState(ignoreActions.justThisTime)
  const [formOnSuccessState, setFormOnSuccessState] = useState(true)
  const [ignoreRuleParams, setIgnoreRuleParams] = useState({})
  const [scope, setScope] = useState(scopeOpts.fromEmail)
  const [showCreateRuleOption, setShowCreateRuleOption] = useState(false)
  const [paramsSuccess, setParamsSuccess] = useState({
    fileName: true,
    fromEmail: true,
    domain: true
  })

  const { data: invoice, isLoading: invoiceDataIsLoading } = useGetInvoice({
    invoiceId: documentId,
    options: {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        const show = Object.keys(invoice).length > 0 && shouldShowCreateRuleOption(invoice)
        setShowCreateRuleOption(show)
      }
    }
  })
  const { isLoading: isLoadingRulePreviewInfo } = useGetIgnoreRulePreCreationInfo(
    invoice?.appId,
    documentId,
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setIgnoreRuleParams({
          fileName: data.fileName,
          fromEmail: data.fromEmail,
          domain: data.domain
        })
        validateParamsOnChange()
      }
    }
  )
  const { mutate: ignoreThisDocument, isLoading: isFetchingIgnoreDocument } = useIgnoreDocument(
    invoice?.appId,
    documentId,
    {
      onSettled: () => {
        onClose()
        onSubmit()
      }
    }
  )

  const handleSubmit = () => {
    let ruleParams = {
      addNewRule: currentAction === ignoreActions.createIgnoreRule,
      ruleName: ignoreRuleParams.ruleName,
      fileName: ignoreRuleParams.fileName
    }

    if (scope === scopeOpts.fromEmail) {
      ruleParams.fromEmail = ignoreRuleParams.fromEmail
    }
    if (scope === scopeOpts.domain) {
      ruleParams.domain = ignoreRuleParams.domain
    }

    if (validateParamsOnChange()) {
      ignoreThisDocument(ruleParams)
    }
  }

  const shouldShowCreateRuleOption = ({ process, status, origin }) => {
    const component =
      ViewComponentShownByExternalFactors.INVOICESAI.IGNORE_DOCUMENTS.IGNORE_MODAL
        .CREATE_RULE_DROPDOWN_OPTION
    return shownComponentByExternalFactors(component, { process, status, origin })
  }

  const validateParamsOnChange = () => {
    paramsSuccess.fileName =
      currentAction === ignoreActions.justThisTime || ignoreRuleParams.fileName !== ''
    paramsSuccess.ruleName =
      currentAction === ignoreActions.justThisTime || ignoreRuleParams.ruleName !== ''
    setParamsSuccess({ ...paramsSuccess })
    setFormOnSuccessState(Object.keys(paramsSuccess).reduce((a, b) => a && paramsSuccess[b], true))
    return paramsSuccess
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      open={showModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose()}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle
        id="notice-modal-slide-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        Ignore Document
      </DialogTitle>
      <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
        <p>
          Do you wish to ignore this document?
          <br />
          <br />
          This action changes the document status, the supplier will not be notified. <br />
          <strong>Just this time: </strong> Change this document status only.
          {invoice && showCreateRuleOption ? (
            <>
              <br />
              <br />
              <strong>Create rule: </strong> Change this document status and create a rule to ignore
              future documents like this one.
              <br />
            </>
          ) : null}
          <br />
        </p>
        <Select
          id="ignoreOpts"
          style={{ width: '100%' }}
          labelId="demo-simple-select-label"
          value={currentAction}
          onChange={e => {
            setCurrentAction(e.target.value)
          }}
        >
          <MenuItem value={ignoreActions.justThisTime}>Just this time</MenuItem>
          {invoice && showCreateRuleOption ? (
            <MenuItem value={ignoreActions.createIgnoreRule}>Create rule</MenuItem>
          ) : null}
        </Select>
        {currentAction === ignoreActions.createIgnoreRule ? (
          isLoadingRulePreviewInfo || invoiceDataIsLoading ? (
            <CircularProgress color="inherit" />
          ) : (
            <GridContainer
              justify="center"
              style={{ paddingTop: '10%', paddingRight: '5%', paddingLeft: '3%' }}
            >
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.inputLabelHorizontal}>Rule Name</FormLabel>
                  </GridItem>
                  <GridItem xs={11} sm={8}>
                    <CustomInput
                      success={paramsSuccess.fileName}
                      error={!paramsSuccess.fileName}
                      labelText="Rule Name*"
                      id="inputFileName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          ignoreRuleParams.ruleName = event.target.value
                          setIgnoreRuleParams({ ...ignoreRuleParams })
                          validateParamsOnChange()
                        },
                        type: 'text',
                        value: ignoreRuleParams.ruleName
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.inputLabelHorizontal}>File Name</FormLabel>
                  </GridItem>
                  <GridItem xs={11} sm={8}>
                    <CustomInput
                      success={paramsSuccess.fileName}
                      error={!paramsSuccess.fileName}
                      labelText="File Name*"
                      id="inputFileName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          ignoreRuleParams.fileName = event.target.value
                          setIgnoreRuleParams({ ...ignoreRuleParams })
                          validateParamsOnChange()
                        },
                        type: 'text',
                        value: ignoreRuleParams.fileName
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={3}>
                    <FormLabel
                      className={classes.inputLabelHorizontal}
                      style={{ marginTop: '-25%' }}
                    >
                      Scope
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={11} sm={8}>
                    <Select
                      id="scope"
                      style={{ width: '100%' }}
                      labelId="demo-simple-select-label"
                      value={scope}
                      onChange={e => {
                        setScope(e.target.value)
                      }}
                    >
                      <MenuItem value={scopeOpts.fromEmail}>From email</MenuItem>
                      <MenuItem value={scopeOpts.domain}>Domain</MenuItem>
                    </Select>
                  </GridItem>
                </GridContainer>
                {scope === scopeOpts.fromEmail ? (
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.inputLabelHorizontal}>From Email</FormLabel>
                    </GridItem>
                    <GridItem xs={11} sm={8}>
                      <CustomInput
                        disabled={true}
                        labelText="From Email*"
                        id="inputFileName"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: 'text',
                          value: ignoreRuleParams.fromEmail
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                ) : null}
                {scope === scopeOpts.domain ? (
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.inputLabelHorizontal}>Domain</FormLabel>
                    </GridItem>
                    <GridItem xs={11} sm={8}>
                      <CustomInput
                        disabled={true}
                        labelText="Domain*"
                        id="inputFileName"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: 'text',
                          value: ignoreRuleParams.domain
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                ) : null}
              </GridItem>
            </GridContainer>
          )
        ) : null}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={() => handleSubmit()}
          disabled={
            !formOnSuccessState ||
            isLoadingRulePreviewInfo ||
            invoiceDataIsLoading ||
            isFetchingIgnoreDocument
          }
          color="primary"
          variant="outlined"
        >
          {isFetchingIgnoreDocument ? 'Ignoring...' : 'Ignore'}
        </Button>
        <Button onClick={() => onClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
