import { UserAgentApplication } from 'msal'
import { Insights } from 'utils/Constants'

export default class MsalAuth {
  constructor (app = 'teams', teamsClientId) {
    let clientId
    let logoutUrl

    switch (app) {
      case 'teams':
        clientId = teamsClientId
        logoutUrl = '/admin/nitorConnect'
        break
      case 'insights':
        clientId = Insights.Microsoft.ClientId
        logoutUrl = '/admin/nitorInsights/index'
        break
      case 'invoices':
        clientId = teamsClientId
        logoutUrl = '/admin/invoiceAI/configurations'
        break
      default:
        break
    }
    this.msalApp = new UserAgentApplication({
      auth: {
        clientId: clientId,
        authority: 'https://login.microsoftonline.com/common',
        validateAuthority: true,
        postLogoutRedirectUri: window.location.origin + logoutUrl,
        navigateToLoginRequestUrl: false,
        redirectUri: window.location.origin + `${logoutUrl}/login-end`,
        tokenType: 'code'
      }
    })
  }

  login = async (callback, scope = '.default') => {
    this.msalApp
      .loginPopup({
        scopes: [scope],
        tokenType: 'code'
      })
      .then(async response => {
        console.info('Login succeeded')
        callback(response)
      })
      .catch(error => {
        console.error('Login failed:', error)
      })
  }

  loginRedirect = async () => {
    if (this.msalApp.loginRedirect !== undefined) {
      this.msalApp.loginRedirect({
        scopes: ['.default']
      })
    }
  }

  isAuthenticated () {
    if (this.msalApp.getAccount()) {
      return true
    }
    return false
  }

  logout () {
    this.msalApp.logout()
  }
}
