import { compose, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import { getListOfuser, getListOfUsersByTenant } from "services/apiUsers";
import { getUserTenant, getUserRoles } from 'utils/functions'

import IndexView from "./IndexView";
import { hideLoading, showLoading } from "utils/functions";

let _isMounted = false;

async function getUsers(props, callback) {
  let result = await getListOfuser();
  if (_isMounted && result.success) {
    props.setUserList(result.data);
  }
  callback(props.setPageIsLoading);
}

async function getUsersByTenant(props, callback) {
  let result = await getListOfUsersByTenant(getUserTenant());
  if (_isMounted && result.success) {
    props.setUserList(result.data);
  }
  callback(props.setPageIsLoading);
}

export default compose(
  connect(
    (state) => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
    }),
    {}
  ),
  withState("userList", "setUserList", []),
  withState("pageIsLoading", "setPageIsLoading", true),
  lifecycle({
    componentDidMount() {
      _isMounted = true;
      showLoading(this.props.setPageIsLoading);

      if (getUserRoles().includes('Super Admin')){
        getUsers(this.props, hideLoading);
      } else {
        getUsersByTenant(this.props, hideLoading)
      }
    },
  })
)(IndexView);
