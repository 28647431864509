import axios from 'axios'
import { ApiAdminInvoice } from 'utils/Constants'
import { createHeaders, getUserTenant } from 'utils/functions'

//#region Transactions Configuration

/*
    Get the transaction list
*/
export async function getTransactionList({ appId, group, rowsPage, lastEvaluatedKey }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/transactions`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: {
      group: group,
      limit: rowsPage,
      key: lastEvaluatedKey
    }
  })
}

/*
    Get a transaction
*/
export async function getTransaction({ appId, transactionId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/transactions/${transactionId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
    Get the child transaction list
*/
export async function getChildTransactions({ appId, transactionId, rowsPage, lastEvaluatedKey }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/childTransactions/${transactionId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: {
      limit: rowsPage,
      key: lastEvaluatedKey
    }
  })
}

/*
    Get a transaction logs
*/
export async function getTransactionLogs({ appId, transactionId, rowsPage, lastEvaluatedKey }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/transactions/${transactionId}/logs`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: {
      limit: rowsPage,
      key: lastEvaluatedKey
    }
  })
}

/*
    Create transaction
*/
export async function createTransaction({ appId, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/transactions`
  let requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

/*
    Update transaction
*/
export async function updateTransaction({ appId, transactionId, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/transactions/${transactionId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

/*
    Change Status transaction
*/
export async function changeStatusTransaction({ appId, transactionId, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/transactions/${transactionId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'patch',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

/*
    New transaction execution
*/
export async function newTransactionExecution({ appId, transactionId }) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/transactions/${transactionId}/execute`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      throw new Error(`newTransactionExecution returned a status code ${error.response.status}`)
    })

    if (response) {
      if (response.status === 200) {
        return { success: true }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}
//#endregion

//#region Transactions Connections Configuration
/*
      Update transaction connection
  */
export async function updateTransactionConnection({ appId, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/transactions/connection`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

/*
    Validate transactions connection
*/
export async function validateTransactionsConnection({ appId, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/transactions/connection`
  let requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: body
  })
}
//#endregion

export async function getInvoiceProcessByTenant() {
  const url = `${ApiAdminInvoice.EndPoint}/configurations/tenants/processConfig`
  const headers = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: headers
  }).then(response => response.data)
}
