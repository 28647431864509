import React, { useState } from 'react'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import CustomInput from '../../components/CustomInput/CustomInput.js'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'

// @material-ui/core components
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'

// styles
import stylesButtons from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

const useStylesButtons = makeStyles(stylesButtons)

export default function EmailsModal(props) {
  const [supplierEmail, setSupplierEmail] = useState(props.supplierEmail)
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const buttonClases = useStylesButtons()
  const returnValues = {
    supplierEmail
  }

  return (
    <Dialog open={true}>
      <div style={{ alignItems: 'center' }}>
        <Grid container style={{ overflow: 'hidden' }}>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <h1>{props.title}</h1>
          </div>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <GridContainer style={{ paddingBottom: '5%', width: '100%' }}>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <CardHeader>Supplier Email</CardHeader>
                <CardBody>
                  <CustomInput
                    labelProps={{ style: { width: '100%' } }}
                    id="paramName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    required
                    inputProps={{
                      type: 'text',
                      value: supplierEmail,
                      onChange: event => {
                        setSupplierEmail(event.target.value)
                      }
                    }}
                  />
                </CardBody>
              </GridItem>
            </GridContainer>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: '5%',
              paddingRight: '5%',
              paddingBottom: '2%',
              textAlign: 'right'
            }}
          >
            <Button
              className={buttonClases.colorPrimary}
              onClick={() => {
                props.onConfirm(returnValues)
              }}
              variant="outlined"
              color="inherit"
              disabled={supplierEmail === '' || !re.test(supplierEmail)}
            >
              Confirm
            </Button>

            <Button
              onClick={props.onCancel}
              style={{ marginLeft: '8px' }}
              color="secondary"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}
