import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { getTransactionsCountByAppId } from '../../services/apiApps'
import DataAssureCountView from './DataAssureCountView'

import { showLoading } from 'utils/functions'
import { hideLoading } from 'utils/functions'

import { updateHeightFunction } from 'store/AppState'

let _isMounted = false

const dates = {
  endDate: new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }),
  startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

async function getTransactionsCount(props) {
  props.setCountIsLoading(true)
  let body = {
    startDate: new Date(props.dates.startDate).toISOString(),
    endDate: new Date(props.dates.endDate).toISOString()
  }
  const response = await getTransactionsCountByAppId('slp', body)
  if (response && response.success) {
    const rows = response.data
    if (_isMounted) {
      props.setRows([...rows])
    }
  } else {
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
  props.setCountIsLoading(false)
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      updateHeight: state.app.updateHeight
    }),
    { updateHeightFunction }
  ),
  withState('appList', 'setAppList', []),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('selectedApp', 'setSelectedApp', null),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('countIsLoading', 'setCountIsLoading', false),
  withState('rows', 'setRows', []),
  withState('dates', 'setDates', { ...dates }),
  withHandlers({
    onDetailsCount: props => validationType => {
      props.history.push(
        `/admin/dataAssure/transactions/count/${validationType}?startDate=${props.dates.startDate}&endDate=${props.dates.endDate}`
      )
    },
    onSearchValues: props => async () => {
      await getTransactionsCount(props)
    },
    onFieldChange: props => (field, value) => {
      if (typeof value === 'object' && value !== null) {
        value = value._d.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      }
      props.dates[field] = value
      props.setDates(props.dates)
    }
  }),
  lifecycle({
    componentDidMount() {
      showLoading(this.props.setPageIsLoading)
      _isMounted = true
      this.props.setPageIsLoading(true)
      getTransactionsCount(this.props)
      this.props.setPageIsLoading(false)
      hideLoading(this.props.setPageIsLoading)
    }
  })
)(DataAssureCountView)
