export const initialState = {
    updateHeight: () => { },
    enabledApps: {}
};

export const UPDATE_HEIGHT_FUNCTION = "App/UPDATE_HEIGHT_FUNCTION";

export const UPDATE_ENABLED_APPS = "App/UPDATE_ENABLED_APPS";

export const updateEnabledApps = (enabledApps) => ({
    type: UPDATE_ENABLED_APPS,
    payload: enabledApps
})

export const updateHeightFunction = (heightFunction) => ({
    type: UPDATE_HEIGHT_FUNCTION,
    payload: heightFunction
})

export default function AppReducer(state = initialState, { type, payload }) {
    switch (type) {
        case UPDATE_HEIGHT_FUNCTION:
            return {
                ...state,
                updateHeight: payload
            };
        case UPDATE_ENABLED_APPS:
            return {
                ...state,
                enabledApps: payload
            };
        default:
            return state;
    }
}
