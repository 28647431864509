/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//Icons
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/Teams/detailsStyle.js";

const useStyles = makeStyles(styles);

export default function createView(props) {
    const classes = useStyles();
    return (
        <div>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridItem xs={12} sm={12} md={12}>
                                <p>
                                    To use this app you must belong to an organization that has registered as a customer in the Relish App Center.
                                    Visit our
                                    <a
                                        href="https://relishiq.com/"
                                        target="_blank"
                                        style={{ fontWeight: "bold" }}
                                    > web site</a> for more information about Relish Connect and how to become a customer.
                                </p>
                                <Button color="primary" simple onClick={() => { props.history.goBack() }}>
                                    <KeyboardArrowLeft className={classes.icons} /> Back to Login
                                </Button>
                            </GridItem>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
