import axios from 'axios'
import store from 'store'
import { signOut } from 'store/AuthState'
import { AuthService } from 'utils/Constants'
import { createHeaders, getUserTenant } from 'utils/functions'

export async function queryPermissionsByRole(role, tenant) {
  let tenantId = tenant
  if (!tenantId) {
    tenantId = getUserTenant()
  }

  let url = `${AuthService.Endpoint}tenants/${tenantId}/roles/${role}/permissions`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getRolesPermissions(roles = '') {
  const tenantId = getUserTenant()
  let url = `${AuthService.Endpoint}tenants/${tenantId}/permissionsByRoles`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: {
      roles: roles
    }
  })
}

export async function getPermissionsByRole(tenant, role) {
  try {
    let response = await queryPermissionsByRole(role, tenant).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getListOfTickets returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

export async function getAvailableRolesEndPoint(tenant) {
  let url = `${AuthService.Endpoint}subscription/tenants/${tenant}/roles`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: {
      includeDefault: true
    }
  })
}

export async function getAvailableRoles(tenant) {
  try {
    let response = await getAvailableRolesEndPoint(tenant).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getListOfTickets returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

//#region Requests for hooks

export async function checkPermissionsInToken(permissionList) {
  const tenantId = getUserTenant()
  const url = `${
    AuthService.Endpoint
  }tenants/${tenantId}/checkPermissionInToken?permissionList=${permissionList.join(
    '&permissionList='
  )}`
  const requestHeaders = createHeaders()
  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

//#endregion
