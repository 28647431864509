import React, { useEffect, useRef, useState } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@material-ui/core/Fade'
// @material-ui/icons
//import SettingsApplications from '@material-ui/icons/SettingsApplications';
import TouchApp from '@material-ui/icons/TouchApp'
import Add from '@material-ui/icons/PlaylistAdd'
import AddAlert from '@material-ui/icons/AddAlert'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

// Views

import AppMainMenu from '../CustomGenericViews/AppMainMenu.js'
import Wizard from '../../components/Wizard/Wizard.js'

import { saveConfiguration } from '../../services/apiApps.js'

import { showLoading, hideLoading } from 'utils/functions.js'

import { saveAppdetails } from './ConnectContainer.js'
import { cloneObject } from 'utils/functions.js'
import ParametersAccordion from '../CustomGenericViews/ParametersAccordion.js'
import TeamsSync from './TeamsSync.js'
const useStyles = makeStyles(styles)

export default function Connect(props) {
  const [showConfiguration, setShowConfiguration] = useState(false)

  const [configurationDetails, setConfigurationDetails] = useState('main')
  const [configurationDetailsLabel, setConfigurationDetailsLabel] = useState('')

  const [service, setService] = useState('ariba')

  const refreshTableFunction = useRef(() => {})
  //const changeUniqueElementFunction = useRef(() => {})
  const isMounted = useRef(false)

  useEffect(
    () => {
      // did mount
      if (!isMounted.current) {
        //getMapping()
        isMounted.current = true
      }
      // did update
      else {
      }

      //this function will be ran when the component is re-renderd or unmounted
      return () => {}
    },
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    []
  )

  async function saveToDataBase(
    configurationProps,
    rollbackFunction,
    successMessage,
    refreshPage = false,
    changeConfigured = true,
    errorMessage = 'Something went wrong, please try again later'
  ) {
    let newConfigurationProp = cloneObject(configurationProps)
    if (changeConfigured) newConfigurationProp[configurationDetails].configured = true

    delete newConfigurationProp.displayMode
    delete newConfigurationProp.mapping
    if (configurationDetails !== 'main') {
      delete newConfigurationProp[configurationDetails].configurationOnClick
      delete newConfigurationProp.environment

      let keys = Object.keys(newConfigurationProp)
      keys.forEach(key => {
        if (
          typeof newConfigurationProp[key] === 'object' &&
          key !== configurationDetails &&
          key !== 'parameters'
        ) {
          delete newConfigurationProp[key]
        }
      })
      keys = Object.keys(newConfigurationProp[configurationDetails].params)

      keys.forEach(paramKey => {
        delete newConfigurationProp[configurationDetails].params[paramKey].errorMessage
        delete newConfigurationProp[configurationDetails].params[paramKey].showErrorIcon
        delete newConfigurationProp[configurationDetails].params[paramKey].showErrorIconDiv
        delete newConfigurationProp[configurationDetails].params[paramKey].state
      })
    } else {
      let keys = Object.keys(newConfigurationProp)
      keys.forEach(key => {
        if (typeof newConfigurationProp[key] === 'object') {
          if (newConfigurationProp[key].configurationOnClick)
            delete newConfigurationProp[key].configurationOnClick
          let paramKeys = Object.keys(newConfigurationProp[key])
          paramKeys.forEach(paramKey => {
            if (newConfigurationProp[key].params && newConfigurationProp[key].params[paramKey]) {
              if (newConfigurationProp[key].params[paramKey].errorMessage)
                delete newConfigurationProp[key].params[paramKey].errorMessage
              if (newConfigurationProp[key].params[paramKey].showErrorIcon)
                delete newConfigurationProp[key].params[paramKey].showErrorIcon
              if (newConfigurationProp[key].params[paramKey].showErrorIconDiv)
                delete newConfigurationProp[key].params[paramKey].showErrorIconDiv
              if (newConfigurationProp[key].params[paramKey].state)
                delete newConfigurationProp[key].params[paramKey].state
            }
          })
        }
      })
    }
    showLoading()

    let response = await saveConfiguration(
      'teams',
      props.validationsForm.env,
      newConfigurationProp,
      props.validationsForm.service,
      configurationDetails
    )
    if (response.success) {
      props.setValidationMessage(successMessage)
      props.setSubmitSuccess(true)
      setTimeout(() => {
        props.setSubmitSuccess(false)
      }, 5000)
      if (response.data) {
        await saveAppdetails(props, response, false, refreshPage)
        // props.setRefresh(!props.refresh)
        // to refresh
      }
    } else {
      props.setValidationMessage(errorMessage)
      props.setCreateError(true)
      setTimeout(() => {
        props.setCreateError(false)
      }, 5000)
      if (rollbackFunction) {
        rollbackFunction(() => {
          props.setRefresh(!props.refresh)
          if (refreshTableFunction.current) refreshTableFunction.current()
        })
      }
    }
    hideLoading()
  }

  const classes = useStyles()
  function getView() {
    if (props.pageIsLoading) {
      return <div></div>
    }
    function scrolltoTop() {
      let element = document.getElementById('MainGrid')
      if (element) element.scrollTo(0, 0)
    }
    function getAdminSteps() {
      let switchChildren = {}
      Object.keys(props.validationsForm).forEach(key => {
        if (props.validationsForm[key] && props.validationsForm[key].configurable) {
          switchChildren[key] = props.validationsForm[key]
          switchChildren[key].id = key
          switchChildren[key].configurationOnClick = (id, state) => {
            setConfigurationDetails(id.toString())
            setConfigurationDetailsLabel(state.mapping.switchChildren[id].title)
            scrolltoTop()
            setShowConfiguration(true)
          }
        }
      })
      let topChildren = <div></div>
      if (
        props.validationsForm &&
        props.validationsForm.parameters &&
        props.validationsForm.parameters.microsoftGraphApi
      ) {
        topChildren = (
          <TeamsSync
            onLogin={(success, message) => {
              function refresh() {
                setShowConfiguration(false)
                setConfigurationDetails('main')
                setConfigurationDetailsLabel('')
                props.onAppChanged(validationInitialState.environment)
                scrolltoTop()
              }
              if (success) {
                props.setValidationMessage(message)
                props.setSubmitSuccess(true)
                setTimeout(() => {
                  props.setSubmitSuccess(false)
                  refresh()
                }, 2000)
              } else {
                props.setValidationMessage(message)
                props.setCreateError(true)
                setTimeout(() => {
                  props.setCreateError(false)
                  refresh()
                }, 2000)
              }
            }}
            app={'teams'}
            clientId={props.validationsForm.parameters.microsoftGraphApi.MGAppId.value}
            hasRefreshToken={props.validationsForm.parameters.microsoftGraphApi?.refresh_token}
            responseType={'code'}
            responseMode={'query'}
            scope={props.validationsForm.parameters.microsoftGraphApi?.scope?.value}
            prompt={'login'}
            environment={props.validationsForm.environment}
            client={props.validationsForm.client}
          ></TeamsSync>
        )
      }
      let regionOptions = []
      if (
        props.globalConstants &&
        props.globalConstants.ariba &&
        props.globalConstants.ariba.region &&
        props.globalConstants.ariba.region.options
      )
        regionOptions = props.globalConstants.ariba.region.options

      let validationInitialState = props.validationsForm
      validationInitialState.env = validationInitialState.environment
      validationInitialState.realm = props.validationsForm.realm
      validationInitialState.region = props.validationsForm.region
      let mapping = {
        //service: props.validationsForm.service,
        // documentationPath: '/admin/Connect/documentation',
        setService: setService,
        service: service,
        configureRealm: true,
        configureRegion: props.validationsForm.service === 'ariba',
        regionOptions: regionOptions,
        enabled: props.validationsForm.enabled,
        mainSwitchText: 'Enable Connect',
        mainSwitchOnChanged: (event, state) => {},
        title: 'Connect Assure Settings',
        topChildren: topChildren,
        bottomChildren: <div></div>,
        applicationTitle: 'Application',
        switchChildrenTitle: 'Connect Integration',
        switchChildren: switchChildren,
        hideService: true
      }

      if (props.displayMode === 'create') {
        validationInitialState.env = ''
        validationInitialState.realm = ''
        validationInitialState.region = 'US'

        mapping = {
          //service: props.validationsForm.service,
          //documentationPath: '/admin/Connect/documentation',
          setService: setService,
          configureRealm: true,
          configureRegion: true,
          regionOptions: regionOptions,
          enabled: props.validationsForm.enabled,
          mainSwitchText: 'Enable Connect',
          mainSwitchOnChanged: (event, state) => {},
          title: 'Connect Settings',
          topChildren: '',
          bottomChildren: <div></div>,
          applicationTitle: 'Application',
          switchChildrenTitle: '',
          switchChildren: {},
          hideService: true
        }
      }

      validationInitialState.mapping = mapping

      let steps = [
        {
          stepName: 'Validations',
          stepComponent: AppMainMenu,
          stepId: 'validations',
          initialState: validationInitialState
        }
      ]

      return steps
    }
    function returnConfigurationWizardToDisplay() {
      let patameterFields = {}
      let userFields = {}
      let documentsFields = {}

      let paramsKeys = Object.keys(props.validationsForm[configurationDetails].params)
      paramsKeys.forEach(key => {
        if (key === 'addChannelToTeams') {
        }
        switch (props.validationsForm[configurationDetails].params[key].includeIn) {
          case 'Users':
            userFields[key] = props.validationsForm[configurationDetails].params[key]
            break
          case 'Documents':
            documentsFields[key] = props.validationsForm[configurationDetails].params[key]
            break
          default:
            patameterFields[key] = props.validationsForm[configurationDetails].params[key]
            break
        }
      })

      let parameters = [
        {
          title: 'General Configurations',
          parentId: configurationDetails,
          conectivityMapping: {
            env: props.validationsForm.environment,
            app: 'teams',
            hideAddButton: true,
            transformedColumnName:
              props.validationsForm.service === 'coupa' ? 'Easy Form' : 'Questionnaire',
            formTitle: 'API Validation Parameters',
            region: { value: props.validationsForm.region },
            service: props.validationsForm.service,
            //documentationPath: '/admin/Connect/documentation',
            setService: setService,
            hideServiceName: true,
            hideTestConnection: true,
            title: '',
            fieldsTitle: '',
            fields: patameterFields
          }
        }
      ]

      if (Object.keys(userFields).length > 0) {
        parameters.push({
          title: 'Users',
          parentId: configurationDetails + 'Users',
          conectivityMapping: {
            env: props.validationsForm.environment,
            app: 'teams',
            hideAddButton: true,
            transformedColumnName:
              props.validationsForm.service === 'coupa' ? 'Easy Form' : 'Questionnaire',
            formTitle: 'API Validation Parameters',
            region: { value: props.validationsForm.region },
            service: props.validationsForm.service,
            //documentationPath: '/admin/Connect/documentation',
            setService: setService,
            hideServiceName: true,
            hideTestConnection: true,
            title: '',
            fieldsTitle: '',
            fields: userFields
          }
        })
      }
      if (Object.keys(documentsFields).length > 0) {
        parameters.push({
          title: 'Documents',
          parentId: configurationDetails + 'Documents',
          conectivityMapping: {
            env: props.validationsForm.environment,
            app: 'teams',
            hideAddButton: true,
            transformedColumnName:
              props.validationsForm.service === 'coupa' ? 'Easy Form' : 'Questionnaire',
            formTitle: 'API Validation Parameters',
            region: { value: props.validationsForm.region },
            service: props.validationsForm.service,
            //documentationPath: '/admin/Connect/documentation',
            setService: setService,
            hideServiceName: true,
            hideTestConnection: true,
            title: '',
            fieldsTitle: '',
            fields: documentsFields
          }
        })
      }

      if (props.validationsForm.parameters) {
        let parameterKeys = Object.keys(props.validationsForm.parameters)
        parameterKeys.forEach(key => {
          if (props.validationsForm.parameters[key].includeIn === configurationDetails) {
            parameters.push({
              title: props.validationsForm.parameters[key].label,
              parentId: props.validationsForm.parameters[key].label,
              conectivityMapping: {
                env: props.validationsForm.environment,
                app: 'teams',
                parametersPosition: key,
                ignoreIds: ['questionnaireResponse'],
                service: props.validationsForm.service,
                setService: setService,
                hideServiceName: true,
                title: '',
                region: { value: props.validationsForm.region },
                siteUrl: props.validationsForm.parameters.siteUrl,
                fieldsTitle: 'Test',
                hideTestConnection:
                  typeof props.validationsForm?.parameters[key]?.showTestConnection !== 'undefined'
                    ? !props.validationsForm.parameters[key].showTestConnection
                    : false,
                fields: props.validationsForm.parameters[key]
              }
            })
          }
        })
      }

      let configStepId = configurationDetails + 'config'

      return (
        <Wizard
          hideNextButton={true}
          hidePreviousButton={true}
          id="configurationWizard"
          color="primary"
          validate
          hideNavigation={true}
          backButtonOnClick={() => {
            setConfigurationDetails('main')
            setConfigurationDetailsLabel('')
            setShowConfiguration(false)
            //for the fade transition and wizard refresh
            props.setRefresh(!props.refresh)
          }}
          title={configurationDetailsLabel + ' Configuration'}
          steps={[
            {
              stepName: 'Configurations',
              stepComponent: ParametersAccordion,
              stepId: configStepId,
              initialState: {
                parameters: parameters
              }
            }
          ]}
          subtitle=""
          finishButtonClick={e => {
            saveToDataBase(props.validationsForm, () => {}, 'Saved', false, true)
          }}
        />
      )
    }

    function returnWizardToDisplay() {
      return (
        <Wizard
          id="Wizard"
          color="primary"
          validate
          hideNavigation={true}
          title="Connect"
          steps={getAdminSteps()}
          subtitle=""
          finishButtonClick={e => {
            if (
              !e.validations ||
              !e.validations.region ||
              (e.validations.region && !e.validations.region.trim())
            ) {
              props.setValidationMessage('Please Add Region')
              props.setCreateError(true)
              setTimeout(() => {
                props.setCreateError(false)
              }, 5000)
              return
            }
            if (props.displayMode === 'create') {
              if (e.validations && e.validations.env)
                if (e.validations && e.validations.realm && e.validations.realm.trim())
                  props.onAppSaved(e)
                else {
                  props.setValidationMessage('Please Add Realm')
                  props.setCreateError(true)
                  setTimeout(() => {
                    props.setCreateError(false)
                  }, 5000)
                }
              else {
                props.setValidationMessage('Please Add Application Name')
                props.setCreateError(true)
                setTimeout(() => {
                  props.setCreateError(false)
                }, 5000)
              }
            } else {
              if (e.validations && e.validations.realm && e.validations.realm.trim()) {
                let validationsForm = props.validationsForm
                validationsForm.enabled = e.validations.mapping.enabled
                saveToDataBase(validationsForm, () => {}, 'Saved', false, false)
              } else {
                props.setValidationMessage('Please Add Realm')
                props.setCreateError(true)
                setTimeout(() => {
                  props.setCreateError(false)
                }, 5000)
              }
            }
          }}
        />
      )
    }

    return (
      <Fade in={true} timeout={250}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={3} className={classes.padding10}>
            <Card className={classes.appBar}>
              <CardHeader>
                Application List
                <Tooltip title="Create new app">
                  <Button
                    justIcon
                    round
                    simple
                    color="primary"
                    className={classes.marginRight}
                    onClick={() => {
                      setShowConfiguration(false)

                      setConfigurationDetails('main')
                      setConfigurationDetailsLabel('')

                      props.onCreateButton()
                      scrolltoTop()
                    }}
                  >
                    <Add className={classes.icons} />
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <List>
                  {props.appList.map((prop, key) => {
                    return (
                      <ListItem
                        className={classes.listItem}
                        button
                        selected={props.selectedAppId === prop.env}
                        key={key}
                        onClick={() => {
                          setShowConfiguration(false)
                          setConfigurationDetails('main')
                          setConfigurationDetailsLabel('')
                          props.onAppChanged(prop.env)
                          scrolltoTop()
                        }}
                      >
                        <ListItemAvatar>
                          {props.selectedAppId === prop.env ? (
                            <Avatar>
                              <TouchApp />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.clearAvatar}></Avatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText primary={prop.env} secondary={`Type: ${prop.type}`} />
                      </ListItem>
                    )
                  })}
                </List>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={9} className={classes.padding10}>
            {props.formIsLoading ? (
              <Card>
                <GridContainer justify="center">
                  <div className={classes.circularProgress}>
                    <CircularProgress color="inherit" />
                  </div>
                </GridContainer>
              </Card>
            ) : showConfiguration ? (
              <Card>
                <GridContainer spacing={4}>
                  <GridItem xs={12}>
                    {React.cloneElement(returnConfigurationWizardToDisplay())}
                  </GridItem>
                </GridContainer>
              </Card>
            ) : props.selectedAppId ||
              props.displayMode === 'edit' ||
              props.displayMode === 'create' ? (
              <Card>
                <GridContainer spacing={4}>
                  <GridItem xs={12}>{React.cloneElement(returnWizardToDisplay())}</GridItem>
                </GridContainer>
              </Card>
            ) : (
              <Card>
                <CardBody>
                  <div className={classes.typo}>
                    <div className={classes.note}>No apps created</div>
                    <h6>Click on the create button to start...</h6>
                  </div>
                </CardBody>
              </Card>
            )}
          </GridItem>
          <Snackbar
            place="br"
            color="success"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.submitSuccess}
            closeNotification={() => props.setSubmitSuccess(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.createError}
            closeNotification={() => props.setCreateError(false)}
            close
          />
        </GridContainer>
      </Fade>
    )
  }
  if (props.refresh) {
    return <div>{getView()}</div>
  } else return getView()
}
