const main = {
    appName: {
        type: "string",
        required: true,
        displayName: "App Name",
        isAlphaNumeric: true,
    },
    type: {
        type: "string",
        required: true,
        displayName: "Environment Type",
    },
    enabled: {
        type: "boolean",
        required: true,
        displayName: "Enabled",
    },
};

const load = {
    name: {
        type: "string",
        required: true,
        displayName: "Name",
    },
    type: {
        type: "string",
        required: true,
        displayName: "Load Type",
    },
    updateFrequency: {
        type: "string",
        required: false,
        displayName: "Frequency",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "scheduled") {
                        isValid =
                            data.updateFrequency !== null &&
                            data.updateFrequency !== undefined &&
                            data.updateFrequency.trim() !== "";
                    }
                    return isValid;
                },
                message: "Update Frequency is a mandatory field",
            },
        ],
    },
    executionTime: {
        type: "string",
        required: false,
        displayName: "Execution Time",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "scheduled") {
                        isValid =
                            data.executionTime !== null &&
                            data.executionTime !== undefined &&
                            data.executionTime.trim() !== "";
                    }
                    return isValid;
                },
                message: "Execution Time is a mandatory field",
            },
        ],
    },
    executionDay: {
        type: "string",
        required: false,
        displayName: "Execution Day",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "scheduled" && (data.updateFrequency === "weekly" || data.updateFrequency === "monthly")) {
                        isValid =
                            data.executionDay !== null &&
                            data.executionDay !== undefined &&
                            data.executionDay.trim() !== "";
                    }
                    return isValid;
                },
                message: "Execution Day is a mandatory field",
            },
        ],
    },
    startDate: {
        type: "string",
        required: true,
        isDate: true,
        displayName: "Start Date",
    },
};

const destination = {
    remoteConnection: {
        type: "string",
        required: true,
        displayName: "Destination Type",
    },
    unzip: {
        type: "boolean",
        required: false,
        displayName: "Unzip file",
    },
    fileExtension: {
        type: "string",
        required: false,
        displayName: "File extension",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    const connection = data.remoteConnections[data.remoteConnection];
                    if (connection.type === "sharepoint" || connection.type === "s3") {
                        isValid =
                            data.fileExtension !== null &&
                            data.fileExtension !== undefined &&
                            data.fileExtension.trim() !== "";
                    }
                    return isValid;
                },
                message: "fileExtension is a mandatory field",
            },
        ],
    },
    path: {
        type: "string",
        required: false,
        isPath: true,
        displayName: "Path",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    const connection = data.remoteConnections[data.remoteConnection];
                    if (connection.type === "sharepoint" || connection.type === "s3") {
                        isValid =
                            data.path !== null &&
                            data.path !== undefined &&
                            data.path.trim() !== "";
                    }
                    return isValid;
                },
                message: "Path is a mandatory field",
            },
        ],
    },
};

const connection = {
    name: {
        type: "string",
        required: true,
        displayName: "Connection Name",
    },
    type: {
        type: "string",
        required: true,
        displayName: "Connection Type",
    },
    path: {
        type: "string",
        required: false,
        isPath: true,
        displayName: "Path",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sharepoint" || data.type === "s3") {
                        isValid =
                            data.path !== null &&
                            data.path !== undefined &&
                            data.path.trim() !== "";
                    }
                    return isValid;
                },
                message: "Path is a mandatory field",
            },
        ],
    },
    unzip: {
        type: "boolean",
        required: false,
        displayName: "Unzip file",
    },
    fileExtension: {
        type: "string",
        required: false,
        displayName: "File extension",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sharepoint" || data.type === "s3") {
                        isValid =
                            data.fileExtension !== null &&
                            data.fileExtension !== undefined &&
                            data.fileExtension.trim() !== "";
                    }
                    return isValid;
                },
                message: "fileExtension is a mandatory field",
            },
        ],
    },
    accessKeyId: {
        type: "string",
        required: false,
        displayName: "Aws Access Key",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "s3") {
                        isValid =
                            data.accessKeyId !== null &&
                            data.accessKeyId !== undefined &&
                            data.accessKeyId.trim() !== "";
                    }
                    return isValid;
                },
                message: "Aws Access Key is a mandatory field",
            },
        ],
    },
    secretAccessKey: {
        type: "string",
        required: false,
        displayName: "Aws Access Secret",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "s3") {
                        isValid =
                            data.secretAccessKey !== null &&
                            data.secretAccessKey !== undefined &&
                            data.secretAccessKey.trim() !== "";
                    }
                    return isValid;
                },
                message: "Aws Access Secret is a mandatory field",
            },
        ],
    },
    bucketName: {
        type: "string",
        required: false,
        displayName: "Bucket Name",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "s3") {
                        isValid =
                            data.bucketName !== null &&
                            data.bucketName !== undefined &&
                            data.bucketName.trim() !== "";
                    }
                    return isValid;
                },
                message: "Bucket Name is a mandatory field",
            },
        ],
    },
    tenantId: {
        type: "string",
        required: false,
        displayName: "Tenant Id",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sharepoint") {
                        isValid =
                            data.tenantId !== null &&
                            data.tenantId !== undefined &&
                            data.tenantId.trim() !== "";
                    }
                    return isValid;
                },
                message: "Tenant Id is a mandatory field",
            },
        ],
    },
    siteUrl: {
        type: "string",
        required: false,
        isURL: true,
        displayName: "Site Url",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sharepoint") {
                        isValid =
                            data.siteUrl !== null &&
                            data.siteUrl !== undefined &&
                            data.siteUrl.trim() !== "";
                    }
                    return isValid;
                },
                message: "Site Url is a mandatory field",
            },
        ],
    },
    dbHost: {
        type: "string",
        required: false,
        displayName: "dbHost",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sapHana") {
                        isValid =
                            data.dbHost !== null &&
                            data.dbHost !== undefined &&
                            data.dbHost.trim() !== "";
                    }
                    return isValid;
                },
                message: "dbHost is a mandatory field",
            },
        ],
    },
    dbPort: {
        type: "string",
        required: false,
        displayName: "dbPort",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sapHana") {
                        isValid =
                            data.dbPort !== null &&
                            data.dbPort !== undefined &&
                            data.dbPort.trim() !== "";
                    }
                    return isValid;
                },
                message: "dbPort is a mandatory field",
            },
        ],
    },
    dbSchema: {
        type: "string",
        required: false,
        displayName: "dbSchema",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sapHana") {
                        isValid =
                            data.dbSchema !== null &&
                            data.dbSchema !== undefined &&
                            data.dbSchema.trim() !== "";
                    }
                    return isValid;
                },
                message: "dbSchema is a mandatory field",
            },
        ],
    },
    dbUser: {
        type: "string",
        required: false,
        displayName: "dbUser",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sapHana") {
                        isValid =
                            data.dbUser !== null &&
                            data.dbUser !== undefined &&
                            data.dbUser.trim() !== "";
                    }
                    return isValid;
                },
                message: "dbUser is a mandatory field",
            },
        ],
    },
    dbPassword: {
        type: "string",
        required: false,
        displayName: "dbPassword",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sapHana") {
                        isValid =
                            data.dbPassword !== null &&
                            data.dbPassword !== undefined &&
                            data.dbPassword.trim() !== "";
                    }
                    return isValid;
                },
                message: "dbPassword is a mandatory field",
            },
        ],
    },
};

const aribaConnection = {
    realm: {
        type: "string",
        required: true,
        displayName: "Realm",
    },
    clientId: {
        type: "string",
        required: true,
        displayName: "Client Id",
    },
    apiKey: {
        type: "string",
        required: true,
        displayName: "Api Key",
    },
    secret: {
        type: "string",
        required: true,
        displayName: "Secret",
    },
};

export { main, load, destination, connection, aribaConnection };
