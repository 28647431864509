import React from 'react'

// Form Validation
import { useWatch } from 'react-hook-form'

import { useDebounce } from 'hooks/useDebounce'
import { useGetSuppliers } from 'hooks/useMasterData'
import { HeaderFieldsStandard } from 'utils/Constants'

import AutoCompleteAsyncHeader from './AutoCompleteAsyncHeader'

const SupplierSearchId = props => {
  const { control, headerField, headerFieldsActions, appId, getValues, setValueForm } = props

  const { deleteErrorHeader } = headerFieldsActions

  const value = useWatch({
    name: 'headerFields',
    control
  })

  const supplierField = value.find(x => x.field === headerField.field)

  const supplierId = supplierField?.value

  const [filter, setFilter] = React.useState(null)
  const [debouncedValue] = useDebounce(filter, 500)

  const supplierIds = useGetSuppliers(debouncedValue, { appId })

  React.useEffect(() => {
    setFilter(supplierId ? supplierId : null)
  }, [supplierId])

  const options = supplierIds.data?.map(x => ({ label: x.name, value: x.supplierId })) ?? []

  function onChangeAutoComplete(data) {
    const summaryFields = getValues('headerFields')
    const indexVendorField = summaryFields.findIndex(
      summaryField => summaryField.field === HeaderFieldsStandard['supplier.name'].type
    )

    if (indexVendorField < 0) {
      return
    }

    if (data) {
      setValueForm(`headerFields.${indexVendorField}.value`, data.label, {
        shouldValidate: true,
        shouldDirty: true
      })
      deleteErrorHeader({
        field: HeaderFieldsStandard['supplier.name'].type
      })
    } else {
      setValueForm(`headerFields.${indexVendorField}.value`, '', {
        shouldValidate: true,
        shouldDirty: true
      })
    }
    deleteErrorHeader({
      field: HeaderFieldsStandard['supplier.id'].type
    })
  }

  return (
    <AutoCompleteAsyncHeader
      {...props}
      filter={filter ?? ''}
      setFilter={setFilter}
      options={options}
      isLoading={supplierIds.isLoading || supplierIds.isFetching}
      //customName={`headerFields.${index}.value`}
      onChangeAutoComplete={onChangeAutoComplete}
    />
  )
}

export default SupplierSearchId
