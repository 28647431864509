import React, { useEffect, useState } from 'react'

// Material UI
import { CircularProgress, FormLabel, Grid, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle'

// Form Validation
import { Controller, useWatch } from 'react-hook-form'

// Functions
import { useDebounce } from 'hooks/useDebounce'
import { useGetSuppliers } from 'hooks/useMasterData'

const useStyles = makeStyles(styles)

export const SupplierSearch = props => {
  const { name, label, control, setValue, getValues, appId } = props

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [filter, setFilter] = useState(undefined)
  const [debouncedValue] = useDebounce(filter, 500)
  const supplierIds = useGetSuppliers(debouncedValue, { appId })

  const value = useWatch({
    name: 'vendorId',
    control
  })

  useEffect(() => {
    if (!supplierIds.data || open) {
      return
    }

    if (supplierIds.data.length === 0) {
      setValue(
        'vendorName',
        { supplierId: '', name: '' },
        {
          shouldValidate: true,
          shouldDirty: true
        }
      )
    } else if (supplierIds.data.length === 1) {
      setValue('vendorName', supplierIds.data[0], {
        shouldValidate: true,
        shouldDirty: true
      })
    } else {
      if (value) {
        const vendorName = supplierIds.data.find(x => x.supplierId === value.supplierId)
        setValue('vendorName', vendorName, {
          shouldValidate: true,
          shouldDirty: true
        })
      }
    }
  }, [supplierIds.data, setValue, open, value])

  useEffect(() => {
    if (!value) {
      return
    }
    const supplier = getValues('vendorId')?.supplierId

    setFilter(supplier)
  }, [value, getValues])

  const handleChange = event => {
    setFilter(event.target.value)
  }

  function changeSupplierName(value) {
    if (!value) {
      return
    }

    setValue('vendorName', value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <FormLabel className={classes.inputLabelHorizontal}>{label}*</FormLabel>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                freeSolo
                style={{ width: '100%' }}
                disableClearable
                options={supplierIds.data ?? []}
                classes={{
                  option: classes.option
                }}
                open={open}
                onOpen={() => {
                  setOpen(true)
                }}
                onClose={() => {
                  setOpen(false)
                }}
                getOptionSelected={(option, value) => option?.supplierId === value?.supplierId}
                getOptionLabel={option => option?.supplierId ?? ''}
                renderOption={option => (
                  <>
                    {option?.name} ({option?.supplierId})
                  </>
                )}
                loading={supplierIds.isLoading || supplierIds.isFetching}
                renderInput={params => (
                  <TextField
                    {...params}
                    value={filter}
                    onChange={handleChange}
                    label={label}
                    fullWidth
                    error={Boolean(error)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {supplierIds.isLoading || supplierIds.isFetching ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
                onChange={(_, data) => {
                  field.onChange(data)
                  changeSupplierName(data)
                }}
              />
            )}
            name={name}
            control={control}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
