/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress, Select, MenuItem, Fade } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

// material ui icons
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import Add from "@material-ui/icons/PlaylistAdd";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";

// other components
import { DropzoneArea } from 'material-ui-dropzone';

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/User/createStyle.js";

// hooks
import { useCheckPermissions } from "hooks/useAuth";
import { useCreateTicket, useCreateAttachments } from "hooks/useSupportTickets";

// functions
import { encodeUrl } from "utils/functions";

const useStyles = makeStyles(styles);

export default function createView(props) {
  const classes = useStyles();

  const { data: pmManageTenantsTickets } = useCheckPermissions('ManageTenantsTickets', ['Manage.SupportTickets.ManageTenantsTickets'])
  const { data: pmManageOwnTenantTickets } = useCheckPermissions('ManageOwnTenantTickets', ['Manage.SupportTickets.ManageOwnTenantTickets'])

  const { mutate: createAttachments } = useCreateAttachments()
  const { mutate: createTicket } = useCreateTicket({
    onSuccess: async ({data}) => {
      props.setCreateSuccess(true)
      const attach = props.attachments.map(file => {
        file.preSignedUrl = data.preSignedUrls.find(url => url.includes(`/${encodeUrl(file.name)}?`))
        return file
      })
      createAttachments(attach)
      setTimeout(() => {
        props.setCreateSuccess(false)
        props.history.push('/admin/support/index')
      }, 1500)
      
    },
    onError: () => {
      let message = result.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  })
  

  if (props.pageIsLoading) {
    return <div></div>;
  }

  let breadcrumbViews = [
    {
        name: "Support Tickets",
        url: "/admin/support/index",
        icon: ContactSupportIcon,
    },
    {
        name: "Create support ticket",
        url: `/admin/support/ticket/create`,
        icon: Add,
    }
];

  return (
    <Fade in={true} timeout={200}>
      <div>
      <Breadcrumbs views={breadcrumbViews} />
        <Heading textAlign="center" title="New Support Ticket" />
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <ContactSupportIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Support Ticket</h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                        { pmManageTenantsTickets ?
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            error={props.ticketState.tenantId === "error"}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Tenant*
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={props.ticket[`tenantId`]}
                              onChange={(e) => {
                                props.onFieldChange(`tenantId`, e.target.value)
                                props.ticket.tenantId = e.target.value
                                props.setTicket(props.ticket)
                                props.onChangeTenantOption(e.target.value)
                              }}
                              inputProps={{
                                name: `tenantId`,
                                id: `tenantId`,
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                Select Tenant
                              </MenuItem>
                              {props.tenantList.map((tenant) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  style={{
                                    overflowX: "auto",
                                    textOverflow: "ellipsis",
                                  }}
                                  value={tenant.id}
                                  key={tenant.id}
                                >
                                  { tenant.id }
                                </MenuItem>
                              )) }
                            </Select>
                          </FormControl> :
                          <CustomInput
                            success={props.ticketState.title === "success"}
                            error={props.ticketState.title === "error"}
                            labelText="Tenant*"
                            id="inputTenant"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              value: props.ticket.tenantId,
                              endAdornment:
                                props.ticketState["tenantId"] === "error" ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                ),
                            }}
                            disabled={true}
                          />
                        }
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                        { pmManageTenantsTickets || pmManageOwnTenantTickets ?
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            error={props.ticketState.user === "error"}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              User*
                              {props.usersAreLoading ? 
                              <div className={classes.circularProgress}>
                                <CircularProgress color='inherit' />
                              </div> : <></>}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={props.ticket[`associatedUser`].id}
                              onChange={(e) => {
                                const info = props.usersList.find(x => x.userId === e.target.value)
                                props.ticket.associatedUser = {
                                  name: `${info.firstName} ${info.lastName}`,
                                  id: info.userId,
                                  email: info.email,
                                  tenantId: info.tenantId
                                }
                                props.ticket.user = info.userId
                                props.setTicket(props.ticket)
                                props.onFieldChange(`user`, info.userId)
                              }}
                              inputProps={{
                                name: `user`,
                                id: `user`,
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                Select User
                              </MenuItem>
                              {props.usersList.map((user) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  style={{
                                    overflowX: "auto",
                                    textOverflow: "ellipsis",
                                  }}
                                  value={user.userId}
                                  key={user.userId}
                                >
                                  { `${user.firstName} ${user.lastName} (${user.email})` }
                                </MenuItem>
                              )) }
                            </Select>
                          </FormControl> :
                          <CustomInput
                          success={props.ticketState.user === "success"}
                            error={props.ticketState.user === "error"}
                            labelText="User*"
                            id="inputUser"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              value: `${props.ticket.creationUser.name} (${props.ticket.creationUser.email})`,
                              endAdornment:
                                props.ticketState["user"] === "error" ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.danger} />
                                  </InputAdornment>
                                ) : (
                                  undefined
                                ),
                            }}
                            disabled={true}
                          />
                        }
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            success={props.ticketState.title === "success"}
                            error={props.ticketState.title === "error"}
                            labelText="Title*"
                            id="inputTitle"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: (event) => {
                                props.onFieldChange(
                                  "title",
                                  event.target.value
                                );
                                props.ticket.title = event.target.value
                                props.setTicket(props.ticket)
                              },
                              type: "text",
                              value: props.ticket.title
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            error={props.ticketState.priority === "error"}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Ticket Priority*
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={props.ticket[`priority`]}
                              onChange={(e) => {
                                props.onFieldChange(`priority`, e.target.value)
                                props.ticket.priority = e.target.value
                                props.setTicket(props.ticket)
                              }}
                              inputProps={{
                                name: `priority`,
                                id: `priority`,
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                Select the Ticket Priority
                              </MenuItem>
                              {[{ name: "High" }, { name: "Medium" }, { name: "Low" }].map(
                                (role, index) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    style={{
                                      overflowX: "auto",
                                      textOverflow: "ellipsis",
                                    }}
                                    value={role.name}
                                    key={index}
                                  >
                                    <ListItemIcon>
                                      { role.name === 'High' ? <TrendingUpIcon fontSize="small" /> : role.name === 'Medium' ? <TrendingFlatIcon fontSize="small" /> : <TrendingDownIcon fontSize="small" />}
                                    </ListItemIcon>
                                    { role.name }
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            error={props.ticketState.appId === "error"}
                            color={props.ticketState.appId === "error" ? 'error' : 'primary'}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Relish Application*
                              {props.appsAreLoading ? 
                              <div className={classes.circularProgress}>
                                <CircularProgress color='inherit' size={25}/>
                              </div> : <></>}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={props.ticket[`appId`]}
                              onChange={(e) => {
                                props.onFieldChange(`appId`, e.target.value)
                                props.ticket.appId = e.target.value
                                props.setTicket(props.ticket)
                                props.onChangeAppOption(e.target.value)
                              }}
                              inputProps={{
                                name: `id`,
                                id: `id`,
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                Select Relish Application
                              </MenuItem>
                              {props.appsList.map((app, index) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  style={{
                                    overflowX: "auto",
                                    textOverflow: "ellipsis",
                                  }}
                                  value={app.id}
                                  key={index}
                                >
                                  {app.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Application Instance*
                              {props.instancesAreLoading ? 
                              <div className={classes.circularProgress}>
                                <CircularProgress color='inherit' size={25}/>
                              </div> : <></>}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={props.ticket[`instance`]}
                              onChange={(e) => {
                                props.onFieldChange(`instance`, e.target.value)
                                props.ticket.instance = e.target.value
                                props.setTicket(props.ticket)
                              }}
                              inputProps={{
                                name: `instance`,
                                id: `instance`,
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                Select Application Instance
                              </MenuItem>
                              {props.instancesList.map((app, index) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  style={{
                                    overflowX: "auto",
                                    textOverflow: "ellipsis",
                                  }}
                                  value={app.appName ? app.appName : app.env}
                                  key={index}
                                >
                                  {app.appName ? app.appName : app.env}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                              success={props.ticketState.description === "success"}
                              error={props.ticketState.description === "error"}
                              labelText="Description*"
                              id="inputDescription"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: (event) => {
                                  props.onFieldChange(
                                    "description",
                                    event.target.value
                                  );
                                  props.ticket.description = event.target.value
                                  props.setTicket(props.ticket)
                                },
                                type: "textarea",
                                multiline: true,
                                rows: 6,
                                value: props.ticket.description
                              }}
                            />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <div style={{ paddingTop: "3%", paddingBottom: "3%", height: "80%" }}>
                    <DropzoneArea
                      dropzoneText={
                        'CLICK OR DRAG A FILE TO THIS AREA TO UPLOAD'
                      }
                      onChange={files => { 
                        const attach = files.map((f, index) => {
                          return { name: f.name, contentType: f.type, file: f }
                        })
                        props.ticket.attachments = attach
                        props.setTicket(props.ticket)
                        props.setAttachments(attach) 
                      }}
                      filesLimit={5}
                      showPreviews={false}
                      showPreviewsInDropzone={true}
                      showFileNamesInPreview={true}
                    />
                  </div>
                  <div className={classes.formCategory}>
                    <small>*</small> Mandatory fields
                  </div>
                  <div className={classes.center}>
                    {props.isLoadingSave ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <Button color="primary" onClick={() => {
                        // validate the data and then create
                        props.onSupportTicketCreate(createTicket)
                      }}>
                        Create Support Ticket
                      </Button>
                    )}
                  </div>
                </form>
              </CardBody>
            </Card>
            <Snackbar
              place="br"
              color="success"
              icon={AddAlert}
              message="Support Ticket created successfully"
              open={props.createSuccess}
              closeNotification={() => props.setCreateSuccess(false)}
              close
            />
            <Snackbar
              place="bl"
              color="danger"
              icon={AddAlert}
              message={props.validationMessage}
              open={props.createError}
              closeNotification={() => props.setCreateError(false)}
              close
            />
          </GridItem>
        </GridContainer>
      </div>
    </Fade>
  );
}
