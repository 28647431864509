import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import MaterialTable from 'material-table'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import { ReactComponent as ReceiptIcon } from 'assets/img/invoices/receipt-outline.svg'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import AttachmentIcon from '@material-ui/icons/Attachment'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import EmailRoundedIcon from '@material-ui/icons/EmailRounded'
import CachedIcon from '@material-ui/icons/Cached'
import BlockIcon from '@material-ui/icons/Block'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'

// components
import GridContainer from '../../../components/Grid/GridContainer.js'
import GridItem from '../../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import { useParams } from 'react-router'
// Constants
import { invoiceStatus, statusHistory } from 'utils/Constants'

// functions
import { statusColor } from 'utils/functions'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js'
import { Tooltip } from '@material-ui/core'

import { EmailDialog } from 'components/Invoice/EmailModal'
import { formatDate } from 'utils/functions.js'
import { useGetPayment, useUpdateStatusPayment, useManualAssignPayment } from 'hooks/usePayments'
import { useGetTenantConfig } from 'hooks/useTenantConfig.js'
import { AttachmentPayments } from './AttachmentPayments'
import SyncLoader from 'components/SyncLoader/SyncLoader.js'
import { ValidationsResponseModal } from 'components/Invoice/ValidationsResponseModal'
import { UpdateStatusDialog } from 'components/Invoice/UpdateStatusModal'
import { ManualAssignmentDialog } from './ModalManualAssing.jsx'

const useStyles = makeStyles(styles)

export function PaymentDetails() {
  const { paymentId } = useParams()
  const queryClient = useQueryClient()
  const [createError, setCreateError] = useState({ message: '', isOpen: false })
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [validationMessage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showAssingModal, setShowAssingModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState({
    isOpen: false,
    data: null
  })

  const classes = useStyles()

  const { data: configTenant, isLoading: isLoadingTenantConfig } = useGetTenantConfig()
  const { data: payment, isLoading, error, isError, refetch, isFetching } = useGetPayment({
    paymentId,
    configTenant,
    options: { refetchOnWindowFocus: false }
  })

  const {
    isError: isErrorUpdate,
    error: errorUpdateStatus,
    mutate: updateStatus
  } = useUpdateStatusPayment({
    paymentId,
    configTenant
  })

  const {
    isError: isErrorAssign,
    error: errorAssignStatus,
    mutate: updateAssignStatus
  } = useManualAssignPayment({ paymentId, configTenant })

  useEffect(() => {
    if (!isError) {
      return
    }
    setCreateError({
      message: error.response?.data?.message ?? 'Something went wrong, try again later',
      isOpen: true
    })
    // eslint-disable-next-line
  }, [isError, error?.response?.data?.message])

  useEffect(() => {
    if (!isErrorUpdate) {
      return
    }
    setCreateError({
      message: errorUpdateStatus.response?.data?.message ?? 'Something went wrong, try again later',
      isOpen: true
    })
    // eslint-disable-next-line
  }, [isErrorUpdate, errorUpdateStatus?.response?.data?.message])

  useEffect(() => {
    if (!isErrorAssign) {
      return
    }
    setCreateError({
      message: errorAssignStatus.response?.data?.message ?? 'Something went wrong, try again later',
      isOpen: true
    })
    // eslint-disable-next-line
  }, [isErrorAssign, errorAssignStatus?.response?.data?.message])

  async function changePaymentStatus(status, message) {
    updateStatus(
      { status, message, paymentId },
      {
        onSettled: () => {
          queryClient.invalidateQueries('payments')
          queryClient.invalidateQueries(['payments', paymentId])
        }
      }
    )
  }

  async function onSubmmitAssign(status, message) {
    updateAssignStatus(
      { status, message, paymentId },
      {
        onSettled: () => {
          queryClient.invalidateQueries('payments')
          queryClient.invalidateQueries(['payments', paymentId])
        }
      }
    )
  }

  const breadcrumbViews = [
    {
      name: 'Payment Receipts',
      url: `/admin/invoiceAI/payments`,
      icon: ReceiptIcon
    },
    {
      name: 'Payment Receipt Details',
      url: `/admin/invoiceAI/payment/${paymentId}/details`,
      icon: AttachmentIcon
    }
  ]

  const columnsHistory = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1
    },
    {
      field: 'date',
      title: 'Date',
      render: rowData => formatDate({ date: rowData.date })
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => statusHistory[rowData.status]
    },
    {
      field: 'description',
      title: 'Description',
      render: rowData => {
        const liData = rowData.description.map(data => {
          const isEmail = rowData.emailRecordId && rowData.emailRecordId !== ''
          return (
            <li key={data}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span>{data}</span>
                {isEmail ? (
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '7px'
                    }}
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      startIcon={<EmailRoundedIcon />}
                      onClick={() =>
                        setShowEmailModal({
                          isOpen: true,
                          data: rowData.emailRecordId
                        })
                      }
                    >
                      See Details
                    </Button>
                  </div>
                ) : null}
              </div>
            </li>
          )
        })

        return <ul>{liData}</ul>
      }
    }
  ]

  function returnPaymentsAddonsTab() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        {payment ? (
          <GridContainer justify="center">
            <GridItem md={12} style={{ marginBottom: '25px' }}>
              <Button
                color="primary"
                size="small"
                className={classes.floatRight}
                onClick={() => refetch({ throwOnError: true })}
              >
                <RefreshRoundedIcon fontSize="small" />
              </Button>
            </GridItem>
            <GridItem md={12}>
              <p style={{ fontSize: '20px', color: 'grey' }}>
                Status:{' '}
                <span
                  style={{
                    color: payment.status
                      ? statusColor(payment.status) !== 'black'
                        ? statusColor(payment.status)
                        : 'grey'
                      : 'grey'
                  }}
                >
                  {payment.status ? invoiceStatus[payment.status] : null}
                </span>
              </p>
            </GridItem>

            <GridItem md={6}>
              <p style={{ color: 'grey' }}>
                Supplier Name: {payment.header ? payment.header.supplierName : null}
              </p>
            </GridItem>
            <GridItem md={6}>
              <p style={{ color: 'grey' }}>
                ERP Vendor ID: {payment.header ? payment.header.supplierId : null}
              </p>
            </GridItem>
            <GridItem md={6}>
              <p style={{ color: 'grey' }}>UUID: {payment.relatedDocumentId ?? null}</p>
            </GridItem>
            <GridItem md={6}>
              <p style={{ color: 'grey' }}>
                Integration Status: {invoiceStatus[(payment.integrationStatus?.status)] ?? null}
              </p>
            </GridItem>
            <GridItem md={6}>
              <p style={{ color: 'grey' }}>
                Invoice Assigned: {invoiceStatus[payment.invoiceAssignmentStatus] ?? null}
              </p>
            </GridItem>

            <GridItem md={6}>
              <p style={{ color: 'grey' }}>
                CFDI Status:{' '}
                <span
                  style={{
                    color: payment.header
                      ? statusColor(payment.header.cfdiStatus) !== 'black'
                        ? statusColor(payment.header.cfdiStatus)
                        : 'grey'
                      : 'grey'
                  }}
                >
                  {payment.header ? invoiceStatus[payment.header.cfdiStatus] : null}
                </span>
              </p>
            </GridItem>

            <GridItem md={6}>
              <AttachmentPayments
                appId={configTenant.defaultApp}
                paymentId={paymentId}
                cantAttachments={payment.attachments.length}
              />
            </GridItem>

            <GridItem md={6}>
              {payment.status === 'failed' ? (
                <>
                  <Tooltip title={'Reject Payment Receipt'}>
                    <Fab
                      size="small"
                      color="primary"
                      style={{ margin: '0 5px 0 5px' }}
                      className={classes.floatRight + ' ' + classes.bgColorPrimary}
                      onClick={() => setShowRejectModal(true)}
                    >
                      <BlockIcon fontSize="small" />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Reprocess Payment Receipt'}>
                    <Fab
                      size="small"
                      color="primary"
                      style={{ margin: '0 5px 0 5px' }}
                      className={classes.floatRight + ' ' + classes.bgColorPrimary}
                      onClick={() => changePaymentStatus('reprocess', '')}
                    >
                      <CachedIcon fontSize="small" />
                    </Fab>
                  </Tooltip>
                </>
              ) : null}

              {payment.integrationStatus?.status !== 'integrated' &&
              payment.integrationStatus?.status !== 'manuallyIntegrated' ? (
                <Tooltip title={'Manually Integration'}>
                  <Fab
                    size="small"
                    color="primary"
                    style={{ margin: '0 5px 0 5px' }}
                    className={classes.floatRight + ' ' + classes.bgColorPrimary}
                    onClick={() => setShowAssingModal(true)}
                  >
                    <AssignmentTurnedInIcon fontSize="small" />
                  </Fab>
                </Tooltip>
              ) : null}

              {payment.integrationStatus?.status === 'notIntegrated' &&
              payment.status === 'completed' ? (
                <Tooltip title={'Search Invoices'}>
                  <Fab
                    size="small"
                    color="primary"
                    style={{ margin: '0 5px 0 5px' }}
                    className={classes.floatRight + ' ' + classes.bgColorPrimary}
                    onClick={() => changePaymentStatus('attachPaymentToInvoice', '')}
                  >
                    <AssignmentReturnedIcon fontSize="small" />
                  </Fab>
                </Tooltip>
              ) : null}

              <Tooltip title={'Validation Details'}>
                <Fab
                  size="small"
                  color="primary"
                  style={{ margin: '0 5px 0 5px' }}
                  className={classes.floatRight + ' ' + classes.bgColorPrimary}
                  onClick={() => {
                    setShowModal(true)
                  }}
                  disabled={payment.header ? payment.header.cfdiStatus === 'new' : false}
                >
                  <PlaylistAddCheckIcon fontSize="small" />
                </Fab>
              </Tooltip>
            </GridItem>

            <GridItem xs={12} sm={12} style={{ marginTop: '15px' }}>
              <br />
              <h4>Payment Receipts History</h4>
              <GridContainer justify="center" style={{ marginTop: '5px' }}>
                {isLoading ? (
                  <div className={classes.reloadSize}>
                    <CircularProgress />
                  </div>
                ) : (
                  <GridItem xs={12} sm={12} md={12}>
                    <MaterialTable
                      columns={columnsHistory}
                      data={payment.history}
                      options={{
                        actionsColumnIndex: -1,
                        filtering: true,
                        sorting: true,
                        showTitle: false,
                        emptyRowsWhenPaging: false
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        ) : null}
        <ValidationsResponseModal
          showModal={showModal}
          modalInfo={payment.header.validationsResponse ?? {}}
          onClose={() => setShowModal(false)}
        />
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          {isLoading || isLoadingTenantConfig ? (
            <Card>
              <GridContainer justify="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </GridContainer>
            </Card>
          ) : (
            <>
              <EmailDialog
                open={showEmailModal.isOpen}
                onClose={() => setShowEmailModal({ ...showEmailModal, isOpen: false })}
                recordId={showEmailModal.data}
                tenantRequest={{ configTenant, isLoadingTenantConfig }}
              />
              <UpdateStatusDialog
                open={showRejectModal}
                onClose={() => setShowRejectModal(false)}
                changeInvoiceStatus={changePaymentStatus}
                title={'Payment'}
                status={'reject'}
                label={'Reject'}
              />
              <ManualAssignmentDialog
                open={showAssingModal}
                onClose={() => setShowAssingModal(false)}
                onSubmmit={onSubmmitAssign}
              />
              <Card>
                <CardHeader>
                  <h3 className={classes.formHeader} style={{ color: 'grey' }}>
                    Payment Receipts
                  </h3>
                </CardHeader>
                <SyncLoader showLoader={isFetching && !isLoading} />
                <CardBody>{returnPaymentsAddonsTab()}</CardBody>
              </Card>
            </>
          )}
        </GridItem>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={createError.message}
          open={createError.isOpen}
          closeNotification={() => setCreateError({ isOpen: false, message: '' })}
          close
        />
        <Snackbar
          place="bl"
          color="success"
          icon={AddAlert}
          message={validationMessage}
          open={submitSuccess}
          closeNotification={() => setSubmitSuccess(false)}
          close
        />
      </GridContainer>
    </Fade>
  )
}
