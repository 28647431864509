import React from 'react'

import { Badge, Grid, TextField, Tooltip } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { errorTypes, getError } from './useHeader'
// Form Validation
import { Controller } from 'react-hook-form'

import { CircularProgress } from '@material-ui/core'
import { generateJsonValidationV2 } from 'utils/validator'

const AutoCompleteAsyncHeader = props => {
  const {
    control,
    index,
    readOnly,
    label,
    headerField,
    headerData,
    headerFieldsActions,
    setFilter,
    filter,
    isOnlyLabel,
    options,
    isLoading,
    onChangeAutoComplete,
    customName,
    customOptionLabel,
    requiredFields,
    getValues
  } = props

  const name = customName ?? `headerFields.${index}.value`

  /*  const getOptionLabel = customOptionLabel ?? (option) => (option.value || option) */

  const getOptionLabel =
    typeof customOptionLabel === 'function' ? customOptionLabel : option => option.value || option

  const { getColors, classes } = headerFieldsActions

  const errorField = headerData.errors.find(x => x.field === headerField.field)

  const errorData = getError(errorField)

  const onFilterChange =
    typeof setFilter === 'function'
      ? (event, newInputValue) => {
          setFilter(newInputValue)
        }
      : null

  const fieldRule = requiredFields.find(x => x.standardField === headerField.field)
  const rules = readOnly ? {} : generateJsonValidationV2(fieldRule, getValues)

  return (
    <Controller
      render={({ field, fieldState: { error, isDirty } }) => {
        const isError = Boolean(error) || errorData?.criticalityType === errorTypes.critical
        const isVisible = Boolean(error) || Boolean(errorData)

        const errorMessage = errorData ? errorData?.message : error?.message

        return (
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Tooltip
                classes={{
                  arrow: isError ? classes.errorColor : classes.warningColor,
                  tooltip: isError ? classes.tooltipError : classes.tooltipWarning
                }}
                title={isVisible ? errorMessage : ''}
                arrow
                placement="right"
              >
                <Badge
                  style={{ width: '95%' }}
                  classes={{
                    badge: isError ? classes.badgeError : classes.badgeWarning
                  }}
                  badgeContent={
                    isError ? '!' : <WarningIcon className={classes.warningColor}></WarningIcon>
                  }
                  invisible={isVisible ? false : true}
                >
                  <Autocomplete
                    {...field}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    style={{ width: '100%' }}
                    inputValue={filter}
                    onInputChange={onFilterChange}
                    getOptionSelected={(option, value) => option.value === value}
                    getOptionLabel={getOptionLabel}
                    renderOption={option => (
                      <React.Fragment>
                        {option.label && isOnlyLabel
                          ? option.label
                          : `${option.value} (${option.label})`}
                      </React.Fragment>
                    )}
                    options={options ?? []}
                    classes={{
                      option: classes.option
                    }}
                    filterOptions={x => x}
                    loading={isLoading}
                    disabled={readOnly ?? false}
                    renderInput={params => (
                      <TextField
                        {...params}
                        id={headerField.id}
                        label={label}
                        variant="outlined"
                        fullWidth
                        error={isError}
                        className={readOnly ? classes.readOnly : ''}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                          readOnly: readOnly ?? false,
                          classes: {
                            notchedOutline: !isError ? getColors(errorData, isDirty).inputColor : ''
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: !isError ? getColors(errorData, isDirty).color : '',
                            focused: !isError ? getColors(errorData, isDirty).color : ''
                          }
                        }}
                      />
                    )}
                    onChange={(_, data) => {
                      field.onChange(data?.value ? data.value : null)
                      if (typeof onChangeAutoComplete === 'function') {
                        onChangeAutoComplete(data)
                      }
                    }}
                  />
                </Badge>
              </Tooltip>
            </Grid>
          </Grid>
        )
      }}
      name={name}
      control={control}
      rules={rules}
    />
  )
}

export default AutoCompleteAsyncHeader
