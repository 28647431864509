import React, { useState } from 'react'

import { Badge } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
// Form Validation
import { Controller, useWatch } from 'react-hook-form'

import { Button, CircularProgress, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import WarningIcon from '@material-ui/icons/Warning'

import { useDebounce } from 'hooks/useDebounce'
import { useGetPoDetails, useGetPoMatches } from 'hooks/useMasterData'
import { generateJsonValidationV2 } from 'utils/validator'
import InfoModal from '../../components/Invoice/InfoModal'
import { errorTypes, getError } from './useHeader'

const PoSearch = React.forwardRef((props, ref) => {
  const {
    control,
    index,
    readOnly,
    label,
    headerField,
    headerData,
    headerFieldsActions,
    appId,
    setPoModal,
    setIsPoWarning,
    isPoWarning,
    onChangePo,
    setValueForm,
    getValues,
    requiredFields
  } = props

  const { deleteErrorHeader, getColors, classes } = headerFieldsActions
  const [filter, setFilter] = useState(undefined)
  const value = useWatch({
    name: 'headerFields',
    control
  })

  //const [createError, setCreateError] = useState({ message: '', isOpen: false })
  const poIdSelected = value?.[index]?.value

  const [infoModal, setInfoModal] = useState(false)
  const [debouncedValue] = useDebounce(filter, 500)
  const poIds = useGetPoMatches(debouncedValue, { appId })
  const poDetails = useGetPoDetails(poIdSelected, { appId })

  React.useEffect(() => {
    if (!poDetails.data) {
      return
    }

    onChangePo(poDetails.data)

    // eslint-disable-next-line
  }, [poDetails.data, onChangePo])

  function onSuccessInfoModal() {
    if (!debouncedValue) {
      return
    }

    setValueForm(`headerFields.${index}.value`, debouncedValue, {
      shouldValidate: true,
      shouldDirty: true
    })

    onChangePo({ poData: null })
    setIsPoWarning(false)
    setInfoModal(false)
    deleteErrorHeader({
      field: headerField.field
    })
  }
  const handleChange = event => {
    setIsPoWarning(true)
    setFilter(event.target.value)
  }
  const errorField = headerData.errors.find(x => x.field === headerField.field)

  const errorData = getError(errorField)

  const fieldRule = requiredFields.find(x => x.standardField === headerField.field)
  const rules = readOnly ? {} : generateJsonValidationV2(fieldRule, getValues)

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Controller
          render={({ field, fieldState: { error, isDirty } }) => {
            const isError = Boolean(error) || errorData?.criticalityType === errorTypes.critical
            const isVisible = Boolean(error) || Boolean(errorData)
            const errorMessage = errorData ? errorData?.message : error?.message

            return (
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Tooltip
                    classes={{
                      arrow: isError ? classes.errorColor : classes.warningColor,
                      tooltip: isError ? classes.tooltipError : classes.tooltipWarning
                    }}
                    title={isVisible ? errorMessage : ''}
                    arrow
                    placement="right"
                  >
                    <Badge
                      style={{ width: '95%' }}
                      classes={{
                        badge: isError ? classes.badgeError : classes.badgeWarning
                      }}
                      badgeContent={
                        isError ? '!' : <WarningIcon className={classes.warningColor}></WarningIcon>
                      }
                      invisible={isVisible ? false : true}
                    >
                      <Autocomplete
                        {...field}
                        freeSolo
                        style={{ width: '100%' }}
                        options={poIds.data ?? []}
                        classes={{
                          option: classes.option
                        }}
                        getOptionLabel={option => option}
                        loading={poIds.isLoading || poIds.isFetching}
                        disabled={readOnly ?? false}
                        className={readOnly ? classes.readOnly : ''}
                        renderInput={params => (
                          <TextField
                            {...params}
                            value={filter}
                            onChange={handleChange}
                            id={headerField.id}
                            label={label}
                            variant="outlined"
                            fullWidth
                            error={isError}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {poIds.isLoading || poIds.isFetching ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : isPoWarning ? (
                                    <WarningIcon
                                      style={{
                                        color: '#FCD34D',
                                        cursor: 'pointer'
                                      }}
                                      size={20}
                                      onClick={() => setInfoModal(true)}
                                    />
                                  ) : null}

                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                              readOnly: readOnly ?? false,
                              classes: {
                                notchedOutline: !isError
                                  ? getColors(errorData, isDirty).inputColor
                                  : ''
                              }
                            }}
                            InputLabelProps={{
                              classes: {
                                root: !isError ? getColors(errorData, isDirty).color : '',
                                focused: !isError ? getColors(errorData, isDirty).color : ''
                              }
                            }}
                          />
                        )}
                        onChange={(_, data) => {
                          field.onChange(data)
                          deleteErrorHeader({
                            field: headerField.field
                          })
                          setIsPoWarning(false)
                          //ref.current = true
                        }}
                      />
                    </Badge>
                  </Tooltip>
                </Grid>
              </Grid>
            )
          }}
          name={`headerFields.${index}.value`}
          control={control}
          rules={rules}
        />
      </Grid>
      <Grid container justifyContent="flex-start">
        <Grid item>
          <Tooltip title="PO Information" placement="right">
            <Button onClick={() => setPoModal(old => ({ ...old, details: true }))} size="small">
              See Details
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="PO Information" placement="right">
            <Button
              disabled={readOnly ?? false}
              onClick={() => setPoModal(old => ({ ...old, update: true }))}
              size="small"
            >
              Update
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      <InfoModal
        showModal={infoModal}
        onSubmit={onSuccessInfoModal}
        onClose={() => setInfoModal(false)}
        warningMessage="This PO number does not exist in our system. Do you want to use it anyway?"
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <WarningIcon style={{ color: '#fcd34d' }}></WarningIcon> <span>Warning</span>
          </div>
        }
        textSuccessBtn={'Agree'}
      />
      {/* <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '' })}
        close
      /> */}
    </Grid>
  )
})

export default PoSearch
