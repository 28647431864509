import React from 'react'
import { NavLink } from 'react-router-dom'

// Material UI
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

// Icons
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'

// Styles
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle'
import { makeStyles } from '@material-ui/core/styles'

// Constants
import { integrationsInsights } from 'utils/Constants'

// Form
import { Controller } from 'react-hook-form'

const useStyles = makeStyles(styles)

export default function IntegrationsView(props) {
  const { mode, appConfig, control, errors, service } = props

  const classes = useStyles()

  function isError(field) {
    if (errors[field]) {
      return true
    }
    return false
  }

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={4}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h5 className={classes.sectionTitle}>Relish Insights Connection</h5>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControlLabel
              style={{ margin: '1rem 0' }}
              labelPlacement="start"
              control={
                <Controller
                  name="enabled"
                  control={control}
                  render={({ field }) => (
                    <Switch color="primary" size="small" checked={field.value} {...field} />
                  )}
                />
              }
              label="Enabled Relish Insights"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h5 className={classes.sectionTitle}>Application</h5>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={5}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Controller
              render={({ field }) => (
                <TextField
                  label="Application Name*"
                  fullWidth
                  error={isError('appName')}
                  {...field}
                />
              )}
              name="appName"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl fullWidth className={classes.formField} error={isError('type')}>
              <InputLabel id="type-label" className={classes.inputLabel}>
                Environment Type*
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <Select labelId="type-label" label="Type" {...field}>
                    <MenuItem disabled>Select Type</MenuItem>
                    <MenuItem value={'Test'}>Test</MenuItem>
                    <MenuItem value={'Production'}>Production</MenuItem>
                  </Select>
                )}
                name="type"
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl fullWidth className={classes.formField} error={isError('service')}>
              <InputLabel id="type-label" className={classes.inputLabel}>
                Service*
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <Select labelId="type-label" label="Type" {...field}>
                    <MenuItem disabled>Select Service</MenuItem>
                    <MenuItem value={'coupa'}>Coupa</MenuItem>
                    <MenuItem value={'ariba'}>Ariba</MenuItem>
                  </Select>
                )}
                name="service"
                control={control}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {mode === 'edit' ? (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h5 className={classes.sectionTitle}>{integrationsInsights[service].title}</h5>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {integrationsInsights[service].integrations.map((integration, i) => {
              return (
                <Grid container justifyContent="center" spacing={4} key={i}>
                  <Grid item xs={6} sm={6} md={10} lg={10} className={classes.inputLabelHorizontal}>
                    <strong>{integration.name}</strong>
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={2}>
                    <NavLink to={`/admin/insights/${appConfig.appId}/groups/${integration.api}`}>
                      <Button className={classes.documentation} size={'small'}>
                        <SettingsApplicationsIcon fontSize="inherit" />
                        Configure
                      </Button>
                    </NavLink>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </>
      ) : null}
      <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
        <Button
          className={classes.bgColorPrimary}
          type="submit"
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </Grid>
    </Grid>
  )
}
