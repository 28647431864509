import { useEffect, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  changeInvoiceAiStatusQuery,
  createCopyInvoice,
  getDownloadsUseQuery,
  getInvoiceByInvoiceIdQuery,
  getInvoiceImportHistoryQuery,
  getInvoiceNotificationConfigOnAppConfiguration,
  getInvoicesByAppIdUseQuery,
  getInvoicesByEmailQuery,
  getInvoicesByImport,
  getInvoicesDocumentQuery,
  getInvoicesEmailQuery,
  getPresignedurlAttachmentsQuery,
  getPreviousAndNextInvoiceId,
  getPreviousAndNextInvoiceIdV2,
  newLoadRunQuery,
  postImportInvoicesQuery,
  postMoveInvoice,
  saveInvoiceNotificationConfigOnAppConfiguration,
  saveInvoicesDocumentQuery,
  updateInvoiceOcrDataBusinessRule
} from 'services/apiInvoices'
import { v4 as uuidv4 } from 'uuid'

// import { invoiceProcessType } from 'utils/Constants'

export async function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export async function fetchInvoiceById({ invoiceId }) {
  return getInvoiceByInvoiceIdQuery({
    invoiceId
  }).then(result => {
    return { ...result.data, history: result.data.history || [] }
  })
}
export function useGetInvoice({ invoiceId, options = {} }) {
  return useQuery(
    ['invoices', invoiceId],
    () =>
      fetchInvoiceById({
        invoiceId
      }),
    {
      ...options
    }
  )
}

function buildFilters(filters, status) {
  const params = { filters: {} }
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(filters)) {
    params.filters[key] = value
  }

  if (status) {
    params.filters['status'] = status
  }
  return params
}

export function useGetInvoicesV2(filters, status, configTenant, pagination) {
  const queryClient = useQueryClient()
  // const { page, rowsPage } = pagination
  const filtersMemo = useMemo(
    // eslint-disable-next-line
    () => ({ ...pagination, ...buildFilters(filters, status) }),
    [filters, status, pagination]
  )
  const { defaultApp } = configTenant ?? {}
  const useQueryResult = useQuery(
    ['invoices', filtersMemo],
    () =>
      getInvoicesByAppIdUseQuery({
        appId: defaultApp,
        params: filtersMemo
      }).then(result => {
        const rows = result.data.invoices.map(row => {
          return {
            status: row.status,
            substatus: row.substatus,
            id: row.id,
            process: row.process,
            processingDate: row.processingDate,
            relatedDocumentId: row.relatedDocumentId,
            orderId: row.orderId,
            origin: row.origin,
            subProcess: row.subProcess,
            invoiceId: row.invoiceId,
            invoiceDate: row.invoiceDate,
            supplierId: row.supplierId,
            supplierName: row.supplierName,
            invoiceType: row.invoiceType,
            cfdiStatus: row.cfdiStatus,
            paymentReceiptCount: row.invoicePayments,
            linkAriba: row.linkAriba
          }
        })
        return {
          data: rows,
          totalCount: result.data.total
        }
      }),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      // keepPreviousData: true,
      enabled: !!configTenant
    }
  )

  useEffect(() => {
    if (!useQueryResult.data) {
      return
    }

    const isTheLastPage =
      filtersMemo.page >= Math.ceil(useQueryResult.totalCount / filtersMemo.pageSize) - 1
    const isTheFirstPage = filtersMemo.page === 0

    if (isTheLastPage || isTheFirstPage) {
      return
    }
    const filtersUpdated = { ...filtersMemo, page: filtersMemo.page + 1 }
    queryClient.prefetchQuery(['invoices', filtersUpdated], () =>
      getInvoicesByAppIdUseQuery({
        appId: defaultApp,
        params: filtersUpdated
      }).then(result => {
        const rows = result.data.invoices.map(row => {
          return {
            status: row.status,
            substatus: row.substatus,
            id: row.id,
            process: row.process,
            processingDate: row.processingDate,
            relatedDocumentId: row.relatedDocumentId,
            orderId: row.orderId,
            origin: row.origin,
            subProcess: row.subProcess,
            invoiceId: row.invoiceId,
            invoiceDate: row.invoiceDate,
            supplierId: row.supplierId,
            supplierName: row.supplierName,
            invoiceType: row.invoiceType,
            cfdiStatus: row.cfdiStatus,
            paymentReceiptCount: row.invoicePayments,
            linkAriba: row.linkAriba
          }
        })
        return {
          data: rows,
          totalCount: result.data.total
        }
      })
    )
    // eslint-disable-next-line
  }, [useQueryResult.data, queryClient, filtersMemo, defaultApp])
  return useQueryResult
}

export function useGetInvoices(status, configTenant, config = {}) {
  return useQuery(
    // eslint-disable-next-line
    ['invoices', status.reduce((acc, curr) => ((acc[curr] = curr), acc), {})],
    () =>
      getInvoicesByAppIdUseQuery(status, configTenant.defaultApp).then(result => {
        const rows = result.data.map(row => {
          return {
            status: row.status,
            substatus: row.substatus,
            RelishId: row.id,
            process: row.process,
            processingDate: row.processingDate,
            UUID: row.relatedDocumentId,
            orderId: row.orderId,
            origin: row.origin,
            subProcess: row.subProcess,
            invoiceId: row.invoiceId,
            invoiceDate: row.invoiceDate,
            supplierId: row.supplierId,
            supplierName: row.supplierName,
            invoiceType: row.invoiceType,
            cfdiStatus: row.cfdiStatus,
            paymentReceiptCount: row.invoicePayments,
            linkAriba: row.linkAriba
          }
        })

        return { rows: rows }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!configTenant,
      ...config
    }
  )
}

export function useGetPreviousAndNextInvoice(status, invoiceId, appId, config = {}) {
  // eslint-disable-next-line
  let filters = status.reduce((acc, curr) => ((acc[curr] = curr), acc), {})
  if (appId) {
    filters['appId'] = appId
  }

  return useQuery(
    ['invoices', invoiceId, 'previousAndNext', filters],
    () => getPreviousAndNextInvoiceId(invoiceId, status, appId).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      ...config
    }
  )
}

export function useGetPreviousAndNextInvoiceV2({ filters, invoiceId, config = {} }) {
  return useQuery(
    ['invoices', invoiceId, 'previousAndNext', filters],
    () => getPreviousAndNextInvoiceIdV2({ invoiceId, filters }),
    {
      refetchOnWindowFocus: false,
      ...config
    }
  )
}

export function useGetEmailDetailsInvoice(recordIid, configTenant) {
  return useQuery(
    ['invoicesEmail', recordIid],
    () =>
      getInvoicesEmailQuery(recordIid, configTenant.defaultApp).then(result => {
        return { ...result.data }
      }),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: !!recordIid && !!configTenant
    }
  )
}

export function useGetImportInvoiceHistory(configTenant) {
  return useQuery(
    ['invoices', 'history-import', configTenant?.defaultApp],
    () => getInvoiceImportHistoryQuery(configTenant.defaultApp).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      enabled: !!configTenant
    }
  )
}

export function useImportInvoices() {
  return useMutation(({ appId, body }) => postImportInvoicesQuery(appId, body))
}

export function useUpdateStatusInvoice(appId, invoiceId) {
  return useMutation(body => changeInvoiceAiStatusQuery(appId, invoiceId, body))
}

export function useGetPresignedurlAttachments({ invoiceId, appId, config = {} }) {
  return useQuery(
    ['invoices', 'attachments', invoiceId],
    () => getPresignedurlAttachmentsQuery({ invoiceId, appId }).then(result => result.data),
    {
      ...config
    }
  )
}

export function useGetDownloads(configTenant) {
  return useQuery(
    ['downloads'],
    () => getDownloadsUseQuery(configTenant.defaultApp).then(result => result.data),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!configTenant
    }
  )
}

export function useRunNewLoad({ app, env, id, config, isRun }) {
  return useQuery(
    ['invoices', 'loads', { app, env, id }],
    () => newLoadRunQuery({ app, env, id }).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      enabled: isRun,
      ...config
    }
  )
}

export function useGetInvoiceComments({ appId, invoiceId, config }) {
  return useQuery(
    ['apps', appId, 'invoices', invoiceId, 'comments'],
    () =>
      getInvoicesDocumentQuery(appId, invoiceId).then(result => {
        const commentsSorted = result.data.sort(function(a, b) {
          return b.epochTime - a.epochTime
        })
        return commentsSorted.map(comment => {
          return {
            key: comment.commentId,
            comment: {
              userId: comment.userId,
              userName: comment.userName,
              comment: comment.comment,
              picture: comment.picture,
              creationDate: comment.creationDate
            }
          }
        })
      }),
    {
      refetchOnWindowFocus: false,
      ...config
    }
  )
}

export function useSaveInvoiceComment({ invoiceId, appId }) {
  const queryClient = useQueryClient()
  return useMutation(body => saveInvoicesDocumentQuery({ appId, invoiceId, body }), {
    onMutate: async newComment => {
      const key = ['invoices', invoiceId, 'comments']
      await queryClient.cancelQueries(key)

      // Snapshot the previous value
      const previousInvoicesComments = queryClient.getQueryData(key) ?? []

      const commentUpdated = {
        key: uuidv4(),
        comment: {
          ...newComment,
          creationDate: new Date().toISOString()
        }
      }

      const commentsUpdated = [commentUpdated, ...previousInvoicesComments]

      // Optimistically update to the new value
      queryClient.setQueryData(key, commentsUpdated)

      // Return a rollback function
      return () => {
        queryClient.setQueryData(key, previousInvoicesComments)
      }
    }
  })
}

export function useCopyInvoice(appId, invoiceId) {
  return useMutation(body => createCopyInvoice({ appId, invoiceId, body }))
}

export function useGetInvoicesByEmail(configTenant, emailId) {
  const { defaultApp } = configTenant ?? {}
  return useQuery(
    ['invoices', 'emails', defaultApp, emailId],
    () =>
      getInvoicesByEmailQuery({
        appId: defaultApp,
        emailId
      }).then(result => {
        const invoices = result.data ?? []
        return invoices.map(invoice => {
          return {
            invoiceDate: invoice.header.invoiceDate ?? '',
            invoiceId: invoice.rk,
            invoiceNumber: invoice.header.invoiceId ?? '',
            supplierId: invoice.header.supplierId ?? '',
            orderId: invoice.header.orderId ?? '',
            status: invoice.status ?? '',
            subStatus: invoice.substatus ?? ''
          }
        })
      }),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!configTenant
    }
  )
}

export function useGetInvoicesByImportFile(configTenant, importId) {
  const { defaultApp } = configTenant ?? {}
  return useQuery(
    ['invoices', 'import', defaultApp, importId],
    () =>
      getInvoicesByImport({
        appId: defaultApp,
        importId
      }).then(result => {
        const invoices = result.data ?? []
        return invoices.map(invoice => {
          return {
            invoiceDate: invoice.header.invoiceDate ?? '',
            invoiceId: invoice.rk,
            invoiceNumber: invoice.header.invoiceId ?? '',
            supplierId: invoice.header.supplierId ?? '',
            orderId: invoice.header.orderId ?? '',
            status: invoice.status ?? '',
            subStatus: invoice.substatus ?? ''
          }
        })
      }),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: !!configTenant
    }
  )
}

export function useSaveInvoiceNotificationConfig(appId, { onSuccess, onError }) {
  return useMutation(
    configurations =>
      saveInvoiceNotificationConfigOnAppConfiguration({
        appId,
        body: configurations
      }),
    {
      onSuccess,
      onError
    }
  )
}

export function useGetInvoiceNotificationConfig(appId, { onSuccess }) {
  return useQuery(
    ['invoices', 'notificationConfig', appId],
    () =>
      getInvoiceNotificationConfigOnAppConfiguration({
        appId
      }).then(result => {
        const config = result.data ?? {}
        return config
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess
    }
  )
}

export function useMoveInvoice() {
  return useMutation(body => postMoveInvoice(body))
}

export function useUpdateInvoiceOcrDataBusinessRule() {
  return useMutation(({ url, body }) => updateInvoiceOcrDataBusinessRule(url, body))
}
