import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import MUIDataTable from "mui-datatables";

// style
import style from "assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js";

// Constants
import { viewsAriba } from 'utils/Constants'

const columns = [
    {
        name: "view",
        label: "Item",
        options: {
            filter: true,
            sort: true,
        },
    },
];

let selectedViews = [];
let selectedViewsApi = [];

class views extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.initialState;
    }
    sendState() {
        return this.state;
    }

    isValidated() {
        if (this.state.views.length > 0) {
            return true;
        }
        return false;
    }

    render() {
        const { classes } = this.props;
        let viewsArray = this.state.typeApp
            ? viewsAriba[this.state.typeApp][this.state.instanceType].map((view) => {
                  return {
                      id: view.viewName,
                      view: view.displayName + ": " + view.viewName,
                      api: this.state.typeApp
                  };
              })
            : [];

        const selectedRows = this.state.views
            ? this.state.views.map((view) => {
                  return viewsArray.findIndex((item) => item.id === view);
              })
            : [];

        const options = {
            download: false,
            filter: false,
            print: false,
            viewColumns: false,
            selectToolbarPlacement: "none",
            rowsSelected: selectedRows,
            onRowSelectionChange: (
                currentRowsSelected,
                allRowsSelected,
                rowsSelected
            ) => {
                selectedViews = rowsSelected.map((row) => {
                    return viewsArray[row].id
                });
                selectedViewsApi = rowsSelected.map((row) => {
                  return { view: viewsArray[row].id, api: viewsArray[row].api };
              });
                this.setState({ views: [...selectedViews] });
                this.setState({ viewsApi: [...selectedViewsApi] });
            },
            selectableRowsOnClick: true,
        };

        return (
            <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12}>
                        <h4 className={classes.infoText}>
                            { this.state.typeApp === 'upstream' || this.state.typeApp === 'downstream' ? 'Integration Toolkit Names' : 'Views configuration' }
                        </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <GridContainer className={classes.appRow}>
                                    <GridItem xs={12} sm={12}>
                                        <MUIDataTable
                                            title={ this.state.typeApp === 'upstream' || this.state.typeApp === 'downstream' ? ' ' : 'Ariba Views' }
                                            data={viewsArray}
                                            columns={columns}
                                            options={options}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </GridItem>
        );
    }
}

views.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(style)(views);
