import React from 'react'

// style
import RedirectUI, { getPathNewUi } from 'guards/RedirectUI.jsx'
import EmailsView from './EmailsView.jsx'

export default function EmailContainer() {
  const path = getPathNewUi(`/platform/invoiceAI/emails/list`)
  return (
    <RedirectUI path={path}>
      <EmailsView />
    </RedirectUI>
  )
}
