import React from "react";

export default function Catalog() {
    return (
        <div>
            <h1>Ariba OCI Tester: SAP SRM to APC realm</h1>
            <hr/> 
                <p>Enter the following fields and press the button to initiate OCI transaction</p>
                <form method="post" name="SSO2" action="https://s1.ariba.com/Buyer/Main/ad/contentPunchin/OCIPunchinDirectAction/AN01436482315-T">
                    <table border="1">
                        <tbody>
                            <tr>
                                <td>Punchin Id(ANID)(*):</td>
                                <td><input title="Punchin User ID" defaultValue="AN01436482315-T" name="PunchinId"/></td>
                            </tr>
                            <tr>
                                <td>Punchin Id(ANID)(*): </td>
                                <td><input title="Supplier Domain"  name="SupplierDomain"/></td>
                            </tr>
                            <tr>
                                <td>Punchin Password(*): </td>
                                <td><input title="Punchin-pwd" defaultValue="ARIBA123" type="password" name="PunchinPassword"/></td>
                            </tr>
                            <tr>
                                <td>Punchin Realm(*): </td>
                                <td><input title="realm" defaultValue="NitorDSAPP-T" name="realm"/></td>
                            </tr>
                            <tr>
                                <td>Shopping Cart Id: </td>
                                <td><input id="ShoppingCartAuxID" title="ShoppingCart Id" name="ShoppingCartAuxID"/></td>
                            </tr>
                            <tr>
                                <td>Groups: </td>
                                <td><input id="Url" title="Groups" name="Groups"/></td>
                            </tr>
                            <tr>
                                <td>Custom Filter Query: </td>
                                <td><input id="CustomFilterQuery" title="CustomFilterQuery" name="CustomFilterQuery"/></td>
                            </tr>
                            <tr>
                                <th colSpan="2"><b>User Address Details<b> </b></b></th>
                            </tr>
                            <tr>
                                <td>Requester Name (*): </td>
                                <td><input title="Punchin-real-user" defaultValue="Alex" name="FullName"/></td>
                            </tr>
                            <tr>
                                <td>Requester ID (*): </td>
                                <td><input title="Punchin-real-user-id" defaultValue="nitorapps@nitorpartners.com" name="UniqueName"/></td>
                            </tr>
                            <tr>
                                <td>Requester Email Address(*): </td>
                                <td><input title="Email Address " defaultValue="nitorapps@nitorpartners.com" name="EmailAddress"/></td>
                            </tr>
                            <tr>
                                <td>SUPPLIER DOMAIN: </td>
                                <td><input title="SupplierDomain" name="SupplierDomain"/></td>
                            </tr>                           
                            <tr>
                                <td>Requester Country: </td>
                                <td><input title="User.Address.Country" name="User.Address.Country"/></td>
                            </tr>
                            <tr>
                                <td>Requester Locale: </td>
                                <td><input title="User.Locale" name="UserLocale"/></td>
                            </tr>
                            <tr>
                                <td>LOB:SubLOB </td>
                                <td><input title="User.Address.Street" name="User.Address.Street"/></td>
                            </tr>
                            <tr>
                                <td>Division:CompanyCode </td>
                                <td><input title="User.Address.City" name="User.Address.City"/></td>
                            </tr>
                            <tr>
                                <td>SubDivision:CostCenter </td>
                                <td><input title="User.Address.State" name="User.Address.State"/></td>
                            </tr>
                            <tr>
                                <td>Group </td>
                                <td><input title="User.Address.PostalCode" name="User.Address.PostalCode"/></td>
                            </tr>
                            <tr>
                                <td>Contract:Hierarchy Code </td>
                                <td><input title="User.Address.Phone" name="User.Address.Phone"/></td>
                            </tr>
                            <tr>
                                <td>Preparer UniqueName </td>
                                <td><input title="User.Address.UniqueName" name="User.Address.UniqueName"/></td>
                            </tr>
                            <tr>
                                <td>MyWork:Preparer Country </td>
                                <td><input title="User.Address.Fax" name="User.Address.Fax"/></td>
                            </tr>
                            <tr>
                                <th colSpan="2">
                                    <div></div>
                                </th>
                            </tr>
                            <tr>
                                <td>Content Solution URL (E.G. https://s1.ariba.com): </td>
                                <td><input title="Url" defaultValue="https://s1.ariba.com" size="50" name="URL"/></td>
                            </tr>
                            <tr>
                                <th colSpan="2"><input id="btnsubmit" /* onClick= "ActionDeterminator()" */ defaultValue="Go to the Ariba Content catalog" type="submit" name="btnsubmit"/></th>
                            </tr>
                        </tbody>
                    </table>
                    <input defaultValue="thirdPartyUser" name="passwordadapter"/> <input defaultValue="http://cbcwstaxserviceexport.azurewebsites.net/Tax/Export" name="HOOK_URL"/>
                </form>
        </div>
    );
}