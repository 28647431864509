import React from 'react'

// Components
import { Divider, Grid } from '@material-ui/core'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'

export default function DnbDaSubCardV2App({ cardName, cardValue }) {
  console.log(`cardName: ${cardName}, cardValue: ${JSON.stringify(cardValue)}`)

  /*
  {
    cards: [
      'company information': {
        'pos01': 'hola mundo'
      },
      'records and others': {
        'records': ['100','200','300']
      },
      'people': {
        'role': {
          'a': 10,
          'b': 20
        }
      },
      'Risk Analysis': {
        'NordicRiskScore': [
          {
            'name': 'top1000',
            description: 40
          },
          {
            'name': 'Financial Risk',
            description: 'AA'
          }
        ]
      }
    ]
  }
  */

  if (typeof cardValue !== 'object') {
    return <>{cardValue}</>
  }

  if (Array.isArray(cardValue)) {
    return (
      <>
        {cardValue.map((value, index) => (
          <>
            {!!index && <Divider style={{ margin: '10px' }} />}
            <DnbDaSubCardV2App key={index} cardValue={value} />
          </>
        ))}
      </>
    )
  }

  return (
    <>
      {Object.entries(cardValue).map(([propName, propValue]) => {
        console.log(`propName: ${propName}, propValue: ${JSON.stringify(propValue)}`)
        return (
          <Grid container key={propName} alignItems="center">
            <Grid item xs={12} sm={3}>
              {propName}
            </Grid>
            <Grid item xs={12} sm={9}>
              <Card>
                <CardBody>
                  <DnbDaSubCardV2App cardValue={propValue} />
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        )
      })}
    </>
  )
}
