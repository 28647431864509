import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import FormControl from '@material-ui/core/FormControl'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'

import AddIcon from '@material-ui/icons/Add'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import React, { useState } from 'react'
import { ColumnsOrderLineItemsStandard, Taxes } from 'utils/Constants'
import { v4 as uuidv4 } from 'uuid'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function TaxLineModal(props) {
  const { showModal, onClose, handleOnSuccess, defaultValues, line } = props
  const lineTotal = line?.[ColumnsOrderLineItemsStandard.total.type]
    ? line[ColumnsOrderLineItemsStandard.total.type]
    : '0'
  const [taxes, setTaxes] = useState(
    defaultValues.length > 0
      ? JSON.parse(JSON.stringify(defaultValues))
      : [
          {
            id: uuidv4(),
            amount: '',
            rate: '',
            description: '',
            taxCode: '',
            type: ''
          }
        ]
  )
  const isReadOnly = props?.isReadOnly ?? false
  function onSuccess() {
    if (typeof handleOnSuccess === 'function') {
      handleOnSuccess(taxes)
    }

    onClose()
  }

  const handleChange = (event, lineId, column) => {
    const lineIndex = taxes.findIndex(x => x.id === lineId)

    if (lineIndex < 0) {
      return
    }

    setTaxes(old => {
      const copy = [...old]

      copy[lineIndex][column] = event.target.value

      return copy
    })
  }

  const handleInputChange = (event, lineId, column) => {
    const newValue = event.target.value
    const lineIndex = taxes.findIndex(x => x.id === lineId)

    if (lineIndex < 0) {
      return
    }

    setTaxes(old => {
      const copy = [...old]

      if (column === 'rate') {
        const taxAmount = `${((Number(newValue || 0) / 100) * Number(lineTotal)).toFixed(2)}`
        copy[lineIndex]['amount'] = taxAmount
      }

      if (column === 'amount') {
        const lineAmount = Number(lineTotal)

        if (isNaN(lineAmount) || lineAmount === 0) {
          copy[lineIndex]['rate'] = ''
        } else {
          const tempRate = Number(newValue || 0) / lineAmount

          const newRate = `${(Number(tempRate) * 100).toFixed(4)}`

          copy[lineIndex]['rate'] = newRate
        }
      }

      copy[lineIndex][column] = newValue

      return copy
    })
  }

  const deleteTax = (lineId: string) => {
    setTaxes(old => old.filter(x => x.id !== lineId))
  }

  const newTax = () => {
    setTaxes(old => [
      ...old,
      {
        id: uuidv4(),
        amount: '',
        rate: '',
        description: '',
        taxCode: '',
        type: ''
      }
    ])
  }

  const taxesByProcess = [Taxes.vatTax]

  const taxesOptions = taxesByProcess.map(x => (
    <MenuItem value={x.value} key={x.value}>
      {x.label}
    </MenuItem>
  ))

  const taxesFields = taxes.map(x => (
    <>
      <Grid item key={x.id} xs={12}>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tax Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={x.type}
                label="Tax Type"
                placeholder="Tax Type"
                onChange={e => handleChange(e, x.id, 'type')}
                disabled={isReadOnly}
              >
                {taxesOptions.length > 0 ? taxesOptions : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id="outlined-required"
              label="Amount"
              placeholder="0%"
              defaultValue={lineTotal}
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id="outlined-required"
              label="Tax Rate"
              placeholder="0%"
              value={x.rate}
              disabled={isReadOnly}
              onChange={e => handleInputChange(e, x.id, 'rate')}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id="outlined-required"
              label="Tax Amount"
              value={x.amount}
              onChange={e => handleInputChange(e, x.id, 'amount')}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              aria-label="delete"
              size="medium"
              disabled={isReadOnly}
              onClick={() => deleteTax(x.id)}
            >
              <DeleteForeverIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  ))

  return (
    <Dialog
      fullWidth
      open={showModal}
      TransitionComponent={Transition}
      onClose={() => onClose()}
      maxWidth={'md'}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Taxes
      </DialogTitle>

      <DialogContent id="notice-modal-slide-description">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              disabled={isReadOnly}
              color="primary"
              onClick={newTax}
            >
              Add Tax
            </Button>
          </Grid>
          {taxesFields.length > 0 ? taxesFields : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => onSuccess()}
          disabled={isReadOnly}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
