import React from 'react'

import { Badge, Box, TextField, Tooltip } from '@material-ui/core'

import { errorTypes, getError } from './useHeader'

const TextFieldLineItems = props => {
  const { label, field, handleChange, value, lineId, readOnly, lines, lineItemsActions } = props

  const { getColors, classes } = lineItemsActions

  const lineErrors = lines.errors.find(x => x.id === lineId)

  const fieldError = lineErrors?.errors?.find(x => x.field === field.standardField)
  const errorData = getError(fieldError)
  let typeMessage = ''
  const criticalType = fieldError?.details?.find(x => x.criticalityType === 'critical')

  if (criticalType) {
    typeMessage = 'critical'
  }

  const isError = typeMessage === errorTypes.critical
  const errorMessage = errorData ? errorData?.message : ''
  const isVisible = Boolean(errorData)

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Tooltip
        classes={{
          arrow: isError ? classes.errorColor : '',
          tooltip: isError ? classes.tooltipError : ''
        }}
        title={isVisible ? errorMessage : ''}
        arrow
        placement="right"
      >
        <Badge
          classes={{
            badge: isError ? classes.badgeError : ''
          }}
          badgeContent={isError ? '!' : null}
          invisible={isVisible ? false : true}
        >
          <TextField
            value={value}
            onChange={event => {
              handleChange(event.target.value)
            }}
            style={{ width: '100%' }}
            label={label ?? ''}
            variant="outlined"
            size="medium"
            fullWidth
            error={isError}
            disabled={readOnly ?? false}
            className={readOnly ? classes.readOnly : ''}
            InputProps={{
              readOnly: readOnly ?? false,
              classes: {
                notchedOutline: !isError ? getColors(errorData).inputColor : ''
              }
            }}
            InputLabelProps={{
              classes: {
                root: !isError ? getColors(errorData).color : '',
                focused: !isError ? getColors(errorData).color : ''
              }
            }}
          />
        </Badge>
      </Tooltip>
    </Box>
  )
}

export default TextFieldLineItems
