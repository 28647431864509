import React, { useEffect, useRef, useState } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@material-ui/core/Fade'
// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp'
import Add from '@material-ui/icons/PlaylistAdd'
import AddAlert from '@material-ui/icons/AddAlert'
import EditIcon from '@material-ui/icons/Edit'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import ClearIcon from '@material-ui/icons/Clear'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import SettingsIcon from '@material-ui/icons/Settings'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

// Views

import AppMainMenu from '../CustomGenericViews/AppMainMenu.js'
import Wizard from '../../components/Wizard/Wizard.js'
import PIIEmails from '../../views/PIIEmails/PIIEmails.js'
import ConnectivityView from '../CustomGenericViews/ConnectivityView.js'
import { saveConfiguration } from '../../services/apiApps.js'
import CustomTreeTable from 'components/CustomTreeTable/CustomTreeTable.js'
import CustomModal from 'components/CustomModal/CustomModal.js'
import { showLoading, hideLoading } from 'utils/functions.js'
import OneStringModal from './OneStringModal.js'
import RowEditModal from './RowEditModal.js'
import CustomValidationsModal from './CustomValidationsModal'
import TypesModal from './TypesModal'
import { saveAppdetails } from './DataAssureContainer.js'
import CustomConfirmAlert from 'components/CustomConfirmAlert/CustomConfirmAlert.js'
import { cloneObject } from 'utils/functions.js'
import ParametersAccordion from '../CustomGenericViews/ParametersAccordion.js'
import AddressInternational from '../AddressInternational/AddressInternationalViewV2.js'

//utils
import { incrementAlphanumeric } from 'utils/functions.js'
import DnBMappingReact from './DnB/DnBMappingReact.js'
import DnBQuestions from './DnB/DnBQuestions.js'

import { ServiceType } from 'utils/Constants'

const useStyles = makeStyles(styles)

export default function DataAssure(props) {
  const [configTableElement, setConfigTableElement] = useState(false)
  const [configTableShowModal, setConfigTableShowModal] = useState(undefined)
  const [configurationDetails, setConfigurationDetails] = useState('main')
  const [configurationDetailsLabel, setConfigurationDetailsLabel] = useState('')
  const [customValidationProps, setCustomValidationProps] = useState(undefined)
  const [selectedQuestion, setSelectedQuestion] = useState(undefined)
  const [service, setService] = useState('')
  const [showConfiguration, setShowConfiguration] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [showDeleteConfirmQuestion, setShowDeleteConfirmQuestion] = useState(false)
  const [showDnBQuestions, setShowDnBQuestions] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalTypes, setShowModalTypes] = useState(false)
  const [showQuestions, setShowQuestions] = useState(false)
  const [tableElement, setTableElement] = useState(undefined)

  const refreshTableFunction = useRef(() => {})

  const isMounted = useRef(false)
  const dnbTypes = {
    address: [
      {
        id: '',
        type: 'extendedAddress',
        values: {
          extendedAddress: {
            label: 'Extended Address',
            required: true,
            value: ''
          }
        }
      },
      {
        id: '',
        type: 'address',
        values: {
          address: {
            label: 'Address',
            required: true,
            value: ''
          }
        }
      },
      {
        id: '',
        type: 'generic',
        values: {
          city: {
            label: 'City',
            order: 2,
            required: true,
            value: ''
          },
          country: {
            label: 'Country',
            order: 1,
            required: true,
            value: ''
          },
          postalCode: {
            label: 'Postal Code',
            order: 3,
            required: true,
            value: ''
          },
          region: {
            label: 'Region',
            order: 4,
            required: true,
            value: ''
          },
          street: {
            label: 'Street',
            order: 5,
            required: true,
            value: ''
          },
          street2: {
            label: 'Street 2',
            order: 6,
            required: true,
            value: ''
          }
        }
      }
    ],
    supplier: {
      id: 'Supplier Name',
      index: 0,
      type: 'generic',
      values: {
        legalName: {
          label: 'Legal Name',
          order: 1,
          required: true,
          value: ''
        }
      }
    }
  }
  const customValidationsRegEx = /customValidation*[1-9]/
  useEffect(
    () => {
      // did mount
      if (!isMounted.current) {
        //getMapping()
        isMounted.current = true
      }
      // did update
      else {
      }

      //this function will be ran when the component is re-renderd or unmounted
      return () => {}
    },
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    []
  )
  function getDeleteConfirmQuestion() {
    return (
      <CustomConfirmAlert
        message={'Are you Sure you want to delete question?'}
        title={'Delete'}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        onCancel={() => setShowDeleteConfirmQuestion(false)}
        onConfirm={() => {
          let originalValidationsForm = cloneObject(props.validationsForm)
          let rollbackFunction = () => {
            props.setValidationsForm(originalValidationsForm)
          }
          let validationsForm = cloneObject(props.validationsForm)
          validationsForm[configurationDetails].mappings[tableElement.parentKey].questions.splice(selectedQuestion.index, 1)
          props.setValidationsForm(validationsForm)
          setSelectedQuestion(undefined)
          setShowDeleteConfirmQuestion(false)
          setTableElement(validationsForm[configurationDetails].mappings[tableElement.parentKey])
          saveToDataBase(validationsForm, rollbackFunction, 'Deleted')
        }}
      ></CustomConfirmAlert>
    )
  }
  function getDeleteConfirm() {
    return (
      <CustomConfirmAlert
        message={'Are you Sure you want to delete the configuration?'}
        title={'Delete'}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={() => {
          let originalValidationsForm = cloneObject(props.validationsForm)
          let rollbackFunction = () => {
            props.setValidationsForm(originalValidationsForm)
          }

          let validationsForm = cloneObject(props.validationsForm)
          if (!tableElement.isRequest) {
            let tempValidationForm = validationsForm
            delete tempValidationForm[configurationDetails].mappings[tableElement.parentKey]
            props.setValidationsForm(tempValidationForm)
            setSelectedQuestion(undefined)
            setShowDeleteConfirmQuestion(false)
            setTableElement(tempValidationForm[configurationDetails].mappings[tableElement.parentKey])
            saveToDataBase(tempValidationForm, rollbackFunction, 'Deleted')
          } else {
            delete validationsForm[configurationDetails].parameters.parameterValues[tableElement.parentKey]
            props.setValidationsForm(validationsForm)
            setSelectedQuestion(undefined)
            setShowDeleteConfirmQuestion(false)
            setTableElement(validationsForm[configurationDetails].mappings[tableElement.parentKey])
            saveToDataBase(validationsForm, rollbackFunction, 'Deleted')
          }
          if (refreshTableFunction && refreshTableFunction.current) refreshTableFunction.current()
          else props.setRefresh(!props.refresh)
          setShowDeleteConfirm(false)
        }}
      ></CustomConfirmAlert>
    )
  }

  function deleteCustomValidation(key) {
    let originalValidationsForm = cloneObject(props.validationsForm)
    let rollbackFunction = () => {
      props.setValidationsForm(originalValidationsForm)
      setTimeout(() => {
        props.setRefresh(true)
        setTimeout(() => {
          props.setRefresh(false)
        }, 125)
      }, 125)
    }
    let validationsForm = cloneObject(props.validationsForm)
    delete validationsForm[key]
    validationsForm.customCorrelativeId -= 1
    props.setValidationsForm(validationsForm)
    props.setRefresh(!props.refresh)
    saveToDataBase(validationsForm, rollbackFunction, 'Deleted', true, false)
  }

  function getQuestionsData() {
    let data = []

    data = tableElement.questions.map((question, index) => {
      let row = {}
      row.id = <Typography>{question.id}</Typography>
      row.type = <Typography>{question.type}</Typography>
      question.index = index
      row.action = (
        <GridContainer>
          <GridItem xs={6}>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  setSelectedQuestion(question)
                  setShowModalTypes(true)
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </GridItem>
          <GridItem xs={6}>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setSelectedQuestion(question)
                  setShowDeleteConfirmQuestion(true)
                }}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </GridItem>
        </GridContainer>
      )
      return row
    })

    return data
  }

  function addNewCustomValidation(newUniqueName) {
    let successMessage = 'Created Successfully'
    let originalState = cloneObject(props.validationsForm)
    let rollbackFunction = () => {
      props.setValidationsForm(originalState)
      setTimeout(() => {
        props.setRefresh(true)
        setTimeout(() => {
          props.setRefresh(false)
        }, 125)
      }, 125)
    }
    let validationsForm = cloneObject(props.validationsForm)
    let keys = Object.keys(validationsForm)
    keys = keys.filter(val => {
      return customValidationsRegEx.test(val)
    })

    let refreshView = true
    let newkey = 'customValidation1'
    let newName = 'Custom Validation 1'
    if (keys.length > 0) {
      newkey = keys.reduce(function(p, v) {
        return p.localeCompare(v, undefined, {
          numeric: true,
          sensitivity: 'base'
        }) > 0
          ? p
          : v
      })
      if (!isNaN(Number(newkey))) {
        newkey = (Number(newkey) + 1).toString()
      } else {
        newkey = incrementAlphanumeric(newkey)
      }

      let keysNames = keys.map(key => {
        return validationsForm[key].title
      })

      newName = keysNames.reduce(function(p, v) {
        return p.localeCompare(v, undefined, {
          numeric: true,
          sensitivity: 'base'
        }) > 0
          ? p
          : v
      })

      newName = incrementAlphanumeric(newName)
    }

    let validationForm = {
      newkey,
      newCustomDescription: newUniqueName,
      newName
    }

    validationsForm.customCorrelativeId += 1
    validationsForm[newkey] = {
      configurable: true,
      configured: false,
      enabled: false,
      title: newName,
      description: newUniqueName,
      isCustomValidation: true
    }
    props.setValidationsForm(validationsForm)
    if (refreshView) props.setRefresh(!props.refresh)
    else if (refreshTableFunction.current) refreshTableFunction.current()
    else props.setRefresh(!props.refresh)
    saveToDataBase2(validationForm, rollbackFunction, successMessage)
  }

  async function saveToDataBase2(
    configurationProps,
    rollbackFunction,
    successMessage,
    errorMessage = 'Something went wrong, please try again later',
    refreshPage = false
  ) {
    showLoading()
    let response = await saveConfiguration(
      'slp',
      props.validationsForm.env,
      configurationProps,
      props.validationsForm.service,
      'new_custom_validation'
    )

    if (response.success) {
      props.setValidationMessage(successMessage)
      props.setSubmitSuccess(true)
      setTimeout(() => {
        props.setSubmitSuccess(false)
      }, 5000)
      if (response.data) {
        let validationForm = props.validationsForm
        validationForm[configurationProps.newkey] = response.data[configurationProps.newkey]
        props.setValidationsForm(validationForm)
        await saveAppdetails(props, response, false, refreshPage)
      }
    } else {
      props.setValidationMessage(errorMessage)
      props.setCreateError(true)
      setTimeout(() => {
        props.setCreateError(false)
      }, 5000)
      if (rollbackFunction) {
        rollbackFunction(() => {
          props.setRefresh(!props.refresh)
          if (refreshTableFunction.current) refreshTableFunction.current()
        })
      }
    }

    hideLoading()
  }

  async function saveToDataBase(
    configurationProps,
    rollbackFunction,
    successMessage,
    refreshPage = false,
    changeConfigured = true,
    errorMessage = 'Something went wrong, please try again later'
  ) {
    console.log('config to save', { con: configurationProps })
    let newConfigurationProp = cloneObject(configurationProps)
    if (changeConfigured) newConfigurationProp[configurationDetails].configured = true

    delete newConfigurationProp.displayMode
    delete newConfigurationProp.mapping
    if (configurationDetails.includes('delete_')) {
      let keys = Object.keys(newConfigurationProp)
      keys.forEach(key => {
        if (typeof newConfigurationProp[key] === 'object') {
          delete newConfigurationProp[key]
        }
      })
    } else if (configurationDetails !== 'main') {
      delete newConfigurationProp.parameters
      delete newConfigurationProp[configurationDetails].configurationOnClick
      delete newConfigurationProp.environment

      let keys = Object.keys(newConfigurationProp)

      keys.forEach(key => {
        if (typeof newConfigurationProp[key] === 'object' && key !== configurationDetails) {
          delete newConfigurationProp[key]
        }
      })
      keys = Object.keys(newConfigurationProp[configurationDetails].parameters)
      keys.forEach(paramKey => {
        delete newConfigurationProp[configurationDetails].parameters[paramKey].errorMessage
        delete newConfigurationProp[configurationDetails].parameters[paramKey].showErrorIcon
        delete newConfigurationProp[configurationDetails].parameters[paramKey].showErrorIconDiv
        delete newConfigurationProp[configurationDetails].parameters[paramKey].state
      })
    } else {
      let keys = Object.keys(newConfigurationProp)
      keys.forEach(key => {
        if (typeof newConfigurationProp[key] === 'object') {
          if (newConfigurationProp[key].configurationOnClick) delete newConfigurationProp[key].configurationOnClick
          if (newConfigurationProp[key].parameters) delete newConfigurationProp[key].parameters
          if (newConfigurationProp[key].mappings) delete newConfigurationProp[key].mappings
        }
      })
    }
    showLoading()

    let response = await saveConfiguration(
      'slp',
      props.validationsForm.env,
      newConfigurationProp,
      props.validationsForm.service,
      configurationDetails
    )
    if (response.success) {
      props.setValidationMessage(successMessage)
      props.setSubmitSuccess(true)
      setTimeout(() => {
        props.setSubmitSuccess(false)
      }, 5000)
      if (response.data) {
        console.log('saved data response', { context: response })
        await saveAppdetails(props, response, false, refreshPage)
      }
    } else {
      props.setValidationMessage(errorMessage)
      props.setCreateError(true)
      setTimeout(() => {
        props.setCreateError(false)
      }, 5000)
      if (rollbackFunction) {
        rollbackFunction(() => {
          props.setRefresh(!props.refresh)
          if (refreshTableFunction.current) refreshTableFunction.current()
        })
      }
    }
    hideLoading()
  }

  function getTypesModal() {
    let constantTypeConstantsString = JSON.stringify(props.typesConstants[configurationDetails])

    let modal = (
      <CustomModal
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        onCancel={() => {
          setShowModalTypes(false)
          setSelectedQuestion(undefined)
        }}
        onConfirm={() => {
          setShowModalTypes(false)
          setSelectedQuestion(undefined)
        }}
      >
        <div>{`No Types Configured for ${configurationDetails}`}</div>
      </CustomModal>
    )
    let originalValidationsForm = cloneObject(props.validationsForm)
    let rollbackFunction = () => {
      props.setValidationsForm(originalValidationsForm)
    }

    if (constantTypeConstantsString)
      modal = (
        <TypesModal
          question={selectedQuestion}
          types={JSON.parse(constantTypeConstantsString)}
          title={'Add Question'}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          onCancel={() => {
            setShowModalTypes(false)
            setSelectedQuestion(undefined)
          }}
          onConfirm={newQuestionalue => {
            if (newQuestionalue) {
              let validationsForm = props.validationsForm
              if (newQuestionalue && newQuestionalue.values) {
                let key = undefined
                for (key of Object.keys(newQuestionalue.values)) {
                  if (newQuestionalue.values[key] && newQuestionalue.values[key].value)
                    newQuestionalue.values[key].value = newQuestionalue.values[key].value.trim()
                }
              }
              if (!selectedQuestion) {
                tableElement.questions.push(newQuestionalue)
              }
              validationsForm[configurationDetails].mappings[tableElement.parentKey].questions = tableElement.questions

              props.setValidationsForm(validationsForm)
              props.setRefresh(!props.refresh)

              setSelectedQuestion(undefined)
              saveToDataBase(validationsForm, rollbackFunction, 'Saved')
            }
            setShowModalTypes(false)
          }}
        ></TypesModal>
      )
    return modal
  }
  function editMessageModal() {
    let modal = ''
    let originalValidationsForm = cloneObject(props.validationsForm)
    modal = (
      <RowEditModal
        messageLabel={'Message'}
        enableLabel={'Enabled'}
        typeLabel={'Type'}
        visibleLabel={'Visible'}
        descriptionLabel={'Description'}
        title={'Modify Configuration'}
        description={configTableElement.description ? configTableElement.description : ''}
        active={configTableElement.active ? configTableElement.active : false}
        visible={configTableElement.visible ? configTableElement.visible : false}
        message={configTableElement.message ? configTableElement.message : ''}
        group={configTableElement.group ? configTableElement.group : ''}
        type={configTableElement.type ? configTableElement.type : 'info'}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        onCancel={() => setConfigTableShowModal(false)}
        onConfirm={returnValues => {
          console.log(returnValues, 'return value')
          if (returnValues) {
            if (returnValues.groupValue) {
              //Is a international address element
              let search = props.validationsForm.addressValidation.parameters.validationInternationalMessages //Get the international addres object
              const arrayMessages = search[returnValues.groupValue] //Get the elements that match with te group value
              const keys_message = Object.keys(arrayMessages)
              let messageInternationalAddress = ''
              for (var i = 0; i < keys_message.length; i++) {
                const messageSearch = arrayMessages[keys_message[i]] //Get each value of the JSON messages
                if (messageSearch.description === configTableElement.description) {
                  //Find the correct message into the object
                  messageInternationalAddress = messageSearch
                  break
                }
              }

              messageInternationalAddress.message = returnValues.messageValue
              messageInternationalAddress.type = returnValues.typeValue
              messageInternationalAddress.visible = returnValues.visibleValue
              messageInternationalAddress.active = returnValues.enableValue
            }

            configTableElement.message = returnValues.messageValue
            configTableElement.type = returnValues.typeValue
            configTableElement.visible = returnValues.visibleValue
            configTableElement.active = returnValues.enableValue
            let validationForm2 = cloneObject(props.validationsForm)
            props.setValidationsForm(validationForm2)
          }

          let rollbackFunction = () => {
            props.setValidationsForm(originalValidationsForm)
          }

          saveToDataBase(props.validationsForm, rollbackFunction, 'Modified Successfully', false)
          setConfigTableShowModal(false)
        }}
      ></RowEditModal>
    )
    return modal
  }

  function editCustomModal(modalProps, isNew) {
    let modal = ''
    let successMessage = ''
    let originalValidationsForm = cloneObject(props.validationsForm)
    modal = (
      <CustomValidationsModal
        descriptionLabel={modalProps.descriptionLabel}
        enableLabel={modalProps.enableLabel}
        typeLabel={modalProps.typeLabel}
        kiLabel={modalProps.kiLabel}
        sendParameterLabel={modalProps.sendParameterLabel}
        responseKILabel={modalProps.responseKILabel}
        parameterNameLabel={modalProps.parameterNameLabel}
        defaultValueLabel={modalProps.defaultValueLabel}
        responseFieldLabel={modalProps.responseFieldLabel}
        subtypeLabel={modalProps.subtypeLabel}
        title={modalProps.title}
        descriptionHidden={modalProps.descriptionHidden}
        defaultValueHidden={modalProps.defaultValueHidden}
        responseFieldHidden={modalProps.responseFieldHidden}
        parameterNameHidden={modalProps.parameterNameHidden}
        kiHidden={modalProps.kiHidden}
        typeValueHidden={modalProps.typeValueHidden}
        enabledValueHidden={modalProps.enabledValueHidden}
        sendParameterHidden={modalProps.sendParameterHidden}
        description={!isNew && configTableElement.description ? configTableElement.description : ''}
        ki={!isNew && configTableElement.ki ? configTableElement.ki : ''}
        enabled={!isNew && configTableElement.enabled ? configTableElement.enabled : false}
        sendParameter={!isNew && configTableElement.sendParameter ? configTableElement.sendParameter : false}
        type={!isNew && configTableElement.type ? configTableElement.type : 'generic'}
        parameterName={!isNew && configTableElement.parameterName ? configTableElement.parameterName : ''}
        responseKI={!isNew && configTableElement.responseKI ? configTableElement.responseKI : ''}
        responseField={!isNew && configTableElement.responseField ? configTableElement.responseField : ''}
        defaultValue={!isNew && configTableElement.defaultValue ? configTableElement.defaultValue : ''}
        subtype={!isNew && configTableElement.subtype ? configTableElement.subtype : ''}
        service={modalProps.service}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        onCancel={() => (isNew ? setShowModal(false) : setConfigTableShowModal(false))}
        onConfirm={returnValues => {
          if (returnValues) {
            if (modalProps.action === 'request') {
              if (!isNew) {
                configTableElement.description = returnValues.descriptionValue
                configTableElement.type = returnValues.typeValue
                configTableElement.ki = returnValues.kiValue
                configTableElement.enabled = returnValues.enableValue
                configTableElement.parameterName = returnValues.parameterNameValue
                configTableElement.sendParameter = returnValues.sendParameterValue
                configTableElement.responseKI = returnValues.responseKIValue
                configTableElement.subtype = returnValues.subtypeValue
                let validationForm2 = cloneObject(props.validationsForm)
                props.setValidationsForm(validationForm2)
                successMessage = 'Modified Successfully'
              } else {
                successMessage = 'Item Created Successfully'
                let validationForm = props.validationsForm
                let keys = Object.keys(validationForm[configurationDetails].parameters.parameterValues)

                let refreshView = true
                let newkey = 'P1'
                if (keys.length > 0) {
                  refreshView = false
                  newkey = keys.reduce(function(p, v) {
                    return p > v ? p : v
                  })
                  if (!isNaN(Number(newkey))) {
                    newkey = (Number(newkey) + 1).toString()
                  } else {
                    newkey = incrementAlphanumeric(newkey)
                  }
                }

                let parametersValues = {
                  description: returnValues.descriptionValue,
                  enabled: returnValues.enableValue,
                  ki: returnValues.kiValue,
                  parameterName: returnValues.parameterNameValue,
                  responseKI: returnValues.responseKIValue,
                  sendParameter: returnValues.sendParameterValue,
                  type: returnValues.typeValue,
                  subtype: returnValues.subtypeValue,
                  parentKey: newkey
                }

                let validationFormParameters = cloneObject(validationForm[configurationDetails].parameters)
                validationFormParameters.parameterValues[newkey] = parametersValues
                validationForm[configurationDetails].parameters = validationFormParameters

                props.setValidationsForm(validationForm)
                if (refreshView) props.setRefresh(!props.refresh)
                else if (refreshTableFunction.current) refreshTableFunction.current()
                else props.setRefresh(!props.refresh)
              }
            } else if (modalProps.action === 'response') {
              if (isNew) {
                successMessage = 'Item Created Successfully'
                let validationForm = props.validationsForm
                let keys = Object.keys(validationForm[configurationDetails].mappings)

                let refreshView = true
                let newkey = 'R1'
                if (keys.length > 0) {
                  refreshView = false
                  newkey = keys.reduce(function(p, v) {
                    return p > v ? p : v
                  })
                  if (!isNaN(Number(newkey))) {
                    newkey = (Number(newkey) + 1).toString()
                  } else {
                    newkey = incrementAlphanumeric(newkey)
                  }
                }

                let newData = {
                  description: returnValues.descriptionValue,
                  enabled: returnValues.enableValue,
                  ki: returnValues.kiValue,
                  defualtValue: returnValues.defaultValue,
                  responseField: returnValues.responseFieldValue,
                  parentKey: newkey
                }

                let newMappings = cloneObject(validationForm[configurationDetails].mappings)
                newMappings[newkey] = newData
                validationForm[configurationDetails].mappings = newMappings

                props.setValidationsForm(validationForm)
                if (refreshView) props.setRefresh(!props.refresh)
                else if (refreshTableFunction.current) refreshTableFunction.current()
                else props.setRefresh(!props.refresh)
              } else {
                successMessage = 'Modified Successfully'
                configTableElement.description = returnValues.descriptionValue
                configTableElement.ki = returnValues.kiValue
                configTableElement.enabled = returnValues.enableValue
                configTableElement.defaultValue = returnValues.defaultValue
                configTableElement.responseField = returnValues.responseFieldValue
                let validationForm2 = cloneObject(props.validationsForm)
                props.setValidationsForm(validationForm2)
              }
            }
          }

          let rollbackFunction = () => {
            props.setValidationsForm(originalValidationsForm)
          }

          saveToDataBase(props.validationsForm, rollbackFunction, successMessage, false)
          if (isNew) setShowModal(false)
          else setConfigTableShowModal(false)
        }}
      ></CustomValidationsModal>
    )
    return modal
  }

  function getModal() {
    let modal = ''

    modal = (
      <OneStringModal
        label={props.validationsForm.service === 'coupa' ? 'Name' : 'Unique Name'}
        title={
          tableElement
            ? props.validationsForm.service === 'coupa'
              ? 'Modify Easy Form'
              : 'Modify Questionnaire Name'
            : props.validationsForm.service === 'coupa'
            ? 'Easy Form'
            : 'Create Questionnaire'
        }
        initValue={tableElement ? tableElement.uniqueName : ''}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        onCancel={() => setShowModal(false)}
        onConfirm={newUniqueName => {
          let successMessage = 'Created Successfully'
          let originalValidationsForm = cloneObject(props.validationsForm)
          let rollbackFunction = () => {
            props.setValidationsForm(originalValidationsForm)
          }
          if (tableElement) {
            successMessage = 'Modified Successfully'
            let validationForm = props.validationsForm
            validationForm[configurationDetails].mappings[tableElement.parentKey].uniqueName = newUniqueName
            props.setValidationsForm(validationForm)
          } else {
            let validationForm = props.validationsForm
            let keys = Object.keys(validationForm[configurationDetails].mappings)
            let refreshView = true
            let newkey = findAvailablePosition(keys)
            newkey = Number(newkey).toString()
            let mappingConstant2 = {}
            //clone object
            mappingConstant2 = cloneObject(props.mappingConstant)

            mappingConstant2.uniqueName = newUniqueName
            if (props.validationsForm[configurationDetails].isDnB) {
              delete mappingConstant2.questions
              const dnbTypes2 = cloneObject(dnbTypes)
              mappingConstant2.dnbQuestions = [{ supplierInformation: dnbTypes2.supplier }]
              validationForm[configurationDetails].mappings[newkey] = mappingConstant2
            } else validationForm[configurationDetails].mappings[newkey] = mappingConstant2
            props.setValidationsForm(validationForm)
            if (refreshView) props.setRefresh(!props.refresh)
            else if (refreshTableFunction.current) refreshTableFunction.current()
            else props.setRefresh(!props.refresh)
          }

          saveToDataBase(props.validationsForm, rollbackFunction, successMessage)
          setShowModal(false)
        }}
      ></OneStringModal>
    )
    return modal
  }

  function findAvailablePosition(numbers) {
    // Convert the array of strings to an array of integers
    const numArray = numbers.map(numStr => parseInt(numStr))

    // Sort the array in ascending order
    numArray.sort((a, b) => a - b)

    // Initialize variables to store the first available position and the first largest consecutive number
    let firstAvailablePosition = null
    let firstLargestConsecutive = null

    for (let i = 1; i < numArray.length; i++) {
      if (numArray[i] - numArray[i - 1] > 1) {
        // Found an available position between two numbers
        firstAvailablePosition = numArray[i - 1] + 1
        break // Stop the search
      }
    }

    if (!firstAvailablePosition) {
      // If no available position was found, find the first largest consecutive number
      firstLargestConsecutive = (numArray[numArray.length - 1] || 0) + 1
    }

    if (firstAvailablePosition !== null) {
      return firstAvailablePosition
    } else {
      return firstLargestConsecutive
    }
  }

  const classes = useStyles()
  function getView() {
    if (props.pageIsLoading) {
      return <div></div>
    }
    function scrolltoTop() {
      let element = document.getElementById('MainGrid')
      if (element) element.scrollTo(0, 0)
    }

    function getAdminSteps() {
      let switchChildren = {}
      Object.keys(props.validationsForm).forEach(key => {
        if (props.validationsForm[key] && props.validationsForm[key].configurable) {
          switchChildren[key] = props.validationsForm[key]
          switchChildren[key].id = key
          switchChildren[key].configurationOnClick = (id, state) => {
            setConfigurationDetails(id.toString())
            setConfigurationDetailsLabel(state.mapping.switchChildren[id].title)
            scrolltoTop()
            setShowConfiguration(true)
          }
        }
      })
      switchChildren = Object.keys(switchChildren)
        .sort((a, b) => {
          if (customValidationsRegEx.test(a) && customValidationsRegEx.test(b)) {
            return a.localeCompare(b)
          } else if (customValidationsRegEx.test(a) && !customValidationsRegEx.test(b)) {
            return 1
          } else if (!customValidationsRegEx.test(a) && customValidationsRegEx.test(b)) {
            return -1
          } else {
            return a.localeCompare(b)
          }
        })
        // eslint-disable-next-line
        .reduce((r, k) => ((r[k] = switchChildren[k]), r), {})

      let validationInitialState = props.validationsForm
      validationInitialState.env = validationInitialState.environment

      let allQuestionnaireResponseParameters =
        Object.keys(switchChildren).length > 0
          ? props.validationsForm.parameters.questionnaireResponse
            ? props.validationsForm.parameters.questionnaireResponse
            : {}
          : {}

      let allApprovalApiParameters =
        Object.keys(switchChildren).length > 0
          ? props.validationsForm.parameters.documentApprovalApi
            ? props.validationsForm.parameters.documentApprovalApi
            : {}
          : {}

      let allGeneralParameters = Object.keys(switchChildren).length > 0 ? props.validationsForm.parameters : {}
      let generalParameters = {}
      let questionnaireResponseParameters = {}
      let approvalApiParameters = {}
      let documentApiParameters = {}
      let supplierDataApiParameters = {}
      let parameterKeys = Object.keys(allQuestionnaireResponseParameters)
      parameterKeys = parameterKeys.concat(Object.keys(allApprovalApiParameters))

      parameterKeys.forEach(key => {
        if (allQuestionnaireResponseParameters[key]?.includeIn === 'approvalApiParameters') {
          approvalApiParameters[key] = allQuestionnaireResponseParameters[key]
        } else if (allQuestionnaireResponseParameters[key]?.includeIn === 'supplierDataApiParameters') {
          supplierDataApiParameters[key] = allQuestionnaireResponseParameters[key]
        } else {
          questionnaireResponseParameters[key] = allQuestionnaireResponseParameters[key]
        }
      })

      parameterKeys.forEach(key => {
        if (allApprovalApiParameters[key]?.includeIn === 'documentApprovalApi') {
          documentApiParameters[key] = allApprovalApiParameters[key]
        }
      })

      parameterKeys = Object.keys(allGeneralParameters)
      parameterKeys = parameterKeys.concat(Object.keys(allApprovalApiParameters))

      parameterKeys.forEach(key => {
        if (allGeneralParameters[key]?.includeIn === 'approvalApiParameters') {
          approvalApiParameters[key] = allGeneralParameters[key]
        } else if (allGeneralParameters[key]?.includeIn === 'supplierDataApiParameters') {
          supplierDataApiParameters[key] = allGeneralParameters[key]
        } else if (allApprovalApiParameters[key]?.includeIn === 'documentApprovalApi') {
          documentApiParameters[key] = allApprovalApiParameters[key]
        } else {
          generalParameters[key] = allGeneralParameters[key]
        }
      })

      let mapping = {
        documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
        setService: setService,
        service: service,
        configureService: props.displayMode === 'create',
        configureRealm: false,
        enabled: props.validationsForm.enabled,
        mainSwitchText: 'Enable Data Assure',
        mainSwitchOnChanged: (event, state) => {},
        title: 'Data Assure Settings',
        topChildren: '',
        bottomChildren: <div></div>,
        applicationTitle: 'Application',
        switchChildrenTitle: 'Integration',
        switchChildren: switchChildren
      }

      if (props.displayMode === 'create') {
        validationInitialState.env = ''
        mapping = {
          documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
          setService: setService,
          configureService: props.displayMode === 'create',
          configureServiceExclusions: [ServiceType.s4Hana],
          configureRealm: false,
          enabled: props.validationsForm.enabled,
          mainSwitchText: 'Enable Data Assure',
          mainSwitchOnChanged: (event, state) => {},
          title: 'Data Assure Settings',
          topChildren: '',
          bottomChildren: <div></div>,
          applicationTitle: 'Application',
          switchChildrenTitle: '',
          switchChildren: {}
        }
      }

      validationInitialState.mapping = mapping
      if (props.displayMode !== 'create') {
        validationInitialState.addButtonText = 'Add Custom Validation'
        validationInitialState.modalTitle = 'Add New Custom Validation'
        validationInitialState.deleteModalMessage = 'Are you sure you want to delete this custom validation?'
        validationInitialState.deleteButtonText = 'Delete custom validation'
        validationInitialState.onAddButtonClick = addNewCustomValidation
        validationInitialState.showAddButton = true
        validationInitialState.onDeleteConfirm = deleteCustomValidation
        validationInitialState.setConfigurationDetails = setConfigurationDetails
      }

      let steps = [
        {
          stepName: 'Validations',
          stepComponent: AppMainMenu,
          stepId: 'validations',
          initialState: validationInitialState
        }
      ]
      let connectivityParameters = []

      if (props.displayMode === 'edit') {
        steps.push({
          stepName: 'Connectivity',
          stepComponent: ParametersAccordion,
          stepId: 'connectivity',
          initialState: {
            documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,

            parameters: connectivityParameters
          }
        })
        connectivityParameters.push({
          title: 'General Configurations',
          parentId: 'GeneralConfigurations',
          conectivityMapping: {
            env: props.validationsForm.environment,
            app: 'slp',
            ignoreIds: ['questionnaireResponse'],
            service: props.validationsForm.service,
            setService: setService,
            hideServiceName: true,
            title: '',
            region: props.validationsForm.parameters.region,
            siteUrl: props.validationsForm.parameters.siteUrl,
            fieldsTitle: 'Integration',
            hideTestConnection: ['ariba', 'generic', 'wss', 'workday'].includes(props.validationsForm.service),
            fields: generalParameters
          }
        })

        if (Object.keys(questionnaireResponseParameters).length) {
          connectivityParameters.push({
            title: 'Response Configurations',
            parentId: 'ResponseConfigurations',
            conectivityMapping: {
              service: props.validationsForm.service,
              setService: setService,
              env: props.validationsForm.environment,
              app: 'slp',
              parametersPosition: 'questionnaireResponse',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              hideServiceName: true,
              hideTestConnection: true,
              title: '',
              fieldsTitle: 'Integration',
              fields: questionnaireResponseParameters
            }
          })
        }

        if (props.validationsForm.service === 'ariba') {
          connectivityParameters.push(
            {
              title: 'External Approval API for Sourcing and Supplier Management',
              parentId: 'approvalApiParameters',
              conectivityMapping: {
                service: props.validationsForm.service,
                setService: setService,
                env: props.validationsForm.environment,
                app: 'slp',
                region: props.validationsForm.parameters.region,
                siteUrl: props.validationsForm.parameters.siteUrl,
                hideServiceName: true,
                hideTestConnection: props.validationsForm.parameters.questionnaireResponse
                  ? !props.validationsForm.parameters.questionnaireResponse.showTestConnection
                  : false,
                title: '',
                fieldsTitle: '',
                fields: approvalApiParameters
              }
            },
            {
              title: 'Supplier Data API with Pagination',
              parentId: 'supplierDataApiParameters',
              conectivityMapping: {
                service: props.validationsForm.service,
                setService: setService,
                env: props.validationsForm.environment,
                app: 'slp',
                parametersPosition: 'questionnaireResponse',
                region: props.validationsForm.parameters.region,
                siteUrl: props.validationsForm.parameters.siteUrl,
                hideServiceName: true,
                hideTestConnection: props.validationsForm.parameters.questionnaireResponse
                  ? !props.validationsForm.parameters.questionnaireResponse.showTestConnection
                  : false,
                title: '',
                fieldsTitle: '',
                fields: supplierDataApiParameters
              }
            },
            {
              title: 'Document Approval Api',
              parentId: 'documentApprovalApi',
              conectivityMapping: {
                service: props.validationsForm.service,
                setService: setService,
                env: props.validationsForm.environment,
                app: 'slp',
                parametersPosition: 'documentApprovalApi',
                region: props.validationsForm.parameters.region,
                siteUrl: props.validationsForm.parameters.siteUrl,
                hideServiceName: true,
                hideTestConnection: props.validationsForm.parameters.documentApprovalApi
                  ? !props.validationsForm.parameters.documentApprovalApi.showTestConnection
                  : false,
                title: '',
                fieldsTitle: '',
                fields: documentApiParameters
              }
            }
          )
        }
      }

      return steps
    }

    function getTableActionsForFormsConfigurationWizard() {
      const tableActionFunctions = []
      const tableActions = []

      tableActionFunctions.push(element => {
        setTableElement(element)
        setShowDeleteConfirm(true)
      })
      tableActions.push(
        <Tooltip title="Delete">
          <IconButton>
            <ClearIcon />
          </IconButton>
        </Tooltip>
      )

      tableActionFunctions.push(element => {
        setTableElement(element)
        setShowQuestions(true)
      })
      tableActions.push(
        <Tooltip title="Add Validation Field">
          <IconButton>
            <PlaylistAddIcon />
          </IconButton>
        </Tooltip>
      )

      tableActionFunctions.push(element => {
        setTableElement(element)
        setShowModal(true)
      })
      tableActions.push(
        <Tooltip title="Edit Name">
          <IconButton>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )

      return {
        tableActionFunctions,
        tableActions
      }
    }

    function returnConfigurationWizardToDisplay() {
      let patameterFields =
        props.validationsForm[configurationDetails] &&
        props.validationsForm[configurationDetails].parameters &&
        Object.keys(props.validationsForm[configurationDetails].parameters).length > 0
          ? props.validationsForm[configurationDetails].parameters
          : {}
      let formValidationImportFields = {}
      let hideTestConnection = true
      let customConnection = false
      if (
        props.validationsForm[configurationDetails].parameters &&
        props.validationsForm[configurationDetails].parameters.showTestConnection
      ) {
        hideTestConnection = false
        customConnection = props.validationsForm[configurationDetails].parameters.customConnection
      }
      if (props.validationsForm[configurationDetails].formValidationImport) {
        formValidationImportFields = props.validationsForm[configurationDetails].formValidationImport
      }

      let mappingKeys = Object.keys(
        props.validationsForm[configurationDetails].mappings ? props.validationsForm[configurationDetails].mappings : {}
      )
      let maxMappings
      if (props.validationsForm && props.validationsForm.parameters) maxMappings = props.validationsForm.parameters.maxMappings
      let questionsFields =
        props.validationsForm[configurationDetails]?.mappings && mappingKeys.length
          ? {
              mappings: props.validationsForm[configurationDetails].mappings
            }
          : { mappings: {} }
      console.log('questionFields', { context: questionsFields })
      let configStepId = configurationDetails + 'config'

      if (props.validationsForm[configurationDetails].isCustomValidation) {
        return (
          <Wizard
            hideNextButton={false}
            hidePreviousButton={false}
            id="configurationWizard"
            color="primary"
            validate
            hideNavigation={false}
            backButtonOnClick={() => {
              setConfigurationDetails('main')
              setConfigurationDetailsLabel('')
              setShowConfiguration(false)
              //for the fade transition and wizard refresh
              props.setRefresh(!props.refresh)
            }}
            title={configurationDetailsLabel + ' Configuration'}
            steps={[
              {
                stepName: 'Request',
                stepComponent: ConnectivityView,
                stepId: configStepId,
                initialState: {
                  conectivityMapping: {
                    env: props.validationsForm.environment,
                    app: 'slp',
                    hideAddButton: false,
                    addButtonOnClick: async () => {
                      setTableElement(undefined)
                      setCustomValidationProps({
                        title: 'Add new Parameter',
                        descriptionLabel: 'Description',
                        enableLabel: 'Enabled',
                        typeLabel: 'Type',
                        kiLabel: 'KI',
                        sendParameterLabel: 'Send Parameter to Response',
                        responseKILabel: 'Response KI',
                        parameterNameLabel: 'Parameter Name',
                        subtypeLabel: 'Sub-Type',
                        descriptionHidden: false,
                        defaultValueHidden: true,
                        responseFieldHidden: true,
                        parameterNameHidden: false,
                        kiHidden: false,
                        typeValueHidden: false,
                        enabledValueHidden: false,
                        sendParameterHidden: false,
                        action: 'request',
                        service: props.validationsForm.service
                      })
                      setShowModal(true)
                    },
                    transformedColumnName: props.validationsForm.service === 'coupa' ? 'Easy Form' : 'Questionnaire',
                    formTitle: 'API Validation Parameters',
                    tableTitle: props.validationsForm.service === 'coupa' ? 'Easy Forms' : 'Questionnaires',
                    tableIcon: <SettingsIcon />,
                    tableActionFunctions: [
                      element => {
                        element.isRequest = true
                        setTableElement(element)
                        setShowDeleteConfirm(true)
                      },
                      element => {
                        setConfigTableElement(element)
                        setCustomValidationProps({
                          title: 'Modify Parameter',
                          descriptionLabel: 'Description',
                          enableLabel: 'Enabled',
                          typeLabel: 'Type',
                          kiLabel: 'KI',
                          sendParameterLabel: 'Send Parameter to Response',
                          responseKILabel: 'Response KI',
                          parameterNameLabel: 'Parameter Name',
                          subtypeLabel: 'Sub-Type',
                          descriptionHidden: false,
                          defaultValueHidden: true,
                          responseFieldHidden: true,
                          parameterNameHidden: false,
                          kiHidden: false,
                          typeValueHidden: false,
                          enabledValueHidden: false,
                          sendParameterHidden: false,
                          action: 'request',
                          service: props.validationsForm.service
                        })
                        setConfigTableShowModal(true)
                      }
                    ],
                    tableActions: [
                      <Tooltip title="Delete">
                        <IconButton>
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>,
                      <Tooltip title="Edit Name">
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ],
                    service: props.validationsForm.service,
                    documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
                    setService: setService,
                    hideTestConnection: hideTestConnection,
                    title: '',
                    fieldsTitle: '',
                    tableId: 'parameterValues',
                    mainAccessor: 'description',
                    hideSaveButton: true,
                    refreshTable: refreshChild => {
                      refreshTableFunction.current = refreshChild
                    },
                    columns: [
                      {
                        Header: 'Enabled',
                        accessor: 'enabled',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'Type',
                        accessor: 'type'
                      },
                      {
                        Header: 'Parameter Name',
                        accessor: 'parameterName',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'Description',
                        accessor: 'description'
                      },
                      {
                        Header: 'KI Name',
                        accessor: 'ki'
                      },
                      {
                        Header: 'Actions',
                        accessor: 'action',
                        sortable: false,
                        filterable: false
                      }
                    ],
                    ignoreIds: ['mappings'],
                    fields: patameterFields,
                    parameterId: configurationDetails,
                    customConnection: customConnection
                  }
                }
              },
              {
                stepName: 'Response',
                stepComponent: ConnectivityView,
                stepId: configStepId,
                initialState: {
                  conectivityMapping: {
                    env: props.validationsForm.environment,
                    app: 'slp',
                    hideAddButton: false,
                    addButtonOnClick: async () => {
                      setCustomValidationProps({
                        title: 'Add new Response Mapping',
                        descriptionLabel: 'Description',
                        enableLabel: 'Enabled',
                        typeLabel: '',
                        kiLabel: 'KI',
                        sendParameterLabel: '',
                        responseKILabel: '',
                        parameterNameLabel: '',
                        defaultValueLabel: 'Default Value',
                        responseFieldLabel: 'API response field',
                        subtypeLabel: '',
                        descriptionHidden: false,
                        defaultValueHidden: false,
                        responseFieldHidden: false,
                        parameterNameHidden: true,
                        kiHidden: false,
                        typeValueHidden: true,
                        enabledValueHidden: false,
                        sendParameterHidden: true,
                        action: 'response',
                        service: props.validationsForm.service
                      })
                      setShowModal(true)
                      setConfigTableElement(undefined)
                    },
                    transformedColumnName: props.validationsForm.service === 'coupa' ? 'Easy Form' : 'Questionnaire',
                    formTitle: 'API Response Parameters',
                    tableTitle: props.validationsForm.service === 'coupa' ? 'Easy Forms' : 'Questionnaires',
                    tableIcon: <SettingsIcon />,
                    tableActionFunctions: [
                      element => {
                        setTableElement(element)
                        setShowDeleteConfirm(true)
                      },
                      element => {
                        setConfigTableElement(element)
                        setCustomValidationProps({
                          title: 'Modify Response Mapping',
                          descriptionLabel: 'Description',
                          enableLabel: 'Enabled',
                          typeLabel: '',
                          kiLabel: 'KI',
                          sendParameterLabel: '',
                          responseKILabel: '',
                          parameterNameLabel: '',
                          defaultValueLabel: 'Default Value',
                          responseFieldLabel: 'API response field',
                          subtypeLabel: '',
                          descriptionHidden: false,
                          defaultValueHidden: false,
                          responseFieldHidden: false,
                          parameterNameHidden: true,
                          kiHidden: false,
                          typeValueHidden: true,
                          enabledValueHidden: false,
                          sendParameterHidden: true,
                          action: 'response',
                          service: props.validationsForm.service
                        })
                        setConfigTableShowModal(true)
                      }
                    ],
                    tableActions: [
                      <Tooltip title="Delete">
                        <IconButton>
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>,
                      <Tooltip title="Edit Name">
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ],
                    service: props.validationsForm.service,
                    documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
                    setService: setService,
                    hideTestConnection: hideTestConnection,
                    title: '',
                    fieldsTitle: '',
                    tableId: 'mappings',
                    mainAccessor: 'description',
                    hideSaveButton: true,
                    refreshTable: refreshChild => {
                      refreshTableFunction.current = refreshChild
                    },
                    columns: [
                      {
                        Header: 'Enabled',
                        accessor: 'enabled',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'Response Field',
                        accessor: 'responseField',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'Description',
                        accessor: 'description'
                      },
                      {
                        Header: 'Response KI',
                        accessor: 'ki'
                      },
                      {
                        Header: 'Default Value',
                        accessor: 'defaultValue',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'Actions',
                        accessor: 'action',
                        sortable: false,
                        filterable: false
                      }
                    ],
                    fields: questionsFields,
                    parameterId: configurationDetails,
                    customConnection: customConnection
                  }
                }
              }
            ]}
            subtitle=""
            finishButtonClick={e => {
              props.validationsForm[configurationDetails].configured = true
              props.setValidationsForm(props.validationsForm)
              saveToDataBase(props.validationsForm, () => {}, 'Saved', false, true)
            }}
          />
        )
      } else if (props.validationsForm[configurationDetails].isPiiValidation) {
        return (
          <Wizard
            id="configurationWizard"
            color="primary"
            validate
            hideNavigation={false}
            backButtonOnClick={() => {
              setConfigurationDetails('main')
              setConfigurationDetailsLabel('')
              setShowConfiguration(false)
              //for the fade transition and wizard refresh
              props.setRefresh(!props.refresh)
            }}
            title={configurationDetailsLabel + ' Configuration'}
            steps={[
              {
                stepName: 'Forms',
                stepComponent: ConnectivityView,
                stepId: configurationDetails,
                initialState: {
                  conectivityMapping: {
                    env: props.validationsForm.environment,
                    app: 'slp',
                    hideTestConnection: true,
                    hideAddButton: false,
                    tableMaxSize: maxMappings,
                    showTablePagination: maxMappings <= 5 ? false : true,
                    addButtonOnClick: async () => {
                      setShowModal(true)
                      setTableElement(undefined)
                    },

                    transformedColumnName: props.validationsForm.service === 'coupa' ? 'Easy Form' : 'Questionnaire',
                    formTitle: props.validationsForm.service === 'coupa' ? 'Easy Form' : 'SLP Questionnaire',
                    addTooltipText: props.validationsForm.service === 'coupa' ? 'Create New Easy Form' : 'Create new questionnaire',
                    tableTitle: props.validationsForm.service === 'coupa' ? 'Easy Forms' : 'Questionnaires',
                    tableIcon: <QuestionAnswerIcon />,
                    tableId: 'mappings',
                    mainAccessor: 'uniqueName',
                    onBooleanSwitchChange: rollbackFunction => {
                      saveToDataBase(props.validationsForm, rollbackFunction, 'Updated')
                    },
                    addParentElementKey: true,
                    ...getTableActionsForFormsConfigurationWizard(),
                    noDataDiv: <div>{`Add new ${props.validationsForm.service} Form`}</div>,
                    noDataText: <div></div>,
                    hideSaveButton: true,
                    refreshTable: refreshChild => {
                      refreshTableFunction.current = refreshChild
                    },
                    maxDrill: 1,
                    columns: [
                      {
                        Header: 'Name',
                        accessor: 'uniqueName',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'Action',
                        accessor: 'action',
                        sortable: false,
                        filterable: false,
                        resizable: false,
                        maxWidth: 250,
                        headerStyle: {
                          textAlign: 'center'
                        },
                        style: {
                          textAlign: 'center'
                        }
                      }
                    ],
                    service: props.validationsForm.service,
                    documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
                    setService: setService,

                    title: '',
                    fieldsTitle: '',
                    ignoreIds: ['validationMessages'],
                    fields: questionsFields
                  }
                }
              },
              {
                stepName: 'Configurations',
                stepComponent: ConnectivityView,
                stepId: configStepId,
                initialState: {
                  conectivityMapping: {
                    env: props.validationsForm.environment,
                    app: 'slp',
                    hideAddButton: true,
                    transformedColumnName: props.validationsForm.service === 'coupa' ? 'Easy Form' : 'Questionnaire',
                    formTitle: 'API Validation Parameters',
                    tableTitle: props.validationsForm.service === 'coupa' ? 'Easy Forms' : 'Questionnaires',
                    tableIcon: <SettingsIcon />,
                    tableActionFunctions: [
                      element => {
                        setConfigTableElement(element)
                        setConfigTableShowModal(true)
                      }
                    ],
                    tableActions: [
                      <Tooltip title="Edit Name">
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ],
                    service: props.validationsForm.service,
                    documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
                    setService: setService,
                    hideTestConnection: hideTestConnection,
                    title: '',
                    fieldsTitle: '',
                    tableId: 'validationMessages',
                    mainAccessor: 'description',
                    hideSaveButton: true,
                    refreshTable: refreshChild => {
                      refreshTableFunction.current = refreshChild
                    },
                    columns: [
                      {
                        Header: 'Enabled',
                        accessor: 'active',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'Type',
                        accessor: 'type'
                      },
                      {
                        Header: 'Visible',
                        accessor: 'visible',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'Description',
                        accessor: 'description'
                      },
                      {
                        Header: 'Message',
                        accessor: 'message'
                      },
                      {
                        Header: 'Actions',
                        accessor: 'action',
                        sortable: false,
                        filterable: false
                      }
                    ],
                    ignoreIds: ['piiEncryptionKey', 'piiIsCertificate'],
                    fields: patameterFields,
                    dataFields:
                      configurationDetails === 'listMatchValidation'
                        ? patameterFields.validationMessages.listMatch
                        : configurationDetails === 'tinValidation'
                        ? patameterFields.validationMessages.tinNameCode
                        : [],
                    parameterId: configurationDetails,
                    customConnection: customConnection
                  }
                }
              },
              {
                stepName: 'Suppliers Emails',
                stepComponent: PIIEmails,
                stepId: 'piiStep',
                initialState: {
                  supplierMapping: {
                    setCreateError: props.setCreateError,
                    setValidationMessage: props.setValidationMessage,
                    setSubmitSuccess: props.setSubmitSuccess,
                    env: props.validationsForm.environment,
                    app: 'slp',
                    hideAddButton: true,
                    formTitle: 'Suppliers Emails',
                    service: props.validationsForm.service,
                    documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
                    setService: setService,
                    hideServiceName: true,
                    hideTestConnection: hideTestConnection,
                    title: 'PII suppliers',
                    fieldsTitle: 'PII Suppliers',
                    hideSaveButton: true,
                    parameterId: configurationDetails,
                    customConnection: customConnection,
                    fields: patameterFields
                  }
                }
              }
            ]}
            subtitle=""
            finishButtonClick={e => {
              props.validationsForm[configurationDetails].mappings = e[configurationDetails].conectivityMapping.fields.mappings
              props.validationsForm[configurationDetails].parameters = e[configStepId].conectivityMapping.fields
              saveToDataBase(props.validationsForm, () => {}, 'Saved', false, true)
            }}
          />
        )
      } else if (props.validationsForm[configurationDetails].isDnB) {
        return (
          <Wizard
            id="configurationWizard"
            color="primary"
            validate
            hideNavigation={false}
            hideNextButton={true}
            hidePreviousButton={true}
            backButtonOnClick={() => {
              setConfigurationDetails('main')
              setConfigurationDetailsLabel('')
              setShowConfiguration(false)
              //for the fade transition and wizard refresh
              props.setRefresh(!props.refresh)
            }}
            title={configurationDetailsLabel + ' Configuration'}
            steps={[
              {
                stepName: 'Forms',
                stepComponent: ConnectivityView,
                stepId: configurationDetails,
                initialState: {
                  conectivityMapping: {
                    env: props.validationsForm.environment,
                    app: 'slp',
                    hideTestConnection: true,
                    hideAddButton: false,

                    addButtonOnClick: async () => {
                      setShowModal(true)
                      setTableElement(undefined)
                    },

                    transformedColumnName: props.validationsForm.service === 'coupa' ? 'Easy Form' : 'Questionnaire',
                    formTitle: props.validationsForm.service === 'coupa' ? 'Easy Form' : 'SLP Questionnaire',
                    addTooltipText: props.validationsForm.service === 'coupa' ? 'Create New Easy Form' : 'Create new questionnaire',
                    tableTitle: props.validationsForm.service === 'coupa' ? 'Easy Forms' : 'Questionnaires',
                    tableIcon: <QuestionAnswerIcon />,
                    tableId: 'mappings',
                    mainAccessor: 'uniqueName',
                    onBooleanSwitchChange: rollbackFunction => {
                      saveToDataBase(props.validationsForm, rollbackFunction, 'Updated')
                    },
                    addParentElementKey: true,
                    tableActionFunctions: [
                      element => {
                        setTableElement(element)
                        setShowDeleteConfirm(true)
                      },
                      element => {
                        setTimeout(() => {
                          scrolltoTop()
                        }, 5000)

                        setTableElement(element)
                        setShowDnBQuestions(true)
                      },
                      element => {
                        setTableElement(element)
                        setShowModal(true)
                      }
                    ],
                    tableActions: [
                      <Tooltip title="Delete">
                        <IconButton>
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>,
                      <Tooltip title="Add Validation Field">
                        <IconButton>
                          <PlaylistAddIcon />
                        </IconButton>
                      </Tooltip>,
                      <Tooltip title="Edit Name">
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ],
                    noDataDiv: <div>{`Add new ${props.validationsForm.service} Form`}</div>,
                    noDataText: <div></div>,
                    hideSaveButton: true,
                    refreshTable: refreshChild => {
                      refreshTableFunction.current = refreshChild
                    },
                    maxDrill: 1,
                    columns: [
                      {
                        Header: 'Name',
                        accessor: 'uniqueName',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'Action',
                        accessor: 'action',
                        sortable: false,
                        filterable: false,
                        resizable: false,
                        maxWidth: 250,
                        headerStyle: {
                          textAlign: 'center'
                        },
                        style: {
                          textAlign: 'center'
                        }
                      }
                    ],
                    service: props.validationsForm.service,
                    documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
                    setService: setService,

                    title: '',
                    fieldsTitle: '',
                    ignoreIds: ['validationMessages'],
                    fields: questionsFields
                  }
                }
              },
              {
                stepName: 'DnB Mapping',
                stepComponent: DnBMappingReact,
                stepId: configurationDetails + 'mapping',
                initialState: { ...props }
              },
              {
                stepName: 'Configurations',
                stepComponent: ConnectivityView,
                stepId: configStepId,
                initialState: {
                  conectivityMapping: {
                    env: props.validationsForm.environment,
                    app: 'slp',
                    hideAddButton: true,
                    formTitle: 'API Validation Parameters',
                    service: props.validationsForm.service,
                    documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
                    setService: setService,
                    hideTestConnection: hideTestConnection,
                    title: '',
                    fieldsTitle: '',
                    tableId: 'validationMessages',
                    mainAccessor: 'description',
                    hideSaveButton: true,
                    refreshTable: refreshChild => {
                      refreshTableFunction.current = refreshChild
                    },

                    ignoreIds: ['piiEncryptionKey', 'piiIsCertificate'],
                    fields: patameterFields,
                    dataFields:
                      configurationDetails === 'listMatchValidation'
                        ? patameterFields.validationMessages.listMatch
                        : configurationDetails === 'tinValidation'
                        ? patameterFields.validationMessages.tinNameCode
                        : [],
                    parameterId: configurationDetails,
                    customConnection: customConnection
                  }
                }
              }
            ]}
            subtitle=""
            finishButtonClick={e => {
              props.validationsForm[configurationDetails].mappings = {}
              props.validationsForm[configurationDetails].parameters = e[configStepId].conectivityMapping.fields
              saveToDataBase(props.validationsForm, () => {}, 'Saved', false, true)
            }}
          />
        )
      } else {
        let steps = []
        if (props.validationsForm.service !== 'ivalua')
          steps.push({
            stepName: 'Forms',
            stepComponent: ConnectivityView,
            stepId: configurationDetails,
            initialState: {
              conectivityMapping: {
                env: props.validationsForm.environment,
                app: 'slp',
                hideTestConnection: true,
                hideAddButton: false,
                tableMaxSize: maxMappings,
                showTablePagination: maxMappings <= 5 ? false : true,
                addButtonOnClick: async () => {
                  setShowModal(true)
                  setTableElement(undefined)
                },

                transformedColumnName: props.validationsForm.service === 'coupa' ? 'Easy Form' : 'Questionnaire',
                formTitle: props.validationsForm.service === 'coupa' ? 'Easy Form' : 'SLP Questionnaire',
                addTooltipText: props.validationsForm.service === 'coupa' ? 'Create New Easy Form' : 'Create new questionnaire',
                tableTitle: props.validationsForm.service === 'coupa' ? 'Easy Forms' : 'Questionnaires',
                tableIcon: <QuestionAnswerIcon />,
                tableId: 'mappings',
                mainAccessor: 'uniqueName',
                onBooleanSwitchChange: rollbackFunction => {
                  saveToDataBase(props.validationsForm, rollbackFunction, 'Updated')
                },
                addParentElementKey: true,
                ...getTableActionsForFormsConfigurationWizard(),
                noDataDiv: <div>{`Add new ${props.validationsForm.service} Form`}</div>,
                noDataText: <div></div>,
                hideSaveButton: true,
                refreshTable: refreshChild => {
                  refreshTableFunction.current = refreshChild
                },
                maxDrill: 1,
                columns: [
                  {
                    Header: 'Name',
                    accessor: 'uniqueName',
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: 'Action',
                    accessor: 'action',
                    sortable: false,
                    filterable: false,
                    resizable: false,
                    maxWidth: 250,
                    headerStyle: {
                      textAlign: 'center'
                    },
                    style: {
                      textAlign: 'center'
                    }
                  }
                ],
                service: props.validationsForm.service,
                documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
                setService: setService,

                title: '',
                fieldsTitle: '',
                ignoreIds: ['validationMessages'],
                fields: questionsFields
              }
            }
          })
        steps.push({
          stepName: 'Configurations',
          stepComponent: ConnectivityView,
          stepId: configStepId,
          initialState: {
            conectivityMapping: {
              hideValue: props.validationsForm[configurationDetails].hideValue
                ? props.validationsForm[configurationDetails].hideValue
                : 'internal',
              hideKey: props.validationsForm[configurationDetails].hideKey ? props.validationsForm[configurationDetails].hideKey : 'type',
              env: props.validationsForm.environment,
              app: 'slp',
              hideAddButton: true,
              transformedColumnName: props.validationsForm.service === 'coupa' ? 'Easy Form' : 'Questionnaire',
              formTitle: 'API Validation Parameters',
              tableTitle: props.validationsForm.service === 'coupa' ? 'Easy Forms' : 'Questionnaires',
              tableIcon: <SettingsIcon />,
              tableActionFunctions: [
                element => {
                  setConfigTableElement(element)
                  setConfigTableShowModal(true)
                }
              ],
              tableActions: [
                <Tooltip title="Edit Name">
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              ],
              service: props.validationsForm.service,
              documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
              setService: setService,
              hideTestConnection: hideTestConnection,
              title: '',
              fieldsTitle: '',
              tableId: 'validationMessages',
              mainAccessor: 'description',
              hideSaveButton: true,
              refreshTable: refreshChild => {
                refreshTableFunction.current = refreshChild
              },
              columns: [
                {
                  Header: 'Enabled',
                  accessor: 'active',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Type',
                  accessor: 'type'
                },
                {
                  Header: 'Visible',
                  accessor: 'visible',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Description',
                  accessor: 'description'
                },
                {
                  Header: 'Message',
                  accessor: 'message'
                },
                {
                  Header: 'Actions',
                  accessor: 'action',
                  sortable: false,
                  filterable: false
                }
              ],
              fields: patameterFields,
              dataFields:
                configurationDetails === 'listMatchValidation'
                  ? patameterFields.validationMessages.listMatch
                  : configurationDetails === 'tinValidation'
                  ? patameterFields.validationMessages.tinNameCode
                  : [],
              parameterId: configurationDetails,
              customConnection: customConnection,
              addressMapings: configurationDetails === 'tinValidation' ? props.validationsForm['addressValidation'].mappings : {},
              validationInternationalAddress:
                configurationDetails === 'addressValidation' &&
                patameterFields.validateInternationalAddress &&
                patameterFields.validateInternationalAddress.value === true
                  ? patameterFields.validationInternationalMessages
                  : {}
            },
            saveFormOnClick: async => {
              let originalValidationsForm = cloneObject(props.validationsForm)
              let rollbackFunction = () => {
                props.setValidationsForm(originalValidationsForm)
              }
              saveToDataBase(props.validationsForm, rollbackFunction, 'Modified Successfully', true)
            }
          }
        })

        //International Address Component

        if (
          configurationDetails === 'addressValidation' &&
          patameterFields.validateInternationalAddress &&
          patameterFields.validateInternationalAddress.value === true &&
          false //https://dev.azure.com/relishiq/Relish%20Apps/_workitems/edit/31614
        ) {
          //Build the complete information merge table
          const internationalAddressList = Object.keys(patameterFields.validationInternationalMessages)
            .filter(e => {
              return ['address_precision', 'analysis_changes', 'verification_status'].includes(e) //Only includes the information related to international address messages
            })
            .map(e => {
              const keys_element = Object.keys(patameterFields.validationInternationalMessages[e])
              const element_group = []
              for (var i = 0; i < keys_element.length; i++) {
                const key = keys_element[i]
                const element = patameterFields.validationInternationalMessages[e][key]
                element['group'] = e //Adding the group clasificaction for each element
                element_group.push(JSON.parse(JSON.stringify(element)))
              }
              return element_group
            })
          patameterFields['internationConfiguration'] = internationalAddressList.flat(1)
          //End build complete information merge table

          steps.push({
            stepName: 'International Configuration',
            stepComponent: AddressInternational,
            stepId: configStepId,
            initialState: {
              conectivityMapping: {
                hideValue: props.validationsForm[configurationDetails].hideValue
                  ? props.validationsForm[configurationDetails].hideValue
                  : 'internal',
                hideKey: props.validationsForm[configurationDetails].hideKey ? props.validationsForm[configurationDetails].hideKey : 'type',
                env: props.validationsForm.environment,
                app: 'slp',
                hideAddButton: true,
                transformedColumnName: props.validationsForm.service === 'coupa' ? 'Easy Form' : 'Questionnaire',
                formTitle: 'API Validation Parameters',
                tableTitle: props.validationsForm.service === 'coupa' ? 'Easy Forms' : 'Questionnaires',
                tableIcon: <SettingsIcon />,
                tableActionFunctions: [
                  element => {
                    setConfigTableElement(element)
                    setConfigTableShowModal(true)
                  }
                ],
                tableActions: [
                  <Tooltip title="Edit Name">
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                ],
                service: props.validationsForm.service,
                documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
                setService: setService,
                hideTestConnection: hideTestConnection,
                title: '',
                fieldsTitle: '',
                tableId: 'internationConfiguration',
                mainAccessor: 'description',
                hideSaveButton: true,
                refreshTable: refreshChild => {
                  refreshTableFunction.current = refreshChild
                },
                columns: [
                  {
                    Header: 'Enabled',
                    accessor: 'active',
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: 'Type',
                    accessor: 'type'
                  },
                  {
                    Header: 'Visible',
                    accessor: 'visible',
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: 'Description',
                    accessor: 'description'
                  },
                  {
                    Header: 'Message',
                    accessor: 'message'
                  },

                  {
                    Header: 'Group',
                    accessor: 'group',
                    sortable: false,
                    filterable: true
                  },
                  {
                    Header: 'Actions',
                    accessor: 'action',
                    sortable: false,
                    filterable: false
                  }
                ],
                fields: patameterFields,
                dataFields:
                  configurationDetails === 'listMatchValidation'
                    ? patameterFields.validationMessages.listMatch
                    : configurationDetails === 'tinValidation'
                    ? patameterFields.validationMessages.tinNameCode
                    : [],
                parameterId: configurationDetails,
                customConnection: customConnection,
                addressMapings: configurationDetails === 'tinValidation' ? props.validationsForm['addressValidation'].mappings : {},
                validationInternationalAddress:
                  configurationDetails === 'addressValidation' &&
                  patameterFields.validateInternationalAddress &&
                  patameterFields.validateInternationalAddress.value === true
                    ? patameterFields.validationInternationalMessages
                    : {}
              },
              saveFormOnClick: async => {
                let originalValidationsForm = cloneObject(props.validationsForm)
                let rollbackFunction = () => {
                  props.setValidationsForm(originalValidationsForm)
                }
                saveToDataBase(props.validationsForm, rollbackFunction, 'Modified Successfully', true)
              }
            }
          })
        }

        //International Address Component
        if (configurationDetails === 'tinValidation' && props.validationsForm[configurationDetails].formValidationImport) {
          console.log('importValidationFields', {
            context: JSON.stringify(formValidationImportFields)
          })
          steps.push({
            stepName: 'Form Validation Import',
            stepComponent: ConnectivityView,
            stepId: 'formValidationImportId', //configStepId,
            initialState: {
              conectivityMapping: {
                hideValue: props.validationsForm[configurationDetails].hideValue
                  ? props.validationsForm[configurationDetails].hideValue
                  : 'internal',
                hideKey: props.validationsForm[configurationDetails].hideKey ? props.validationsForm[configurationDetails].hideKey : 'type',
                env: props.validationsForm.environment,
                app: 'slp',
                hideAddButton: true,
                transformedColumnName: props.validationsForm.service === 'coupa' ? 'Easy Form' : 'Questionnaire',
                formTitle: 'API Validation Parameters',
                tableTitle: props.validationsForm.service === 'coupa' ? 'Easy Forms' : 'Questionnaires',
                tableIcon: <SettingsIcon />,
                tableActionFunctions: [
                  element => {
                    setConfigTableElement(element)
                    setConfigTableShowModal(true)
                  }
                ],
                tableActions: [
                  <Tooltip title="Edit Name">
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                ],
                service: props.validationsForm.service,
                documentationPath: '/admin/dataAssure/documentation/' + props.validationsForm.service,
                setService: setService,
                hideTestConnection: hideTestConnection,
                title: '',
                fieldsTitle: '',
                tableId: 'validationMessages',
                mainAccessor: 'description',
                hideSaveButton: true,
                refreshTable: refreshChild => {
                  refreshTableFunction.current = refreshChild
                },
                columns: [
                  {
                    Header: 'Enabled',
                    accessor: 'active',
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: 'Type',
                    accessor: 'type'
                  },
                  {
                    Header: 'Visible',
                    accessor: 'visible',
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: 'Description',
                    accessor: 'description'
                  },
                  {
                    Header: 'Message',
                    accessor: 'message'
                  },
                  {
                    Header: 'Actions',
                    accessor: 'action',
                    sortable: false,
                    filterable: false
                  }
                ],
                fields: formValidationImportFields,
                dataFields: [],
                parameterId: configurationDetails,
                customConnection: customConnection,
                addressMapings: {}
              },
              saveFormOnClick: async validationForm => {
                let originalValidationsForm = cloneObject(props.validationsForm)
                let rollbackFunction = () => {
                  props.setValidationsForm(originalValidationsForm)
                }
                saveToDataBase(validationForm, rollbackFunction, 'Modified Successfully', true)
              }
            }
          })
        }
        return (
          <Wizard
            forceFinishButton={props.validationsForm.service === 'ivalua'}
            hideNextButton={true}
            hidePreviousButton={true}
            id="configurationWizard"
            color="primary"
            validate
            hideNavigation={props.validationsForm.service === 'ivalua'}
            backButtonOnClick={() => {
              setConfigurationDetails('main')
              setConfigurationDetailsLabel('')
              setShowConfiguration(false)
              //for the fade transition and wizard refresh
              props.setRefresh(!props.refresh)
            }}
            title={configurationDetailsLabel + ' Configuration'}
            steps={steps}
            subtitle=""
            finishButtonClick={e => {
              console.log('button click')
              if (props.validationsForm.service !== 'ivalua') {
                props.validationsForm[configurationDetails].mappings = e[configurationDetails].conectivityMapping.fields.mappings
              }
              props.validationsForm[configurationDetails].parameters = e[configStepId].conectivityMapping.fields
              saveToDataBase(props.validationsForm, () => {}, 'Saved', false, true)
            }}
          />
        )
      }
    }

    function returnWizardToDisplay() {
      return (
        <Wizard
          id="Wizard"
          color="primary"
          validate
          hideNavigation={props.displayMode === 'create'}
          title="Data Assure"
          steps={getAdminSteps()}
          subtitle=""
          finishButtonClick={e => {
            if (props.displayMode === 'create') {
              console.log('props app', { context: props.validationsForm, event: e })
              if (e.validations && e.validations.env) {
                if (validAppName(e.validations.env)) props.onAppSaved(e)
                else {
                  props.setValidationMessage('Only alphanumeric and _ are allow for the Application Name.')
                  props.setCreateError(true)
                  setTimeout(() => {
                    props.setCreateError(false)
                  }, 5000)
                }
              } else {
                props.setValidationMessage('Please Add Application Name')
                props.setCreateError(true)
                setTimeout(() => {
                  props.setCreateError(false)
                }, 5000)
              }
            } else {
              let validationsForm = props.validationsForm
              //trim values
              let key = undefined
              for (key of Object.keys(validationsForm.parameters?.questionnaireResponse || {})) {
                if (
                  validationsForm.parameters.questionnaireResponse[key] &&
                  typeof validationsForm.parameters.questionnaireResponse[key] === 'object' &&
                  validationsForm.parameters.questionnaireResponse[key].type !== 'password' &&
                  validationsForm.parameters.questionnaireResponse[key].value &&
                  typeof validationsForm.parameters.questionnaireResponse[key].value === 'string'
                ) {
                  validationsForm.parameters.questionnaireResponse[key].value = validationsForm.parameters.questionnaireResponse[
                    key
                  ].value.trim()
                }
              }
              console.log('save to data base', { context: validationsForm })
              validationsForm.enabled = e.validations.mapping.enabled
              saveToDataBase(validationsForm, () => {}, 'Saved', false, false)
            }
          }}
        />
      )
    }
    function validAppName(appName) {
      const appNameRegEx = /^[a-zA-Z0-9_]+$/
      return appNameRegEx.test(appName)
    }
    return (
      <Fade in={true} timeout={250}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={3} className={classes.padding10}>
            <Card className={classes.appBar}>
              <CardHeader>
                Application List
                <Tooltip title="Create new app">
                  <Button
                    justIcon
                    round
                    simple
                    color="primary"
                    className={classes.marginRight}
                    onClick={() => {
                      setShowConfiguration(false)
                      setConfigTableElement(false)
                      setConfigurationDetails('main')
                      setConfigurationDetailsLabel('')
                      setSelectedQuestion(undefined)
                      setTableElement(undefined)
                      setShowQuestions(false)
                      props.onCreateButton()
                      scrolltoTop()
                    }}
                  >
                    <Add className={classes.icons} />
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <List>
                  {props.appList.map((prop, key) => {
                    return (
                      <ListItem
                        style={{ paddingLeft: '5px', paddingRight: '5px' }}
                        className={classes.listItem}
                        button
                        selected={props.selectedAppId === prop.env}
                        key={key}
                        onClick={() => {
                          setShowConfiguration(false)
                          setConfigTableElement(false)
                          setConfigurationDetails('main')
                          setConfigurationDetailsLabel('')
                          setSelectedQuestion(undefined)
                          setTableElement(undefined)
                          setShowQuestions(false)
                          props.onAppChanged(prop.env)
                          scrolltoTop()
                        }}
                      >
                        <ListItemAvatar>
                          {props.selectedAppId === prop.env ? (
                            <Avatar>
                              <TouchApp />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.clearAvatar}></Avatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText primary={prop.env} secondary={`Type: ${prop.type}`} />
                        {prop.errors && prop.errors > 0 ? (
                          <ListItemText style={{ marginLeft: '4px' }}>
                            <Button className={classes.textErrors} simple onClick={() => props.onErrors(prop.env)}>
                              <ReportProblemIcon fontSize="small" />
                              {prop.errors} {prop.errors === 1 ? 'Error' : 'Errors'}
                            </Button>
                          </ListItemText>
                        ) : null}
                      </ListItem>
                    )
                  })}
                </List>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={9} className={classes.padding10}>
            {props.formIsLoading ? (
              <Card>
                <GridContainer justify="center">
                  <div className={classes.circularProgress}>
                    <CircularProgress color="inherit" />
                  </div>
                </GridContainer>
              </Card>
            ) : showDnBQuestions ? (
              <div style={{ width: '100%', margin: 'auto' }}>
                <div>
                  <IconButton
                    onClick={() => {
                      setShowDnBQuestions(false)
                    }}
                  >
                    <KeyboardBackspaceIcon />
                  </IconButton>
                </div>
                <DnBQuestions
                  types={JSON.parse(JSON.stringify(dnbTypes))}
                  questions={tableElement.dnbQuestions}
                  onSave={() => {
                    saveToDataBase(props.validationsForm, () => {}, 'Saved', false, true)
                  }}
                />
              </div>
            ) : showQuestions ? (
              <div>
                {showDeleteConfirmQuestion ? getDeleteConfirmQuestion() : ''}
                <Card style={{ height: '100%' }}>
                  {showModalTypes ? getTypesModal() : ''}
                  <div>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <h3>{configurationDetailsLabel + ' Configuration'}</h3>
                    </div>
                  </div>
                  <div style={{ width: '100%', margin: 'auto' }}>
                    <div>
                      <IconButton
                        onClick={() => {
                          setShowQuestions(false)
                        }}
                      >
                        <KeyboardBackspaceIcon />
                      </IconButton>
                    </div>
                    <CustomTreeTable
                      hideSaveButton={true}
                      hideAddButton={false}
                      onClickAdd={() => {
                        setShowModalTypes(true)
                      }}
                      formLabel={''}
                      transformedColumnName={'Name'}
                      formTitle={'Questions'}
                      tableTitle={''}
                      tableIcon={
                        <div>
                          <QuestionAnswerIcon />
                        </div>
                      }
                      mainAccessor={'id'}
                      noDataDiv={''}
                      noDataText=""
                      data={getQuestionsData()}
                      columns={[
                        {
                          Header: 'Id',
                          accessor: 'id',
                          sortable: false,
                          filterable: false
                        },
                        {
                          Header: 'Type',
                          accessor: 'type',
                          sortable: false,
                          filterable: false
                        },
                        {
                          Header: 'Actions',
                          accessor: 'action',
                          sortable: false,
                          filterable: false
                        }
                      ]}
                      refreshData={refreshChild => {
                        refreshTableFunction.current = () => {
                          refreshChild(getQuestionsData())
                        }
                      }}
                      transformCodes={['xs', 'sm']}
                      defaultPageSize={10}
                      showPaginationTop
                      minRows={0}
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    ></CustomTreeTable>
                  </div>
                </Card>
              </div>
            ) : showConfiguration ? (
              <Card>
                <GridContainer spacing={4}>
                  <GridItem xs={12}>
                    {React.cloneElement(returnConfigurationWizardToDisplay())}
                    {showDeleteConfirm ? getDeleteConfirm() : ''}
                    {showModal
                      ? props.validationsForm[configurationDetails].isCustomValidation
                        ? editCustomModal(customValidationProps, true)
                        : getModal()
                      : ''}
                    {configTableShowModal
                      ? props.validationsForm[configurationDetails].isCustomValidation
                        ? editCustomModal(customValidationProps, false)
                        : editMessageModal()
                      : ''}
                  </GridItem>
                </GridContainer>
              </Card>
            ) : props.selectedAppId || props.displayMode === 'edit' || props.displayMode === 'create' ? (
              <Card>
                <GridContainer spacing={4}>
                  <GridItem xs={12}>{React.cloneElement(returnWizardToDisplay())}</GridItem>
                </GridContainer>
              </Card>
            ) : (
              <Card>
                <CardBody>
                  <div className={classes.typo}>
                    <div className={classes.note}>No apps created</div>
                    <h6>Click on the create button to start...</h6>
                  </div>
                </CardBody>
              </Card>
            )}
          </GridItem>
          <Snackbar
            place="br"
            color="success"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.submitSuccess}
            closeNotification={() => props.setSubmitSuccess(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.createError}
            closeNotification={() => props.setCreateError(false)}
            close
          />
        </GridContainer>
      </Fade>
    )
  }
  if (props.refresh) {
    return <div>{getView()}</div>
  } else return getView()
}
