import React from "react";
import Auth from "authentication/Auth";

export default function LoginEnd(props) {

    const auth = new Auth();
    auth.popupLoginCallback();

    return (
        <div>
        </div>
    );
}
