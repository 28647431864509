import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'

// Icons
import Computer from '@material-ui/icons/Computer'
import Check from '@material-ui/icons/Check'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle'

// authentication
import MsalAuth from 'authentication/MsalAuth'

const useStyles = makeStyles(styles)

export const SharePointInput = props => {
  const classes = useStyles()
  const { name, setValue, getValues } = props

  async function msAuthentication() {
    const msalAuth = new MsalAuth('insights')
    msalAuth.login(function(response) {
      setValue(name, response.tenantId, { shouldValidate: true })
    })
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormLabel className={classes.contentLabelHorizontal}>
            Connect <strong>Relish App Center</strong> with your Microsoft account. If you accept
            this app will get access to the specified resources in you organization.
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Tooltip title="Connect Relish Apps with your Microsoft Account">
            <Button
              //   disabled={!props.expirationData.enabled}
              onClick={() => msAuthentication()}
              className={classes.documentation}
            >
              {getValues('tenantId') === '' ? (
                <Computer className={classes.icons} />
              ) : (
                <Check className={classes.icons} />
              )}
              Connect with Microsoft
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  )
}
