import { ApiDataAssure } from '../utils/Constants'
import { createHeaders, handleError, handleRequestError } from 'utils/functions'
import axios from 'axios'

export const transformOpReadBatchesPag = async (
  appId,
  source,
  externalBatchId,
  pageSize,
  currentPage
) => {
  try {
    const url = `${ApiDataAssure.EndPoint}/apps/${appId}/operations/read-batches-paginated/sources/${source}`
    const response = await axios({
      method: 'get',
      url: url,
      headers: createHeaders(),
      params: {
        externalBatchId,
        pageSize,
        currentPage
      }
    }).catch(error => {
      handleRequestError(error)
    })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data
      }
    } else {
      return handleError(response.status, 'Something went wrong, try again later')
    }
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

export const transformOpReadTransactionsPag = async (
  appId,
  source,
  grouperId,
  pageSize,
  currentPage
) => {
  try {
    const url = `${ApiDataAssure.EndPoint}/apps/${appId}/operations/read-transactions-paginated/sources/${source}/groupers/${grouperId}`
    const response = await axios({
      method: 'get',
      url: url,
      headers: createHeaders(),
      params: {
        pageSize,
        currentPage
      }
    }).catch(error => {
      handleRequestError(error)
    })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data
      }
    } else {
      return handleError(response.status, 'Something went wrong, try again later')
    }
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

export const transformOpReadDetails = async (appId, transactionId) => {
  try {
    const url = `${ApiDataAssure.EndPoint}/apps/${appId}/core/transactions/${transactionId}`

    const response = await axios({
      method: 'get',
      url: url,
      headers: createHeaders()
    }).catch(error => {
      handleRequestError(error)
    })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data
      }
    } else {
      return handleError(response.status, 'Something went wrong, try again later')
    }
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

export const transformOpWrite = async (appId, dto) => {
  try {
    const url = `${ApiDataAssure.EndPoint}/apps/${appId}/operations/write`

    const response = await axios({
      method: 'post',
      url: url,
      headers: createHeaders(),
      data: dto
    }).catch(error => {
      handleRequestError(error)
    })

    if ([200, 202].includes(response.status)) {
      return {
        success: true,
        data: response.data
      }
    } else {
      return handleError(response.status, 'Something went wrong, try again later')
    }
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

export const getUploadS3Url = async (appId, source, folderName, fileName) => {
  try {
    const url = `${ApiDataAssure.EndPoint}/apps/${appId}/operations/presigned-upload-url/sources/${source}`

    const response = await axios({
      method: 'get',
      url: url,
      headers: createHeaders(),
      params: {
        folderName,
        fileName
      }
    }).catch(error => {
      handleRequestError(error)
    })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data
      }
    } else {
      return handleError(response.status, 'Something went wrong, try again later')
    }
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

export const uploadFileToS3 = async (presignedUrl, buffer) => {
  try {
    const response = await axios({
      method: 'put',
      url: presignedUrl,
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      data: buffer
    }).catch(error => {
      handleRequestError(error)
    })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data
      }
    } else {
      return handleError(response.status, 'Something went wrong, try again later')
    }
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

export const getDownloadS3Url = async (source, s3ObjectKey) => {
  try {
    const url = `${ApiDataAssure.EndPoint}/operations/presigned-download-url/sources/${source}`

    const response = await axios({
      method: 'get',
      url: url,
      headers: createHeaders(),
      params: {
        s3ObjectKey
      }
    }).catch(error => {
      handleRequestError(error)
    })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data
      }
    } else {
      handleError(response.status, 'Something went wrong, try again later')
    }
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

export const slpWriteTransaction = async (appId, source, slpWriteTransactionDto) => {
  try {
    const url = `${ApiDataAssure.EndPoint}/apps/${appId}/slp/sources/${source}/transactions`

    const response = await axios({
      method: 'post',
      url: url,
      headers: createHeaders(),
      data: slpWriteTransactionDto
    }).catch(error => {
      handleRequestError(error)
    })

    if (response.status === 202) {
      return {
        success: true,
        data: response.data
      }
    } else {
      handleError(response.status, 'Something went wrong, try again later')
    }
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}
