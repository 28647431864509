import React, { useState, forwardRef, useMemo, useEffect, useRef } from 'react'
import countryList from 'react-select-country-list'

import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
//import Select from '@material-ui/core/Select'
import Select from 'react-select'

// Style
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'

import ArrowDownward from '@material-ui/icons/ArrowDownward'

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'

import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import Search from '@material-ui/icons/Search'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { searchDnbSupplier, updateDnbSupplier } from 'services/apiApps'
import { AddCircle } from '@material-ui/icons'
import { Spinner } from 'components/Loader/Spinner'

import CustomModal from 'components/CustomModal/CustomModal'

// import LinkIcon from '@material-ui/icons/Link'

const useStyles = makeStyles({})
const columns = [
  { title: 'Duns Number', field: 'dunsNumber' },
  { title: 'Confidence Code', field: 'confidenceCode' },
  { title: 'Name', field: 'name' }
]
export default function SuppliersCriteria (props) {
  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      if (props.supplierName) {
        onFieldChange('name', props.supplierName)
        //getInfoSearch()
      }
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  })
  const classes = useStyles()
  const [data, setData] = useState([])
  const [showMatchDialog, setShowMatchDialog] = useState(false)
  const [matchDetails, setMatchDetails] = useState({})

  const [searchInfo, setSearchInfo] = useState(
    props.searchInfo
      ? props.searchInfo
      : {
          name: '',
          country: '',
          streetLine: ''
        }
  )
  const [isLoading, setIsLoading] = useState(false)
  const countryListOptions = useMemo(() => countryList().getData(), [])

  async function getInfoSearch () {
    if (!searchInfo.country) {
      props.setValidationMessage('Please Select Country')
      props.setCreateError(true)
      //props.setSubmitSuccess(true)
    } else if (!searchInfo.name) {
      props.setValidationMessage('Please Add Name')
      props.setCreateError(true)
    } else {
      setIsLoading(true)
      let response
      response = await searchDnbSupplier(props.validationsForm.environment, {
        ...searchInfo
      })

      if (response && response.success) {
        setData(response.data.result)
      } else {
        setData([])
        if (response?.data?.message)
          props.setValidationMessage(response.data.message)
        else props.setValidationMessage('No results found')
        props.setCreateError(true)
      }
      setIsLoading(false)
    }
    setTimeout(function () {
      props.setSubmitSuccess(false)
      props.setCreateError(false)
    }, 5000)
  }
  const tableIcons = {
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  }

  function onFieldChange (field, value, check = false) {
    if (check) {
      if (value) {
        searchInfo.Exclusions.push(field)
      } else {
        let selected = searchInfo.Exclusions.findIndex(x => x === field)
        if (selected !== -1) {
          searchInfo.Exclusions.splice(selected, 1)
        }
      }
    } else {
      searchInfo[field] = value
    }
    setSearchInfo({ ...searchInfo })
    if (props.setSearchInfo) props.setSearchInfo({ ...searchInfo })
  }
  const actions = [
    {
      icon: () => <AddCircle style={{ color: '#081c3e' }} />,
      tooltip: 'Map',
      onClick: (event, rowData) => {
        setShowMatchDialog(true)
        setMatchDetails({
          dunsNumber: rowData.dunsNumber,
          supplierId: props.supplierId
        })
        // updateMissingDuns(matchDetails)
      }
    }
  ]
  async function updateMissingDuns (data) {
    const response = await updateDnbSupplier(
      props.validationsForm.environment,
      data
    )

    if (response && response.success) {
      if (response?.data?.message)
        props.setValidationMessage(response.data.message)
      else props.setValidationMessage('Success')
      props.setSubmitSuccess(true)
    } else {
      if (response?.data?.message)
        props.setValidationMessage(response.data.message)
      else props.setValidationMessage('Something went wrong')
      props.setCreateError(true)
    }
    setIsLoading(false)

    setTimeout(function () {
      props.setSubmitSuccess(false)
      props.setCreateError(false)
    }, 5000)
    props.setAssignDuns(false)
  }

  return (
    <Grid container>
      {showMatchDialog ? (
        <CustomModal
          title={`Are you Sure you want to set duns ${matchDetails.dunsNumber} to Supplier?`}
          confirmBtnCssClass={props.confirmBtnCssClass}
          cancelBtnCssClass={props.cancelBtnCssClass}
          onCancel={() => {
            setShowMatchDialog(false)
          }}
          onConfirm={async () => {
            setIsLoading(true)
            setShowMatchDialog(false)
            await updateMissingDuns(matchDetails)
            setIsLoading(false)
          }}
        >
          <div style={{ width: '100%' }}></div>
          <Grid container spacing={4} style={{ paddingTop: '10px' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
          </Grid>
        </CustomModal>
      ) : (
        ''
      )}

      {isLoading ? <Spinner /> : ''}
      <h3 className={classes.h3}>
        {props.title ? props.title : 'Match Suppliers'}
      </h3>
      <Grid container>
        <Button
          onClick={() => {
            props.setAssignDuns(false)
          }}
        >
          <ArrowBackIcon />
        </Button>
        <Grid item xs={12}>
          {props.cardTitle ? props.cardTitle : ''}

          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', margin: '20px 10px 20px 10px' }}
                    value={searchInfo.name}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        getInfoSearch()
                      }
                    }}
                    onChange={e => {
                      onFieldChange('name', e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Street Line 1"
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', margin: '20px 10px 20px 10px' }}
                    value={searchInfo.streetLine}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        getInfoSearch()
                      }
                    }}
                    onChange={e => {
                      onFieldChange('streetLine', e.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%', margin: '20px 10px 20px 10px' }}
                  >
                    <Select
                      menuPortalTarget={document.getElementById('cardId')}
                      style={{ width: '100%' }}
                      placeholder={'Country'}
                      options={countryListOptions}
                      value={searchInfo.country}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                      onChange={e => {
                        onFieldChange('country', e)
                      }}
                    ></Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider variant="middle" />
              <Button
                color="primary"
                style={{ float: 'right' }}
                onClick={() => getInfoSearch()}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div>
            <h4 style={{ margin: '50px 0 20px 0' }}>Possible Suppliers</h4>

            <MaterialTable
              icons={tableIcons}
              columns={columns}
              data={data}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false
              }}
              actions={actions}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
