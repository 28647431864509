import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle'

// Form
import { Controller } from 'react-hook-form'

const useStyles = makeStyles(styles)

export const BooleanInput = props => {
  const classes = useStyles()
  const { name, control, label, readOnly } = props

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormLabel className={classes.inputLabelHorizontal}>{label}</FormLabel>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControlLabel
            style={{ margin: '1rem 0' }}
            control={
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <Switch color="primary" size="small" checked={field.value} {...field} disabled={readOnly}/>
                )}
              />
            }
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
