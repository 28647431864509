import { useQuery, useMutation } from 'react-query'
import { getRequisition, updateRequisition } from 'services/apiCoupa'

export function useGetRequisition(requisitionId) {
  return useQuery(
    ['requisition', requisitionId],
    () => getRequisition(requisitionId).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 60000,
      refetchIntervalInBackground: true
    }
  )
}

export function useUpdateRequisitionPrice() {
  return useMutation(({ requisitionId, body }) =>
    updateRequisition(requisitionId, body)
  )
}
