import { compose, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import { signOut } from "store/AuthState";

import CreatePage from "views/Teams/Create";
import DetailsPage from "views/Teams/Details";

import TeamsView from "./TeamsView";


let _isMounted = false

function getMenu(props) {
	let menuRoutes = [
		{
			path: "/create",
			lookup: "create",
			name: "Create",
			component: CreatePage,
			layout: "/teams"
		},
		{
			path: "/details",
			lookup: "details",
			name: "Details",
			component: DetailsPage,
			layout: "/teams"
		}
	]

	if (_isMounted) {
		props.setTeamsMenu(menuRoutes)
	}
}


export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{ signOut }
	),
	withState("teamsMenu", "setTeamsMenu", []),
	lifecycle({
		componentDidMount() {
			_isMounted = true
			getMenu(this.props)
		}
	})
)(TeamsView);
