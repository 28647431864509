import React, { useCallback, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Badge } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import AddAlert from '@material-ui/icons/AddAlert'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Snackbar from 'components/Snackbar/Snackbar'
// Form Validation
import { Controller, useWatch } from 'react-hook-form'

import { Button, CircularProgress, IconButton, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import { useDebounce } from 'hooks/useDebounce'
import { useGetPoDetails, useGetPoMatches } from 'hooks/useMasterData'
import { HeaderFieldsSide } from 'utils/Constants'
import { checkErrorOrWarning } from 'utils/functions'
import { generateJsonValidation } from 'utils/validator'
import InfoModal from '../../components/Invoice/InfoModal'

const useStyles = makeStyles(styles)

const PoSearch = React.forwardRef((props, ref) => {
  const {
    control,
    summaryField,
    onChangeSummaryField,
    index,
    rules,
    getValues,
    setValueForm,
    label,
    //appConfig,
    onChangePo,
    validationsError,
    setTextInputRef,
    setValidationRules,
    setValidationErrorsState,
    validationErrorsState,
    setIsPoWarning,
    isPoWarning,
    setPoInformation,
    setPoModal,
    appId
    //onApplyMappings
  } = props

  const classes = useStyles()

  let validationErrorMessages = validationErrorsState[summaryField.type]?.messages ?? ''
  let validated = validationErrorsState[summaryField.type]?.valid ?? null
  const [isValid, setIsValid] = useState(false)
  const [initialErrorMessages] = useState(validationsError)
  const [updatedValue, setUpdatedValue] = useState(summaryField.value)
  const [initialValue] = useState(summaryField.value)
  const [typeMsg, setTypeMsg] = useState(checkErrorOrWarning(validationErrorMessages, validated))
  const [typeMsgFirst, setTypeMsgFirst] = useState(checkErrorOrWarning(validationsError))

  const [open, setOpen] = useState(false)
  const [createError, setCreateError] = useState({ message: '', isOpen: false })

  const [filter, setFilter] = useState(() => {
    const summaryFields = getValues('summaryFields')
    const item = summaryFields.find(
      summaryField => summaryField.type === HeaderFieldsSide.PURCHASE_ORDER.type
    )

    if (!item || !item?.value) {
      return
    }
    return item.value
  })
  const value = useWatch({
    name: 'summaryFields',
    control
  })

  const nonPOIndex = value.findIndex(field => field.type === HeaderFieldsSide.NON_PO.type)
  const nonPO = nonPOIndex >= 0 ? value[nonPOIndex] : {}
  const nonPoValue = nonPO?.value ?? false

  const poIdSelected = value?.[index]?.value

  const [infoModal, setInfoModal] = useState(false)
  const [debouncedValue] = useDebounce(filter, 500)
  const poIds = useGetPoMatches(debouncedValue, { appId })
  const poDetails = useGetPoDetails(poIdSelected, { appId })

  const rulesPO = generateJsonValidation(rules, summaryField.type)
  const changeValidationToSuccess = useCallback(() => {
    setTypeMsg('')
    setIsValid(true)
    setValidationErrorsState(old => {
      const previousValidation = old[summaryField.type] ?? {}
      return {
        ...old,
        [summaryField.type]: {
          ...previousValidation,
          messages: [{ type: 'success', message: 'Accepted by User' }],
          valid: true
        }
      }
    })
  }, [setValidationErrorsState, summaryField.type])

  const changeValidationToWarning = useCallback(
    initialErrorMessages => {
      setTypeMsg('warning')
      setTypeMsgFirst('warning')
      setIsValid(false)
      setValidationErrorsState(old => {
        const previousValidation = old[summaryField.type] ?? {}
        return {
          ...old,
          [summaryField.type]: {
            ...previousValidation,
            messages: initialErrorMessages,
            valid: null
          }
        }
      })
    },
    [setValidationErrorsState, summaryField.type]
  )

  const changeValidationToError = useCallback(
    initialErrorMessages => {
      setTypeMsg('error')
      setTypeMsgFirst('error')
      setIsValid(false)
      setValidationErrorsState(old => {
        const previousValidation = old[summaryField.type] ?? {}
        return {
          ...old,
          [summaryField.type]: {
            ...previousValidation,
            messages: initialErrorMessages,
            valid: null
          }
        }
      })
    },
    [setValidationErrorsState, summaryField.type]
  )

  useEffect(() => {
    if (nonPoValue) {
      changeValidationToSuccess()
      return
    }
    if (typeMsgFirst === 'error') {
      changeValidationToError(initialErrorMessages)
    } else if (typeMsgFirst === 'warning') {
      changeValidationToWarning(initialErrorMessages)
    }
    // eslint-disable-next-line
  }, [
    nonPoValue,
    changeValidationToSuccess,
    changeValidationToError,
    changeValidationToWarning,
    initialErrorMessages
  ])

  useEffect(() => {
    if (!poDetails.data) {
      return
    }

    setPoInformation(poDetails.data)
    setValidationErrorsState(old => {
      const previousValidation = old[summaryField.type] ?? {}
      if (previousValidation.ruleId === 'PONF' && !previousValidation.valid) {
        setTypeMsg('')
        setIsValid(true)
        return {
          ...old,
          [summaryField.type]: {
            ...previousValidation,
            messages: [{ type: 'success', message: 'Accepted by User' }],
            valid: true
          }
        }
      }
      return old
    })
  }, [poDetails.data, setPoInformation, setValidationErrorsState, summaryField.type])

  useEffect(() => {
    if (!open || !poIdSelected || poIdSelected === '') {
      return
    }

    setFilter(poIdSelected)
  }, [open, getValues, poIdSelected])

  useEffect(() => {
    if (poIds.data && poIds.data?.length > 0 && setIsPoWarning) {
      setIsPoWarning(false)
    }
  }, [poIds.data, setIsPoWarning])

  useEffect(() => {
    if (!poIdSelected) {
      setIsPoWarning(true)
    }
  }, [poIdSelected, setIsPoWarning])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    if (poIdSelected !== filter) {
      setFilter(poIdSelected)
    }

    onChangePo(poDetails.data)

    ref.current = false

    // eslint-disable-next-line
  }, [poDetails.data, ref, onChangePo, rules.required, setValueForm])

  useEffect(() => {
    if (initialValue !== updatedValue) {
      if (typeMsgFirst !== '') {
        changeValidationToSuccess()
      }
      return
    }

    if (isValid || validated) {
      changeValidationToSuccess()
      return
    }

    if (typeMsgFirst === 'error') {
      changeValidationToError(initialErrorMessages)
    } else if (typeMsgFirst === 'warning') {
      changeValidationToWarning(initialErrorMessages)
    }
  }, [
    updatedValue,
    changeValidationToError,
    changeValidationToWarning,
    changeValidationToSuccess,
    initialValue,
    isValid,
    typeMsgFirst,
    initialErrorMessages,
    validated
  ])

  const handleChange = event => {
    setIsPoWarning(true)
    setFilter(event.target.value)
  }

  function changePoId(value) {
    if (!value) {
      return
    }
    setTypeMsg('')
    if (typeMsgFirst === 'warning' && !validated) {
      setTypeMsg('warning')
    }
  }

  function onSuccessInfoModal() {
    if (!debouncedValue) {
      return
    }
    const summaryFields = getValues('summaryFields')
    const index = summaryFields.findIndex(summaryField => summaryField.type === 'PURCHASE_ORDER')
    if (index < 0) {
      return
    }
    onChangePo({ poData: null })
    setValueForm(`summaryFields.${index}.value`, debouncedValue, {
      shouldValidate: true,
      shouldDirty: true
    })
    setValidationRules(old => ({
      ...old,
      PONF: false,
      SNMPO: false,
      POSNVSISN: false
    }))
    setInfoModal(false)
    changeValidationToSuccess()
    setIsPoWarning(false)
  }

  function validationMessages(validationsError, validated = false) {
    if (!validationsError || validated) {
      return ''
    }

    if (Array.isArray(validationsError)) {
      return (
        <>
          {validationsError.map((message, index) => (
            <div key={index}>{message.message}</div>
          ))}
        </>
      )
    }

    const messages = validationsError.split('\n')

    return (
      <>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </>
    )
  }

  function getColors(isDirty, typeMsg, error) {
    let color = ''
    let inputColor = ''
    let badgeColor = ''
    let tooltipColor = ''

    if (typeMsg === 'error') {
      color = classes.errorColor
      inputColor = classes.inputError
      tooltipColor = classes.tooltipError
    }

    if (validated) {
      color = classes.successColor
      inputColor = classes.inputSuccess
    }

    if (isValid && !Boolean(error)) {
      color = classes.successColor
      inputColor = classes.inputSuccess
    }

    if (isDirty && !Boolean(error)) {
      color = classes.successColor
      inputColor = classes.inputSuccess
    }

    if (!isDirty && typeMsg === 'warning') {
      color = classes.warningColor
      inputColor = classes.inputWarning
      tooltipColor = classes.tooltipWarning
    }

    return { color, inputColor, badgeColor, tooltipColor }
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Controller
          render={({ field, fieldState: { error, isDirty } }) => {
            const isWarning = !isDirty && typeMsg === 'warning' && !Boolean(error)
            const isVisible =
              (Boolean(error) && typeMsg === 'error') ||
              (!isDirty && typeMsg === 'error') ||
              isWarning ||
              Boolean(error)
            setUpdatedValue(field.value)

            const backEndErrorMessage = validationMessages(validationErrorMessages, validated)
            const errorMessage = backEndErrorMessage ? backEndErrorMessage : error?.message

            return (
              <Grid container justifyContent="center">
                <Grid item xs={typeMsgFirst === 'warning' && !Boolean(error) && !isDirty ? 11 : 12}>
                  <Tooltip
                    classes={{
                      arrow: isWarning ? classes.warningColor : classes.errorColor,
                      tooltip: isWarning ? classes.tooltipWarning : classes.tooltipError
                    }}
                    title={isVisible ? errorMessage : ''}
                    arrow
                    placement="right"
                  >
                    <Badge
                      style={{ width: '95%' }}
                      classes={{
                        badge: isWarning ? classes.badgeWarning : classes.badgeError
                      }}
                      badgeContent={
                        isWarning ? (
                          <WarningIcon className={classes.warningColor}></WarningIcon>
                        ) : (
                          '!'
                        )
                      }
                      invisible={isVisible ? false : true}
                    >
                      <Autocomplete
                        {...field}
                        freeSolo
                        style={{ width: '100%' }}
                        options={poIds.data ?? []}
                        classes={{
                          option: classes.option
                        }}
                        open={open}
                        onOpen={() => {
                          setOpen(true)
                        }}
                        onClose={() => {
                          setOpen(false)
                        }}
                        getOptionLabel={option => option}
                        loading={poIds.isLoading || poIds.isFetching}
                        renderInput={params => (
                          <TextField
                            {...params}
                            value={filter}
                            onChange={handleChange}
                            id={summaryField.id}
                            label={label}
                            variant="outlined"
                            fullWidth
                            error={Boolean(error)}
                            onClick={e =>
                              onChangeSummaryField({
                                id: summaryField.id,
                                type: 'header',
                                ocr: null,
                                shouldSetValue: false,
                                page: value?.[index]?.page
                              })
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {poIds.isLoading || poIds.isFetching ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : isPoWarning ? (
                                    <WarningIcon
                                      style={{
                                        color: '#FCD34D',
                                        cursor: 'pointer'
                                      }}
                                      size={20}
                                      onClick={() => setInfoModal(true)}
                                    />
                                  ) : null}

                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                              classes: {
                                notchedOutline: !Boolean(error)
                                  ? getColors(isDirty, typeMsg, error, field.value).inputColor
                                  : ''
                              }
                            }}
                            InputLabelProps={{
                              classes: {
                                root: !Boolean(error)
                                  ? getColors(isDirty, typeMsg, error).color
                                  : '',
                                focused: !Boolean(error)
                                  ? getColors(isDirty, typeMsg, error).color
                                  : ''
                              }
                            }}
                            inputRef={element => setTextInputRef(element, summaryField.id)}
                          />
                        )}
                        onChange={(_, data) => {
                          field.onChange(data)
                          changePoId(data)
                          ref.current = true
                        }}
                      />
                    </Badge>
                  </Tooltip>
                </Grid>
                {typeMsgFirst === 'warning' && !Boolean(error) && !isDirty ? (
                  <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton
                      style={{ padding: '0' }}
                      className={classes.buttonSuccess}
                      onClick={() =>
                        isValid || validated
                          ? changeValidationToWarning(initialErrorMessages)
                          : changeValidationToSuccess()
                      }
                    >
                      {isValid || validated ? (
                        <CheckCircleIcon className={classes.successColor} />
                      ) : (
                        <CheckCircleOutlineIcon className={classes.successColor} />
                      )}
                    </IconButton>
                  </Grid>
                ) : null}
              </Grid>
            )
          }}
          name={`summaryFields.${index}.value`}
          control={control}
          rules={{
            validate: {
              required: value => {
                if (!rulesPO.required?.value) {
                  return true
                }

                if (nonPOIndex >= 0 && getValues(`summaryFields.${nonPOIndex}`).value) return true

                return value ? true : rulesPO.required.message
              },
              pattern: value => {
                if (!rulesPO?.pattern?.value) {
                  return true
                }
                const exp = new RegExp(rulesPO.pattern.value)
                if ((nonPOIndex >= 0 && getValues(`summaryFields.${nonPOIndex}`).value) || !exp)
                  return true
                return exp.test(value) || rulesPO.pattern.message
              }
            }
          }}
        />
      </Grid>
      <Grid container justifyContent="flex-start">
        <Grid item>
          <Tooltip title="PO Information" placement="right">
            <Button onClick={() => setPoModal(old => ({ ...old, details: true }))} size="small">
              See Details
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="PO Information" placement="right">
            <Button onClick={() => setPoModal(old => ({ ...old, update: true }))} size="small">
              Update
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      <InfoModal
        showModal={infoModal}
        onSubmit={onSuccessInfoModal}
        onClose={() => setInfoModal(false)}
        warningMessage="This PO number does not exist in our system. Do you want to use it anyway?"
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <WarningIcon style={{ color: '#fcd34d' }}></WarningIcon> <span>Warning</span>
          </div>
        }
        textSuccessBtn={'Agree'}
      />
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '' })}
        close
      />
    </Grid>
  )
})

export default PoSearch
