const exceptionFieldsStyle = theme => ({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  },
  otherFields: {
    flex: '0 0 50%',
    display: 'flex',
    margin: '2rem 0 0 0',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formControl: {
    minWidth: 100,
    maxWidth: 100,
    margin: ' 0 2rem 0 1rem'
  },
  badgeWarning: {
    backgroundColor: 'white',
    color: 'white'
  },
  badgeError: {
    backgroundColor: 'red',
    color: 'white'
  },
  warningColor: {
    color: '#f3bc07 !important'
  },
  successColor: {
    color: 'green !important'
  },
  errorColor: {
    color: 'red !important'
  },
  tooltipWarning: {
    backgroundColor: 'white',
    color: '#cfa107',
    maxWidth: 220,
    border: '1px solid #f3bc07'
  },
  tooltipError: {
    backgroundColor: 'white',
    color: 'red',
    maxWidth: 220,
    border: '1px solid red'
  },
  inputWarning: {
    borderColor: '#f3bc07 !important'
  },
  inputSuccess: {
    borderColor: 'green !important'
  },
  inputError: {
    borderColor: 'red !important'
  },

  buttonSuccess: {
    color: 'green !important',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  readOnly: {
    '& .MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: '#ebe9e9'
    },
    '& input:disabled': {
      cursor: 'pointer'
    },
    '& textarea:disabled': {
      cursor: 'pointer'
    }
  },
  readOnlySelect: {
    '& .MuiSelect-select.Mui-disabled': {
      backgroundColor: '#ebe9e9 !important',
      cursor: 'pointer'
    }
  }
})
export default exceptionFieldsStyle
