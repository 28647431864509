import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import Button from '@material-ui/core/Button'
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'
import Slide from '@material-ui/core/Slide'
const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export default function ResetModal(props) {
  const classes = useStyles()
  const { showModal, onClose, onSubmit } = props

  function handleSubmit(isActiveApplyMappings) {
    onSubmit(isActiveApplyMappings)
    onClose()
  }
  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      open={showModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose()}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
    >
      <DialogTitle
        id="notice-modal-slide-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        Reset Invoice
      </DialogTitle>
      <DialogContent
        id="notice-modal-slide-description"
        className={classes.modalBody}
      >
        <p
          style={{
            marginBottom: '1.5rem'
          }}
        >
          Do you wish to refresh or reset the invoice?
        </p>
        <p>
          <strong>Refresh</strong>: Reprocess the invoice with your newly
          updated mappings
        </p>
        <p>
          <strong>Reset</strong>: Revert to the original values on the invoice
        </p>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={() => handleSubmit(true)}
          color="primary"
          variant="outlined"
        >
          Refresh
        </Button>
        <Button
          onClick={() => handleSubmit(false)}
          color="primary"
          variant="outlined"
        >
          Reset
        </Button>
        <Button onClick={() => onClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
