import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'
import AddAlert from '@material-ui/icons/AddAlert'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import DescriptionIcon from '@material-ui/icons/Description'
import ErrorIcon from '@material-ui/icons/Error'
import InboxIcon from '@material-ui/icons/Inbox'
import Alert from '@material-ui/lab/Alert'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import AdminNavbarLinks from 'components/Navbars/Admin/AdminNavbarLinks'
import Snackbar from 'components/Snackbar/Snackbar'

import { useGetPreviousAndNextInvoiceV2, useUpdateStatusInvoice } from 'hooks/useInvoices'

import { useGeInvoicesAiTenantConfig, useGetTenantConfig } from 'hooks/useTenantConfig'
import React, { useCallback, useEffect, useState } from 'react'
//import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { signOut } from 'store/AuthState'

import { ExceptionFields } from './ExceptionFields'

import Grid from '@material-ui/core/Grid'
import { useGetInvoiceStandardV2 } from 'hooks/useInvocieSandard'
import { useGetConnectivityConfiguration } from 'hooks/useInvoiceConfig'
import { useGetSchemaRuleFields } from 'hooks/useValidationRules'
//import { getStandardInvoice } from 'services/apiInvoices'
import { ExceptionDocumentSimplePdf } from './ExceptionDocumentSimplePdf'
import { useHeader } from './useHeader'
import { useLines } from './useLines'

const useStylesContainer = makeStyles(theme => ({
  root: {
    maxHeight: '100vh'
  }
}))

const useStylesGridItemLeft = makeStyles(theme => ({
  root: {
    maxHeight: '100vh'

    //flexGrow: 1
  }
}))

const useStylesGridItemRight = makeStyles(theme => ({
  root: {
    maxHeight: '100vh',
    borderLeft: '3px solid rgb(0, 0, 0, 0.14)',
    backgroundColor: 'white',
    overflow: 'auto'
  }
}))

const useStylesGridItemRightHeader = makeStyles(theme => ({
  root: {
    padding: '2px'
  }
}))
const useStylesGridItemRightBody = makeStyles(theme => ({
  root: {
    padding: '0 5px 5px 5px'
  }
}))

const useStylesAlert = makeStyles({
  myAlert: {
    color: '#000000',
    backgroundColor: '#f3bc07'
  }
})

export default function ExceptionView(props) {
  const { invoiceFetch, isReadOnly, processList } = props
  const classesContainer = useStylesContainer()
  const classesAlert = useStylesAlert()
  const dispatch = useDispatch()
  const classesGridItemLeft = useStylesGridItemLeft()
  const classesGridItemRight = useStylesGridItemRight()
  const classesGridItemRightHeader = useStylesGridItemRightHeader()
  const classesGridItemRightBody = useStylesGridItemRightBody()
  const connectivityConfig = useGetConnectivityConfiguration(invoiceFetch.data?.appId)
  const { invoiceId } = useParams()
  const history = useHistory()
  //const queryClient = useQueryClient()
  const [pageNumber, setPageNumber] = useState(1)
  const [tab, setTab] = useState(0)
  const [createError, setCreateError] = useState({ message: '', isOpen: false })
  const [lineItems, lineItemsActions] = useLines({
    type: 'lineItem',
    dataPerPage: 20
  })

  const [additionalCharges, additionalChargesActions] = useLines({
    type: 'additionalCharges',
    dataPerPage: 20
  })

  const [taxes, taxesActions] = useLines({
    type: 'taxes',
    dataPerPage: 20
  })

  const [headerFields, headerFieldsActions] = useHeader()

  const {
    data: configTenant,
    isLoading: isLoadingTenantConfig,
    isError: isErrorConfig
  } = useGetTenantConfig()

  const schemaRules = useGetSchemaRuleFields(invoiceFetch.data?.appId, invoiceFetch.data?.process)

  const invoiceStandardData = useGetInvoiceStandardV2({ invoiceId })

  const updateStatusInvoice = useUpdateStatusInvoice(invoiceFetch.data?.appId, invoiceId)
  const { mutate: mutateUpdateStatusInvoice } = updateStatusInvoice

  const nextPreviousInvoice = useGetPreviousAndNextInvoiceV2({
    filters: {
      subProcess: 'e-invoice',
      status: ['invoiceException']
    },
    invoiceId: invoiceId,
    config: { enabled: !isReadOnly }
  })

  const appConfig = useGeInvoicesAiTenantConfig('invoiceAI', invoiceFetch.data?.appId)

  const isLocked = invoiceFetch.data?.isLocked

  const { setData: setDataLineItems } = lineItemsActions
  const { setData: setDataAdditionalCharges } = additionalChargesActions
  const { setDataHeader } = headerFieldsActions
  const { setData: setDataTaxes } = taxesActions

  useEffect(() => {
    if (!invoiceStandardData.data || !schemaRules.data) {
      return
    }

    const dataCharges = invoiceStandardData.data.invoice.invoice.detail.additionalCharges ?? []
    const dataTaxes = invoiceStandardData.data.invoice.invoice.detail.taxLines ?? []

    const errorCharges = Object.values(
      invoiceStandardData.data.metadata.validations.additionalCharges ?? []
    )
    const errorTaxes = Object.values(invoiceStandardData.data.metadata.validations.taxLines ?? [])

    setDataLineItems({
      lines: invoiceStandardData.data.invoice.invoice.detail.lineItems ?? [],
      errors: isReadOnly
        ? []
        : Object.values(invoiceStandardData.data.metadata.validations.lineItems ?? []),
      requiredFields: schemaRules.data
    })
    setDataAdditionalCharges({
      lines: dataCharges,
      errors: isReadOnly ? [] : errorCharges
    })
    setDataTaxes({
      lines: dataTaxes,
      errors: isReadOnly ? [] : errorTaxes
    })
    setDataHeader({
      errors: isReadOnly ? [] : invoiceStandardData.data.metadata.validations.header.errors ?? []
    })
  }, [
    invoiceId,
    setDataLineItems,
    setDataAdditionalCharges,
    setDataHeader,
    setDataTaxes,
    invoiceStandardData.data,
    schemaRules.data,
    isReadOnly
  ])

  useEffect(() => {
    if (isLocked === false) {
      const body = { status: 'locked' }
      mutateUpdateStatusInvoice(body, {
        onError: error => {
          console.error('error', error)
        }
      })

      const handler = setInterval(() => {
        const body = { status: 'locked' }
        mutateUpdateStatusInvoice(body, {
          onError: error => {
            console.error('error', error)
          }
        })
      }, 20000)
      return () => {
        clearInterval(handler)
      }
    }
  }, [isLocked, invoiceId, mutateUpdateStatusInvoice])

  const onChangeTab = useCallback(index => {
    setTab(index)
  }, [])

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  if (
    isLoadingTenantConfig ||
    appConfig.isLoading ||
    invoiceStandardData.isLoading ||
    schemaRules.isLoading
  ) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    )
  }

  if (isErrorConfig || invoiceStandardData.isError || schemaRules.isError) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Something went wrong, try again later</p>
      </div>
    )
  }

  const breadcrumbViews = [
    {
      name: 'Invoices',
      url: `/admin/invoiceAI/index`,
      icon: InboxIcon
    },
    {
      name: 'Invoice Details',
      url: `/admin/invoiceAI/${invoiceId}/details`,
      icon: DescriptionIcon
    },
    {
      name: 'Invoice Exception',
      url: `/view/invoiceAI/${invoiceId}/exception-details`,
      icon: ArtTrackIcon
    }
  ]

  function getInvoiceId(invoiceId) {
    if (!invoiceId) {
      return
    }
    setPageNumber(1)
    setTab(0)
    history.push(`/view/invoiceAI/${invoiceId}/exception-details`)
  }

  return (
    <Grid container className={classesContainer.root}>
      <Grid item xs={6} className={classesGridItemLeft.root}>
        <ExceptionDocumentSimplePdf
          changePage={changePage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          filePath={invoiceStandardData.data.attachment}
        />
      </Grid>
      <Grid item xs={6} className={classesGridItemRight.root}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classesGridItemRightHeader.root}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {isLocked ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Alert
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => history.push(`/admin/invoiceAI/index`)}
                    >
                      Exit
                    </Button>
                  }
                  icon={
                    <ErrorIcon
                      fontSize="small"
                      style={{
                        color: '#000000'
                      }}
                    />
                  }
                  className={classesAlert.myAlert}
                >
                  This invoice is currently read-only because{' '}
                  {invoiceFetch.data?.lockedBy?.userName} is currently editing the document.{' '}
                  {invoiceFetch.data?.lockedBy?.userName} must finish editing the invoice by leaving
                  the screen or logging out.
                </Alert>
              </Grid>
            ) : null}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                height: '25px'
              }}
            >
              <Hidden smDown implementation="css">
                <AdminNavbarLinks signOut={() => dispatch(signOut())} rtlActive={true} />
              </Hidden>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Breadcrumbs views={breadcrumbViews} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {/*   <SyncLoader showLoader={ocrInformation.isFetching && !ocrInformation.isLoading} /> */}
          </Grid>
        </Grid>
        <Grid container className={classesGridItemRightBody.root}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ExceptionFields
              standardInvoice={invoiceStandardData.data}
              //standardInvoice={standardInvoice}
              appId={invoiceFetch.data.appId}
              invoiceId={invoiceId}
              pageNumber={pageNumber}
              onChangeTab={onChangeTab}
              tab={tab}
              appConfig={appConfig.data}
              invoiceData={invoiceFetch.data}
              setPageNumber={setPageNumber}
              onChangeInvoice={getInvoiceId}
              invoicesExceptions={nextPreviousInvoice.data}
              connectivityConfig={connectivityConfig.data}
              requiredFields={schemaRules.data}
              defaultAppId={configTenant.defaultApp}
              isLocked={isLocked}
              lineItems={lineItems}
              additionalCharges={additionalCharges}
              lineItemsActions={lineItemsActions}
              additionalChargesActions={additionalChargesActions}
              headerData={headerFields}
              headerFieldsActions={headerFieldsActions}
              taxes={taxes}
              taxesActions={taxesActions}
              readOnly={isReadOnly}
              processList={processList}
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '' })}
        close
      />
    </Grid>
  )
}
