import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { getDownloadDocumentsQuery, postDownloadDocumentsQuery } from 'services/apiInvoices'

import {
  createPurchaseOrder,
  getAccountTypesByTenant,
  getAccounts,
  getBuyerAddresses,
  getCommodities,
  getCommodity,
  getPurchaseOrder,
  getPurchaseOrderDetailsQuery,
  getPurchaseOrderMatchesQuery,
  getPurchaseOrders,
  getSegmentsByAccountTypes,
  getSupplierAddresses,
  getSuppliersByNameQuery,
  getSuppliersQuery,
  updatePurchaseOrder
} from 'services/apiMasterData'
import { getUserTenant } from 'utils/functions'

export function useGetSuppliers(debouncedFilter, params) {
  return useQuery(
    ['MasterData', 'Supplier-Id', debouncedFilter, params],
    () => getSuppliersQuery(debouncedFilter, params).then(result => result.data),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false
    }
  )
}

export function useGetSuppliersByName(debouncedFilter, params) {
  return useQuery(
    ['MasterData', 'Supplier-ByName', debouncedFilter, params],
    () =>
      getSuppliersByNameQuery(debouncedFilter, params).then(result => {
        if (!result.data) {
          return []
        }
        return result.data.map(supplier => ({
          name: supplier.name,
          supplierId: supplier.supplierId,
          status: supplier.status
        }))
      }),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false
    }
  )
}

export function useGetPoMatches(debouncedFilter, params) {
  return useQuery(
    ['MasterData', 'purchase-orders', 'lookup', debouncedFilter],
    () => getPurchaseOrderMatchesQuery(debouncedFilter, params).then(result => result.data),
    {
      enabled: Boolean(debouncedFilter),
      refetchOnWindowFocus: false
    }
  )
}

export function useGetPoDetails(poId, params) {
  return useQuery(
    ['MasterData', 'PO', poId, params],
    () => getPurchaseOrderDetailsQuery(poId, params).then(result => result.data),
    {
      enabled: Boolean(poId),
      refetchOnWindowFocus: false
    }
  )
}

export function usePostDownloadDocument(configTenant, historicalInvoiceFlag) {
  return useMutation(({ type, extension }) =>
    postDownloadDocumentsQuery({
      app: configTenant?.defaultApp,
      type,
      extension,
      historicalInvoiceFlag
    })
  )
}

export function useGetDownloadDocument(configTenant, documentId) {
  return useQuery(
    ['MasterData', 'Document', documentId],
    () =>
      getDownloadDocumentsQuery({
        app: configTenant?.defaultApp,
        documentId
      }).then(result => result.data),
    {
      enabled: Boolean(documentId),
      refetchOnWindowFocus: false,
      refetchInterval: 3000
    }
  )
}

export function useGetBuyerAddress({ companyCode, params, config = {} }) {
  return useQuery(
    ['MasterData', 'company-addresses', 'company-profiles', companyCode, params],
    () =>
      getBuyerAddresses({
        companyCode,
        params
      }).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      ...config
    }
  )
}

export function useGetSupplierAddresses({ supplierId, config = {}, params }) {
  return useQuery(
    ['MasterData', 'supplier-addresses', 'suppliers', supplierId, params],
    () => getSupplierAddresses(supplierId, params).then(result => result.data),
    {
      enabled: Boolean(supplierId),
      refetchOnWindowFocus: false,
      ...config
    }
  )
}

// Master Data Management

// Purchase order
export function useGetPurchaseOrders({ pagination, appId, options = {} }) {
  const queryClient = useQueryClient()
  const { lastEvaluatedKeys, page, rowsPage } = pagination
  const lastEvaluatedKey = page === 0 ? null : lastEvaluatedKeys[page - 1]

  const useQueryResult = useQuery(
    ['MasterData', 'PurchaseOrders', page, rowsPage],
    () =>
      getPurchaseOrders({ lastEvaluatedKey, rowsPage, appId }).then(result => {
        const { purchaseOrders, lastEvaluatedKey } = result.data
        const newPurchaseOrders = purchaseOrders.map(purchaseOrder => {
          return {
            id: purchaseOrder.poNumber,
            poNumber: purchaseOrder.poNumber,
            dateCreated: purchaseOrder.dateCreated,
            requester: purchaseOrder.requester?.name,
            supplierId: purchaseOrder.supplier?.id,
            approver: purchaseOrder.approvers?.[0]?.name,
            dateCreatedEpoch: purchaseOrder.dateCreatedEpoch
          }
        })
        return { rows: newPurchaseOrders, lastEvaluatedKey: lastEvaluatedKey ?? null }
      }),
    {
      ...options
    }
  )

  useEffect(() => {
    if (useQueryResult?.data?.lastEvaluatedKey) {
      queryClient.prefetchQuery(['MasterData', 'PurchaseOrders', page + 1, rowsPage], () =>
        getPurchaseOrders({
          lastEvaluatedKey: useQueryResult?.data?.lastEvaluatedKey,
          rowsPage
        }).then(result => {
          const { purchaseOrders, lastEvaluatedKey } = result.data
          const newPurchaseOrders = purchaseOrders.map(purchaseOrder => {
            return {
              id: purchaseOrder.poNumber,
              poNumber: purchaseOrder.poNumber,
              dateCreated: purchaseOrder.dateCreated,
              requester: purchaseOrder.requester?.name,
              supplierId: purchaseOrder.supplier?.id,
              approver: purchaseOrder.approvers?.[0]?.name,
              dateCreatedEpoch: purchaseOrder.dateCreatedEpoch
            }
          })
          return { rows: newPurchaseOrders, lastEvaluatedKey: lastEvaluatedKey ?? null }
        })
      )
    }
  }, [useQueryResult.data, page, rowsPage, queryClient])

  return useQueryResult
}

export function useGetPurchaseOrder({ poId, appId, options = {} }) {
  return useQuery(
    ['MasterData', 'PurchaseOrders', poId],
    () =>
      getPurchaseOrder({
        poId,
        appId
      }).then(result => result.data),
    {
      ...options
    }
  )
}

export function useCreatePurchaseOrder() {
  return useMutation(values =>
    createPurchaseOrder({
      poBody: values.data,
      appId: values.appId
    })
  )
}

export function useUpdatePurchaseOrder({ poId }) {
  return useMutation(values =>
    updatePurchaseOrder({
      poId,
      poBody: values.data,
      appId: values.values
    })
  )
}

export function useGetAccountTypesByTenant(params, options = {}) {
  const tenantId = getUserTenant()
  return useQuery(
    ['MasterData', 'AccountTypes', 'Tenants', tenantId, params],
    () => getAccountTypesByTenant(params).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      ...options
    }
  )
}

export function useGetSegmentsByAccountType(paramsFetch) {
  const { accountTypeId, params, options = {} } = paramsFetch
  return useQuery(
    ['MasterData', 'AccountTypes', accountTypeId, 'Segments', params],
    () => getSegmentsByAccountTypes(accountTypeId, params).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(accountTypeId),
      ...options
    }
  )
}

export function useGetAllCommodity(params, options = {}) {
  return useQuery(
    ['MasterData', 'Commodities', params],
    () => getCommodities(params).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      ...options
    }
  )
}

export function useGetCommodity(commodityId, params, options = {}) {
  return useQuery(
    ['MasterData', 'Commodities', commodityId, params],
    () => getCommodity(commodityId, params).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      enabled: !!commodityId,
      ...options
    }
  )
}

export function useGetAccounts(params) {
  return useQuery(['MasterData', 'Accounts', params], () => getAccounts(params), {
    enabled: Boolean(params.accountTypeId),
    refetchOnWindowFocus: false
  })
}
