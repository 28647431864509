import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'
import moment from 'moment'

// material-ui/icons
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { TablePaginationActions } from 'components/Invoice/TablePaginationActions'
import TablePagination from '@material-ui/core/TablePagination'

// material-ui/core
import Fab from '@material-ui/core/Fab'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'

// functions
import { statusColor } from 'utils/functions'

import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js'
const useStyles = makeStyles(styles)

export default function CustomExecutionsTable(props) {
  const classes = useStyles()
  const {
    enabled,
    rows,
    render,
    childLoads,
    showDestination,
    buttonsArray,
    newRun
  } = props

  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: true
    },
    {
      field: 'load',
      title: 'Load'
    },
    {
      field: 'destination',
      title: 'Destination',
      hidden: !showDestination
    },
    {
      field: 'type',
      title: 'Type'
    },
    {
      field: 'startDate',
      title: 'Start Date'
    },
    {
      field: 'creationDate',
      title: 'Creation Date',
      render: rowData =>
        moment(rowData.creationDate).format('MM/DD/YYYY HH:mm'),
      customSort: (a, b) => {
        return moment(moment(b.creationDate)).isBefore(moment(a.creationDate))
          ? 1
          : -1
      },
      defaultSort: 'desc'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: statusColor(rowData.status) }}>{rowData.status}</p>
      )
    },
    {
      field: 'updateFrequency',
      title: 'Frequency'
    },
    {
      field: 'statusProgress',
      title: 'Progress',
      render: rowData => (
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={rowData.statusProgress}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="caption" color="textSecondary">{`${Math.round(
              rowData.statusProgress
            )}%`}</Typography>
          </Box>
        </Box>
      )
    }
  ]

  /*   const handleChangePage = (event, newPage) => {
    setPagination(old => {
      return {
        ...old,
        page: newPage
      }
    })
  } */

  function typeOfTable(props) {
    const {
      isCustomPagination,
      pagination,
      pageSizeOptions,
      onChangeRowsPerPage,
      handleChangePage,
      rows
    } = props

    if (isCustomPagination) {
      if (
        !pagination ||
        !pageSizeOptions ||
        !onChangeRowsPerPage ||
        !onChangeRowsPerPage
      ) {
        throw new Error('You need to send props for pagination')
      }
    }

    if (isCustomPagination) {
      return (
        <MaterialTable
          columns={columns}
          data={rows}
          actions={[
            {
              disabled: !enabled,
              icon: 'view_column',
              tooltip: 'Load Details',
              onClick: (event, rowData) => render(rowData.id)
            }
          ]}
          options={{
            actionsColumnIndex: -1,
            sorting: true,
            showTitle: false,
            emptyRowsWhenPaging: false,
            pageSize: pagination.rowsPage,
            pageSizeOptions: pageSizeOptions
          }}
          onChangeRowsPerPage={pageSize => onChangeRowsPerPage(pageSize)}
          components={{
            Pagination: props => {
              return (
                <TablePagination
                  {...props}
                  onChangePage={handleChangePage}
                  labelDisplayedRows={() => `${pagination.page + 1}`}
                  ActionsComponent={subProps => (
                    <TablePaginationActions
                      {...subProps}
                      pagination={pagination}
                    />
                  )}
                />
              )
            }
          }}
        />
      )
    } else {
      return (
        <MaterialTable
          columns={columns}
          data={rows}
          actions={[
            {
              disabled: !enabled,
              icon: 'view_column',
              tooltip: 'Load Details',
              onClick: (event, rowData) => render(rowData.id)
            }
          ]}
          options={{
            actionsColumnIndex: -1,
            sorting: true,
            showTitle: false,
            emptyRowsWhenPaging: false
          }}
        />
      )
    }
  }

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12}>
        <h5 className={classes.sectionTitle}>
          {rows ? (
            <>
              {buttonsArray.includes('newRun') ? (
                <Tooltip title={'New Run'}>
                  <Fab
                    style={{ marginLeft: '8px' }}
                    size="small"
                    color="primary"
                    className={
                      classes.floatRight + ' ' + classes.bgColorPrimary
                    }
                    onClick={() => newRun()}
                  >
                    <PlayArrowIcon fontSize="small" />
                  </Fab>
                </Tooltip>
              ) : null}
              {buttonsArray.includes('refresh') ? (
                <Tooltip title={'Refresh'}>
                  <Fab
                    disabled={!enabled}
                    size="small"
                    color="primary"
                    className={
                      classes.floatRight + ' ' + classes.bgColorPrimary
                    }
                    onClick={() => childLoads()}
                  >
                    <RefreshRoundedIcon fontSize="small" />
                  </Fab>
                </Tooltip>
              ) : null}
            </>
          ) : null}
        </h5>
      </GridItem>
      <GridItem xs={12} sm={12}>
        <p>
          <b>Executions:</b>
        </p>

        {rows ? (
          <GridContainer justify="center" style={{ marginTop: '5px' }}>
            <GridItem xs={12} sm={12} md={12}>
              {typeOfTable(props)}
            </GridItem>
          </GridContainer>
        ) : null}
      </GridItem>
    </GridContainer>
  )
}

CustomExecutionsTable.defaultProps = {
  showDestination: true,
  buttonsArray: ['refresh']
}

CustomExecutionsTable.propTypes = {
  rows: PropTypes.array,
  enabled: PropTypes.bool,
  render: PropTypes.func,
  childLoads: PropTypes.func,
  showDestination: PropTypes.bool,
  buttonsArray: PropTypes.array,
  isCustomPagination: PropTypes.bool,
  pagination: PropTypes.object,
  pageSizeOptions: PropTypes.array,
  onChangeRowsPerPage: PropTypes.func
}
