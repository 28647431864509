import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import Button from '@material-ui/core/Button'
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'
import Slide from '@material-ui/core/Slide'

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export default function InfoModal(props) {
  const classes = useStyles()
  const { showModal, onClose, warningMessage, title, transitionComponent = Transition } = props

  return (
    <Dialog
      fullWidth={true}
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      open={showModal}
      TransitionComponent={transitionComponent}
      keepMounted
      onClose={() => onClose()}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
    >
      <DialogTitle
        id="notice-modal-slide-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
        {warningMessage}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => onClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
