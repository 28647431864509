import React, { useState } from 'react'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import CustomInput from '../../components/CustomInput/CustomInput.js'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Switch from '@material-ui/core/Switch'
import CustomModal from 'components/CustomModal/CustomModal.js'
import { Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js'

const useStyles = makeStyles(styles)

export default function CustomValidationsModal(props) {
  const [descriptionValue, setDescriptionValue] = useState(props.description)
  const [typeValue, setTypeValue] = useState(props.type)
  const [enableValue, setEnableValue] = useState(props.enabled)
  const [kiValue, setKIValue] = useState(props.ki)
  const [sendParameterValue, setSendParameterValue] = useState(
    props.sendParameter
  )
  const [responseKIValue, setResponseKIValue] = useState(props.responseKI)
  const [parameterNameValue, setParameterNameValue] = useState(
    props.parameterName
  )
  const [defaultValue, setDefaultValue] = useState(props.defaultValue)
  const [responseFieldValue, setResponseFieldValue] = useState(
    props.responseField
  )
  const [subtypeMenu, setSubtypeMenu] = useState([])
  const [subtypeValue, setSubtypeValue] = useState(props.subtype)
  const classes = useStyles()
  const menuOptions = ['Generic']

  const addressMenuOptions = [
    { value: 'street_line_1', label: 'Street Line 1' },
    { value: 'street_line_2', label: 'Street Line 2' },
    { value: 'street_line_3', label: 'Street Line 3' },
    { value: 'city', label: 'City' },
    { value: 'state', label: 'State' },
    { value: 'postal_code', label: 'Postal Code' },
    { value: 'country', label: 'Country' }
  ]

  const extendedMenuOptions = [
    { value: 'street', label: 'Street' },
    { value: 'street_2', label: 'Street 2' },
    { value: 'city', label: 'City' },
    { value: 'state', label: 'State' },
    { value: 'postal_code', label: 'Postal Code' },
    { value: 'country', label: 'Country' }
  ]

  if (props.service === 'ariba') {
    menuOptions.push('Address', 'Tax', 'Extended')
  }

  const returnValues = {
    descriptionValue,
    typeValue,
    enableValue,
    kiValue,
    sendParameterValue,
    responseKIValue,
    parameterNameValue,
    defaultValue,
    responseFieldValue,
    subtypeValue
  }

  return (
    <CustomModal
      title={props.title}
      confirmBtnCssClass={props.confirmBtnCssClass}
      cancelBtnCssClass={props.cancelBtnCssClass}
      onCancel={props.onCancel}
      onConfirm={() => {
        props.onConfirm(returnValues)
      }}
    >
      <GridContainer container spacing={4} style={{ paddingBottom: '5%' }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Box hidden={props.descriptionHidden} component={GridItem}>
            <CardHeader>{props.descriptionLabel}</CardHeader>
            <CardBody>
              <CustomInput
                labelProps={{ style: { width: '100%' } }}
                id="description"
                formControlProps={{
                  fullWidth: true
                }}
                required
                inputProps={{
                  type: 'text',
                  value: descriptionValue,
                  onChange: event => {
                    setDescriptionValue(event.target.value)
                  }
                }}
              />
            </CardBody>
          </Box>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Box hidden={props.defaultValueHidden} component={GridItem}>
            <CardHeader>{props.defaultValueLabel}</CardHeader>
            <CardBody>
              <CustomInput
                labelProps={{ style: { width: '100%' } }}
                id="defaultValue"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: 'text',
                  value: defaultValue,
                  onChange: event => {
                    setDefaultValue(event.target.value)
                  }
                }}
              />
            </CardBody>
          </Box>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Box hidden={props.responseFieldHidden} component={GridItem}>
            <CardHeader>{props.responseFieldLabel}</CardHeader>
            <CardBody>
              <CustomInput
                labelProps={{ style: { width: '100%' } }}
                id="responseField"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: 'text',
                  value: responseFieldValue,
                  onChange: event => {
                    setResponseFieldValue(event.target.value)
                  }
                }}
              />
            </CardBody>
          </Box>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Box hidden={props.parameterNameHidden} component={GridItem}>
            <CardHeader>{props.parameterNameLabel}</CardHeader>
            <CardBody>
              <CustomInput
                labelProps={{ style: { width: '100%' } }}
                id="parameterName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => {
                    setParameterNameValue(event.target.value)
                    // console.log(event)
                  },
                  type: 'text',
                  value: parameterNameValue
                }}
              />
            </CardBody>
          </Box>
        </GridItem>
        <GridContainer
          spacing={typeValue === 'address' || typeValue === 'extended' ? 4 : 0}
        >
          <GridItem
            xs={typeValue === 'address' || typeValue === 'extended' ? 6 : 12}
            sm={typeValue === 'address' || typeValue === 'extended' ? 6 : 12}
            md={typeValue === 'address' || typeValue === 'extended' ? 6 : 12}
            lg={typeValue === 'address' || typeValue === 'extended' ? 6 : 12}
          >
            <Box hidden={props.typeValueHidden} component={GridItem}>
              <div>
                <CardHeader
                  style={{
                    paddingLeft:
                      typeValue === 'address' || typeValue === 'extended'
                        ? '20px'
                        : '40px'
                  }}
                >
                  {props.typeLabel}
                </CardHeader>
                <CardBody>
                  <Select
                    MenuProps={{
                      style: { zIndex: 5555 }
                    }}
                    style={{
                      width: '100%',
                      paddingLeft:
                        typeValue === 'address' || typeValue === 'extended'
                          ? 'auto'
                          : '20px'
                    }}
                    value={typeValue}
                    onChange={e => {
                      if (e.target.value === 'address') {
                        setSubtypeMenu(addressMenuOptions)
                        setSubtypeValue(addressMenuOptions[0].value)
                      } else if (e.target.value === 'extended') {
                        setSubtypeMenu(extendedMenuOptions)
                        setSubtypeValue(extendedMenuOptions[0].value)
                      } else {
                        setSubtypeValue('')
                      }
                      setTypeValue(e.target.value)
                    }}
                    inputProps={{
                      name: 'fieldType',
                      id: 'fieldType'
                    }}
                  >
                    {menuOptions.map(value => {
                      let val = value.toLowerCase()
                      return (
                        <MenuItem key={value} value={val}>
                          {value}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </CardBody>
              </div>
            </Box>
          </GridItem>
          {typeValue === 'address' || typeValue === 'extended' ? (
            <GridItem xs={6} sm={6} md={6} lg={6}>
              <div>
                <CardHeader>{props.subtypeLabel}</CardHeader>
                <CardBody>
                  <Select
                    MenuProps={{
                      style: { zIndex: 5555 }
                    }}
                    style={{ width: '100%' }}
                    value={subtypeValue}
                    onChange={e => setSubtypeValue(e.target.value)}
                    inputProps={{
                      name: 'fieldType',
                      id: 'fieldType'
                    }}
                  >
                    {subtypeMenu.map(item => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </CardBody>
              </div>
            </GridItem>
          ) : (
            ''
          )}
        </GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Box hidden={props.kiHidden} component={GridItem}>
            <CardHeader>{props.kiLabel}</CardHeader>
            <CardBody>
              <CustomInput
                labelProps={{ style: { width: '100%' } }}
                id="ki"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => {
                    setKIValue(event.target.value)
                    // console.log(event)
                  },
                  type: 'text',
                  value: kiValue
                }}
              />
            </CardBody>
          </Box>
        </GridItem>
        <GridContainer
          container
          spacing={props.typeValueHidden && props.sendParameterHidden ? 0 : 4}
        >
          <GridItem
            xs={props.typeValueHidden && props.sendParameterHidden ? false : 6}
            sm={props.typeValueHidden && props.sendParameterHidden ? false : 6}
            md={props.typeValueHidden && props.sendParameterHidden ? false : 6}
            lg={props.typeValueHidden && props.sendParameterHidden ? false : 6}
            style={{
              paddingLeft:
                props.typeValueHidden && props.sendParameterHidden
                  ? '40px'
                  : 'auto'
            }}
          >
            <Box hidden={props.enabledValueHidden} component={GridItem}>
              <CardHeader
                style={{
                  paddingLeft:
                    props.typeValueHidden && props.sendParameterHidden
                      ? '40px'
                      : 'auto'
                }}
              >
                {props.enableLabel}
              </CardHeader>
              <CardBody
                style={{
                  paddingLeft:
                    props.typeValueHidden && props.sendParameterHidden
                      ? '40px'
                      : 'auto'
                }}
              >
                <div>
                  <Switch
                    checked={enableValue}
                    onChange={event => setEnableValue(event.target.checked)}
                    value="Active"
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                </div>
              </CardBody>
            </Box>
          </GridItem>
          <GridItem
            xs={props.typeValueHidden && props.sendParameterHidden ? false : 6}
            sm={props.typeValueHidden && props.sendParameterHidden ? false : 6}
            md={props.typeValueHidden && props.sendParameterHidden ? false : 6}
            lg={props.typeValueHidden && props.sendParameterHidden ? false : 6}
          >
            <Box hidden={props.sendParameterHidden} component={GridItem}>
              <CardHeader>{props.sendParameterLabel}</CardHeader>
              <CardBody>
                <div>
                  <Switch
                    checked={sendParameterValue}
                    onChange={event =>
                      setSendParameterValue(event.target.checked)
                    }
                    value="Active"
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                </div>
              </CardBody>
            </Box>
          </GridItem>
        </GridContainer>
        {sendParameterValue && (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CardHeader>{props.responseKILabel}</CardHeader>
            <CardBody>
              <div>
                <CustomInput
                  labelProps={{ style: { width: '100%' } }}
                  id="responseKI"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: 'text',
                    value: responseKIValue,
                    onChange: event => {
                      setResponseKIValue(event.target.value)
                    }
                  }}
                />
              </div>
            </CardBody>
          </GridItem>
        )}
      </GridContainer>
    </CustomModal>
  )
}
