import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'

//Services
import {
  getRequisitionsDetails,
  getRequisitionsDetailsChanges
} from 'services/apiApps'

//Views
import TmobileDetailsView from './TmobileDetailsView'

//Functions
import { showLoading } from 'utils/functions'
import { hideLoading } from 'utils/functions'

let _isMounted = false
let id

async function initDetails (props, id) {
  await getDetails(props, id)
  await getDetailsChanges(props, id)
  hideLoading(props.setPageIsLoading)
}

async function getDetails (props, id) {
  let response = await getRequisitionsDetails('requisitions', id)
  if (response && response.success) {
    if (_isMounted) {
      props.setRequisitions(response.data)
      props.setRowsDetails(response.data.lineItems)
      props.setRequester(response.data.requester)
      let approvalRequests = response.data.approvalRequests.map((approver, i) => {
        approver.order = 2
        if (approver.dependencyApprovalRequestsUID.length === 0) {
          approver.order = 1
        }
        if (approver.approvalState === "Ready for approval") {
          props.setActiveStep(i)
        }
        return approver
      })
      approvalRequests.sort(function (a, b) {
        return a.order - b.order || b.state - a.state
      })
      props.setRowsApprovals(approvalRequests)
    }
  } else {
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

async function getDetailsChanges (props, id) {
  let response = await getRequisitionsDetailsChanges('requisitions', id)
  if (response && response.success && _isMounted) {
    if (_isMounted) {
      props.setRowsChanges(response.data)
    }
  } else {
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

export default compose(
  connect(state => ({
    isAuthenticated: state.login.isAuthenticated,
    name: state.login.name
  })),
  withState('activeStep', 'setActiveStep', 0),
  withState('requisitions', 'setRequisitions', {}),
  withState('requester', 'setRequester', null),
  withState('rowsApprovals', 'setRowsApprovals', []),
  withState('rowsDetails', 'setRowsDetails', []),
  withState('rowsChanges', 'setRowsChanges', []),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withHandlers({
    onAppChanged: props => id => {}
  }),
  lifecycle({
    componentDidMount () {
      _isMounted = true
      id = this.props.match.params.id
      showLoading(this.props.setPageIsLoading)
      initDetails(this.props, id)
    },
    componentWillUnmount () {
      _isMounted = false
    }
  })
)(TmobileDetailsView)
