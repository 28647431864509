import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import MaterialTable from 'material-table'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { CardActions, Link } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'

// Assets
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import poweredByRelish from 'assets/img/POC/powered-by-relish.png'

// Components
import Badge from 'components/Badge/Badge'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardText from 'components/Card/CardText'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import Timeline from 'components/Timeline/Timeline'

// Hooks
import { useQueryParams } from 'hooks/POC/CoupaIFrameApp/useQueryParams'

// Services
import { getAppConfigurationsByApp } from 'services/apiApps'

// Custom Components
import PocExecutionApp from './ExecutionApp'
import PocActionButtonsApp from './ActionButtonsApp'
import { transformOpReadTransactionsPag } from 'services/apiDataAssure'

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: '#FFFFFF',
    marginTop: '0'
  },
  cardCategory: {
    color: '#999999',
    marginTop: '10px'
  },
  cardCategoryWhite: {
    margin: '0',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '.875rem'
  }
}

const useStyles = makeStyles(styles)

export default function PocResultsApp(props) {
  const classes = useStyles()

  const query = useQueryParams()
  const appId = query.get('appId')
  const supplierId = query.get('object_id')

  const [states, setStates] = useState({
    showLoader: false,
    selectedApp: null,
    lastExecution: null,
    historyExecutions: null,
    snackBarMessage: null,
    snackBarShowSuccess: false,
    snackBarShowError: false
  })

  const { data: dataAppList, isLoading: loadingAppList, isFetching: fetchingAppList } = useQuery(
    ['AppList', 'slp'],
    () => getAppConfigurationsByApp('slp').then(result => result.data),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      onSuccess: data => {
        if (data) {
          const app = data.find(app => app.env.trim().toLowerCase() === appId.trim().toLowerCase())
          if (app) {
            setStates(old => ({
              ...old,
              selectedApp: app
            }))
          }
        }
      }
    }
  )

  const { data: executions, isLoading: loadingExecutionList, isFetching: fetchingExecutionList, refetch: refetchExecutions } = useQuery(
    ['SlpValidations', `${states.selectedApp?.env || null}-${supplierId}`],
    () =>
      transformOpReadTransactionsPag(states.selectedApp?.env || null, 'coupa-iframe', supplierId, -1, undefined, undefined).then(result => {
        if (result && result.data) {
          result.data.items = result.data.items.filter(i => ['IFRAME', 'DA'].includes(i.pollerId))
          setStates(old => ({
            ...old,
            lastExecution: result.data.items.shift()
          }))
          return result.data.items
        }
      }),
    {
      enabled: Boolean(states.selectedApp),
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true
    }
  )

  useEffect(() => {
    setStates(old => ({
      ...old,
      showLoader: loadingAppList || fetchingAppList || loadingExecutionList || fetchingExecutionList
    }))
  }, [loadingAppList, fetchingAppList, loadingExecutionList, fetchingExecutionList])

  return (
    <>
      <GridContainer
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '30px',
          width: '100%',
          margin: '0'
        }}
      >
        <GridItem xs={12}></GridItem>
        <GridItem xs={12}>{states.showLoader && <SyncLoader showLoader={true} />}</GridItem>

        <GridItem xs={12}>
          {!states.showLoader && !dataAppList && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <WarningIcon fontSize="large" style={{ color: '#ff9800' }} />
              <h3 style={{ margin: '20px' }}>{`Couldn't retrieve apps from tenant!`}</h3>
            </div>
          )}
        </GridItem>

        <GridItem xs={12}>
          {!states.showLoader && dataAppList && !states.selectedApp && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <WarningIcon fontSize="large" style={{ color: '#ff9800' }} />
              <h3 style={{ margin: '20px' }}>{`App ${appId} wasn't found in tenant!`}</h3>
            </div>
          )}
        </GridItem>

        <GridItem xs={12} sm={10} md={8} lg={6}>
          {!states.showLoader && dataAppList && states.selectedApp && !states.lastExecution && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <WarningIcon fontSize="large" style={{ color: '#ff9800' }} />
                <h3 style={{ margin: '20px' }}>{`Supplier has not been Validated by Data Assure`}</h3>
              </div>
              <br />
              <Card>
                <CardHeader color="gray" text>
                  <CardText color="gray">
                    <h4 className={classes.cardTitleWhite}>{'Not Validated by Relish Data Assure'}</h4>
                  </CardText>
                  <div style={{ display: 'inline-block', float: 'right', marginTop: '15px' }}>
                    <PocActionButtonsApp
                      parentStates={states}
                      setParentStates={setStates}
                      refetchExecutions={refetchExecutions}
                      validateText="Validate"
                    ></PocActionButtonsApp>
                  </div>
                </CardHeader>
                <CardBody>
                  <p style={{ textAlign: 'left' }}>
                    <b>
                      Real-time supplier validations of Addresses, TIN, Bank Routing, and Sanction List checks provided by Relish Data
                      Assure.
                    </b>
                    <a
                      href="https://relishiq.com/solutions/relish-data-assure/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#3c78c8' }}
                    >
                      {' Click to learn More'}
                    </a>
                    <br />
                    <br />- Last check was performed: Not Validated by Relish Data Assure
                    <br />- Next check is scheduled for: Not currently scheduled
                  </p>
                  <MaterialTable
                    columns={[
                      {
                        cellStyle: { padding: '5px 16px' },
                        title: 'Validation type',
                        field: 'name'
                      },
                      {
                        cellStyle: { padding: '5px 16px' },
                        title: 'Status',
                        field: 'result',
                        render: rowData => <Badge color="gray">{rowData.result}</Badge>
                      }
                    ]}
                    data={[
                      {
                        name: 'Address',
                        result: 'Not validated'
                      },
                      {
                        name: 'Bank',
                        result: 'Not validated'
                      },
                      {
                        name: 'Sanction list',
                        result: 'Not validated'
                      },
                      {
                        name: 'Tax',
                        result: 'Not validated'
                      },
                      {
                        name: 'Bank Account Ownership',
                        result: 'Not validated'
                      }
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      sorting: true,
                      showTitle: false,
                      search: false,
                      paging: false,
                      toolbar: false
                    }}
                  />
                </CardBody>
                <CardActions>
                  <div style={{ textAlign: 'left' }}>
                    <a
                      href="https://relishiq.com/solutions/relish-data-assure/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#3c78c8' }}
                    >
                      <img src={poweredByRelish} alt="Go to Relish" style={{ maxWidth: '200px' }} />
                    </a>
                  </div>
                </CardActions>
              </Card>
            </>
          )}
        </GridItem>

        <GridItem xs={12} sm={12} md={10} lg={8}>
          {!states.showLoader && dataAppList && states.selectedApp && states.lastExecution && (
            <Card>
              <CardHeader
                color={
                  (states.lastExecution.result.toUpperCase() === 'PASSED' && 'success') ||
                  (states.lastExecution.result.toUpperCase() === 'PASSED WITH CAUTIONS' && 'yellow') ||
                  (states.lastExecution.result.toUpperCase() === 'FAILED' && 'danger') ||
                  'gray'
                }
                text
              >
                <CardText
                  color={
                    (states.lastExecution.result.toUpperCase() === 'PASSED' && 'success') ||
                    (states.lastExecution.result.toUpperCase() === 'PASSED WITH CAUTIONS' && 'yellow') ||
                    (states.lastExecution.result.toUpperCase() === 'FAILED' && 'danger') ||
                    'gray'
                  }
                >
                  <h4 className={classes.cardTitleWhite}>
                    {(states.lastExecution.result.toUpperCase() === 'NO MATCH' && 'No data Available') || states.lastExecution.result}
                  </h4>
                  <h5 className={classes.cardCategoryWhite}>{new Date(states.lastExecution.processingDate).toLocaleString('en-US')}</h5>
                </CardText>
                <div style={{ display: 'inline-block', float: 'right', marginTop: '15px' }}>
                  <PocActionButtonsApp
                    parentStates={states}
                    setParentStates={setStates}
                    refetchExecutions={refetchExecutions}
                  ></PocActionButtonsApp>
                </div>
              </CardHeader>
              <CardBody>
                <p style={{ textAlign: 'left' }}>
                  <b>
                    Real-time supplier validations of Addresses, TIN, Bank Routing, and Sanction List checks provided by Relish Data Assure.
                  </b>
                  <a
                    href="https://relishiq.com/solutions/relish-data-assure/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#3c78c8' }}
                  >
                    {' Click to learn More'}
                  </a>
                  <br />
                  <br />- Last check was performed:
                  {` ${new Date(states.lastExecution.processingDate).toLocaleString('en-US')}`}
                  <br />- Next check is scheduled for: Not currently scheduled
                </p>
                <PocExecutionApp execution={states.lastExecution} />
              </CardBody>
              <CardActions>
                <GridContainer>
                  <GridItem xs={6} style={{ textAlign: 'left' }}>
                    <a
                      href="https://relishiq.com/solutions/relish-data-assure/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#3c78c8' }}
                    >
                      <img src={poweredByRelish} alt="Go to Relish" style={{ maxWidth: '200px' }} />
                    </a>
                  </GridItem>

                  <GridItem xs={6} style={{ textAlign: 'right', alignSelf: 'center' }}>
                    {executions?.length ? (
                      <Link
                        style={{ padding: 'inherit' }}
                        component="button"
                        variant="button"
                        onClick={() => {
                          if (states.historyExecutions) {
                            setStates(old => ({
                              ...old,
                              historyExecutions: null
                            }))
                          } else {
                            setStates(old => ({
                              ...old,
                              historyExecutions: executions
                            }))
                            setTimeout(() => {
                              document.getElementById('executions-timeline').scrollIntoView({
                                behavior: 'smooth'
                              })
                            }, 100)
                          }
                        }}
                      >
                        {(states.historyExecutions && 'Hide history') || 'View history'}
                      </Link>
                    ) : null}
                  </GridItem>
                </GridContainer>
              </CardActions>
            </Card>
          )}
        </GridItem>

        <GridItem xs={12} id={'executions-timeline'}>
          {!states.showLoader && states.historyExecutions && (
            <Card plain>
              <CardBody plain>
                <Timeline
                  stories={states.historyExecutions.map((execution, index, array) => ({
                    inverted: index % 2 === 0,
                    badgeColor:
                      (execution.result === 'Passed' && 'success') ||
                      (execution.result === 'Passed with Cautions' && 'yellow') ||
                      (execution.result === 'Failed' && 'danger'),
                    badgeIcon:
                      (execution.result === 'Passed' && CheckIcon) ||
                      (execution.result === 'Passed with Cautions' && WarningIcon) ||
                      (execution.result === 'Failed' && ErrorIcon),
                    title: execution.result,
                    titleColor:
                      (execution.result === 'Passed' && 'success') ||
                      (execution.result === 'Passed with Cautions' && 'yellow') ||
                      (execution.result === 'Failed' && 'danger'),
                    body: <PocExecutionApp execution={execution} />,
                    footerTitle: new Date(execution.processingDate).toLocaleString('en-US')
                  }))}
                />
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </>
  )
}
