import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@material-ui/lab/Alert'

export default function NotificationAlert (props) {
  const { appInfo, variant, severity } = props
  let daysLeft = ''
  let type = 'suscription'
  let showAlert = false

  if (appInfo.suscriptionType === 'freeTrial') {
    type = 'free trial'
  }

  if (appInfo.enabled === true) {
    showAlert = true
    if (appInfo.daysBetween > 15) {
      showAlert = false
    }
  }

  if (appInfo.daysBetween <= 15 && appInfo.daysBetween >= 0) {
    daysLeft = 'in ' + appInfo.daysBetween + ' days'
    if (appInfo.daysBetween === 1) {
      daysLeft = 'in ' + appInfo.daysBetween + ' day'
    }
    if (appInfo.daysBetween === 0) {
      daysLeft = 'today'
    }
  }

  return (
    <div>
      {showAlert ? (
        <Alert
          variant={variant}
          severity={severity}
          style={{ marginBottom: '7px' }}
        >
          Hey! Your {type} expires {daysLeft}. Your account will be deactivated
          and all of your apps will be disabled.
        </Alert>
      ) : null}
    </div>
  )
}

NotificationAlert.propTypes = {
  variant: PropTypes.string,
  severity: PropTypes.string,
  appInfo: PropTypes.object
}
