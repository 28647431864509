const emailModalStyle = {
  dialogContent: { display: 'flex', flexDirection: 'column' },
  body: {
    margin: '50px auto',
    maxWidth: '500px',
    textAlign: 'center'
  },
  fromTo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px'
  },
  center: {
    textAlign: 'center'
  },
  colorPrimary: {
    color: '#081c3e'
  },
  subTitle: { display: 'flex', justifyContent: 'space-between' }
}
export default emailModalStyle
