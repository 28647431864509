import axios from 'axios'
import { ApiCoupa } from 'utils/Constants'
import { createHeaders } from 'utils/functions'

export async function getRequisition(requisitionId) {
  let url = `${ApiCoupa.EndPoint}/requisition/${requisitionId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function updateRequisition(requisitionId, body) {
  let url = `${ApiCoupa.EndPoint}/requisition/${requisitionId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'patch',
    url: url,
    headers: requestHeaders,
    data: body
  })
}
