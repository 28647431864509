import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import TabPanel from 'components/Invoice/TabPanel'
import { get } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import SwipeableViews from 'react-swipeable-views'
import {
  AdditionalCharges,
  ColumnsOrderChargesTaxesStandard,
  ColumnsOrderLineItemsStandard,
  HeaderFieldsStandard,
  Taxes
} from 'utils/Constants'
import { HeaderField } from './HeaderField'
import SwitchField from './SwitchField'

import { useGeInvoicesAiTenantConfig } from 'hooks/useTenantConfig'

import { CircularProgress } from '@material-ui/core'

import DescriptionIcon from '@material-ui/icons/Description'
import InboxIcon from '@material-ui/icons/Inbox'
import { ReactComponent as InvoiceOcrCompare } from 'assets/img/invoices/git-compare-outline.svg'
import { useGetInvoiceStandardDiff, useGetInvoiceVersions } from 'hooks/useInvocieSandard'

import { useGetSchemaRuleFields } from 'hooks/useValidationRules'
import { formatDate } from 'utils/functions'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const gridStyle = { minHeight: 550 }

function sortElements(a, b) {
  return a.pos - b.pos
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    border: `1px solid #d1d5db`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper
  },
  rootGridCurrentVersion: {
    borderRight: `2px solid ${theme.palette.divider}`,
    backgroundColor: `#eeeeee`
  },
  rootGridHeaderPreviousVersion: {
    marginBottom: '1rem'
  },
  rootGridCircularProgress: {
    height: '50vh'
  },
  btnColorPrimary: {
    color: '#081c3e',
    border: '1px solid #081c3e',
    '&:hover': {
      backgroundColor: '#081c3e',
      color: 'white',
      border: '1px solid #081c3e'
    }
  }
}))

const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
  autoHide: true,
  alwaysShowTrack: true,
  scrollThumbStyle: {
    background: '#1E408A'
  }
})

const taxAndCharges = {
  ...Taxes,
  ...AdditionalCharges
}

function onRenderRow(cellProps, { data }, lineItemsDiff, side) {
  const { name } = cellProps
  const line = lineItemsDiff.find(x => x.id === data.id)
  const field = line?.changes?.find(x => x.field === name)

  let background = ''

  switch (field?.status) {
    case 'changed':
      if (side === 'left') {
        background = 'rgba(204, 122, 122, 0.27)'
        break
      }
      background = 'rgba(129, 204, 122, 0.27)'
      break
    case 'new':
      if (side === 'left') {
        break
      }
      background = 'rgba(129, 204, 122, 0.27)'
      break
    case 'deleted':
      if (side === 'right') {
        break
      }
      background = 'rgba(204, 122, 122, 0.27)'
      break
    default:
      break
  }
  cellProps.style.background = background
}

export function getColumns(params) {
  const { lineItemsDiff, side, appConfig, requiredFields, typeLine } = params

  const lineItemsFields = requiredFields.filter(x => x.level === typeLine)

  let lineItemsFieldsSorted = []
  if (typeLine === 'lineItem') {
    lineItemsFieldsSorted = lineItemsFields
      .map(x => ({
        ...x,
        pos: ColumnsOrderLineItemsStandard[x.standardField]?.pos ?? lineItemsFields.length
      }))
      .sort(function sortElements(a, b) {
        return a.pos - b.pos
      })
  } else {
    lineItemsFieldsSorted = lineItemsFields
      .map(x => ({
        ...x,
        pos: ColumnsOrderChargesTaxesStandard[x.standardField]?.pos ?? lineItemsFields.length
      }))
      .sort(function sortElements(a, b) {
        return a.pos - b.pos
      })
  }

  const columns = []
  const columnPos = {}

  columns.push({
    header: 'id',
    name: 'id',
    defaultFlex: 1,
    minWidth: 100,
    editable: false,
    defaultVisible: false
  })

  let position = 0

  // eslint-disable-next-line
  for (const field of lineItemsFieldsSorted) {
    if (field.standardField === ColumnsOrderLineItemsStandard.lineNumber.type) {
      columns.push({
        name: field.standardField,
        header: field.displayName,
        defaultFlex: 1,
        minWidth: 100,
        defaultVisible: false
      })
      continue
    }

    if (
      !appConfig.data?.params?.invoices?.showTaxRate &&
      field.standardField === ColumnsOrderChargesTaxesStandard.rate.type
    ) {
      continue
    }

    columnPos[field.standardField] = position
    position++

    let minWidth = 120
    if (field.standardField === ColumnsOrderLineItemsStandard.description.type) {
      minWidth = 400
    }

    if (field.standardField === ColumnsOrderLineItemsStandard.poLineNumber.type) {
      minWidth = 300
    }
    if (
      field.standardField === ColumnsOrderChargesTaxesStandard.type.type ||
      field.standardField === ColumnsOrderChargesTaxesStandard.taxCode.type
    ) {
      columns.push({
        name: field.standardField,
        header: field.displayName,
        defaultFlex: 1,
        minWidth: minWidth,
        defaultVisible: field.visible,
        render: ({ value }) => {
          return taxAndCharges[value] ? taxAndCharges[value].label : ''
        },
        onRender: (cellProps, cellParams) => onRenderRow(cellProps, cellParams, lineItemsDiff, side)
      })
      continue
    }
    columns.push({
      name: field.standardField,
      header: field.displayName,
      defaultFlex: 1,
      minWidth: minWidth,
      defaultVisible: field.visible,
      onRender: (cellProps, cellParams) => onRenderRow(cellProps, cellParams, lineItemsDiff, side)
    })
  }
  return { columns, columnPos }
}

function sortLines(a, b) {
  return a.version - b.version
}

function transformLines(lines, requiredFields = []) {
  const linesUpdated = []
  const lineItemsFields = requiredFields.filter(x => x.level === 'lineItem')
  // eslint-disable-next-line
  for (const [index, line] of lines.entries()) {
    const data = {}
    // eslint-disable-next-line
    for (const field of lineItemsFields) {
      const value = get(line, field.standardField)
      data[field.standardField] = value ?? ''
    }
    data.id = line.id
    //data.lineNumber = index
    linesUpdated.push(data)
  }
  return linesUpdated
}

export default function InvoiceAIDiff(props) {
  const { invoiceFetch } = props
  const theme = useTheme()
  const classes = useStyles()
  const { invoiceId } = useParams()
  const [tab, setTab] = useState(0)
  const [inputSelected, setInputSelected] = useState(null)
  const [gridRefPreviousLineItems, setGridRefPreviousLineItems] = useState(null)
  const [gridRefCurrentLineItems, setGridRefCurrentLineItems] = useState(null)
  const [gridRefPreviousTax, setGridRefPreviousTax] = useState(null)
  const [gridRefCurrentTax, setGridRefCurrentTax] = useState(null)
  const [gridRefPreviousCharges, setGridRefPreviousCharges] = useState(null)
  const [gridRefCurrentCharges, setGridRefCurrentCharges] = useState(null)
  const [cellSelectedTax, setCellSelectedTax] = useState(null)
  const [cellSelectedCharge, setCellSelectedCharge] = useState(null)
  const [cellSelectedLineItems, setCellSelectedLineItems] = useState(null)
  const [fileVersion, setFileVersion] = useState({ previous: null, current: null })
  const schemaRules = useGetSchemaRuleFields(invoiceFetch.data?.appId, invoiceFetch.data?.process)
  const appConfig = useGeInvoicesAiTenantConfig('invoiceAI', invoiceFetch.data?.appId)

  const transactionVersions = useGetInvoiceVersions({ invoiceId })

  const transactionDiff = useGetInvoiceStandardDiff({
    invoiceId,
    latestVersion: fileVersion.current,
    previousVersion: fileVersion.previous
  })

  useEffect(() => {
    if (transactionVersions.data) {
      const versionsSorted = transactionVersions.data.sort(sortLines)
      if (versionsSorted.length >= 2) {
        setFileVersion({
          previous: versionsSorted[versionsSorted.length - 2].version,
          current: versionsSorted[versionsSorted.length - 1].version
        })
      }
    }
  }, [transactionVersions.data])

  useEffect(() => {
    if (tab !== 1) {
      setGridRefPreviousTax(null)
      setGridRefCurrentTax(null)
      setGridRefPreviousCharges(null)
      setGridRefCurrentCharges(null)
    }
    if (tab !== 2) {
      setGridRefPreviousLineItems(null)
      setGridRefCurrentLineItems(null)
    }
  }, [tab])

  const lineItemsPrevious = React.useMemo(
    () =>
      transformLines(
        transactionDiff.data?.previousVersion?.lineItems ?? [],
        schemaRules.data?.filter(x => x.level === 'lineItem')
      ),
    [transactionDiff.data, schemaRules.data]
  )

  const lineItemsLatest = React.useMemo(
    () =>
      transformLines(
        transactionDiff.data?.latestVersion?.lineItems ?? [],
        schemaRules.data?.filter(x => x.level === 'lineItem')
      ),
    [transactionDiff.data, schemaRules.data]
  )

  const columnsLatestVersion = getColumns({
    lineItemsDiff: transactionDiff.data?.resultDiff?.lineItems?.result ?? [],
    side: 'right',
    appConfig,
    requiredFields: schemaRules.data ?? [],
    typeLine: 'lineItem'
  })

  const columnsPreviousVersion = getColumns({
    lineItemsDiff: transactionDiff.data?.resultDiff?.lineItems?.result ?? [],
    side: 'left',
    appConfig,
    requiredFields: schemaRules.data ?? [],
    typeLine: 'lineItem'
  })

  const columnsChargesPreviousVersion = getColumns({
    lineItemsDiff: transactionDiff.data?.resultDiff?.chargesAndTaxes?.result ?? [],
    side: 'left',
    appConfig,
    requiredFields: schemaRules.data ?? [],
    typeLine: 'additionalCharges'
  })

  const columnsChargesLatestVersion = getColumns({
    lineItemsDiff: transactionDiff.data?.resultDiff?.chargesAndTaxes?.result ?? [],
    side: 'right',
    appConfig,
    requiredFields: schemaRules.data ?? [],
    typeLine: 'additionalCharges'
  })

  const columnsTaxesPreviousVersion = getColumns({
    lineItemsDiff: transactionDiff.data?.resultDiff?.chargesAndTaxes?.result ?? [],
    side: 'left',
    appConfig,
    requiredFields: schemaRules.data ?? [],
    typeLine: 'taxes'
  })

  const columnsTaxesLatestVersion = getColumns({
    lineItemsDiff: transactionDiff.data?.resultDiff?.chargesAndTaxes?.result ?? [],
    side: 'right',
    appConfig,
    requiredFields: schemaRules.data ?? [],
    typeLine: 'taxes'
  })

  const onCellClickTax = useCallback(
    (event, cellProps) => {
      const { name, data } = cellProps

      if (gridRefPreviousTax && gridRefPreviousTax.current) {
        gridRefPreviousTax.current.scrollToId(data.id, {
          duration: 300,
          force: true
        })
        gridRefPreviousTax.current.scrollToColumn(
          columnsTaxesPreviousVersion.columnPos[name] ?? 0,
          {
            force: true,
            duration: 300
          }
        )
      }
      if (gridRefCurrentTax && gridRefCurrentTax.current) {
        gridRefCurrentTax.current.scrollToId(data.id, {
          duration: 300,
          force: true
        })
        gridRefCurrentTax.current.scrollToColumn(columnsTaxesLatestVersion.columnPos[name] ?? 0, {
          force: true,
          duration: 300
        })
      }
      setCellSelectedTax({ [`${data.id},${name}`]: true })
    },
    [
      gridRefPreviousTax,
      gridRefCurrentTax,
      columnsTaxesLatestVersion.columnPos,
      columnsTaxesPreviousVersion.columnPos
    ]
  )

  const onCellClickCharges = useCallback(
    (event, cellProps) => {
      const { name, data } = cellProps

      if (gridRefPreviousCharges && gridRefPreviousCharges.current) {
        gridRefPreviousCharges.current.scrollToId(data.id, {
          duration: 300,
          force: true
        })
        gridRefPreviousCharges.current.scrollToColumn(
          columnsChargesPreviousVersion.columnPos[name] ?? 0,
          {
            force: true,
            duration: 300
          }
        )
      }
      if (gridRefCurrentCharges && gridRefCurrentCharges.current) {
        gridRefCurrentCharges.current.scrollToId(data.id, {
          duration: 300,
          force: true
        })
        gridRefCurrentCharges.current.scrollToColumn(
          columnsChargesLatestVersion.columnPos[name] ?? 0,
          {
            force: true,
            duration: 300
          }
        )
      }
      setCellSelectedTax({ [`${data.id},${name}`]: true })
    },
    [
      gridRefPreviousCharges,
      gridRefCurrentCharges,
      columnsChargesLatestVersion.columnPos,
      columnsChargesPreviousVersion.columnPos
    ]
  )

  const onCellClickLineItems = useCallback(
    (event, cellProps) => {
      const { name, data } = cellProps
      if (gridRefPreviousLineItems && gridRefPreviousLineItems.current) {
        gridRefPreviousLineItems.current.scrollToId(data.id, {
          duration: 300,
          force: true
        })
        gridRefPreviousLineItems.current.scrollToColumn(
          columnsPreviousVersion.columnPos[name] ?? 0,
          {
            force: true,
            duration: 300
          }
        )
      }
      if (gridRefCurrentLineItems && gridRefCurrentLineItems.current) {
        gridRefCurrentLineItems.current.scrollToId(data.id, {
          duration: 300,
          force: true
        })
        gridRefCurrentLineItems.current.scrollToColumn(columnsLatestVersion.columnPos[name] ?? 0, {
          force: true,
          duration: 300
        })
      }
      setCellSelectedLineItems({ [`${data.id},${name}`]: true })
    },
    [
      gridRefPreviousLineItems,
      gridRefCurrentLineItems,
      columnsLatestVersion.columnPos,
      columnsPreviousVersion.columnPos
    ]
  )

  const breadcrumbViews = [
    {
      name: 'Invoices',
      url: `/admin/invoiceAI/index`,
      icon: InboxIcon
    },
    {
      name: 'Invoice Details',
      url: `/admin/invoiceAI/${invoiceId}/details`,
      icon: DescriptionIcon
    },
    {
      name: 'Compare Invoice',
      url: `/admin/invoiceAI/${invoiceId}/compare`,
      icon: InvoiceOcrCompare
    }
  ]

  const handleChangeIndex = index => {
    setTab(index)
  }

  const onClickTextField = summaryField => {
    setInputSelected({ id: summaryField.id, type: summaryField.type })
  }

  if (
    transactionVersions.isLoading ||
    transactionDiff.isLoading ||
    appConfig.isLoading ||
    schemaRules.isLoading
  ) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    )
  }

  if (transactionVersions.isError || transactionDiff.isError || schemaRules.isError) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Something went wrong, try again later</p>
      </div>
    )
  }

  const versionSelected = transactionVersions.data.find(
    versionFile => versionFile.version === fileVersion.previous
  )

  const latestVersionInformation = transactionVersions.data.find(
    versionFile => versionFile.version === fileVersion.current
  )

  const selectVersionOptions = transactionVersions.data
    .filter(versionTransaction => versionTransaction.version !== fileVersion.current)
    .sort(sortLines)
    .map(versionTransaction => {
      const numberVersion = versionTransaction.version
      const date = formatDate({
        date: versionTransaction.createdDate,
        options: {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23'
        }
      })
      return (
        <option key={numberVersion} value={numberVersion}>
          {`V${numberVersion + 1} (${date} - ${versionTransaction.generateBy?.userName?.substring(
            0,
            12
          )})${versionTransaction?.generateBy?.userName?.length > 12 ? '...' : ''}`}
        </option>
      )
    })

  const selectVersionOptionsB = transactionVersions.data
    .filter(versionTransaction => versionTransaction.version > fileVersion.previous)
    .sort(sortLines)
    .map(versionTransaction => {
      const numberVersion = versionTransaction.version
      const date = formatDate({
        date: versionTransaction.createdDate,
        options: {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23'
        }
      })
      return (
        <option key={numberVersion} value={numberVersion}>
          {`V${numberVersion + 1} (${date} - ${versionTransaction.generateBy?.userName?.substring(
            0,
            12
          )}${versionTransaction?.generateBy?.userName?.length > 12 ? '...' : ''})`}
        </option>
      )
    })

  function leftRightFields(invoiceInfo, side) {
    const invoiceData = []
    const vendorInfo = []
    const invoiceAmount = []
    const addressInfo = []
    const start = []
    const taxInformation = []
    const customInformation = []

    const headerFields = schemaRules.data.filter(x => x.level === 'header')
    // eslint-disable-next-line
    for (const field of headerFields) {
      let jsxElement
      const pos = HeaderFieldsStandard[field.standardField]?.pos ?? 0
      const label = field?.displayName ?? ''

      const fieldStatus = transactionDiff.data.resultDiff.header.find(
        x => x.field === field.standardField
      )

      const dataType = field?.validations?.find(x => x.type === 'dataType')
      let value = get(invoiceInfo.invoice.invoice.header, field.standardField)

      const modifiedBy = invoiceInfo.metadata.header.find(x => x.field === field.standardField)

      const summaryField = {
        type: field.standardField,
        modifiedBy: modifiedBy
      }

      switch (dataType.value) {
        case 'boolean':
          jsxElement = {
            jsx: (
              <Grid item xs={12} key={`${field.standardField}-${side}`}>
                <SwitchField
                  summaryField={summaryField}
                  value={value}
                  key={field.standardField}
                  label={label}
                />
              </Grid>
            ),
            pos
          }
          break
        default:
          let multiline = false
          if (HeaderFieldsStandard['supplier.remitToAddress.id'].type === field.standardField) {
            value = invoiceInfo.supplier?.address?.name ?? ''
            multiline = true
          }
          if (HeaderFieldsStandard['supplier.address.id'].type === field.standardField) {
            value = invoiceInfo.supplier?.remitAddress?.name ?? ''
            multiline = true
          }
          if (
            HeaderFieldsStandard['companyProfile.billToAddress.id'].type === field.standardField
          ) {
            value = invoiceInfo.companyProfile?.billingAddress?.name ?? ''
            multiline = true
          }
          if (
            HeaderFieldsStandard['companyProfile.shipToAddress.id'].type === field.standardField
          ) {
            value = invoiceInfo.companyProfile?.shipAddress?.name ?? ''
            multiline = true
          }
          jsxElement = {
            jsx: (
              <Grid item xs={12} key={`${field.standardField}-${side}`}>
                <HeaderField
                  summaryField={summaryField}
                  onClick={onClickTextField}
                  value={value ?? ''}
                  multiline={multiline}
                  inputSelected={inputSelected}
                  key={field.standardField}
                  fieldStatus={fieldStatus?.status ?? ''}
                  label={label}
                  side={side}
                />
              </Grid>
            ),
            pos
          }
          break
      }

      if (field.isCustomField) {
        customInformation.push(jsxElement)
        continue
      }

      switch (HeaderFieldsStandard[field.standardField]?.section) {
        case 'invoiceData':
          invoiceData.push(jsxElement)
          break
        case 'vendorInfo':
          vendorInfo.push(jsxElement)
          break
        case 'invoiceAmount':
          invoiceAmount.push(jsxElement)
          break
        case 'addressInfo':
          addressInfo.push(jsxElement)
          break
        case 'start':
          start.push(jsxElement)
          break
        case 'taxInfo':
          taxInformation.push(jsxElement)
          break
        default:
          break
      }
    }

    invoiceData.sort(sortElements)
    vendorInfo.sort(sortElements)
    invoiceAmount.sort(sortElements)
    addressInfo.sort(sortElements)
    start.sort(sortElements)
    taxInformation.sort(sortElements)
    customInformation.sort(sortElements)

    const invoiceDataJsx = invoiceData.map(jsxElement => jsxElement.jsx)
    const vendorInfoJsx = vendorInfo.map(jsxElement => jsxElement.jsx)
    const invoiceAmountJsx = invoiceAmount.map(jsxElement => jsxElement.jsx)
    const addressInfoJsx = addressInfo.map(jsxElement => jsxElement.jsx)
    const startJsx = start.map(jsxElement => jsxElement.jsx)
    const taxInfoJsx = taxInformation.map(jsxElement => jsxElement.jsx)
    const customInfoJsx = customInformation.map(jsxElement => jsxElement.jsx)

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={3}>
            {startJsx.length > 2 ? <Grid item xs={6} sm={6} md={6} lg={6}></Grid> : null}
            {startJsx.length !== 0 ? startJsx : null}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Key Invoice Data</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {invoiceDataJsx.length !== 0 ? invoiceDataJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Invoice Amount Information</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {invoiceAmountJsx.length !== 0 ? invoiceAmountJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Vendor Information</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {vendorInfoJsx.length !== 0 ? vendorInfoJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Address Information</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {addressInfoJsx.length !== 0 ? addressInfoJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {taxInfoJsx.length !== 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h5 className={classes.colorPrimary}>
                      <b>Tax Information</b>
                    </h5>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2} alignItems="center">
                      {taxInfoJsx}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            </Grid>
          </Grid>
        ) : null}
        {customInfoJsx.length !== 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h5 className={classes.colorPrimary}>
                      <b>Client Managed Fields</b>
                    </h5>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2} alignItems="center">
                      {customInfoJsx}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    )
  }

  const handleChange = event => {
    setFileVersion(old => ({ ...old, previous: Number(event.target.value) }))
    setTab(0)
  }

  const handleChangeCurrent = event => {
    setFileVersion(old => ({ ...old, current: Number(event.target.value) }))
    setTab(0)
  }

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }, lines, originalLines, side) => {
    const lineData = lines.find(x => x.id === rowProps.id)

    const columnStatus = lineData?.changes?.find(x => x.field === cellProps.id)

    if (side === 'left' && columnStatus?.status === 'new') {
      return
    }

    if (side === 'right' && columnStatus?.status === 'deleted') {
      return
    }

    const changedBy = originalLines[rowProps.id]?.updatedBy?.find(x => x.field === cellProps.id)

    if (!changedBy) {
      return
    }

    menuProps.autoDismiss = true
    menuProps.items = [
      {
        label: `Changed By: ${changedBy.userName}`
      },
      {
        label: `Email: ${changedBy.email}`
      },
      {
        label: `Date: ${formatDate({
          date: changedBy.changeDate,
          options: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h23'
          }
        })}`
      }
    ]
  }

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Breadcrumbs views={breadcrumbViews} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-around">
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.rootGridCurrentVersion}
              >
                <div style={{ padding: '2rem' }}>
                  <Grid
                    item
                    xs={12}
                    justifyContent="flex-start"
                    alignItems="center"
                    container
                    className={classes.rootGridHeaderPreviousVersion}
                  >
                    <Grid item xs={7}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl className={classes.formControl}>
                          <Select
                            native
                            value={fileVersion.previous}
                            onChange={handleChange}
                            inputProps={{
                              name: 'age',
                              id: 'age-native-simple'
                            }}
                          >
                            {selectVersionOptions}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <p style={{ fontSize: '20px', marginRight: '1rem' }}>
                          {`Changed By: ${versionSelected?.generateBy?.userName?.substring(0, 12) ??
                            ''}${versionSelected?.generateBy?.userName?.length > 12 ? '...' : ''}`}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AppBar position={'static'} color="default">
                      <Tabs
                        value={tab}
                        onChange={(event, newValue) => setTab(newValue)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Header Fields" {...a11yProps(0)} />
                        <Tab label="Tax & Other Charges" {...a11yProps(1)} />
                        <Tab label="Line item fields" {...a11yProps(2)} />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={tab}
                      onChangeIndex={handleChangeIndex}
                    >
                      <TabPanel value={tab} index={0} dir={theme.direction}>
                        {leftRightFields(transactionDiff.data?.previousVersion, 'left')}
                      </TabPanel>
                      <TabPanel value={tab} index={1} dir={theme.direction}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <ReactDataGrid
                              onReady={setGridRefCurrentCharges}
                              idProperty="id"
                              columns={columnsChargesPreviousVersion.columns}
                              dataSource={transactionDiff.data?.previousVersion?.charges ?? []}
                              style={gridStyle}
                              onCellClick={onCellClickCharges}
                              cellSelection={cellSelectedCharge}
                              onCellSelectionChange={setCellSelectedCharge}
                              cellStyle={{}}
                              enableKeyboardNavigation={false}
                              scrollProps={scrollProps}
                              activeIndexThrottle={300}
                              pagination
                              showZebraRows={false}
                              sortable={false}
                              renderRowContextMenu={(menuProps, menuParams) =>
                                renderRowContextMenu(
                                  menuProps,
                                  menuParams,
                                  transactionDiff.data?.resultDiff?.chargesAndTaxes?.result,
                                  transactionDiff.data?.previousVersion?.metadata?.chargesAndTaxes,
                                  'left'
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ReactDataGrid
                              onReady={setGridRefCurrentTax}
                              idProperty="id"
                              columns={columnsTaxesPreviousVersion.columns}
                              dataSource={transactionDiff.data?.previousVersion?.taxes ?? []}
                              style={gridStyle}
                              onCellClick={onCellClickTax}
                              cellSelection={cellSelectedTax}
                              onCellSelectionChange={setCellSelectedTax}
                              cellStyle={{}}
                              enableKeyboardNavigation={false}
                              scrollProps={scrollProps}
                              activeIndexThrottle={300}
                              pagination
                              showZebraRows={false}
                              sortable={false}
                              renderRowContextMenu={(menuProps, menuParams) =>
                                renderRowContextMenu(
                                  menuProps,
                                  menuParams,
                                  transactionDiff.data?.resultDiff?.chargesAndTaxes?.result,
                                  transactionDiff.data?.previousVersion?.metadata?.chargesAndTaxes,
                                  'left'
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value={tab} index={2} dir={theme.direction}>
                        <ReactDataGrid
                          onReady={setGridRefCurrentLineItems}
                          idProperty="id"
                          columns={columnsPreviousVersion.columns}
                          dataSource={lineItemsPrevious}
                          style={gridStyle}
                          onCellClick={onCellClickLineItems}
                          cellSelection={cellSelectedLineItems}
                          onCellSelectionChange={setCellSelectedLineItems}
                          cellStyle={{}}
                          enableKeyboardNavigation={false}
                          activeIndexThrottle={300}
                          scrollProps={scrollProps}
                          pagination
                          showZebraRows={false}
                          sortable={false}
                          renderRowContextMenu={(menuProps, menuParams) =>
                            renderRowContextMenu(
                              menuProps,
                              menuParams,
                              transactionDiff.data?.resultDiff?.lineItems.result,
                              transactionDiff.data?.previousVersion?.metadata?.lineItems,
                              'left'
                            )
                          }
                        />
                      </TabPanel>
                    </SwipeableViews>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div style={{ padding: '2rem' }}>
                  <Grid
                    item
                    xs={12}
                    justifyContent="flex-start"
                    alignItems="center"
                    container
                    className={classes.rootGridHeaderPreviousVersion}
                  >
                    <Grid item xs={7}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl className={classes.formControl}>
                          <Select
                            native
                            value={fileVersion.current}
                            onChange={handleChangeCurrent}
                            inputProps={{
                              name: 'age',
                              id: 'age-native-simple'
                            }}
                          >
                            {selectVersionOptionsB}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <p style={{ fontSize: '20px', marginRight: '1rem' }}>
                          {`Changed By: ${latestVersionInformation?.generateBy?.userName?.substring(
                            0,
                            12
                          ) ?? ''}${
                            latestVersionInformation?.generateBy?.userName?.length > 12 ? '...' : ''
                          }`}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AppBar position={'static'} color="default">
                      <Tabs
                        value={tab}
                        onChange={(event, newValue) => setTab(newValue)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Header Fields" {...a11yProps(0)} />
                        <Tab label="Tax & Other Charges" {...a11yProps(1)} />
                        <Tab label="Line item fields" {...a11yProps(2)} />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={tab}
                      onChangeIndex={handleChangeIndex}
                    >
                      <TabPanel value={tab} index={0} dir={theme.direction}>
                        {leftRightFields(transactionDiff.data?.latestVersion, 'right')}
                      </TabPanel>
                      <TabPanel value={tab} index={1} dir={theme.direction}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <ReactDataGrid
                              onReady={setGridRefPreviousCharges}
                              idProperty="id"
                              columns={columnsChargesLatestVersion.columns}
                              dataSource={transactionDiff.data?.latestVersion?.charges ?? []}
                              style={gridStyle}
                              onCellClick={onCellClickCharges}
                              cellSelection={cellSelectedCharge}
                              onCellSelectionChange={setCellSelectedCharge}
                              cellStyle={{}}
                              enableKeyboardNavigation={false}
                              scrollProps={scrollProps}
                              activeIndexThrottle={300}
                              pagination
                              showZebraRows={false}
                              renderRowContextMenu={(menuProps, menuParams) =>
                                renderRowContextMenu(
                                  menuProps,
                                  menuParams,
                                  transactionDiff.data?.resultDiff?.chargesAndTaxes?.result,
                                  transactionDiff.data?.latestVersion?.metadata?.chargesAndTaxes,
                                  'right'
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ReactDataGrid
                              onReady={setGridRefPreviousTax}
                              idProperty="id"
                              columns={columnsTaxesLatestVersion.columns}
                              dataSource={transactionDiff.data?.latestVersion?.taxes ?? []}
                              style={gridStyle}
                              onCellClick={onCellClickTax}
                              cellSelection={cellSelectedTax}
                              onCellSelectionChange={setCellSelectedTax}
                              cellStyle={{}}
                              enableKeyboardNavigation={false}
                              scrollProps={scrollProps}
                              activeIndexThrottle={300}
                              pagination
                              showZebraRows={false}
                              renderRowContextMenu={(menuProps, menuParams) =>
                                renderRowContextMenu(
                                  menuProps,
                                  menuParams,
                                  transactionDiff.data?.resultDiff?.chargesAndTaxes?.result,
                                  transactionDiff.data?.latestVersion?.metadata?.chargesAndTaxes,
                                  'right'
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value={tab} index={2} dir={theme.direction}>
                        <ReactDataGrid
                          onReady={setGridRefPreviousLineItems}
                          idProperty="id"
                          columns={columnsLatestVersion.columns}
                          dataSource={lineItemsLatest}
                          style={gridStyle}
                          onCellClick={onCellClickLineItems}
                          cellSelection={cellSelectedLineItems}
                          onCellSelectionChange={setCellSelectedLineItems}
                          cellStyle={{}}
                          enableKeyboardNavigation={false}
                          scrollProps={scrollProps}
                          activeIndexThrottle={300}
                          pagination
                          showZebraRows={false}
                          sortable={false}
                          renderRowContextMenu={(menuProps, menuParams) =>
                            renderRowContextMenu(
                              menuProps,
                              menuParams,
                              transactionDiff.data?.resultDiff?.lineItems.result,
                              transactionDiff.data?.latestVersion?.metadata?.lineItems,
                              'right'
                            )
                          }
                        />
                      </TabPanel>
                    </SwipeableViews>
                  </Grid>
                </div>
              </Grid>
            </>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
