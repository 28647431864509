import React, { useState } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import { CircularProgress, Switch } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

// @material-ui/icons
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'
import AssessmentIcon from '@material-ui/icons/Assessment'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import NavPills from 'components/NavPills/NavPills.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Button from 'components/CustomButtons/Button.js'
import ExpiredDiv from 'components/ExpiredDiv/ExpiredDiv'
import NotificationAlert from 'components/NotificationAlert/NotificationAlert'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// functions
import { checkExpiration } from 'utils/functions'

// Constants
import { updateHeightFunction } from 'store/AppState'
import TagsInput from 'react-tagsinput'
import { useGetAppList } from 'hooks/useApp'
import {
  useGetInvoiceNotificationConfig,
  useSaveInvoiceNotificationConfig
} from 'hooks/useInvoices'
import { shallowEqual, useSelector } from 'react-redux'
import CustomInput from 'components/CustomInput/CustomInput'
import { isObject } from 'utils/functions'

const useStyles = makeStyles(styles)

export default function InvoiceAINotifications(props) {
  const classes = useStyles()
  const [notificationsConfig, setNotificationsConfig] = useState(null)
  const [validationMessage, setValidationMessage] = useState('')
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [selectedAppId, setSelectedAppId] = useState(props.match.params.id)
  const appName = 'invoiceAI'
  const typeApp = 'Notifications'
  const enabledAppsInvoiceAI = useSelector(state => state.app.enabledApps[appName], shallowEqual)
  const expirationData = checkExpiration(enabledAppsInvoiceAI)

  let breadcrumbViews = [
    {
      name: 'Invoice AI',
      url: '/admin/invoiceAI/configurations',
      icon: AssessmentIcon
    },
    {
      name: 'Notifications',
      url: `/admin/invoiceAI/${typeApp}/${selectedAppId}`,
      icon: MailOutlineIcon
    }
  ]

  const onAppChanged = id => {
    setSelectedAppId(id)
    props.history.push(`/admin/invoiceAI/additionalConfigurations/${typeApp}/${id}`)
  }

  //#region react queries
  const { data: appList, isLoading: isLoadingAppList } = useGetAppList(appName)
  const {
    mutate: saveNotificationConfig,
    isLoading: isLoadingSave
  } = useSaveInvoiceNotificationConfig(selectedAppId, {
    onSuccess: () => {
      setValidationMessage('Configuration saved successfully')
      setShowSuccessAlert(true)
    },
    onError: () => {
      setValidationMessage('Something went wrong, please try again later.')
      setShowErrorAlert(true)
    }
  })
  const { isLoading: isLoadingNotificationConfig } = useGetInvoiceNotificationConfig(
    selectedAppId,
    {
      onSuccess: config => {
        setNotificationsConfig({ ...config })
      }
    }
  )
  //#endregion

  // #region generic renders based on notification configuration parameters
  const renderParameterSingleComponent = (type, value, onChangeFn, placeholder, hasError) => {
    switch (type) {
      case 'boolean':
        return (
          <>
            <br />
            <Switch
              checked={value}
              onChange={event => {
                onChangeFn(event.target.checked)
              }}
              classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: classes.switchIcon,
                track: classes.switchBar
              }}
            />
          </>
        )
      case 'tagListString':
        return (
          <TagsInput
            value={value}
            onChange={values => {
              onChangeFn(values)
            }}
            tagProps={{ className: 'react-tagsinput-tag info' }}
            inputProps={{
              className: 'react-tagsinput-input',
              placeholder: placeholder || 'Add tag'
            }}
          />
        )
      case 'text':
      default:
        return (
          <CustomInput
            error={hasError}
            labelText={
              <span>
                {placeholder || 'Write here'} <small>{hasError ? '(required)' : '*'} </small>
              </span>
            }
            id="custom-input"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: false,
              onChange: event => {
                let value = event.target.value
                if (!value || value === '') {
                  value = undefined
                }
                onChangeFn(value)
              },
              value: value
            }}
          />
        )
    }
  }

  const renderEntireParameterObject = (parameter, sectionKey, parameterKey) => {
    let renderParameter = true
    if (parameter.showIf) {
      renderParameter = !!notificationsConfig[sectionKey][parameter.showIf].value
    }

    return renderParameter ? (
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <FormLabel className={classes.inputLabelHorizontal}>{parameter.label}</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={6}>
          {renderParameterSingleComponent(
            parameter.type,
            notificationsConfig[sectionKey][parameterKey].value,
            newValue => {
              notificationsConfig[sectionKey][parameterKey].value = newValue
              setNotificationsConfig({ ...notificationsConfig })
            },
            parameter.placeholder,
            parameter.error
          )}
        </GridItem>
      </GridContainer>
    ) : null
  }

  function returnParameterTab(section) {
    const components = Object.keys(notificationsConfig[section]).filter(key =>
      isObject(notificationsConfig[section][key])
    )
    const subsections = [
      ...new Set(components.map(key => notificationsConfig[section][key].subsection))
    ]
    return notificationsConfig ? (
      <GridItem xs={12} sm={12} md={12}>
        {subsections.map(subsection => {
          return (
            <>
              {returnSubsectionTitle(subsection)}
              {components
                .filter(key => notificationsConfig[section][key].subsection === subsection)
                .map(key =>
                  renderEntireParameterObject(notificationsConfig[section][key], section, key)
                )}
            </>
          )
        })}
      </GridItem>
    ) : null
  }

  function returnSubsectionTitle(subsection) {
    let subsectionTile = null
    if (subsection) {
      const parts = subsection.split('|')
      const title = parts[0]
      const replacements = parts.length > 1 ? parts[1] : undefined
      subsectionTile = (
        <>
          <br /> <h4> {title} </h4>
          <small>{replacements ? ` available replacements: ${replacements}` : null}</small>
          <hr />
        </>
      )
    }
    return subsectionTile
  }

  function validateBeforeSave() {
    let isValid = true
    let section
    let parameter

    for (section in notificationsConfig) {
      for (parameter in notificationsConfig[section]) {
        if (isObject(notificationsConfig[section][parameter])) {
          const parameterIsValid = isValidParameter(section, parameter)
          if (!parameterIsValid) {
            isValid = false
            notificationsConfig[section][parameter].error = true
            setNotificationsConfig({ ...notificationsConfig })

            setValidationMessage(notificationsConfig[section][parameter].errorMessage)
            setShowErrorAlert(true)
          }
        }
      }
    }

    if (isValid) {
      saveNotificationConfig(notificationsConfig)
    }
  }

  function isValidParameter(sectionKey, parameterKey) {
    const parameter = notificationsConfig[sectionKey][parameterKey]
    let isValid = true

    /* required validation */
    if (parameter.required) {
      isValid = parameter.value === false || !!parameter.value

      if (!isValid && parameter.showIf) {
        const dependencyHasValue = !!notificationsConfig[sectionKey][parameter.showIf].value
        isValid = !dependencyHasValue
      }
    }

    /* format validation */
    if (isValid) {
      isValid = isValidFormat(parameter)
    }

    notificationsConfig[sectionKey][parameterKey].error = !isValid
    setNotificationsConfig({ ...notificationsConfig })
    return isValid
  }

  function isValidFormat(parameter) {
    let isValid = true
    if (parameter.regex) {
      const reg = new RegExp(parameter.regex)
      isValid = reg.test(parameter.value)
    }
    return isValid
  }

  // #endregion

  function returnTabsToDisplay() {
    const tabs = notificationsConfig
      ? Object.keys(notificationsConfig).map(tabSectionKey => {
          return {
            tabButton: notificationsConfig[tabSectionKey].tabName,
            tabContent: returnParameterTab(tabSectionKey)
          }
        })
      : []
    return (
      <GridItem xs={12} sm={12} md={12}>
        <div style={{ position: 'relative' }}>
          <NotificationAlert variant="filled" severity="warning" appInfo={expirationData} />
          <Card>
            <CardHeader>
              <h3 className={classes.formHeader}>Invoice AI - Notifications</h3>
            </CardHeader>
            <CardBody>
              <NavPills
                action={actions => {
                  if (actions) {
                    updateHeightFunction(actions.updateHeight)
                  }
                }}
                animateHeight={false}
                color="primary"
                tabs={tabs}
              />
              <GridContainer justify="flex-end">
                <Button
                  disabled={isLoadingSave || !expirationData.enabled}
                  variant="outlined"
                  color="primary"
                  onClick={() => validateBeforeSave()}
                >
                  {!isLoadingSave ? 'Save' : 'Loading...'}
                </Button>
              </GridContainer>
            </CardBody>
          </Card>
          <ExpiredDiv appInfo={expirationData} />
        </div>
      </GridItem>
    )
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={3}>
        <Card className={classes.appBar}>
          <CardHeader>Application List</CardHeader>
          <CardBody>
            <List>
              {appList
                ? appList.map((prop, key) => {
                    return (
                      <ListItem
                        className={classes.listItem}
                        button
                        selected={selectedAppId === prop.env}
                        key={key}
                        onClick={() => onAppChanged(prop.env)}
                      >
                        <ListItemAvatar>
                          {selectedAppId === prop.env ? (
                            <Avatar>
                              <TouchApp />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.clearAvatar}></Avatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText primary={prop.appName} secondary={`Type: ${prop.type}`} />
                      </ListItem>
                    )
                  })
                : null}
            </List>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={9}>
        <Breadcrumbs views={breadcrumbViews} />
        {isLoadingAppList || isLoadingNotificationConfig ? (
          <Card>
            <GridContainer justify="center">
              <div className={classes.circularProgress}>
                <CircularProgress color="inherit" />
              </div>
            </GridContainer>
          </Card>
        ) : (
          returnTabsToDisplay()
        )}
      </GridItem>
      <Snackbar
        place="bl"
        color="success"
        icon={AddAlert}
        message={validationMessage}
        open={showSuccessAlert}
        closeNotification={() => setShowSuccessAlert(false)}
        close
      />
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={validationMessage}
        open={showErrorAlert}
        closeNotification={() => setShowErrorAlert(false)}
        close
      />
    </GridContainer>
  )
}
