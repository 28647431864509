import { Badge, FormControlLabel, Grid, Switch, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
// Form Validation
import WarningIcon from '@material-ui/icons/Warning'
import { Controller } from 'react-hook-form'
import { generateJsonValidationV2 } from 'utils/validator'
import { errorTypes, getError } from './useHeader'

const ColorSwitch = withStyles({
  switchBase: {
    color: '#fafafa',
    '&$checked': {
      color: '#f15f22'
    },
    '&$checked + $track': {
      backgroundColor: '#f15f22'
    }
  },
  checked: {},
  track: {}
})(Switch)

const SwitchField = props => {
  const {
    control,
    index,
    readOnly,
    label,
    headerField,
    headerFieldsActions,
    onChange,
    headerData,
    requiredFields,
    getValues
  } = props

  const { deleteErrorHeader, classes } = headerFieldsActions

  const errorField = headerData.errors.find(x => x.field === headerField.field)

  const errorData = getError(errorField)

  const fieldRule = requiredFields.find(x => x.standardField === headerField.field)
  const rules = readOnly ? {} : generateJsonValidationV2(fieldRule, getValues)

  return (
    <Controller
      render={({ field, fieldState: { error } }) => {
        const isError = Boolean(error) || errorData?.criticalityType === errorTypes.critical
        const isVisible = Boolean(error) || Boolean(errorData)

        const errorMessage = errorData ? errorData?.message : error?.message

        return (
          <Grid container justifyContent="flex-start" alignItems="center" direction="row">
            <Grid item xs={12}>
              <Tooltip
                classes={{
                  arrow: isError ? classes.errorColor : classes.warningColor,
                  tooltip: isError ? classes.tooltipError : classes.tooltipWarning
                }}
                title={isVisible ? errorMessage : ''}
                arrow
                placement="right"
              >
                <Badge
                  classes={{
                    badge: isError ? classes.badgeError : classes.badgeWarning
                  }}
                  badgeContent={
                    isError ? '!' : <WarningIcon className={classes.warningColor}></WarningIcon>
                  }
                  invisible={isVisible ? false : true}
                >
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <ColorSwitch
                        {...field}
                        id={headerField.id}
                        error={!!error}
                        onChange={e => {
                          field.onChange(Boolean(e.target.checked))
                          deleteErrorHeader({ field: headerField.field })
                          if (typeof onChange === 'function') {
                            onChange(Boolean(e.target.checked))
                          }
                        }}
                        checked={Boolean(field.value)}
                        disabled={readOnly ?? false}
                      />
                    }
                    label={label ?? ''}
                  />
                </Badge>
              </Tooltip>
            </Grid>
          </Grid>
        )
      }}
      name={`headerFields.${index}.value`}
      control={control}
      rules={rules}
    />
  )
}

export default SwitchField
