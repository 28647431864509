import PropTypes from 'prop-types'
import React from 'react'
// @material-ui/core components

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'

import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'

import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'
import { ColumnsOrderLineItems } from 'utils/Constants'
import AutoCompleteAsyncDefaultValues from 'views/OcrInvoiceExceptions/AutoCompleteAsyncDefaultValues'
import BuyerAddSelectLineItem from 'views/OcrInvoiceExceptions/BuyerAddSelectLineItem'
import CommoditySelectLine from 'views/OcrInvoiceExceptions/CommoditySelect'
import SupplierAddressSelectLine from 'views/OcrInvoiceExceptions/SuplrAddrSelectLineItem'
import TextFieldLineItems from 'views/OcrInvoiceExceptions/TextFieldLineItems'

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const FIELD_LEVEL = 'lineItems'

export default function AdditionalDetails(props) {
  const classes = useStyles()

  const {
    isOpen,
    onClose,
    lineItemSelected,
    readOnly = false,
    onEditComplete,
    validationErrorsState,
    requiredFields,
    removeWarningLineItem,
    supplierId,
    companyCode,
    process,
    appId
  } = props

  const customFields = Object.values(requiredFields ?? {}).filter(
    x => x.level === FIELD_LEVEL && x.isCustomField && x.visible && x.typeField === 'string'
  )

  const customFieldsSelects = Object.values(requiredFields ?? {}).filter(
    x => x.level === FIELD_LEVEL && x.visible && x.typeField.startsWith('ocrFieldValues#')
  )

  const supplierAddressValue = lineItemSelected
    ? {
        supplierAddressFullAddress:
          lineItemSelected[ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.type],
        supplierAddressId: lineItemSelected[ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.id]
      }
    : { supplierAddressFullAddress: '', supplierAddressId: '' }

  const buyerAddressValue = lineItemSelected
    ? {
        fullAddress: lineItemSelected[ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.type],
        companyAddressId: lineItemSelected[ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.id]
      }
    : { fullAddress: '', companyAddressId: '' }

  const commodityId = lineItemSelected
    ? lineItemSelected[ColumnsOrderLineItems.COMMODITY_ID.type]
    : ''

  function onChangeSupplierAddress(value) {
    if (!lineItemSelected) {
      return
    }

    onEditComplete({
      value: value.supplierAddressFullAddress,
      columnId: ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.type,
      data: { key: lineItemSelected.key }
    })

    onEditComplete({
      value: value.supplierAddressId,
      columnId: ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.id,
      data: { key: lineItemSelected.key }
    })
  }

  function onChangeBuyerAddress(value) {
    if (!lineItemSelected) {
      return
    }
    onEditComplete({
      value: value.fullAddress,
      columnId: ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.type,
      data: { key: lineItemSelected.key }
    })

    onEditComplete({
      value: value.companyAddressId,
      columnId: ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.id,
      data: { key: lineItemSelected.key }
    })
  }

  function onChangeInputText(value, columnName) {
    if (!lineItemSelected) {
      return
    }

    onEditComplete({
      value: value ?? '',
      columnId: columnName,
      data: { key: lineItemSelected.key }
    })
  }

  function onChangeCommodity(value) {
    if (!lineItemSelected) {
      return
    }

    onEditComplete({
      value: value?.id ?? '',
      columnId: ColumnsOrderLineItems.COMMODITY_ID.type,
      data: { key: lineItemSelected.key }
    })
  }

  const isVisibleSupplierShipAddress =
    requiredFields[`${FIELD_LEVEL}#${ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.type}`]
      ?.visible
  const isVisibleBuyerShipAddress =
    requiredFields[`${FIELD_LEVEL}#${ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.type}`]?.visible
  const isVisibleCommodity =
    requiredFields[`${FIELD_LEVEL}#${ColumnsOrderLineItems.COMMODITY_ID.type}`]?.visible

  const customFieldsInputs = customFields.map(x => {
    return (
      <Grid item xs={6} key={x.standardField}>
        <TextFieldLineItems
          field={x.standardField}
          value={lineItemSelected ? lineItemSelected[x.standardField] : ''}
          label={x.label}
          validationErrorsState={validationErrorsState ?? {}}
          handleChange={value => onChangeInputText(value, x.standardField)}
          readOnly={readOnly}
          lineId={lineItemSelected?.key ?? ''}
          removeWarningLineItem={removeWarningLineItem}
        />
      </Grid>
    )
  })

  const customFieldsSelectsJsx = customFieldsSelects.map(x => {
    return (
      <Grid item xs={6} key={x.standardField}>
        <AutoCompleteAsyncDefaultValues
          standardField={x.standardField}
          label={x.label}
          validationErrorsState={validationErrorsState ?? {}}
          handleChange={value => onChangeInputText(value, x.standardField)}
          readOnly={readOnly}
          removeWarningLineItem={removeWarningLineItem}
          lineItemSelected={lineItemSelected}
          onChange={newValue => onChangeInputText(newValue?.value, x.standardField)}
          process={process}
        />
      </Grid>
    )
  })

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isOpen}
        TransitionComponent={Transition}
        onClose={onClose}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            Additional Details
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container justifyContent="center" spacing={2}>
            {isVisibleSupplierShipAddress ? (
              <Grid item xs={6}>
                <SupplierAddressSelectLine
                  field={ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.type}
                  value={supplierAddressValue ?? ''}
                  label={ColumnsOrderLineItems.SUPPLIER_SHIP_FROM_ADDRESS.label}
                  validationErrorsState={validationErrorsState ?? {}}
                  handleChange={onChangeSupplierAddress}
                  readOnly={readOnly}
                  supplierId={supplierId?.value?.supplierId}
                  lineId={lineItemSelected?.key ?? ''}
                  removeWarningLineItem={removeWarningLineItem}
                  appId={appId}
                />
              </Grid>
            ) : null}

            {isVisibleBuyerShipAddress ? (
              <Grid item xs={6}>
                <BuyerAddSelectLineItem
                  field={ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.type}
                  value={buyerAddressValue ?? ''}
                  label={ColumnsOrderLineItems.BUYER_SHIP_TO_ADDRESS.label}
                  validationErrorsState={validationErrorsState ?? {}}
                  handleChange={onChangeBuyerAddress}
                  readOnly={readOnly}
                  companyCode={companyCode?.value}
                  lineId={lineItemSelected?.key ?? ''}
                  removeWarningLineItem={removeWarningLineItem}
                  appId={appId}
                />
              </Grid>
            ) : null}

            {isVisibleCommodity ? (
              <Grid item xs={6}>
                <CommoditySelectLine
                  value={commodityId ?? ''}
                  validationErrorsState={validationErrorsState ?? {}}
                  handleChange={onChangeCommodity}
                  readOnly={readOnly}
                  lineId={lineItemSelected?.key ?? ''}
                  removeWarningLineItem={removeWarningLineItem}
                  appId={appId}
                />
              </Grid>
            ) : null}

            {customFieldsInputs.length > 0 ? customFieldsInputs : null}
            {customFieldsSelectsJsx.length > 0 ? customFieldsSelectsJsx : null}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={onClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

AdditionalDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onEditComplete: PropTypes.func,
  readOnly: PropTypes.bool,
  lineItemSelected: PropTypes.object,
  validationErrorsState: PropTypes.object,
  requiredFields: PropTypes.object.isRequired
}
