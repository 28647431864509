import PropTypes from 'prop-types'
import React from 'react'
// @material-ui/core components

import AdditionalDetails from 'components/Invoice/AdditionalDetails'

export default function AdditionalDetailsRO(props) {
  return <AdditionalDetails {...props} />
}

AdditionalDetailsRO.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onEditComplete: PropTypes.func,
  readOnly: PropTypes.bool,
  lineItemSelected: PropTypes.object,
  validationErrorsState: PropTypes.object,
  requiredFields: PropTypes.object.isRequired
}
