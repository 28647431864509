import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'

//services
import { getAppConfigurationsByApp } from 'services/apiApps'

//Views
import IntelligenceLogsView from './IntelligenceLogsView'

import { updateHeightFunction } from 'store/AppState'

let _isMounted = false
let appId
let typeApp
let idLoad

async function getAppList (props) {
  let response = await getAppConfigurationsByApp('intelligence')
  if (response.success) {
    if (_isMounted) {
      props.setAppList(response.data)
    }
  }
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      updateHeight: state.app.updateHeight
    }),
    { updateHeightFunction }
  ),
  withState('appList', 'setAppList', []),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('selectedApp', 'setSelectedApp', null),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('typeApp', 'setTypeApp', null),
  withState('idLoad', 'setIdLoad', null),
  withHandlers({
    onAppChanged: props => id => {
      props.history.push(`/admin/intelligence/${props.typeApp}/${id}`)
    }
  }),
  lifecycle({
    componentDidMount () {
      _isMounted = true
      appId = this.props.match.params.id
      typeApp = this.props.match.params.type
      idLoad = this.props.match.params.idLoad
      this.props.setPageIsLoading(true)
      this.props.setTypeApp(typeApp)
      this.props.setIdLoad(idLoad)
      this.props.setSelectedAppId(appId)
      getAppList(this.props)
    }
  })
)(IntelligenceLogsView)
