import React from 'react'
import PropTypes from 'prop-types'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// style
import style from '../../../assets/jss/material-dashboard-pro-react/views/Apps/widgetFormStyle.js'
import DnBMapping from './DnBMapping.js'

class DnBMappingReact extends React.Component {
  constructor (props) {
    super(props)
    this.state = { state: '' }
  }

  componentDidMount () {}

  sendState () {
    return this.state
  }

  isValidated () {
    return true
  }

  render () {
    return <DnBMapping {...this.props.initialState} />
  }
}

DnBMappingReact.propTypes = {
  classes: PropTypes.object
}

export default withStyles(style)(DnBMappingReact)
