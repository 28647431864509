import { Box, CircularProgress, Typography } from '@material-ui/core'
import { useLocalStorageState } from 'hooks/useLocalStorage'
import { useGetTenantConfig } from 'hooks/useTenantConfig'
import { useGetUserFutureFlags } from 'hooks/useUser'
import React, { useEffect } from 'react'

function Container({ children }) {
  return (
    <Box
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {children}
    </Box>
  )
}

export function getPathNewUi(path) {
  if (window.self !== window.top) {
    console.info('This page is rendered in an iframe')
    return null
  } else {
    return `${window?.top?.location.origin}${path}`
  }
}

function RedirectUI({ children, path, omitValidation }) {
  const [userInfo] = useLocalStorageState('userInfo', {})
  const userData = useGetUserFutureFlags({ enableQuery: !omitValidation, userId: userInfo?.sub })
  const configTenant = useGetTenantConfig({ enabled: !omitValidation })

  let isNewUiActivated = true
  let isSettingEnabled = true
  let showAlwaysNewUI = false

  if (!omitValidation) {
    isNewUiActivated = userData.data?.featureFlags?.newInvoiceAI?.status ?? false
    isSettingEnabled = configTenant.data?.featureFlags?.newInvoiceAI?.status ?? false
    showAlwaysNewUI = configTenant.data?.featureFlags?.newInvoiceAI?.showAlways
  }

  useEffect(() => {
    if (!configTenant.isLoading && !configTenant.isError && showAlwaysNewUI) {
      localStorage.setItem('origin', 'Old_webApp')
      window.location.href = path
      return
    }
    if (isNewUiActivated && !userData.isLoading && !userData.isError && isSettingEnabled && path) {
      // Redirect to an external URL after the component mounts
      localStorage.setItem('origin', 'Old_webApp')
      window.location.href = path
    }
  }, [
    isNewUiActivated,
    userData.isLoading,
    userData.isError,
    path,
    isSettingEnabled,
    configTenant.isLoading,
    configTenant.isError,
    showAlwaysNewUI
  ])

  if (userData.isLoading || configTenant.isLoading) {
    return (
      <div
        style={{
          position: 'absolute',
          zIndex: '1300',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f8f8f8ad'
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    )
  }

  if (userData.isError || configTenant.isError) {
    return (
      <Container>
        <Typography variant="h3">Something went wrong...</Typography>;
      </Container>
    )
  }

  if (!showAlwaysNewUI && (!isNewUiActivated || !isSettingEnabled || !path)) {
    return <>{children}</>
  }

  return (
    <Container>
      <Typography variant="h3">Redirecting...</Typography>;
    </Container>
  )
}

export default RedirectUI
