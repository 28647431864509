import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined'
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle'

import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import { formatDate } from 'utils/functions'

const useStyles = makeStyles(theme => ({
  inputChanged: {
    color: '#1864ab !important'
  },
  borderColorInputChanged: {
    borderColor: '#1864ab !important'
  },
  inputDeleted: {
    color: '#dc2626 !important'
  },
  borderColorInputDeleted: {
    borderColor: '#dc2626 !important'
  },
  inputCreated: {
    color: '#16a34a !important'
  },
  borderColorInputCreated: {
    borderColor: '#16a34a !important'
  },
  defaultColor: {
    color: '#1d4ed8'
  },
  tooltipChanged: {
    backgroundColor: 'white',
    color: '#1864ab',
    maxWidth: 220,
    border: '1px solid #1864ab'
  },
  tooltipDeleted: {
    backgroundColor: 'white',
    color: '#dc2626',
    maxWidth: 220,
    border: '1px solid #dc2626'
  },
  tooltipCreated: {
    backgroundColor: 'white',
    color: '#16a34a',
    maxWidth: 220,
    border: '1px solid #16a34a'
  },
  tooltipDefault: {
    backgroundColor: 'white',
    color: '#1864ab',
    maxWidth: 220,
    border: '1px solid #1864ab'
  },
  resize: {
    fontWeight: 'bold'
  }
}))

export const HeaderField = props => {
  const { summaryField, multiline, label, value, onClick, inputSelected, fieldStatus, side } = props

  const classes = useStyles()

  const { modifiedBy } = summaryField

  const isChanged = fieldStatus === 'changed' || fieldStatus === 'new' || fieldStatus === 'deleted'

  function getColor(fieldStatus) {
    const style = { color: '', borderColor: '', tooltipColor: '' }
    switch (fieldStatus) {
      case 'changed':
        if (side === 'left') {
          style.color = classes.inputDeleted
          style.borderColor = classes.borderColorInputDeleted
          style.tooltipColor = classes.tooltipDeleted
          break
        }
        style.color = classes.inputCreated
        style.borderColor = classes.borderColorInputCreated
        style.tooltipColor = classes.tooltipCreated
        break
      case 'new':
        style.color = classes.inputCreated
        style.borderColor = classes.borderColorInputCreated
        style.tooltipColor = classes.tooltipCreated
        break
      case 'deleted':
        style.color = classes.inputDeleted
        style.borderColor = classes.borderColorInputDeleted
        style.tooltipColor = classes.tooltipDeleted
        break
      default:
        style.color = classes.inputChanged
        style.borderColor = classes.borderColorInputChanged
        style.tooltipColor = classes.tooltipDefault
        break
    }
    return style
  }

  const isSelectedInput =
    (isChanged && inputSelected?.id === summaryField.id) ||
    (isChanged && inputSelected?.type === summaryField.type)

  const tooltipComponent = modifiedBy ? (
    <>
      Changed By: <em>{modifiedBy.userName}</em>
      <br /> Email: <em>{modifiedBy.email}</em>
      <br /> Date:
      <em>
        {formatDate({
          date: modifiedBy.changeDate,
          options: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h23'
          }
        })}
      </em>
    </>
  ) : (
    ''
  )

  return (
    <Grid container justifyContent="center">
      <Grid item xs={isChanged ? 11 : 12}>
        <Tooltip
          classes={{
            arrow: getColor(fieldStatus).color,
            tooltip: getColor(fieldStatus).tooltipColor
          }}
          title={tooltipComponent}
          arrow
          placement="left"
        >
          <TextField
            style={{ width: '100%' }}
            id={summaryField.id}
            label={label ?? ''}
            variant="outlined"
            size="small"
            InputProps={{
              readOnly: true,
              classes: {
                notchedOutline: isChanged ? getColor(fieldStatus).borderColor : '',
                input: isSelectedInput && isChanged ? classes.resize : ''
              }
            }}
            InputLabelProps={{
              classes: {
                root: isChanged ? getColor(fieldStatus).color : '',
                focused: isChanged ? getColor(fieldStatus).color : ''
              }
            }}
            onClick={() => onClick(summaryField)}
            fullWidth
            defaultValue={value ?? ''}
            multiline={multiline}
            rows="3"
          />
        </Tooltip>
      </Grid>
      {fieldStatus === 'changed' ? (
        <Grid
          item
          xs={1}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {isSelectedInput ? (
            <SwapHorizontalCircleIcon className={getColor(fieldStatus).color} />
          ) : (
            <SwapHorizontalCircleOutlinedIcon className={getColor(fieldStatus).color} />
          )}
        </Grid>
      ) : null}
      {fieldStatus === 'new' ? (
        <Grid
          item
          xs={1}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <AddCircleOutlineOutlinedIcon className={getColor(fieldStatus).color} />
        </Grid>
      ) : null}
      {fieldStatus === 'deleted' ? (
        <Grid
          item
          xs={1}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <RemoveCircleOutlineOutlinedIcon className={getColor(fieldStatus).color} />
        </Grid>
      ) : null}
    </Grid>
  )
}
