import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import WarningIcon from '@material-ui/icons/Warning'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

import Slide from '@material-ui/core/Slide'

import TextField from '@material-ui/core/TextField'

import React, { useEffect, useMemo, useState } from 'react'

import {
  useGetAccountTypesByTenant,
  useGetAccounts,
  useGetSegmentsByAccountType
} from '../../hooks/useMasterData'

import { useDebounce } from 'hooks/useDebounce'
import { v4 as uuidv4 } from 'uuid'
import { getAccountByIdAndCode } from '../../services/apiMasterData'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const gridStyle = { minHeight: 550, maxHeight: 700 }
const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
  autoHide: true,
  alwaysShowTrack: true,
  scrollThumbStyle: {
    background: '#1E408A'
  }
})

/**
 * This constant is for clients that don't have accountId Validation in the front-end like ezcorp
 */
const accountIdNotFound = 'relishTBD'

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: { value: string, description: string }) => option.value + option.description
})

const filterOptionsAccounts = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: { key: string, value: string }) => option.key + option.value
})

export default function ModalAccountType(props: Props) {
  const {
    isOpen,
    onClose,
    defaultDataSource,
    onComplete,
    setCreateError,
    isAccountValidationEnabled = false,
    appId,
    isReadOnly,
    serviceType
  } = props

  const [accountType, setAccountType] = useState(null)
  const [account, setAccount] = useState(null)

  const [dataSource, setDataSource] = useState([])

  const [inputValue, setInputValue] = React.useState('')

  const [debouncedValue] = useDebounce(inputValue, 500)
  const [, setSegmentValidation] = useState(false)
  const isReadOnlyComponent = isReadOnly ?? false
  const defaultDataSourceMemo = useMemo(() => defaultDataSource, [defaultDataSource])

  const [loadingAccount, setLoadingAccount] = useState(false)
  const accountTypes = useGetAccountTypesByTenant({ appId })

  const accounts = useGetAccounts({ appId, accountTypeId: accountType?.key, name: debouncedValue })
  const segments = useGetSegmentsByAccountType({
    accountTypeId: accountType?.key,
    params: { appId }
  })

  const segmentsOptions = segments.data ?? []

  const accountTypesOptions = accountTypes.data ?? []
  const accountSelectedDefault =
    defaultDataSourceMemo.length === 1
      ? accountTypesOptions.find(x => x.id === defaultDataSourceMemo[0].accountTypeId)
      : null

  const accountSelected = accountTypesOptions.find(x => x.id === accountType?.key)

  useEffect(() => {
    if (
      isOpen &&
      defaultDataSourceMemo.length === 1 &&
      accountSelectedDefault?.id &&
      accountSelectedDefault.concatChar
    ) {
      const getAccount = async () => {
        const segmentsCopy = { ...defaultDataSourceMemo[0].segments }
        try {
          setLoadingAccount(true)
          const code = Object.values(segmentsCopy).join(accountSelectedDefault.concatChar)
          const account = await getAccountByIdAndCode({
            accountType: accountSelectedDefault?.id ?? '',
            code,
            params: { appId }
          })

          if (account.length === 1) {
            setAccount({ key: account[0].id, value: account[0].name })
            setInputValue(account[0].name)
          }
        } catch (error) {}
        setLoadingAccount(false)
      }

      getAccount()
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    accountSelectedDefault?.id,
    // eslint-disable-next-line
    accountSelectedDefault?.concatChar,
    appId,
    defaultDataSourceMemo,
    isOpen
  ])

  useEffect(() => {
    if (!accountTypes.data) return
    const accountTypesOptions = accountTypes.data ?? []
    const accountSelected = accountTypesOptions.find(x => x.id === accountType?.key)

    if (!accountSelected) return

    const copyLines: { [key: string]: string }[] = []
    const newElement: { [key: string]: string } = {}
    // eslint-disable-next-line
    for (const column of accountSelected?.accountSegments ?? []) {
      newElement[column.segmentId] = ''
    }
    newElement['id'] = uuidv4()
    copyLines.push(newElement)

    setDataSource(old => {
      if (old.length > 0) {
        return old
      }
      return copyLines
    })
    // eslint-disable-next-line
  }, [accountTypes.data, accountType?.key])

  useEffect(() => {
    if (isOpen) {
      if (defaultDataSourceMemo.length > 0) {
        const accountTypeTemp = defaultDataSourceMemo[0]

        const accountTypeValue = accountTypes.data?.find(
          x => x.id === accountTypeTemp.accountTypeId
        )

        setAccountType({ key: accountTypeTemp.accountTypeId, value: accountTypeValue?.name ?? '' })
      } else if (accountTypes.data && accountTypes.data.length === 1) {
        setAccountType({ key: accountTypes.data[0].id, value: accountTypes.data[0].name })
      }

      const data = defaultDataSourceMemo.map(x => ({ id: x.id, ...x.segments }))
      if (data.length > 0) {
        setDataSource(data)
      }
    }
    // eslint-disable-next-line
  }, [isOpen, defaultDataSourceMemo, accountTypes.data])

  const onCreateError = message => {
    setCreateError({
      isOpen: true,
      message: message ?? 'Something went wrong, try again later',
      color: 'danger'
    })
  }

  const onEditComplete = React.useCallback(
    ({ value, columnId, data }) => {
      const copyRows = [...dataSource]
      const lineItemIndex = copyRows.findIndex(line => line.id === data.id)

      if (lineItemIndex < 0) {
        return
      }
      copyRows[lineItemIndex][columnId] = value
      setDataSource(copyRows)
    },
    [dataSource, setDataSource]
  )

  const columns =
    accountType && accountSelected?.accountSegments
      ? accountSelected.accountSegments.map(x => ({
          name: x.segmentId,
          header: x.segmentName,
          defaultFlex: 1,
          minWidth: 300,
          editable: !isReadOnlyComponent,
          textAlign: 'center',
          render: ({ value }) => (value ? value : 'Segment Value'),
          renderEditor: editorProps => {
            const segment = segmentsOptions.find(segment => segment.segmentId === x.segmentId)

            let selectOptions: { value: string, description: string }[] = []
            if (segment) {
              selectOptions = segment.segmentValues
            }

            const autoCompleteValue = selectOptions.find(x => x.value === editorProps.value) ?? {
              value: editorProps.value ?? '',
              description: ''
            }

            const isError = x.required && !Boolean(editorProps.value)

            return (
              <Autocomplete
                key={x.segmentId}
                id="country-select-demo"
                disabled={isReadOnlyComponent}
                style={{ width: '100%', height: '100%' }}
                options={selectOptions}
                autoHighlight
                fullWidth
                value={autoCompleteValue}
                onChange={(event, newValue) => {
                  editorProps.onChange(newValue?.value ?? '')
                }}
                onBlur={editorProps.onComplete}
                isOptionEqualToValue={(option, value) => option?.value === value.value}
                filterOptions={filterOptions}
                getOptionLabel={option =>
                  option.value && option.description
                    ? `(${option.value}) ${option.description}`
                    : ''
                }
                renderOption={option => (
                  <React.Fragment> {`(${option.value}) ${option.description} `}</React.Fragment>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    error={isError}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password' // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )
          }
        }))
      : []

  function handleChangeAccountType(value) {
    setAccountType(value)
    setDataSource([])
    setAccount({ key: '', value: '' })
  }

  const handleOnComplete = async () => {
    if (!accountSelected || dataSource.length === 0) {
      handleClose()
      onCreateError('Invalid Segments')

      return
    }

    let isError = false
    // eslint-disable-next-line
    for (const segments of dataSource) {
      isError =
        accountSelected?.accountSegments?.some(x => x.required && !segments[x.segmentId]) ?? false
      if (isError) {
        break
      }
    }

    if (isError) {
      onCreateError('Invalid Segments')
      return
    }

    setSegmentValidation(true)
    const dataUpdated = await validateAccount(dataSource)

    const isValidFunction = typeof onComplete === 'function'

    if (!isAccountValidationEnabled) {
      if (isValidFunction) {
        onComplete(dataUpdated)
      }
      handleClose()
      setSegmentValidation(false)
      return
    }

    const isValidSegments = dataUpdated.every(x => x.accountId !== accountIdNotFound)

    if (isValidSegments) {
      if (isValidFunction) {
        onComplete(dataUpdated)
      }
      handleClose()
    } else {
      onCreateError('Invalid Segments')
      setSegmentValidation(false)
    }
  }

  const handleClose = () => {
    onClose()
    setSegmentValidation(false)
    setAccountType(null)
    setAccount(null)
    setDataSource([])
    setInputValue('')
  }

  async function validateAccount(dataSource = []) {
    const concatChar = accountSelected?.concatChar

    const newDataSource = []
    // eslint-disable-next-line
    for (const segment of dataSource) {
      const segmentsCopy = { ...segment }
      delete segmentsCopy.id

      let account = []
      let accountId = ''
      let name = ''
      try {
        const code = Object.values(segmentsCopy).join(concatChar)
        account = await getAccountByIdAndCode({
          accountType: accountSelected?.id ?? '',
          code,
          params: { appId }
        })
      } catch (error) {}

      if (account && account.length === 1) {
        accountId = account[0].id
        name = account[0].name
      } else {
        accountId = accountIdNotFound
      }

      newDataSource.push({
        id: segment.id,
        segments: segmentsCopy,
        accountId: accountId,
        accountTypeId: accountType?.key ?? '',
        name
      })
    }

    return newDataSource
  }

  function handleChangeAccount(value) {
    const account = accounts.data?.accounts?.find(x => x.accountId === value?.key)

    const copyLines: { [key: string]: string }[] = []
    const newElement: { [key: string]: string } = {}

    const accountSelected = accountTypesOptions.find(x => x.id === accountType?.key)
    const segments = accountSelected?.accountSegments ?? []
    // eslint-disable-next-line
    for (const [index, segment] of segments.entries()) {
      const accountSegments = account?.segmentValues ? account.segmentValues.split(',') : []
      const segmentValue = index <= accountSegments.length - 1 ? accountSegments[index] : ''
      newElement[segment.segmentId] = segmentValue
    }
    newElement['id'] = uuidv4()
    copyLines.push(newElement)

    setDataSource(copyLines)
    setAccount(account ? { key: account.id, value: account.name } : { key: '', value: '' })
  }

  async function getAccount() {
    const dataUpdated = await validateAccount(dataSource)

    const isValidSegments = dataUpdated.every(x => x.accountId !== accountIdNotFound)

    if (isValidSegments && dataUpdated.length > 0) {
      setAccount({
        key: dataUpdated[0].accountId ?? '',
        value: dataUpdated[0].name ?? ''
      })
    } else {
      setAccount({
        key: '',
        value: ''
      })

      if (isAccountValidationEnabled) {
        onCreateError('Invalid Segments')
        setSegmentValidation(false)
      }
    }
  }

  const optionsAccountTypes = accountTypesOptions?.map(x => ({ key: x.id, value: x.name }))
  let optionsAccounts =
    accounts.data?.accounts?.map(x => ({ key: x.accountId, value: x.name })) ?? []
  if (optionsAccounts.length === 0 && account?.key && account?.value) {
    optionsAccounts = [account]
  }

  const queryAccountLoading = accounts.status === 'loading' && accounts.fetchStatus === 'fetching'

  let content = (
    <>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Autocomplete
              value={accountType}
              onChange={(_event, newValue): void => {
                handleChangeAccountType(newValue)
              }}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              options={optionsAccountTypes}
              style={{ width: 300 }}
              loading={accountTypes.isLoading}
              readOnly={isReadOnlyComponent}
              filterOptions={filterOptionsAccounts}
              getOptionLabel={option =>
                option.key && option.value ? `(${option.key}) ${option.value}` : ''
              }
              renderOption={option => (
                <React.Fragment>{`(${option.key}) ${option.value}`}</React.Fragment>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Account Type"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {accountTypes.isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
            {serviceType === 'coupa' ? (
              <>
                <Autocomplete
                  value={account}
                  onChange={(_event, newValue): void => {
                    handleChangeAccount(newValue)
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                  }}
                  isOptionEqualToValue={(option, value) => option.key === value.key}
                  style={{ width: 300 }}
                  options={optionsAccounts}
                  loading={queryAccountLoading || loadingAccount}
                  readOnly={isReadOnlyComponent}
                  filterOptions={x => x}
                  getOptionLabel={option => option.value}
                  renderOption={option => (
                    <React.Fragment>{`(${option.key}) ${option.value}`}</React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Account"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {queryAccountLoading || loadingAccount ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
                <Button
                  onClick={() => getAccount()}
                  color="primary"
                  variant="outlined"
                  size="medium"
                  disabled={isReadOnlyComponent}
                >
                  Get Account
                </Button>
              </>
            ) : null}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12}>
            <ReactDataGrid
              idProperty="id"
              dataSource={dataSource}
              scrollProps={scrollProps}
              sortable={false}
              style={gridStyle}
              minRowHeight={80}
              rowHeight={null}
              columns={columns ?? []}
              showColumnMenuTool={false}
              onEditComplete={onEditComplete}
              editable={true}
            />
            {/*  <Box
              sx={{
                '& .super-app-theme--header': {
                  backgroundColor: '#F4F6F8'
                },
                height: 200
              }}
            >
              <DataGridPro
                //autoHeight
                rows={dataSource}
                columns={columns}
                loading={
                  accountTypes.isLoading ||
                  (segments.status === 'loading' && segments.fetchStatus === 'fetching')
                }
                editMode="row"
                getRowHeight={() => 'auto'}
                disableColumnMenu
                disableRowSelectionOnClick
              />
            </Box> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  )

  if (accountTypes.isError || segments.isError) {
    content = (
      <>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <div
              style={{
                height: '30vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <h2>Unable to get accounting information</h2>
              <WarningIcon fontSize="large" color="error" />
              <h4>Try again later!</h4>
            </div>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        //hideBackdrop
        //disableEnforceFocus
        //style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        //PaperComponent={PaperComponent}
        //keepMounted
        //aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Account Type
        </DialogTitle>

        <DialogContent id="notice-modal-slide-description" dividers>
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" size="medium">
            Cancel
          </Button>
          <Button
            onClick={() => handleOnComplete()}
            color="primary"
            variant="contained"
            size="medium"
            disabled={accountTypes.isError || segments.isError || isReadOnlyComponent}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
