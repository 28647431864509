import React, { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import { CircularProgress } from '@material-ui/core'

// @material-ui/icons

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from '@material-ui/core/Button'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

// Constants
import TeamsSync from 'views/Connect/TeamsSync.js'
import { getUserTenant } from 'utils/functions'
import jwtDecode from 'jwt-decode'
import { useGetAppConfigurationById } from 'hooks/useApp'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import { Select, MenuItem } from '@material-ui/core'
import {
  useGetTenantProcess,
  useGetEmailOriginConfigurationById,
  useCreateEmailOriginConfiguration,
  useUpdateEmailOriginConfiguration
} from 'hooks/useInvoiceOrigin'

const useStyles = makeStyles(styles)
const useStyles2 = makeStyles(styles2)

export default function EmailConfigurationForm(props) {

  const { setUserInfo } = props
  const classes = useStyles()
  const classes2 = useStyles2()
  const [processForEmail, setProcessForEmail] = useState([])
  const [defaultProcess, setDefaultProcess] = useState('')
  const [serviceProvider, setServiceProvider] = useState('microsoftAccount')
  const [alternativeEmail, setAlternativeEmail] = useState({ email: '', error: false })
  const [mailboxType, setMailboxType] = useState('')
  const app = useGetAppConfigurationById(props.appId)
  const processByTenantApp = useGetTenantProcess(props.appId)
  const emailConfigById = useGetEmailOriginConfigurationById(props.id, props.appId)
  const createEmailConfig = useCreateEmailOriginConfiguration()
  const updateEmailConfig = useUpdateEmailOriginConfiguration()
  const queryClient = useQueryClient()
  const [microsoftGraphConfig, setMicrosoftGraph] = useState({
    code: '',
    idToken: '',
    redirectUrl: '',
    email: '',
    accountId: undefined
  })

  const serviceProviderOptions = [{ id: 'microsoftAccount', name: 'Microsoft' }]
  const mailboxTypeOptions = [
    { id: 'mailbox', name: 'Mailbox' },
    { id: 'sharedEmail', name: 'Shared Email' }
  ]
  let invoiceProcess = []
  let defaultProcessOptions = []
  if (!processByTenantApp.isLoading && processByTenantApp.data?.processConfig) {
    invoiceProcess = processByTenantApp.data?.processConfig
  }
  if (processForEmail.length > 0 && processByTenantApp.data?.processConfig) {
    // eslint-disable-next-line
    for (const process of processForEmail) {
      const processOption = invoiceProcess.filter(x => x.id === process)
      if (processOption) {
        defaultProcessOptions.push(...processOption)
      }
    }
  }

  useEffect(() => {
    if (emailConfigById.data) {
      setProcessForEmail(emailConfigById.data.process)
      setDefaultProcess(emailConfigById.data.defaultProcess)
      setUserInfo({
        createdBy: emailConfigById.data.createdBy,
        updatedBy: emailConfigById.data.updatedBy
      })
      const microsoftAccount = {
        ...emailConfigById.data.emailProvider?.microsoftGraphApi,
        email: emailConfigById.data.email
      }
      setMicrosoftGraph(microsoftAccount)
      setMailboxType(emailConfigById.data.mailboxType ?? 'mailbox')
      setAlternativeEmail({ email: emailConfigById.data.alternativeEmail, error: false })
    }
  }, [emailConfigById.data, setUserInfo])
  const updateTeamsFlag = () => {
    let body = JSON.parse(localStorage.getItem('MSResponseBody'))
    localStorage.removeItem('MSResponseBody')
    const microsoftGraph = {
      code: '',
      idToken: '',
      redirectUrl: '',
      email: '',
      accountId: emailConfigById.data?.emailAccountId
    }

    microsoftGraph.code = body.code
    microsoftGraph.idToken = body.idToken
    microsoftGraph.redirectUrl = body.redirectUrl
    microsoftGraph.email = body.idToken ? jwtDecode(body.idToken).email : null
    setMicrosoftGraph(microsoftGraph)
  }
  const handleChangeProcess = event => {
    setProcessForEmail([...event.target.value])
  }

  const handleChangeDefaultProcess = event => {
    const defaultProcess = event.target.value
    setDefaultProcess(defaultProcess)
  }

  const onCreateEmailConfig = data => {
    createEmailConfig.mutate(
      { data },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['invoiceOrigin', 'all', props.appId])
        },
        onSuccess: response => {
          props.showValidationMessage('success', 'Saved')
          onClose()
        },
        onError: error => {
          props.showValidationMessage(
            'error',
            error.response?.data?.message ?? 'Something went wrong, try again later'
          )
        }
      }
    )
  }
  const onUpdateEmailConfig = data => {
    updateEmailConfig.mutate(
      { data },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['invoiceOrigin', 'all', props.appId])
        },
        onSuccess: response => {
          props.showValidationMessage('success', 'Saved')
          onClose()
        },
        onError: error => {
          props.showValidationMessage(
            'error',
            error.response?.data?.message ?? 'Something went wrong, try again later'
          )
        }
      }
    )
  }

  function validateBeforeSave() {
    if (alternativeEmail.error) {
      props.showValidationMessage('error', 'Enter a valid email')
    }
    let data = {
      appId: props.appId,
      email: microsoftGraphConfig.email,
      emailProviderType: 'microsoftAccount',
      process: processForEmail,
      mailboxType: mailboxType,
      alternativeEmail: alternativeEmail.email ?? '',
      defaultProcess: defaultProcess,
      microsoftGraphApi: {
        code: microsoftGraphConfig.code,
        idToken: microsoftGraphConfig.idToken,
        redirectUrl: microsoftGraphConfig.redirectUrl
      }
    }
    if (props.action === 'edit') {
      data = {
        appId: props.appId,
        id: props?.id ?? '',
        email: microsoftGraphConfig.email,
        emailProviderType: 'microsoftAccount',
        mailboxType: mailboxType,
        process: processForEmail,
        defaultProcess: defaultProcess,
        alternativeEmail: alternativeEmail.email ?? '',
        emailAccountId: microsoftGraphConfig.accountId,
        microsoftGraphApi: {
          code: microsoftGraphConfig.code,
          idToken: microsoftGraphConfig.idToken,
          redirectUrl: microsoftGraphConfig.redirectUrl
        },
        deleted: false,
        isProcessRunning: emailConfigById.data?.isProcessRunning
      }
    }

    onSaveHandler(data)
  }

  const onClose = () => {
    setUserInfo(null)
    props.onClose()
  }

  const handleChangeServiceProvider = event => {
    setServiceProvider(event.target.value)
  }

  const handleChangeMailboxType = event => {
    setMailboxType(event.target.value)
  }

  const onChangeAlternativeEmail = value => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const isValidEmail = emailRegex.test(value)
    setAlternativeEmail({ email: value, error: !isValidEmail })
  }

  const onSaveHandler = data => {
    setUserInfo(null)
    if (props.action === 'create') {
      onCreateEmailConfig(data)
    } else {
      onUpdateEmailConfig({ id: data.id, data })
    }
  }
  const disabledButton =
    !microsoftGraphConfig.email ||
    processForEmail.length === 0 ||
    !defaultProcess ||
    !mailboxType ||
    (mailboxType === 'sharedEmail' && (!alternativeEmail.email || alternativeEmail.error))

  function returnTabsToDisplay() {
    return (
      <>
        <GridContainer
          style={{ margin: 'auto' }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <GridItem xs={6}>
            <div className={classes2.verticalAlign}>
              <FormLabel id={'mailboxType'} className={classes2.inputLabel}>
                Mailbox Type:
              </FormLabel>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel className={classes.selectLabel}>Mailbox Type*</InputLabel>
              <Select
                labelId="mailboxType"
                id="mailboxTypeSelect"
                value={mailboxType}
                onChange={handleChangeMailboxType}
                MenuProps={{
                  className: classes.selectMenu
                }}
                disabled={props.action === 'edit'}
                classes={{
                  select: classes.select
                }}
              >
                {mailboxTypeOptions.map(option => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{ margin: 'auto' }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <GridItem xs={6}>
            <div className={classes2.verticalAlign}>
              <FormLabel id={'emailProvider'} className={classes2.inputLabel}>
                Email Provider:
              </FormLabel>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel className={classes.selectLabel}>Email Provider*</InputLabel>
              <Select
                labelId="emailProvider"
                id="emailProviderSelect"
                value={serviceProvider}
                onChange={handleChangeServiceProvider}
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
              >
                {serviceProviderOptions.map(option => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{ margin: 'auto' }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <GridItem xs={6}>
            <div className={classes2.verticalAlign}>
              <FormLabel id={'process'} className={classes2.inputLabel}>
                Process
              </FormLabel>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
                Process*
              </InputLabel>
              <Select
                labelId="multiple-select"
                id="multiple-select"
                multiple
                value={processForEmail}
                onChange={handleChangeProcess}
                input={<Input />}
                inputProps={{
                  name: `process`,
                  id: `process`
                }}
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Select the process
                </MenuItem>
                {invoiceProcess.map(process => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={process.id}
                    value={process.id}
                  >
                    {process.processName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{ margin: 'auto' }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <GridItem xs={6}>
            <div className={classes2.verticalAlign}>
              <FormLabel id={'defaultProcess'} className={classes2.inputLabel}>
                Default Process
              </FormLabel>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel className={classes.selectLabel}>Default Process*</InputLabel>
              <Select
                value={defaultProcess}
                onChange={handleChangeDefaultProcess}
                inputProps={{
                  name: `defaultProcess`,
                  id: `defaultProcess`
                }}
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Select the default process
                </MenuItem>
                {defaultProcessOptions.map(process => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={process.id}
                    value={process.id}
                  >
                    {process.processName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer
          style={{ margin: 'auto' }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <GridItem xs={6}>
            <div className={classes2.verticalAlign}>
              <FormLabel id={'msEmailUser'} className={classes2.inputLabel}>
                MS Email User:
              </FormLabel>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <CustomInput
              labelText={
                <div className={classes2.inputLabel}>
                  <span>MS Email User</span>
                </div>
              }
              id={'msEmailUserInput'}
              inputInternalProps={{
                style: {
                  width: '100%',
                  textAlign: 'center'
                }
              }}
              inputProps={{
                type: 'msAccount',
                value: microsoftGraphConfig.email
              }}
              disabled={
                !microsoftGraphConfig.email ||
                (emailConfigById.data?.skipEmailProcessing !== undefined &&
                  !emailConfigById.data?.skipEmailProcessing)
              }
            />
          </GridItem>
        </GridContainer>
        <GridItem xs={12}>
          <TeamsSync
            onLogin={() => updateTeamsFlag()}
            app={'Invoices-AI'}
            clientId={app.data?.data?.invoiceOrigin?.microsoftGraphApi?.MGAppId?.value}
            hasRefreshToken={microsoftGraphConfig.code}
            responseType={'code+id_token'}
            responseMode={'fragment'}
            disabled={
              emailConfigById.data?.skipEmailProcessing !== undefined &&
              !emailConfigById.data?.skipEmailProcessing
            }
            scope={[
              'openid',
              'profile',
              'email',
              'Mail.Read',
              'Mail.ReadWrite',
              'Mail.ReadBasic',
              'User.Read',
              'offline_access',
              'Mail.Read.Shared',
              'Mail.ReadWrite.Shared',
              'Mail.ReadBasic.Shared'
            ]}
            prompt={'login'}
            nonce={'invoices-ai-user-consent'}
            environment={props.appId}
            client={getUserTenant()}
          ></TeamsSync>
        </GridItem>
        {mailboxType === 'sharedEmail' ? (
          <GridContainer
            style={{ margin: 'auto' }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <GridItem xs={6}>
              <div className={classes2.verticalAlign}>
                <FormLabel id={'alternativeEmail'} className={classes2.inputLabel}>
                  Alternative Email
                </FormLabel>
              </div>
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                labelText={
                  <div className={classes2.inputLabel}>
                    <span>Alternative Email</span>
                  </div>
                }
                id="custom-input"
                formControlProps={{
                  fullWidth: true
                }}
                error={alternativeEmail.error}
                inputProps={{
                  disabled: false,
                  onChange: event => {
                    let value = event.target.value
                    if (!value || value === '') {
                      value = undefined
                    }
                    onChangeAlternativeEmail(value)
                  },
                  value: alternativeEmail.email,
                  type: 'email'
                }}
              />
            </GridItem>
          </GridContainer>
        ) : null}

        <GridContainer
          style={{ marginTop: '20px' }}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <GridItem>
            <Button
              style={{ marginRight: '10px' }}
              disabled={disabledButton}
              variant="contained"
              color="primary"
              onClick={() => validateBeforeSave()}
            >
              Save
            </Button>
            <Button variant="contained" color="secondary" onClick={() => onClose()}>
              Cancel
            </Button>
          </GridItem>
        </GridContainer>
      </>
    )
  }
  if (
    app.isError ||
    processByTenantApp.isError ||
    emailConfigById.isError ||
    createEmailConfig.isError
  ) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Something went wrong, try again later</p>
      </div>
    )
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {app.isLoading ||
        processByTenantApp.isLoading ||
        emailConfigById.isLoading ||
        emailConfigById.isFetching ||
        createEmailConfig.isLoading ? (
          <GridContainer justifyContent="center" alignItems="center">
            <div>
              <CircularProgress color="inherit" />
            </div>
          </GridContainer>
        ) : (
          returnTabsToDisplay()
        )}
      </GridItem>
    </GridContainer>
  )
}
