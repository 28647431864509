import { useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  getAppList,
  getApp,
  createApp,
  updateApp,
  updateAppConnection,
  deleteAppConnection,
  validateAppConnection,
  validateSftpAppConnection,
  validateMsSqlAppConnection,
  getTransactionList,
  getTransaction,
  getChildTransactions,
  getTransactionLogs,
  createTransaction,
  updateTransaction,
  changeStatusTransaction,
  updateTransactionConnection,
  validateTransactionsConnection
} from 'services/apiInsights'
import { getUserTenant } from 'utils/functions'

//#region App Configuration
export function useGetAppList({ options = {} }) {
  const tenantId = getUserTenant()
  return useQuery(['Apps', 'tenant', tenantId], () => getAppList().then(result => result.data), {
    ...options
  })
}

export function useGetApp({ appId, options = {} }) {
  return useQuery(
    ['Apps', appId],
    () =>
      getApp({
        appId
      }).then(result => result.data),
    {
      enabled: !!appId,
      ...options
    }
  )
}

export function useCreateApp() {
  return useMutation(values =>
    createApp({
      body: values.data
    })
  )
}

export function useUpdateApp({ appId }) {
  return useMutation(values =>
    updateApp({
      appId,
      body: values.data
    })
  )
}
//#endregion

//#region App Connections Configuration
export function useUpdateAppConnection({ appId }) {
  return useMutation(values =>
    updateAppConnection({
      appId,
      connectionBody: values.data
    })
  )
}

export function useDeleteAppConnection({ appId }) {
  const queryClient = useQueryClient()
  return useMutation(
    values =>
      deleteAppConnection({
        appId,
        connectionBody: values.data
      }),
    {
      onMutate: async connectionDeleted => {
        await queryClient.cancelQueries(['Apps', appId])
        const previousConnection = queryClient.getQueryData(['Apps', appId])
        const newConnection = { ...previousConnection }
        delete newConnection.params.remoteConnections[connectionDeleted.data.connectionId]
        queryClient.setQueryData(['Apps', appId], newConnection)
        return () => {
          queryClient.setQueryData(['Apps', appId], previousConnection)
        }
      }
    }
  )
}

export function useValidateAppConnection({ appId }) {
  return useMutation(values =>
    validateAppConnection({
      appId,
      connectionBody: values.data
    })
  )
}

export function useValidateSftpAppConnection({ appId }) {
  return useMutation(values =>
    validateSftpAppConnection({
      appId,
      connectionBody: values.data
    })
  )
}

export function useValidateMsSqlAppConnection({ appId }) {
  return useMutation(values =>
    validateMsSqlAppConnection({
      appId,
      connectionBody: values.data
    })
  )
}
//#endregion

//#region Transactions Configuration
export function useGetTransactionList({ appId, group, pagination, options = {} }) {
  const queryClient = useQueryClient()
  const { lastEvaluatedKeys, page, rowsPage } = pagination
  const lastEvaluatedKey = page === 0 ? null : lastEvaluatedKeys[page - 1]

  const useQueryResult = useQuery(
    ['Transactions', 'appId', appId, page, rowsPage],
    () =>
      getTransactionList({ appId, group, lastEvaluatedKey, rowsPage }).then(result => {
        const { transactions, lastEvaluatedKey } = result.data
        const newTransactions = transactions.map(transaction => {
          return {
            id: transaction.transactionId,
            transaction: transaction.params.name,
            destination: transaction.params.destination?.name,
            fromDate: transaction.params.filters?.fromDate,
            creationDate: transaction.creationDate,
            status: transaction.status,
            frequency: transaction.params.execution?.frequency
          }
        })
        return { rows: newTransactions, lastEvaluatedKey: lastEvaluatedKey ?? null }
      }),
    {
      enabled: !!appId,
      ...options
    }
  )

  useEffect(() => {
    if (useQueryResult?.data?.lastEvaluatedKey) {
      queryClient.prefetchQuery(['Transactions', 'appId', appId, page + 1, rowsPage], () =>
        getTransactionList({
          appId,
          group,
          lastEvaluatedKey: useQueryResult?.data?.lastEvaluatedKey,
          rowsPage
        }).then(result => {
          const { transactions, lastEvaluatedKey } = result.data
          const newTransactions = transactions.map(transaction => {
            return {
              id: transaction.transactionId,
              transaction: transaction.params.name,
              destination: transaction.params.destination?.name,
              fromDate: transaction.params.filters?.fromDate,
              creationDate: transaction.creationDate,
              status: transaction.status,
              frequency: transaction.params.execution?.frequency
            }
          })
          return { rows: newTransactions, lastEvaluatedKey: lastEvaluatedKey ?? null }
        })
      )
    }
  }, [useQueryResult.data, page, rowsPage, queryClient, appId, group])

  return useQueryResult
}

export function useGetTransaction({ appId, transactionId, options = {} }) {
  return useQuery(
    ['Transactions', transactionId],
    () =>
      getTransaction({
        appId,
        transactionId
      }).then(result => result.data),
    {
      enabled: !!appId && !!transactionId,
      ...options
    }
  )
}

export function useGetChildTransactions({ appId, transactionId, pagination, options = {} }) {
  const queryClient = useQueryClient()
  const { lastEvaluatedKeys, page, rowsPage } = pagination
  const lastEvaluatedKey = page === 0 ? null : lastEvaluatedKeys[page - 1]

  const useQueryResult = useQuery(
    ['ChildTransactions', transactionId, page, rowsPage],
    () =>
      getChildTransactions({
        appId,
        transactionId,
        lastEvaluatedKey,
        rowsPage
      }).then(result => {
        const { childTransactions, lastEvaluatedKey } = result.data
        const newChildTransactions = childTransactions.map(childTransaction => {
          return {
            id: childTransaction.transactionId,
            transaction: childTransaction.params.name,
            destination: childTransaction.params.destination?.name,
            fromDate: childTransaction.params.filters?.fromDate,
            creationDate: childTransaction.creationDate,
            status: childTransaction.status,
            frequency: childTransaction.params.execution?.frequency
          }
        })
        return { rows: newChildTransactions, lastEvaluatedKey: lastEvaluatedKey ?? null }
      }),
    {
      enabled: !!appId && !!transactionId,
      ...options
    }
  )

  useEffect(() => {
    if (useQueryResult?.data?.lastEvaluatedKey) {
      queryClient.prefetchQuery(['ChildTransactions', transactionId, page + 1, rowsPage], () =>
        getChildTransactions({
          appId,
          transactionId,
          lastEvaluatedKey: useQueryResult?.data?.lastEvaluatedKey,
          rowsPage
        }).then(result => {
          const { childTransactions, lastEvaluatedKey } = result.data
          const newChildTransactions = childTransactions.map(childTransaction => {
            return {
              id: childTransaction.transactionId,
              transaction: childTransaction.params.name,
              destination: childTransaction.params.destination?.name,
              fromDate: childTransaction.params.filters?.fromDate,
              creationDate: childTransaction.creationDate,
              status: childTransaction.status,
              frequency: childTransaction.params.execution?.frequency
            }
          })
          return { rows: newChildTransactions, lastEvaluatedKey: lastEvaluatedKey ?? null }
        })
      )
    }
  }, [useQueryResult.data, page, rowsPage, queryClient, appId, transactionId])

  return useQueryResult
}

export function useTransactionLogs({ appId, transactionId, pagination, options = {} }) {
  const queryClient = useQueryClient()
  const { lastEvaluatedKeys, page, rowsPage } = pagination
  const lastEvaluatedKey = page === 0 ? null : lastEvaluatedKeys[page - 1]

  const useQueryResult = useQuery(
    ['TransactionsLogs', transactionId, page, rowsPage],
    () =>
      getTransactionLogs({
        appId,
        transactionId,
        lastEvaluatedKey,
        rowsPage
      }).then(result => {
        const { transactionLogs, lastEvaluatedKey } = result.data
        const newTransactionLogs = transactionLogs.map(log => {
          return {
            id: log.logId,
            type: log.type,
            message: log.message,
            transactionLogDate: log.transactionLogDate
          }
        })
        return { rows: newTransactionLogs, lastEvaluatedKey: lastEvaluatedKey ?? null }
      }),
    {
      enabled: !!appId && !!transactionId,
      ...options
    }
  )

  useEffect(() => {
    if (useQueryResult?.data?.lastEvaluatedKey) {
      queryClient.prefetchQuery(['TransactionsLogs', transactionId, page + 1, rowsPage], () =>
        getTransactionLogs({
          appId,
          transactionId,
          lastEvaluatedKey: useQueryResult?.data?.lastEvaluatedKey,
          rowsPage
        }).then(result => {
          const { transactionLogs, lastEvaluatedKey } = result.data
          const newTransactionLogs = transactionLogs.map(log => {
            return {
              id: log.logId,
              message: log.message,
              transactionLogDate: log.transactionLogDate
            }
          })
          return { rows: newTransactionLogs, lastEvaluatedKey: lastEvaluatedKey ?? null }
        })
      )
    }
  }, [useQueryResult.data, page, rowsPage, queryClient, appId, transactionId])

  return useQueryResult
}

export function useCreateTransaction({ appId }) {
  return useMutation(values =>
    createTransaction({
      appId,
      body: values.data
    })
  )
}

export function useUpdateTransaction({ appId, transactionId }) {
  return useMutation(values =>
    updateTransaction({
      appId,
      transactionId,
      body: values.data
    })
  )
}

export function useChangeStatusTransaction({ appId }) {
  return useMutation(values =>
    changeStatusTransaction({
      appId,
      transactionId: values.data.transactionId,
      body: values.data.changeStatus
    })
  )
}

//#endregion

//#region Transactions Connections Configuration

export function useUpdateTransactionConnection({ appId }) {
  return useMutation(values =>
    updateTransactionConnection({
      appId,
      body: values.data
    })
  )
}

export function useValidateTransactionsConnection({ appId }) {
  return useMutation(values =>
    validateTransactionsConnection({
      appId,
      body: values.data
    })
  )
}

//#endregion
