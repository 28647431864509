import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { validateProperty, validateObject } from 'utils/validator'
import { teamCreate as teamCreateSchema } from 'models/teamModel'
import {
  getAppConfigurationsByApp,
  associateDocumentToTeam
} from 'services/apiApps'
import * as microsoftTeams from '@microsoft/teams-js'

import CreateView from './CreateView'

const newTeam = {
  environment: '',
  documentType: 'requisitions',
  documentId: ''
}

const newTeamState = {
  environment: '',
  documentType: '',
  documentId: ''
}

let _isMounted = false

async function onSubmit (props) {
  // Save handler when user clicked on Save button
  microsoftTeams.settings.registerOnSaveHandler(function (saveEvent) {
    microsoftTeams.getContext(async function (context) {
      props.setIsLoadingSave(true)

      let validation = validateObject(teamCreateSchema, props.team)
      if (validation.isValid) {
        // Set context values to the request
        props.team.channelId = context.channelId
        props.team.teamId = context.teamId
        props.team.groupId = context.groupId

        let result = await associateDocumentToTeam(props.team)
        console.debug(result)
        // Show confirmation message
        if (result.success && _isMounted) {
          props.setCreateSuccess(true)

          let entity = `${props.team.documentId}-${context.teamId}-${context.channelId}`
          let webSiteUrl = `${
            window.location.origin
          }/public/document/details/${encodeURI(result.data.data)}`
          console.debug(`Url: ${webSiteUrl}`)
          let settings = {
            entityId: entity,
            contentUrl: webSiteUrl,
            websiteUrl: webSiteUrl,
            suggestedDisplayName: 'Ariba'
          }
          console.debug(`Settings: ${JSON.stringify(settings)}`)
          microsoftTeams.settings.setSettings(settings)
          saveEvent.notifySuccess()
        } else {
          // Show error message
          if (result) {
            let message
            if (result.data && result.data.length !== 0) {
              message = result.data[0].msg
            } else {
              message = 'Something went wrong, please try again later.'
            }

            saveEvent.notifyFailure(message)
          }
        }
      } else {
        microsoftTeams.settings.setValidityState(false)
        let field
        for (field in props.team) {
          if (validation.errors[field]) {
            props.teamState[field] = 'error'
          }
        }
        props.setTeamState(props.teamState)
      }
      props.setIsLoadingSave(false)
    })
  })
}

async function getAppList (props) {
  let response = await getAppConfigurationsByApp('teams')

  if (response.success) {
    if (_isMounted) {
      props.setAppList(response.data.appList)

      if (response.data.length !== 0) {
        props.team.environment = response.data.appList[0].env
        props.setTeam(props.team)
      }
    }
  }
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name
    }),
    {}
  ),
  withState('team', 'setTeam', { ...newTeam }),
  withState('teamState', 'setTeamState', { ...newTeamState }),
  withState('appList', 'setAppList', []),
  withState('createSuccess', 'setCreateSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('isLoadingSave', 'setIsLoadingSave', false),
  withHandlers({
    onFieldChange: props => (field, value) => {
      props.team[field] = value
      let isValid = validateProperty(teamCreateSchema, props.team, field)
        .isValid
      if (isValid) {
        props.teamState[field] = 'success'
      } else {
        props.teamState[field] = 'error'
      }

      let validation = validateObject(teamCreateSchema, props.team)
      microsoftTeams.settings.setValidityState(validation.isValid)

      props.setTeamState(props.teamState)
      props.setTeam(props.team)
    }
  }),
  lifecycle({
    componentDidMount () {
      _isMounted = true

      // Initialize the Microsoft Teams Library
      microsoftTeams.initialize()

      this.props.setTeam({ ...newTeam })
      this.props.setTeamState({ ...newTeamState })
      this.props.setCreateSuccess(false)
      getAppList(this.props)

      onSubmit(this.props)
    },
    componentWillUnmount () {
      _isMounted = false
    }
  })
)(CreateView)
