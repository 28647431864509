import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
// import dayjs from 'dayjs'
// Material UI
import { CardActions, Fade, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import csc from 'countries-states-cities'
import { hideLoading, showLoading } from 'utils/functions'
import NavPills from '../../../../components/NavPills/NavPills'
// react plugin for creating charts
import Chart from 'react-apexcharts'

// Assets
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
// import poweredByRelish from 'assets/img/POC/powered-by-relish.png'
import dunsNBradstreetLogo from 'assets/img/POC/poweredByRelishNDnBV2.png'
import Accordion from 'components/Accordion2/Accordion2.js'
// Components
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardText from 'components/Card/CardText'
import CustomInput from 'components/CustomInput/CustomInput.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import InfoModal from 'components/Invoice/InfoModal'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import { useQueryParams } from 'hooks/POC/CoupaIFrameApp/useQueryParams'
import ModalWrapper from '../../../../components/ModalWrapper/ModalWrapper'

// Services
import {} from 'services/apiDnbConnector'
import {
  getDnBMatch,
  getDnBSupplierInformation,
  matchCompany,
  unmatchCompany
} from 'services/apiDnbConnector'

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: '#FFFFFF',
    marginTop: '0'
  },
  cardCategory: {
    color: '#999999',
    marginTop: '10px'
  },
  cardCategoryWhite: {
    margin: '0',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '.875rem'
  }
}

const useStyles = makeStyles(styles)

export default function DnBSupplier(props) {
  const classes = useStyles()
  const query = useQueryParams()
  const supplierId = query.get('object_id')

  const hideAlert = () => {
    setStates(old => ({
      ...old,
      alert: null
    }))
  }

  const apexChartOptions = {
    options: {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 4,
          distributed: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: ['High Risk', 'Medium Risk', 'Low Risk', 'Unknown']
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function() {
              return ''
            }
          }
        }
      },
      colors: ['#ed6f6d', '#f5f37f', '#a0d47b', '#bcbfba']
    },
    series: [
      {
        data: [428, 5320, 1589, 9300]
      }
    ]
  }

  const paydexRiskTypes = {
    highRisk: {
      label: 'High Risk',
      value: 'highRisk',
      paydexRange: ['0-10', '11-20', '21-30', '31-40', '41-90'],
      thirdParties: ['29', '35', '80', '119', '165']
    },
    mediumRisk: {
      label: 'Medium Risk',
      value: 'mediumRisk',
      paydexRange: ['50-60', '61-70', '71-79'],
      thirdParties: ['639', '1700', '2981']
    },
    lowRisk: {
      label: 'Low Risk',
      value: 'lowRisk',
      paydexRange: ['80-90', '91-100'],
      thirdParties: ['1583', '6']
    },
    unknown: {
      label: 'Unknown',
      value: 'unknown',
      paydexRange: [''],
      thirdParties: ['9359']
    }
  }

  const mapCountries = () => {
    return csc
      .getAllCountries()
      .map(country => <MenuItem value={country.iso2}>{country.name}</MenuItem>)
  }

  const totalAssetsOptions = {
    options: {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          distributed: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: ['2021-09-04', '2019-12-28', '2018-12-29']
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function() {
              return ''
            }
          }
        }
      },
      colors: ['#47a15f', '#5387a6', '#b5945b']
    },
    series: [
      {
        data: [93.2, 78.5, 77.6]
      }
    ]
  }

  const financialsArray = [
    {
      code: 'Quick Ratio',
      description: '0.7'
    },
    {
      code: 'Current Ratio',
      description: '1'
    },
    {
      code: 'Fixed Assets/Net Worth (%)',
      description: '157.7'
    },
    {
      code: 'Current Liabilities Over Net Worth',
      description: '172.5'
    },
    {
      code: 'Collection Period',
      description: '43.4'
    },
    {
      code: 'Assets Over Sales',
      description: '132'
    },
    {
      code: 'Current Liabilities to Inventory',
      description: '560.2'
    },
    {
      code: 'Return on Sales',
      description: '10.2'
    },
    {
      code: 'Total Liabilities Over Net Worth',
      description: '585.6'
    },
    {
      code: 'Profit Margin',
      description: '10.2'
    },
    {
      code: 'Return On Assets',
      description: '7.7'
    },
    {
      code: 'Accounts Payable to Sales',
      description: '27.8'
    },
    {
      code: 'Sales to Inventory',
      description: '16.9'
    },
    {
      code: 'Total liabilities to gross profit',
      description: '2.0574'
    }
  ]

  const getModalItems = itemArray => {
    return itemArray.map(item => (
      <>
        <GridContainer>
          <GridItem xs={6}>
            <label style={{ float: 'left', paddingBottom: '15px' }}>{item.code}</label>
          </GridItem>
          <GridItem xs={6}>
            <label style={{ float: 'left', paddingBottom: '15px' }}>{item.description}</label>
          </GridItem>
        </GridContainer>
      </>
    ))
  }

  const getModalItemsV2 = itemArray => {
    return itemArray.map(item => (
      <>
        <GridContainer>
          <GridItem xs={9}>
            <label style={{ display: 'inline-block', float: 'left', color: '#3c78c8' }}>
              {item.code}
            </label>
          </GridItem>
          <GridItem xs={3}>
            <label style={{ display: 'inline-block', float: 'left', color: 'primary' }}>
              {item.description}
            </label>
          </GridItem>
        </GridContainer>
      </>
    ))
  }

  const getTableGrids = itemArray => {
    return itemArray.map(item => (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <label style={{ float: 'center' }}>{item}</label>
          </GridItem>
        </GridContainer>
      </>
    ))
  }

  const getCardComponents = labelArrays => {
    let result = []
    // eslint-disable-next-line
    for (const label of labelArrays) {
      const labelFound = states.companyInformation.find(value => value.label === label)
      if (labelFound) {
        if (labelFound.label === 'Registration Codes') {
          result.push(
            <>
              <GridItem xs={6} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={9}>
                    <label style={{ display: 'inline-block', float: 'left', color: '#3c78c8' }}>
                      {labelFound.label}
                    </label>
                  </GridItem>
                  <GridItem xs={3}>
                    <Link
                      style={{ display: 'inline-block', float: 'left', bottom: 0 }}
                      component="button"
                      variant="body2"
                      onClick={() => {
                        setStates(old => ({
                          ...old,
                          alert: (
                            <ModalWrapper>
                              <InfoModal
                                fullWidth={true}
                                maxWidth="sm"
                                showModal={true}
                                warningMessage={`Do you want to add these Registration Codes to Coupa supplier ${states.supplierName} (${states.supplierId})? `}
                                title={'Coupa Supplier Information'}
                                textSuccessBtn={'Push'}
                                onSubmit={() => hideAlert()}
                                onClose={() => hideAlert()}
                                transitionComponent={Fade}
                              />
                            </ModalWrapper>
                          )
                        }))
                      }}
                    >
                      {
                        <>
                          <ImportExportIcon style={{ maxWidth: '16px' }} />
                        </>
                      }
                    </Link>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Link
                  style={{ display: 'inline-block', float: 'left', color: '#3c78c8' }}
                  component="button"
                  variant="body2"
                  onClick={() => {
                    setStates(old => ({
                      ...old,
                      alert: (
                        <ModalWrapper>
                          <InfoModal
                            fullWidth={true}
                            maxWidth="sm"
                            successBtn={false}
                            showModal={true}
                            warningMessage={<>{getModalItems(labelFound.items)}</>}
                            title={labelFound.label}
                            onClose={() => hideAlert()}
                            transitionComponent={Fade}
                          />
                        </ModalWrapper>
                      )
                    }))
                  }}
                >
                  {'View'}
                </Link>
              </GridItem>
            </>
          )
        } else if (labelFound.type === 'list') {
          result.push(
            <>
              <GridItem xs={6} sm={6} md={6}>
                <label style={{ display: 'inline-block', float: 'left', color: '#3c78c8' }}>
                  {labelFound.label}
                </label>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Link
                  style={{ display: 'inline-block', float: 'left', color: '#3c78c8' }}
                  component="button"
                  variant="body2"
                  onClick={() => {
                    setStates(old => ({
                      ...old,
                      alert: (
                        <ModalWrapper>
                          <InfoModal
                            fullWidth={true}
                            maxWidth="sm"
                            successBtn={false}
                            showModal={true}
                            warningMessage={<>{getModalItems(labelFound.items)}</>}
                            title={labelFound.label}
                            onClose={() => hideAlert()}
                            transitionComponent={Fade}
                          />
                        </ModalWrapper>
                      )
                    }))
                  }}
                >
                  {'View'}
                </Link>
              </GridItem>
            </>
          )
        } else {
          result.push(
            <>
              <GridItem xs={6} sm={6} md={6}>
                <label style={{ display: 'inline-block', float: 'left', color: '#3c78c8' }}>
                  {label}
                </label>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <label style={{ display: 'inline-block', float: 'left', color: 'primary' }}>
                  {labelFound.data}
                </label>
              </GridItem>
            </>
          )
        }
      }
    }
    return result
  }

  const [states, setStates] = useState({
    showLoader: false,
    supplierName: '',
    supplierId: null,
    screenType: null,
    matchCriteria: null,
    possibleMatches: null,
    companyInformation: null,
    snackBarMessage: null,
    snackBarShowSuccess: false,
    snackBarShowError: false,
    alert: null,
    matchDuns: null
  })

  const setMatchCriteria = (name, value) => {
    setStates(old => ({
      ...old,
      matchCriteria: {
        ...old.matchCriteria,
        [name]: value
      }
    }))
  }

  const {
    isLoading: loadingSupplierInformation,
    isFetching: fetchingSupplierInformation
  } = useQuery(
    ['SupplierInformation', 'dnbConnector-supplierInformation'],
    () => getDnBSupplierInformation(supplierId).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      onSuccess: data => {
        if (data) {
          setStates(old => ({
            ...old,
            ...data
          }))
        }
      }
    }
  )

  const { refetch: refetchSupplierMatch } = useQuery(
    ['SupplierMatch', `dnbConnector-supplierMatch`],
    () =>
      getDnBMatch(states.matchCriteria, states.supplierId, states.supplierName).then(
        result => result.data
      ),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      onSuccess: data => {
        hideLoading()
        if (data) {
          setStates(old => ({
            ...old,
            ...data
          }))
        }
      }
    }
  )

  const { refetch: refetchMatchedCompany } = useQuery(
    ['MatchedCompany', `dnbConnector-matchedCompany`],
    () =>
      matchCompany(states.matchDuns, states.supplierId, states.supplierName).then(
        result => result.data
      ),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      onSuccess: data => {
        hideLoading()
        if (data) {
          setStates(old => ({
            ...old,
            ...data
          }))
        }
      }
    }
  )

  const { refetch: refetchUnmatchDuns } = useQuery(
    ['Unmatch', `dnbConnector-unmatchedCompany`],
    () => unmatchCompany(states.supplierId, states.supplierName).then(result => result.data),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      onSuccess: data => {
        hideLoading()
        if (data) {
          setStates(old => ({
            ...old,
            ...data
          }))
        }
      }
    }
  )

  useEffect(() => {
    setStates(old => ({
      ...old,
      showLoader: loadingSupplierInformation || fetchingSupplierInformation
    }))
  }, [loadingSupplierInformation, fetchingSupplierInformation])

  return (
    <>
      <GridContainer
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '30px',
          paddingBottom: '30px',
          width: '100%',
          margin: '0'
        }}
      >
        <GridItem xs={12}></GridItem>
        <GridItem xs={12}>{states.showLoader && <SyncLoader showLoader={true} />}</GridItem>

        {states.alert}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {!states.showLoader && states.screenType === 'match' && (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader>
                  <CardText style={{ backgroundColor: '#3C78C8' }}>
                    <h4 className={classes.cardTitleWhite}>{states.supplierName}</h4>
                    <h5
                      className={classes.cardCategoryWhite}
                    >{`${states.possibleMatches?.length} possible matches`}</h5>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <p style={{ textAlign: 'left' }}>
                    <b>Please select a company to associate information with DnB Connector</b>
                    <Accordion
                      collapses={[
                        {
                          title: 'Match Criteria',
                          content: (
                            <>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  <GridContainer>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <CustomInput
                                        labelText="DUNS Number"
                                        id="dunsNumber"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('duns', event.target.value)
                                          },
                                          type: 'text',
                                          value: states.matchCriteria.duns
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <CustomInput
                                        labelText="Company Name"
                                        id="companyName"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('companyName', event.target.value)
                                          },
                                          type: 'text',
                                          value: states.matchCriteria.companyName
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <FormControl fullWidth>
                                          <InputLabel id="countryLabel">Country</InputLabel>
                                          <Select
                                            labelId="countryLabel"
                                            id="countrySelect"
                                            value={states.matchCriteria.countryISOAlpha2Code}
                                            onChange={event => {
                                              setMatchCriteria(
                                                'countryISOAlpha2Code',
                                                event.target.value
                                              )
                                            }}
                                          >
                                            {mapCountries()}
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={9} md={9}>
                                      <CustomInput
                                        labelText="Address Line 1"
                                        id="addressLine1"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria(
                                              'streetAddressLine1',
                                              event.target.value
                                            )
                                          },
                                          type: 'text',
                                          value: states.matchCriteria.streetAddressLine1
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={9} md={9}>
                                      <CustomInput
                                        labelText="Address Line 2"
                                        id="addressLine2"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria(
                                              'streetAddressLine2',
                                              event.target.value
                                            )
                                          },
                                          type: 'text',
                                          value: states.matchCriteria.streetAddressLine2
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <CustomInput
                                        labelText="Postal Code"
                                        id="postalCode"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('postalCode', event.target.value)
                                          },
                                          type: 'text',
                                          value: states.matchCriteria.postalCode
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <CustomInput
                                        labelText="Region"
                                        id="region"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('addressRegion', event.target.value)
                                          },
                                          type: 'text',
                                          value: states.matchCriteria.addressRegion
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={4}>
                                      <CustomInput
                                        labelText="Registration Code"
                                        id="registrationCode"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          onChange: event => {
                                            setMatchCriteria('registrationCode', event.target.value)
                                          },
                                          type: 'text',
                                          value: states.matchCriteria.registrationCode
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <Box textAlign="right">
                                        <Button
                                          color="primary"
                                          type="button"
                                          disabled={false}
                                          variant="contained"
                                          style={{ marginRight: '5px', backgroundColor: '#081c3e' }}
                                          onClick={() => {
                                            showLoading()
                                            refetchSupplierMatch({ throwOnError: true })
                                          }}
                                        >
                                          {'Search'}
                                        </Button>
                                      </Box>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                            </>
                          )
                        }
                      ]}
                    />
                  </p>
                  <MaterialTable
                    columns={[
                      {
                        cellStyle: { padding: '5px 16px' },
                        title: 'Company Name',
                        field: 'primaryName'
                      },
                      {
                        cellStyle: { padding: '5px 16px' },
                        title: 'DUNS number',
                        field: 'duns'
                      },
                      {
                        cellStyle: { padding: '5px 16px' },
                        title: 'Confidence Code',
                        field: 'matchScore'
                      },
                      {
                        cellStyle: { padding: '5px 16px' },
                        title: 'Address',
                        field: 'address'
                      },
                      {
                        cellStyle: { padding: '5px 16px' },
                        title: 'Country',
                        field: 'country'
                      },
                      {
                        cellStyle: { padding: '5px 16px' },
                        title: 'Region',
                        field: 'region'
                      },
                      {
                        cellStyle: { padding: '5px 16px' },
                        title: 'Postal Code',
                        field: 'postalCode'
                      },
                      {
                        cellStyle: { padding: '5px 16px' },
                        title: 'Match',
                        render: rowData => (
                          <IconButton
                            onClick={() => {
                              setStates(old => ({
                                ...old,
                                matchDuns: rowData.duns,
                                alert: (
                                  <ModalWrapper>
                                    <InfoModal
                                      fullWidth={true}
                                      maxWidth="sm"
                                      showModal={true}
                                      warningMessage={
                                        <>
                                          <p>{`Do you want to match DUNS Number ${rowData.duns} with Coupa supplier ${states.supplierName} (${states.supplierId})? `}</p>
                                          <br />
                                          <p>
                                            {
                                              'Note: This action will update the Coupa supplier record with the updated DUNS number.'
                                            }
                                          </p>
                                        </>
                                      }
                                      title={'D&B Supplier Match'}
                                      textSuccessBtn={'Match'}
                                      onSubmit={async () => {
                                        hideAlert()
                                        showLoading()
                                        refetchMatchedCompany()
                                      }}
                                      onClose={() => hideAlert()}
                                      transitionComponent={Fade}
                                    />
                                  </ModalWrapper>
                                )
                              }))
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )
                      }
                    ]}
                    data={states.possibleMatches}
                    options={{
                      actionsColumnIndex: -1,
                      sorting: true,
                      showTitle: false,
                      search: false,
                      paging: false,
                      toolbar: false
                    }}
                  />
                </CardBody>
                <CardActions>
                  <GridContainer>
                    <GridItem xs={6} style={{ textAlign: 'left' }}>
                      <a
                        href="https://relishiq.com/solutions/relish-data-assure/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#3c78c8' }}
                      >
                        <img
                          src={dunsNBradstreetLogo}
                          alt="Go to Relish"
                          style={{ maxWidth: '500px' }}
                        />
                      </a>
                    </GridItem>
                  </GridContainer>
                </CardActions>
              </Card>
            </GridItem>
          )}
        </GridItem>

        <GridItem xs={12}>
          {!states.showLoader && states.screenType === 'supplierInformation' && (
            <Card>
              <CardHeader style={{ paddingBottom: '0px' }}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <CardText style={{ backgroundColor: '#3C78C8' }}>
                      <h4 className={classes.cardTitleWhite}>{`${
                        states.companyInformation.find(value => value.label === 'Company Name').data
                      }`}</h4>
                    </CardText>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4
                          color="primary"
                          style={{ float: 'right', marginBottom: '0px', marginTop: '0px' }}
                        >{`D-U-N-S# ${states.matchDuns}`}</h4>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Link
                          style={{ display: 'inline-block', float: 'right', padding: { top: 0 } }}
                          component="button"
                          variant="body2"
                          onClick={() => {
                            setStates(old => ({
                              ...old,
                              alert: (
                                <ModalWrapper>
                                  <InfoModal
                                    fullWidth={true}
                                    maxWidth="sm"
                                    showModal={true}
                                    warningMessage={`Do you want to unmatch the DUNS number associated to this supplier ${states.supplierName}`}
                                    title={'Dnb Connector'}
                                    textSuccessBtn={'Unmatch'}
                                    onSubmit={async () => {
                                      hideAlert()
                                      showLoading()
                                      refetchUnmatchDuns()
                                    }}
                                    onClose={() => hideAlert()}
                                    transitionComponent={Fade}
                                  />
                                </ModalWrapper>
                              )
                            }))
                          }}
                        >
                          {'Unmatch Supplier'}
                        </Link>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <NavPills
                  color="primary"
                  alignCenter={true}
                  deleteBorderRadius={true}
                  tabs={[
                    {
                      tabButton: 'Company Profile',
                      tabContent: (
                        <>
                          <GridContainer xs={12}>
                            <GridItem xs={6}>
                              <Card style={{ height: '95%' }}>
                                <CardBody>
                                  <GridContainer>
                                    {getCardComponents([
                                      'Address',
                                      'Phone',
                                      'Website',
                                      'Registration Codes'
                                    ])}
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>
                            <GridItem xs={6}>
                              <Card style={{ height: '95%' }}>
                                <CardBody>
                                  <GridContainer>
                                    {getCardComponents([
                                      'Employees (this site)',
                                      'Employees (all sites)',
                                      'Year Started',
                                      'Incorporated Date'
                                    ])}
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>
                            <GridItem xs={6}>
                              <Card style={{ height: '95%' }}>
                                <CardBody>
                                  <GridContainer>
                                    {getCardComponents([
                                      'Ranking',
                                      'Revenue',
                                      'Stock Exchange',
                                      'Ownership Type'
                                    ])}
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>
                            <GridItem xs={6}>
                              <Card style={{ height: '95%' }}>
                                <CardBody>
                                  <GridContainer>
                                    {getCardComponents([
                                      'Primary Industry Code (SIC)',
                                      'Industry Codes'
                                    ])}
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>
                          </GridContainer>
                        </>
                      )
                    },
                    {
                      tabButton: 'Risk',
                      tabContent: (
                        <>
                          <GridContainer xs={12}>
                            <GridItem xs={12}>
                              <h4 style={{ color: '#3C78C8' }}>Financial Risk Scores</h4>
                            </GridItem>
                            <GridItem xs={6}>
                              <Card style={{ height: '100%' }}>
                                <CardBody>
                                  <Chart
                                    options={apexChartOptions.options}
                                    series={apexChartOptions.series}
                                    type="bar"
                                  />
                                </CardBody>
                              </Card>
                            </GridItem>
                            <GridItem xs={6}>
                              <Card style={{ height: '100%' }}>
                                <CardBody>
                                  <MaterialTable
                                    columns={[
                                      {
                                        headerStyle: {
                                          paddingBottom: '0px',
                                          paddingTop: '0px',
                                          float: 'center'
                                        },
                                        title: 'Risk Type',
                                        cellStyle: { paddingBottom: '0px', paddingTop: '0px' },
                                        field: 'name'
                                      },
                                      {
                                        headerStyle: {
                                          paddingBottom: '0px',
                                          paddingTop: '0px',
                                          float: 'center'
                                        },
                                        title: 'PAYDEX',
                                        cellStyle: { paddingBottom: '0px', paddingTop: '0px' },
                                        render: rowData => (
                                          <>
                                            {getTableGrids(
                                              paydexRiskTypes[rowData.value].paydexRange
                                            )}
                                          </>
                                        )
                                      },
                                      {
                                        headerStyle: {
                                          paddingBottom: '0px',
                                          paddingTop: '0px',
                                          float: 'center'
                                        },
                                        title: 'Third Parties',
                                        cellStyle: { paddingBottom: '0px', paddingTop: '0px' },
                                        render: rowData => (
                                          <>
                                            {getTableGrids(
                                              paydexRiskTypes[rowData.value].thirdParties
                                            )}
                                          </>
                                        )
                                      }
                                    ]}
                                    data={[
                                      {
                                        name: 'High Risk',
                                        value: paydexRiskTypes.highRisk.value
                                      },
                                      {
                                        name: 'Medium Risk',
                                        value: paydexRiskTypes.mediumRisk.value
                                      },
                                      {
                                        name: 'Low Risk',
                                        value: paydexRiskTypes.lowRisk.value
                                      },
                                      {
                                        name: 'Unknown',
                                        value: paydexRiskTypes.unknown.value
                                      }
                                    ]}
                                    options={{
                                      actionsColumnIndex: -1,
                                      sorting: true,
                                      showTitle: false,
                                      search: false,
                                      paging: false,
                                      toolbar: false
                                    }}
                                  />
                                </CardBody>
                              </Card>
                            </GridItem>
                          </GridContainer>
                        </>
                      )
                    },
                    {
                      tabButton: 'Financials',
                      tabContent: (
                        <>
                          <GridContainer xs={12}>
                            <GridItem xs={6}>
                              <Card style={{ height: '100%' }}>
                                <CardBody>
                                  <GridContainer>{getModalItemsV2(financialsArray)}</GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>
                            <GridItem xs={6}>
                              <Card style={{ height: '100%' }}>
                                <CardHeader>
                                  <h5>
                                    Total Assets <small>{`(In Billions)`}</small>
                                  </h5>
                                </CardHeader>
                                <CardBody>
                                  <Chart
                                    options={totalAssetsOptions.options}
                                    series={totalAssetsOptions.series}
                                    type="bar"
                                  />
                                </CardBody>
                              </Card>
                            </GridItem>
                          </GridContainer>
                        </>
                      )
                    },
                    {
                      tabButton: 'Diversity',
                      tabContent: (
                        <>
                          <div>
                            <h4>
                              Requested product or feature or block(s) is not authorized as per the
                              contract.
                            </h4>
                          </div>
                        </>
                      )
                    }
                  ]}
                />
                <br />
              </CardBody>
              <CardActions>
                <GridContainer>
                  <GridItem xs={6} style={{ textAlign: 'left' }}>
                    <a
                      href="https://relishiq.com/solutions/relish-data-assure/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#3c78c8' }}
                    >
                      <img
                        src={dunsNBradstreetLogo}
                        alt="Go to Relish"
                        style={{ maxWidth: '500px' }}
                      />
                    </a>
                  </GridItem>
                </GridContainer>
              </CardActions>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </>
  )
}
