import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import {
  getInvoiceConfigByAppQuery,
  getInvoiceAppConfigurationByAppAndIdQuery,
  getInvoiceChildLoadsByLoadIdQuery,
  getInvoiceAppLoadByAppAndIdQuery
} from 'services/apiApps'
import { getUserTenant } from 'utils/functions'
import moment from 'moment'
import { dayOfWeekDictionary } from 'utils/Constants'
moment.locale(window.navigator.userLanguage || window.navigator.language)

export function useGetAppList(app) {
  const tenantId = getUserTenant()
  return useQuery(
    ['Apps', 'tenant', tenantId, app],
    () => getInvoiceConfigByAppQuery(app).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )
}

export function useGetAppConfigurationById(environmentId) {
  return useQuery(
    ['Apps', environmentId],
    () => getInvoiceAppConfigurationByAppAndIdQuery(environmentId).then(result => result.data),
    {
      enabled: Boolean(environmentId),
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )
}

export function useGetChildLoadsByLoadId(params) {
  const queryClient = useQueryClient()

  const { app, type, idLoad, environmentId, format, pagination } = params
  const { lastEvaluatedKeys, page, rowsPage } = pagination
  const lastEvaluatedKey = page === 0 ? null : lastEvaluatedKeys[page - 1]

  const useQueryResult = useQuery(
    ['Loads', 'Child', { app, type, idLoad, environmentId, page, rowsPage }],
    () =>
      getInvoiceChildLoadsByLoadIdQuery({
        app,
        env: environmentId,
        type,
        id: idLoad,
        rowsPage,
        lastEvaluatedKey: lastEvaluatedKey
      }).then(result => {
        const { childLoads: loads, lastEvaluatedKey } = result.data
        let rows = []
        if (typeof format === 'function') {
          rows = format(loads)
        } else {
          rows = loads
        }
        return { rows: rows, lastEvaluatedKey: lastEvaluatedKey ?? null }
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: Boolean(environmentId)
      // keepPreviousData: true
    }
  )

  useEffect(() => {
    if (useQueryResult?.data?.lastEvaluatedKey) {
      queryClient.prefetchQuery(
        ['Loads', 'Child', { app, type, idLoad, environmentId, page: page + 1, rowsPage }],
        () =>
          getInvoiceChildLoadsByLoadIdQuery({
            app,
            env: environmentId,
            type,
            id: idLoad,
            rowsPage,
            lastEvaluatedKey: useQueryResult?.data?.lastEvaluatedKey
          }).then(result => {
            const { childLoads: loads, lastEvaluatedKey } = result.data
            let rows = []
            if (typeof format === 'function') {
              rows = format(loads)
            } else {
              rows = loads
            }
            return { rows: rows, lastEvaluatedKey: lastEvaluatedKey ?? null }
          })
      )
    }
  }, [useQueryResult.data, page, rowsPage, queryClient, app, type, idLoad, environmentId, format])

  return useQueryResult
}

export function useGetAppLoadByAppAndId(params) {
  const { app, idLoad, environmentId, typeApp } = params
  return useQuery(
    ['Apps', params],
    () =>
      getInvoiceAppLoadByAppAndIdQuery({
        app,
        env: environmentId,
        idLoad
      }).then(result => {
        const load = result.data.params
        let config = {
          name: load.name,
          type: load.type,
          updateFrequency: load.updateFrequency,
          startDate: moment(load.startDate).format('MM/DD/YYYY'),
          executionTime: moment(load.executionTime).format('hh:mm a'),
          executionDay:
            load.updateFrequency === 'weekly'
              ? dayOfWeekDictionary[load.executionDay]
              : load.executionDay.padStart(2, '0'),
          executionHour: load.executionHour.padStart(2, '0'),
          executionMinute: load.executionMinute?.padStart(2, '0'),
          typeApp: typeApp,
          creationDate: load.creationDate,
          status: result.data.status,
          views: load.views,
          statusProgress: result.data.statusProgress
        }
        return config
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: Boolean(environmentId)
    }
  )
}
