import { CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'
import AddAlert from '@material-ui/icons/AddAlert'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import DescriptionIcon from '@material-ui/icons/Description'
import InboxIcon from '@material-ui/icons/Inbox'

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import AdminNavbarLinks from 'components/Navbars/Admin/AdminNavbarLinks'
import Snackbar from 'components/Snackbar/Snackbar'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import { useGetConnectivityConfiguration } from 'hooks/useInvoiceConfig'
import { useGetFileInformation } from 'hooks/useOcrInvoice'
import { useGeInvoicesAiTenantConfig } from 'hooks/useTenantConfig'
import { useGetValidationRuleFields } from 'hooks/useValidationRules'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { signOut } from 'store/AuthState'
import { ExceptionDocument } from './ExceptionDocument'
import { ExceptionDocumentSimplePdf } from './ExceptionDocumentSimplePdf'
import { ExceptionFields } from './ExceptionFields'
const useStylesContainer = makeStyles(theme => ({
  root: {
    height: '100vh'
  }
}))

const useStylesGridItemLeft = makeStyles(theme => ({
  root: {
    width: '50%',
    maxHeight: '100vh'
  }
}))

const useStylesGridItemRight = makeStyles(theme => ({
  root: {
    width: '50%',
    maxHeight: '100vh',
    borderLeft: '3px solid rgb(0, 0, 0, 0.14)',
    backgroundColor: 'white',
    overflow: 'auto'
  }
}))

const useStylesGridItemRightHeader = makeStyles(theme => ({
  root: {
    padding: '2px'
  }
}))
const useStylesGridItemRightBody = makeStyles(theme => ({
  root: {
    padding: '0 5px 5px 5px'
  }
}))

export default function ExceptionOcrReadOnly(props) {
  const { invoiceFetch } = props
  const { invoiceId } = useParams()
  const classesContainer = useStylesContainer()
  const dispatch = useDispatch()
  const classesGridItemLeft = useStylesGridItemLeft()
  const classesGridItemRight = useStylesGridItemRight()
  const classesGridItemRightHeader = useStylesGridItemRightHeader()
  const classesGridItemRightBody = useStylesGridItemRightBody()

  const [pageNumber, setPageNumber] = useState(1)

  const [unrecognizedFieldSelected, setUnrecognizedFieldSelected] = useState({
    previus: null,
    current: null
  })

  const [lineItemKey, setLineItemKey] = useState(null)
  const [additionalChargeKey, setAdditionalChargeKey] = useState(null)
  const [summaryFieldId, setSummaryFieldId] = useState(null)
  const [tab, setTab] = useState(0)
  const [annotationUnrecognizedField, setAnnotationUnrecognizedField] = useState(null)
  const [createError, setCreateError] = useState({ message: '', isOpen: false })
  const [dataDelete, setDataDelete] = useState({ isDelete: false })
  const inputRef = useRef({ annotation: {} })

  const connectivityConfig = useGetConnectivityConfiguration(invoiceFetch.data?.appId)

  const ocrInformation = useGetFileInformation({
    invoiceId
  })

  const appConfig = useGeInvoicesAiTenantConfig('invoiceAI', invoiceFetch.data?.appId)

  const ruleFields = useGetValidationRuleFields(
    invoiceFetch.data?.appId,
    invoiceFetch.data?.process
  )

  const onChangeTab = useCallback(index => {
    setTab(index)
  }, [])

  useEffect(() => {
    setSummaryFieldId(null)
  }, [tab])

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  const onChangeUnrecognizedField = (e, data) => {
    setUnrecognizedFieldSelected(data)
  }

  const geometryMemo = useMemo(() => {
    if (!ocrInformation.data) {
      return []
    }
    return ocrInformation.data.geometryPerPage
  }, [ocrInformation])

  if (
    ocrInformation.isLoading ||
    appConfig.isLoading ||
    connectivityConfig.isLoading ||
    ruleFields.isLoading
  ) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    )
  }

  if (ocrInformation.isError || ruleFields.isError) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Something went wrong, try again later</p>
      </div>
    )
  }

  const breadcrumbViews = [
    {
      name: 'Invoices',
      url: `/admin/invoiceAI/index`,
      icon: InboxIcon
    },
    {
      name: 'Invoice Details',
      url: `/admin/invoiceAI/${invoiceId}/details`,
      icon: DescriptionIcon
    },
    {
      name: 'Invoice Exception',
      url: `/view/invoiceAI/${invoiceId}/exception-read-only`,
      icon: ArtTrackIcon
    }
  ]

  return (
    <Grid container className={classesContainer.root}>
      <Grid item className={classesGridItemLeft.root}>
        {Object.keys(ocrInformation.data.files || {}).length > 0 ? (
          <ExceptionDocument
            ocrInformation={ocrInformation}
            changePage={changePage}
            geometryPerPage={geometryMemo}
            pageNumber={pageNumber}
            unrecognizedFieldSelected={unrecognizedFieldSelected}
            onChangeSummaryField={setSummaryFieldId}
            summaryFieldId={summaryFieldId}
            setLineItemKey={setLineItemKey}
            setAdditionalChargeKey={setAdditionalChargeKey}
            onChangeTab={onChangeTab}
            tab={tab}
            annotationUnrecognizedField={annotationUnrecognizedField}
            setPageNumber={setPageNumber}
            setDataDelete={setDataDelete}
            ref={inputRef}
          />
        ) : (
          <ExceptionDocumentSimplePdf
            ocrInformation={ocrInformation}
            changePage={changePage}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </Grid>
      <Grid item className={classesGridItemRight.root}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classesGridItemRightHeader.root}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                height: '25px'
              }}
            >
              <Hidden smDown implementation="css">
                <AdminNavbarLinks signOut={() => dispatch(signOut())} rtlActive={true} />
              </Hidden>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Breadcrumbs views={breadcrumbViews} />
              </Grid>
            </Grid>
            <SyncLoader showLoader={ocrInformation.isFetching && !ocrInformation.isLoading} />
          </Grid>
        </Grid>
        <Grid container className={classesGridItemRightBody.root}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ExceptionFields
              ocrInformation={ocrInformation}
              appId={invoiceFetch.data?.appId}
              invoiceId={invoiceId}
              pageNumber={pageNumber}
              onChangeUnrecognizedField={onChangeUnrecognizedField}
              onChangeSummaryField={setSummaryFieldId}
              summaryFieldId={summaryFieldId}
              lineItemKey={lineItemKey}
              additionalChargeKey={additionalChargeKey}
              setLineItemKey={setLineItemKey}
              setAdditionalChargeKey={setAdditionalChargeKey}
              setAnnotationUnrecognizedField={setAnnotationUnrecognizedField}
              onChangeTab={onChangeTab}
              tab={tab}
              ref={inputRef}
              appConfig={appConfig.data}
              invoiceData={invoiceFetch.data}
              setPageNumber={setPageNumber}
              dataDelete={dataDelete}
              setDataDelete={setDataDelete}
              connectivityConfig={connectivityConfig.data}
              requiredFields={ruleFields.data}
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '' })}
        close
      />
    </Grid>
  )
}
