import { compose, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import { getListOfTickets } from "services/apiSupportTickets";

import IndexView from "./IndexView";
import { hideLoading, showLoading } from "utils/functions";

let _isMounted = false;

async function getTickets(props, callback) {
  let result = await getListOfTickets();
  if (_isMounted && result.success) {
    props.setTicketsList(result.data);
  }
  callback(props.setPageIsLoading);
}

export default compose(
  connect(
    (state) => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
    }),
    {}
  ),
  withState("ticketsList", "setTicketsList", []),
  withState("pageIsLoading", "setPageIsLoading", true),
  lifecycle({
    componentDidMount() {
      _isMounted = true;
      showLoading(this.props.setPageIsLoading);
      getTickets(this.props, hideLoading);
    },
  })
)(IndexView);
