import React from 'react'
import PropTypes from 'prop-types'
import { useGetPresignedurlAttachments } from 'hooks/useInvoices'
import { Attachments } from 'components/Invoice/Attachments'

export function AttachmentsInvoices(props) {
  const { cantAttachments, appId, invoiceId } = props
  const responseQuery = useGetPresignedurlAttachments({
    invoiceId,
    appId,
    config: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: !!cantAttachments,
      cacheTime: 2400000
    }
  })

  return (
    <Attachments
      dataAttachments={{
        ...responseQuery,
        cantAttachments,
        attachments: responseQuery.data ?? []
      }}
    />
  )
}

AttachmentsInvoices.propTypes = {
  cantAttachments: PropTypes.number.isRequired,
  invoiceId: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired
}
