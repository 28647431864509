import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { getSupportTicketDetails, closeSupportTicket } from 'services/apiSupportTickets'

//Views
import SupportTicketsDetailsView from './SupportTicketsDetailsView'

//Functions
import { showLoading, hideLoading, getUserRoles, getUserTenant, getUserInfo } from 'utils/functions';

let _isMounted = false
let tenant
let ticketId

async function getTicketDetails(props, tenant, ticketId) {
  let response = await getSupportTicketDetails({tenant, ticketId, userTenant: getUserTenant(), userEmail: getUserInfo().email})
  if (response && response.success) {
    if (_isMounted) {
      props.setIsExpandComments(response.data.status === 'Opened')
      props.setSupportTicket(response.data)
      hideLoading(props.setPageIsLoading)
    }
  } else if (response && response.code === 403) {
    props.setIsUnauthorizer(true)
    hideLoading(props.setPageIsLoading)
  } else if (response && response.code === 404) {
    props.setIsNotFound(true)
    hideLoading(props.setPageIsLoading)
  } else {
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

async function getUserRole(props) {
  let roles = getUserRoles()

  // Check Roles
  if (roles.includes('Admin') || roles.includes('Super Admin')) {
    props.setIsUserAdmin(true)
  }
}
async function updateSupportTicket(props) {
  let result = await closeSupportTicket(props.supportTicket.tenantId, props.supportTicket.ticketId, {supportComment: props.supportComment});
  // Show confirmation message
  if (result.success && _isMounted) {
    props.setSubmitSuccess(true);
    setTimeout(function() {
      if (_isMounted) {
        props.setSubmitSuccess(false);
        props.history.push("/admin/support/index");
      }
    }, 1500);
  } else {
    // Show error message
    if (result) {
      let message = result.message;
      if (!message) {
        message = "Something went wrong, please try again later.";
      }
      props.setValidationMessage(message);
      props.setCreateError(true);
    }
  }
}

export default compose(
  connect(state => ({
    isAuthenticated: state.login.isAuthenticated,
    name: state.login.name
  })),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('supportTicket', 'setSupportTicket', {}),
  withState('supportComment', 'setSupportComment', ''),
  withState('open', 'setOpen', false),
  withState("isLoadingSave", "setIsLoadingSave", false),
  withState('isUserAdmin', 'setIsUserAdmin', false),
  withState('isNotFound', 'setIsNotFound', false),
  withState('isUnauthorizer', 'setIsUnauthorizer', false),
  withState('currentComment', 'setCurrentComment', ''),
  withState('isExpandHistory', 'setIsExpandHistory', false),
  withState('isExpandComments', 'setIsExpandComments', true),
  withHandlers({
    onAppChanged: props => id => {},
    handleClickOpen: (props) => async () => {
      props.setOpen(true)
    },
    handleClose: (props) => async () => {
      props.setOpen(false)
    },
    updateSupportTicket: (props) => async () => {
      props.setOpen(false)
      props.setIsLoadingSave(true);
      showLoading();
      await updateSupportTicket(props)
      props.setIsLoadingSave(false);
    },
    onFieldChange: (props) => (value) => {
      props.setSupportComment(value.trim())
    }
  }),
  lifecycle({
    componentDidMount () {
      _isMounted = true
      tenant = this.props.match.params.tenantId
      ticketId = this.props.match.params.ticketId
      showLoading(this.props.setPageIsLoading)
      getUserRole(this.props)
      getTicketDetails(this.props, tenant, ticketId)
    },
    componentWillUnmount () {
      _isMounted = false
    }
  })
)(SupportTicketsDetailsView)
