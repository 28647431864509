import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import Slide from '@material-ui/core/Slide'
import { withStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const StyledLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#f15f22'
  },
  barColorPrimary: {
    backgroundColor: '#fdba74'
  }
})(LinearProgress)

const useStylesBtn = makeStyles(theme => ({
  btnBgColorOrange: {
    backgroundColor: '#f15f22',
    '&:hover': {
      backgroundColor: '#ea580c'
    }
  }
}))

export default function InfoModal(props) {
  const classes = useStyles()
  const classesBtn = useStylesBtn()
  const {
    showModal,
    onClose,
    onSubmit,
    warningMessage,
    title,
    textSuccessBtn,
    successBtn = true,
    fullWidth = false,
    maxWidth = 'false',
    transitionComponent = Transition,
    isLoading,
    fullWidthBtn = false,
    variantBtn,
    isRelishPrimaryColor
  } = props

  function onSuccess() {
    onSubmit()
    onClose(true)
  }

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      open={showModal}
      TransitionComponent={transitionComponent}
      keepMounted
      onClose={() => onClose()}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
    >
      <DialogTitle
        id="notice-modal-slide-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
        {warningMessage}
        {isLoading ? <StyledLinearProgress /> : null}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        {successBtn ? (
          <Button onClick={() => onSuccess()} color="primary" variant="outlined">
            {textSuccessBtn}
          </Button>
        ) : null}
        <Button
          variant={variantBtn}
          onClick={() => onClose()}
          color="secondary"
          fullWidth={fullWidthBtn}
          className={isRelishPrimaryColor ? classesBtn.btnBgColorOrange : null}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
