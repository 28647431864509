import React from 'react'
import { useParams } from 'react-router'
import { invoiceProcessType } from 'utils/Constants'

import { CircularProgress } from '@material-ui/core'
import RedirectUI, { getPathNewUi } from 'guards/RedirectUI'
import { useGetInvoice } from 'hooks/useInvoices'
import InvoiceAIOcrDiff from 'views/OcrInvoiceExceptions/Diff/InvoiceAIOcrDiff'
import InvoiceAIDiff from './Diff/InvoiceAIDiff'

export default function Difference() {
  const { invoiceId } = useParams()

  const invoiceFetch = useGetInvoice({
    invoiceId,
    options: { refetchOnWindowFocus: false }
  })

  if (invoiceFetch.isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    )
  }

  if (invoiceFetch.isError) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Something went wrong, try again later</p>
      </div>
    )
  }

  const isInvoiceOcr =
    invoiceFetch.data.subProcess === invoiceProcessType.ocr ||
    invoiceFetch.data.process === invoiceProcessType.ocr

  const path = getPathNewUi(`/platform/invoiceAI/compare/${invoiceId}`)

  return (
    <>
      {isInvoiceOcr ? (
        <RedirectUI path={path}>
          <InvoiceAIOcrDiff invoiceFetch={invoiceFetch} />
        </RedirectUI>
      ) : (
        <InvoiceAIDiff invoiceFetch={invoiceFetch} />
      )}
    </>
  )
}
