import React from 'react'
// Router
import { useParams } from 'react-router'

// Constants
import { entities, insightsDeluxeTitles } from 'utils/Constants'

import InvoiceTransaction from '../../../components/Configuration/addTransaction/InvoiceTransaction'

// Icons

import CheckIcon from '@material-ui/icons/Check'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AddIcon from '@material-ui/icons/PlaylistAdd'
import { useGetAppConfigurationById } from 'hooks/useApp'

export default function AddWorkdayTransaction() {
  const { group, appId, transactionId } = useParams()
  const app = useGetAppConfigurationById(appId)
  const breadcrumbViews = [
    {
      name: 'Invoice AI',
      url: '/admin/invoiceAI/configurations',
      icon: AssessmentIcon
    },
    {
      name: insightsDeluxeTitles[group] + ' Connection',
      url: `/admin/invoiceAI/workday/${group}/${appId}`,
      icon: CheckIcon
    },
    {
      name: 'Transaction',
      url: `/admin/invoiceAI/workday/transaction/${group}/${appId}/${transactionId}`,
      icon: AddIcon
    }
  ]

  let dataEntities =
    entities[group]?.map(entity => {
      return {
        id: entity.entity,
        entity: entity.entity,
        group: group
      }
    }) ?? []

  if (group === 'raasApi' && !app.isLoading && app.data?.data) {
    const raasApiEntities =
      app.data?.data.params?.workdayAdditionalParams?.accounting?.workTags?.map(x => {
        return {
          id: x.customReportName,
          entity: x.customReportName,
          group: group
        }
      }) ?? []
    dataEntities.push(...raasApiEntities)
  }

  return (
    <InvoiceTransaction
      transactionId={transactionId}
      group={group}
      appId={appId}
      path={{ main: `/admin/invoiceAI/workday/${group}/${appId}` }}
      breadcrumbViews={breadcrumbViews}
      entities={dataEntities}
      app={app}
    />
  )
}
