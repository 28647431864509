import { CircularProgress } from '@material-ui/core'

import { useGetInvoice } from 'hooks/useInvoices'

import React from 'react'

import { useParams } from 'react-router'

import RedirectUI, { getPathNewUi } from 'guards/RedirectUI'
import { useGetInvoiceProcessByTenant } from 'hooks/useTenantConfig'
import { invoiceProcessType } from 'utils/Constants'
import ExceptionViewOcr from 'views/OcrInvoiceExceptions/ExceptionViewOcr'
import ExceptionView from './ExceptionView'

export default function InvoiceException() {
  const { invoiceId } = useParams()

  const invoiceFetch = useGetInvoice({
    invoiceId,
    options: { refetchOnWindowFocus: false }
  })
  const processList = useGetInvoiceProcessByTenant(invoiceFetch.data?.tenantId ?? '')

  if (invoiceFetch.isLoading || processList.isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    )
  }

  if (invoiceFetch.isError) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Something went wrong, try again later</p>
      </div>
    )
  }

  const isInvoiceOcr =
    invoiceFetch.data.subProcess === invoiceProcessType.ocr ||
    invoiceFetch.data.process === invoiceProcessType.ocr

  const path = getPathNewUi(`/platform/invoiceAI/exception/${invoiceId}`)

  return (
    <>
      {isInvoiceOcr ? (
        <RedirectUI path={path}>
          <ExceptionViewOcr invoiceFetch={invoiceFetch} processList={processList.data} />
        </RedirectUI>
      ) : (
        <ExceptionView
          key={invoiceFetch.data.invoiceId}
          invoiceFetch={invoiceFetch}
          isReadOnly={false}
          processList={processList.data}
        />
      )}
    </>
  )
}
