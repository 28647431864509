import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import {
  getAppConfigurationsByApp,
  createAppConfiguration,
  getAppConfigurationByAppAndId,
  getTransactionsByAppId
} from '../../../../services/apiApps'

import DataAssureDnBMissing from './DataAssureDnBMissing'

import { showLoading } from 'utils/functions'
import { hideLoading } from 'utils/functions'

let _isMounted = false

const newAppValidations = {
  service: 'ariba',
  env: '',
  type: 'Test',
  enabled: true,
  displayMode: 'create'
}

async function getAppListError (app) {
  const response = await getTransactionsByAppId('slp', app.env, ['failed'])
  if (response && response.success) {
    return response.data.transactions.length
  }
}

async function getAppList (
  props,
  callback = () => {},
  selectedApp = undefined
) {
  let response = await getAppConfigurationsByApp('slp')

  if (response.success) {
    if (_isMounted) {
      if (response.data && response.data.length > 0) {
        const errorsLength = await response.data.map(async item => {
          let length = await getAppListError(item)
          item.errors = length
          return { ...item }
        })
        const data = await Promise.all(errorsLength)
        props.setAppList(data)
        if (data.length !== 0) {
          // get app details
          if (!selectedApp) selectedApp = data[0].env
          props.setSelectedAppId(selectedApp)
          await getAppDetails(props, selectedApp)
        }
        props.setPageIsLoading(false)
      }
    }
  }
  callback(props.setPageIsLoading)
}
export async function saveAppdetails (
  props,
  appDetails,
  getConstants = true,
  refresh = true
) {
  //Save complete object

  if (appDetails.data && appDetails.data.data) {
    if (!appDetails.data.data.env)
      appDetails.data.data.env = appDetails.data.data.environment
    let validationDetails = appDetails.data.data

    validationDetails.displayMode = 'edit'
    if (_isMounted) {
      props.setValidationsForm(validationDetails)

      props.setFormIsLoading(false)
      if (refresh) props.setRefresh(!props.refresh)
      //setTimeout(function () {}, 500)
    }
  }
}
async function getAppDetails (props, id) {
  props.setFormIsLoading(true)
  props.setSelectedAppId(id)

  let appDetails = await getAppConfigurationByAppAndId('slp', id)

  if (appDetails.success) {
    await saveAppdetails(props, appDetails)
  } else {
    props.setFormIsLoading(false)
  }
}

async function onSubmit (props, event) {
  let successMessage

  let response
  let env
  if (props.displayMode === 'create') {
    let validations = event.validations
    validations.env = validations.env.replace(' ', '_')
    delete validations.mapping
    response = await createAppConfiguration('slp', validations, true)
    successMessage = 'App created successfully'
    env = validations.env
  }

  if (response.success && _isMounted) {
    props.setValidationMessage(successMessage)
    props.setSubmitSuccess(true)

    if (props.displayMode === 'create') {
      //onNewCreateIsCalled(props)
      await getAppList(props, async callback => {
        await appChanged(props, env)
        hideLoading(callback)
      })
    } else hideLoading(props.setPageIsLoading)

    setTimeout(function () {
      if (_isMounted) {
        props.setSubmitSuccess(false)
      }
    }, 5000)
  } else {
    hideLoading(props.setPageIsLoading)
    // Show error message
    if (response) {
      let message = response.message
      if (!message) {
        if (response.errors && typeof response.errors === 'string') {
          message = response.errors
        } else if (
          response.errors &&
          typeof response.errors === 'object' &&
          response.errors.length > 0
        ) {
          message = ''
          response.errors.forEach(error => {
            message += ' ' + error.msg
          })
        } else message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

/*function createRequestBody (event, requestBody) {
  let joinedBody = {
    ...event.validations,
    ...event.connectivity
  }

  // Create external params
  requestBody.env = joinedBody.env
  requestBody.type = joinedBody.type
  requestBody.realm = joinedBody.realm
  requestBody.enabled = joinedBody.enabled
  requestBody.addressValidation.enabled = joinedBody.addressValidationEnabled

  // Add paramters
  let parameters = [
    'AribaApiKey',
    'AribaClientId',
    'AribaIntegrationUser',
    'AribaPasswordAdapter',
    'AribaSecret'
  ]

  let parameter
  for (parameter of parameters) {
    if (joinedBody.hasOwnProperty(parameter)) {
      requestBody.parameters[parameter] = joinedBody[parameter]
    }
  }

  return requestBody
}*/
async function appChanged (props, id) {
  await getAppDetails(props, id)
  hideLoading(props.setPageIsLoading)
}
function onNewCreateIsCalled (props) {
  props.setFormIsLoading(true)
  props.setSelectedAppId(null)

  props.setValidationsForm({ ...newAppValidations })

  setTimeout(function () {
    props.setFormIsLoading(false)
  }, 500)
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name
    }),
    {}
  ),
  withState('appList', 'setAppList', []),
  withState('refresh', 'setRefresh', false),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('selectedApp', 'setSelectedApp', null),

  withState('validationsForm', 'setValidationsForm', { ...newAppValidations }),

  withState('formIsLoading', 'setFormIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withHandlers({
    onAppChanged: props => id => {
      showLoading(props.setPageIsLoading)
      appChanged(props, id)
    },
    onCreateButton: props => () => {
      if (props.displayMode !== 'create') {
        onNewCreateIsCalled(props)
      }
    },
    onAppSaved: props => async event => {
      props.setPageIsLoading(true)
      showLoading(props.setPageIsLoading)
      await onSubmit(props, event)
      // console.log(event)
    },
    onErrors: props => id => {
      props.history.push(`/admin/dataAssure/${id}/transactions`)
    }
  }),
  lifecycle({
    componentDidMount () {
      showLoading(this.props.setPageIsLoading)
      _isMounted = true
      this.props.setPageIsLoading(true)

      getAppList(this.props, hideLoading)
    }
  })
)(DataAssureDnBMissing)
