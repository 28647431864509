import React, { useEffect } from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'

// Form
import { useWatch } from 'react-hook-form'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Inputs
import { TextInput } from '../Inputs/TextInput'
import { SelectInput } from '../Inputs/SelectInput'
import { DatetimeInput } from '../Inputs/DatetimeInput'

// Constants
import { minutesOfHour, hoursOfDay, dayOfMonth, weeksOfYear } from 'utils/Constants'

const useStyles = makeStyles(styles)

export default function ConfigurationView(props) {
  const { control, frequencyId, setFrequencyId } = props
  const classes = useStyles()

  const updateFrequency = useWatch({
    control,
    name: 'frequency',
    defaultValue: frequencyId
  })

  useEffect(() => {
    setFrequencyId(updateFrequency)
  }, [updateFrequency, setFrequencyId])

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <h4 className={classes.infoText}>Configuration</h4>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <h5 className={classes.sectionTitle}>Settings</h5>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent="center" spacing={3}>
          <TextInput name={'name'} control={control} label={'Name'} type={'text'} />
          <SelectInput
            name={'frequency'}
            control={control}
            label={'Frequency'}
            values={[
              { label: 'One Time', key: 'oneTime' },
              { label: 'Minutes', key: 'minutes' },
              { label: 'Hours', key: 'hours' },
              { label: 'Days', key: 'days' },
              { label: 'Weeks', key: 'weeks' },
              { label: 'Months', key: 'months' }
            ]}
          />
          {updateFrequency === 'days' ? (
            <>
              <SelectInput name={'executionDay'} control={control} label={'Every # days'} values={dayOfMonth} />
              <DatetimeInput name={'executionTime'} control={control} label={'Execution Time'} dateFormat={false} timeFormat={true} />
            </>
          ) : null}
          {updateFrequency === 'minutes' ? (
            <SelectInput name={'executionMinute'} control={control} label={'Every # minutes'} values={minutesOfHour} />
          ) : null}
          {updateFrequency === 'hours' ? (
            <SelectInput name={'executionHour'} control={control} label={'Every # hours'} values={hoursOfDay} />
          ) : null}
          {updateFrequency === 'weeks' ? (
            <>
              <SelectInput name={'executionDay'} control={control} label={'Every # weeks'} values={weeksOfYear} />
              <DatetimeInput name={'executionTime'} control={control} label={'Execution Time'} dateFormat={false} timeFormat={true} />
            </>
          ) : null}
          {updateFrequency === 'months' ? (
            <>
              <SelectInput
                name={'executionDay'}
                control={control}
                label={'Every # months'}
                values={[
                  { label: '1', key: '1' },
                  { label: '2', key: '2' },
                  { label: '3', key: '3' },
                  { label: '4', key: '4' },
                  { label: '5', key: '5' },
                  { label: '6', key: '6' },
                  { label: '7', key: '7' },
                  { label: '8', key: '8' },
                  { label: '9', key: '9' },
                  { label: '10', key: '10' },
                  { label: '11', key: '11' },
                  { label: '12', key: '12' }
                ]}
              />
              <DatetimeInput name={'executionTime'} control={control} label={'Execution Time'} dateFormat={false} timeFormat={true} />
            </>
          ) : null}
          <DatetimeInput name={'fromDate'} control={control} label={'From Date'} dateFormat={'MM/DD/YYYY'} timeFormat={false} />
          {updateFrequency === 'oneTime' ? (
            <DatetimeInput name={'toDate'} control={control} label={'To Date'} dateFormat={'MM/DD/YYYY'} timeFormat={false} />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}
