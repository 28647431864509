import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getSchemaRulesFields,
  getValidationRuleFields,
  getValidationRulesUseQuery,
  getValidationRuleUseQuery,
  saveValidationRuleUseQuery
} from 'services/apiInvoices'

export function useGetValidationRules(app, type, pagination, process) {
  const queryClient = useQueryClient()
  const { lastEvaluatedKeys, page, rowsPage } = pagination
  const lastEvaluatedKey = page === 0 ? null : lastEvaluatedKeys[page - 1]

  const useQueryResult = useQuery(
    ['validationRules', app, type, page, rowsPage, process],
    () =>
      getValidationRulesUseQuery(app, type, lastEvaluatedKey, rowsPage, process).then(result => {
        const { validationsRules, lastEvaluatedKey } = result.data
        return { validationsRules, lastEvaluatedKey: lastEvaluatedKey ?? null }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!app,
      refetchInterval: 60000,
      refetchIntervalInBackground: true
    }
  )

  useEffect(() => {
    if (useQueryResult?.data?.lastEvaluatedKey) {
      queryClient.prefetchQuery(['validationRules', app, type, page + 1, rowsPage, process], () =>
        getValidationRulesUseQuery(
          app,
          type,
          useQueryResult?.data?.lastEvaluatedKey,
          rowsPage,
          process
        ).then(result => {
          const { validationsRules, lastEvaluatedKey } = result.data
          return { validationsRules, lastEvaluatedKey: lastEvaluatedKey ?? null }
        })
      )
    }
  }, [useQueryResult.data, page, rowsPage, queryClient, app, type, process])

  return useQueryResult
}

export function useGetValidationRule(app, type, id) {
  return useQuery(
    ['validationRules', app, type, id],
    () => getValidationRuleUseQuery(app, type, id).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      enabled: !!app
    }
  )
}

export function useGetValidationRuleFields(appId, process) {
  return useQuery(
    ['validationRules', 'apps', appId, 'processes', process],
    () =>
      getValidationRuleFields(appId, process).then(result =>
        result.data.reduce(
          // eslint-disable-next-line
          (acc, curr) => ((acc[`${curr.level}#${curr.standardField}`] = curr), acc),
          {}
        )
      ),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(appId) && Boolean(process)
    }
  )
}

export function useGetSchemaRuleFields(appId, process) {
  return useQuery(
    ['Rules', 'schema', 'apps', appId, 'processes', process],
    () => getSchemaRulesFields(appId, process).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(appId) && Boolean(process)
    }
  )
}

export function useSaveValidationRule(app, type, id) {
  return useMutation(body => saveValidationRuleUseQuery(app, type, id, body))
}
