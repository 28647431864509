import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import { Badge, Grid, IconButton, TextField, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'
import Autocomplete from '@material-ui/lab/Autocomplete'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import { checkErrorOrWarning } from 'utils/functions'

// Form Validation
import { Controller, useWatch } from 'react-hook-form'

import { CircularProgress } from '@material-ui/core'
import { useGetBuyerAddress } from 'hooks/useMasterData'
import { HeaderFieldsSide } from 'utils/Constants'
import { generateJsonValidation } from 'utils/validator'

const useStyles = makeStyles(styles)

const AddressSearch = props => {
  const {
    control,
    summaryField,
    index,
    rules,
    onChangeSummaryField,
    setTextInputRef,
    label,
    validationsError,
    setValidationErrorsState,
    validationErrorsState,
    getValues,
    setValueForm,
    appId
  } = props
  let validationErrorMessages = validationErrorsState[summaryField.type]?.messages ?? ''
  let validated = validationErrorsState[summaryField.type]?.valid ?? null
  const [isValid, setIsValid] = useState(false)
  const [initialErrorMessages] = useState(validationsError)
  const [updatedValue, setUpdatedValue] = useState(summaryField.value)
  const [initialValue] = useState(summaryField.value)

  const [typeMsg, setTypeMsg] = useState(checkErrorOrWarning(validationErrorMessages, validated))
  const [typeMsgFirst, setTypeMsgFirst] = useState(checkErrorOrWarning(validationsError))
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const field = summaryField.type

  const fieldType = field === HeaderFieldsSide.RECEIVER_ADDRESS.type ? 'shipTo' : 'billTo'

  const value = useWatch({
    name: 'summaryFields',
    control
  })

  const companyCode = Object.values(value).find(field => field.type === 'COMPANY_CODE')
  const addrId = Object.values(value).find(field => field.type === `${summaryField.type}_ID`)?.value

  const address = useGetBuyerAddress({
    companyCode: companyCode?.value ?? '',
    params: {
      type: fieldType,
      status: 'enabled',
      appId
    }
  })

  useEffect(() => {
    if (addrId && address.data && address.data.length > 0) {
      const addressGotten = address.data.find(x => x.companyAddressId === addrId)

      const summaryFields = getValues('summaryFields')

      const indexField = summaryFields.findIndex(x => summaryField.type === x.type)

      if (indexField > 0 && addressGotten) {
        setValueForm(
          `summaryFields.${indexField}.value`,
          {
            fullAddress: addressGotten.fullAddress,
            companyAddressId: addressGotten.companyAddressId
          },
          {
            shouldValidate: true,
            shouldDirty: true
          }
        )
      }
    }
  }, [addrId, address.data, getValues, setValueForm, summaryField.type])

  useEffect(() => {
    if (!address.data || address.data?.length !== 1) {
      return
    }

    const summaryFields = getValues('summaryFields')

    let indexField = -1
    let indexFieldId = -1
    if (fieldType === 'billTo') {
      indexField = summaryFields.findIndex(
        summaryField => HeaderFieldsSide.BILL_TO.type === summaryField.type
      )
      indexFieldId = summaryFields.findIndex(
        summaryField => `${HeaderFieldsSide.BILL_TO.type}_ID` === summaryField.type
      )
    } else {
      indexField = summaryFields.findIndex(
        summaryField => HeaderFieldsSide.RECEIVER_ADDRESS.type === summaryField.type
      )
      indexFieldId = summaryFields.findIndex(
        summaryField => `${HeaderFieldsSide.RECEIVER_ADDRESS.type}_ID` === summaryField.type
      )
    }
    const companyAddress = address.data[0]

    if (indexField < 0 || indexFieldId < 0) {
      return
    }

    setValueForm(
      `summaryFields.${indexField}.value`,
      {
        fullAddress: companyAddress.fullAddress,
        companyAddressId: companyAddress.companyAddressId
      },
      {
        shouldValidate: true,
        shouldDirty: true
      }
    )
    setValueForm(`summaryFields.${indexFieldId}.value`, companyAddress.companyAddressId)
  }, [address.data, fieldType, getValues, setValueForm])

  const onChangeAutoComplete = address => {
    const summaryFields = getValues('summaryFields')
    const index = summaryFields.findIndex(summaryField => summaryField.type === `${field}_ID`)
    if (index < 0) {
      return
    }
    setValueForm(`summaryFields.${index}.value`, address?.companyAddressId ?? '')
  }

  function validationMessages(validationsError) {
    if (!validationsError) {
      return ''
    }

    if (Array.isArray(validationsError)) {
      return (
        <>
          {validationsError.map((message, index) => (
            <div key={index}>{message.message}</div>
          ))}
        </>
      )
    }

    const messages = validationsError.split('\n')

    return (
      <>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </>
    )
  }

  function getColors(isDirty, typeMsg, error) {
    let color = ''
    let inputColor = ''
    let badgeColor = ''
    let tooltipColor = ''

    if (typeMsg === 'error') {
      color = classes.errorColor
      inputColor = classes.inputError
      tooltipColor = classes.tooltipError
    }

    if (validated) {
      color = classes.successColor
      inputColor = classes.inputSuccess
    }

    if (isValid && !Boolean(error)) {
      color = classes.successColor
      inputColor = classes.inputSuccess
    }

    if (isDirty && !Boolean(error)) {
      color = classes.successColor
      inputColor = classes.inputSuccess
    }

    if (!isDirty && typeMsg === 'warning') {
      color = classes.warningColor
      inputColor = classes.inputWarning
      tooltipColor = classes.tooltipWarning
    }

    return { color, inputColor, badgeColor, tooltipColor }
  }

  const changeValidationToSuccess = useCallback(() => {
    setTypeMsg('')
    setIsValid(true)
    setValidationErrorsState(old => {
      const previousValidation = old[summaryField.type] ?? {}
      return {
        ...old,
        [summaryField.type]: {
          ...previousValidation,
          messages: [{ type: 'success', message: 'Accepted by User' }],
          valid: true
        }
      }
    })
  }, [setValidationErrorsState, summaryField.type])

  const changeValidationToWarning = useCallback(
    initialErrorMessages => {
      setTypeMsg('warning')
      setTypeMsgFirst('warning')
      setIsValid(false)
      setValidationErrorsState(old => {
        const previousValidation = old[summaryField.type] ?? {}
        return {
          ...old,
          [summaryField.type]: {
            ruleId: `header#${summaryField.type}`,
            ...previousValidation,
            messages: initialErrorMessages,
            valid: null
          }
        }
      })
    },
    [setValidationErrorsState, summaryField.type]
  )

  const changeValidationToError = useCallback(
    initialErrorMessages => {
      setTypeMsg('error')
      setTypeMsgFirst('error')
      setIsValid(false)
      setValidationErrorsState(old => {
        const previousValidation = old[summaryField.type] ?? {}
        return {
          ...old,
          [summaryField.type]: {
            ruleId: `header#${summaryField.type}`,
            ...previousValidation,
            messages: initialErrorMessages,
            valid: null
          }
        }
      })
    },
    [setValidationErrorsState, summaryField.type]
  )

  useEffect(() => {
    if (!_.isEqual(initialValue, updatedValue)) {
      if (typeMsgFirst !== '') {
        changeValidationToSuccess()
      }
    } else {
      if (typeMsgFirst === 'error') {
        changeValidationToError(initialErrorMessages)
      } else if (typeMsgFirst === 'warning') {
        if (isValid || validated) {
          changeValidationToSuccess()
        } else {
          changeValidationToWarning(initialErrorMessages)
        }
      }
    }
  }, [
    updatedValue,
    changeValidationToError,
    changeValidationToWarning,
    changeValidationToSuccess,
    initialValue,
    isValid,
    typeMsgFirst,
    initialErrorMessages,
    validated
  ])

  const buyerAddrRules = generateJsonValidation(rules, summaryField.type)

  return (
    <Controller
      render={({ field, fieldState: { error, isDirty } }) => {
        const isWarning = !isDirty && typeMsg === 'warning' && !Boolean(error)
        const isVisible =
          (Boolean(error) && typeMsg === 'error') || (!isDirty && typeMsg === 'error') || isWarning

        setUpdatedValue(field.value)

        return (
          <Grid container justifyContent="center">
            <Grid item xs={typeMsgFirst === 'warning' && !Boolean(error) && !isDirty ? 11 : 12}>
              <Tooltip
                classes={{
                  arrow: isWarning ? classes.warningColor : classes.errorColor,
                  tooltip: isWarning ? classes.tooltipWarning : classes.tooltipError
                }}
                title={isVisible ? validationMessages(validationErrorMessages) : ''}
                arrow
                placement="right"
              >
                <Badge
                  style={{ width: '95%' }}
                  classes={{
                    badge: isWarning ? classes.badgeWarning : classes.badgeError
                  }}
                  badgeContent={
                    isWarning ? <WarningIcon className={classes.warningColor}></WarningIcon> : '!'
                  }
                  invisible={isVisible ? false : true}
                >
                  <Autocomplete
                    {...field}
                    style={{ width: '100%' }}
                    open={open}
                    onOpen={() => {
                      setOpen(true)
                    }}
                    onClose={() => {
                      setOpen(false)
                    }}
                    options={address.data ?? []}
                    classes={{
                      option: classes.option
                    }}
                    getOptionSelected={(option, value) =>
                      option?.fullAddress === value?.fullAddress
                    }
                    getOptionLabel={option => option.fullAddress ?? ''}
                    renderOption={option => (
                      <>
                        ({option?.companyAddressId}) {option?.fullAddress}
                      </>
                    )}
                    loading={address.isLoading}
                    renderInput={params => (
                      <TextField
                        {...params}
                        id={summaryField.id}
                        label={label}
                        variant="outlined"
                        fullWidth
                        multiline
                        error={Boolean(error)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {address.isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                          classes: {
                            notchedOutline: !Boolean(error)
                              ? getColors(isDirty, typeMsg, error, field.value).inputColor
                              : ''
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: !Boolean(error)
                              ? getColors(isDirty, typeMsg, error, field.value).color
                              : '',
                            focused: !Boolean(error)
                              ? getColors(isDirty, typeMsg, error, field.value).color
                              : ''
                          }
                        }}
                        onClick={e =>
                          onChangeSummaryField({
                            id: summaryField.id,
                            type: 'header',
                            ocr: null,
                            shouldSetValue: false,
                            page: value?.[index]?.page
                          })
                        }
                        inputRef={element => setTextInputRef(element, summaryField.id)}
                      />
                    )}
                    onChange={(_, data) => {
                      field.onChange(data)
                      onChangeAutoComplete(data)
                    }}
                  />
                </Badge>
              </Tooltip>
            </Grid>
            {typeMsgFirst === 'warning' && !Boolean(error) && !isDirty ? (
              <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                  style={{ padding: '0' }}
                  className={classes.buttonSuccess}
                  onClick={() =>
                    isValid || validated
                      ? changeValidationToWarning(initialErrorMessages)
                      : changeValidationToSuccess()
                  }
                >
                  {isValid || validated ? (
                    <CheckCircleIcon className={classes.successColor} />
                  ) : (
                    <CheckCircleOutlineIcon className={classes.successColor} />
                  )}
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
        )
      }}
      name={`summaryFields.${index}.value`}
      control={control}
      rules={{
        validate: {
          required: value => {
            if (!buyerAddrRules.required?.value) {
              return true
            }

            return value?.fullAddress ? true : buyerAddrRules.required.message
          }
        }
      }}
    />
  )
}

export default AddressSearch
