import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'

import { useGetBusinessRules } from 'hooks/useBusinessRule'
import React from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function RuleSelectModal(props) {
  const { label, onClose, title, onApply, appId } = props

  const rules = useGetBusinessRules({
    filters: {
      status: ['enabled'],
      appId
    }
  })
  const rulesData = rules.data ?? []
  const [ids, setIds] = React.useState([])

  const submit = () => {
    onApply(ids)
    onClose()
  }

  const onCloseHandler = () => {
    onClose()
  }

  const onChangeHandler = (uuid, checked) => {
    if (checked) {
      setIds(current => [...current, uuid])
    } else {
      setIds(current => current.filter(x => x !== uuid))
    }
  }

  const onSelectAllHandler = () => {
    setIds(rulesData.map(x => x.uuid))
  }

  return (
    <Dialog
      open={props.open}
      maxWidth="sm"
      fullWidth={true}
      TransitionComponent={Transition}
      onClose={() => onClose()}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>

      <Divider />

      <DialogContent>
        {rules.isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h4">All rules</Typography>
              </Grid>
              <Grid item xs={6} textAlign={'right'}>
                <Button variant="text" color="primary" size="large" onClick={onSelectAllHandler}>
                  Select all
                </Button>
              </Grid>
            </Grid>
            {rulesData.map(x => (
              <Grid item xs={12} key={x.uuid}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ids.some(y => x.uuid === y) ?? false}
                      id={x.uuid}
                      onChange={x => onChangeHandler(x.target.id, x.target.checked)}
                    />
                  }
                  label={x.name}
                />
              </Grid>
            ))}
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" size="large" onClick={onCloseHandler}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={ids.length === 0}
          variant="contained"
          size="large"
          onClick={() => submit()}
        >
          {label}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RuleSelectModal
