import React from 'react'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

moment.locale(window.navigator.userLanguage || window.navigator.language)
const useStyles = makeStyles(styles)

function DatePickerField(props) {
  const { summaryField, label, value } = props
  const classes = useStyles()

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <KeyboardDatePicker
        style={{ width: '100%' }}
        variant="inline"
        inputVariant="outlined"
        id={summaryField.id}
        label={label ?? ''}
        format="YYYY-MM-DD"
        value={value}
        disabled={true}
        className={classes.readOnly}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        readOnly
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePickerField
