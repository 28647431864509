import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles(styles)
function typeArray(key = 'error') {
  let type = ''
  switch (key) {
    case 'error':
      type = (
        <div style={{ color: 'red', display: 'flex', alignItems: 'center' }}>
          <PriorityHighIcon style={{ fontSize: 20 }} />
          Error
        </div>
      )
      break
    case 'warning':
      type = (
        <div style={{ color: '#f3bc07', display: 'flex', alignItems: 'center' }}>
          <WarningIcon style={{ fontSize: 20 }} />
          Warning
        </div>
      )
      break
    default:
      break
  }
  return type
}

export function ValidationsModal(props) {
  const classes = useStyles()
  const { showModal, modalInfo, onClose, type } = props

  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot
      }}
      open={showModal}
      fullWidth={true}
      maxWidth={'md'}
      onClose={() => onClose()}
    >
      <DialogTitle>Validation Details</DialogTitle>
      <DialogContent className={classes.modalBody}>
        {modalInfo.length > 0 ? (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Section</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modalInfo.map((info, i) =>
                Array.isArray(info.messages) ? (
                  info.messages.map((message, j) => (
                    <TableRow key={j}>
                      <TableCell>{typeArray(type)}</TableCell>
                      <TableCell>{message.message}</TableCell>
                      <TableCell>{info.section}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow key={i}>
                    <TableCell>{typeArray(type)}</TableCell>
                    <TableCell>{info.messages}</TableCell>
                    <TableCell>{info.section}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        ) : null}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => onClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
