import React from 'react'

import AutoCompleteAsyncHeader from 'components/Invoice/AutoCompleteAsyncHeader'
import { useGetAppList } from 'hooks/useApp'
import { useWatch } from 'react-hook-form'
const AutoCompleteAppList = props => {
  const { appId, defaultAppId, index, control } = props

  const appList = useGetAppList('invoiceAI')

  const value = useWatch({
    name: `summaryFields.${index}.value`,
    control
  })

  let options = appList.data?.map(x => ({ label: x.appName, value: x.env })) ?? []
  options = defaultAppId !== appId ? options.filter(x => x.value !== defaultAppId) : options

  const app = appList.data?.find(x => x.env === value)

  return (
    <AutoCompleteAsyncHeader
      {...props}
      filter={app?.appName ?? ''}
      isLoading={appList.isLoading || appList.isFetching}
      options={options}
      page={'1'}
      isOnlyLabel={true}
    />
  )
}

export default AutoCompleteAppList
