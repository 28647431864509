import { useMutation, useQuery } from 'react-query'
import {
  getIgnoreRulePreCreationInfo,
  ignoreDocument,
  processIgnoredDocument
} from 'services/apiInvoices'

export function useGetIgnoreRulePreCreationInfo(appId, documentId, options = {}) {
  return useQuery(
    ['apps', appId, 'ignoreRulePreCreationInfo', documentId],
    () =>
      getIgnoreRulePreCreationInfo({
        appId,
        documentId
      }).then(result => {
        return result.data
      }),
    {
      enabled: Boolean(appId),
      ...options
    }
  )
}

export function useIgnoreDocument(appId, documentId, { onSettled }) {
  return useMutation(body => ignoreDocument({ appId, documentId, body }), {
    onSettled
  })
}

export function useProcessIgnoredDocument(appId, documentId, { onSettled }) {
  return useMutation(() => processIgnoredDocument({ appId, documentId }), {
    onSettled
  })
}
