import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from 'assets/jss/material-dashboard-pro-react/components/emailModalStyle'
import { makeStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'

const useStyles = makeStyles(styles)

export function SendToAnotherInboxModal(props) {
  const classes = useStyles()
  const { onClose, open, sendToAnotherInbox, email } = props

  const submit = () => {
    sendToAnotherInbox(emails.join(','))
    handleClose()
  }

  const [emails, setEmails] = useState([])
  const [emailOptions, setEmailOptions] = useState([])

  useEffect(() => {
    let newEmail = ''
    let newEmailOptions = [email]
    if (email) {
      newEmail = email.replace(/' '/g, '')
      if (newEmail.includes('\n')) {
        newEmailOptions = newEmail.split('\n')
      } else if (newEmail.includes(',')) {
        newEmailOptions = newEmail.split(',')
      }
      newEmailOptions = newEmailOptions.filter(x => x !== '')
      setEmailOptions(newEmailOptions)
      setEmails([newEmailOptions[0]])
    }
  }, [email])

  const handleClose = () => {
    setEmails([emailOptions[0]])
    onClose()
  }

  const handleChange = event => {
    const {
      target: { value }
    } = event
    setEmails(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot
      }}
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Forward Selected Invoices</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <p>Select the email addresses to which the invoices should be forwarded:</p>
        <FormControl variant="outlined">
          <InputLabel htmlFor="emails-label">Emails</InputLabel>
          <Select
            inputProps={{
              name: 'emails',
              id: 'emails-label'
            }}
            label="Emails"
            multiple
            value={emails}
            onChange={event => handleChange(event)}
          >
            {emailOptions.map((email, i) => (
              <MenuItem key={i} value={email}>
                {email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <p>** This will change the status of these invoices to "Completed".</p>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.colorPrimary}
          color="primary"
          variant="outlined"
          onClick={() => submit()}
          disabled={emails.length === 0}
        >
          Send
        </Button>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SendToAnotherInboxModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  sendToAnotherInbox: PropTypes.func.isRequired
}
