import React from 'react'

// Form Validation
import { useWatch } from 'react-hook-form'

import { useGetSupplierAddresses } from 'hooks/useMasterData'
import { HeaderFieldsStandard } from 'utils/Constants'

import AutoCompleteAsyncHeader from './AutoCompleteAsyncHeader'

const SupplierAddressSelect = props => {
  const { control, index, headerField, headerFieldsActions, appId, getValues, setValueForm } = props
  const { deleteErrorHeader } = headerFieldsActions

  const value = useWatch({
    name: 'headerFields',
    control
  })

  const supplier = value.find(field => field.field === HeaderFieldsStandard['supplier.id'].type)

  const supplierId = supplier?.value

  const typeAddress =
    HeaderFieldsStandard['supplier.remitToAddress.fullAddress'].type === headerField.field
      ? 'remitToLocation'
      : 'location'

  const addresses = useGetSupplierAddresses({
    supplierId,
    params: {
      status: 'enabled',
      type: typeAddress,
      appId
    }
  })

  const options =
    addresses.data?.map(x => ({
      label: x.supplierAddressId,
      value: x.supplierAddressFullAddress
    })) ?? []

  const onChangeAutoComplete = address => {
    const summaryFields = getValues('headerFields')
    const index = summaryFields.findIndex(
      summaryField => summaryField.field === HeaderFieldsStandard['supplier.id'].type
    )

    if (index < 0) {
      return
    }

    if (address) {
      setValueForm(`headerFields.${index}.value`, address.label, {
        shouldValidate: true,
        shouldDirty: true
      })
    } else {
      setValueForm(`headerFields.${index}.value`, '', {
        shouldValidate: true,
        shouldDirty: true
      })
    }
    deleteErrorHeader({ field: headerField.field })
  }

  return (
    <AutoCompleteAsyncHeader
      {...props}
      options={options}
      isLoading={addresses.isLoading || addresses.isFetching}
      customOptionLabel={option => option.label || option}
      customName={`headerFields.${index}.address`}
      onChangeAutoComplete={onChangeAutoComplete}
    />
  )
}

export default SupplierAddressSelect
