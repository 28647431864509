import React, { useState, useEffect } from 'react'
import moment from 'moment'
import MaterialTable from 'material-table'

// Router
import { useHistory } from 'react-router'

// Material UI
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import TablePagination from '@material-ui/core/TablePagination'

// Icons
import AddAlert from '@material-ui/icons/AddAlert'
import Add from '@material-ui/icons/PlaylistAdd'
import AssessmentIcon from '@material-ui/icons/Assessment'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'

// Components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import { TablePaginationActions } from 'components/Invoice/TablePaginationActions'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Hooks
import { useGetPurchaseOrders } from 'hooks/useMasterData'
import { useGetTenantConfig } from 'hooks/useTenantConfig.js'

const useStyles = makeStyles(styles)

export default function MasterDataPOView() {
  const classes = useStyles()
  const history = useHistory()
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPage: 10,
    lastPage: -1,
    lastEvaluatedKeys: {}
  })
  const [submitSuccess, setSubmitSuccess] = useState({
    isOpen: false,
    message: ''
  })
  const [createError, setCreateError] = useState({
    message: '',
    isOpen: false,
    color: 'danger'
  })
  const { data: configTenant, isLoading: isLoadingTenantConfig } = useGetTenantConfig()

  const appId = configTenant?.defaultApp ?? ''

  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: true
    },
    {
      field: 'poNumber',
      title: 'P.O. Number'
    },
    {
      field: 'dateCreated',
      title: 'P.O. Date',
      render: rowData => moment(rowData.dateCreated).format('MM/DD/YYYY')
    },
    {
      field: 'dateCreatedEpoch',
      title: 'Date Created',
      render: rowData => moment(rowData.dateCreatedEpoch).format('MM/DD/YYYY HH:mm')
    },
    {
      field: 'requester',
      title: 'Requester'
    },
    {
      field: 'approver',
      title: 'Approver'
    },
    {
      field: 'supplierId',
      title: 'Vendor Id'
    }
  ]

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Edit Purchase Order',
      onClick: (event, rowData) =>
        history.push(`/admin/invoiceAI/masterData/purchaseOrder/${rowData.id}`)
    }
  ]

  let breadcrumbViews = [
    {
      name: 'Purchase Orders',
      url: '/admin/invoiceAI/masterData/purchaseOrder/index',
      icon: AssessmentIcon
    }
  ]

  const handleChangePage = (event, newPage) => {
    setPagination(old => {
      return {
        ...old,
        page: newPage
      }
    })
  }

  const { data, isLoading, error, isError, refetch, isFetching } = useGetPurchaseOrders({
    pagination,
    appId: appId,
    options: { refetchOnWindowFocus: false, enabled: !!appId }
  })

  useEffect(() => {
    if (!isError) {
      return
    }
    setCreateError({
      isOpen: true,
      message: error.response?.data?.message || 'Something went wrong, try again later'
    })
  }, [isError, error])

  useEffect(() => {
    if (!data) {
      return
    }

    if (!data?.lastEvaluatedKey) {
      setPagination(old => {
        return {
          ...old,
          lastPage: old.page
        }
      })
      return
    }

    setPagination(old => {
      return {
        ...old,
        lastEvaluatedKeys: {
          ...old.lastEvaluatedKeys,
          [old.page]: data?.lastEvaluatedKey
        }
      }
    })
  }, [data])

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <Breadcrumbs views={breadcrumbViews} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Card>
          <CardContent>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={12}>
                <h5 className={classes.sectionTitle}>
                  Purchase Orders
                  <Tooltip title="Add Purchase Order">
                    <IconButton
                      color="primary"
                      component="span"
                      size="small"
                      className={classes.marginLeft}
                      onClick={() => history.push(`/admin/invoiceAI/masterData/purchaseOrder/`)}
                    >
                      <Add fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  {!isLoading ? (
                    <Fab
                      size="small"
                      color="primary"
                      style={{ marginBottom: '10px' }}
                      className={classes.floatRight + ' ' + classes.bgColorPrimary}
                      onClick={() => refetch({ throwOnError: false })}
                    >
                      <RefreshRoundedIcon fontSize="small" />
                    </Fab>
                  ) : null}
                </h5>
              </Grid>
              <Grid item xs={12} sm={12}>
                {isLoading || isFetching || isLoadingTenantConfig ? (
                  <Grid container justifyContent="center">
                    <div className={classes.circularProgress}>
                      <CircularProgress color="inherit" />
                    </div>
                  </Grid>
                ) : (
                  <Grid container justifyContent="center">
                    <Grid item xs={12} sm={12} md={12}>
                      <MaterialTable
                        columns={columns}
                        actions={actions}
                        data={data?.rows ?? []}
                        options={{
                          actionsColumnIndex: -1,
                          sorting: true,
                          showTitle: false,
                          emptyRowsWhenPaging: false,
                          pageSize: pagination.rowsPage,
                          pageSizeOptions: [10, 20, 30, 40, 50]
                        }}
                        onChangeRowsPerPage={pageSize => {
                          setPagination({
                            page: 0,
                            rowsPage: pageSize,
                            lastEvaluatedKeys: []
                          })
                        }}
                        components={{
                          Pagination: props => {
                            return (
                              <TablePagination
                                {...props}
                                onChangePage={handleChangePage}
                                ActionsComponent={subProps => (
                                  <TablePaginationActions {...subProps} pagination={pagination} />
                                )}
                              />
                            )
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Snackbar
        place="br"
        color="success"
        icon={AddAlert}
        message={submitSuccess.message}
        open={submitSuccess.isOpen}
        closeNotification={() =>
          setSubmitSuccess({
            isOpen: false,
            message: ''
          })
        }
        close
      />
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() =>
          setCreateError({
            isOpen: false,
            message: ''
          })
        }
        close
      />
    </Grid>
  )
}
