import RedirectUI, { getPathNewUi } from 'guards/RedirectUI'
import React from 'react'
import { useParams } from 'react-router'
import InvoiceDetailsView from './InvoiceDetailsView'
export default function InvoiceDetails(props) {
  const { invoiceId } = useParams()
  const path = getPathNewUi(`/platform/invoiceAI/details/${invoiceId}`)
  return (
    <RedirectUI path={path}>
      <InvoiceDetailsView {...props} />
    </RedirectUI>
  )
}
