import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles(styles)
function typeArray (key) {
  let type = ''
  switch (key) {
    case 'successArray':
      type = 'Success'
      break
    case 'errorArray':
      type = (
        <div style={{ color: 'red', display: 'flex', alignItems: 'center' }}>
          <PriorityHighIcon style={{ fontSize: 20 }} />
          Error
        </div>
      )
      break
    case 'warningArray':
      type = (
        <div style={{ color: 'orange', display: 'flex', alignItems: 'center' }}>
          <WarningIcon style={{ fontSize: 20 }} />
          Warning
        </div>
      )
      break
    default:
      break
  }
  return type
}
const keys = ['errorArray', 'warningArray', 'successArray']
export function ValidationsResponseModal (props) {
  const classes = useStyles()
  const { showModal, modalInfo, onClose } = props

  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot
      }}
      open={showModal}
      fullWidth={true}
      maxWidth={'md'}
      onClose={() => onClose()}
    >
      <DialogTitle>Validation Details</DialogTitle>
      <DialogContent className={classes.modalBody}>
        {typeof modalInfo === 'object' && Object.keys(modalInfo).length > 0 ? (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Message Details</TableCell>
                <TableCell>Section</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keys.map(key =>
                modalInfo[key].map((item, j) =>
                  item.details && item.details.length > 0 ? (
                    item.details.map((info, i) => (
                      <TableRow key={j}>
                        <TableCell>
                          {info.message
                            ? info.message.replace(/\/n/g, ' ')
                            : ''}
                        </TableCell>
                        <TableCell>{typeArray(key)}</TableCell>
                        <TableCell>
                          {info.messageDetails
                            ? Array.isArray(info.messageDetails)
                              ? info.messageDetails.map((message, k) => (
                                  <span key={k}>{message.message} </span>
                                ))
                              : info.messageDetails.replace(/\/n/g, ' ')
                            : ''}
                        </TableCell>
                        <TableCell>
                          {info.section
                            ? info.section.replace(/\/n/g, ' ')
                            : ''}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow key={j}>
                      <TableCell>{typeArray(key)}</TableCell>
                      <TableCell>
                        {item.message ? item.message.replace(/\/n/g, ' ') : ''}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )
                )
              )}
            </TableBody>
          </Table>
        ) : null}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => onClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
