import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Heading from "components/Heading/Heading.js";

import file from "assets/files/NitorConnect.pdf";

export default function DocumentationPage() {
    return (
        <div>
            <GridContainer justify="center" >
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <Heading
                                textAlign="center"
                                title="Nitor Connect - Configuration Guide"
                            />
                            <iframe title="Documenation" src={file} width="100%" height="600" />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}