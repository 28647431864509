import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'

import TableRow from '@material-ui/core/TableRow'
import ReactTable from 'react-table'
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js'

import Card from '@material-ui/core/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import Tooltip from '@material-ui/core/Tooltip'
import GridItem from 'components/Grid/GridItem'

import { Button, FormLabel, Hidden } from '@material-ui/core'

import Add from '@material-ui/icons/PlaylistAdd'
import CircularProgress from '@material-ui/core/CircularProgress'

import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'

import SvgIcon from '@material-ui/core/SvgIcon'

class CustomTreeTable2 extends React.Component {
  constructor(props) {
    super(props)
    let state = {}
    const { classes } = this.props
    state.data = this.props.data
    state.classes = classes
    state.columns = this.props.columns
    state.transformedData = []
    state.transformedColumns = []
    state.currentPage = 0
    state.refresh = false
    state.pageSize = this.props.defaultPageSize
    state.dataVersion = this.props.dataVersion
    this.state = state
  }
  hasAccessor(element, columns) {
    return columns.map(val => val.accessor).filter(x => Object.keys(element).includes(x)).length > 0
  }
  hideData(data) {
    if (data && data.length > 0 && this.props.hideKey) {
      const newData = []
      data.forEach(element => {
        if (element[this.props.hideKey] !== this.props.hideValue) {
          newData.push(element)
        }
      })

      return newData
    } else return data
  }
  transformData(tableData, columns) {
    tableData = this.hideData(tableData)
    let transformedData = []
    if (tableData && tableData.length > 0) {
      tableData.forEach(element => {
        let label = ''
        if (this.props.mainAccessor && element[this.props.mainAccessor]) {
          label = element[this.props.mainAccessor]
        }
        if (this.hasAccessor(element, columns)) {
          let row = (
            <TreeView
              defaultCollapseIcon={this.MinusSquare(this.props)}
              defaultExpandIcon={this.PlusSquare(this.props)}
              defaultEndIcon={this.CloseSquare(this.props)}
            >
              <TreeItem nodeId="1" label={label ? label : 'node'}>
                <Table>
                  <TableBody>
                    {this.state.columns.map((id, key) => {
                      return (
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">
                            {id.Header}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <div style={{ display: 'inline-block' }}>{element[id.accessor]}</div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TreeItem>
            </TreeView>
          )
          let rowColumn = {}
          rowColumn[this.props.transformedColumnName] = row
          transformedData.push(rowColumn)
        }
      })
    }

    let newColumn = {}
    newColumn.accessor = this.props.transformedColumnName
    newColumn.Header = this.props.transformedColumnName
    let newState = this.state
    newState.transformedData = transformedData
    newState.transformedColumns = [newColumn]
    newState.columns = columns
    newState.data = tableData
    newState.refresh = !newState.refresh
    newState.dataVersion = this.props.dataVersion

    newState.tableData = tableData
    this.setState(newState)

    this.refreshTable = this.refreshTable.bind(this)
  }
  refreshTable(data, columns) {
    //if (columns) setColumns(this.props.columns)
    if (data) {
      if (columns) this.transformData(data, columns)
      else this.transformData(data, this.props.columns)
    }
  }
  componentDidMount() {
    //if (this.props.refreshData) this.props.refreshData(this.refreshTable)
    this.transformData(this.props.data, this.props.columns)
    //alert()
  }
  componentDidUpdate() {
    //if (this.props.refreshData) this.props.refreshData(this.refreshTable)
    //else {

    if (
      (this.props.data !== this.state.data && this.state.data !== this.state.tableData) ||
      this.props.dataVersion !== this.state.dataVersion
    ) {
      this.transformData(this.props.data, this.props.columns)
    }
    // }
  }

  MinusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    )
  }

  PlusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    )
  }
  CloseSquare(props) {
    return (
      <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
      </SvgIcon>
    )
  }
  getTable() {
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <GridItem xs={12} sm={12} className={this.state.classes.noPadding}>
          <GridItem xs={12} sm={12}>
            <h5 className={this.state.classes.sectionTitle}>{this.props.formTitle}</h5>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <FormLabel className={this.state.classes.contentLabelHorizontal}>
              {this.props.formLabel}
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <Card className={this.state.classes.card}>
              <CardHeader color="info" icon>
                <CardIcon color="info">{this.props.tableIcon}</CardIcon>
                <h4 className={this.state.classes.cardTitle}>{this.props.tableTitle}</h4>
                {this.props.onClickAdd &&
                !this.props.hideAddButton &&
                ((this.props.tableMaxSize && this.state.data.length < this.props.tableMaxSize) ||
                  !this.props.tableMaxSize) ? (
                  <Tooltip title={this.props.addTooltipText ? this.props.addTooltipText : ' '}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={this.state.classes.addButton}
                      onClick={() => {
                        if (this.props.onClickAdd) this.props.onClickAdd()
                      }}
                    >
                      <Add className={this.state.classes.icons} />
                    </Button>
                  </Tooltip>
                ) : (
                  ''
                )}
              </CardHeader>
              {this.state.data.length === 0 && this.props.noDataText ? (
                <div>
                  {this.props.noDataText}
                  <div style={{ height: '25px' }}></div>
                </div>
              ) : (
                <div>
                  <Hidden only={this.props.transformCodes}>
                    <div style={{ paddingTop: '50px' }}>
                      <ReactTable
                        manual
                        page={this.state.currentPage}
                        onPageChange={e => {
                          let newState = this.state
                          newState.currentPage = e
                          this.setState(newState)
                        }}
                        pages={Math.ceil(this.state.data.length / this.state.pageSize)}
                        pageSize={this.state.pageSize}
                        showPagination={
                          this.props.showTablePagination !== undefined
                            ? this.props.showTablePagination
                            : true
                        }
                        showPageJump={false}
                        data={this.state.data.slice(
                          this.state.currentPage * this.state.pageSize,
                          (this.state.currentPage + 1) * this.state.pageSize
                        )}
                        onPageSizeChange={size => {
                          let newPage = this.state.currentPage
                          while (newPage * size > this.state.data.length) {
                            newPage--
                          }
                          let newState = this.state
                          newState.currentPage = newPage
                          newState.pageSize = size
                          this.setState(newState)
                        }}
                        columns={this.state.columns}
                        defaultPageSize={this.props.defaultPageSize}
                        showPaginationTop={this.props.showPaginationTop}
                        minRows={this.props.minRows}
                        showPaginationBottom={this.props.showPaginationBottom}
                        className={this.props.className}
                      />
                    </div>
                  </Hidden>
                  <Hidden
                    only={['xl', 'lg', 'md', 'sm', 'xs'].filter(
                      x => !this.props.transformCodes.includes(x)
                    )}
                  >
                    <div style={{ paddingTop: '50px' }}>
                      <ReactTable
                        manual
                        page={this.state.currentPage}
                        onPageChange={e => {
                          let newState = this.state
                          newState.currentPage = e
                          this.setState(newState)
                        }}
                        pages={Math.ceil(this.state.data.length / this.state.pageSize)}
                        pageSize={this.state.pageSize}
                        showPagination={
                          this.props.showTablePagination !== undefined
                            ? this.props.showTablePagination
                            : true
                        }
                        showPageJump={false}
                        data={this.state.transformedData.slice(
                          this.state.currentPage * this.state.pageSize,
                          (this.state.currentPage + 1) * this.state.pageSize
                        )}
                        onPageSizeChange={size => {
                          let newPage = this.state.currentPage
                          while (newPage * size > this.state.data.length) {
                            newPage--
                          }
                          let newState = this.state
                          newState.currentPage = newPage
                          newState.pageSize = size
                          this.setState(newState)
                        }}
                        columns={this.state.transformedColumns}
                        defaultPageSize={this.props.defaultPageSize}
                        showPaginationTop={this.props.showPaginationTop}
                        minRows={this.props.minRows}
                        showPaginationBottom={this.props.showPaginationBottom}
                        className={this.props.className}
                      />
                    </div>
                  </Hidden>
                </div>
              )}
            </Card>

            <div className={this.state.classes.center} style={{ paddingTop: '15px' }}>
              {this.state.data.length === 0 && this.props.noDataDiv ? this.props.noDataDiv : ''}
              {this.props.isLoadingSave ? (
                <CircularProgress color="inherit" />
              ) : !this.props.hideSaveButton ? (
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={this.props.onSave}
                >
                  Save
                </Button>
              ) : (
                ''
              )}
            </div>
          </GridItem>
        </GridItem>
      </div>
      /*<ResizeObserver>
     
    </ResizeObserver>
    */
    )
  }
  render() {
    return this.getTable()
  }
}
export default withStyles(styles)(CustomTreeTable2)
