import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import Button from '@material-ui/core/Button'
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'
import Slide from '@material-ui/core/Slide'

import CommentsView from './CommentsView'
// import { CircularProgress } from '@material-ui/core'
const useStyles = makeStyles(styles)
/* const useStylesDialog = makeStyles(() => ({
  dialog: {
    height: '100vh'
  }
})) */

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export default function ModalComments(props) {
  const classes = useStyles()
  const {
    showModal,
    onClose,
    invoiceId,
    appId,
    disabled,
    commentsInformation
  } = props
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={showModal}
      TransitionComponent={Transition}
      onClose={() => onClose()}
    >
      <DialogTitle id="notice-modal-slide-title">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          Comments
        </div>
      </DialogTitle>
      <DialogContent id="notice-modal-slide-description">
        <CommentsView
          invoiceId={invoiceId}
          appId={appId}
          disabled={disabled}
          commentsInformation={commentsInformation}
        />
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => onClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalComments.defaultProps = {
  disabled: false
}
