import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { makeStyles } from '@material-ui/core/styles'

// Material UI
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

// Icons
import Add from '@material-ui/icons/PlaylistAdd'

// Components
import MaterialTable from 'material-table'

// Constants
import { ConnectionTypes } from 'utils/Constants'

// Views
import ConnectionsForm from './ConnectionsForm'

// Styles
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle'

// Hooks
import { useDeleteAppConnection } from 'hooks/useInsights'

const useStyles = makeStyles(styles)

const connection = {
  connectionId: '',
  name: '',
  fileExtension: '.json',
  path: '',
  zipFile: false,
  type: 'msSql',
  accessKeyId: '',
  secretAccessKey: '',
  bucketName: '',
  appSecret: '',
  clientId: '',
  tenantId: '',
  siteUrl: '',
  token: '',
  dbSchema: '',
  dbHost: '',
  dbPort: '',
  dbUser: '',
  dbPassword: '',
  dbName: '',
  dbServer: '',
  host: '',
  user: '',
  port: '',
  encryptionMethod: 'noencryption',
  encryptionFile: '',
  encryptionFileObj: '',
  singleConnection: false
}

export default function ConnectionsView(props) {
  const { connections, appList, app, setSubmitSuccess, setCreateError } = props
  const rows = Object.keys(connections).map(function(key) {
    return connections[key]
  })
  const queryClient = useQueryClient()
  const classes = useStyles()
  const [connectionObj, setConnectionObj] = useState(connection)
  const [mode, setMode] = useState('list')

  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: true
    },
    {
      field: 'name',
      title: 'Connection Name'
    },
    {
      field: 'type',
      title: 'Type',
      render: rowData => ConnectionTypes[rowData.type]
    }
  ]

  function modifyForm(rowData) {
    switch (rowData.type) {
      case 's3':
        rowData = { ...connection, ...rowData, ...rowData.s3Params }
        delete rowData.s3Params
        break
      case 'sharepoint':
        rowData = { ...connection, ...rowData, ...rowData.sharePointParams }
        delete rowData.sharePointParams
        break
      case 'sftp':
        rowData = { ...connection, ...rowData, ...rowData.sftpParams }
        delete rowData.sftpParams
        break
      case 'msSql':
        rowData = { ...connection, ...rowData, ...rowData.msSqlParams }
        delete rowData.msSqlParams
        break
      default:
        break
    }
    return rowData
  }

  const { mutate: deleteAppConnection } = useDeleteAppConnection({
    appId: app.data.appId
  })

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Edit',
      onClick: (event, rowData) => {
        setMode('edit')
        const data = modifyForm(rowData)
        setConnectionObj(data)
      }
    },
    {
      icon: 'delete',
      tooltip: 'Delete',
      onClick: (event, rowData) =>
        deleteAppConnection(
          { data: { connectionId: rowData.connectionId } },
          {
            onSettled: () => {
              queryClient.invalidateQueries(['Apps', app.data.appId])
            },
            onSuccess: response => {
              if (response.status === 200) {
                setSubmitSuccess({ message: 'Deleted', isOpen: true })
                appList.refetch({ throwOnError: true })
                app.refetch({ throwOnError: true })
              }
            },
            onError: error => {
              setCreateError({
                message: error.response?.data?.message ?? 'Something went wrong, try again later',
                isOpen: true,
                color: 'danger'
              })
            }
          }
        )
    }
  ]

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <h5 className={classes.sectionTitle}>
          Connections
          <Tooltip title="Create new connection">
            <IconButton
              color="primary"
              component="span"
              size="small"
              className={classes.marginLeft}
              onClick={() => {
                setMode('create')
                setConnectionObj(connection)
              }}
            >
              <Add fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </h5>
      </Grid>
      {mode === 'list' ? (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MaterialTable
            columns={columns}
            data={rows}
            actions={actions}
            options={{
              actionsColumnIndex: -1,
              sorting: true,
              showTitle: false
            }}
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ConnectionsForm
            connectionObj={connectionObj}
            setMode={setMode}
            appList={appList}
            app={app}
            setCreateError={setCreateError}
            setSubmitSuccess={setSubmitSuccess}
          />
        </Grid>
      )}
    </Grid>
  )
}
