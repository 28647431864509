import React, { useState } from 'react'

// Material UI
import { CircularProgress, FormLabel, Grid, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle'

// Form Validation
import { Controller } from 'react-hook-form'

// Functions
import { useDebounce } from 'hooks/useDebounce'
import { useGetSuppliersByName } from 'hooks/useMasterData'

const useStyles = makeStyles(styles)

export const SupplierSearchName = props => {
  const { name, label, control, setValue, appId } = props

  const classes = useStyles()
  const [filter, setFilter] = useState(undefined)
  const [open, setOpen] = useState(false)
  const [debouncedValue] = useDebounce(filter, 500)
  const supplierIds = useGetSuppliersByName(debouncedValue, { appId })

  const handleChange = event => {
    const value = event.target.value.toLowerCase()
    setFilter(value)
  }

  function changeSupplierId(value) {
    if (!value) {
      return
    }

    setValue('vendorId', value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <FormLabel className={classes.inputLabelHorizontal}>{label}*</FormLabel>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                freeSolo
                style={{ width: '100%' }}
                open={open}
                disableClearable
                onOpen={() => {
                  setOpen(true)
                }}
                onClose={() => {
                  setOpen(false)
                }}
                options={supplierIds.data ?? []}
                classes={{
                  option: classes.option
                }}
                getOptionSelected={(option, value) => {
                  const optionName = option?.name ?? ''
                  const valueName = value?.name ?? ''

                  return optionName.toLowerCase() === valueName.toLowerCase()
                }}
                getOptionLabel={option => option?.name ?? ''}
                renderOption={option => (
                  <>
                    {option?.name} ({option?.supplierId})
                  </>
                )}
                loading={supplierIds.isLoading || supplierIds.isFetching}
                renderInput={params => (
                  <TextField
                    {...params}
                    value={filter}
                    onChange={handleChange}
                    label={label}
                    fullWidth
                    error={Boolean(error)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {supplierIds.isLoading || supplierIds.isFetching ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
                onChange={(_, data) => {
                  field.onChange(data)
                  changeSupplierId(data)
                }}
              />
            )}
            name={name}
            control={control}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
