import MaterialTable from 'material-table'
import React from 'react'

// Material UI
import { CardActions } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import ModalWrapper from '../../../../components/ModalWrapper/ModalWrapper'

import InfoModal from 'components/Invoice/InfoModal'

// Assets
import poweredByRelish from 'assets/img/POC/powered-by-relish.png'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'

// Components
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardText from 'components/Card/CardText'
import CustomInput from 'components/CustomInput/CustomInput.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: '#FFFFFF',
    marginTop: '0'
  },
  cardCategory: {
    color: '#999999',
    marginTop: '10px'
  },
  cardCategoryWhite: {
    margin: '0',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '.875rem'
  }
}

const useStyles = makeStyles(styles)

export default function SupplierInformationApp(props) {
  const classes = useStyles()

  return (
    <>
      <GridContainer
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '30px',
          width: '100%',
          margin: '0'
        }}
      >
        <GridItem xs={12}></GridItem>

        <GridItem xs={12} sm={12} md={10} lg={8}>
          <Card>
            <CardHeader color="gray" text="PepsiCO">
              <CardText color="gray">
                <h4 className={classes.cardTitleWhite}>PepsiCO</h4>
                <h5 className={classes.cardCategoryWhite}>1 possible matches</h5>
              </CardText>
            </CardHeader>
            <CardBody>
              <p style={{ textAlign: 'left' }}>
                <b>Please select a company to associate information with DnB Connector</b>
                <a
                  href="https://relishiq.com/solutions/relish-data-assure/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#3c78c8' }}
                >
                  {' Click to learn More'}
                </a>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                          labelText="DUNS Number*"
                          id="dunsNumber"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            /* onChange: event => {
                                props.onFieldChange('name', event.target.value)
                              }, */
                            type: 'text',
                            value: ''
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                          labelText="Company Name*"
                          id="companyName"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            /* onChange: event => {
                                props.onFieldChange('name', event.target.value)
                              }, */
                            type: 'text',
                            value: 'Pepsico'
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <InputLabel id="countryLabel">Country</InputLabel>
                          <Select
                            labelId="countryLabel"
                            id="countrySelect"
                            value="US"
                            label="Country"
                            /* onChange={handleChange} */
                          >
                            <MenuItem value={'US'}>United States</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={9}>
                        <CustomInput
                          labelText="Address Line 1*"
                          id="addressLine1"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            /* onChange: event => {
                                props.onFieldChange('name', event.target.value)
                              }, */
                            type: 'text',
                            value: '1135 W MIRACLE MILE'
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={9} md={9}>
                        <CustomInput
                          labelText="Address Line 2*"
                          id="addressLine2"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            /* onChange: event => {
                                props.onFieldChange('name', event.target.value)
                              }, */
                            type: 'text',
                            value: ''
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                          labelText="Postal Code*"
                          id="postalCode"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            /* onChange: event => {
                                props.onFieldChange('name', event.target.value)
                              }, */
                            type: 'text',
                            value: '85705'
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <CustomInput
                          labelText="Region*"
                          id="region"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            /* onChange: event => {
                                props.onFieldChange('name', event.target.value)
                              } */
                            type: 'text',
                            value: 'AZ'
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Box textAlign="center">
                          <Button
                            color="inherit"
                            type="button"
                            disabled={false}
                            variant="contained"
                            style={{ marginRight: '5px' }}
                            onClick={() => {}}
                          >
                            {'Search Company'}
                          </Button>
                        </Box>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </p>
              <MaterialTable
                columns={[
                  {
                    cellStyle: { padding: '5px 16px' },
                    title: 'Company Name',
                    field: 'primaryName'
                  },
                  {
                    cellStyle: { padding: '5px 16px' },
                    title: 'DUNS number',
                    field: 'duns'
                  },
                  {
                    cellStyle: { padding: '5px 16px' },
                    title: 'Match Percentage',
                    field: 'match'
                  },
                  {
                    cellStyle: { padding: '5px 16px' },
                    title: 'Address',
                    field: 'address'
                  },
                  {
                    cellStyle: { padding: '5px 16px' },
                    title: 'Country',
                    field: 'country'
                  },
                  {
                    cellStyle: { padding: '5px 16px' },
                    title: 'Region',
                    field: 'region'
                  },
                  {
                    cellStyle: { padding: '5px 16px' },
                    title: 'Postal Code',
                    field: 'postalCode'
                  },
                  {
                    cellStyle: { padding: '5px 16px' },
                    title: 'Match',
                    render: rowData => (
                      <IconButton
                        onClick={() => {
                          return (
                            <ModalWrapper>
                              <InfoModal
                                fullWidth={true}
                                maxWidth="sm"
                                showModal={true}
                                warningMessage="Do you want to match this record with supplier ID 4"
                                title={'Dnb Connector'}
                                textSuccessBtn={'Validate'}
                              />
                            </ModalWrapper>
                          )
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    )
                  }
                ]}
                data={[
                  {
                    primaryName: 'PEPSICO, INC.',
                    duns: '001287762',
                    match: '100%',
                    address: '700 ANDERSON HILL RD',
                    country: 'United States',
                    region: 'NY',
                    postalCode: '10577'
                  },
                  {
                    primaryName: 'PEPSICO, INC.',
                    duns: '001287762',
                    match: '100%',
                    address: '700 ANDERSON HILL RD',
                    country: 'United States',
                    region: 'NY',
                    postalCode: '10577'
                  }
                ]}
                options={{
                  actionsColumnIndex: -1,
                  sorting: true,
                  showTitle: false,
                  search: false,
                  paging: false,
                  toolbar: false
                }}
              />
            </CardBody>
            <CardActions>
              <GridContainer>
                <GridItem xs={6} style={{ textAlign: 'left' }}>
                  <a
                    href="https://relishiq.com/solutions/relish-data-assure/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#3c78c8' }}
                  >
                    <img src={poweredByRelish} alt="Go to Relish" style={{ maxWidth: '200px' }} />
                  </a>
                </GridItem>
              </GridContainer>
            </CardActions>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  )
}
