import React from 'react'
import PropTypes from 'prop-types'
import styles from 'assets/jss/material-dashboard-pro-react/components/emailModalStyle'
import { makeStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

// Constants
import { invoiceStatus } from 'utils/Constants'

// Icons
import DoneIcon from '@material-ui/icons/Done'
import HourglassFullIcon from '@material-ui/icons/HourglassFull'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(styles)
export function IntegratedModal (props) {
  const classes = useStyles()
  const { onClose, open, data } = props

  const handleClose = () => {
    onClose()
  }

  if (!data) {
    return null
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
    >
      <DialogTitle id="simple-dialog-title">Integration Details</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <p style={{ fontSize: '20px', display: 'flex', alignItems: 'center' }}>
          {data.status === 'notIntegrated' ? (
            <PriorityHighIcon style={{ color: '#3f51b5' }} />
          ) : null}
          {data.status === 'integrating' ? (
            <HourglassFullIcon style={{ color: 'grey' }} />
          ) : null}
          {data.status === 'integrated' ? (
            <DoneIcon style={{ color: 'green' }} />
          ) : null}
          {data.status === 'failed' ? (
            <CloseIcon style={{ color: '#d01b1b' }} />
          ) : null}{' '}
          {invoiceStatus[data.status]}
        </p>
        {data.message ? (
          <p style={{ fontSize: '16px', marginLeft: '25px' }}>{data.message}</p>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

IntegratedModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object
}
