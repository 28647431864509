import { Spinner } from 'components/Loader/Spinner'
import React, { useEffect } from 'react'
import { saveTeamsInit } from '../../services/apiApps'
export default function TeamsRedirect () {
  useEffect(() => {
    sessionStorage.removeItem('isLoading')

    let windowParams =
      window.location.search !== ''
        ? window.location.search
        : window.location.hash.replace(/#/g, '?')
    const urlSearchParams = new URLSearchParams(windowParams)
    console.log(window.location)
    const params = Object.fromEntries(urlSearchParams.entries())

    const code = params.code
    const idToken = params.id_token
    const scope = window.localStorage.getItem('MSScope')
      ? window.localStorage.MSScope.split(',')
      : []
    const tenantId = window.localStorage.getItem('SelectedTenant')
    const redirectUrl = window.localStorage.getItem('MSRedirect')
    const appId = window.localStorage.getItem('SelectedAppId')
    const app = window.localStorage.getItem('MSApp')
    const body = { code, scope, tenantId, appId, app, redirectUrl, idToken }

    saveTeamsInit(body, (message, success) => {
      window.localStorage.setItem('MSResponseMessage', message)
      window.localStorage.setItem('MSResponseStatus', success)
      window.localStorage.setItem('MSResponseBody', success)
      window.localStorage.setItem('externalLogin', true)
    })

    // window.addEventListener('resize', listenerWidth)
  })
  return (
    <div>
      <Spinner />
    </div>
  )
}
