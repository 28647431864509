import React from 'react'
import PropTypes from 'prop-types'
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(styles)

export function CopyInvoicesModal(props) {
  const classes = useStyles()
  const { onClose, open, copyInvoice, generateCopyInvoice } = props
  const handleClose = () => {
    onClose()
  }

  const submit = status => {
    copyInvoice(status)
    // handleClose()
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Copy Invoice</DialogTitle>
      <DialogContent className={classes.modalBody}>
        {generateCopyInvoice.isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '1rem 0 1rem'
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <>
            <p
              style={{
                marginBottom: '1.5rem'
              }}
            >
              Do you want to edit this invoice before processing it?
            </p>
            <p>
              <strong>Edit:</strong> Trigger invoice exception process.
            </p>
            <p>
              <strong>Reprocess:</strong> Resubmit invoice to the external.
              system.
            </p>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.colorPrimary}
          color="primary"
          variant="outlined"
          onClick={() => submit('invoiceException')}
        >
          Edit
        </Button>
        <Button
          className={classes.colorPrimary}
          color="primary"
          variant="outlined"
          onClick={() => submit('')}
        >
          Reprocess
        </Button>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CopyInvoicesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  copyInvoice: PropTypes.func.isRequired
}
