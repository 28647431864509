import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

// Router
import { useHistory, useParams } from 'react-router'

// Material UI
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

// Components
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import ModalAccountType from 'components/Invoice/ModalAccountType'
import Snackbar from 'components/Snackbar/Snackbar.js'

// Inputs
import { TextInput } from './Inputs/TextInput'
// import { SelectInput } from './Inputs/SelectInput'
import { DatetimeInput } from './Inputs/DatetimeInput'
import { SupplierSearch } from './Inputs/SupplierSearch'
import { SupplierSearchName } from './Inputs/SupplierSearchName'

// Icons
import AddAlert from '@material-ui/icons/AddAlert'
import AssessmentIcon from '@material-ui/icons/Assessment'
import EditRounded from '@material-ui/icons/EditRounded'
import AddIcon from '@material-ui/icons/PlaylistAdd'

// Form
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Hooks
import { useGetConnectivityConfiguration } from 'hooks/useInvoiceConfig'
import {
  useCreatePurchaseOrder,
  useGetPurchaseOrder,
  useUpdatePurchaseOrder
} from 'hooks/useMasterData'
import { useGeInvoicesAiTenantConfig, useGetTenantConfig } from 'hooks/useTenantConfig'

// Functions
import { getUserTenant } from 'utils/functions'
import { validateInput } from 'utils/validator'

const useStyles = makeStyles(styles)

export default function MasterDataPO() {
  const classes = useStyles()
  const history = useHistory()
  const { poId } = useParams()

  const [poForm] = useState({
    customerId: '',
    poNumber: '',
    dateCreated: moment(new Date()).format('MM/DD/YYYY'),
    requester: '',
    approver: '',
    assignedBy: '',
    vendorId: '',
    vendorName: ''
  })
  const [mode, setMode] = useState('create')
  const [poLines, setPoLines] = useState({
    lineItems: [
      {
        lineNumber: '1',
        quantity: '1',
        description: '',
        currency: 'USD',
        total: ''
      }
    ],
    lineItemsAccountSegments: {}
  })
  const [accountTypeModal, setAccountTypeModal] = useState(false)

  const [cellSelected, setCellSelected] = useState(null)
  const selectedLine =
    Object.keys(cellSelected ?? {}).length === 1
      ? Object.keys(cellSelected ?? {})[0].split(',')
      : []

  const queryClient = useQueryClient()

  const tenant = getUserTenant()
  const [createError, setCreateError] = useState({
    message: '',
    isOpen: false,
    color: 'danger'
  })
  const [submitSuccess, setSubmitSuccess] = useState({
    message: '',
    isOpen: false
  })

  const { data: configTenant, isLoading: isLoadingTenantConfig } = useGetTenantConfig()
  const appId = configTenant?.defaultApp ?? ''
  const connectivityConfig = useGetConnectivityConfiguration(appId)
  const accountSegmentsEnabled = connectivityConfig?.data?.data?.enabled ?? false
  const isAccountValidationEnabled =
    connectivityConfig?.data?.isExistingAccountValidationEnabled ?? false

  const {
    data: appConfig,
    isLoading: appConfigIsLoading,
    isError: appConfigIsError,
    error: appConfigError
  } = useGeInvoicesAiTenantConfig('invoiceAI', appId)

  useEffect(() => {
    if (!appConfigIsError) {
      return
    }
    setCreateError({
      isOpen: true,
      message: appConfigError.response?.data?.message || 'Something went wrong, try again later'
    })
  }, [appConfigIsError, appConfigError])

  useEffect(() => {
    if (poId) {
      setMode('edit')
    }
  }, [poId, setMode])

  const po = useGetPurchaseOrder({
    poId,
    appId,
    options: { refetchOnWindowFocus: false, enabled: !!appId && !!poId }
  })

  useEffect(() => {
    if (!po.isLoading && po.isError) {
      setCreateError({
        message: po.error.response?.data?.message ?? 'Something went wrong, try again later',
        isOpen: true,
        color: 'danger'
      })
    }
  }, [po.isLoading, po.isError, po.error])

  const schemaPo = yup.object().shape({
    customerId: yup
      .string()
      .min(1)
      .required(),
    poNumber: yup
      .string()
      .min(1)
      .required(),
    dateCreated: yup
      .string()
      .min(1)
      .required(),
    requester: yup
      .string()
      .min(1)
      .required(),
    approver: yup
      .string()
      .min(1)
      .required(),
    assignedBy: yup
      .string()
      .min(1)
      .required(),
    vendorId: yup.object(),
    vendorName: yup.object()
  })

  const { handleSubmit, control, reset, setValue, getValues } = useForm({
    mode: 'all',
    defaultValues: poForm,
    resolver: yupResolver(schemaPo),
    shouldUnregister: false
  })

  useEffect(() => {
    if (!po.data) {
      return
    }

    const poForm = {
      customerId: po.data.customerId,
      poNumber: po.data.poNumber,
      dateCreated: moment(po.data.dateCreated).format('MM/DD/YYYY'),
      requester: po.data.requester?.name ?? '',
      approver: po.data.approvers?.[0]?.name ?? '',
      assignedBy: po.data.approvers?.[1]?.name ?? '',
      vendorId: { supplierId: po.data.supplier?.id ?? '', name: '' },
      vendorName: { supplierId: '', name: '' }
    }
    if (po.data.poLines.length > 0) {
      const accountSegments = []
      // eslint-disable-next-line
      for (const poLine of po.data.poLines) {
        accountSegments[poLine.lineNumber] = {
          accountSegments: poLine.accountSegments
        }
        poLine.quantity = poLine.quantity && poLine.quantity !== '0' ? poLine.quantity : '1'
        poLine.currency = poLine.currency ?? 'USD'
        poLine.unitPrice =
          poLine.unitPrice && poLine.unitPrice !== '0' ? poLine.unitPrice : poLine.total
      }
      setPoLines(old => ({
        ...old,
        lineItems: po.data.poLines,
        lineItemsAccountSegments: accountSegments
      }))
    }

    reset(poForm)
  }, [po.data, reset])

  let breadcrumbViews = [
    {
      name: 'Purchase Orders',
      url: '/admin/invoiceAI/masterData/purchaseOrder/index',
      icon: AssessmentIcon
    },
    {
      name: 'Purchase Order',
      url: '/admin/invoiceAI/masterData/purchaseOrder/',
      icon: mode === 'edit' ? EditRounded : AddIcon
    }
  ]

  const {
    mutate: createPurchaseOrder,
    isLoading: isLoadingCreatePurchaseOrder
  } = useCreatePurchaseOrder()

  const {
    mutate: updatePurchaseOrder,
    isLoading: isLoadingUpdatePurchaseOrder
  } = useUpdatePurchaseOrder({
    poId: poId
  })

  function modifyData(data) {
    const newData = {}
    if (!poId) {
      newData.version = '1'
      newData.dateCreatedEpoch = new Date().getTime()
    }
    if (po.data) {
      newData.version = (Number(po.data.version) + 1).toString()
    }
    newData.tenantId = tenant
    newData.appId = appId
    newData.poNumber = data.poNumber
    newData.supplier = {
      id: data.vendorId?.supplierId
    }
    newData.customerId = data.customerId
    newData.status = 'open'
    newData.dateCreated = new Date(data.dateCreated).toISOString()
    newData.requester = {
      name: data.requester
    }
    newData.approvers = []
    newData.approvers.push({
      name: data.approver
    })
    newData.approvers.push({
      name: data.assignedBy
    })
    if (poLines) {
      newData.poLines = []
      let isValidSegments = true
      // eslint-disable-next-line
      for (const poLine of poLines.lineItems) {
        if (!poLine.total || poLine.total === '') {
          setCreateError({
            message: 'You have to fill the amount field.',
            isOpen: true,
            color: 'danger'
          })
          return { valid: false }
        }
        const pol = {
          tenantId: tenant,
          appId: appId,
          poNumber: data.poNumber,
          version: newData.version,
          lineNumber: poLine.lineNumber,
          description: poLine.description,
          total: poLine.total,
          itemCategory: 'material',
          quantity: poLine.quantity,
          unitPrice: (Number(poLine.total) / Number(poLine.quantity)).toString(),
          currency: poLine.currency
        }
        const accountSegments = poLines.lineItemsAccountSegments[poLine.lineNumber]

        if (accountSegmentsEnabled) {
          if (!accountSegments || accountSegments.accountSegments?.length === 0) {
            isValidSegments = false
          } else {
            pol.accountSegments = accountSegments.accountSegments
          }
        }

        pol.splitAccountType = 'percentage'
        newData.poLines.push(pol)
      }
      if (!isValidSegments) {
        setCreateError({
          message: 'One or more lines are missing accounting information.',
          isOpen: true,
          color: 'danger'
        })
        return { valid: false }
      }
    }

    return { valid: true, newData }
  }

  function onSubmitHook(data) {
    const { valid, newData } = modifyData(data)
    if (!valid) {
      return
    }
    if (poId) {
      updatePurchaseOrder(
        { data: newData, appId },
        {
          onSettled: () => {
            queryClient.invalidateQueries(['MasterData', 'PurchaseOrders', poId])
          },
          onSuccess: response => {
            if (response.status === 200) {
              setSubmitSuccess({ message: 'Saved', isOpen: true })
              setTimeout(() => {
                history.push(`/admin/invoiceAI/masterData/purchaseOrder/index`)
              }, 1000)
            }
          },
          onError: error => {
            setCreateError({
              message: error.response?.data?.message ?? 'Something went wrong, try again later',
              isOpen: true,
              color: 'danger'
            })
          }
        }
      )
    } else {
      createPurchaseOrder(
        { data: newData, appId },
        {
          onSettled: () => {
            queryClient.invalidateQueries('MasterData', 'PurchaseOrders', poId)
          },
          onSuccess: response => {
            if (response.status === 201) {
              setSubmitSuccess({ message: 'Saved', isOpen: true })
              setTimeout(() => {
                history.push(`/admin/invoiceAI/masterData/purchaseOrder/index`)
              }, 1000)
            }
          },
          onError: error => {
            setCreateError({
              message: error.response?.data?.message ?? 'Something went wrong, try again later',
              isOpen: true,
              color: 'danger'
            })
          }
        }
      )
    }
  }

  const rules = {
    total: {
      required: true,
      typeField: 'number'
    },
    quantity: {
      required: true,
      typeField: 'number'
    },
    currency: {
      required: true
    },
    lineNumber: {
      required: true
    }
  }

  const onEditComplete = ({ value, columnId, data }) => {
    const rule = rules[columnId] ?? {}
    const validate = validateInput(rule, value)
    if (!validate.valid) {
      setCreateError({
        message: `Invalid Input`,
        isOpen: true,
        color: 'danger'
      })
      return
    }
    const copyPoLines = [...poLines.lineItems]
    const poLinesIndex = copyPoLines.findIndex(line => line.key === data?.key)
    if (poLinesIndex < 0) {
      return
    }
    copyPoLines[poLinesIndex][columnId] = value
    setPoLines(old => ({
      ...old,
      lineItems: [...copyPoLines]
    }))
  }

  const columns = [
    {
      name: 'lineNumber',
      header: 'Line Number',
      defaultFlex: 1,
      minWidth: 200,
      textAlign: 'center',
      editable: false
    },
    {
      name: 'quantity',
      header: 'Quantity',
      defaultFlex: 1,
      minWidth: 200,
      textAlign: 'center',
      editable: appConfig?.data?.params?.masterData?.purchaseOrder?.lineItems?.qtyReadOnly
        ? false
        : true
    },
    {
      name: 'description',
      header: 'Description',
      defaultFlex: 1,
      minWidth: 200,
      textAlign: 'center'
    },
    {
      name: 'currency',
      header: 'Currency',
      defaultFlex: 1,
      minWidth: 200,
      textAlign: 'center',
      editable: appConfig?.data?.params?.masterData?.purchaseOrder?.lineItems?.curReadOnly
        ? false
        : true
    },
    {
      name: 'total',
      header: 'Amount',
      defaultFlex: 1,
      minWidth: 200,
      textAlign: 'center'
    }
  ]

  if (accountSegmentsEnabled) {
    columns.push({
      header: 'Additional Details',
      name: 'accountSegments',
      defaultFlex: 1,
      minWidth: 200,
      editable: false,
      textAlign: 'center',
      render: ({ value, data }) => {
        return (
          <Button color="primary" onClick={() => setAccountTypeModal(true)}>
            Accounting | Details
          </Button>
        )
      }
    })
  }

  const onCellClick = useCallback((event, cellProps) => {
    const { name, data } = cellProps
    setCellSelected({ [`${data.lineNumber},${name}`]: true })
  }, [])

  function handleOnCompleteAccountSegments(data) {
    if (!selectedLine || selectedLine.length === 0 || !data) {
      return
    }

    setPoLines(old => ({
      ...old,
      lineItemsAccountSegments: {
        ...old.lineItemsAccountSegments,
        [selectedLine[0]]: {
          id: selectedLine[0],
          accountSegments: data
        }
      }
    }))
  }

  const accountSegmentsRow =
    selectedLine.length > 0 ? poLines.lineItemsAccountSegments[selectedLine[0]] : null

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Breadcrumbs views={breadcrumbViews} />
        <Card style={{ overflow: 'visible' }}>
          <CardContent>
            {po.isLoading ||
            connectivityConfig.isLoading ||
            isLoadingTenantConfig ||
            appConfigIsLoading ? (
              <Grid container justifyContent="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            ) : (
              <form onSubmit={handleSubmit(onSubmitHook)}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <h5 className={classes.sectionTitle}>Purchase Order</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name={'customerId'}
                      control={control}
                      type={'text'}
                      label={'Id'}
                      multiline={false}
                      readOnly={mode === 'edit' ? true : false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name={'poNumber'}
                      control={control}
                      type={'text'}
                      label={'P.O. Number'}
                      multiline={false}
                      readOnly={mode === 'edit' ? true : false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name={'requester'}
                      control={control}
                      type={'text'}
                      label={'Requester'}
                      multiline={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name={'approver'}
                      control={control}
                      type={'text'}
                      label={'Approver'}
                      multiline={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SupplierSearch
                      name={'vendorId'}
                      control={control}
                      label={'Vendor ID'}
                      setValue={setValue}
                      getValues={getValues}
                      appId={appId}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SupplierSearchName
                      name={'vendorName'}
                      control={control}
                      label={'Vendor Name'}
                      setValue={setValue}
                      getValues={getValues}
                      appId={appId}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name={'assignedBy'}
                      control={control}
                      type={'text'}
                      label={'P.O Assigned by'}
                      multiline={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatetimeInput
                      name={'dateCreated'}
                      control={control}
                      label={'Date'}
                      dateFormat={'MM/DD/YYYY'}
                      timeFormat={false}
                      readOnly={mode === 'edit' ? true : false}
                    />
                  </Grid>
                  {mode === 'edit' ? (
                    <Grid item xs={12}>
                      <Grid container justifyContent="flex-start" spacing={3}>
                        <Grid item xs={4}>
                          <div style={{ marginLeft: '20px' }}>
                            <p>
                              Created By:
                              <spam> {po.data?.createdBy?.userName ?? ''}</spam>
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ marginLeft: '20px' }}>
                            <p>
                              Updated By:
                              <span> {po.data?.updatedBy?.userName ?? ''}</span>
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ marginLeft: '20px' }}>
                            <p>
                              Date Updated:
                              <span>
                                {' '}
                                {moment(po.data?.updatedBy?.updateDate).format(
                                  'MM/DD/YYYY HH:mm'
                                ) ?? ''}
                              </span>
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <ReactDataGrid
                      idProperty="lineNumber"
                      onEditComplete={onEditComplete}
                      editable={true}
                      showColumnMenuTool={false}
                      loading={po.isLoading}
                      sortable={false}
                      enableKeyboardNavigation={false}
                      columns={columns}
                      dataSource={poLines.lineItems}
                      onCellClick={onCellClick}
                      cellSelection={cellSelected}
                      onCellSelectionChange={setCellSelected}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Button
                      className={classes.bgColorPrimary}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isLoadingUpdatePurchaseOrder || isLoadingCreatePurchaseOrder}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </CardContent>
        </Card>
      </Grid>
      {accountSegmentsEnabled ? (
        <ModalAccountType
          isOpen={accountTypeModal}
          onClose={() => setAccountTypeModal(false)}
          defaultDataSource={accountSegmentsRow?.accountSegments ?? []}
          onComplete={handleOnCompleteAccountSegments}
          isAccountValidationEnabled={isAccountValidationEnabled}
          appId={appId}
        />
      ) : null}
      <Snackbar
        place="br"
        color="success"
        icon={AddAlert}
        message={submitSuccess.message}
        open={submitSuccess.isOpen}
        closeNotification={() =>
          setSubmitSuccess({
            isOpen: false,
            message: ''
          })
        }
        close
      />
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() =>
          setCreateError({
            isOpen: false,
            message: ''
          })
        }
        close
      />
    </Grid>
  )
}
