import React, { useEffect} from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import jwtDecode from "jwt-decode";

import styles from "assets/jss/material-dashboard-pro-react/views/User/createStyle.js";

function DummyView(props) {

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [valid, setValid] = React.useState(false)

  //Component did mount
  useEffect(() => {
      const search = props.location.search
      const params = new URLSearchParams(search);

      const jwtToken = params.get("token")
        if (jwtToken) {
          try {
            const tokenPayload = jwtDecode(jwtToken);
            const current_time = new Date().getTime() / 1000;

            if (current_time > tokenPayload.exp) {
              console.log('Token expired')
              setValid(false)
            } else {
              console.log('Token still valid')
              setValid(true)
            }
          } catch (e) {
            setValid(false)
          }
        } else {
          console.log('Unable to read token')
          setValid(false)
        }
      
  }, [props])

  return (
    <div className={classes.contentCenter}>{valid ? (
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>TBD</h1>
          <h2 className={classes.subTitle}>If you can see this page, means that your token is valid!</h2>
          <h4 className={classes.description}>
            Site still under construction! :) 
          </h4>
        </GridItem>
      </GridContainer>
    ) : (
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>404</h1>
          <h2 className={classes.subTitle}>Page not found :(</h2>
          <h4 className={classes.description}>
            Ups! Looks like you got lost.
          </h4>
        </GridItem>
      </GridContainer>
    )}
      
    </div>
);
}

export default DummyView