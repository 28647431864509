import React, { useState } from 'react'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import CustomInput from '../../components/CustomInput/CustomInput.js'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Switch from '@material-ui/core/Switch'
import CustomModal from 'components/CustomModal/CustomModal.js'
import { Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js'

const useStyles = makeStyles(styles)

export default function RowEditModal(props) {
  const [messageValue, setMessageValue] = useState(props.message)
  const [typeValue, setTypeValue] = useState(props.type)
  const [enableValue, setEnableValue] = useState(props.active)
  const [visibleValue, setVisibleValue] = useState(props.visible)
  const [groupValue] = useState(props.group)
  const classes = useStyles()

  const returnValues = { messageValue, typeValue, enableValue, visibleValue, groupValue }

  return (
    <CustomModal
      title={props.title}
      // confirmBtnCssClass={props.confirmBtnCssClass}
      // cancelBtnCssClass={props.cancelBtnCssClass}
      onCancel={props.onCancel}
      onConfirm={() => {
        props.onConfirm(returnValues)
      }}
    >
      <GridContainer container spacing={4} style={{ paddingBottom: '5%' }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader>{props.descriptionLabel}</CardHeader>
          <CardBody>
            <div>
              <CustomInput
                disabled
                labelProps={{ style: { width: '100%' } }}
                id="inputId"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: 'text',
                  value: props.description
                }}
              />
            </div>
          </CardBody>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CardHeader>{props.messageLabel}</CardHeader>
          <CardBody>
            <div>
              <CustomInput
                labelProps={{ style: { width: '100%' } }}
                id="inputId"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => {
                    setMessageValue(event.target.value)
                    // console.log(event)
                  },
                  type: 'text',
                  value: messageValue
                }}
              />
            </div>
          </CardBody>
        </GridItem>
        <GridContainer container spacing={4}>
          <GridItem xs={4} sm={4} md={4} lg={4}>
            <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
              <CardHeader>{props.typeLabel}</CardHeader>
              <CardBody>
                <Select
                  MenuProps={{
                    style: { zIndex: 5555 }
                  }}
                  style={{ width: '100%' }}
                  value={typeValue}
                  onChange={e => setTypeValue(e.target.value)}
                  inputProps={{
                    name: 'addressType',
                    id: 'addressType'
                  }}
                >
                  <MenuItem style={{ overflowX: 'auto', textOverflow: 'ellipsis' }} value={'info'}>
                    Info
                  </MenuItem>
                  <MenuItem
                    style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                    value={'warning'}
                  >
                    Warning
                  </MenuItem>
                  <MenuItem style={{ overflowX: 'auto', textOverflow: 'ellipsis' }} value={'error'}>
                    Error
                  </MenuItem>
                </Select>
              </CardBody>
            </div>
          </GridItem>
          <GridItem xs={4} sm={4} md={4} lg={4}>
            <CardHeader>{props.enableLabel}</CardHeader>
            <CardBody>
              <div>
                <Switch
                  checked={enableValue}
                  onChange={event => setEnableValue(!enableValue)}
                  value="Active"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                  }}
                />
              </div>
            </CardBody>
          </GridItem>
          <GridItem xs={4} sm={4} md={4} lg={4}>
            <CardHeader>{props.visibleLabel}</CardHeader>
            <CardBody>
              <div>
                <Switch
                  checked={visibleValue}
                  onChange={event => setVisibleValue(!visibleValue)}
                  value="Active"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                  }}
                />
              </div>
            </CardBody>
          </GridItem>
        </GridContainer>
      </GridContainer>
    </CustomModal>
  )
}
