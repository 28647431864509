import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { getPriceBookConfigurationList, getApp, createApp } from 'services/apiPricebook'
import { v4 as uuidv4 } from 'uuid'

import PriceBookView from './PriceBookView'

import { showLoading, hideLoading } from 'utils/functions'

let _isMounted = false

const newAppValidations = {
  service: 'coupa',
  env: '',
  type: 'Test',
  enabled: true,
  displayMode: 'create',
  parameters: {}
}

async function getAppList(props, callback = () => {}, selectedApp = undefined) {
  let response = await getPriceBookConfigurationList()
  if (response && response.success) {
    if (_isMounted) {
      if (response.data && response.data.length > 0) {
        props.setAppList(response.data)
        if (response.data.length !== 0) {
          // get app details
          if (!selectedApp) selectedApp = response.data[0].appId
          await getAppDetails(props, selectedApp)
        }
        props.setPageIsLoading(false)
      }
    }
  }
  callback(props.setPageIsLoading)
}

export async function saveAppDetails(props, appDetails, refresh = true) {
  //Save complete object
  if (appDetails && appDetails.data) {
    let validationDetails = appDetails.data
    validationDetails.displayMode = 'edit'
    if (_isMounted) {
      props.setValidationsForm(validationDetails)
      props.setFormIsLoading(false)
      if (refresh) props.setRefresh(!props.refresh)
    }
  }
}

export async function getAppDetails(props, id) {
  props.setFormIsLoading(true)
  props.setSelectedAppId(id)
  props.setDisplayMode('edit')
  let appDetails = await getApp(id)
  if (appDetails && appDetails.success) {
    await saveAppDetails(props, appDetails)
  } else {
    let message = appDetails.message
    if (!message) {
      message = 'Something went wrong, please try again later.'
    }
    props.setValidationMessage(message)
    props.setCreateError(true)
    props.setFormIsLoading(false)
  }
}

async function onSubmit(props, event) {
  let successMessage
  let response

  let validations = event.validations
  validations.appName = validations.env
  delete validations.mapping
  delete validations.localConfis
  delete validations.env
  delete validations.displayMode
  validations.appId = uuidv4()
  validations.appType = 'pricebook'
  response = await createApp(validations)
  successMessage = 'App created successfully'

  if (response && response.success && _isMounted) {
    props.setValidationMessage(successMessage)
    props.setSubmitSuccess(true)

    if (props.displayMode === 'create') {
      await getAppList(props, async callback => {
        await appChanged(props, validations.appId)
        hideLoading(callback)
      })
    } else hideLoading(props.setPageIsLoading)

    setTimeout(function() {
      if (_isMounted) {
        props.setSubmitSuccess(false)
      }
    }, 5000)
    return true
  } else {
    hideLoading(props.setPageIsLoading)
    // Show error message
    if (response) {
      let message = response.message
      if (!message) {
        if (response.errors && typeof response.errors === 'string') {
          message = response.errors
        } else if (
          response.errors &&
          typeof response.errors === 'object' &&
          response.errors.length > 0
        ) {
          message = ''
          response.errors.forEach(error => {
            message += ' ' + error.msg
          })
        } else message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
    return false
  }
}

async function appChanged(props, id) {
  await getAppDetails(props, id)
  hideLoading(props.setPageIsLoading)
}

function onNewCreateIsCalled(props) {
  props.setFormIsLoading(true)
  props.setDisplayMode('create')
  props.setSelectedAppId(null)

  props.setValidationsForm({ ...newAppValidations })

  setTimeout(function() {
    props.setFormIsLoading(false)
  }, 500)
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      enabledApps: state.app.enabledApps
    }),
    {}
  ),
  withState('appList', 'setAppList', []),
  withState('refresh', 'setRefresh', false),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('displayMode', 'setDisplayMode', 'create'),
  withState('validationsForm', 'setValidationsForm', { ...newAppValidations }),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withHandlers({
    onAppChanged: props => id => {
      showLoading(props.setPageIsLoading)
      appChanged(props, id)
    },
    onCreateButton: props => () => {
      if (props.displayMode !== 'create') {
        onNewCreateIsCalled(props)
      }
    },
    onAppSaved: props => async event => {
      props.setPageIsLoading(true)
      showLoading(props.setPageIsLoading)
      return onSubmit(props, event)
    }
  }),
  lifecycle({
    componentDidMount() {
      showLoading(this.props.setPageIsLoading)
      _isMounted = true
      this.props.setPageIsLoading(true)
      getAppList(this.props, hideLoading)
    }
  })
)(PriceBookView)
