

const buttonsStyle = {
  breadcrumbButton: {
    padding: "0px 5px",
    margin: ".3125rem 1px",
    fontSize: "10px",
    fontWeight: "400",
    textTransform: "capitalize",
  },
  arrowIcon: {
    float: "left",
    marginTop: "10px"
  },
  buttonGroup: {
    position: "relative",
    margin: "10px 1px",
    //display: "inline-block",
    verticalAlign: "middle"
  },
  firstButton: {
    padding: "12px 3px",
    textTransform: "capitalize",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
    margin: "0",
    position: "relative",
    float: "left",
    "&:hover": {
      zIndex: "2"
    }
  },
  middleButton: {
    padding: "12px 3px",
    textTransform: "capitalize",
    borderRadius: "0",
    margin: "0",
    position: "relative",
    float: "left",
    "&:hover": {
      zIndex: "2"
    }
  },
  lastButton: {
    padding: "12px 3px",
    textTransform: "capitalize",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    margin: "0",
    "&:hover": {
      zIndex: "2"
    }
  },
    icons: {
    width: "17px",
    height: "17px"
  }
};

export default buttonsStyle;
