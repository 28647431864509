import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Button, CircularProgress } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import AssessmentIcon from '@material-ui/icons/Assessment'
import CheckIcon from '@material-ui/icons/Check'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import { ListItemIcon } from '@material-ui/core'
import { ListItemSecondaryAction } from '@material-ui/core'

const useStyles = makeStyles(styles)

export default function InsightsAriba (props) {
  const classes = useStyles()

  let breadcrumbViews = [
    {
      name: 'Microsoft Teams default connection',
      url: '/admin/nitorConnect/connections',
      icon: AssessmentIcon
    }
  ]

  function returnViewToDisplay () {
    return (
      <GridContainer justify='center'>
        <GridItem md={4} style={{ marginTop: '5px' }}>
          <Card>
            <CardHeader>Microsoft Tenant Ids</CardHeader>
            <CardBody>
              {props.isTenantsLoading ? (
                <div className={classes.reloadSize}>
                  <CircularProgress />
                </div>
              ) : (
                <List component='nav' aria-label='main mailbox folders'>
                  {props.tenants.map((tenant, i) => {
                    return (
                      <ListItem
                        key={i}
                        selected={props.selectedTenantIndex === i}
                        button
                        onClick={() => props.onTenantListItemClick(tenant, i)}
                      >
                        <ListItemText primary={tenant.tenantId} />
                      </ListItem>
                    )
                  })}
                </List>
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={8} style={{ marginTop: '5px' }}>
          <Card>
            <CardHeader>Relish Applications</CardHeader>
            <CardBody>
              {props.isAppsLoading ? (
                <div className={classes.reloadSize}>
                  <CircularProgress />
                </div>
              ) : (
                <List component='nav' aria-label='main mailbox folders'>
                  {props.apps.map((app, i) => {
                    return (
                      <ListItem
                        key={i}
                        selected={props.selectedAppIndex === i}
                        button
                        onClick={() => props.onAppListItemClick(app, i)}
                      >
                        {props.selectedAppIndex === i ? (
                          <ListItemSecondaryAction>
                            <ListItemIcon>
                              <CheckIcon style={{ color: '#008000' }} />
                            </ListItemIcon>
                          </ListItemSecondaryAction>
                        ) : null}
                        <ListItemText
                          primary={app.environment}
                          secondary={'Type:' + app.type}
                        />
                      </ListItem>
                    )
                  })}
                </List>
              )}
              <GridContainer justify='center'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => props.onSave()}
                >
                  Save
                </Button>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Breadcrumbs views={breadcrumbViews} />
        {props.formIsLoading ? (
          <Card>
            <GridContainer justify='center'>
              <div className={classes.circularProgress}>
                <CircularProgress color='inherit' />
              </div>
            </GridContainer>
          </Card>
        ) : (
          returnViewToDisplay()
        )}
      </GridItem>
      <Snackbar
        place='br'
        color='success'
        icon={AddAlert}
        message={props.validationMessage}
        open={props.submitSuccess}
        closeNotification={() => props.setSubmitSuccess(false)}
        close
      />
      <Snackbar
        place='bl'
        color='danger'
        icon={AddAlert}
        message={props.validationMessage}
        open={props.createError}
        closeNotification={() => props.setCreateError(false)}
        close
      />
    </GridContainer>
  )
}
