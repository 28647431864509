import { connect } from 'react-redux'
import { compose, lifecycle, withState } from 'recompose'
import { signOut } from 'store/AuthState'

import ExternalLoginEnd from 'views/External/Login/LoginEnd'
import LoginPage from 'views/Login'
import LoginEnd from 'views/Teams/Login/LoginEnd'
import LoginStart from 'views/Teams/Login/LoginStart'

import LoginPageExternal from 'views/External/Login/LoginView'
import AuthView from './AuthView'

let _isMounted = false

function getMenu(props) {
  let menuRoutes = [
    {
      path: '/login-page/external',
      name: 'Login Page',
      mini: 'L',
      component: LoginPageExternal,
      layout: '/auth'
    },
    {
      path: '/login-page/:origin?',
      name: 'Login Page',
      mini: 'L',
      component: LoginPage,
      layout: '/auth'
    },

    {
      path: '/nitor-connect/login-start',
      name: 'Login Start',
      mini: 'L',
      component: LoginStart,
      layout: '/auth',
      redirect: true
    },
    {
      path: '/nitor-connect/login-end',
      name: 'Login End',
      mini: 'L',
      component: LoginEnd,
      layout: '/auth',
      redirect: true
    },
    {
      path: '/external/login-end',
      name: 'Login End',
      mini: 'L',
      component: ExternalLoginEnd,
      layout: '/auth',
      redirect: true
    }
  ]

  if (_isMounted) {
    props.setAuthMenu(menuRoutes)
  }
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name
    }),
    { signOut }
  ),
  withState('authMenu', 'setAuthMenu', []),
  lifecycle({
    componentDidMount() {
      _isMounted = true
      getMenu(this.props)
    }
  })
)(AuthView)
