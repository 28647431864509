/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import App from 'AppContainer'
import 'assets/scss/material-dashboard-pro-react.scss?v=1.8.0'
import { PermissionProvider } from 'contexts/PermissionsContext'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from 'store'
import loadingGif from './assets/img/loadingGif2.gif'

const { persistor, store } = configureStore()
const hist = createBrowserHistory()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        if (error.response?.status === 404) return false
        else if (failureCount < 2) return true
        else return false
      },
      onError(error) {
        if (!error) {
          return
        }
        // if (error.response?.status === 401) storeFn.dispatch(signOut())
      }
    },
    mutations: {
      onError: (err, variables, rollback) => (typeof rollback === 'function' ? rollback() : null)
    }
  }
})

ReactDOM.render(
  <Router history={hist}>
    <div
      id={'loadingDiv'}
      style={{
        display: 'none',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: '1300',
        height: '100%',
        position: 'absolute'
      }}
    >
      <div
        style={{
          margin: 'auto'
        }}
      >
        <img
          style={{
            height: '20%',
            margin: '0px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            alignContent: 'center'
          }}
          src={loadingGif}
          alt="loading"
        />
      </div>
    </div>

    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <PermissionProvider>
            <App />
          </PermissionProvider>
        </PersistGate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
)
