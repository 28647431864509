import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
// @material-ui/core components
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

import AddIcon from '@material-ui/icons/Add'
import AddAlert from '@material-ui/icons/AddAlert'
import WarningIcon from '@material-ui/icons/Warning'
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'
import Snackbar from 'components/Snackbar/Snackbar'
import { useGetAccountTypesByTenant, useGetSegmentsByAccountType } from 'hooks/useMasterData'
import { useMemo } from 'react'
import { getAccountByIdAndCode } from 'services/apiMasterData'
import { v4 as uuidv4 } from 'uuid'

const useStyles = makeStyles(styles)
const useStylesInputs = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),

    minWidth: 250
  },
  btnColorPrimary: {
    color: '#081c3e',
    border: '1px solid #081c3e',
    '&:hover': {
      backgroundColor: '#081c3e',
      color: 'white',
      border: '1px solid #081c3e'
    }
  }
}))

const gridStyle = { minHeight: 550, maxHeight: 700 }
const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
  autoHide: true,
  alwaysShowTrack: true,
  scrollThumbStyle: {
    background: '#1E408A'
  }
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

/**
 * This constant is for clients that don't have accountId Validation in the front-end like ezcorp
 */
const accountIdNotFound = 'relishTBD'

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: option => option.value + option.description
})
export default function ModalAccountType(props) {
  const classes = useStyles()
  const classesInputs = useStylesInputs()
  const {
    isOpen,
    onClose,
    defaultDataSource,
    onComplete,
    readOnly = false,
    isAccountValidationEnabled = false,
    appId
  } = props
  const [accountType, setAccountType] = useState('')
  const [dataSource, setDataSource] = useState([])
  const [segmentValidation, setSegmentValidation] = useState({
    isFetching: false,
    isError: false
  })

  const defaultDataSourceMemo = useMemo(() => defaultDataSource, [defaultDataSource])
  const accountTypes = useGetAccountTypesByTenant({ appId })

  const segments = useGetSegmentsByAccountType({
    accountTypeId: accountType,
    params: { appId }
  })

  const segmentsOptions = segments.data ?? []

  useEffect(() => {
    if (!accountTypes.data) return
    const accountTypesOptions = accountTypes.data ?? []
    const accountSelected = accountTypesOptions.find(x => x.id === accountType)

    if (!accountSelected) return

    const copyLines = []
    const newElement = {}
    // eslint-disable-next-line
    for (const column of accountSelected.accountSegments) {
      newElement[column.segmentId] = ''
    }
    newElement['id'] = uuidv4()
    copyLines.push(newElement)

    setDataSource(old => {
      if (old.length > 0) {
        return old
      }
      return copyLines
    })
  }, [accountTypes.data, accountType])

  useEffect(() => {
    if (isOpen) {
      if (defaultDataSourceMemo.length > 0) {
        const accountTypeTemp = defaultDataSourceMemo[0].accountTypeId

        setAccountType(accountTypeTemp)
      } else if (accountTypes.data && accountTypes.data.length === 1) {
        setAccountType(accountTypes.data[0].id)
      }

      const data = defaultDataSourceMemo.map(x => ({ id: x.id, ...x.segments }))
      if (data.length > 0) {
        setDataSource(data)
      }
    }
  }, [isOpen, defaultDataSourceMemo, accountTypes.data])

  const accountTypesOptions = accountTypes.data ?? []
  const accountSelected = accountTypesOptions.find(x => x.id === accountType)

  const columns = accountType
    ? accountSelected?.accountSegments?.map(x => ({
        name: x.segmentId,
        header: x.segmentName,
        defaultFlex: 1,
        minWidth: 300,
        editable: !readOnly,
        textAlign: 'center',
        render: ({ value }) => (value ? value : 'Segment Value'),
        renderEditor: editorProps => {
          const segment = segmentsOptions.find(segment => segment.segmentId === x.segmentId)

          let selectOptions = []
          if (segment) {
            selectOptions = segment.segmentValues
          }

          const autoCompleteValue = selectOptions.find(x => x.value === editorProps.value) ?? {
            value: editorProps.value ?? '',
            description: ''
          }

          return (
            <Autocomplete
              key={x.segmentId}
              style={{ width: '100%', height: '100%' }}
              id="country-select-demo"
              options={selectOptions}
              autoHighlight
              value={autoCompleteValue}
              onChange={(event, newValue) => {
                editorProps.onChange(newValue?.value ?? '')
              }}
              onBlur={editorProps.onComplete}
              getOptionSelected={(option, value) => option?.value === value.value}
              filterOptions={filterOptions}
              getOptionLabel={option => {
                return option.value && option.description
                  ? `${option.description} (${option.value})`
                  : ''
              }}
              renderOption={option => (
                <React.Fragment>{`${option.description} (${option.value})`}</React.Fragment>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password' // disable autocomplete and autofill
                  }}
                />
              )}
            />
          )
        },
        onRender: (cellProps, rowData) => {
          const { data } = rowData
          if (x.required && !data[x.segmentId]) {
            cellProps.style.background = 'rgba(204, 122, 122, 0.27)'
          }
        }
      }))
    : []

  function handleChangeAccountType(event) {
    setAccountType(event.target.value)
    setDataSource([])
  }

  const onEditComplete = useCallback(
    ({ value, columnId, data }) => {
      const copyRows = [...dataSource]
      const lineItemIndex = copyRows.findIndex(line => line.id === data.id)

      if (lineItemIndex < 0) {
        return
      }
      copyRows[lineItemIndex][columnId] = value
      setDataSource(copyRows)
    },
    [dataSource, setDataSource]
  )

  const handleOnComplete = async () => {
    if (!accountSelected || dataSource.length === 0) {
      handleClose()
      setSegmentValidation(old => ({ ...old, isError: true }))
      return
    }

    let isError = false

    // eslint-disable-next-line
    for (const segments of dataSource) {
      isError = accountSelected.accountSegments.some(x => x.required && !segments[x.segmentId])
      if (isError) {
        break
      }
    }

    if (isError) {
      setSegmentValidation(old => ({ ...old, isFetching: false, isError: true }))
      return
    }

    setSegmentValidation(old => ({ ...old, isFetching: true }))
    const dataUpdated = await validateAccount(dataSource)

    if (isAccountValidationEnabled) {
      const isValidSegments = dataUpdated.every(x => x.accountId !== 'accountIdNotFound')

      if (isValidSegments) {
        if (typeof onComplete === 'function') {
          onComplete(dataUpdated)
        }
        handleClose()
      }
      setSegmentValidation(old => ({ ...old, isFetching: false, isError: !isValidSegments }))
    } else {
      if (typeof onComplete === 'function') {
        onComplete(dataUpdated)
      }
      handleClose()
      setSegmentValidation(old => ({ ...old, isFetching: false, isError: false }))
    }
  }

  const handleAddNewRow = () => {
    if (!columns) {
      return
    }

    const copyLines = [...dataSource]
    const newElement = {}
    // eslint-disable-next-line
    for (const column of columns) {
      newElement[column.name] = ''
    }
    newElement['id'] = uuidv4()
    copyLines.push(newElement)

    setDataSource(copyLines)
  }

  const handleClose = () => {
    onClose()
    setSegmentValidation({
      isFetching: false,
      isError: false
    })
    setAccountType('')
    setDataSource([])
  }

  async function validateAccount(dataSource = []) {
    const concatChar = accountSelected.concatChar

    const newDataSource = []
    // eslint-disable-next-line
    for (const segment of dataSource) {
      const segmentsCopy = { ...segment }
      delete segmentsCopy.id

      let account = []
      let accountId = ''
      try {
        const code = Object.values(segmentsCopy).join(concatChar)
        account = await getAccountByIdAndCode({
          accountType: accountSelected?.id ?? '',
          code,
          params: { appId }
        }).then(res => res.data)
      } catch (error) {}

      if (account && account.length === 1) {
        accountId = account[0].id
      } else {
        accountId = accountIdNotFound
      }

      newDataSource.push({
        id: segment.id,
        segments: segmentsCopy,
        accountId: accountId,
        accountTypeId: accountType
      })
    }

    return newDataSource
  }

  let content = (
    <>
      <Grid container>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid
            container
            item
            xs
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControl variant="outlined" className={classesInputs.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={accountType}
                onChange={handleChangeAccountType}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                readOnly={readOnly}
              >
                <MenuItem value="" disabled>
                  Account Type
                </MenuItem>
                {accountTypesOptions.length > 0
                  ? accountTypesOptions.map(x => (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              startIcon={<AddIcon />}
              onClick={handleAddNewRow}
              disabled={true}
              className={classesInputs.btnColorPrimary}
            >
              Add new Row
            </Button>
          </Grid>
        </Grid>
        <Grid container xs item direction="row" justifyContent="flex-end" alignItems="center">
          <Grid item xs>
            <ReactDataGrid
              idProperty="id"
              dataSource={dataSource}
              scrollProps={scrollProps}
              sortable={false}
              style={gridStyle}
              minRowHeight={80}
              rowHeight={null}
              columns={columns ?? []}
              showColumnMenuTool={false}
              onEditComplete={onEditComplete}
              editable={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )

  if (accountTypes.isLoading) {
    content = (
      <>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs>
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }

  if (segments.isLoading || segmentValidation.isFetching) {
    content = (
      <>
        <Grid container>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs>
              <FormControl variant="filled" className={classesInputs.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={accountType}
                  onChange={handleChangeAccountType}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Account Type
                  </MenuItem>
                  {accountTypesOptions.map(x => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container xs item direction="row" justifyContent="center" alignItems="center">
            <div
              style={{
                height: '30vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          </Grid>
        </Grid>
      </>
    )
  }

  if (accountTypes.isError || segments.isError) {
    content = (
      <>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <DialogContent>
            <div
              style={{
                height: '30vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <h2>Unable to get accounting information</h2>
              <WarningIcon fontSize="large" color="error" />
              <h4 className={classes.description}>Try again later!</h4>
            </div>
          </DialogContent>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={isOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <DialogTitle id="notice-modal-slide-title">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            Account Type
          </div>
        </DialogTitle>
        <DialogContent id="notice-modal-slide-description">{content}</DialogContent>
        <DialogActions className={classes.modalFooter}>
          {!readOnly ? (
            <Button
              onClick={handleOnComplete}
              color="primary"
              variant="outlined"
              disabled={accountTypes.isError || segments.isError}
            >
              Save
            </Button>
          ) : null}
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={'Invalid Segments'}
        open={segmentValidation.isError}
        closeNotification={() => setSegmentValidation(old => ({ ...old, isError: false }))}
        close
      />
    </>
  )
}

ModalAccountType.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  defaultDataSource: PropTypes.array,
  onComplete: PropTypes.func,
  readOnly: PropTypes.bool,
  isAccountValidationEnabled: PropTypes.bool
}
