import React from 'react'
import { compose, withState, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import DetailsPage from 'views/Teams/Details'
import BotCatalog from 'views/ConnectBot/Catalog/Catalog'
import AboutPage from 'views/About'
import ConsentPage from 'views/TermsAndConditions/ConsentPage/UserConsentView'
import DummyPage from 'views/Dummy/DummyView'
import RelishMessage from 'views/RelishMessages/RelishMessageView'

import PublicView from './PublicView'

let _isMounted = false

function getMenu(props) {
  let menuRoutes = [
    {
      path: '/document/details/:data',
      lookup: 'details',
      name: 'Details',
      component: DetailsPage,
      layout: '/public'
    },
    {
      path: '/about',
      lookup: 'about',
      name: 'About',
      component: AboutPage,
      layout: '/public'
    },
    {
      path: '/connect/bot/catalog',
      lookup: 'catlog',
      name: 'Catalog',
      component: BotCatalog,
      layout: '/public'
    },
    {
      path: '/iam/termsandconditions',
      lookup: 'consent',
      name: 'Consent',
      component: ConsentPage,
      layout: '/public'
    },
    {
      path: '/dummy/dummyPage',
      lookup: 'dummyPage',
      name: 'DummyPage',
      component: DummyPage,
      layout: '/public'
    },
    {
      path: '/dataAccess/relishMessage',
      lookup: 'relishMessage',
      name: 'relishMessage',
      useProps: true,
      component: props2 => {
        return <RelishMessage {...props2}></RelishMessage>
      },
      layout: '/public'
    }
  ]

  if (_isMounted) {
    props.setTeamsMenu(menuRoutes)
  }
}

export default compose(
  connect(state => ({
    isAuthenticated: state.login.isAuthenticated,
    name: state.login.name
  })),
  withState('teamsMenu', 'setTeamsMenu', []),
  withState('accessToken', 'setAccessToken', ''),
  withState('tenantId', 'setTenantId', ''),
  withState('validationId', 'setValidationId', ''),
  withState('email', 'setEmail', ''),
  withState('supplierId', 'setSupplierId', ''),
  withState('supplierName', 'setSupplierName', ''),
  withState('isFormUpdate', 'setIsFormUpdate', ''),
  withState('formUpdateValidation', 'setFormUpdateValidation', ''),
  withState('urlToken', 'setUrlToken', ''),
  withState('appId', 'setAppId', ''),
  withState('typeId', 'setTypeId', ''),
  withState('message', 'setMessage', null),
  withState('callBackFunction', 'setCallBackFunction', null),
  lifecycle({
    componentDidMount() {
      _isMounted = true
      getMenu(this.props)
    }
  })
)(PublicView)
