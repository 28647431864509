import {
  createSupportTicket,
  uploadAttachments,
  getSupportTicketComments,
  addCommentToSupportTicket
} from 'services/apiSupportTickets'
import { useMutation, useQuery } from 'react-query'

export function useCreateTicket({ onSuccess, onError }) {
  return useMutation(['tickets', 'latest'], createSupportTicket, {
    onSuccess,
    onError
  })
}

export function useCreateAttachments() {
  return useMutation(['tickets', 'attachments'], uploadAttachments)
}

export function useGetComments(tenantId, ticketId) {
  return useQuery(['tickets', 'comments'], () =>
    getSupportTicketComments(tenantId, ticketId).then(response => {
      return response.data
    })
  )
}

export function useAddComment(queryClient) {
  return useMutation(
    ['tickets', 'comments', 'latest'],
    addCommentToSupportTicket, 
    {
      onSuccess: ({data}) => {
        queryClient.setQueryData(['tickets', 'comments'], (prevComments) => prevComments ? [data].concat(prevComments) : [data])
      }
    }
  )
}
