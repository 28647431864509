import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'

// Form
import { useWatch } from 'react-hook-form'

// Inputs
import { TextInput } from '../Inputs/TextInput'
import { SelectInput } from '../Inputs/SelectInput'
import { BooleanInput } from '../Inputs/BooleanInput'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Constants
import { fieldsByType } from 'utils/Constants'
import { useEffect } from 'react'

const useStyles = makeStyles(styles)

const connectionObj = {
  remoteConnection: '',
  connectionId: '',
  name: '',
  fileExtension: '.json',
  path: '',
  zipFile: false,
  type: 'sharepoint',
  bucketName: '',
  appSecret: '',
  clientId: '',
  siteUrl: '',
  dbSchema: '',
  dbHost: '',
  dbPort: '',
  dbUser: '',
  dbName: '',
  dbServer: '',
  host: '',
  port: '',
  userName: '',
  encryptionMethod: '',
  singleConnection: false
}

export default function ConnectionView(props) {
  const { control, remoteConnections, reset, remoteConnectionId, transRemoteConnection } = props

  const classes = useStyles()

  const type = useWatch({
    control,
    name: 'type',
    defaultValue: 'sharepoint'
  })

  const remoteConnection = useWatch({
    control,
    name: 'remoteConnection',
    defaultValue: remoteConnectionId
  })

  function modifyForm(rowData, transRowData) {
    switch (rowData.type) {
      case 's3':
        rowData = { ...connectionObj, ...rowData, ...rowData.s3Params, ...transRowData?.s3Params }
        delete rowData.s3Params
        break
      case 'sharepoint':
        rowData = { ...connectionObj, ...rowData, ...rowData.sharePointParams, ...transRowData?.sharePointParams }
        delete rowData.sharePointParams
        break
      case 'sftp':
        rowData = { ...connectionObj, ...rowData, ...rowData.sftpParams, ...transRowData?.sftpParams }
        delete rowData.sftpParams
        break
      case 'msSql':
        rowData = { ...connectionObj, ...rowData, ...rowData.msSqlParams, ...transRowData?.msSqlParams }
        delete rowData.msSqlParams
        break
      default:
        break
    }
    return rowData
  }

  useEffect(() => {
    remoteConnections[remoteConnection].remoteConnection = remoteConnection
    const newData = modifyForm(remoteConnections[remoteConnection], transRemoteConnection)
    reset(newData)
  }, [remoteConnection, reset, remoteConnections, transRemoteConnection])

  function typeForm() {
    let fields = []
    if (!type || type === '') {
      return
    }
    fields = fieldsByType[type]

    return (
      <Grid container justifyContent="center" spacing={3}>
        {fields.map(field => inputs(field))}
      </Grid>
    )
  }

  function inputs(field) {
    let jsxElements = []
    switch (field.type) {
      case 'boolean':
        jsxElements.push(
          <BooleanInput
            key={field.key}
            name={field.key}
            control={control}
            label={field.label}
            readOnly={field.readOnly}
          />
        )
        break
      case 'select':
        jsxElements.push(
          <SelectInput
            key={field.key}
            name={field.key}
            control={control}
            label={field.label}
            values={field.values}
            readOnly={field.readOnly}
          />
        )
        break
      case 'text':
        jsxElements.push(
          <TextInput
            key={field.key}
            name={field.key}
            control={control}
            label={field.label}
            type={field.type}
            readOnly={field.readOnly}
          />
        )
        break
      default:
        break
    }

    return jsxElements.map(jsxElement => jsxElement)
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <h4 className={classes.infoText}>Destination Connection</h4>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <h5 className={classes.sectionTitle}>Destination</h5>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container justifyContent="center" spacing={3}>
          <SelectInput
            name={'remoteConnection'}
            control={control}
            label={'Destination'}
            values={Object.keys(remoteConnections).map(connection => {
              return {
                label: remoteConnections[connection].name,
                key: remoteConnections[connection].connectionId
              }
            })}
          />
          <TextInput name={'type'} control={control} label={'Type'} type={'text'} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {typeForm()}
      </Grid>
    </Grid>
  )
}
