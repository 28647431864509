const teamCreate = {
    environment: {
        type: "string",
        required: true,
        displayName: "Environment"
    },
    documentType: {
        type: "string",
        required: true,
        displayName: "Document Type"
    },
    documentId: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 20
        },
        displayName: "Document Number"
    }
}

export {
    teamCreate
};
