import { CircularProgress } from '@material-ui/core'

import { useGetInvoice } from 'hooks/useInvoices'

import React from 'react'
import { useParams } from 'react-router'

import RedirectUI, { getPathNewUi } from 'guards/RedirectUI'
import { invoiceProcessType } from 'utils/Constants'
import ExceptionOcrReadOnly from 'views/OcrInvoiceExceptionROnly/ExceptionOcrReadOnly'
import ExceptionView from './ExceptionView'
export default function InvoiceExceptionReadOnly() {
  const { invoiceId } = useParams()

  const invoiceFetch = useGetInvoice({
    invoiceId,
    options: { refetchOnWindowFocus: false }
  })

  if (invoiceFetch.isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    )
  }

  if (invoiceFetch.isError) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Something went wrong, try again later</p>
      </div>
    )
  }

  const isInvoiceOcr =
    invoiceFetch.data.subProcess === invoiceProcessType.ocr ||
    invoiceFetch.data.process === invoiceProcessType.ocr

  const path = getPathNewUi(`/platform/invoiceAI/exceptionReadOnly/${invoiceId}`)

  return (
    <>
      {isInvoiceOcr ? (
        <RedirectUI path={path}>
          <ExceptionOcrReadOnly invoiceFetch={invoiceFetch} />
        </RedirectUI>
      ) : (
        <ExceptionView invoiceFetch={invoiceFetch} isReadOnly={true} />
      )}
    </>
  )
}
