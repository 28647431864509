import { Badge, Grid, IconButton, Tooltip } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'
import styles from 'assets/jss/material-dashboard-pro-react/components/exceptionFieldsStyle'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { HeaderFieldsSide } from 'utils/Constants'
import { checkErrorOrWarning } from 'utils/functions'
import { generateJsonValidation } from 'utils/validator'

const useStyles = makeStyles(styles)

const useStylesSelect = makeStyles(theme => ({
  selectBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green'
    }
  },
  inputWarning: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f3bc07 !important'
    }
  },
  inputSuccess: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'green !important'
    }
  },
  inputError: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red !important'
    }
  }
}))

const SelectCompanyCode = props => {
  const {
    summaryField,
    control,
    index,
    rules,
    label,
    validationsError,
    setValidationErrorsState,
    validationErrorsState,
    appConfig,
    getValues,
    setValueForm
  } = props
  let validationErrorMessages = validationErrorsState[summaryField.type]?.messages ?? ''
  let validated = validationErrorsState[summaryField.type]?.valid ?? null
  const [typeMsg, setTypeMsg] = useState(checkErrorOrWarning(validationErrorMessages, validated))

  const [typeMsgFirst, setTypeMsgFirst] = useState(checkErrorOrWarning(validationsError))
  const [isValid, setIsValid] = useState(false)
  const [initialErrorMessages] = useState(validationsError)
  const [updatedValue, setUpdatedValue] = useState(summaryField.value)
  const [initialValue] = useState(summaryField.value)

  const classes = useStyles()
  const classesSelect = useStylesSelect()

  function validationMessages(validationsError, validated = false) {
    if (!validationsError || validated) {
      return ''
    }

    if (Array.isArray(validationsError)) {
      return (
        <>
          {validationsError.map((message, index) => (
            <div key={index}>{message.message}</div>
          ))}
        </>
      )
    }

    const messages = validationsError.split('\n')

    return (
      <>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </>
    )
  }

  const changeValidationToSuccess = useCallback(() => {
    setTypeMsg('')
    setIsValid(true)
    setValidationErrorsState(old => {
      const previousValidation = old[summaryField.type] ?? {}
      return {
        ...old,
        [summaryField.type]: {
          ...previousValidation,
          messages: [{ type: 'success', message: 'Accepted by User' }],
          valid: true
        }
      }
    })
  }, [setValidationErrorsState, summaryField.type])

  const changeValidationToWarning = useCallback(
    initialErrorMessages => {
      setTypeMsg('warning')
      setTypeMsgFirst('warning')
      setIsValid(false)
      setValidationErrorsState(old => {
        const previousValidation = old[summaryField.type] ?? {}
        return {
          ...old,
          [summaryField.type]: {
            ...previousValidation,
            messages: initialErrorMessages,
            valid: null
          }
        }
      })
    },
    [setValidationErrorsState, summaryField.type]
  )

  const changeValidationToError = useCallback(
    initialErrorMessages => {
      setTypeMsg('error')
      setTypeMsgFirst('error')
      setIsValid(false)
      setValidationErrorsState(old => {
        const previousValidation = old[summaryField.type] ?? {}
        return {
          ...old,
          [summaryField.type]: {
            ...previousValidation,
            messages: initialErrorMessages,
            valid: null
          }
        }
      })
    },
    [setValidationErrorsState, summaryField.type]
  )

  useEffect(() => {
    if (initialValue !== updatedValue) {
      if (typeMsgFirst !== '') {
        changeValidationToSuccess()
      }
    } else {
      if (typeMsgFirst === 'error') {
        changeValidationToError(initialErrorMessages)
      } else if (typeMsgFirst === 'warning') {
        if (isValid || validated) {
          changeValidationToSuccess()
        } else {
          changeValidationToWarning(initialErrorMessages)
        }
      }
    }
  }, [
    updatedValue,
    changeValidationToError,
    changeValidationToWarning,
    changeValidationToSuccess,
    initialValue,
    isValid,
    typeMsgFirst,
    initialErrorMessages,
    validated
  ])

  function getSelectOptions(appConfig) {
    const jsxResult = []
    jsxResult.push(
      <MenuItem value="" key={'none'}>
        <em>None</em>
      </MenuItem>
    )
    // eslint-disable-next-line
    for (const company of appConfig.params?.rfc ?? []) {
      jsxResult.push(
        <MenuItem value={company.companyCode} key={company.rfc}>
          {`(${company.companyCode}) ${company.name}`}
        </MenuItem>
      )
    }

    return jsxResult
  }

  function onChangeCompanyCode(rfcList, code) {
    const companyCode = rfcList.find(rfc => rfc.companyCode === code)

    if (!companyCode) {
      return
    }

    const summaryFields = getValues('summaryFields')
    const index = summaryFields.findIndex(summaryField => summaryField.type === 'TAX_RECEIVER_ID')
    const indexFieldBillTo = summaryFields.findIndex(
      summaryField => HeaderFieldsSide.BILL_TO.type === summaryField.type
    )
    const indexFieldIdBillTo = summaryFields.findIndex(
      summaryField => `${HeaderFieldsSide.BILL_TO.type}_ID` === summaryField.type
    )
    const indexFieldShip = summaryFields.findIndex(
      summaryField => HeaderFieldsSide.RECEIVER_ADDRESS.type === summaryField.type
    )
    const indexFieldIdShip = summaryFields.findIndex(
      summaryField => `${HeaderFieldsSide.RECEIVER_ADDRESS.type}_ID` === summaryField.type
    )

    if (index < 0) {
      return
    }

    if (indexFieldBillTo > 0 && indexFieldIdBillTo > 0) {
      setValueForm(
        `summaryFields.${indexFieldBillTo}.value`,
        {
          fullAddress: '',
          companyAddressId: ''
        },
        {
          shouldValidate: true,
          shouldDirty: true
        }
      )
      setValueForm(`summaryFields.${indexFieldIdBillTo}.value`, '')
    }

    if (indexFieldShip > 0 && indexFieldIdShip > 0) {
      setValueForm(
        `summaryFields.${indexFieldShip}.value`,
        {
          fullAddress: '',
          companyAddressId: ''
        },
        {
          shouldValidate: true,
          shouldDirty: true
        }
      )
      setValueForm(`summaryFields.${indexFieldIdShip}.value`, '')
    }

    setValueForm(`summaryFields.${index}.value`, companyCode.rfc, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  function getColors(isDirty, typeMsg, error) {
    let color = ''
    let inputColor = ''
    let badgeColor = ''
    let tooltipColor = ''

    if (typeMsg === 'error') {
      color = classes.errorColor
      inputColor = classesSelect.inputError
      tooltipColor = classes.tooltipError
    }

    if (validated) {
      color = classes.successColor
      inputColor = classesSelect.inputSuccess
    }

    if (isValid && !Boolean(error)) {
      color = classes.successColor
      inputColor = classesSelect.inputSuccess
    }

    if (isDirty && !Boolean(error)) {
      color = classes.successColor
      inputColor = classesSelect.inputSuccess
    }

    if (!isDirty && typeMsg === 'warning') {
      color = classes.warningColor
      inputColor = classesSelect.inputWarning
      tooltipColor = classes.tooltipWarning
    }

    return { color, inputColor, badgeColor, tooltipColor }
  }

  return (
    <Controller
      render={({ field, fieldState: { error, isDirty } }) => {
        const isWarning = !isDirty && typeMsg === 'warning' && !Boolean(error)
        const isVisible =
          (Boolean(error) && typeMsg === 'error') ||
          (!isDirty && typeMsg === 'error') ||
          isWarning ||
          Boolean(error)
        setUpdatedValue(field.value)

        const backEndErrorMessage = validationMessages(validationErrorMessages, validated)
        const errorMessage = backEndErrorMessage ? backEndErrorMessage : error?.message

        return (
          <Grid container justifyContent="center">
            <Grid item xs={typeMsgFirst === 'warning' && !Boolean(error) && !isDirty ? 11 : 12}>
              <Tooltip
                classes={{
                  arrow: isWarning ? classes.warningColor : classes.errorColor,
                  tooltip: isWarning ? classes.tooltipWarning : classes.tooltipError
                }}
                title={isVisible ? errorMessage : ''}
                arrow
                placement="right"
              >
                <Badge
                  style={{ width: '95%' }}
                  classes={{
                    badge: isWarning ? classes.badgeWarning : classes.badgeError
                  }}
                  badgeContent={
                    isWarning ? <WarningIcon className={classes.warningColor}></WarningIcon> : '!'
                  }
                  invisible={isVisible ? false : true}
                >
                  <FormControl
                    variant="outlined"
                    error={Boolean(error) || typeMsg === 'error'}
                    style={{ width: '100%' }}
                  >
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      classes={{
                        root: !Boolean(error) ? getColors(isDirty, typeMsg, error).color : '',
                        focused: !Boolean(error) ? getColors(isDirty, typeMsg, error).color : ''
                      }}
                    >
                      {label}
                    </InputLabel>
                    <Select
                      {...field}
                      id={summaryField.id}
                      style={{ width: '100%' }}
                      labelId="demo-simple-select-label"
                      label={label ?? ''}
                      className={
                        !Boolean(error) ? getColors(isDirty, typeMsg, error).inputColor : ''
                      }
                      onChange={e => {
                        field.onChange(e.target.value)
                        onChangeCompanyCode(appConfig.params.rfc, e.target.value)
                        setTypeMsg('')
                      }}
                    >
                      {getSelectOptions(appConfig)}
                    </Select>
                  </FormControl>
                </Badge>
              </Tooltip>
            </Grid>
            {typeMsgFirst === 'warning' && !Boolean(error) && !isDirty ? (
              <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                  style={{ padding: '0' }}
                  className={classes.buttonSuccess}
                  onClick={() =>
                    isValid || validated
                      ? changeValidationToWarning(initialErrorMessages)
                      : changeValidationToSuccess()
                  }
                >
                  {isValid || validated ? (
                    <CheckCircleIcon className={classes.successColor} />
                  ) : (
                    <CheckCircleOutlineIcon className={classes.successColor} />
                  )}
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
        )
      }}
      name={`summaryFields.${index}.value`}
      control={control}
      rules={generateJsonValidation(rules, summaryField.type)}
    />
  )
}

export default SelectCompanyCode
