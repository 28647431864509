import React from 'react'

import AutoCompleteAsyncHeader from 'components/Invoice/AutoCompleteAsyncHeader'
import { useDebounce } from 'hooks/useDebounce'
import { useGetInvoiceOcrFieldValues } from 'hooks/useOcrInvoice'
import { useWatch } from 'react-hook-form'
import { HeaderFieldsSide } from 'utils/Constants'

const AutoCompleteAsyncValuesHeader = props => {
  const { summaryField, control } = props

  const [filter, setFilter] = React.useState('')
  const [debouncedValue] = useDebounce(filter, 500)

  const value = useWatch({
    name: 'summaryFields',
    control
  })

  const process = value.find(field => field.type === HeaderFieldsSide.PROCESS.type)
  const field = value.find(field => field.type === summaryField.type)

  const filedValues = useGetInvoiceOcrFieldValues(summaryField.type, {
    filter: debouncedValue,
    level: 'header',
    status: 'enabled',
    process: process.value
  })

  const valueFilter = field?.value ?? ''

  React.useEffect(() => {
    setFilter(valueFilter)
  }, [valueFilter])

  return (
    <AutoCompleteAsyncHeader
      {...props}
      filter={filter}
      setFilter={setFilter}
      isLoading={filedValues.isLoading || filedValues.isFetching}
      options={filedValues.data}
      page={field?.page ?? '1'}
    />
  )
}

export default AutoCompleteAsyncValuesHeader
