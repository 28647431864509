import React from 'react'
// @material-ui/core components
import { CircularProgress } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
// import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'
import GridItem from 'components/Grid/GridItem.js'
import { useLocalStorageState } from 'hooks/useLocalStorage.js'
import { useGetPoDetails } from 'hooks/useMasterData.js'
import { useWatch } from 'react-hook-form'
import { HeaderFieldsStandard } from 'utils/Constants.js'
import GridContainer from '../../components/Grid/GridContainer.js'

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export default function PoModal(props) {
  const classes = useStyles()
  const {
    showModal,
    onClose,
    onSubmit,
    control,
    accountSegmentsEnabled,
    isHeaderSection,
    requiredFields,
    appId,
    isDetailsEnabled
  } = props

  const supplierAddress = requiredFields.find(
    x =>
      x.level === 'header' && x.standardField === HeaderFieldsStandard['supplier.address.id'].type
  )

  const isEnabledSupAddr = Boolean(supplierAddress)

  const [filtersHeader, setFiltersHeader] = useLocalStorageState('invoice.poModal.header', {
    companyCode: true,
    currency: true,
    vendorIdVendorName: true,
    buyerShipAddress: true,
    buyerBillAddress: true,
    paymentTerms: true,
    matchPoVsLine: true,
    accounting: accountSegmentsEnabled,
    details: true,
    supplierShipAddress: isEnabledSupAddr
  })

  const [filtersLineItems, setFiltersLineItems] = useLocalStorageState(
    'invoice.poModal.lineItems',
    {
      accounting: accountSegmentsEnabled,
      details: true
    }
  )
  const value = useWatch({
    name: 'headerFields',
    control
  })

  const po = value?.find(x => x.field === HeaderFieldsStandard.poNumber.type)

  const { isLoading, data: poInfo } = useGetPoDetails(po?.value, { appId })

  const filters = isHeaderSection ? filtersHeader : filtersLineItems

  const {
    companyCode,
    currency,
    vendorIdVendorName,
    buyerShipAddress,
    buyerBillAddress,
    paymentTerms,
    matchPoVsLine,
    accounting,
    details,
    supplierShipAddress
  } = filters

  const handleChange = event => {
    if (isHeaderSection) {
      setFiltersHeader({ ...filters, [event.target.name]: event.target.checked })
    } else {
      setFiltersLineItems({ ...filters, [event.target.name]: event.target.checked })
    }
    //setFilters()
  }

  const handleSubmit = () => {
    onSubmit(filters)
    onClose()
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      fullWidth={true}
      maxWidth={'sm'}
      open={showModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose()}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
    >
      <DialogTitle id="notice-modal-slide-title">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          Update invoice information from PO
        </div>
      </DialogTitle>
      <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : poInfo ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">
                  Select the information you want to update from the Purchase Order:
                </FormLabel>
                <FormGroup>
                  {isHeaderSection ? (
                    <>
                      <Typography variant="subtitle2">Header Level:</Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            checked={companyCode}
                            onChange={handleChange}
                            name="companyCode"
                          />
                        }
                        label={
                          <>
                            <Typography variant="body1">
                              Company Code: <strong>{poInfo?.companyCode}</strong>
                            </Typography>
                          </>
                        }
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            checked={currency}
                            onChange={handleChange}
                            name="currency"
                          />
                        }
                        label={
                          <Typography variant="body1">
                            Currency: <strong>{poInfo?.currency}</strong>
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            checked={vendorIdVendorName}
                            onChange={handleChange}
                            name="vendorIdVendorName"
                          />
                        }
                        label={
                          <>
                            {poInfo.vendor.id ? (
                              <Typography variant="body1">
                                Vendor ID: <strong>{poInfo.vendor.id}</strong>
                              </Typography>
                            ) : (
                              <Typography variant="body1">Vendor ID</Typography>
                            )}

                            {poInfo.vendor.name ? (
                              <Typography variant="body1">
                                Vendor Name: <strong>{poInfo.vendor.name}</strong>
                              </Typography>
                            ) : (
                              <Typography variant="body1">Vendor Name</Typography>
                            )}
                          </>
                        }
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            checked={buyerShipAddress}
                            onChange={handleChange}
                            name="buyerShipAddress"
                          />
                        }
                        label={
                          <Typography variant="body1">
                            Buyer Ship-to Address:
                            <strong>{poInfo.buyerShipToAddress?.address}</strong>
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            checked={buyerBillAddress}
                            onChange={handleChange}
                            name="buyerBillAddress"
                          />
                        }
                        label={
                          <Typography variant="body1">
                            Buyer Bill-to Address:{' '}
                            <strong>{poInfo.buyerBillToAddress?.address}</strong>
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            checked={paymentTerms}
                            onChange={handleChange}
                            name="paymentTerms"
                          />
                        }
                        label={
                          <Typography variant="body1">
                            Payment Terms: <strong>{poInfo.paymentTerms}</strong>
                          </Typography>
                        }
                      />

                      {isEnabledSupAddr ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="default"
                              checked={supplierShipAddress}
                              onChange={handleChange}
                              name="supplierShipAddress"
                            />
                          }
                          label={
                            <Typography variant="body1">
                              Supplier Address:{' '}
                              <strong>{poInfo?.supplierShipAddress?.address}</strong>
                            </Typography>
                          }
                        />
                      ) : null}
                    </>
                  ) : null}
                  <br />
                  <Box>
                    <Typography variant="subtitle2">Line Level:</Typography>
                  </Box>

                  {isHeaderSection ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          checked={matchPoVsLine}
                          onChange={handleChange}
                          name="matchPoVsLine"
                        />
                      }
                      label="Automatic line level matching between PO and Invoice"
                    />
                  ) : null}

                  {accountSegmentsEnabled ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          checked={accounting}
                          onChange={handleChange}
                          name="accounting"
                        />
                      }
                      label="Accounting"
                    />
                  ) : null}

                  {isDetailsEnabled ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          checked={details}
                          onChange={handleChange}
                          name="details"
                        />
                      }
                      label="Details"
                    />
                  ) : null}
                </FormGroup>
              </FormControl>
            </GridItem>
          </GridContainer>
        ) : (
          <p>This PO number does not exist in our system.</p>
        )}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => handleSubmit()} color="primary" variant="outlined">
          Agree
        </Button>
        <Button onClick={() => onClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
