import React, { useEffect } from 'react'

import { Badge, Box, TextField, Tooltip } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { CircularProgress } from '@material-ui/core'
import { useDebounce } from 'hooks/useDebounce'
import { useGetAllCommodity, useGetCommodity } from 'hooks/useMasterData'
import { errorTypes, getError } from './useHeader'

const CommoditySelectLine = props => {
  const {
    handleChange,
    value,
    lineId,
    readOnly,
    appId,
    lineItemsActions,
    label,
    field,
    lines
  } = props
  const { getColors, classes } = lineItemsActions

  const [filter, setFilter] = React.useState('')
  const [debouncedValue] = useDebounce(filter, 500)

  const commodities = useGetAllCommodity({
    text: debouncedValue,
    status: 'enabled',
    appId
  })

  const commodity = useGetCommodity(value, { appId })

  useEffect(() => {
    if (commodity.data && !filter) {
      setFilter(commodity.data.name)
    }
  }, [commodity.data, filter])

  const lineErrors = lines.errors.find(x => x.id === lineId)

  const fieldError = lineErrors?.errors?.find(x => x.field === field.standardField)
  const errorData = getError(fieldError)
  let typeMessage = ''
  const criticalType = fieldError?.details?.find(x => x.criticalityType === 'critical')

  if (criticalType) {
    typeMessage = 'critical'
  }

  const isError = typeMessage === errorTypes.critical
  const errorMessage = errorData ? errorData?.message : ''
  const isVisible = Boolean(errorData)

  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Tooltip
        classes={{
          arrow: isError ? classes.errorColor : '',
          tooltip: isError ? classes.tooltipError : ''
        }}
        title={isVisible ? errorMessage : ''}
        arrow
        placement="right"
      >
        <Badge
          classes={{
            badge: isError ? classes.badgeError : ''
          }}
          badgeContent={isError ? '!' : null}
          invisible={isVisible ? false : true}
        >
          <Autocomplete
            freeSolo
            onChange={(event, newValue) => {
              handleChange(newValue)
            }}
            options={commodities.data ?? []}
            id="asynchronous-commodities"
            style={{ width: 300 }}
            getOptionSelected={(option, value) => option.name === value.name}
            disabled={readOnly}
            inputValue={filter}
            onInputChange={(event, newInputValue) => {
              setFilter(`${newInputValue}`)
            }}
            className={readOnly ? classes.readOnly : ''}
            getOptionLabel={option => option.name}
            renderOption={option => (
              <>
                ({option?.code}) {option?.name}
              </>
            )}
            filterOptions={x => x}
            loading={commodities.isLoading || commodities.isFetching}
            renderInput={params => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                fullWidth
                multiline
                error={isError}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {commodities.isLoading || commodities.isFetching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                  classes: {
                    notchedOutline: !isError ? getColors(errorData).inputColor : ''
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: !isError ? getColors(errorData).color : '',
                    focused: !isError ? getColors(errorData).color : ''
                  }
                }}
              />
            )}
          />
        </Badge>
      </Tooltip>
    </Box>
  )
}

export default CommoditySelectLine
