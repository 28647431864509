import { PermissionContext } from 'contexts/PermissionsContext'
import * as React from 'react'
import { useQuery } from 'react-query'
import {
  checkPermissionsInToken,
  getAvailableRolesEndPoint,
  getRolesPermissions
} from 'services/apiAuth'
import { getUserTenant } from 'utils/functions'

export function useGetAvailableRoles(types = ['user']) {
  const tenantId = getUserTenant()
  return useQuery(
    ['auth', 'roles', tenantId],
    () =>
      getAvailableRolesEndPoint(tenantId).then(result => {
        const sanitizedArray = result.data
          .filter(role => role.enabled && types.includes(role.type))
          .map(role => role.roleName)

        return sanitizedArray
      }),
    {
      refetchOnWindowFocus: false
    }
  )
}

export function useGetRolesPermissions(roles) {
  const key = roles.join(',')
  return useQuery(
    ['auth', 'roles', key, 'permissions'],
    () => getRolesPermissions(key).then(x => x.data),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: Boolean(key)
    }
  )
}

export function useCheckPermissions(key, permissions) {
  return useQuery(
    ['auth', 'checkPermissions', key],
    () =>
      checkPermissionsInToken(permissions).then(x => {
        return x.data.allowed
      }),
    {
      refetchOnWindowFocus: false
    }
  )
}

export const usePermissions = () => {
  const context = React.useContext(PermissionContext)
  if (!context) {
    throw new Error(`Permissions must be rendered within the PermissionProvider`)
  }

  return context
}
