export const overrides = {
    MuiCircularProgress: {
        root: {
            color: '#f15f22'
        }
    },
    MuiAvatar: {
        colorDefault: {
            backgroundColor: '#f15f22'
        }
    },
    MuiFormControl: {
        root: {
            zIndex: 'auto'
        }
    }
};
